<mat-card
  appearance="outlined"
  class="company-account-card"
  [style.background-color]="bgColor"
>
  <mat-card-header>{{ title }}</mat-card-header>
  <div class="dummy-title" *ngIf="title"></div>
  <mat-card-content class="company-account-card-content">
    <div class="company-account-data">
      <div>
        <p class="field-title">COMPANY NAME</p>
        <span data-testid="companyName" class="field-data">{{
          customerData?.companyName
        }}</span>
      </div>

      <div *ngIf="customerData?.accountCreatedAt">
        <p class="field-title">ACCOUNT CREATED</p>
        <span data-testid="accountCreatedAt" class="field-data">{{
          customerData?.accountCreatedAt
        }}</span>
      </div>

      <div *ngIf="customerData?.lastModifiedTimestamp">
        <p class="field-title">LAST MODIFIED</p>
        <span data-testid="lastModified" class="field-data">{{
          customerData?.lastModifiedTimestamp
        }}</span>
      </div>

      <div *ngIf="customerData?.partnerId">
        <p class="field-title">PARTNER ID</p>
        <span data-testid="partnerId" class="field-data">{{
          customerData?.partnerId
        }}</span>
      </div>

      <div *ngIf="customerData?.numberOfUsers">
        <p class="field-title"># Of users</p>
        <span data-testid="numberOfUsers" class="field-data">{{
          customerData?.numberOfUsers
        }}</span>
      </div>

      <div *ngIf="customerData?.status">
        <span data-testid="companyStatus" class="company-status">{{
          customerData?.status
        }}</span>
      </div>
    </div>
  </mat-card-content>
</mat-card>
