import { Injectable } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';

/**
 * Service to provide helper methods for form validation and management.
 */
@Injectable({
  providedIn: 'root',
})
export class FormHelpersService {
  private currentForm: FormGroup | undefined;

  /**
   * Sets the current form to be used by the service.
   *
   * @param form - The FormGroup to be set as the current form.
   */
  public setForm(form: FormGroup) {
    this.currentForm = form;
  }

  /**
   * Checks if a control in the current form has a specific error.
   *
   * @param controlName - The name of the control to check.
   * @param errorName - The name of the error to check for.
   * @returns True if the control has the error and is invalid, dirty, or touched; otherwise, false.
   * @throws Error if the current form is not set.
   */
  public checkError(controlName: string, errorName: string) {
    if (!this.currentForm) {
      throw Error('CurrentForm should be set.');
    }

    const hasError = this.currentForm.controls[controlName].hasError(errorName);

    return (
      hasError &&
      this.currentForm.controls[controlName].invalid &&
      (this.currentForm.controls[controlName].dirty ||
        this.currentForm.controls[controlName].touched)
    );
  }

  /**
   * Validates a phone number to check if it is in E.164 format.
   *
   * @param phoneNum - The phone number to validate.
   * @returns True if the phone number is in E.164 format; otherwise, false.
   */
  public validatePhoneNumForE164(phoneNum: string) {
    const regEx = /^\+[1-9]\d{10,14}$/;
    return regEx.test(phoneNum);
  }

  /**
   * Checks if a control's value matches the value of the 'password' control in the same form group.
   *
   * @param control - The control to check.
   * @returns An error object if the values do not match; otherwise, null.
   */
  public checkConfirmationPassword(control: AbstractControl) {
    const value = control.value;
    const compare = control.parent?.get('password')?.value;
    if (compare !== value) {
      return {
        notMatch: value,
      };
    }
    return null;
  }

  /**
   * Checks if a control's value is less than zero.
   *
   * @param control - The control to check.
   * @returns An error object if the value is less than zero; otherwise, null.
   */
  public checkIfLessThanZero(control: AbstractControl) {
    const val = control.value;
    if (val < 0) {
      return {
        lessThanZero: 'lessThanZero',
      };
    }
    return null;
  }

  /**
   * Checks if a control's value is a file path and not a directory.
   *
   * @param control - The control to check.
   * @returns An error object if the value is not a file path; otherwise, null.
   */
  public checkIfAFile(control: AbstractControl) {
    const val = control.value;
    if (val.charAt(val.length - 1) === '/') {
      return {
        NotAFile: val,
      };
    }
    return null;
  }

  /**
   * Checks if a control's value matches a time format (±HH:MM).
   *
   * @param control - The control to check.
   * @returns Null if the value matches the format; otherwise, an error object.
   */
  public checkTimeFormat(control: AbstractControl) {
    const regex = /^(\+|-)(\d{1,2}):(\d{2})$/;
    const val = control.value;
    if (regex.test(val)) {
      return null;
    } else {
      return {
        notMatch: val,
      };
    }
  }

  /**
   * Validates that an end date is not before a start date in a form group.
   *
   * @param control - The control containing the end date.
   * @param start_control - The name of the control containing the start date.
   * @returns An error object if the end date is before the start date; otherwise, null.
   */
  dateRangeValidator(control: AbstractControl, start_control: string) {
    const endDate = control.value;
    const startDate = control.parent?.get(start_control)?.value;

    if (startDate != '' && startDate > endDate) {
      //this is an error set for a specific control which you can use in a mat-error
      control.setErrors({ dateError: true });
      //this is the returned error for the form normally used to disable a submit button
      return { dateError: true };
    }
    return null;
  }
}
