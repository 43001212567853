<div class="modal">
  <div mat-dialog-title>
    <h2 class="modal-title">{{ title }}</h2>
    <button
      mat-icon-button
      aria-label="close dialog"
      mat-dialog-close
      class="modal-close-btn"
      (click)="close()"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div mat-dialog-content>
    <form
      ngNativeValidate
      (ngSubmit)="process()"
      [formGroup]="user"
      id="addTeamMemberForm"
    >
      <input type="hidden" formControlName="uid" />

      <div class="field-row">
        <div class="field-name-container">
          <label class="field-title">Team Member's Name</label>
          <div>
            <input
              class="app-input"
              type="text"
              placeholder="Type in User name"
              formControlName="name"
              size="40"
              [ngClass]="{ 'app-input-error': checkError('name', 'required') }"
            />
          </div>
          <mat-error *ngIf="checkError('name', 'required')"
            >Member Name is required</mat-error
          >
        </div>

        <div class="field-email-container">
          <label class="field-title">Team Member's Email</label>
          <div>
            <input
              class="app-input"
              type="email"
              placeholder="User's email"
              formControlName="email"
              required
              size="40"
              [ngClass]="{ 'app-input-error': checkError('email', 'required') }"
            />
          </div>
          <mat-error *ngIf="checkError('email', 'required')"
            >Member Email is required</mat-error
          >
        </div>
      </div>

      <div class="field-row">
        <div class="field-phone-container">
          <label class="field-title">Team Member's Phone</label>
          <div>
            <input
              class="app-input"
              type="text"
              placeholder="Type in User Phone Number"
              formControlName="phoneNumber"
              min="0"
            />
          </div>
        </div>
      </div>

      <div class="field-row">
        <div class="field-roles-container">
          <label class="field-title">Team Members Role</label>
          <div class="field-roles-checkboxes">
            <div *ngFor="let role of roles">
              <label class="role-label">
                <input
                  type="”checkbox”"
                  formArrayName="roles"
                  [value]="role.value"
                  (change)="onCheckboxChange($event)"
                  type="checkbox"
                  [checked]="role.selected"
                />
                {{ role.name }}
              </label>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <app-message-box></app-message-box>
  <div mat-dialog-actions>
    <button
      mat-flat-button
      color="primary"
      [disabled]="isLoading || !user.valid"
      form="addTeamMemberForm"
      type="submit"
      class="save-btn"
    >
      {{ isLoading ? null : 'Save' }}
      <span *ngIf="isLoading" class="spinner">
        <mat-spinner diameter="24"></mat-spinner>
      </span>
    </button>
  </div>
</div>
