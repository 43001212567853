/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import { InjectionToken } from '@angular/core';

/**
 * Specific GrpcClientSettings for JobScheduleService.
 * Use it only if your default settings are not set or the service requires other settings.
 */
export const GRPC_JOB_SCHEDULE_SERVICE_CLIENT_SETTINGS =
  new InjectionToken<any>('GRPC_JOB_SCHEDULE_SERVICE_CLIENT_SETTINGS');
