import { Injectable } from '@angular/core';

/**
 * Service to provide URL parameters from the initial URL.
 */
@Injectable({
  providedIn: 'root',
})
export class UrlParamsService {
  private parameters = new URLSearchParams('');

  /**
   * Parses the URL and extracts the search parameters.
   *
   * @param url - The URL to parse.
   */
  public parseUrl(url: string) {
    this.parameters = new URL(url).searchParams;
  }

  /**
   * Retrieves the URL search parameters.
   *
   * @returns The URL search parameters.
   */
  public get params(): URLSearchParams {
    return this.parameters;
  }
}
