import { Component, OnDestroy, OnInit } from '@angular/core';
import { Auth, getIdTokenResult } from '@angular/fire/auth';
import { FormControl, FormGroup } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { ApprovalService } from 'app/services/approval.service';
import { BinaryTypeService } from 'app/services/binary-type.service';
import { CustomerService } from 'app/services/customer.service';
import { FormatService } from 'app/services/format.service';
import { ApiApprovalRequest } from 'app/services/generated/src/main/proto/api/approval-service.pb';
import {
  BinaryType,
  BinaryTypeInfo,
} from 'app/services/generated/src/main/proto/storage/binary-type.pb';
import { LoggerService } from 'app/services/logger.service';
import { PermissionRoles } from 'app/services/permission.service';
import { SourceCodeService } from 'app/services/source-code.service';

const RECORDS_PER_PAGE = 10;
const REFRESH_TIME = 5 * 60 * 1000; // 5 minutes

interface Page {
  continuationToken: string | null;
}

@Component({
  selector: 'app-audit-approval',
  templateUrl: './audit-approval.component.html',
  styleUrls: ['./audit-approval.component.scss'],
})
export class AuditApprovalComponent implements OnInit, OnDestroy {
  allowedBinaryTypes: BinaryType[] | undefined = [];
  allowedRoles = ['PartnerAdmin', 'Approver'];
  binaryTypeInfos: BinaryTypeInfo[] = [];
  customerId: string | undefined;
  hasPermissionToDisplaySourceCode = false;
  selectedBinaryType = 0;
  lastUpdate = this.formatService.getLastUpdate();
  activeId: string | undefined;
  isFetching = false;
  isFetchingAllowedBinaryTypes = false;
  updateInProgress = false;
  isApprover = false;
  totalRecords = 0;
  pageSize = RECORDS_PER_PAGE;
  currentPageIndex = 0;
  refreshInterval: number | undefined;
  pages: Page[] = [];
  approvals: ApiApprovalRequest[] | undefined;
  displaySourceCodeIconFor: string[] = [];

  formGroup: FormGroup = new FormGroup({
    binaryTypeSelect: new FormControl<string>('', []),
  });

  constructor(
    private auth: Auth,
    private approvalService: ApprovalService,
    private binaryTypeService: BinaryTypeService,
    private customerService: CustomerService,
    private formatService: FormatService,
    private loggerService: LoggerService,
    private sourceCodeService: SourceCodeService
  ) {
    this.auth.onAuthStateChanged((user) => {
      if (user) {
        getIdTokenResult(user).then((res) => {
          const {
            claims: { Roles },
          } = res;

          this.hasPermissionToDisplaySourceCode =
            (Roles as string)
              .split(',')
              .find((value: string) => this.allowedRoles.includes(value)) !==
            undefined;

          if ((Roles as string).includes(PermissionRoles.Approver)) {
            this.isApprover = true;
            return;
          }
        });
      } else {
        this.hasPermissionToDisplaySourceCode = false;
        this.isApprover = false;
      }
    });
  }

  get currentPage(): Page | undefined {
    return this.pages[this.currentPageIndex];
  }

  async getAllowedBinaryTypes() {
    this.isFetchingAllowedBinaryTypes = true;

    try {
      const customerResponse = await this.customerService.getCustomer();
      this.allowedBinaryTypes = customerResponse.customer?.allowedBinaryTypes;

      const binaryTypeResponse = await this.binaryTypeService.getBinaryTypes();
      this.binaryTypeInfos = [];
      binaryTypeResponse.binaryTypeInfos?.forEach((binaryType) => {
        if (this.allowedBinaryTypes?.includes(binaryType.binaryType))
          this.binaryTypeInfos.push(binaryType);
      });
    } catch (error) {
      let message = 'Something went wrong while fetching allowed binary types';
      if (error instanceof Error) message = error.message;
      this.loggerService.error(message);
    } finally {
      this.isFetchingAllowedBinaryTypes = false;
    }
  }

  async getApprovals(pageIndex: number, token: string | null = null) {
    this.isFetching = true;
    try {
      const res = await this.approvalService.getApprovals(
        this.pageSize,
        token,
        this.selectedBinaryType,
        [this.customerId!]
      );

      this.approvals = res.approvalRequests ?? [];

      this.approvals.forEach((approval: ApiApprovalRequest) => {
        this.checkForMissingSourceCodeFiles(
          approval.approvalRequestDetail?.change?.binaryInfo?.version,
          approval.approvalRequestDetail?.change?.binaryInfo?.binaryType
        );
      });

      if (!token) {
        // When we fetch the first page, (no continuation token), we receive the
        // total records count.
        this.totalRecords =
          res.paginatedResponse?.count ?? this.approvals.length;
      }

      this.pages[pageIndex] = {
        continuationToken: token,
      };
      if (res.paginatedResponse?.continuationToken) {
        this.pages[pageIndex + 1] = {
          continuationToken: res.paginatedResponse?.continuationToken,
        };
      }
      this.lastUpdate = this.formatService.getLastUpdate();
    } catch (error) {
      return this.loggerService.error('Error getting approvals', error);
    } finally {
      this.isFetching = false;
    }
  }

  async onPageChange(event: PageEvent) {
    const page = this.pages[event.pageIndex];
    await this.getApprovals(event.pageIndex, page.continuationToken);
    this.currentPageIndex = event.pageIndex;
  }

  handleRowToggle = (id: string | undefined) => {
    if (!id) return;
    this.activeId = this.activeId === id ? undefined : id;
  };

  async ngOnInit(): Promise<void> {
    this.getAllowedBinaryTypes();

    this.customerId = (await this.customerService.getCustomer()).customer?.id;

    this.getApprovals(this.currentPageIndex);

    this.refreshInterval = window.setInterval(() => {
      this.getApprovals(
        this.currentPageIndex,
        this.currentPage?.continuationToken
      );
    }, REFRESH_TIME);
  }

  ngOnDestroy(): void {
    this.refreshInterval && window.clearInterval(this.refreshInterval);
  }

  onApprovalUpdate = (approval: ApiApprovalRequest | undefined) => {
    if (!approval || !this.approvals) return;
    this.approvals.splice(
      this.approvals.findIndex(({ id }) => approval.id === id),
      1,
      approval
    );
  };

  setUpdateInProgress = (isUpdating: boolean) => {
    this.updateInProgress = isUpdating;
  };

  filterByBinaryType(binaryType: number) {
    this.selectedBinaryType = binaryType;
    this.getApprovals(this.currentPageIndex);
  }

  getDisplaySourceCodeIconKey(binaryType: BinaryType, version: string) {
    return `${binaryType.toString()}-${version}`;
  }

  checkForMissingSourceCodeFiles(
    version: string | undefined,
    binaryType: BinaryType | undefined
  ) {
    if (version && binaryType) {
      if (this.hasPermissionToDisplaySourceCode) {
        this.sourceCodeService
          .sourceFileExists(binaryType, version)
          .then(() => {
            this.displaySourceCodeIconFor.push(
              this.getDisplaySourceCodeIconKey(binaryType, version)
            );
          })
          .catch(() => {
            this.loggerService.info('File not found, skipping');
          });
      }
    }
  }

  displaySourceCodeIcon(
    version: string | undefined,
    binaryType: BinaryType | undefined
  ) {
    if (version && binaryType) {
      return this.displaySourceCodeIconFor.includes(
        this.getDisplaySourceCodeIconKey(binaryType, version)
      );
    } else {
      return false;
    }
  }
}
