import { Injectable } from '@angular/core';
import { BlockBlobClient } from '@azure/storage-blob';
import { Observable } from 'rxjs';

import { LoggerService } from './logger.service';

/**
 * Service to handle file uploads to blob storage.
 */
@Injectable({
  providedIn: 'root',
})
export class FileUploadService {
  constructor(private logger: LoggerService) {}

  /**
   * Uploads a Uint8Array to blob storage.
   * Warning subscribing to the observable is required.
   *
   * @param data Uint8Array
   * @param sasTokenUrl String
   * @returns Observable<number> Stream of uploaded byte counts.
   */
  uploadToBlobStorage(
    data: Uint8Array,
    sasTokenUrl: string
  ): Observable<number> {
    return new Observable<number>((observer) => {
      const blockBlobClient = new BlockBlobClient(sasTokenUrl);
      const blobUploadCommonResponse = blockBlobClient.uploadData(data, {
        blobHTTPHeaders: { blobContentType: 'application/octet-stream' },
        onProgress: (progress) => {
          this.logger.info(
            `Uploading bytes: ${progress.loadedBytes.toString()}`
          );
          observer.next(progress.loadedBytes);
        },
      });

      blobUploadCommonResponse
        .then(() => {
          observer.complete();
        })
        .catch((error) => {
          observer.error(error);
        });
    });
  }
}
