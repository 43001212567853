import { Component, Input, OnInit } from '@angular/core';
import { FormatService } from 'app/services/format.service';
import { GetPublicKeyResponse } from 'app/services/generated/src/main/proto/api/key-service.pb';
import { KeyService } from 'app/services/key.service';
import dayjs from 'dayjs';

@Component({
  selector: 'app-key-renderer',
  templateUrl: './key-renderer.component.html',
  styleUrls: ['./key-renderer.component.scss'],
})
export class KeyRendererComponent implements OnInit {
  createdAt: string | undefined;
  expiresAt: string | undefined;
  expiresSoonMessage: string | undefined;
  isExpired = false;

  key: GetPublicKeyResponse | undefined;
  isLoading = false;

  @Input() keyId: string | undefined;
  @Input() title: string | undefined;
  @Input() version: string | undefined;

  constructor(
    private keyService: KeyService,
    private FormatService: FormatService
  ) {}
  download() {
    if (!this.key) return;
    const a = document.createElement('a');
    const file = new Blob([this.createPem(this.key.keyDerValue)], {
      type: 'text/plain',
    });
    a.href = URL.createObjectURL(file);
    a.download = `${
      this.title?.toLowerCase()?.replace(/ /g, '-') ?? 'key'
    }.pem`;
    a.click();
  }

  insertNewlines(str: string): string {
    let result = '';
    for (let i = 0; i < str.length; i++) {
      if (i % 64 === 0 && i !== 0) {
        result += '\n';
      }
      result += str[i];
    }
    return result;
  }

  createPem(derValue: string): string {
    return `-----BEGIN PUBLIC KEY-----\n${this.insertNewlines(
      derValue
    )}\n-----END PUBLIC KEY-----`;
  }

  ngOnInit(): void {
    if (!this.keyId) return;
    this.isLoading = true;
    this.keyService
      .getPublicKey(this.keyId, this.version)
      .then((res) => {
        const expiresTime = res.expiresTime
          ? dayjs(res.expiresTime.toDate())
          : undefined;

        const daysLeft = expiresTime?.diff(dayjs(), 'days');

        if (daysLeft) {
          if (daysLeft <= 15) {
            this.expiresSoonMessage = `Expires in ${daysLeft} days`;
          }

          if (daysLeft <= 0) {
            this.expiresSoonMessage = 'Expired';
            this.isExpired = true;
          }
        }

        this.key = res;

        this.createdAt = this.FormatService.formatProtoDateTime(
          res.createdTime
        );
        this.expiresAt = this.FormatService.formatProtoDateTime(
          res.expiresTime
        );
      })
      .catch((error) => console.error(error))
      .finally(() => {
        this.isLoading = false;
      });
  }
}
