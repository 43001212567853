/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import {
  GrpcMessage,
  RecursivePartial,
  ToProtobufJSONOptions,
} from '@ngx-grpc/common';
import { BinaryReader, BinaryWriter, ByteSource } from 'google-protobuf';
import * as googleProtobuf000 from '@ngx-grpc/well-known-types';
import * as api001 from '../../../../src/main/proto/api/headers.pb';
import * as storage002 from '../../../../src/main/proto/storage/binary-type.pb';
/**
 * Message implementation for api.binary_type.GetBinaryTypesRequest
 */
export class GetBinaryTypesRequest implements GrpcMessage {
  static id = 'api.binary_type.GetBinaryTypesRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetBinaryTypesRequest();
    GetBinaryTypesRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetBinaryTypesRequest) {
    _instance.header = _instance.header || undefined;
    _instance.includeDeprecated = _instance.includeDeprecated || false;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetBinaryTypesRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api001.RequestHeader();
          _reader.readMessage(
            _instance.header,
            api001.RequestHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.includeDeprecated = _reader.readBool();
          break;
        default:
          _reader.skipField();
      }
    }

    GetBinaryTypesRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetBinaryTypesRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api001.RequestHeader.serializeBinaryToWriter
      );
    }
    if (_instance.includeDeprecated) {
      _writer.writeBool(2, _instance.includeDeprecated);
    }
  }

  private _header?: api001.RequestHeader;
  private _includeDeprecated: boolean;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetBinaryTypesRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetBinaryTypesRequest.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api001.RequestHeader(_value.header)
      : undefined;
    this.includeDeprecated = _value.includeDeprecated;
    GetBinaryTypesRequest.refineValues(this);
  }
  get header(): api001.RequestHeader | undefined {
    return this._header;
  }
  set header(value: api001.RequestHeader | undefined) {
    this._header = value;
  }
  get includeDeprecated(): boolean {
    return this._includeDeprecated;
  }
  set includeDeprecated(value: boolean) {
    this._includeDeprecated = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetBinaryTypesRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetBinaryTypesRequest.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      includeDeprecated: this.includeDeprecated,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetBinaryTypesRequest.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      includeDeprecated: this.includeDeprecated,
    };
  }
}
export module GetBinaryTypesRequest {
  /**
   * Standard JavaScript object representation for GetBinaryTypesRequest
   */
  export interface AsObject {
    header?: api001.RequestHeader.AsObject;
    includeDeprecated: boolean;
  }

  /**
   * Protobuf JSON representation for GetBinaryTypesRequest
   */
  export interface AsProtobufJSON {
    header: api001.RequestHeader.AsProtobufJSON | null;
    includeDeprecated: boolean;
  }
}

/**
 * Message implementation for api.binary_type.GetBinaryTypesResponse
 */
export class GetBinaryTypesResponse implements GrpcMessage {
  static id = 'api.binary_type.GetBinaryTypesResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetBinaryTypesResponse();
    GetBinaryTypesResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetBinaryTypesResponse) {
    _instance.header = _instance.header || undefined;
    _instance.binaryTypeInfos = _instance.binaryTypeInfos || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetBinaryTypesResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api001.ResponseHeader();
          _reader.readMessage(
            _instance.header,
            api001.ResponseHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          const messageInitializer2 = new storage002.BinaryTypeInfo();
          _reader.readMessage(
            messageInitializer2,
            storage002.BinaryTypeInfo.deserializeBinaryFromReader
          );
          (_instance.binaryTypeInfos = _instance.binaryTypeInfos || []).push(
            messageInitializer2
          );
          break;
        default:
          _reader.skipField();
      }
    }

    GetBinaryTypesResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetBinaryTypesResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api001.ResponseHeader.serializeBinaryToWriter
      );
    }
    if (_instance.binaryTypeInfos && _instance.binaryTypeInfos.length) {
      _writer.writeRepeatedMessage(
        2,
        _instance.binaryTypeInfos as any,
        storage002.BinaryTypeInfo.serializeBinaryToWriter
      );
    }
  }

  private _header?: api001.ResponseHeader;
  private _binaryTypeInfos?: storage002.BinaryTypeInfo[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetBinaryTypesResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetBinaryTypesResponse.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api001.ResponseHeader(_value.header)
      : undefined;
    this.binaryTypeInfos = (_value.binaryTypeInfos || []).map(
      (m) => new storage002.BinaryTypeInfo(m)
    );
    GetBinaryTypesResponse.refineValues(this);
  }
  get header(): api001.ResponseHeader | undefined {
    return this._header;
  }
  set header(value: api001.ResponseHeader | undefined) {
    this._header = value;
  }
  get binaryTypeInfos(): storage002.BinaryTypeInfo[] | undefined {
    return this._binaryTypeInfos;
  }
  set binaryTypeInfos(value: storage002.BinaryTypeInfo[] | undefined) {
    this._binaryTypeInfos = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetBinaryTypesResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetBinaryTypesResponse.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      binaryTypeInfos: (this.binaryTypeInfos || []).map((m) => m.toObject()),
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetBinaryTypesResponse.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      binaryTypeInfos: (this.binaryTypeInfos || []).map((m) =>
        m.toProtobufJSON(options)
      ),
    };
  }
}
export module GetBinaryTypesResponse {
  /**
   * Standard JavaScript object representation for GetBinaryTypesResponse
   */
  export interface AsObject {
    header?: api001.ResponseHeader.AsObject;
    binaryTypeInfos?: storage002.BinaryTypeInfo.AsObject[];
  }

  /**
   * Protobuf JSON representation for GetBinaryTypesResponse
   */
  export interface AsProtobufJSON {
    header: api001.ResponseHeader.AsProtobufJSON | null;
    binaryTypeInfos: storage002.BinaryTypeInfo.AsProtobufJSON[] | null;
  }
}
