<form [formGroup]="form">
  <mat-form-field class="input-full-width">
    <mat-label>Storage Type</mat-label>
    <mat-select
      formControlName="storage_type"
      (selectionChange)="onStorageTypeChange()"
    >
      <mat-option value="aws">AWS S3</mat-option>
      <mat-option value="gcs">Google Cloud Storage</mat-option>
    </mat-select>
  </mat-form-field>

  <app-dataset-picker
    name="Customer"
    [inputCustomerId]="customerId"
    [customerDataSetReference]="customerDataSetReference"
    [finalizedOnly]="false"
    [location]="location"
    (customerDataSetInfo)="receiveCustomerDataSet($event)"
  >
  </app-dataset-picker>
  <mat-checkbox formControlName="isEncrypted"> Data encrypted </mat-checkbox>
  <div *ngIf="form.value.storage_type === 'aws'">
    <mat-form-field class="input-full-width">
      <mat-label>Storage Credentials</mat-label>
      <mat-select formControlName="storage_credential_id">
        <mat-option
          *ngFor="let storage_credential of storage_credentials"
          [value]="storage_credential.id"
        >
          {{ storage_credential.id }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="input-full-width">
      <mat-label>S3 Bucket Name</mat-label>
      <input matInput formControlName="s3_bucket_name" />
    </mat-form-field>
    <mat-form-field class="input-full-width">
      <mat-label>S3 Prefix</mat-label>
      <input matInput formControlName="s3_prefix" />
    </mat-form-field>
    <mat-form-field class="input-full-width">
      <mat-label>key prefix</mat-label>
      <input matInput formControlName="key_prefix" />
    </mat-form-field>
  </div>
  <div *ngIf="form.value.storage_type === 'gcs'">
    <mat-form-field class="input-full-width">
      <mat-label>GCS Bucket Name</mat-label>
      <input matInput formControlName="gcs_bucket_name" />
    </mat-form-field>
    <mat-form-field class="input-full-width">
      <mat-label>GCS Prefix</mat-label>
      <input matInput formControlName="gcs_prefix" />
    </mat-form-field>
    <mat-form-field class="input-full-width">
      <mat-label>key prefix</mat-label>
      <input matInput formControlName="key_prefix" />
    </mat-form-field>
  </div>
</form>
