import { Component, Inject } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GrpcStatusEvent } from '@ngx-grpc/common';
import { FormHelpersService } from 'app/services/form-helpers.service';
import { UserInfo } from 'app/services/generated/src/main/proto/api/user-service.pb';

import { GrpcStatus } from '../../constants/grpc-status';
import { AnonymRoles, CustomerRoles } from '../../constants/lookups';
import { LoggerService } from '../../services/logger.service';
import { UserService } from '../../services/user.service';
import { MessageBoxProvider } from '../../views/shared/components/message-box/message-box.provider';

@Component({
  selector: 'app-add-user-modal',
  templateUrl: './add-user-modal.component.html',
  styleUrls: ['./add-user-modal.component.scss', '../../shared/shared.scss'],
})
export class AddUserModalComponent {
  title: string;
  isLoading = false;
  user: FormGroup;
  update = false;
  action = '';
  isPartnerAdmin = false;

  get roles() {
    return this.data?.isAnonym ? AnonymRoles : CustomerRoles;
  }

  constructor(
    private analytics: AngularFireAnalytics,
    private dialogRef: MatDialogRef<AddUserModalComponent>,
    private formBuilder: FormBuilder,
    private formHelper: FormHelpersService,
    private logger: LoggerService,
    private messageBox: MessageBoxProvider,
    public userService: UserService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      isAdmin: boolean | undefined;
      isAnonym: boolean | undefined;
      tenantId: string | undefined;
      userInfo: UserInfo | undefined;
    }
  ) {
    if (data.userInfo) {
      this.update = true;
      if (data.userInfo.roles.find((role) => role === 'PartnerAdmin')) {
        this.isPartnerAdmin = true;
      }
    }

    if (this.update) {
      this.title = 'Update Team Member';
      this.action = 'update';
    } else {
      this.title = 'Add Team Member';
      this.action = 'create';
    }

    this.user = this.formBuilder.group({
      uid: new FormControl(data.userInfo?.uid, {}),
      name: new FormControl(data.userInfo?.displayName, {
        validators: [Validators.required],
      }),
      email: new FormControl(data.userInfo?.email, {
        validators: [Validators.required],
      }),
      phoneNumber: new FormControl(data.userInfo?.phoneNumber, {}),
      roles: new FormArray([]),
    });

    this.roles.forEach((role) => {
      this.data?.userInfo?.roles.includes(role.value)
        ? (role.selected = true)
        : (role.selected = false);
    });

    const customerTypesArray: FormArray = this.user.get('roles') as FormArray;
    this.roles.forEach((role) => {
      if (role.selected) {
        customerTypesArray.push(new FormControl(role.value));
      }
    });

    this.formHelper.setForm(this.user);
  }

  public checkError(controlName: string, errorName: string) {
    return this.formHelper.checkError(controlName, errorName);
  }

  public close() {
    this.dialogRef.close();
  }

  createUser(userInfo: UserInfo, isAnonym: boolean): void {
    this.isLoading = true;

    this.userService
      .createUser(userInfo, isAnonym)
      .then(() => {
        this.analytics.logEvent('user-created');
        this.user.reset();
        this.messageBox.success(
          'User successfully created with email: ' + userInfo.email
        );
      })
      .catch((error: GrpcStatusEvent | any) => {
        this.handleErrorMessage(error, userInfo);
      })
      .finally(() => (this.isLoading = false));
  }

  sendEmailLink(userInfo: UserInfo) {
    try {
      this.userService.sendEmailSignInLink(userInfo.email, userInfo.tenantId);
      this.analytics.logEvent('invite-email-sent');
    } catch (error: any) {
      this.logger.error(error);
      this.analytics.logEvent('invite-email-failed');
      this.messageBox.error('Failed to send invite email');
    }

    this.messageBox.show(
      `User successfully ${this.action}d with email:  ${userInfo.email}`
    );
  }

  onCheckboxChange(e: Event) {
    const component = <HTMLInputElement>e.target;
    const rolesArray: FormArray = this.user.get('roles') as FormArray;
    if (component.checked) {
      rolesArray.push(new FormControl(component.value));
    } else {
      let i = 0;
      rolesArray.controls.forEach((item: AbstractControl) => {
        if (item.value == component.value) {
          rolesArray.removeAt(i);
          return;
        }
        i++;
      });
    }
  }

  process() {
    const { value } = this.user;
    const isAnonym = Boolean(this.data.isAnonym);
    const user = new UserInfo();
    user.displayName = value.name;
    user.phoneNumber = value.phoneNumber;
    user.email = value.email;
    user.roles = value.roles;

    if (value.uid) {
      user.uid = value.uid;
    }

    if (this.data.tenantId) {
      user.tenantId = this.data.tenantId;
    }

    this.update
      ? this.updateUser(user, isAnonym)
      : this.createUser(user, isAnonym);
  }

  updateUser(userInfo: UserInfo, isAnonym: boolean): void {
    this.isLoading = true;

    // An Admin should not be able to remove their own Admin role.
    if (
      this.isPartnerAdmin &&
      !userInfo.roles.find((role) => role === 'PartnerAdmin')
    ) {
      userInfo.roles.push('PartnerAdmin');
    }

    this.userService
      .updateUser(userInfo, isAnonym)
      .then(() => {
        this.analytics.logEvent('user-updated');
        this.messageBox.success(
          'User successfully updated with email: ' + userInfo.email
        );
        this.user.reset();
        this.close();
      })
      .catch((error: GrpcStatusEvent | any) => {
        this.handleErrorMessage(error, userInfo);
      })
      .finally(() => (this.isLoading = false));
  }

  validatePhoneNumForE164(phoneNum: string) {
    return this.formHelper.validatePhoneNumForE164(phoneNum);
  }

  handleErrorMessage(error: GrpcStatusEvent | any, user: UserInfo) {
    switch (error.statusCode) {
      case GrpcStatus.FAILED_PRECONDITION:
        this.messageBox.show(
          `Failed to ${this.action} user, email domain used is not allowed for this customer: ${user.email}`,
          'error'
        );
        break;
      case GrpcStatus.INVALID_ARGUMENT:
        // This is a validation error, output directly unless we need to format ugly FB error
        if (error.statusMessage?.includes('(EMAIL_EXISTS)')) {
          this.messageBox.show(
            `Failed to ${this.action} user, email already exists: ${user.email}`,
            'error'
          );
        } else if (error.statusMessage?.includes('(PHONE_NUMBER_EXISTS)')) {
          this.messageBox.show(
            `Failed to ${this.action} user, phone number already exists: ${user.phoneNumber}`,
            'error'
          );
        } else if (error.statusMessage?.includes('(INVALID_PHONE_NUMBER)')) {
          this.messageBox.show(
            `Failed to ${this.action} user, invalid phone number: ${user.phoneNumber}`,
            'error'
          );
        } else if (error.statusMessage?.includes('(INVALID_EMAIL)')) {
          this.messageBox.show(
            `Failed to ${this.action} user, invalid email: ${user.email}`,
            'error'
          );
        } else if (error.statusMessage?.includes('(INVALID_DISPLAY_NAME)')) {
          this.messageBox.show(
            `Failed to ${this.action} user, invalid display name: ${user.displayName}`,
            'error'
          );
        } else if (
          error.statusMessage?.includes('(TOO_MANY_ATTEMPTS_TRY_LATER')
        ) {
          this.messageBox.show(
            `Failed to ${this.action} user, too many attempts.`,
            'error'
          );
        } else {
          this.messageBox.show(error.statusMessage, 'error');
        }
        break;
      default:
        this.messageBox.show(
          `Failed to ${this.action} user with email: ${user.email}`,
          'error'
        );
    }
    this.logger.error('Error creating/updating new user', error);
  }
}
