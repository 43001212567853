<ng-container *ngIf="key">
  <div>
    <div class="label">Key Name</div>
    <h2>{{ title ?? 'Unknown Key' }}</h2>
    <hr />
    <div>
      Key created: {{ createdAt
      }}<span *ngIf="expiresAt"> | Key expires {{ expiresAt }}</span>
    </div>
  </div>
  <div class="right-container">
    <div *ngIf="key?.keyVersion" class="badge">
      Version: {{ key.keyVersion }}
    </div>
    <button
      mat-flat-button
      color="primary"
      [disabled]="isExpired"
      (click)="download()"
    >
      Download
    </button>
    <div [ngClass]="{ warning: true, expired: isExpired }">
      {{ expiresSoonMessage }}
    </div>
  </div>
</ng-container>
