import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { AbstractControl, FormArray, FormBuilder } from '@angular/forms';
import { Timestamp } from '@ngx-grpc/well-known-types';
import { CustomerDataService } from 'app/services/customer-data.service';
import { FormatService } from 'app/services/format.service';
import { AdvertiserEventType } from 'app/services/generated/src/main/proto/attribution/advertiser.pb';
import {
  AttributionAlgorithm,
  AttributionConfig,
  AttributionEstimationConfig,
  GroupConfig,
  LastTouchConfig,
  LookbackWindow,
  Metric,
  MetricType,
  PrivacyConfig,
  SensitivityConfig,
  TimeWindow,
} from 'app/services/generated/src/main/proto/attribution/attribution-config.pb';
import { PublisherEventType } from 'app/services/generated/src/main/proto/attribution/publisher.pb';
import { MatchingConfig } from 'app/services/generated/src/main/proto/matching/matching-config.pb';
import { BinaryType } from 'app/services/generated/src/main/proto/storage/binary-type.pb';
import { Location } from 'app/services/generated/src/main/proto/storage/commons.pb';
import { CustomerDataSetReference } from 'app/services/generated/src/main/proto/storage/customer-data-set-reference.pb';
import { BehaviorSubject } from 'rxjs';

import {
  CustomerDataSetInfo,
  DatasetPickerComponent,
} from '../common/dataset-picker/dataset-picker.component';

export interface AttributionInfo {
  attributionConfig: AttributionConfig;
  customerIds: string[];
}

@Component({
  selector: 'app-attribution',
  templateUrl: './attribution.component.html',
  styleUrls: ['./attribution.component.scss'],
})
export class AttributionComponent implements OnChanges {
  @ViewChild(DatasetPickerComponent)
  publisherDatasetPicker!: DatasetPickerComponent;
  @ViewChild(DatasetPickerComponent)
  advertiserDatasetPicker!: DatasetPickerComponent;
  @Output() attributionInfo = new EventEmitter<AttributionInfo>();
  readonly BinaryType = BinaryType;
  @Input() location: Location = Location.LOCATION_UNSPECIFIED;
  @Input() attributionConfig: AttributionConfig | undefined =
    new AttributionConfig();

  pubCustomerId = '';
  pubCustomerDataSetReference: CustomerDataSetReference =
    new CustomerDataSetReference();
  advCustomerId = '';
  advCustomerDataSetReference: CustomerDataSetReference =
    new CustomerDataSetReference();
  metricsDataSource = new BehaviorSubject<AbstractControl[]>([]);
  // Column definitions for the metrics table.
  metricsColumns: string[] = ['type', 'count', 'amount', 'numUnits'];
  algorithmTypes: AttributionAlgorithm[] = [];

  publisherCustomerDataSetInfo: CustomerDataSetInfo = {
    customerId: '',
    customerDataSetReference: new CustomerDataSetReference(),
  };
  advertiserCustomerDataSetInfo: CustomerDataSetInfo = {
    customerId: '',
    customerDataSetReference: new CustomerDataSetReference(),
  };
  metricRows = this.fb.array([
    this.fb.group({
      counts: false,
      amounts: false,
      numUnits: false,
    }),
  ]);

  form = this.fb.group({
    advertiserStartDate: '',
    advertiserEndDate: '',
    publisherStartDate: '',
    publisherEndDate: '',
    matchingColumns: '',
    maxPiiPerImpression: 0,
    publisherEventPriority: '',
    lookbackWindowClicks: 0,
    lookbackWindowEngagedViews: 0,
    lookbackWindowViews: 0,
    attributionEstimationConfigs: this.fb.array([
      this.fb.group({
        algorithm: AttributionAlgorithm.ATTRIBUTION_ALGORITHM_NON_DP,
        rho: '',
        sensitivity: '',
        rhoDivision: '',
      }),
    ]),
    attributionGroups: this.fb.array([
      this.fb.group({
        publisherAttributes: '',
      }),
    ]),
    metrics: this.metricRows,
    enableDebugLogging: false,
  });

  constructor(
    private fb: FormBuilder,
    private customerDataService: CustomerDataService,
    private formatService: FormatService
  ) {
    this.metrics.removeAt(0);
    this.metricsDataSource.next(this.metricRows.controls);

    for (const value in AttributionAlgorithm) {
      if ((parseInt(value) || 0) < 1) {
        // Skip ATTRIBUTION_ALGORITHM_UNSPECIFIED
        continue;
      }
      this.algorithmTypes.push(parseInt(value));
    }
    // Populate the metrics table with one row for every possible event_type.
    for (const value in AdvertiserEventType) {
      if ((parseInt(value) || 0) < 1) {
        // Skip ADVERTISER_EVENT_TYPE_UNSPECIFIED
        continue;
      }
      const row = this.fb.group({
        counts: '',
        amounts: '',
        numUnits: '',
      });
      this.metrics.push(row);
    }
    this.form.valueChanges.subscribe(() => this.emitAttributionInfo());
  }

  receivePublisherCustomerDataSetInfo(data: CustomerDataSetInfo) {
    this.publisherCustomerDataSetInfo = data;
    this.emitAttributionInfo();
  }

  receiveAdvertiserCustomerDataSetInfo(data: CustomerDataSetInfo) {
    this.advertiserCustomerDataSetInfo = data;
    this.emitAttributionInfo();
  }

  get attributionGroups() {
    return this.form.controls['attributionGroups'] as FormArray;
  }

  get attributionEstimationConfigs() {
    return this.form.controls['attributionEstimationConfigs'] as FormArray;
  }

  get metrics() {
    return this.form.controls['metrics'] as FormArray;
  }

  addAttributionEstimationConfig() {
    this.attributionEstimationConfigs.push(
      this.fb.group({
        algorithm: AttributionAlgorithm.ATTRIBUTION_ALGORITHM_NON_DP,
        rho: '',
        sensitivity: '',
        rhoDivision: '',
      })
    );
  }

  addAttributionGroup() {
    this.attributionGroups.push(
      this.fb.group({
        publisherAttributes: '',
      })
    );
  }

  deleteAttributionEstimationConfig(index: number) {
    this.attributionEstimationConfigs.removeAt(index);
  }

  deleteAttributionGroup(index: number) {
    this.attributionGroups.removeAt(index);
  }

  attributionAlgorithmName(index: number) {
    return AttributionAlgorithm[index];
  }

  metricName(index: number) {
    const value = AdvertiserEventType[index];
    return value.substring('ADVERTISER_EVENT_TYPE_'.length);
  }

  toTimestamp(
    timestampField: string | undefined | null
  ): Timestamp | undefined {
    if (!timestampField) {
      return undefined;
    }
    const time_millis = Date.parse(timestampField);
    return new Timestamp({
      seconds: Math.trunc(time_millis / 1000).toString(),
    });
  }

  emitAttributionInfo() {
    this.attributionInfo.emit({
      attributionConfig: this.createAttributionConfig(),
      customerIds: [
        this.publisherCustomerDataSetInfo.customerId,
        this.advertiserCustomerDataSetInfo.customerId,
      ],
    });
  }

  createAttributionConfig(): AttributionConfig {
    const form = this.form.value;
    const advertiserRef =
      this.advertiserCustomerDataSetInfo.customerDataSetReference;
    advertiserRef.startTime = this.toTimestamp(form.advertiserStartDate);
    advertiserRef.endTime = this.toTimestamp(form.advertiserEndDate);
    const publisherRef =
      this.publisherCustomerDataSetInfo.customerDataSetReference;
    publisherRef.startTime = this.toTimestamp(form.publisherStartDate);
    publisherRef.endTime = this.toTimestamp(form.publisherEndDate);
    const attributionConfig = new AttributionConfig({
      advertiserCustomerDataSet: advertiserRef,
      publisherCustomerDataSet: publisherRef,
      timeWindow: new TimeWindow({
        advertiserStartTime: this.toTimestamp(form.advertiserStartDate),
        advertiserEndTime: this.toTimestamp(form.advertiserEndDate),
        publisherStartTime: this.toTimestamp(form.publisherStartDate),
        publisherEndTime: this.toTimestamp(form.publisherEndDate),
      }),
      matchingConfig: new MatchingConfig({
        matchingColumns: form.matchingColumns!.split(',').map((x) => x.trim()),
      }),
      maxPiiPerImpression: form.maxPiiPerImpression!,
      attributionEstimationConfigs: form.attributionEstimationConfigs?.map(
        (value) => {
          return new AttributionEstimationConfig({
            attributionAlgorithm: value.algorithm!,
            privacyConfig: new PrivacyConfig({
              rho: parseFloat(value.rho!),
              rhoDivision: value.rhoDivision
                ? value.rhoDivision!.split(',').map(Number)
                : [],
            }),
            sensitivityConfig: new SensitivityConfig({
              percentile: parseFloat(value.sensitivity!),
            }),
          });
        }
      ),
      enableDebugLogging: form.enableDebugLogging!,
    });

    attributionConfig.lastTouchConfig = new LastTouchConfig({
      lookbackWindow: [
        new LookbackWindow({
          type: PublisherEventType.PUBLISHER_EVENT_TYPE_CLICK,
          durationSeconds: String(form.lookbackWindowClicks! * 86400),
        }),
        new LookbackWindow({
          type: PublisherEventType.PUBLISHER_EVENT_TYPE_ENGAGED_VIEW,
          durationSeconds: String(form.lookbackWindowEngagedViews! * 86400),
        }),
        new LookbackWindow({
          type: PublisherEventType.PUBLISHER_EVENT_TYPE_VIEW,
          durationSeconds: String(form.lookbackWindowViews! * 86400),
        }),
      ],
    });

    form.attributionGroups?.forEach((value) => {
      const groupConfig = new GroupConfig();
      if (value.publisherAttributes) {
        groupConfig.publisherAttributes = value
          .publisherAttributes!.split(',')
          .map((s) => s.trim());
      }
      attributionConfig.groups!.push(groupConfig);
    });

    if (form.metrics) {
      for (let i = 0; i < form.metrics.length; i++) {
        const row = form.metrics[i];
        if (row.counts) {
          attributionConfig.metrics?.push(
            new Metric({
              metricType: MetricType.METRIC_TYPE_COUNT,
              advertiserEventType: i + 1,
            })
          );
        }
        if (row.amounts) {
          attributionConfig.metrics?.push(
            new Metric({
              metricType: MetricType.METRIC_TYPE_AMOUNT,
              advertiserEventType: i + 1,
            })
          );
        }
        if (row.numUnits) {
          attributionConfig.metrics?.push(
            new Metric({
              metricType: MetricType.METRIC_TYPE_NUM_UNITS,
              advertiserEventType: i + 1,
            })
          );
        }
      }
    }

    return attributionConfig;
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes['attributionConfig']) {
      const attributionConfig: AttributionConfig =
        changes['attributionConfig'].currentValue;
      if (!attributionConfig) {
        return;
      }
      const controls = this.form.controls;
      this.attributionEstimationConfigs.clear();
      attributionConfig.attributionEstimationConfigs!.forEach((v) => {
        this.attributionEstimationConfigs.push(
          this.fb.group({
            algorithm: v.attributionAlgorithm!,
            rho: '' + v.privacyConfig!.rho!,
            sensitivity: '' + v.sensitivityConfig!.percentile!,
            rhoDivision: v.privacyConfig?.rhoDivision
              ? v.privacyConfig.rhoDivision.join(',')
              : [],
          })
        );
      });
      attributionConfig.metrics!.forEach((v) => {
        const index = v.advertiserEventType - 1;
        if (v.metricType == MetricType.METRIC_TYPE_COUNT) {
          controls.metrics.at(index).controls.counts.setValue(true);
        }
        if (v.metricType == MetricType.METRIC_TYPE_AMOUNT) {
          controls.metrics.at(index).controls.amounts.setValue(true);
        }
        if (v.metricType == MetricType.METRIC_TYPE_NUM_UNITS) {
          controls.metrics.at(index).controls.numUnits.setValue(true);
        }
      });
      controls.advertiserStartDate.setValue(
        this.formatService.formatProtoDateForInput(
          attributionConfig.timeWindow?.advertiserStartTime
        )
      );
      controls.advertiserEndDate.setValue(
        this.formatService.formatProtoDateForInput(
          attributionConfig.timeWindow?.advertiserEndTime
        )
      );
      controls.publisherStartDate.setValue(
        this.formatService.formatProtoDateForInput(
          attributionConfig.timeWindow?.publisherStartTime
        )
      );
      controls.publisherEndDate.setValue(
        this.formatService.formatProtoDateForInput(
          attributionConfig.timeWindow?.publisherEndTime
        )
      );
      controls.matchingColumns.setValue(
        attributionConfig.matchingConfig!.matchingColumns!.join(',')
      );
      controls.maxPiiPerImpression.setValue(
        attributionConfig.maxPiiPerImpression
      );
      this.attributionGroups.clear();
      attributionConfig.groups!.forEach((v) => {
        this.attributionGroups.push(
          this.fb.group({
            publisherAttributes: v.publisherAttributes.join(','),
          })
        );
      });
      const lookbackWindows =
        attributionConfig.lastTouchConfig?.lookbackWindow ?? [];
      if (lookbackWindows.length > 0) {
        controls.lookbackWindowClicks.setValue(
          parseInt(lookbackWindows[0].durationSeconds) / 86400
        );
      }
      if (lookbackWindows.length > 1) {
        controls.lookbackWindowEngagedViews.setValue(
          parseInt(lookbackWindows[1].durationSeconds) / 86400
        );
      }
      if (lookbackWindows.length > 2) {
        controls.lookbackWindowViews.setValue(
          parseInt(lookbackWindows[2].durationSeconds) / 86400
        );
      }
      controls.enableDebugLogging.setValue(
        attributionConfig.enableDebugLogging!
      );
      const pubResponse = await this.customerDataService.get(
        attributionConfig.publisherCustomerDataSet!.id
      );
      this.pubCustomerId = pubResponse.customerDataSet!.customerId;
      this.pubCustomerDataSetReference =
        attributionConfig.publisherCustomerDataSet!;
      const advResponse = await this.customerDataService.get(
        attributionConfig.advertiserCustomerDataSet!.id
      );
      this.advCustomerId = advResponse.customerDataSet!.customerId;
      this.advCustomerDataSetReference =
        attributionConfig.advertiserCustomerDataSet!;
    }
  }
}
