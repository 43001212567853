<app-container class="background">
  <app-page-header
    title="User Profile"
    lastUpdated="{{ time }}"
  ></app-page-header>

  <app-company-account-card
    [customerData]="customerData"
    title="Customer Account"
    bgColor="#ffff"
  >
  </app-company-account-card>

  <mat-card appearance="outlined">
    <div class="card-header">
      <div>
        <mat-card-header>Personal Information</mat-card-header>
      </div>
      <div>
        <button
          data-testid="updateProfileInfo"
          class="edit-button"
          (click)="updateProfileInfo()"
        >
          <mat-icon color="#000000">edit</mat-icon>
        </button>
      </div>
    </div>

    <img
      mat-card-avatar
      class="mat-card-avatar"
      src="{{ userProfileIcon || 'assets/default-profile.png' }}"
    />

    <mat-card-content>
      <div class="personal-info-card-data">
        <div>
          <p class="field-title">FIRST NAME</p>
          <span data-testid="firstName" class="field-data">{{
            firstName
          }}</span>
        </div>
        <div>
          <p class="field-title">LAST NAME</p>
          <span data-testid="lastName" class="field-data">{{ lastName }}</span>
        </div>
        <div>
          <p class="field-title">EMAIL</p>
          <span data-testid="email" class="field-data">{{ email }}</span>
        </div>
        <div>
          <p class="field-title">ROLE</p>
          <span data-testid="role" class="field-data">{{ roles }}</span>
        </div>
      </div>
    </mat-card-content>
  </mat-card>

  <mat-card appearance="outlined">
    <div class="card-header">
      <div>
        <mat-card-header>Account Information</mat-card-header>
      </div>
      <div>
        <button
          data-testid="updatePassword"
          class="edit-button"
          (click)="updatePassword()"
        >
          <mat-icon color="#000000">edit</mat-icon>
        </button>
      </div>
    </div>

    <mat-card-content>
      <div
        class="account-info-data"
        *ngIf="customerData?.passwordSignInAllowed"
      >
        <p class="password">Password</p>
        <span>*********</span>
      </div>
      <div
        class="account-info-data"
        *ngIf="
          showSamlLinkOptions &&
          !isUserLinkedWithSaml() &&
          customerData?.samlSignInAllowed
        "
      >
        <p class="password">Link account with Saml provider</p>
        <button
          data-testid="linkAccount"
          class="edit-button"
          (click)="linkWithSamlAccount()"
        >
          <mat-icon color="#000000">link</mat-icon>
        </button>
      </div>
      <div
        class="account-info-data"
        *ngIf="
          showSamlLinkOptions &&
          isUserLinkedWithSaml() &&
          customerData?.samlSignInAllowed
        "
      >
        <p class="password">Unlink Saml account provider</p>
        <button
          data-testid="unlinkAccount"
          class="edit-button"
          (click)="unlinkSamlAccount()"
        >
          <mat-icon color="#000000">remove_circle_outline</mat-icon>
        </button>
      </div>
    </mat-card-content>
  </mat-card>

  <mat-card appearance="outlined" *ngIf="customerData?.passwordSignInAllowed">
    <div class="card-header">
      <div>
        <mat-card-header>Multifactor Authentication</mat-card-header>
      </div>
      <div>
        <button
          data-testid="setupMultiFactorAuthentication"
          class="edit-button"
          (click)="setupMultiFactorAuthentication()"
        >
          <mat-icon color="#000000">edit</mat-icon>
        </button>
      </div>
    </div>
    <mat-card-content>
      <div class="account-info-data">
        <p
          class="multi-factor-authentication"
          *ngIf="multiFactorAuthenticationStatus === undefined"
        >
          Two-factor authentication disabled.
        </p>
        <p
          class="multi-factor-authentication"
          *ngIf="multiFactorAuthenticationStatus !== undefined"
        >
          Two-factor authentication enabled with phone number(s):
          {{ multiFactorAuthenticationStatus }}
        </p>
      </div>
    </mat-card-content>
  </mat-card>

  <div class="footer-toolbar">
    <button (click)="deleteUser()" class="delete" data-testid="delete">
      Delete User
    </button>
    <button (click)="signOut()" class="logout" data-testid="logout">
      Logout
    </button>
  </div>
</app-container>
