import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { CustomerDataService } from 'app/services/customer-data.service';
import {
  ListFilter,
  ListRequest,
} from 'app/services/generated/src/main/proto/api/customer-data-set-service.pb';
import { GetPaginatedRequest } from 'app/services/generated/src/main/proto/api/pagination.pb';
import { Location } from 'app/services/generated/src/main/proto/storage/commons.pb';
import { Customer } from 'app/services/generated/src/main/proto/storage/customer.pb';
import { CustomerDataSet } from 'app/services/generated/src/main/proto/storage/customer-data-set.pb';
import { CustomerDataSetReference } from 'app/services/generated/src/main/proto/storage/customer-data-set-reference.pb';

const MAX_RECORDS = 50;

export interface CustomerDataSetInfo {
  customerDataSetReference: CustomerDataSetReference;
  customerId: string;
}

@Component({
  selector: 'app-dataset-picker',
  templateUrl: './dataset-picker.component.html',
  styleUrls: ['./dataset-picker.component.scss'],
})
export class DatasetPickerComponent implements OnChanges {
  datasets: Map<string, CustomerDataSet> = new Map();
  @Input() inputCustomerId = '';
  @Input() customerDataSetReference: CustomerDataSetReference =
    new CustomerDataSetReference();
  @Input() location: Location = Location.LOCATION_UNSPECIFIED;
  @Input() name = '';
  // If set, only allow finalized datasets to be picked.
  @Input() finalizedOnly = true;
  @Output() customerDataSetInfo = new EventEmitter<CustomerDataSetInfo>();
  customers: Map<string, Customer> = new Map();

  customerId = this.fb.control('', {});
  form = this.fb.group({
    customerId: this.customerId,
    datasetId: '',
    version: '',
    partitions: 0,
  });

  constructor(
    private fb: FormBuilder,
    private customerDataService: CustomerDataService
  ) {
    this.customerId.valueChanges.subscribe(() =>
      this.onCustomerIdValueChange()
    );
    this.form.valueChanges.subscribe(() => this.emitCustomerDataSetInfo());
  }

  emitCustomerDataSetInfo() {
    const values = this.form.value;
    if (values.datasetId) {
      this.customerDataSetInfo.emit({
        customerId: values.customerId!,
        customerDataSetReference: new CustomerDataSetReference({
          id: values.datasetId,
          // delete version when no longer used.
          version: values.version ?? '',
          versions: values.version ? values.version.split(',') : [],
          desiredPartitions: values.partitions ?? 0,
        }),
      });
    }
  }

  async onCustomerIdValueChange() {
    this.datasets.clear();
    const listResponse = await this.customerDataService.list(
      new ListRequest({
        paginated: new GetPaginatedRequest({
          numRecords: MAX_RECORDS,
        }),
        filter: new ListFilter({
          customerId: this.customerId.value!,
          location: this.location,
        }),
      })
    );
    if (listResponse.customerDataSets) {
      listResponse.customerDataSets.forEach((dataset) => {
        if (
          this.finalizedOnly &&
          dataset.state != CustomerDataSet.DatasetState.DATASET_STATE_FINALIZED
        ) {
          return;
        }
        this.datasets.set(dataset.id, dataset);
      });
    }
  }

  onCustomerSelect(customer: Customer) {
    if (customer) {
      this.form.controls.customerId.setValue(customer.id);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['customerDataSetReference']) {
      const customerDataSetReference: CustomerDataSetReference =
        changes['customerDataSetReference'].currentValue;
      this.form.controls.datasetId.setValue(customerDataSetReference.id);
      // TODO: Delete version once no longer used.
      if (customerDataSetReference.version) {
        this.form.controls.version.setValue(customerDataSetReference.version);
      }
      // versions should override version if set.
      if (customerDataSetReference.versions) {
        this.form.controls.version.setValue(
          customerDataSetReference.versions.join(',')
        );
      }
      this.form.controls.partitions.setValue(
        customerDataSetReference.desiredPartitions
      );
    }
  }
}
