/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import {
  GrpcMessage,
  RecursivePartial,
  ToProtobufJSONOptions,
} from '@ngx-grpc/common';
import { BinaryReader, BinaryWriter, ByteSource } from 'google-protobuf';
import * as googleProtobuf000 from '@ngx-grpc/well-known-types';
import * as storage001 from '../../../../src/main/proto/storage/schema-annotations.pb';
import * as api002 from '../../../../src/main/proto/api/headers.pb';
import * as api003 from '../../../../src/main/proto/api/pagination.pb';
import * as storage004 from '../../../../src/main/proto/storage/customer-storage-credential.pb';
/**
 * Message implementation for api.customerstoragecredential.CreateRequest
 */
export class CreateRequest implements GrpcMessage {
  static id = 'api.customerstoragecredential.CreateRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new CreateRequest();
    CreateRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: CreateRequest) {
    _instance.header = _instance.header || undefined;
    _instance.customerStorageCredential =
      _instance.customerStorageCredential || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: CreateRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api002.RequestHeader();
          _reader.readMessage(
            _instance.header,
            api002.RequestHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.customerStorageCredential =
            new storage004.CustomerStorageCredential();
          _reader.readMessage(
            _instance.customerStorageCredential,
            storage004.CustomerStorageCredential.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    CreateRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: CreateRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api002.RequestHeader.serializeBinaryToWriter
      );
    }
    if (_instance.customerStorageCredential) {
      _writer.writeMessage(
        2,
        _instance.customerStorageCredential as any,
        storage004.CustomerStorageCredential.serializeBinaryToWriter
      );
    }
  }

  private _header?: api002.RequestHeader;
  private _customerStorageCredential?: storage004.CustomerStorageCredential;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of CreateRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<CreateRequest.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api002.RequestHeader(_value.header)
      : undefined;
    this.customerStorageCredential = _value.customerStorageCredential
      ? new storage004.CustomerStorageCredential(
          _value.customerStorageCredential
        )
      : undefined;
    CreateRequest.refineValues(this);
  }
  get header(): api002.RequestHeader | undefined {
    return this._header;
  }
  set header(value: api002.RequestHeader | undefined) {
    this._header = value;
  }
  get customerStorageCredential():
    | storage004.CustomerStorageCredential
    | undefined {
    return this._customerStorageCredential;
  }
  set customerStorageCredential(
    value: storage004.CustomerStorageCredential | undefined
  ) {
    this._customerStorageCredential = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    CreateRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): CreateRequest.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      customerStorageCredential: this.customerStorageCredential
        ? this.customerStorageCredential.toObject()
        : undefined,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): CreateRequest.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      customerStorageCredential: this.customerStorageCredential
        ? this.customerStorageCredential.toProtobufJSON(options)
        : null,
    };
  }
}
export module CreateRequest {
  /**
   * Standard JavaScript object representation for CreateRequest
   */
  export interface AsObject {
    header?: api002.RequestHeader.AsObject;
    customerStorageCredential?: storage004.CustomerStorageCredential.AsObject;
  }

  /**
   * Protobuf JSON representation for CreateRequest
   */
  export interface AsProtobufJSON {
    header: api002.RequestHeader.AsProtobufJSON | null;
    customerStorageCredential: storage004.CustomerStorageCredential.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for api.customerstoragecredential.CreateResponse
 */
export class CreateResponse implements GrpcMessage {
  static id = 'api.customerstoragecredential.CreateResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new CreateResponse();
    CreateResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: CreateResponse) {
    _instance.header = _instance.header || undefined;
    _instance.customerStorageCredentialId =
      _instance.customerStorageCredentialId || '';
    _instance.etag = _instance.etag || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: CreateResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api002.ResponseHeader();
          _reader.readMessage(
            _instance.header,
            api002.ResponseHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.customerStorageCredentialId = _reader.readString();
          break;
        case 3:
          _instance.etag = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    CreateResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: CreateResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api002.ResponseHeader.serializeBinaryToWriter
      );
    }
    if (_instance.customerStorageCredentialId) {
      _writer.writeString(2, _instance.customerStorageCredentialId);
    }
    if (_instance.etag) {
      _writer.writeString(3, _instance.etag);
    }
  }

  private _header?: api002.ResponseHeader;
  private _customerStorageCredentialId: string;
  private _etag: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of CreateResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<CreateResponse.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api002.ResponseHeader(_value.header)
      : undefined;
    this.customerStorageCredentialId = _value.customerStorageCredentialId;
    this.etag = _value.etag;
    CreateResponse.refineValues(this);
  }
  get header(): api002.ResponseHeader | undefined {
    return this._header;
  }
  set header(value: api002.ResponseHeader | undefined) {
    this._header = value;
  }
  get customerStorageCredentialId(): string {
    return this._customerStorageCredentialId;
  }
  set customerStorageCredentialId(value: string) {
    this._customerStorageCredentialId = value;
  }
  get etag(): string {
    return this._etag;
  }
  set etag(value: string) {
    this._etag = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    CreateResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): CreateResponse.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      customerStorageCredentialId: this.customerStorageCredentialId,
      etag: this.etag,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): CreateResponse.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      customerStorageCredentialId: this.customerStorageCredentialId,
      etag: this.etag,
    };
  }
}
export module CreateResponse {
  /**
   * Standard JavaScript object representation for CreateResponse
   */
  export interface AsObject {
    header?: api002.ResponseHeader.AsObject;
    customerStorageCredentialId: string;
    etag: string;
  }

  /**
   * Protobuf JSON representation for CreateResponse
   */
  export interface AsProtobufJSON {
    header: api002.ResponseHeader.AsProtobufJSON | null;
    customerStorageCredentialId: string;
    etag: string;
  }
}

/**
 * Message implementation for api.customerstoragecredential.GetRequest
 */
export class GetRequest implements GrpcMessage {
  static id = 'api.customerstoragecredential.GetRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetRequest();
    GetRequest.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetRequest) {
    _instance.header = _instance.header || undefined;
    _instance.id = _instance.id || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api002.RequestHeader();
          _reader.readMessage(
            _instance.header,
            api002.RequestHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.id = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    GetRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(_instance: GetRequest, _writer: BinaryWriter) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api002.RequestHeader.serializeBinaryToWriter
      );
    }
    if (_instance.id) {
      _writer.writeString(2, _instance.id);
    }
  }

  private _header?: api002.RequestHeader;
  private _id: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetRequest.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api002.RequestHeader(_value.header)
      : undefined;
    this.id = _value.id;
    GetRequest.refineValues(this);
  }
  get header(): api002.RequestHeader | undefined {
    return this._header;
  }
  set header(value: api002.RequestHeader | undefined) {
    this._header = value;
  }
  get id(): string {
    return this._id;
  }
  set id(value: string) {
    this._id = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetRequest.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      id: this.id,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetRequest.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      id: this.id,
    };
  }
}
export module GetRequest {
  /**
   * Standard JavaScript object representation for GetRequest
   */
  export interface AsObject {
    header?: api002.RequestHeader.AsObject;
    id: string;
  }

  /**
   * Protobuf JSON representation for GetRequest
   */
  export interface AsProtobufJSON {
    header: api002.RequestHeader.AsProtobufJSON | null;
    id: string;
  }
}

/**
 * Message implementation for api.customerstoragecredential.GetResponse
 */
export class GetResponse implements GrpcMessage {
  static id = 'api.customerstoragecredential.GetResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetResponse();
    GetResponse.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetResponse) {
    _instance.header = _instance.header || undefined;
    _instance.customerStorageCredential =
      _instance.customerStorageCredential || undefined;
    _instance.etag = _instance.etag || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api002.ResponseHeader();
          _reader.readMessage(
            _instance.header,
            api002.ResponseHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.customerStorageCredential =
            new storage004.CustomerStorageCredential();
          _reader.readMessage(
            _instance.customerStorageCredential,
            storage004.CustomerStorageCredential.deserializeBinaryFromReader
          );
          break;
        case 3:
          _instance.etag = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    GetResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api002.ResponseHeader.serializeBinaryToWriter
      );
    }
    if (_instance.customerStorageCredential) {
      _writer.writeMessage(
        2,
        _instance.customerStorageCredential as any,
        storage004.CustomerStorageCredential.serializeBinaryToWriter
      );
    }
    if (_instance.etag) {
      _writer.writeString(3, _instance.etag);
    }
  }

  private _header?: api002.ResponseHeader;
  private _customerStorageCredential?: storage004.CustomerStorageCredential;
  private _etag: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetResponse.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api002.ResponseHeader(_value.header)
      : undefined;
    this.customerStorageCredential = _value.customerStorageCredential
      ? new storage004.CustomerStorageCredential(
          _value.customerStorageCredential
        )
      : undefined;
    this.etag = _value.etag;
    GetResponse.refineValues(this);
  }
  get header(): api002.ResponseHeader | undefined {
    return this._header;
  }
  set header(value: api002.ResponseHeader | undefined) {
    this._header = value;
  }
  get customerStorageCredential():
    | storage004.CustomerStorageCredential
    | undefined {
    return this._customerStorageCredential;
  }
  set customerStorageCredential(
    value: storage004.CustomerStorageCredential | undefined
  ) {
    this._customerStorageCredential = value;
  }
  get etag(): string {
    return this._etag;
  }
  set etag(value: string) {
    this._etag = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetResponse.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      customerStorageCredential: this.customerStorageCredential
        ? this.customerStorageCredential.toObject()
        : undefined,
      etag: this.etag,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetResponse.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      customerStorageCredential: this.customerStorageCredential
        ? this.customerStorageCredential.toProtobufJSON(options)
        : null,
      etag: this.etag,
    };
  }
}
export module GetResponse {
  /**
   * Standard JavaScript object representation for GetResponse
   */
  export interface AsObject {
    header?: api002.ResponseHeader.AsObject;
    customerStorageCredential?: storage004.CustomerStorageCredential.AsObject;
    etag: string;
  }

  /**
   * Protobuf JSON representation for GetResponse
   */
  export interface AsProtobufJSON {
    header: api002.ResponseHeader.AsProtobufJSON | null;
    customerStorageCredential: storage004.CustomerStorageCredential.AsProtobufJSON | null;
    etag: string;
  }
}

/**
 * Message implementation for api.customerstoragecredential.ListRequest
 */
export class ListRequest implements GrpcMessage {
  static id = 'api.customerstoragecredential.ListRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ListRequest();
    ListRequest.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ListRequest) {
    _instance.header = _instance.header || undefined;
    _instance.customerId = _instance.customerId || '';
    _instance.paginated = _instance.paginated || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ListRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api002.RequestHeader();
          _reader.readMessage(
            _instance.header,
            api002.RequestHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.customerId = _reader.readString();
          break;
        case 3:
          _instance.paginated = new api003.GetPaginatedRequest();
          _reader.readMessage(
            _instance.paginated,
            api003.GetPaginatedRequest.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    ListRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ListRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api002.RequestHeader.serializeBinaryToWriter
      );
    }
    if (_instance.customerId) {
      _writer.writeString(2, _instance.customerId);
    }
    if (_instance.paginated) {
      _writer.writeMessage(
        3,
        _instance.paginated as any,
        api003.GetPaginatedRequest.serializeBinaryToWriter
      );
    }
  }

  private _header?: api002.RequestHeader;
  private _customerId: string;
  private _paginated?: api003.GetPaginatedRequest;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ListRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<ListRequest.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api002.RequestHeader(_value.header)
      : undefined;
    this.customerId = _value.customerId;
    this.paginated = _value.paginated
      ? new api003.GetPaginatedRequest(_value.paginated)
      : undefined;
    ListRequest.refineValues(this);
  }
  get header(): api002.RequestHeader | undefined {
    return this._header;
  }
  set header(value: api002.RequestHeader | undefined) {
    this._header = value;
  }
  get customerId(): string {
    return this._customerId;
  }
  set customerId(value: string) {
    this._customerId = value;
  }
  get paginated(): api003.GetPaginatedRequest | undefined {
    return this._paginated;
  }
  set paginated(value: api003.GetPaginatedRequest | undefined) {
    this._paginated = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ListRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ListRequest.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      customerId: this.customerId,
      paginated: this.paginated ? this.paginated.toObject() : undefined,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ListRequest.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      customerId: this.customerId,
      paginated: this.paginated ? this.paginated.toProtobufJSON(options) : null,
    };
  }
}
export module ListRequest {
  /**
   * Standard JavaScript object representation for ListRequest
   */
  export interface AsObject {
    header?: api002.RequestHeader.AsObject;
    customerId: string;
    paginated?: api003.GetPaginatedRequest.AsObject;
  }

  /**
   * Protobuf JSON representation for ListRequest
   */
  export interface AsProtobufJSON {
    header: api002.RequestHeader.AsProtobufJSON | null;
    customerId: string;
    paginated: api003.GetPaginatedRequest.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for api.customerstoragecredential.ListResponse
 */
export class ListResponse implements GrpcMessage {
  static id = 'api.customerstoragecredential.ListResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ListResponse();
    ListResponse.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ListResponse) {
    _instance.header = _instance.header || undefined;
    _instance.customerStorageCredentials =
      _instance.customerStorageCredentials || [];
    _instance.paginatedResponse = _instance.paginatedResponse || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ListResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api002.ResponseHeader();
          _reader.readMessage(
            _instance.header,
            api002.ResponseHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          const messageInitializer2 =
            new storage004.CustomerStorageCredential();
          _reader.readMessage(
            messageInitializer2,
            storage004.CustomerStorageCredential.deserializeBinaryFromReader
          );
          (_instance.customerStorageCredentials =
            _instance.customerStorageCredentials || []).push(
            messageInitializer2
          );
          break;
        case 3:
          _instance.paginatedResponse = new api003.PaginatedResponse();
          _reader.readMessage(
            _instance.paginatedResponse,
            api003.PaginatedResponse.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    ListResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ListResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api002.ResponseHeader.serializeBinaryToWriter
      );
    }
    if (
      _instance.customerStorageCredentials &&
      _instance.customerStorageCredentials.length
    ) {
      _writer.writeRepeatedMessage(
        2,
        _instance.customerStorageCredentials as any,
        storage004.CustomerStorageCredential.serializeBinaryToWriter
      );
    }
    if (_instance.paginatedResponse) {
      _writer.writeMessage(
        3,
        _instance.paginatedResponse as any,
        api003.PaginatedResponse.serializeBinaryToWriter
      );
    }
  }

  private _header?: api002.ResponseHeader;
  private _customerStorageCredentials?: storage004.CustomerStorageCredential[];
  private _paginatedResponse?: api003.PaginatedResponse;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ListResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<ListResponse.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api002.ResponseHeader(_value.header)
      : undefined;
    this.customerStorageCredentials = (
      _value.customerStorageCredentials || []
    ).map((m) => new storage004.CustomerStorageCredential(m));
    this.paginatedResponse = _value.paginatedResponse
      ? new api003.PaginatedResponse(_value.paginatedResponse)
      : undefined;
    ListResponse.refineValues(this);
  }
  get header(): api002.ResponseHeader | undefined {
    return this._header;
  }
  set header(value: api002.ResponseHeader | undefined) {
    this._header = value;
  }
  get customerStorageCredentials():
    | storage004.CustomerStorageCredential[]
    | undefined {
    return this._customerStorageCredentials;
  }
  set customerStorageCredentials(
    value: storage004.CustomerStorageCredential[] | undefined
  ) {
    this._customerStorageCredentials = value;
  }
  get paginatedResponse(): api003.PaginatedResponse | undefined {
    return this._paginatedResponse;
  }
  set paginatedResponse(value: api003.PaginatedResponse | undefined) {
    this._paginatedResponse = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ListResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ListResponse.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      customerStorageCredentials: (this.customerStorageCredentials || []).map(
        (m) => m.toObject()
      ),
      paginatedResponse: this.paginatedResponse
        ? this.paginatedResponse.toObject()
        : undefined,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ListResponse.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      customerStorageCredentials: (this.customerStorageCredentials || []).map(
        (m) => m.toProtobufJSON(options)
      ),
      paginatedResponse: this.paginatedResponse
        ? this.paginatedResponse.toProtobufJSON(options)
        : null,
    };
  }
}
export module ListResponse {
  /**
   * Standard JavaScript object representation for ListResponse
   */
  export interface AsObject {
    header?: api002.ResponseHeader.AsObject;
    customerStorageCredentials?: storage004.CustomerStorageCredential.AsObject[];
    paginatedResponse?: api003.PaginatedResponse.AsObject;
  }

  /**
   * Protobuf JSON representation for ListResponse
   */
  export interface AsProtobufJSON {
    header: api002.ResponseHeader.AsProtobufJSON | null;
    customerStorageCredentials:
      | storage004.CustomerStorageCredential.AsProtobufJSON[]
      | null;
    paginatedResponse: api003.PaginatedResponse.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for api.customerstoragecredential.ArchiveRequest
 */
export class ArchiveRequest implements GrpcMessage {
  static id = 'api.customerstoragecredential.ArchiveRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ArchiveRequest();
    ArchiveRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ArchiveRequest) {
    _instance.header = _instance.header || undefined;
    _instance.id = _instance.id || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ArchiveRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api002.RequestHeader();
          _reader.readMessage(
            _instance.header,
            api002.RequestHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.id = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    ArchiveRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ArchiveRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api002.RequestHeader.serializeBinaryToWriter
      );
    }
    if (_instance.id) {
      _writer.writeString(2, _instance.id);
    }
  }

  private _header?: api002.RequestHeader;
  private _id: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ArchiveRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<ArchiveRequest.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api002.RequestHeader(_value.header)
      : undefined;
    this.id = _value.id;
    ArchiveRequest.refineValues(this);
  }
  get header(): api002.RequestHeader | undefined {
    return this._header;
  }
  set header(value: api002.RequestHeader | undefined) {
    this._header = value;
  }
  get id(): string {
    return this._id;
  }
  set id(value: string) {
    this._id = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ArchiveRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ArchiveRequest.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      id: this.id,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ArchiveRequest.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      id: this.id,
    };
  }
}
export module ArchiveRequest {
  /**
   * Standard JavaScript object representation for ArchiveRequest
   */
  export interface AsObject {
    header?: api002.RequestHeader.AsObject;
    id: string;
  }

  /**
   * Protobuf JSON representation for ArchiveRequest
   */
  export interface AsProtobufJSON {
    header: api002.RequestHeader.AsProtobufJSON | null;
    id: string;
  }
}

/**
 * Message implementation for api.customerstoragecredential.ArchiveResponse
 */
export class ArchiveResponse implements GrpcMessage {
  static id = 'api.customerstoragecredential.ArchiveResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ArchiveResponse();
    ArchiveResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ArchiveResponse) {
    _instance.header = _instance.header || undefined;
    _instance.etag = _instance.etag || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ArchiveResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api002.ResponseHeader();
          _reader.readMessage(
            _instance.header,
            api002.ResponseHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.etag = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    ArchiveResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ArchiveResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api002.ResponseHeader.serializeBinaryToWriter
      );
    }
    if (_instance.etag) {
      _writer.writeString(2, _instance.etag);
    }
  }

  private _header?: api002.ResponseHeader;
  private _etag: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ArchiveResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<ArchiveResponse.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api002.ResponseHeader(_value.header)
      : undefined;
    this.etag = _value.etag;
    ArchiveResponse.refineValues(this);
  }
  get header(): api002.ResponseHeader | undefined {
    return this._header;
  }
  set header(value: api002.ResponseHeader | undefined) {
    this._header = value;
  }
  get etag(): string {
    return this._etag;
  }
  set etag(value: string) {
    this._etag = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ArchiveResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ArchiveResponse.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      etag: this.etag,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ArchiveResponse.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      etag: this.etag,
    };
  }
}
export module ArchiveResponse {
  /**
   * Standard JavaScript object representation for ArchiveResponse
   */
  export interface AsObject {
    header?: api002.ResponseHeader.AsObject;
    etag: string;
  }

  /**
   * Protobuf JSON representation for ArchiveResponse
   */
  export interface AsProtobufJSON {
    header: api002.ResponseHeader.AsProtobufJSON | null;
    etag: string;
  }
}

/**
 * Message implementation for api.customerstoragecredential.UpdateRequest
 */
export class UpdateRequest implements GrpcMessage {
  static id = 'api.customerstoragecredential.UpdateRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new UpdateRequest();
    UpdateRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: UpdateRequest) {
    _instance.header = _instance.header || undefined;
    _instance.customerStorageCredential =
      _instance.customerStorageCredential || undefined;
    _instance.etag = _instance.etag || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: UpdateRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api002.RequestHeader();
          _reader.readMessage(
            _instance.header,
            api002.RequestHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.customerStorageCredential =
            new storage004.CustomerStorageCredential();
          _reader.readMessage(
            _instance.customerStorageCredential,
            storage004.CustomerStorageCredential.deserializeBinaryFromReader
          );
          break;
        case 3:
          _instance.etag = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    UpdateRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: UpdateRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api002.RequestHeader.serializeBinaryToWriter
      );
    }
    if (_instance.customerStorageCredential) {
      _writer.writeMessage(
        2,
        _instance.customerStorageCredential as any,
        storage004.CustomerStorageCredential.serializeBinaryToWriter
      );
    }
    if (_instance.etag) {
      _writer.writeString(3, _instance.etag);
    }
  }

  private _header?: api002.RequestHeader;
  private _customerStorageCredential?: storage004.CustomerStorageCredential;
  private _etag: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of UpdateRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<UpdateRequest.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api002.RequestHeader(_value.header)
      : undefined;
    this.customerStorageCredential = _value.customerStorageCredential
      ? new storage004.CustomerStorageCredential(
          _value.customerStorageCredential
        )
      : undefined;
    this.etag = _value.etag;
    UpdateRequest.refineValues(this);
  }
  get header(): api002.RequestHeader | undefined {
    return this._header;
  }
  set header(value: api002.RequestHeader | undefined) {
    this._header = value;
  }
  get customerStorageCredential():
    | storage004.CustomerStorageCredential
    | undefined {
    return this._customerStorageCredential;
  }
  set customerStorageCredential(
    value: storage004.CustomerStorageCredential | undefined
  ) {
    this._customerStorageCredential = value;
  }
  get etag(): string {
    return this._etag;
  }
  set etag(value: string) {
    this._etag = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    UpdateRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): UpdateRequest.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      customerStorageCredential: this.customerStorageCredential
        ? this.customerStorageCredential.toObject()
        : undefined,
      etag: this.etag,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): UpdateRequest.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      customerStorageCredential: this.customerStorageCredential
        ? this.customerStorageCredential.toProtobufJSON(options)
        : null,
      etag: this.etag,
    };
  }
}
export module UpdateRequest {
  /**
   * Standard JavaScript object representation for UpdateRequest
   */
  export interface AsObject {
    header?: api002.RequestHeader.AsObject;
    customerStorageCredential?: storage004.CustomerStorageCredential.AsObject;
    etag: string;
  }

  /**
   * Protobuf JSON representation for UpdateRequest
   */
  export interface AsProtobufJSON {
    header: api002.RequestHeader.AsProtobufJSON | null;
    customerStorageCredential: storage004.CustomerStorageCredential.AsProtobufJSON | null;
    etag: string;
  }
}

/**
 * Message implementation for api.customerstoragecredential.UpdateResponse
 */
export class UpdateResponse implements GrpcMessage {
  static id = 'api.customerstoragecredential.UpdateResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new UpdateResponse();
    UpdateResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: UpdateResponse) {
    _instance.header = _instance.header || undefined;
    _instance.etag = _instance.etag || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: UpdateResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api002.ResponseHeader();
          _reader.readMessage(
            _instance.header,
            api002.ResponseHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.etag = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    UpdateResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: UpdateResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api002.ResponseHeader.serializeBinaryToWriter
      );
    }
    if (_instance.etag) {
      _writer.writeString(2, _instance.etag);
    }
  }

  private _header?: api002.ResponseHeader;
  private _etag: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of UpdateResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<UpdateResponse.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api002.ResponseHeader(_value.header)
      : undefined;
    this.etag = _value.etag;
    UpdateResponse.refineValues(this);
  }
  get header(): api002.ResponseHeader | undefined {
    return this._header;
  }
  set header(value: api002.ResponseHeader | undefined) {
    this._header = value;
  }
  get etag(): string {
    return this._etag;
  }
  set etag(value: string) {
    this._etag = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    UpdateResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): UpdateResponse.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      etag: this.etag,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): UpdateResponse.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      etag: this.etag,
    };
  }
}
export module UpdateResponse {
  /**
   * Standard JavaScript object representation for UpdateResponse
   */
  export interface AsObject {
    header?: api002.ResponseHeader.AsObject;
    etag: string;
  }

  /**
   * Protobuf JSON representation for UpdateResponse
   */
  export interface AsProtobufJSON {
    header: api002.ResponseHeader.AsProtobufJSON | null;
    etag: string;
  }
}
