import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AppRoutingModule } from 'app/routing/app-routing.module';
import { DocumentationPageComponent } from 'app/views/documentation-page/documentation-page.component';
import { DocumentationViewComponent } from 'app/views/documentation-view/documentation-view.component';
import { ContainerComponent } from 'app/views/shared/components/container/container.component';
import { PageHeaderComponent } from 'app/views/shared/components/page-header/page-header.component';
import { Highlight, HighlightAuto } from 'ngx-highlightjs';

import { MessageBoxComponent } from '../../views/shared/components/message-box/message-box.component';

@NgModule({
  declarations: [DocumentationPageComponent, DocumentationViewComponent],
  imports: [
    CommonModule,
    ContainerComponent,
    PageHeaderComponent,
    AppRoutingModule,
    Highlight,
    HighlightAuto,
    MatButtonModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatSortModule,
    MatTableModule,
    MatToolbarModule,
    MatTooltipModule,
    MessageBoxComponent,
  ],
})
export class DocumentationModule {}
