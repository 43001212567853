import { Injectable } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';

/**
 * Enumeration for different types of events that can occur.
 */
export enum Events {
  UserProfileChangePicture = 'user-profile-change-picture',
  UserProfileChange = 'user-profile-change',
  NotificationReceived = 'notification-received',
}

/**
 * Class representing event data.
 */
export class EventData {
  eventName: Events;
  message: any;

  constructor(eventName: Events, message: any) {
    this.eventName = eventName;
    this.message = message;
  }
}

/**
 * Service to manage event bus for emitting and subscribing to events.
 */
@Injectable({
  providedIn: 'root',
})
export class EventBusService {
  private subject = new Subject<any>();

  /**
   * Emits an event with the given data.
   *
   * @param ev - The event data to emit.
   */
  emit(ev: EventData) {
    this.subject.next(ev);
  }

  /**
   * Subscribes to a specific event and listens for messages associated with it.
   *
   * @param eventName - The name of the event to subscribe to.
   * @param message - The callback function to handle the event message.
   * @returns A Subscription object for managing the subscription.
   */
  on(eventName: Events, message: any): Subscription {
    return this.subject
      .pipe(
        filter((ev: EventData) => ev.eventName === eventName),
        map((ev: EventData) => ev['message'])
      )
      .subscribe(message);
  }
}
