/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import {
  GrpcMessage,
  RecursivePartial,
  ToProtobufJSONOptions,
} from '@ngx-grpc/common';
import { BinaryReader, BinaryWriter, ByteSource } from 'google-protobuf';

/**
 * Message implementation for api.GetPaginatedRequest
 */
export class GetPaginatedRequest implements GrpcMessage {
  static id = 'api.GetPaginatedRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetPaginatedRequest();
    GetPaginatedRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetPaginatedRequest) {
    _instance.numRecords = _instance.numRecords || 0;
    _instance.continuationToken = _instance.continuationToken || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetPaginatedRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.numRecords = _reader.readInt32();
          break;
        case 2:
          _instance.continuationToken = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    GetPaginatedRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetPaginatedRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.numRecords) {
      _writer.writeInt32(1, _instance.numRecords);
    }
    if (_instance.continuationToken) {
      _writer.writeString(2, _instance.continuationToken);
    }
  }

  private _numRecords: number;
  private _continuationToken: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetPaginatedRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetPaginatedRequest.AsObject>) {
    _value = _value || {};
    this.numRecords = _value.numRecords;
    this.continuationToken = _value.continuationToken;
    GetPaginatedRequest.refineValues(this);
  }
  get numRecords(): number {
    return this._numRecords;
  }
  set numRecords(value: number) {
    this._numRecords = value;
  }
  get continuationToken(): string {
    return this._continuationToken;
  }
  set continuationToken(value: string) {
    this._continuationToken = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetPaginatedRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetPaginatedRequest.AsObject {
    return {
      numRecords: this.numRecords,
      continuationToken: this.continuationToken,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetPaginatedRequest.AsProtobufJSON {
    return {
      numRecords: this.numRecords,
      continuationToken: this.continuationToken,
    };
  }
}
export module GetPaginatedRequest {
  /**
   * Standard JavaScript object representation for GetPaginatedRequest
   */
  export interface AsObject {
    numRecords: number;
    continuationToken: string;
  }

  /**
   * Protobuf JSON representation for GetPaginatedRequest
   */
  export interface AsProtobufJSON {
    numRecords: number;
    continuationToken: string;
  }
}

/**
 * Message implementation for api.PaginatedResponse
 */
export class PaginatedResponse implements GrpcMessage {
  static id = 'api.PaginatedResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new PaginatedResponse();
    PaginatedResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: PaginatedResponse) {
    _instance.continuationToken = _instance.continuationToken || '';
    _instance.count = _instance.count || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: PaginatedResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.continuationToken = _reader.readString();
          break;
        case 2:
          _instance.count = _reader.readInt32();
          break;
        default:
          _reader.skipField();
      }
    }

    PaginatedResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: PaginatedResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.continuationToken) {
      _writer.writeString(1, _instance.continuationToken);
    }
    if (_instance.count) {
      _writer.writeInt32(2, _instance.count);
    }
  }

  private _continuationToken: string;
  private _count: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of PaginatedResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<PaginatedResponse.AsObject>) {
    _value = _value || {};
    this.continuationToken = _value.continuationToken;
    this.count = _value.count;
    PaginatedResponse.refineValues(this);
  }
  get continuationToken(): string {
    return this._continuationToken;
  }
  set continuationToken(value: string) {
    this._continuationToken = value;
  }
  get count(): number {
    return this._count;
  }
  set count(value: number) {
    this._count = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    PaginatedResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): PaginatedResponse.AsObject {
    return {
      continuationToken: this.continuationToken,
      count: this.count,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): PaginatedResponse.AsProtobufJSON {
    return {
      continuationToken: this.continuationToken,
      count: this.count,
    };
  }
}
export module PaginatedResponse {
  /**
   * Standard JavaScript object representation for PaginatedResponse
   */
  export interface AsObject {
    continuationToken: string;
    count: number;
  }

  /**
   * Protobuf JSON representation for PaginatedResponse
   */
  export interface AsProtobufJSON {
    continuationToken: string;
    count: number;
  }
}
