<div class="background">
  <div class="card">
    <app-anonym-logo></app-anonym-logo>
    <hr class="card-separator" />
    <div class="body">
      <h1>Create A Password</h1>

      <form
        ngNativeValidate
        (ngSubmit)="confirmNewPassword()"
        [formGroup]="newPasswordForm"
      >
        <div class="form-content">
          <span class="user-info-title">User Information</span>

          <hr class="user-info-separator" />

          <div class="user-info">
            <div>
              <label>First Name:</label>
              <p>{{ userData.firstName }}</p>
            </div>

            <div>
              <label>Last Name:</label>
              <p>{{ userData.lastName }}</p>
            </div>

            <div>
              <label>Email:</label>
              <p>{{ userData.email }}</p>
            </div>
          </div>
        </div>

        <div class="form-inputs-container">
          <div>
            <label class="input-label">Password</label>
            <input
              type="password"
              placeholder="Type in your new password"
              formControlName="password"
              required
              [disabled]="codeExpired"
              [ngClass]="{
                'app-input-error': checkError('password', 'required'),
              }"
              class="app-input"
              data-testid="passInput"
            />
            <mat-error *ngIf="checkError('password', 'required')"
              >Password is required</mat-error
            >
          </div>

          <div>
            <label class="input-label">Confirmation Password</label>
            <input
              type="password"
              placeholder="Confirm your new password"
              formControlName="confirmation"
              required
              [disabled]="codeExpired"
              [ngClass]="{
                'app-input-error': checkError('confirmation', 'required'),
              }"
              class="app-input"
              data-testid="confirmationInput"
            />
            <mat-error *ngIf="checkError('confirmation', 'required')"
              >Confirmation Password is required</mat-error
            >
            <mat-error *ngIf="checkError('confirmation', 'notMatch')"
              >Please make sure your passwords match</mat-error
            >
          </div>
        </div>

        <app-message-box> </app-message-box>

        <button
          type="submit"
          color="primary"
          mat-flat-button
          [disabled]="isLoading || codeExpired"
          data-testid="createAccountBtn"
        >
          {{ isLoading ? null : 'Create Account' }}
          <span *ngIf="isLoading" class="spinner">
            <mat-spinner diameter="24"></mat-spinner>
          </span>
        </button>
      </form>

      <div class="footer-info">
        <p>
          Already have an account?
          <a class="link" href="/sign-in">Log in here.</a>
        </p>
      </div>
    </div>
  </div>
</div>
