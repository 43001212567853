import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { BinaryTypeService } from 'app/services/binary-type.service';
import {
  BinaryType,
  BinaryTypeInfo,
} from 'app/services/generated/src/main/proto/storage/binary-type.pb';

@Component({
  selector: 'app-binary-type-dropdown',
  templateUrl: './binary-type-dropdown.component.html',
  styleUrls: ['./binary-type-dropdown.component.scss'],
})
export class BinaryTypeDropdownComponent implements OnInit, OnChanges {
  @Input() selectedBinaryType: BinaryType | undefined;
  @Input() displayBinaryDescription: boolean = false;
  @Output() selectBinaryTypeEvent = new EventEmitter<BinaryType>();
  binaryTypesInfo: BinaryTypeInfo[] = [];
  binaryTypeUnspecified = BinaryType.BINARY_TYPE_UNSPECIFIED;
  currentBinaryTypeLabel: string | undefined;

  constructor(private binaryTypeService: BinaryTypeService) {}

  async ngOnInit() {
    this.binaryTypesInfo = [];
    const binaryTypes = await this.binaryTypeService.getBinaryTypes();

    if (binaryTypes.binaryTypeInfos) {
      this.binaryTypesInfo = binaryTypes.binaryTypeInfos;
    }
    if (this.selectedBinaryType) {
      this.onBinaryTypeSelect(this.selectedBinaryType);
    }
  }

  ngOnChanges(): void {
    this.ngOnInit();
  }

  onBinaryTypeSelect(binaryType: BinaryType) {
    this.selectedBinaryType = binaryType;
    this.currentBinaryTypeLabel = this.binaryTypesInfo.find(
      (binaryTypeInfo) => binaryTypeInfo.binaryType === binaryType
    )?.description;
    this.selectBinaryTypeEvent.emit(this.selectedBinaryType);
  }
}
