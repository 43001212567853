import { Injectable } from '@angular/core';
import { FirebaseApp } from '@angular/fire/app';
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from '@angular/fire/compat/firestore';
import { getDownloadURL, ref } from '@angular/fire/storage';
import { getStorage } from 'firebase/storage';

import { DocumentType } from '../../../src/types/document';
import { BinaryTypeService } from './binary-type.service';
import { BinaryType } from './generated/src/main/proto/storage/binary-type.pb';
import { LoggerService } from './logger.service';

/**
 * Service to manage documents, including fetching, caching, and retrieving document links.
 */
@Injectable({
  providedIn: 'root',
})
export class DocumentService {
  docsRef: AngularFirestoreCollection<DocumentType>;

  constructor(
    public firebaseApp: FirebaseApp,
    private logger: LoggerService,
    public afs: AngularFirestore,
    public binaryTypeService: BinaryTypeService
  ) {
    this.docsRef = this.afs.collection('docs');
  }

  /**
   * Retrieves a signed URL for a specified path.
   *
   * @param path - The path to retrieve the signed URL for.
   * @returns A promise that resolves to the signed URL.
   */
  public getSignedURL(path: string) {
    return getDownloadURL(ref(getStorage(), path));
  }

  /**
   * Retrieves the Firestore collection reference for documents.
   *
   * @returns The AngularFirestoreCollection for documents.
   */
  public getDocuments() {
    return this.docsRef;
  }

  /**
   * Retrieves a cached value from local storage for a specified path.
   *
   * @param path - The path to retrieve the cached value for.
   * @returns The cached value as a string, or null if not found.
   */
  public getCache(path: string) {
    return localStorage.getItem(path);
  }

  /**
   * Sets a cached value in local storage for a specified path.
   *
   * @param path - The path to set the cached value for.
   * @param signedUrl - The signed URL to cache.
   */
  public setCache(path: string, signedUrl: string) {
    localStorage.setItem(path, signedUrl);
  }

  /**
   * Retrieves the documentation link for a specified binary type and release version.
   *
   * @param binaryType - The binary type to filter documents by.
   * @param release - The release version to filter documents by.
   * @returns An observable of the document data matching the specified criteria.
   */
  public getBinaryDocumentationLink(binaryType: BinaryType, release: string) {
    return this.afs
      .collection('docs', (ref) =>
        ref
          .where('binary_type', '==', binaryType)
          .where('release', '==', release)
          .where('docType', '==', 'html')
      )
      .valueChanges();
  }
}
