<div class="background">
  <div class="card">
    <app-anonym-logo [showWelcomeMessage]="true"></app-anonym-logo>
    <hr />
    <div *ngIf="!isInviteValid">
      <div class="body-title">
        <h1>Your invite is invalid or expired.</h1>
        <p>Your email invite link has already been used or is expired.</p>
        <p>
          Please reset your password <a href="./forgot-password">here</a>, or
          <a href="./sign-in">sign in</a> if you have a password.
        </p>
      </div>
    </div>
    <div *ngIf="isInviteValid">
      <div class="body-title">
        <h1>Verify Your Account</h1>
        <p>
          After entering your email below you will be prompted to set your
          password.
        </p>
      </div>

      <form ngNativeValidate (ngSubmit)="confirm()" [formGroup]="emailForm">
        <div class="input-container">
          <label class="form-label">Email:</label>
          <input
            class="app-input"
            type="email"
            tabindex="1"
            placeholder="Please enter your email"
            formControlName="email"
            required
          />
        </div>
        <mat-error *ngIf="checkError('email', 'required')"
          >Email is required.</mat-error
        >
        <div class="button">
          <button type="submit" color="primary" mat-flat-button>Confirm</button>
        </div>
      </form>
    </div>
    <app-message-box></app-message-box>
  </div>
</div>
