// TODO. We might want to move this to GCP

export const STEPS = [
  {
    id: '4ad942b5-ce94-4aa4-b8c2-f822d5512ea4',
    title: 'Review System Documentation',
    description:
      "The Knowledge Base section of the Transparency Portal contains extensive documentation of Anonym's infrastructure, processes and methodologies. We encourage you to learn more about our approach to confidential computing. Please reach out with any questions.",
  },
  {
    id: 'a7791b71-19dc-4924-a50e-5dbfd43b01a6',
    title: 'Review Binary Documentation',
    description:
      'With an understanding of our system in place, partners can now review the binaries that enable data to be processed for a specific use case. This documentation includes the algorithms used to support the use case (e.g. measurement) as well as the privacy mechanisms (e.g. differential privacy) used to anonymize the outputs.',
  },
  {
    id: '72ee273b-233d-4b6e-8c04-b8a04e980782',
    title: 'Approve Binary',
    description:
      'Anonym partners who chose to participate in binary approval must do so before Anonym is able to process data. Binary approval takes place in the <a href="/audit-approval">Your Binaries</a> section. Within each binary panel, we provide access to the cryptographic signatures used in the attestation process as well as access to the binary\'s source code.',
  },
];

export const TODOS = [
  {
    id: '3cf6c046-b397-47e4-812f-fa8c85af7f00',
    label:
      '<a href="/documentation-view?get=docs%2Fweb%2Farchitecture%2Flatest%2Findex.html#attestation-process">Architecture</a>: Start by reviewing our system\'s architecture to develop an understanding of Anonym\'s approach privacy-safe computation. In this section, we describe how we <a href="/documentation-view?get=docs%2Fweb%2Farchitecture%2Flatest%2Findex.html#data-storage">store data</a> and <a href="/documentation-view?get=docs%2Fweb%2Farchitecture%2Flatest%2Findex.html#user-data-handling">handle user data</a> as well as our <a href="/documentation-view?get=docs%2Fweb%2Farchitecture%2Flatest%2Findex.html#data-encryption">retention policy</a>.',
    parentId: '4ad942b5-ce94-4aa4-b8c2-f822d5512ea4',
  },
  {
    id: '75288658-aec4-45a8-8dd0-3a32b1a5250c',
    label:
      '<a href="/documentation-view?get=docs%2Fweb%2Farchitecture%2Flatest%2Findex.html#document-includes/tee">Trusted Execution Environment</a>: Anonym uses Trusted Execution Environments (TEEs) to ensure data is processed in a fully encrypted computing environment. In this section, we describe how we <a href="/documentation-view?get=docs%2Fweb%2Farchitecture%2Flatest%2Findex.html#document-includes/tee">create enclaves</a>, decrypt data and do computation within them. We also document our approach to parallel computing using <a href="/documentation-view?get=docs%2Fweb%2Farchitecture%2Flatest%2Findex.html#document-includes/tee">multiple enclaves</a> for a computing job.',
    parentId: '4ad942b5-ce94-4aa4-b8c2-f822d5512ea4',
  },
  {
    id: '91e9d340-3627-11ee-be56-0242ac120002',
    label:
      '<a href="/documentation-view?get=docs%2Fweb%2Farchitecture%2Flatest%2Findex.html#document-includes/code_review">Code Review & Approval</a>: To ensure our customers have full transparency and control over how we process their data, we provide customers with the ability to <a href="/documentation-view?get=docs%2Fweb%2Farchitecture%2Flatest%2Findex.html#document-includes/code_review">review our code</a> and approve binaries. Approving a binary <a href="docs%2Fweb%2Farchitecture%2Flatest%2Findex.html#document-includes/attestation">creates an attestation policy</a>, which will be used in the next step.',
    parentId: '4ad942b5-ce94-4aa4-b8c2-f822d5512ea4',
  },
  {
    id: 'dc8f9d06-363d-11ee-be56-0242ac120002',
    label:
      '<a href="/documentation-view?get=docs%2Fweb%2Farchitecture%2Flatest%2Findex.html#document-includes/attestation">Attestation Process</a>: During the attestation process, the TEE must prove that it\'s running a binary that was previously approved and has a corresponding attestation policy. If attestation is successful, the TEE is able to access the encryption key needed to decrypt data so it can be processed.',
    parentId: '4ad942b5-ce94-4aa4-b8c2-f822d5512ea4',
  },
  {
    id: 'd0450100-3627-11ee-be56-0242ac120002',
    label:
      'We also provide documentation of our <a href="documentation-view?get=docs%2Fweb%2Fsecurity%2Flatest%2Findex.html">security practices</a> and an overview of the third party security audit that Anonym commissioned.',
    parentId: '4ad942b5-ce94-4aa4-b8c2-f822d5512ea4',
  },
];
