<div class="field-dropdown-label">
  <span class="field-title">{{ title }}</span>
</div>
<div class="field-row">
  <mat-checkbox
    [checked]="archiveChecked"
    (change)="onArchiveSelect($event)"
  ></mat-checkbox>
  <mat-label>Archived</mat-label>
</div>
