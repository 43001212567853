/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import {
  GrpcMessage,
  RecursivePartial,
  ToProtobufJSONOptions,
  uint8ArrayToBase64,
} from '@ngx-grpc/common';
import { BinaryReader, BinaryWriter, ByteSource } from 'google-protobuf';

/**
 * Message implementation for storage.Encrypted
 */
export class Encrypted implements GrpcMessage {
  static id = 'storage.Encrypted';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new Encrypted();
    Encrypted.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: Encrypted) {
    _instance.encryptionKey = _instance.encryptionKey || undefined;
    _instance.encryptedValues = _instance.encryptedValues || [];
    _instance.dataOwnerId = _instance.dataOwnerId || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: Encrypted,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.encryptionKey = new EncryptionKey();
          _reader.readMessage(
            _instance.encryptionKey,
            EncryptionKey.deserializeBinaryFromReader
          );
          break;
        case 2:
          const messageInitializer2 = new EncryptedValue();
          _reader.readMessage(
            messageInitializer2,
            EncryptedValue.deserializeBinaryFromReader
          );
          (_instance.encryptedValues = _instance.encryptedValues || []).push(
            messageInitializer2
          );
          break;
        case 3:
          _instance.dataOwnerId = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    Encrypted.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(_instance: Encrypted, _writer: BinaryWriter) {
    if (_instance.encryptionKey) {
      _writer.writeMessage(
        1,
        _instance.encryptionKey as any,
        EncryptionKey.serializeBinaryToWriter
      );
    }
    if (_instance.encryptedValues && _instance.encryptedValues.length) {
      _writer.writeRepeatedMessage(
        2,
        _instance.encryptedValues as any,
        EncryptedValue.serializeBinaryToWriter
      );
    }
    if (_instance.dataOwnerId) {
      _writer.writeString(3, _instance.dataOwnerId);
    }
  }

  private _encryptionKey?: EncryptionKey;
  private _encryptedValues?: EncryptedValue[];
  private _dataOwnerId: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of Encrypted to deeply clone from
   */
  constructor(_value?: RecursivePartial<Encrypted.AsObject>) {
    _value = _value || {};
    this.encryptionKey = _value.encryptionKey
      ? new EncryptionKey(_value.encryptionKey)
      : undefined;
    this.encryptedValues = (_value.encryptedValues || []).map(
      (m) => new EncryptedValue(m)
    );
    this.dataOwnerId = _value.dataOwnerId;
    Encrypted.refineValues(this);
  }
  get encryptionKey(): EncryptionKey | undefined {
    return this._encryptionKey;
  }
  set encryptionKey(value: EncryptionKey | undefined) {
    this._encryptionKey = value;
  }
  get encryptedValues(): EncryptedValue[] | undefined {
    return this._encryptedValues;
  }
  set encryptedValues(value: EncryptedValue[] | undefined) {
    this._encryptedValues = value;
  }
  get dataOwnerId(): string {
    return this._dataOwnerId;
  }
  set dataOwnerId(value: string) {
    this._dataOwnerId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    Encrypted.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): Encrypted.AsObject {
    return {
      encryptionKey: this.encryptionKey
        ? this.encryptionKey.toObject()
        : undefined,
      encryptedValues: (this.encryptedValues || []).map((m) => m.toObject()),
      dataOwnerId: this.dataOwnerId,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): Encrypted.AsProtobufJSON {
    return {
      encryptionKey: this.encryptionKey
        ? this.encryptionKey.toProtobufJSON(options)
        : null,
      encryptedValues: (this.encryptedValues || []).map((m) =>
        m.toProtobufJSON(options)
      ),
      dataOwnerId: this.dataOwnerId,
    };
  }
}
export module Encrypted {
  /**
   * Standard JavaScript object representation for Encrypted
   */
  export interface AsObject {
    encryptionKey?: EncryptionKey.AsObject;
    encryptedValues?: EncryptedValue.AsObject[];
    dataOwnerId: string;
  }

  /**
   * Protobuf JSON representation for Encrypted
   */
  export interface AsProtobufJSON {
    encryptionKey: EncryptionKey.AsProtobufJSON | null;
    encryptedValues: EncryptedValue.AsProtobufJSON[] | null;
    dataOwnerId: string;
  }
}

/**
 * Message implementation for storage.EncryptionKey
 */
export class EncryptionKey implements GrpcMessage {
  static id = 'storage.EncryptionKey';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new EncryptionKey();
    EncryptionKey.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: EncryptionKey) {}

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: EncryptionKey,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.rsaAes = new RsaAesHybridKey();
          _reader.readMessage(
            _instance.rsaAes,
            RsaAesHybridKey.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    EncryptionKey.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: EncryptionKey,
    _writer: BinaryWriter
  ) {
    if (_instance.rsaAes) {
      _writer.writeMessage(
        1,
        _instance.rsaAes as any,
        RsaAesHybridKey.serializeBinaryToWriter
      );
    }
  }

  private _rsaAes?: RsaAesHybridKey;

  private _key: EncryptionKey.KeyCase = EncryptionKey.KeyCase.none;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of EncryptionKey to deeply clone from
   */
  constructor(_value?: RecursivePartial<EncryptionKey.AsObject>) {
    _value = _value || {};
    this.rsaAes = _value.rsaAes
      ? new RsaAesHybridKey(_value.rsaAes)
      : undefined;
    EncryptionKey.refineValues(this);
  }
  get rsaAes(): RsaAesHybridKey | undefined {
    return this._rsaAes;
  }
  set rsaAes(value: RsaAesHybridKey | undefined) {
    if (value !== undefined && value !== null) {
      this._key = EncryptionKey.KeyCase.rsaAes;
    }
    this._rsaAes = value;
  }
  get key() {
    return this._key;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    EncryptionKey.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): EncryptionKey.AsObject {
    return {
      rsaAes: this.rsaAes ? this.rsaAes.toObject() : undefined,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): EncryptionKey.AsProtobufJSON {
    return {
      rsaAes: this.rsaAes ? this.rsaAes.toProtobufJSON(options) : null,
    };
  }
}
export module EncryptionKey {
  /**
   * Standard JavaScript object representation for EncryptionKey
   */
  export interface AsObject {
    rsaAes?: RsaAesHybridKey.AsObject;
  }

  /**
   * Protobuf JSON representation for EncryptionKey
   */
  export interface AsProtobufJSON {
    rsaAes: RsaAesHybridKey.AsProtobufJSON | null;
  }
  export enum KeyCase {
    none = 0,
    rsaAes = 1,
  }
}

/**
 * Message implementation for storage.RsaAesHybridKey
 */
export class RsaAesHybridKey implements GrpcMessage {
  static id = 'storage.RsaAesHybridKey';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new RsaAesHybridKey();
    RsaAesHybridKey.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: RsaAesHybridKey) {
    _instance.encryptedAesKey = _instance.encryptedAesKey || new Uint8Array();
    _instance.rsaKey = _instance.rsaKey || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: RsaAesHybridKey,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.encryptedAesKey = _reader.readBytes();
          break;
        case 2:
          _instance.rsaKey = new RsaKeyReference();
          _reader.readMessage(
            _instance.rsaKey,
            RsaKeyReference.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    RsaAesHybridKey.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: RsaAesHybridKey,
    _writer: BinaryWriter
  ) {
    if (_instance.encryptedAesKey && _instance.encryptedAesKey.length) {
      _writer.writeBytes(1, _instance.encryptedAesKey);
    }
    if (_instance.rsaKey) {
      _writer.writeMessage(
        2,
        _instance.rsaKey as any,
        RsaKeyReference.serializeBinaryToWriter
      );
    }
  }

  private _encryptedAesKey: Uint8Array;
  private _rsaKey?: RsaKeyReference;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of RsaAesHybridKey to deeply clone from
   */
  constructor(_value?: RecursivePartial<RsaAesHybridKey.AsObject>) {
    _value = _value || {};
    this.encryptedAesKey = _value.encryptedAesKey;
    this.rsaKey = _value.rsaKey
      ? new RsaKeyReference(_value.rsaKey)
      : undefined;
    RsaAesHybridKey.refineValues(this);
  }
  get encryptedAesKey(): Uint8Array {
    return this._encryptedAesKey;
  }
  set encryptedAesKey(value: Uint8Array) {
    this._encryptedAesKey = value;
  }
  get rsaKey(): RsaKeyReference | undefined {
    return this._rsaKey;
  }
  set rsaKey(value: RsaKeyReference | undefined) {
    this._rsaKey = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    RsaAesHybridKey.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): RsaAesHybridKey.AsObject {
    return {
      encryptedAesKey: this.encryptedAesKey
        ? this.encryptedAesKey.subarray(0)
        : new Uint8Array(),
      rsaKey: this.rsaKey ? this.rsaKey.toObject() : undefined,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): RsaAesHybridKey.AsProtobufJSON {
    return {
      encryptedAesKey: this.encryptedAesKey
        ? uint8ArrayToBase64(this.encryptedAesKey)
        : '',
      rsaKey: this.rsaKey ? this.rsaKey.toProtobufJSON(options) : null,
    };
  }
}
export module RsaAesHybridKey {
  /**
   * Standard JavaScript object representation for RsaAesHybridKey
   */
  export interface AsObject {
    encryptedAesKey: Uint8Array;
    rsaKey?: RsaKeyReference.AsObject;
  }

  /**
   * Protobuf JSON representation for RsaAesHybridKey
   */
  export interface AsProtobufJSON {
    encryptedAesKey: string;
    rsaKey: RsaKeyReference.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for storage.RsaKeyReference
 */
export class RsaKeyReference implements GrpcMessage {
  static id = 'storage.RsaKeyReference';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new RsaKeyReference();
    RsaKeyReference.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: RsaKeyReference) {
    _instance.name = _instance.name || '';
    _instance.version = _instance.version || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: RsaKeyReference,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.name = _reader.readString();
          break;
        case 2:
          _instance.version = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    RsaKeyReference.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: RsaKeyReference,
    _writer: BinaryWriter
  ) {
    if (_instance.name) {
      _writer.writeString(1, _instance.name);
    }
    if (_instance.version) {
      _writer.writeString(2, _instance.version);
    }
  }

  private _name: string;
  private _version: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of RsaKeyReference to deeply clone from
   */
  constructor(_value?: RecursivePartial<RsaKeyReference.AsObject>) {
    _value = _value || {};
    this.name = _value.name;
    this.version = _value.version;
    RsaKeyReference.refineValues(this);
  }
  get name(): string {
    return this._name;
  }
  set name(value: string) {
    this._name = value;
  }
  get version(): string {
    return this._version;
  }
  set version(value: string) {
    this._version = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    RsaKeyReference.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): RsaKeyReference.AsObject {
    return {
      name: this.name,
      version: this.version,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): RsaKeyReference.AsProtobufJSON {
    return {
      name: this.name,
      version: this.version,
    };
  }
}
export module RsaKeyReference {
  /**
   * Standard JavaScript object representation for RsaKeyReference
   */
  export interface AsObject {
    name: string;
    version: string;
  }

  /**
   * Protobuf JSON representation for RsaKeyReference
   */
  export interface AsProtobufJSON {
    name: string;
    version: string;
  }
}

/**
 * Message implementation for storage.EncryptedValue
 */
export class EncryptedValue implements GrpcMessage {
  static id = 'storage.EncryptedValue';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new EncryptedValue();
    EncryptedValue.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: EncryptedValue) {
    _instance.value = _instance.value || new Uint8Array();
    _instance.initVector = _instance.initVector || new Uint8Array();
    _instance.tag = _instance.tag || new Uint8Array();
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: EncryptedValue,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.value = _reader.readBytes();
          break;
        case 2:
          _instance.initVector = _reader.readBytes();
          break;
        case 3:
          _instance.tag = _reader.readBytes();
          break;
        default:
          _reader.skipField();
      }
    }

    EncryptedValue.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: EncryptedValue,
    _writer: BinaryWriter
  ) {
    if (_instance.value && _instance.value.length) {
      _writer.writeBytes(1, _instance.value);
    }
    if (_instance.initVector && _instance.initVector.length) {
      _writer.writeBytes(2, _instance.initVector);
    }
    if (_instance.tag && _instance.tag.length) {
      _writer.writeBytes(3, _instance.tag);
    }
  }

  private _value: Uint8Array;
  private _initVector: Uint8Array;
  private _tag: Uint8Array;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of EncryptedValue to deeply clone from
   */
  constructor(_value?: RecursivePartial<EncryptedValue.AsObject>) {
    _value = _value || {};
    this.value = _value.value;
    this.initVector = _value.initVector;
    this.tag = _value.tag;
    EncryptedValue.refineValues(this);
  }
  get value(): Uint8Array {
    return this._value;
  }
  set value(value: Uint8Array) {
    this._value = value;
  }
  get initVector(): Uint8Array {
    return this._initVector;
  }
  set initVector(value: Uint8Array) {
    this._initVector = value;
  }
  get tag(): Uint8Array {
    return this._tag;
  }
  set tag(value: Uint8Array) {
    this._tag = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    EncryptedValue.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): EncryptedValue.AsObject {
    return {
      value: this.value ? this.value.subarray(0) : new Uint8Array(),
      initVector: this.initVector
        ? this.initVector.subarray(0)
        : new Uint8Array(),
      tag: this.tag ? this.tag.subarray(0) : new Uint8Array(),
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): EncryptedValue.AsProtobufJSON {
    return {
      value: this.value ? uint8ArrayToBase64(this.value) : '',
      initVector: this.initVector ? uint8ArrayToBase64(this.initVector) : '',
      tag: this.tag ? uint8ArrayToBase64(this.tag) : '',
    };
  }
}
export module EncryptedValue {
  /**
   * Standard JavaScript object representation for EncryptedValue
   */
  export interface AsObject {
    value: Uint8Array;
    initVector: Uint8Array;
    tag: Uint8Array;
  }

  /**
   * Protobuf JSON representation for EncryptedValue
   */
  export interface AsProtobufJSON {
    value: string;
    initVector: string;
    tag: string;
  }
}

/**
 * Message implementation for storage.EncryptedValueAndKey
 */
export class EncryptedValueAndKey implements GrpcMessage {
  static id = 'storage.EncryptedValueAndKey';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new EncryptedValueAndKey();
    EncryptedValueAndKey.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: EncryptedValueAndKey) {
    _instance.value = _instance.value || new Uint8Array();
    _instance.initVector = _instance.initVector || new Uint8Array();
    _instance.tag = _instance.tag || new Uint8Array();
    _instance.encryptedKey = _instance.encryptedKey || new Uint8Array();
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: EncryptedValueAndKey,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.value = _reader.readBytes();
          break;
        case 2:
          _instance.initVector = _reader.readBytes();
          break;
        case 3:
          _instance.tag = _reader.readBytes();
          break;
        case 4:
          _instance.encryptedKey = _reader.readBytes();
          break;
        default:
          _reader.skipField();
      }
    }

    EncryptedValueAndKey.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: EncryptedValueAndKey,
    _writer: BinaryWriter
  ) {
    if (_instance.value && _instance.value.length) {
      _writer.writeBytes(1, _instance.value);
    }
    if (_instance.initVector && _instance.initVector.length) {
      _writer.writeBytes(2, _instance.initVector);
    }
    if (_instance.tag && _instance.tag.length) {
      _writer.writeBytes(3, _instance.tag);
    }
    if (_instance.encryptedKey && _instance.encryptedKey.length) {
      _writer.writeBytes(4, _instance.encryptedKey);
    }
  }

  private _value: Uint8Array;
  private _initVector: Uint8Array;
  private _tag: Uint8Array;
  private _encryptedKey: Uint8Array;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of EncryptedValueAndKey to deeply clone from
   */
  constructor(_value?: RecursivePartial<EncryptedValueAndKey.AsObject>) {
    _value = _value || {};
    this.value = _value.value;
    this.initVector = _value.initVector;
    this.tag = _value.tag;
    this.encryptedKey = _value.encryptedKey;
    EncryptedValueAndKey.refineValues(this);
  }
  get value(): Uint8Array {
    return this._value;
  }
  set value(value: Uint8Array) {
    this._value = value;
  }
  get initVector(): Uint8Array {
    return this._initVector;
  }
  set initVector(value: Uint8Array) {
    this._initVector = value;
  }
  get tag(): Uint8Array {
    return this._tag;
  }
  set tag(value: Uint8Array) {
    this._tag = value;
  }
  get encryptedKey(): Uint8Array {
    return this._encryptedKey;
  }
  set encryptedKey(value: Uint8Array) {
    this._encryptedKey = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    EncryptedValueAndKey.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): EncryptedValueAndKey.AsObject {
    return {
      value: this.value ? this.value.subarray(0) : new Uint8Array(),
      initVector: this.initVector
        ? this.initVector.subarray(0)
        : new Uint8Array(),
      tag: this.tag ? this.tag.subarray(0) : new Uint8Array(),
      encryptedKey: this.encryptedKey
        ? this.encryptedKey.subarray(0)
        : new Uint8Array(),
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): EncryptedValueAndKey.AsProtobufJSON {
    return {
      value: this.value ? uint8ArrayToBase64(this.value) : '',
      initVector: this.initVector ? uint8ArrayToBase64(this.initVector) : '',
      tag: this.tag ? uint8ArrayToBase64(this.tag) : '',
      encryptedKey: this.encryptedKey
        ? uint8ArrayToBase64(this.encryptedKey)
        : '',
    };
  }
}
export module EncryptedValueAndKey {
  /**
   * Standard JavaScript object representation for EncryptedValueAndKey
   */
  export interface AsObject {
    value: Uint8Array;
    initVector: Uint8Array;
    tag: Uint8Array;
    encryptedKey: Uint8Array;
  }

  /**
   * Protobuf JSON representation for EncryptedValueAndKey
   */
  export interface AsProtobufJSON {
    value: string;
    initVector: string;
    tag: string;
    encryptedKey: string;
  }
}
