<div class="ticket-form-wrapper" *ngIf="!submittedSuccesfully">
  <div class="header">
    <h2>Have an Issue?</h2>
    <button mat-icon-button (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <form ngNativeValidate (ngSubmit)="submit()" [formGroup]="ticket">
    <label for="description"> What is the Issue </label>
    <textarea
      required
      name="description"
      placeholder="Describe issue"
      formControlName="description"
    ></textarea>
    <button
      [disabled]="ticket.invalid || isLoading"
      type="submit"
      mat-flat-button
      color="primary"
      data-testid="submitButton"
    >
      {{ isLoading ? null : 'Send Issue' }}
      <span *ngIf="isLoading" class="spinner">
        <mat-spinner diameter="24"></mat-spinner>
      </span>
    </button>
  </form>
</div>

<app-message-box></app-message-box>

<div *ngIf="submittedSuccesfully" class="submitted-wrapper">
  <div class="header">
    <button mat-icon-button (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <img class="logo" src="assets/logo.svg" />
  <img class="graphic" src="assets/graphic_mail.svg" />
  <h2>Your Issue Has Been Sent</h2>
  <p>
    Keep an eye out for an email on updates to your issue from our support team.
  </p>
  <button
    mat-flat-button
    color="primary"
    data-testid="closeButton"
    (click)="close()"
  >
    Back to Site
  </button>
</div>
