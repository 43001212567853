/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import {
  GrpcMessage,
  RecursivePartial,
  ToProtobufJSONOptions,
} from '@ngx-grpc/common';
import { BinaryReader, BinaryWriter, ByteSource } from 'google-protobuf';
import * as googleProtobuf000 from '@ngx-grpc/well-known-types';
import * as storage001 from '../../../../src/main/proto/storage/binary-type.pb';
import * as storage002 from '../../../../src/main/proto/storage/commons.pb';
import * as storage003 from '../../../../src/main/proto/storage/schema-annotations.pb';
import * as api004 from '../../../../src/main/proto/api/headers.pb';
import * as api005 from '../../../../src/main/proto/api/pagination.pb';
import * as storage006 from '../../../../src/main/proto/storage/customer.pb';
/**
 * Message implementation for api.customer.CreateRequest
 */
export class CreateRequest implements GrpcMessage {
  static id = 'api.customer.CreateRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new CreateRequest();
    CreateRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: CreateRequest) {
    _instance.header = _instance.header || undefined;
    _instance.customer = _instance.customer || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: CreateRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api004.RequestHeader();
          _reader.readMessage(
            _instance.header,
            api004.RequestHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.customer = new storage006.Customer();
          _reader.readMessage(
            _instance.customer,
            storage006.Customer.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    CreateRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: CreateRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api004.RequestHeader.serializeBinaryToWriter
      );
    }
    if (_instance.customer) {
      _writer.writeMessage(
        2,
        _instance.customer as any,
        storage006.Customer.serializeBinaryToWriter
      );
    }
  }

  private _header?: api004.RequestHeader;
  private _customer?: storage006.Customer;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of CreateRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<CreateRequest.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api004.RequestHeader(_value.header)
      : undefined;
    this.customer = _value.customer
      ? new storage006.Customer(_value.customer)
      : undefined;
    CreateRequest.refineValues(this);
  }
  get header(): api004.RequestHeader | undefined {
    return this._header;
  }
  set header(value: api004.RequestHeader | undefined) {
    this._header = value;
  }
  get customer(): storage006.Customer | undefined {
    return this._customer;
  }
  set customer(value: storage006.Customer | undefined) {
    this._customer = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    CreateRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): CreateRequest.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      customer: this.customer ? this.customer.toObject() : undefined,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): CreateRequest.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      customer: this.customer ? this.customer.toProtobufJSON(options) : null,
    };
  }
}
export module CreateRequest {
  /**
   * Standard JavaScript object representation for CreateRequest
   */
  export interface AsObject {
    header?: api004.RequestHeader.AsObject;
    customer?: storage006.Customer.AsObject;
  }

  /**
   * Protobuf JSON representation for CreateRequest
   */
  export interface AsProtobufJSON {
    header: api004.RequestHeader.AsProtobufJSON | null;
    customer: storage006.Customer.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for api.customer.CreateResponse
 */
export class CreateResponse implements GrpcMessage {
  static id = 'api.customer.CreateResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new CreateResponse();
    CreateResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: CreateResponse) {
    _instance.header = _instance.header || undefined;
    _instance.customerId = _instance.customerId || '';
    _instance.etag = _instance.etag || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: CreateResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api004.ResponseHeader();
          _reader.readMessage(
            _instance.header,
            api004.ResponseHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.customerId = _reader.readString();
          break;
        case 3:
          _instance.etag = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    CreateResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: CreateResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api004.ResponseHeader.serializeBinaryToWriter
      );
    }
    if (_instance.customerId) {
      _writer.writeString(2, _instance.customerId);
    }
    if (_instance.etag) {
      _writer.writeString(3, _instance.etag);
    }
  }

  private _header?: api004.ResponseHeader;
  private _customerId: string;
  private _etag: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of CreateResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<CreateResponse.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api004.ResponseHeader(_value.header)
      : undefined;
    this.customerId = _value.customerId;
    this.etag = _value.etag;
    CreateResponse.refineValues(this);
  }
  get header(): api004.ResponseHeader | undefined {
    return this._header;
  }
  set header(value: api004.ResponseHeader | undefined) {
    this._header = value;
  }
  get customerId(): string {
    return this._customerId;
  }
  set customerId(value: string) {
    this._customerId = value;
  }
  get etag(): string {
    return this._etag;
  }
  set etag(value: string) {
    this._etag = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    CreateResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): CreateResponse.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      customerId: this.customerId,
      etag: this.etag,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): CreateResponse.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      customerId: this.customerId,
      etag: this.etag,
    };
  }
}
export module CreateResponse {
  /**
   * Standard JavaScript object representation for CreateResponse
   */
  export interface AsObject {
    header?: api004.ResponseHeader.AsObject;
    customerId: string;
    etag: string;
  }

  /**
   * Protobuf JSON representation for CreateResponse
   */
  export interface AsProtobufJSON {
    header: api004.ResponseHeader.AsProtobufJSON | null;
    customerId: string;
    etag: string;
  }
}

/**
 * Message implementation for api.customer.UpdateManagedCustomerRequest
 */
export class UpdateManagedCustomerRequest implements GrpcMessage {
  static id = 'api.customer.UpdateManagedCustomerRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new UpdateManagedCustomerRequest();
    UpdateManagedCustomerRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: UpdateManagedCustomerRequest) {
    _instance.header = _instance.header || undefined;
    _instance.customerId = _instance.customerId || '';
    _instance.isManagedCustomer = _instance.isManagedCustomer || false;
    _instance.etag = _instance.etag || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: UpdateManagedCustomerRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api004.RequestHeader();
          _reader.readMessage(
            _instance.header,
            api004.RequestHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.customerId = _reader.readString();
          break;
        case 3:
          _instance.isManagedCustomer = _reader.readBool();
          break;
        case 4:
          _instance.etag = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    UpdateManagedCustomerRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: UpdateManagedCustomerRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api004.RequestHeader.serializeBinaryToWriter
      );
    }
    if (_instance.customerId) {
      _writer.writeString(2, _instance.customerId);
    }
    if (_instance.isManagedCustomer) {
      _writer.writeBool(3, _instance.isManagedCustomer);
    }
    if (_instance.etag) {
      _writer.writeString(4, _instance.etag);
    }
  }

  private _header?: api004.RequestHeader;
  private _customerId: string;
  private _isManagedCustomer: boolean;
  private _etag: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of UpdateManagedCustomerRequest to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<UpdateManagedCustomerRequest.AsObject>
  ) {
    _value = _value || {};
    this.header = _value.header
      ? new api004.RequestHeader(_value.header)
      : undefined;
    this.customerId = _value.customerId;
    this.isManagedCustomer = _value.isManagedCustomer;
    this.etag = _value.etag;
    UpdateManagedCustomerRequest.refineValues(this);
  }
  get header(): api004.RequestHeader | undefined {
    return this._header;
  }
  set header(value: api004.RequestHeader | undefined) {
    this._header = value;
  }
  get customerId(): string {
    return this._customerId;
  }
  set customerId(value: string) {
    this._customerId = value;
  }
  get isManagedCustomer(): boolean {
    return this._isManagedCustomer;
  }
  set isManagedCustomer(value: boolean) {
    this._isManagedCustomer = value;
  }
  get etag(): string {
    return this._etag;
  }
  set etag(value: string) {
    this._etag = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    UpdateManagedCustomerRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): UpdateManagedCustomerRequest.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      customerId: this.customerId,
      isManagedCustomer: this.isManagedCustomer,
      etag: this.etag,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): UpdateManagedCustomerRequest.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      customerId: this.customerId,
      isManagedCustomer: this.isManagedCustomer,
      etag: this.etag,
    };
  }
}
export module UpdateManagedCustomerRequest {
  /**
   * Standard JavaScript object representation for UpdateManagedCustomerRequest
   */
  export interface AsObject {
    header?: api004.RequestHeader.AsObject;
    customerId: string;
    isManagedCustomer: boolean;
    etag: string;
  }

  /**
   * Protobuf JSON representation for UpdateManagedCustomerRequest
   */
  export interface AsProtobufJSON {
    header: api004.RequestHeader.AsProtobufJSON | null;
    customerId: string;
    isManagedCustomer: boolean;
    etag: string;
  }
}

/**
 * Message implementation for api.customer.UpdateManagedCustomerResponse
 */
export class UpdateManagedCustomerResponse implements GrpcMessage {
  static id = 'api.customer.UpdateManagedCustomerResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new UpdateManagedCustomerResponse();
    UpdateManagedCustomerResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: UpdateManagedCustomerResponse) {
    _instance.header = _instance.header || undefined;
    _instance.etag = _instance.etag || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: UpdateManagedCustomerResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api004.ResponseHeader();
          _reader.readMessage(
            _instance.header,
            api004.ResponseHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.etag = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    UpdateManagedCustomerResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: UpdateManagedCustomerResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api004.ResponseHeader.serializeBinaryToWriter
      );
    }
    if (_instance.etag) {
      _writer.writeString(2, _instance.etag);
    }
  }

  private _header?: api004.ResponseHeader;
  private _etag: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of UpdateManagedCustomerResponse to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<UpdateManagedCustomerResponse.AsObject>
  ) {
    _value = _value || {};
    this.header = _value.header
      ? new api004.ResponseHeader(_value.header)
      : undefined;
    this.etag = _value.etag;
    UpdateManagedCustomerResponse.refineValues(this);
  }
  get header(): api004.ResponseHeader | undefined {
    return this._header;
  }
  set header(value: api004.ResponseHeader | undefined) {
    this._header = value;
  }
  get etag(): string {
    return this._etag;
  }
  set etag(value: string) {
    this._etag = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    UpdateManagedCustomerResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): UpdateManagedCustomerResponse.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      etag: this.etag,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): UpdateManagedCustomerResponse.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      etag: this.etag,
    };
  }
}
export module UpdateManagedCustomerResponse {
  /**
   * Standard JavaScript object representation for UpdateManagedCustomerResponse
   */
  export interface AsObject {
    header?: api004.ResponseHeader.AsObject;
    etag: string;
  }

  /**
   * Protobuf JSON representation for UpdateManagedCustomerResponse
   */
  export interface AsProtobufJSON {
    header: api004.ResponseHeader.AsProtobufJSON | null;
    etag: string;
  }
}

/**
 * Message implementation for api.customer.UpdateRequest
 */
export class UpdateRequest implements GrpcMessage {
  static id = 'api.customer.UpdateRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new UpdateRequest();
    UpdateRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: UpdateRequest) {
    _instance.header = _instance.header || undefined;
    _instance.customer = _instance.customer || undefined;
    _instance.etag = _instance.etag || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: UpdateRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api004.RequestHeader();
          _reader.readMessage(
            _instance.header,
            api004.RequestHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.customer = new storage006.Customer();
          _reader.readMessage(
            _instance.customer,
            storage006.Customer.deserializeBinaryFromReader
          );
          break;
        case 3:
          _instance.etag = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    UpdateRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: UpdateRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api004.RequestHeader.serializeBinaryToWriter
      );
    }
    if (_instance.customer) {
      _writer.writeMessage(
        2,
        _instance.customer as any,
        storage006.Customer.serializeBinaryToWriter
      );
    }
    if (_instance.etag) {
      _writer.writeString(3, _instance.etag);
    }
  }

  private _header?: api004.RequestHeader;
  private _customer?: storage006.Customer;
  private _etag: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of UpdateRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<UpdateRequest.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api004.RequestHeader(_value.header)
      : undefined;
    this.customer = _value.customer
      ? new storage006.Customer(_value.customer)
      : undefined;
    this.etag = _value.etag;
    UpdateRequest.refineValues(this);
  }
  get header(): api004.RequestHeader | undefined {
    return this._header;
  }
  set header(value: api004.RequestHeader | undefined) {
    this._header = value;
  }
  get customer(): storage006.Customer | undefined {
    return this._customer;
  }
  set customer(value: storage006.Customer | undefined) {
    this._customer = value;
  }
  get etag(): string {
    return this._etag;
  }
  set etag(value: string) {
    this._etag = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    UpdateRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): UpdateRequest.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      customer: this.customer ? this.customer.toObject() : undefined,
      etag: this.etag,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): UpdateRequest.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      customer: this.customer ? this.customer.toProtobufJSON(options) : null,
      etag: this.etag,
    };
  }
}
export module UpdateRequest {
  /**
   * Standard JavaScript object representation for UpdateRequest
   */
  export interface AsObject {
    header?: api004.RequestHeader.AsObject;
    customer?: storage006.Customer.AsObject;
    etag: string;
  }

  /**
   * Protobuf JSON representation for UpdateRequest
   */
  export interface AsProtobufJSON {
    header: api004.RequestHeader.AsProtobufJSON | null;
    customer: storage006.Customer.AsProtobufJSON | null;
    etag: string;
  }
}

/**
 * Message implementation for api.customer.UpdateResponse
 */
export class UpdateResponse implements GrpcMessage {
  static id = 'api.customer.UpdateResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new UpdateResponse();
    UpdateResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: UpdateResponse) {
    _instance.header = _instance.header || undefined;
    _instance.etag = _instance.etag || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: UpdateResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api004.ResponseHeader();
          _reader.readMessage(
            _instance.header,
            api004.ResponseHeader.deserializeBinaryFromReader
          );
          break;
        case 3:
          _instance.etag = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    UpdateResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: UpdateResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api004.ResponseHeader.serializeBinaryToWriter
      );
    }
    if (_instance.etag) {
      _writer.writeString(3, _instance.etag);
    }
  }

  private _header?: api004.ResponseHeader;
  private _etag: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of UpdateResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<UpdateResponse.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api004.ResponseHeader(_value.header)
      : undefined;
    this.etag = _value.etag;
    UpdateResponse.refineValues(this);
  }
  get header(): api004.ResponseHeader | undefined {
    return this._header;
  }
  set header(value: api004.ResponseHeader | undefined) {
    this._header = value;
  }
  get etag(): string {
    return this._etag;
  }
  set etag(value: string) {
    this._etag = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    UpdateResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): UpdateResponse.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      etag: this.etag,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): UpdateResponse.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      etag: this.etag,
    };
  }
}
export module UpdateResponse {
  /**
   * Standard JavaScript object representation for UpdateResponse
   */
  export interface AsObject {
    header?: api004.ResponseHeader.AsObject;
    etag: string;
  }

  /**
   * Protobuf JSON representation for UpdateResponse
   */
  export interface AsProtobufJSON {
    header: api004.ResponseHeader.AsProtobufJSON | null;
    etag: string;
  }
}

/**
 * Message implementation for api.customer.ReadCustomerRequest
 */
export class ReadCustomerRequest implements GrpcMessage {
  static id = 'api.customer.ReadCustomerRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ReadCustomerRequest();
    ReadCustomerRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ReadCustomerRequest) {
    _instance.header = _instance.header || undefined;
    _instance.customerId = _instance.customerId || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ReadCustomerRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api004.RequestHeader();
          _reader.readMessage(
            _instance.header,
            api004.RequestHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.customerId = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    ReadCustomerRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ReadCustomerRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api004.RequestHeader.serializeBinaryToWriter
      );
    }
    if (_instance.customerId) {
      _writer.writeString(2, _instance.customerId);
    }
  }

  private _header?: api004.RequestHeader;
  private _customerId: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ReadCustomerRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<ReadCustomerRequest.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api004.RequestHeader(_value.header)
      : undefined;
    this.customerId = _value.customerId;
    ReadCustomerRequest.refineValues(this);
  }
  get header(): api004.RequestHeader | undefined {
    return this._header;
  }
  set header(value: api004.RequestHeader | undefined) {
    this._header = value;
  }
  get customerId(): string {
    return this._customerId;
  }
  set customerId(value: string) {
    this._customerId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ReadCustomerRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ReadCustomerRequest.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      customerId: this.customerId,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ReadCustomerRequest.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      customerId: this.customerId,
    };
  }
}
export module ReadCustomerRequest {
  /**
   * Standard JavaScript object representation for ReadCustomerRequest
   */
  export interface AsObject {
    header?: api004.RequestHeader.AsObject;
    customerId: string;
  }

  /**
   * Protobuf JSON representation for ReadCustomerRequest
   */
  export interface AsProtobufJSON {
    header: api004.RequestHeader.AsProtobufJSON | null;
    customerId: string;
  }
}

/**
 * Message implementation for api.customer.ReadCustomerResponse
 */
export class ReadCustomerResponse implements GrpcMessage {
  static id = 'api.customer.ReadCustomerResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ReadCustomerResponse();
    ReadCustomerResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ReadCustomerResponse) {
    _instance.header = _instance.header || undefined;
    _instance.customer = _instance.customer || undefined;
    _instance.etag = _instance.etag || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ReadCustomerResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api004.ResponseHeader();
          _reader.readMessage(
            _instance.header,
            api004.ResponseHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.customer = new storage006.Customer();
          _reader.readMessage(
            _instance.customer,
            storage006.Customer.deserializeBinaryFromReader
          );
          break;
        case 3:
          _instance.etag = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    ReadCustomerResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ReadCustomerResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api004.ResponseHeader.serializeBinaryToWriter
      );
    }
    if (_instance.customer) {
      _writer.writeMessage(
        2,
        _instance.customer as any,
        storage006.Customer.serializeBinaryToWriter
      );
    }
    if (_instance.etag) {
      _writer.writeString(3, _instance.etag);
    }
  }

  private _header?: api004.ResponseHeader;
  private _customer?: storage006.Customer;
  private _etag: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ReadCustomerResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<ReadCustomerResponse.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api004.ResponseHeader(_value.header)
      : undefined;
    this.customer = _value.customer
      ? new storage006.Customer(_value.customer)
      : undefined;
    this.etag = _value.etag;
    ReadCustomerResponse.refineValues(this);
  }
  get header(): api004.ResponseHeader | undefined {
    return this._header;
  }
  set header(value: api004.ResponseHeader | undefined) {
    this._header = value;
  }
  get customer(): storage006.Customer | undefined {
    return this._customer;
  }
  set customer(value: storage006.Customer | undefined) {
    this._customer = value;
  }
  get etag(): string {
    return this._etag;
  }
  set etag(value: string) {
    this._etag = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ReadCustomerResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ReadCustomerResponse.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      customer: this.customer ? this.customer.toObject() : undefined,
      etag: this.etag,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ReadCustomerResponse.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      customer: this.customer ? this.customer.toProtobufJSON(options) : null,
      etag: this.etag,
    };
  }
}
export module ReadCustomerResponse {
  /**
   * Standard JavaScript object representation for ReadCustomerResponse
   */
  export interface AsObject {
    header?: api004.ResponseHeader.AsObject;
    customer?: storage006.Customer.AsObject;
    etag: string;
  }

  /**
   * Protobuf JSON representation for ReadCustomerResponse
   */
  export interface AsProtobufJSON {
    header: api004.ResponseHeader.AsProtobufJSON | null;
    customer: storage006.Customer.AsProtobufJSON | null;
    etag: string;
  }
}

/**
 * Message implementation for api.customer.ReadCustomersRequest
 */
export class ReadCustomersRequest implements GrpcMessage {
  static id = 'api.customer.ReadCustomersRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ReadCustomersRequest();
    ReadCustomersRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ReadCustomersRequest) {
    _instance.header = _instance.header || undefined;
    _instance.filter = _instance.filter || undefined;
    _instance.paginated = _instance.paginated || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ReadCustomersRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api004.RequestHeader();
          _reader.readMessage(
            _instance.header,
            api004.RequestHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.filter = new ReadCustomersFilter();
          _reader.readMessage(
            _instance.filter,
            ReadCustomersFilter.deserializeBinaryFromReader
          );
          break;
        case 3:
          _instance.paginated = new api005.GetPaginatedRequest();
          _reader.readMessage(
            _instance.paginated,
            api005.GetPaginatedRequest.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    ReadCustomersRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ReadCustomersRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api004.RequestHeader.serializeBinaryToWriter
      );
    }
    if (_instance.filter) {
      _writer.writeMessage(
        2,
        _instance.filter as any,
        ReadCustomersFilter.serializeBinaryToWriter
      );
    }
    if (_instance.paginated) {
      _writer.writeMessage(
        3,
        _instance.paginated as any,
        api005.GetPaginatedRequest.serializeBinaryToWriter
      );
    }
  }

  private _header?: api004.RequestHeader;
  private _filter?: ReadCustomersFilter;
  private _paginated?: api005.GetPaginatedRequest;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ReadCustomersRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<ReadCustomersRequest.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api004.RequestHeader(_value.header)
      : undefined;
    this.filter = _value.filter
      ? new ReadCustomersFilter(_value.filter)
      : undefined;
    this.paginated = _value.paginated
      ? new api005.GetPaginatedRequest(_value.paginated)
      : undefined;
    ReadCustomersRequest.refineValues(this);
  }
  get header(): api004.RequestHeader | undefined {
    return this._header;
  }
  set header(value: api004.RequestHeader | undefined) {
    this._header = value;
  }
  get filter(): ReadCustomersFilter | undefined {
    return this._filter;
  }
  set filter(value: ReadCustomersFilter | undefined) {
    this._filter = value;
  }
  get paginated(): api005.GetPaginatedRequest | undefined {
    return this._paginated;
  }
  set paginated(value: api005.GetPaginatedRequest | undefined) {
    this._paginated = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ReadCustomersRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ReadCustomersRequest.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      filter: this.filter ? this.filter.toObject() : undefined,
      paginated: this.paginated ? this.paginated.toObject() : undefined,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ReadCustomersRequest.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      filter: this.filter ? this.filter.toProtobufJSON(options) : null,
      paginated: this.paginated ? this.paginated.toProtobufJSON(options) : null,
    };
  }
}
export module ReadCustomersRequest {
  /**
   * Standard JavaScript object representation for ReadCustomersRequest
   */
  export interface AsObject {
    header?: api004.RequestHeader.AsObject;
    filter?: ReadCustomersFilter.AsObject;
    paginated?: api005.GetPaginatedRequest.AsObject;
  }

  /**
   * Protobuf JSON representation for ReadCustomersRequest
   */
  export interface AsProtobufJSON {
    header: api004.RequestHeader.AsProtobufJSON | null;
    filter: ReadCustomersFilter.AsProtobufJSON | null;
    paginated: api005.GetPaginatedRequest.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for api.customer.ReadCustomersResponse
 */
export class ReadCustomersResponse implements GrpcMessage {
  static id = 'api.customer.ReadCustomersResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ReadCustomersResponse();
    ReadCustomersResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ReadCustomersResponse) {
    _instance.header = _instance.header || undefined;
    _instance.customers = _instance.customers || [];
    _instance.paginatedResponse = _instance.paginatedResponse || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ReadCustomersResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api004.ResponseHeader();
          _reader.readMessage(
            _instance.header,
            api004.ResponseHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          const messageInitializer2 = new storage006.Customer();
          _reader.readMessage(
            messageInitializer2,
            storage006.Customer.deserializeBinaryFromReader
          );
          (_instance.customers = _instance.customers || []).push(
            messageInitializer2
          );
          break;
        case 3:
          _instance.paginatedResponse = new api005.PaginatedResponse();
          _reader.readMessage(
            _instance.paginatedResponse,
            api005.PaginatedResponse.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    ReadCustomersResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ReadCustomersResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api004.ResponseHeader.serializeBinaryToWriter
      );
    }
    if (_instance.customers && _instance.customers.length) {
      _writer.writeRepeatedMessage(
        2,
        _instance.customers as any,
        storage006.Customer.serializeBinaryToWriter
      );
    }
    if (_instance.paginatedResponse) {
      _writer.writeMessage(
        3,
        _instance.paginatedResponse as any,
        api005.PaginatedResponse.serializeBinaryToWriter
      );
    }
  }

  private _header?: api004.ResponseHeader;
  private _customers?: storage006.Customer[];
  private _paginatedResponse?: api005.PaginatedResponse;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ReadCustomersResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<ReadCustomersResponse.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api004.ResponseHeader(_value.header)
      : undefined;
    this.customers = (_value.customers || []).map(
      (m) => new storage006.Customer(m)
    );
    this.paginatedResponse = _value.paginatedResponse
      ? new api005.PaginatedResponse(_value.paginatedResponse)
      : undefined;
    ReadCustomersResponse.refineValues(this);
  }
  get header(): api004.ResponseHeader | undefined {
    return this._header;
  }
  set header(value: api004.ResponseHeader | undefined) {
    this._header = value;
  }
  get customers(): storage006.Customer[] | undefined {
    return this._customers;
  }
  set customers(value: storage006.Customer[] | undefined) {
    this._customers = value;
  }
  get paginatedResponse(): api005.PaginatedResponse | undefined {
    return this._paginatedResponse;
  }
  set paginatedResponse(value: api005.PaginatedResponse | undefined) {
    this._paginatedResponse = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ReadCustomersResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ReadCustomersResponse.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      customers: (this.customers || []).map((m) => m.toObject()),
      paginatedResponse: this.paginatedResponse
        ? this.paginatedResponse.toObject()
        : undefined,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ReadCustomersResponse.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      customers: (this.customers || []).map((m) => m.toProtobufJSON(options)),
      paginatedResponse: this.paginatedResponse
        ? this.paginatedResponse.toProtobufJSON(options)
        : null,
    };
  }
}
export module ReadCustomersResponse {
  /**
   * Standard JavaScript object representation for ReadCustomersResponse
   */
  export interface AsObject {
    header?: api004.ResponseHeader.AsObject;
    customers?: storage006.Customer.AsObject[];
    paginatedResponse?: api005.PaginatedResponse.AsObject;
  }

  /**
   * Protobuf JSON representation for ReadCustomersResponse
   */
  export interface AsProtobufJSON {
    header: api004.ResponseHeader.AsProtobufJSON | null;
    customers: storage006.Customer.AsProtobufJSON[] | null;
    paginatedResponse: api005.PaginatedResponse.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for api.customer.GetTenantIdRequest
 */
export class GetTenantIdRequest implements GrpcMessage {
  static id = 'api.customer.GetTenantIdRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetTenantIdRequest();
    GetTenantIdRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetTenantIdRequest) {
    _instance.header = _instance.header || undefined;
    _instance.email = _instance.email || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetTenantIdRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api004.RequestHeader();
          _reader.readMessage(
            _instance.header,
            api004.RequestHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.email = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    GetTenantIdRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetTenantIdRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api004.RequestHeader.serializeBinaryToWriter
      );
    }
    if (_instance.email) {
      _writer.writeString(2, _instance.email);
    }
  }

  private _header?: api004.RequestHeader;
  private _email: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetTenantIdRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetTenantIdRequest.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api004.RequestHeader(_value.header)
      : undefined;
    this.email = _value.email;
    GetTenantIdRequest.refineValues(this);
  }
  get header(): api004.RequestHeader | undefined {
    return this._header;
  }
  set header(value: api004.RequestHeader | undefined) {
    this._header = value;
  }
  get email(): string {
    return this._email;
  }
  set email(value: string) {
    this._email = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetTenantIdRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetTenantIdRequest.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      email: this.email,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetTenantIdRequest.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      email: this.email,
    };
  }
}
export module GetTenantIdRequest {
  /**
   * Standard JavaScript object representation for GetTenantIdRequest
   */
  export interface AsObject {
    header?: api004.RequestHeader.AsObject;
    email: string;
  }

  /**
   * Protobuf JSON representation for GetTenantIdRequest
   */
  export interface AsProtobufJSON {
    header: api004.RequestHeader.AsProtobufJSON | null;
    email: string;
  }
}

/**
 * Message implementation for api.customer.GetTenantIdResponse
 */
export class GetTenantIdResponse implements GrpcMessage {
  static id = 'api.customer.GetTenantIdResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetTenantIdResponse();
    GetTenantIdResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetTenantIdResponse) {
    _instance.header = _instance.header || undefined;
    _instance.tenantInfos = _instance.tenantInfos || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetTenantIdResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api004.ResponseHeader();
          _reader.readMessage(
            _instance.header,
            api004.ResponseHeader.deserializeBinaryFromReader
          );
          break;
        case 3:
          const messageInitializer3 = new TenantInfo();
          _reader.readMessage(
            messageInitializer3,
            TenantInfo.deserializeBinaryFromReader
          );
          (_instance.tenantInfos = _instance.tenantInfos || []).push(
            messageInitializer3
          );
          break;
        default:
          _reader.skipField();
      }
    }

    GetTenantIdResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetTenantIdResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api004.ResponseHeader.serializeBinaryToWriter
      );
    }
    if (_instance.tenantInfos && _instance.tenantInfos.length) {
      _writer.writeRepeatedMessage(
        3,
        _instance.tenantInfos as any,
        TenantInfo.serializeBinaryToWriter
      );
    }
  }

  private _header?: api004.ResponseHeader;
  private _tenantInfos?: TenantInfo[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetTenantIdResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetTenantIdResponse.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api004.ResponseHeader(_value.header)
      : undefined;
    this.tenantInfos = (_value.tenantInfos || []).map((m) => new TenantInfo(m));
    GetTenantIdResponse.refineValues(this);
  }
  get header(): api004.ResponseHeader | undefined {
    return this._header;
  }
  set header(value: api004.ResponseHeader | undefined) {
    this._header = value;
  }
  get tenantInfos(): TenantInfo[] | undefined {
    return this._tenantInfos;
  }
  set tenantInfos(value: TenantInfo[] | undefined) {
    this._tenantInfos = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetTenantIdResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetTenantIdResponse.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      tenantInfos: (this.tenantInfos || []).map((m) => m.toObject()),
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetTenantIdResponse.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      tenantInfos: (this.tenantInfos || []).map((m) =>
        m.toProtobufJSON(options)
      ),
    };
  }
}
export module GetTenantIdResponse {
  /**
   * Standard JavaScript object representation for GetTenantIdResponse
   */
  export interface AsObject {
    header?: api004.ResponseHeader.AsObject;
    tenantInfos?: TenantInfo.AsObject[];
  }

  /**
   * Protobuf JSON representation for GetTenantIdResponse
   */
  export interface AsProtobufJSON {
    header: api004.ResponseHeader.AsProtobufJSON | null;
    tenantInfos: TenantInfo.AsProtobufJSON[] | null;
  }
}

/**
 * Message implementation for api.customer.GetSelfCustomerRequest
 */
export class GetSelfCustomerRequest implements GrpcMessage {
  static id = 'api.customer.GetSelfCustomerRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetSelfCustomerRequest();
    GetSelfCustomerRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetSelfCustomerRequest) {
    _instance.header = _instance.header || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetSelfCustomerRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api004.RequestHeader();
          _reader.readMessage(
            _instance.header,
            api004.RequestHeader.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    GetSelfCustomerRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetSelfCustomerRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api004.RequestHeader.serializeBinaryToWriter
      );
    }
  }

  private _header?: api004.RequestHeader;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetSelfCustomerRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetSelfCustomerRequest.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api004.RequestHeader(_value.header)
      : undefined;
    GetSelfCustomerRequest.refineValues(this);
  }
  get header(): api004.RequestHeader | undefined {
    return this._header;
  }
  set header(value: api004.RequestHeader | undefined) {
    this._header = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetSelfCustomerRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetSelfCustomerRequest.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetSelfCustomerRequest.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
    };
  }
}
export module GetSelfCustomerRequest {
  /**
   * Standard JavaScript object representation for GetSelfCustomerRequest
   */
  export interface AsObject {
    header?: api004.RequestHeader.AsObject;
  }

  /**
   * Protobuf JSON representation for GetSelfCustomerRequest
   */
  export interface AsProtobufJSON {
    header: api004.RequestHeader.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for api.customer.GetSelfCustomerResponse
 */
export class GetSelfCustomerResponse implements GrpcMessage {
  static id = 'api.customer.GetSelfCustomerResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetSelfCustomerResponse();
    GetSelfCustomerResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetSelfCustomerResponse) {
    _instance.header = _instance.header || undefined;
    _instance.customer = _instance.customer || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetSelfCustomerResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api004.ResponseHeader();
          _reader.readMessage(
            _instance.header,
            api004.ResponseHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.customer = new storage006.Customer();
          _reader.readMessage(
            _instance.customer,
            storage006.Customer.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    GetSelfCustomerResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetSelfCustomerResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api004.ResponseHeader.serializeBinaryToWriter
      );
    }
    if (_instance.customer) {
      _writer.writeMessage(
        2,
        _instance.customer as any,
        storage006.Customer.serializeBinaryToWriter
      );
    }
  }

  private _header?: api004.ResponseHeader;
  private _customer?: storage006.Customer;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetSelfCustomerResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetSelfCustomerResponse.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api004.ResponseHeader(_value.header)
      : undefined;
    this.customer = _value.customer
      ? new storage006.Customer(_value.customer)
      : undefined;
    GetSelfCustomerResponse.refineValues(this);
  }
  get header(): api004.ResponseHeader | undefined {
    return this._header;
  }
  set header(value: api004.ResponseHeader | undefined) {
    this._header = value;
  }
  get customer(): storage006.Customer | undefined {
    return this._customer;
  }
  set customer(value: storage006.Customer | undefined) {
    this._customer = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetSelfCustomerResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetSelfCustomerResponse.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      customer: this.customer ? this.customer.toObject() : undefined,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetSelfCustomerResponse.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      customer: this.customer ? this.customer.toProtobufJSON(options) : null,
    };
  }
}
export module GetSelfCustomerResponse {
  /**
   * Standard JavaScript object representation for GetSelfCustomerResponse
   */
  export interface AsObject {
    header?: api004.ResponseHeader.AsObject;
    customer?: storage006.Customer.AsObject;
  }

  /**
   * Protobuf JSON representation for GetSelfCustomerResponse
   */
  export interface AsProtobufJSON {
    header: api004.ResponseHeader.AsProtobufJSON | null;
    customer: storage006.Customer.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for api.customer.ReadCustomersFilter
 */
export class ReadCustomersFilter implements GrpcMessage {
  static id = 'api.customer.ReadCustomersFilter';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ReadCustomersFilter();
    ReadCustomersFilter.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ReadCustomersFilter) {}

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ReadCustomersFilter,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.customerType = _reader.readEnum();
          break;
        case 2:
          _instance.disabled = _reader.readBool();
          break;
        default:
          _reader.skipField();
      }
    }

    ReadCustomersFilter.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ReadCustomersFilter,
    _writer: BinaryWriter
  ) {
    if (_instance.customerType || _instance.customerType === 0) {
      _writer.writeEnum(1, _instance.customerType);
    }
    if (_instance.disabled !== undefined && _instance.disabled !== null) {
      _writer.writeBool(2, _instance.disabled);
    }
  }

  private _customerType: storage006.CustomerType;
  private _disabled?: boolean;

  private _criteria: ReadCustomersFilter.CriteriaCase =
    ReadCustomersFilter.CriteriaCase.none;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ReadCustomersFilter to deeply clone from
   */
  constructor(_value?: RecursivePartial<ReadCustomersFilter.AsObject>) {
    _value = _value || {};
    this.customerType = _value.customerType;
    this.disabled = _value.disabled;
    ReadCustomersFilter.refineValues(this);
  }
  get customerType(): storage006.CustomerType {
    return this._customerType;
  }
  set customerType(value: storage006.CustomerType) {
    if (value !== undefined && value !== null) {
      this._criteria = ReadCustomersFilter.CriteriaCase.customerType;
    }
    this._customerType = value;
  }
  get disabled(): boolean | undefined {
    return this._disabled;
  }
  set disabled(value?: boolean) {
    this._disabled = value;
  }
  get criteria() {
    return this._criteria;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ReadCustomersFilter.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ReadCustomersFilter.AsObject {
    return {
      customerType: this.customerType,
      disabled: this.disabled,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ReadCustomersFilter.AsProtobufJSON {
    return {
      customerType:
        this.customerType === undefined
          ? null
          : storage006.CustomerType[
              this.customerType === null || this.customerType === undefined
                ? 0
                : this.customerType
            ],
      disabled: this.disabled === undefined ? null : this.disabled,
    };
  }
}
export module ReadCustomersFilter {
  /**
   * Standard JavaScript object representation for ReadCustomersFilter
   */
  export interface AsObject {
    customerType: storage006.CustomerType;
    disabled?: boolean;
  }

  /**
   * Protobuf JSON representation for ReadCustomersFilter
   */
  export interface AsProtobufJSON {
    customerType: string | null;
    disabled: boolean | null;
  }
  export enum CriteriaCase {
    none = 0,
    customerType = 1,
  }
}

/**
 * Message implementation for api.customer.GetManagedCustomersForBinaryTypeRequest
 */
export class GetManagedCustomersForBinaryTypeRequest implements GrpcMessage {
  static id = 'api.customer.GetManagedCustomersForBinaryTypeRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetManagedCustomersForBinaryTypeRequest();
    GetManagedCustomersForBinaryTypeRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetManagedCustomersForBinaryTypeRequest) {
    _instance.header = _instance.header || undefined;
    _instance.binaryType = _instance.binaryType || 0;
    _instance.paginated = _instance.paginated || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetManagedCustomersForBinaryTypeRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api004.RequestHeader();
          _reader.readMessage(
            _instance.header,
            api004.RequestHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.binaryType = _reader.readEnum();
          break;
        case 3:
          _instance.paginated = new api005.GetPaginatedRequest();
          _reader.readMessage(
            _instance.paginated,
            api005.GetPaginatedRequest.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    GetManagedCustomersForBinaryTypeRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetManagedCustomersForBinaryTypeRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api004.RequestHeader.serializeBinaryToWriter
      );
    }
    if (_instance.binaryType) {
      _writer.writeEnum(2, _instance.binaryType);
    }
    if (_instance.paginated) {
      _writer.writeMessage(
        3,
        _instance.paginated as any,
        api005.GetPaginatedRequest.serializeBinaryToWriter
      );
    }
  }

  private _header?: api004.RequestHeader;
  private _binaryType: storage001.BinaryType;
  private _paginated?: api005.GetPaginatedRequest;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetManagedCustomersForBinaryTypeRequest to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<GetManagedCustomersForBinaryTypeRequest.AsObject>
  ) {
    _value = _value || {};
    this.header = _value.header
      ? new api004.RequestHeader(_value.header)
      : undefined;
    this.binaryType = _value.binaryType;
    this.paginated = _value.paginated
      ? new api005.GetPaginatedRequest(_value.paginated)
      : undefined;
    GetManagedCustomersForBinaryTypeRequest.refineValues(this);
  }
  get header(): api004.RequestHeader | undefined {
    return this._header;
  }
  set header(value: api004.RequestHeader | undefined) {
    this._header = value;
  }
  get binaryType(): storage001.BinaryType {
    return this._binaryType;
  }
  set binaryType(value: storage001.BinaryType) {
    this._binaryType = value;
  }
  get paginated(): api005.GetPaginatedRequest | undefined {
    return this._paginated;
  }
  set paginated(value: api005.GetPaginatedRequest | undefined) {
    this._paginated = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetManagedCustomersForBinaryTypeRequest.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetManagedCustomersForBinaryTypeRequest.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      binaryType: this.binaryType,
      paginated: this.paginated ? this.paginated.toObject() : undefined,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetManagedCustomersForBinaryTypeRequest.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      binaryType:
        storage001.BinaryType[
          this.binaryType === null || this.binaryType === undefined
            ? 0
            : this.binaryType
        ],
      paginated: this.paginated ? this.paginated.toProtobufJSON(options) : null,
    };
  }
}
export module GetManagedCustomersForBinaryTypeRequest {
  /**
   * Standard JavaScript object representation for GetManagedCustomersForBinaryTypeRequest
   */
  export interface AsObject {
    header?: api004.RequestHeader.AsObject;
    binaryType: storage001.BinaryType;
    paginated?: api005.GetPaginatedRequest.AsObject;
  }

  /**
   * Protobuf JSON representation for GetManagedCustomersForBinaryTypeRequest
   */
  export interface AsProtobufJSON {
    header: api004.RequestHeader.AsProtobufJSON | null;
    binaryType: string;
    paginated: api005.GetPaginatedRequest.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for api.customer.TenantInfo
 */
export class TenantInfo implements GrpcMessage {
  static id = 'api.customer.TenantInfo';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new TenantInfo();
    TenantInfo.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: TenantInfo) {
    _instance.tenantId = _instance.tenantId || '';
    _instance.companyName = _instance.companyName || '';
    _instance.passwordSignInAllowed = _instance.passwordSignInAllowed || false;
    _instance.samlSignInAllowed = _instance.samlSignInAllowed || false;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: TenantInfo,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.tenantId = _reader.readString();
          break;
        case 3:
          _instance.companyName = _reader.readString();
          break;
        case 4:
          _instance.passwordSignInAllowed = _reader.readBool();
          break;
        case 5:
          _instance.samlSignInAllowed = _reader.readBool();
          break;
        default:
          _reader.skipField();
      }
    }

    TenantInfo.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(_instance: TenantInfo, _writer: BinaryWriter) {
    if (_instance.tenantId) {
      _writer.writeString(1, _instance.tenantId);
    }
    if (_instance.companyName) {
      _writer.writeString(3, _instance.companyName);
    }
    if (_instance.passwordSignInAllowed) {
      _writer.writeBool(4, _instance.passwordSignInAllowed);
    }
    if (_instance.samlSignInAllowed) {
      _writer.writeBool(5, _instance.samlSignInAllowed);
    }
  }

  private _tenantId: string;
  private _companyName: string;
  private _passwordSignInAllowed: boolean;
  private _samlSignInAllowed: boolean;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of TenantInfo to deeply clone from
   */
  constructor(_value?: RecursivePartial<TenantInfo.AsObject>) {
    _value = _value || {};
    this.tenantId = _value.tenantId;
    this.companyName = _value.companyName;
    this.passwordSignInAllowed = _value.passwordSignInAllowed;
    this.samlSignInAllowed = _value.samlSignInAllowed;
    TenantInfo.refineValues(this);
  }
  get tenantId(): string {
    return this._tenantId;
  }
  set tenantId(value: string) {
    this._tenantId = value;
  }
  get companyName(): string {
    return this._companyName;
  }
  set companyName(value: string) {
    this._companyName = value;
  }
  get passwordSignInAllowed(): boolean {
    return this._passwordSignInAllowed;
  }
  set passwordSignInAllowed(value: boolean) {
    this._passwordSignInAllowed = value;
  }
  get samlSignInAllowed(): boolean {
    return this._samlSignInAllowed;
  }
  set samlSignInAllowed(value: boolean) {
    this._samlSignInAllowed = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    TenantInfo.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): TenantInfo.AsObject {
    return {
      tenantId: this.tenantId,
      companyName: this.companyName,
      passwordSignInAllowed: this.passwordSignInAllowed,
      samlSignInAllowed: this.samlSignInAllowed,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): TenantInfo.AsProtobufJSON {
    return {
      tenantId: this.tenantId,
      companyName: this.companyName,
      passwordSignInAllowed: this.passwordSignInAllowed,
      samlSignInAllowed: this.samlSignInAllowed,
    };
  }
}
export module TenantInfo {
  /**
   * Standard JavaScript object representation for TenantInfo
   */
  export interface AsObject {
    tenantId: string;
    companyName: string;
    passwordSignInAllowed: boolean;
    samlSignInAllowed: boolean;
  }

  /**
   * Protobuf JSON representation for TenantInfo
   */
  export interface AsProtobufJSON {
    tenantId: string;
    companyName: string;
    passwordSignInAllowed: boolean;
    samlSignInAllowed: boolean;
  }
}
