import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'app-archive-select',
  templateUrl: './archive-select.component.html',
  styleUrls: ['./archive-select.component.scss'],
})
export class ArchiveSelectComponent implements OnInit, OnChanges {
  @Input() archiveChecked: boolean = false;
  @Input() hideComponent: boolean = false;
  @Input() title = 'Display archived records';
  @Output() selectArchiveEvent = new EventEmitter<boolean>();

  constructor() {}

  async ngOnInit() {
    this.selectArchiveEvent.emit(this.archiveChecked);
  }

  ngOnChanges(): void {
    this.ngOnInit();
  }

  onArchiveSelect(event: MatCheckboxChange) {
    this.archiveChecked = event.checked;
    this.selectArchiveEvent.emit(this.archiveChecked);
  }
}
