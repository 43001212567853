/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import {
  GrpcMessage,
  RecursivePartial,
  ToProtobufJSONOptions,
} from '@ngx-grpc/common';
import { BinaryReader, BinaryWriter, ByteSource } from 'google-protobuf';
import * as googleProtobuf000 from '@ngx-grpc/well-known-types';
import * as storage001 from '../../../../src/main/proto/storage/customer-data-set-reference.pb';
import * as matching002 from '../../../../src/main/proto/matching/matching-config.pb';
/**
 * Message implementation for dentsuprototype.TimeWindow
 */
export class TimeWindow implements GrpcMessage {
  static id = 'dentsuprototype.TimeWindow';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new TimeWindow();
    TimeWindow.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: TimeWindow) {
    _instance.publisherStartTime = _instance.publisherStartTime || undefined;
    _instance.publisherEndTime = _instance.publisherEndTime || undefined;
    _instance.advertiserStartTime = _instance.advertiserStartTime || undefined;
    _instance.advertiserEndTime = _instance.advertiserEndTime || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: TimeWindow,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.publisherStartTime = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.publisherStartTime,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.publisherEndTime = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.publisherEndTime,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 3:
          _instance.advertiserStartTime = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.advertiserStartTime,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 4:
          _instance.advertiserEndTime = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.advertiserEndTime,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    TimeWindow.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(_instance: TimeWindow, _writer: BinaryWriter) {
    if (_instance.publisherStartTime) {
      _writer.writeMessage(
        1,
        _instance.publisherStartTime as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.publisherEndTime) {
      _writer.writeMessage(
        2,
        _instance.publisherEndTime as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.advertiserStartTime) {
      _writer.writeMessage(
        3,
        _instance.advertiserStartTime as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.advertiserEndTime) {
      _writer.writeMessage(
        4,
        _instance.advertiserEndTime as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
  }

  private _publisherStartTime?: googleProtobuf000.Timestamp;
  private _publisherEndTime?: googleProtobuf000.Timestamp;
  private _advertiserStartTime?: googleProtobuf000.Timestamp;
  private _advertiserEndTime?: googleProtobuf000.Timestamp;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of TimeWindow to deeply clone from
   */
  constructor(_value?: RecursivePartial<TimeWindow.AsObject>) {
    _value = _value || {};
    this.publisherStartTime = _value.publisherStartTime
      ? new googleProtobuf000.Timestamp(_value.publisherStartTime)
      : undefined;
    this.publisherEndTime = _value.publisherEndTime
      ? new googleProtobuf000.Timestamp(_value.publisherEndTime)
      : undefined;
    this.advertiserStartTime = _value.advertiserStartTime
      ? new googleProtobuf000.Timestamp(_value.advertiserStartTime)
      : undefined;
    this.advertiserEndTime = _value.advertiserEndTime
      ? new googleProtobuf000.Timestamp(_value.advertiserEndTime)
      : undefined;
    TimeWindow.refineValues(this);
  }
  get publisherStartTime(): googleProtobuf000.Timestamp | undefined {
    return this._publisherStartTime;
  }
  set publisherStartTime(value: googleProtobuf000.Timestamp | undefined) {
    this._publisherStartTime = value;
  }
  get publisherEndTime(): googleProtobuf000.Timestamp | undefined {
    return this._publisherEndTime;
  }
  set publisherEndTime(value: googleProtobuf000.Timestamp | undefined) {
    this._publisherEndTime = value;
  }
  get advertiserStartTime(): googleProtobuf000.Timestamp | undefined {
    return this._advertiserStartTime;
  }
  set advertiserStartTime(value: googleProtobuf000.Timestamp | undefined) {
    this._advertiserStartTime = value;
  }
  get advertiserEndTime(): googleProtobuf000.Timestamp | undefined {
    return this._advertiserEndTime;
  }
  set advertiserEndTime(value: googleProtobuf000.Timestamp | undefined) {
    this._advertiserEndTime = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    TimeWindow.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): TimeWindow.AsObject {
    return {
      publisherStartTime: this.publisherStartTime
        ? this.publisherStartTime.toObject()
        : undefined,
      publisherEndTime: this.publisherEndTime
        ? this.publisherEndTime.toObject()
        : undefined,
      advertiserStartTime: this.advertiserStartTime
        ? this.advertiserStartTime.toObject()
        : undefined,
      advertiserEndTime: this.advertiserEndTime
        ? this.advertiserEndTime.toObject()
        : undefined,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): TimeWindow.AsProtobufJSON {
    return {
      publisherStartTime: this.publisherStartTime
        ? this.publisherStartTime.toProtobufJSON(options)
        : null,
      publisherEndTime: this.publisherEndTime
        ? this.publisherEndTime.toProtobufJSON(options)
        : null,
      advertiserStartTime: this.advertiserStartTime
        ? this.advertiserStartTime.toProtobufJSON(options)
        : null,
      advertiserEndTime: this.advertiserEndTime
        ? this.advertiserEndTime.toProtobufJSON(options)
        : null,
    };
  }
}
export module TimeWindow {
  /**
   * Standard JavaScript object representation for TimeWindow
   */
  export interface AsObject {
    publisherStartTime?: googleProtobuf000.Timestamp.AsObject;
    publisherEndTime?: googleProtobuf000.Timestamp.AsObject;
    advertiserStartTime?: googleProtobuf000.Timestamp.AsObject;
    advertiserEndTime?: googleProtobuf000.Timestamp.AsObject;
  }

  /**
   * Protobuf JSON representation for TimeWindow
   */
  export interface AsProtobufJSON {
    publisherStartTime: googleProtobuf000.Timestamp.AsProtobufJSON | null;
    publisherEndTime: googleProtobuf000.Timestamp.AsProtobufJSON | null;
    advertiserStartTime: googleProtobuf000.Timestamp.AsProtobufJSON | null;
    advertiserEndTime: googleProtobuf000.Timestamp.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for dentsuprototype.DentsuPrototypeConfig
 */
export class DentsuPrototypeConfig implements GrpcMessage {
  static id = 'dentsuprototype.DentsuPrototypeConfig';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new DentsuPrototypeConfig();
    DentsuPrototypeConfig.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: DentsuPrototypeConfig) {
    _instance.tiktokIdentityGraph = _instance.tiktokIdentityGraph || undefined;
    _instance.tiktokImpressions = _instance.tiktokImpressions || undefined;
    _instance.dentsuIdentityGraph = _instance.dentsuIdentityGraph || undefined;
    _instance.dentsuConversions = _instance.dentsuConversions || undefined;
    _instance.matchingConfig = _instance.matchingConfig || undefined;
    _instance.timeWindow = _instance.timeWindow || undefined;
    _instance.campaignStartTime = _instance.campaignStartTime || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: DentsuPrototypeConfig,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.tiktokIdentityGraph =
            new storage001.CustomerDataSetReference();
          _reader.readMessage(
            _instance.tiktokIdentityGraph,
            storage001.CustomerDataSetReference.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.tiktokImpressions =
            new storage001.CustomerDataSetReference();
          _reader.readMessage(
            _instance.tiktokImpressions,
            storage001.CustomerDataSetReference.deserializeBinaryFromReader
          );
          break;
        case 3:
          _instance.dentsuIdentityGraph =
            new storage001.CustomerDataSetReference();
          _reader.readMessage(
            _instance.dentsuIdentityGraph,
            storage001.CustomerDataSetReference.deserializeBinaryFromReader
          );
          break;
        case 4:
          _instance.dentsuConversions =
            new storage001.CustomerDataSetReference();
          _reader.readMessage(
            _instance.dentsuConversions,
            storage001.CustomerDataSetReference.deserializeBinaryFromReader
          );
          break;
        case 5:
          _instance.matchingConfig = new matching002.MatchingConfig();
          _reader.readMessage(
            _instance.matchingConfig,
            matching002.MatchingConfig.deserializeBinaryFromReader
          );
          break;
        case 6:
          _instance.timeWindow = new TimeWindow();
          _reader.readMessage(
            _instance.timeWindow,
            TimeWindow.deserializeBinaryFromReader
          );
          break;
        case 7:
          _instance.campaignStartTime = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.campaignStartTime,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    DentsuPrototypeConfig.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: DentsuPrototypeConfig,
    _writer: BinaryWriter
  ) {
    if (_instance.tiktokIdentityGraph) {
      _writer.writeMessage(
        1,
        _instance.tiktokIdentityGraph as any,
        storage001.CustomerDataSetReference.serializeBinaryToWriter
      );
    }
    if (_instance.tiktokImpressions) {
      _writer.writeMessage(
        2,
        _instance.tiktokImpressions as any,
        storage001.CustomerDataSetReference.serializeBinaryToWriter
      );
    }
    if (_instance.dentsuIdentityGraph) {
      _writer.writeMessage(
        3,
        _instance.dentsuIdentityGraph as any,
        storage001.CustomerDataSetReference.serializeBinaryToWriter
      );
    }
    if (_instance.dentsuConversions) {
      _writer.writeMessage(
        4,
        _instance.dentsuConversions as any,
        storage001.CustomerDataSetReference.serializeBinaryToWriter
      );
    }
    if (_instance.matchingConfig) {
      _writer.writeMessage(
        5,
        _instance.matchingConfig as any,
        matching002.MatchingConfig.serializeBinaryToWriter
      );
    }
    if (_instance.timeWindow) {
      _writer.writeMessage(
        6,
        _instance.timeWindow as any,
        TimeWindow.serializeBinaryToWriter
      );
    }
    if (_instance.campaignStartTime) {
      _writer.writeMessage(
        7,
        _instance.campaignStartTime as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
  }

  private _tiktokIdentityGraph?: storage001.CustomerDataSetReference;
  private _tiktokImpressions?: storage001.CustomerDataSetReference;
  private _dentsuIdentityGraph?: storage001.CustomerDataSetReference;
  private _dentsuConversions?: storage001.CustomerDataSetReference;
  private _matchingConfig?: matching002.MatchingConfig;
  private _timeWindow?: TimeWindow;
  private _campaignStartTime?: googleProtobuf000.Timestamp;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of DentsuPrototypeConfig to deeply clone from
   */
  constructor(_value?: RecursivePartial<DentsuPrototypeConfig.AsObject>) {
    _value = _value || {};
    this.tiktokIdentityGraph = _value.tiktokIdentityGraph
      ? new storage001.CustomerDataSetReference(_value.tiktokIdentityGraph)
      : undefined;
    this.tiktokImpressions = _value.tiktokImpressions
      ? new storage001.CustomerDataSetReference(_value.tiktokImpressions)
      : undefined;
    this.dentsuIdentityGraph = _value.dentsuIdentityGraph
      ? new storage001.CustomerDataSetReference(_value.dentsuIdentityGraph)
      : undefined;
    this.dentsuConversions = _value.dentsuConversions
      ? new storage001.CustomerDataSetReference(_value.dentsuConversions)
      : undefined;
    this.matchingConfig = _value.matchingConfig
      ? new matching002.MatchingConfig(_value.matchingConfig)
      : undefined;
    this.timeWindow = _value.timeWindow
      ? new TimeWindow(_value.timeWindow)
      : undefined;
    this.campaignStartTime = _value.campaignStartTime
      ? new googleProtobuf000.Timestamp(_value.campaignStartTime)
      : undefined;
    DentsuPrototypeConfig.refineValues(this);
  }
  get tiktokIdentityGraph(): storage001.CustomerDataSetReference | undefined {
    return this._tiktokIdentityGraph;
  }
  set tiktokIdentityGraph(
    value: storage001.CustomerDataSetReference | undefined
  ) {
    this._tiktokIdentityGraph = value;
  }
  get tiktokImpressions(): storage001.CustomerDataSetReference | undefined {
    return this._tiktokImpressions;
  }
  set tiktokImpressions(
    value: storage001.CustomerDataSetReference | undefined
  ) {
    this._tiktokImpressions = value;
  }
  get dentsuIdentityGraph(): storage001.CustomerDataSetReference | undefined {
    return this._dentsuIdentityGraph;
  }
  set dentsuIdentityGraph(
    value: storage001.CustomerDataSetReference | undefined
  ) {
    this._dentsuIdentityGraph = value;
  }
  get dentsuConversions(): storage001.CustomerDataSetReference | undefined {
    return this._dentsuConversions;
  }
  set dentsuConversions(
    value: storage001.CustomerDataSetReference | undefined
  ) {
    this._dentsuConversions = value;
  }
  get matchingConfig(): matching002.MatchingConfig | undefined {
    return this._matchingConfig;
  }
  set matchingConfig(value: matching002.MatchingConfig | undefined) {
    this._matchingConfig = value;
  }
  get timeWindow(): TimeWindow | undefined {
    return this._timeWindow;
  }
  set timeWindow(value: TimeWindow | undefined) {
    this._timeWindow = value;
  }
  get campaignStartTime(): googleProtobuf000.Timestamp | undefined {
    return this._campaignStartTime;
  }
  set campaignStartTime(value: googleProtobuf000.Timestamp | undefined) {
    this._campaignStartTime = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    DentsuPrototypeConfig.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): DentsuPrototypeConfig.AsObject {
    return {
      tiktokIdentityGraph: this.tiktokIdentityGraph
        ? this.tiktokIdentityGraph.toObject()
        : undefined,
      tiktokImpressions: this.tiktokImpressions
        ? this.tiktokImpressions.toObject()
        : undefined,
      dentsuIdentityGraph: this.dentsuIdentityGraph
        ? this.dentsuIdentityGraph.toObject()
        : undefined,
      dentsuConversions: this.dentsuConversions
        ? this.dentsuConversions.toObject()
        : undefined,
      matchingConfig: this.matchingConfig
        ? this.matchingConfig.toObject()
        : undefined,
      timeWindow: this.timeWindow ? this.timeWindow.toObject() : undefined,
      campaignStartTime: this.campaignStartTime
        ? this.campaignStartTime.toObject()
        : undefined,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): DentsuPrototypeConfig.AsProtobufJSON {
    return {
      tiktokIdentityGraph: this.tiktokIdentityGraph
        ? this.tiktokIdentityGraph.toProtobufJSON(options)
        : null,
      tiktokImpressions: this.tiktokImpressions
        ? this.tiktokImpressions.toProtobufJSON(options)
        : null,
      dentsuIdentityGraph: this.dentsuIdentityGraph
        ? this.dentsuIdentityGraph.toProtobufJSON(options)
        : null,
      dentsuConversions: this.dentsuConversions
        ? this.dentsuConversions.toProtobufJSON(options)
        : null,
      matchingConfig: this.matchingConfig
        ? this.matchingConfig.toProtobufJSON(options)
        : null,
      timeWindow: this.timeWindow
        ? this.timeWindow.toProtobufJSON(options)
        : null,
      campaignStartTime: this.campaignStartTime
        ? this.campaignStartTime.toProtobufJSON(options)
        : null,
    };
  }
}
export module DentsuPrototypeConfig {
  /**
   * Standard JavaScript object representation for DentsuPrototypeConfig
   */
  export interface AsObject {
    tiktokIdentityGraph?: storage001.CustomerDataSetReference.AsObject;
    tiktokImpressions?: storage001.CustomerDataSetReference.AsObject;
    dentsuIdentityGraph?: storage001.CustomerDataSetReference.AsObject;
    dentsuConversions?: storage001.CustomerDataSetReference.AsObject;
    matchingConfig?: matching002.MatchingConfig.AsObject;
    timeWindow?: TimeWindow.AsObject;
    campaignStartTime?: googleProtobuf000.Timestamp.AsObject;
  }

  /**
   * Protobuf JSON representation for DentsuPrototypeConfig
   */
  export interface AsProtobufJSON {
    tiktokIdentityGraph: storage001.CustomerDataSetReference.AsProtobufJSON | null;
    tiktokImpressions: storage001.CustomerDataSetReference.AsProtobufJSON | null;
    dentsuIdentityGraph: storage001.CustomerDataSetReference.AsProtobufJSON | null;
    dentsuConversions: storage001.CustomerDataSetReference.AsProtobufJSON | null;
    matchingConfig: matching002.MatchingConfig.AsProtobufJSON | null;
    timeWindow: TimeWindow.AsProtobufJSON | null;
    campaignStartTime: googleProtobuf000.Timestamp.AsProtobufJSON | null;
  }
}
