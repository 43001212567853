import { Injectable } from '@angular/core';
import { EncryptedValueAndKey } from 'app/services/generated/src/main/proto/storage/encrypted.pb';

type PublicOrPrivate = 'PUBLIC' | 'PRIVATE';

// Cannot encrypt more than 64 GiB with a single AES key using AES-GCM.
// See NIST SP 800-38D, section 5.2.1.1, Input Data: len(P) ≤ (2^39)-256.
// Also see NCC Group finding NCC-E005887-AH7 from 2023 security assessment.
const AES_GCM_PLAINTEXT_MAX_BYTES: number = 2 ** 36 - 32;

export class EncryptedSymmetricKey {
  public constructor(
    /**
     * The symmetric key represented as a CryptoKey.
     */
    public key: CryptoKey,
    /**
     * Base-64 encoded symmetric key encrypted with the public key.
     */
    public encryptedSymmetricKey: ArrayBuffer,
    /**
     * Public key in PEM format used to encrypt the symmetric key.
     */
    public publicKeyPem: string,
    /**
     * Number of bytes encrypted with this symmetric key.
     */
    public bytesEncrypted: number = 0
  ) {}
}

@Injectable({
  providedIn: 'root',
})
export class EncryptionService {
  private textEncoder = new TextEncoder();
  private textDecoder = new TextDecoder();

  /**
   * Decodes a base64-encoded string into a byte array.
   *
   * @param base64str The base64-encoded string.
   * @returns The decoded byte array.
   */
  public base64ToBytes(base64str: string): Uint8Array {
    const decodedString = atob(base64str);
    const byteArray = new Uint8Array(decodedString.length);
    for (let i = 0; i < decodedString.length; i++) {
      byteArray[i] = decodedString.charCodeAt(i);
    }
    return byteArray;
  }

  /**
   * Encodes a byte array as a base64-encoded string.
   *
   * @param bytes The byte array.
   * @returns The base64-encoded string.
   */
  public bytesToBase64(bytes: Uint8Array): string {
    let binary = '';
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return btoa(binary);
  }

  /**
   * Encrypts a plaintext value with a securely generated AES 256-bit key using
   * AES-GCM, then encrypts the base64-encoded AES key with the given RSA public key.
   *
   * @param plaintext The data to encrypt.
   * @param publicKeyPem A public RSA key in PEM format.
   * @returns The encrypted data.
   */
  public async encrypt(
    plaintext: Uint8Array | ArrayBuffer | string,
    publicKeyPem: string
  ): Promise<EncryptedValueAndKey> {
    if (typeof plaintext === 'string') {
      plaintext = this.textEncoder.encode(plaintext);
    }
    const symmetricKey = await this.generateSymmetricKey(publicKeyPem);
    return this.encryptWithKey(plaintext, symmetricKey);
  }

  /**
   * Encrypts a plaintext value with a securely generated AES 256-bit key using
   * AES-GCM.
   *
   * @param plaintext The data to encrypt.
   * @param publicKeyPem A public RSA key in PEM format.
   * @returns The encrypted data.
   */
  public async encryptWithKey(
    plaintext: Uint8Array | ArrayBuffer | string,
    symmetricKey: EncryptedSymmetricKey
  ): Promise<EncryptedValueAndKey> {
    if (typeof plaintext === 'string') {
      plaintext = this.textEncoder.encode(plaintext);
    }
    const iv = crypto.getRandomValues(new Uint8Array(12));
    // encrypt() concatenates the ciphertext and the tag like this: C | T
    // See https://www.w3.org/TR/WebCryptoAPI/#aes-gcm-operations
    const ciphertextWithTag = await crypto.subtle.encrypt(
      { name: 'AES-GCM', iv: iv },
      symmetricKey.key,
      plaintext
    );

    const tagLengthBytes = 16;
    const combined = new Uint8Array(ciphertextWithTag);

    const encryptedValueAndKey = new EncryptedValueAndKey({
      value: combined.slice(0, combined.length - tagLengthBytes),
      initVector: new Uint8Array(iv),
      tag: combined.slice(combined.length - tagLengthBytes),
      encryptedKey: new Uint8Array(symmetricKey.encryptedSymmetricKey),
    });

    symmetricKey.bytesEncrypted += encryptedValueAndKey.value.length;
    if (symmetricKey.bytesEncrypted > AES_GCM_PLAINTEXT_MAX_BYTES) {
      throw new Error(
        `Cannot encrypt more than ${AES_GCM_PLAINTEXT_MAX_BYTES} bytes with a single AES key.`
      );
    }

    return encryptedValueAndKey;
  }

  /**
   * Creates a new AES-256 key and encrypts it with the given public key.
   * The return value of this function can be passed to encryptWithKey()
   * to encrypt multiple files with the same AES key.
   *
   * @param publicKeyPem A public RSA key in PEM format.
   * @returns The symmetric key and associated metadata.
   */
  public async generateSymmetricKey(
    publicKeyPem: string
  ): Promise<EncryptedSymmetricKey> {
    const publicKey = await this.importPublicRSAKey(publicKeyPem);
    const aesKey = await crypto.subtle.generateKey(
      { name: 'AES-GCM', length: 256 },
      true,
      ['encrypt']
    );
    const aesKeyBytes = await crypto.subtle.exportKey('raw', aesKey);
    const encryptedAesKey = await crypto.subtle.encrypt(
      { name: 'RSA-OAEP' },
      publicKey,
      this.textEncoder.encode(this.bytesToBase64(new Uint8Array(aesKeyBytes)))
    );
    return new EncryptedSymmetricKey(aesKey, encryptedAesKey, publicKeyPem);
  }

  /**
   * Decrypts encrypted data that was encrypted using AES-GCM.
   *
   * @param encryptedData The encrypted data.
   * @param privateKeyPem The private RSA key that is paired with the public key used to encrypt the symmetric key.
   * @returns The plaintext data.
   */
  public async decrypt(
    encryptedData: EncryptedValueAndKey,
    privateKeyPem: string
  ): Promise<ArrayBuffer> {
    const symmetricKeyBase64 = await this.decryptSymmetricKey(
      encryptedData.encryptedKey,
      privateKeyPem
    );
    const symmetricKeyBytes = this.base64ToBytes(
      this.textDecoder.decode(symmetricKeyBase64)
    );
    const symmetricKey = await crypto.subtle.importKey(
      'raw',
      symmetricKeyBytes,
      'AES-GCM',
      false,
      ['decrypt']
    );
    const combined = new Uint8Array(
      encryptedData.value.length + encryptedData.tag.length
    );
    combined.set(encryptedData.value, 0);
    combined.set(encryptedData.tag, encryptedData.value.length);

    return crypto.subtle.decrypt(
      { name: 'AES-GCM', iv: encryptedData.initVector },
      symmetricKey,
      combined
    );
  }

  /**
   * Decrypts a symmetric key that was encrypted by a public RSA key. Note the
   * symmetric key is usually base64 encoded before encryption. Therefore, this
   * method usually returns a base64-encoded symmetric key instead of the raw
   * bytes.
   *
   * @param encryptedSymmetricKey The encrypted symmetric key.
   * @param privateKeyPem The private RSA key that is paired with the public key used to encrypt the symmetric key.
   * @returns The base64-encoded symmetric key.
   */
  public async decryptSymmetricKey(
    encryptedSymmetricKey: ArrayBuffer,
    privateKeyPem: string
  ): Promise<ArrayBuffer> {
    const privateKey = await this.importPrivateRSAKey(privateKeyPem);
    const symmetricKeyBytes = await crypto.subtle.decrypt(
      { name: 'RSA-OAEP' },
      privateKey,
      encryptedSymmetricKey
    );
    return symmetricKeyBytes;
  }

  /**
   * Converts a key in PEM format into a byte array.
   *
   * @param keyPem The key in PEM format.
   * @param publicOrPrivate Either "PUBLIC" or "PRIVATE".
   * @returns The key as a byte array.
   */
  pemToByteArray(keyPem: string, publicOrPrivate: PublicOrPrivate): Uint8Array {
    // Strip header and footer.
    const pemHeader = `-----BEGIN ${publicOrPrivate} KEY-----`;
    const pemFooter = `-----END ${publicOrPrivate} KEY-----`;
    if (keyPem.includes(pemHeader) && keyPem.includes(pemFooter)) {
      keyPem = keyPem.substring(
        pemHeader.length,
        keyPem.length - pemFooter.length - 1
      );
    }

    return this.base64ToBytes(keyPem);
  }

  /**
   * Converts a public RSA key in PEM format into a CryptoKey by importing
   * it into the WebCrypto API.
   *
   * @param publicKeyPem A public RSA key in PEM format.
   * @returns The public RSA key as a CryptoKey.
   */
  importPublicRSAKey(publicKeyPem: string): Promise<CryptoKey> {
    const publicKeyBytes = this.pemToByteArray(publicKeyPem, 'PUBLIC');
    return crypto.subtle.importKey(
      'spki',
      publicKeyBytes,
      { name: 'RSA-OAEP', hash: 'SHA-256' },
      true,
      ['encrypt']
    );
  }

  /**
   * Converts a private RSA key in PEM format into a CryptoKey by importing
   * it into the WebCrypto API.
   *
   * @param privateKeyPem A private RSA key in PEM format.
   * @returns The private RSA key as a CryptoKey.
   */
  importPrivateRSAKey(privateKeyPem: string): Promise<CryptoKey> {
    const privateKeyBytes = this.pemToByteArray(privateKeyPem, 'PRIVATE');
    return crypto.subtle.importKey(
      'pkcs8',
      privateKeyBytes,
      { name: 'RSA-OAEP', hash: 'SHA-256' },
      true,
      ['decrypt']
    );
  }
}
