<div class="field-dropdown-label" *ngIf="!displayAsBanner && displayTitle">
  <span class="field-title">{{ title }}</span>
</div>
<div class="mat-mdc-card" *ngIf="displayAsBanner">
  <div class="field-row">
    <mat-form-field class="dropdown">
      <mat-label>{{ label }}</mat-label>
      <mat-select
        class="select"
        data-testid="customerSelect"
        (selectionChange)="onCustomerSelect($event.value)"
        placeholder="--Select--"
        [disabled]="readOnly"
        [value]="getSelectedCustomerId()"
      >
        <mat-option *ngFor="let customer of customers" [value]="customer.id">
          {{ customer.companyName }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="search" *ngIf="showSearchBox">
      <mat-label>Search</mat-label>
      <input
        matInput
        #search
        type="text"
        size="40"
        placeholder="AnonymCo"
        (input)="onTextSearch()"
      />
    </mat-form-field>
    <div *ngIf="isLoading" class="loader">
      <mat-spinner data-testid="datasetsSpinner" diameter="42"></mat-spinner>
    </div>
  </div>
  <div class="field-row">
    <label class="checkbox" *ngIf="showArchiveToggle">
      <input
        type="checkbox"
        [checked]="filterArchived"
        (change)="onFilterArchivedChange()"
      />
      Filter archived
    </label>
  </div>
</div>
<div *ngIf="!displayAsBanner">
  <mat-form-field class="dropdown">
    <mat-label>{{ label }}</mat-label>
    <mat-select
      class="select"
      data-testid="customerSelect"
      (selectionChange)="onCustomerSelect($event.value)"
      placeholder="--Select--"
      [disabled]="readOnly"
      [value]="getSelectedCustomerId()"
    >
      <mat-option *ngFor="let customer of customers" [value]="customer.id">
        {{ customer.companyName }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field class="search" *ngIf="showSearchBox">
    <mat-label>Search</mat-label>
    <input
      matInput
      #search
      type="text"
      size="40"
      placeholder="AnonymCo"
      (input)="onTextSearch()"
    />
  </mat-form-field>
  <label class="checkbox-form" *ngIf="showArchiveToggle">
    <input
      type="checkbox"
      [checked]="filterArchived"
      (change)="onFilterArchivedChange()"
    />
    Filter archived
  </label>
</div>
