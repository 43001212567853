/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import {
  GrpcMessage,
  RecursivePartial,
  ToProtobufJSONOptions,
} from '@ngx-grpc/common';
import { BinaryReader, BinaryWriter, ByteSource } from 'google-protobuf';
export enum FeatureType {
  FEATURE_TYPE_UNSPECIFIED = 0,
  FEATURE_TYPE_TEST = 1,
}
/**
 * Message implementation for config.Config
 */
export class Config implements GrpcMessage {
  static id = 'config.Config';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new Config();
    Config.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: Config) {
    _instance.experimentConfig = _instance.experimentConfig || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(_instance: Config, _reader: BinaryReader) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.experimentConfig = new ExperimentConfig();
          _reader.readMessage(
            _instance.experimentConfig,
            ExperimentConfig.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    Config.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(_instance: Config, _writer: BinaryWriter) {
    if (_instance.experimentConfig) {
      _writer.writeMessage(
        1,
        _instance.experimentConfig as any,
        ExperimentConfig.serializeBinaryToWriter
      );
    }
  }

  private _experimentConfig?: ExperimentConfig;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of Config to deeply clone from
   */
  constructor(_value?: RecursivePartial<Config.AsObject>) {
    _value = _value || {};
    this.experimentConfig = _value.experimentConfig
      ? new ExperimentConfig(_value.experimentConfig)
      : undefined;
    Config.refineValues(this);
  }
  get experimentConfig(): ExperimentConfig | undefined {
    return this._experimentConfig;
  }
  set experimentConfig(value: ExperimentConfig | undefined) {
    this._experimentConfig = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    Config.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): Config.AsObject {
    return {
      experimentConfig: this.experimentConfig
        ? this.experimentConfig.toObject()
        : undefined,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): Config.AsProtobufJSON {
    return {
      experimentConfig: this.experimentConfig
        ? this.experimentConfig.toProtobufJSON(options)
        : null,
    };
  }
}
export module Config {
  /**
   * Standard JavaScript object representation for Config
   */
  export interface AsObject {
    experimentConfig?: ExperimentConfig.AsObject;
  }

  /**
   * Protobuf JSON representation for Config
   */
  export interface AsProtobufJSON {
    experimentConfig: ExperimentConfig.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for config.ExperimentConfig
 */
export class ExperimentConfig implements GrpcMessage {
  static id = 'config.ExperimentConfig';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ExperimentConfig();
    ExperimentConfig.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ExperimentConfig) {
    _instance.experiments = _instance.experiments || {};
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ExperimentConfig,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          const msg_1 = {} as any;
          _reader.readMessage(
            msg_1,
            ExperimentConfig.ExperimentsEntry.deserializeBinaryFromReader
          );
          _instance.experiments = _instance.experiments || {};
          _instance.experiments[msg_1.key] = msg_1.value;
          break;
        default:
          _reader.skipField();
      }
    }

    ExperimentConfig.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ExperimentConfig,
    _writer: BinaryWriter
  ) {
    if (!!_instance.experiments) {
      const keys_1 = Object.keys(_instance.experiments as any);

      if (keys_1.length) {
        const repeated_1 = keys_1
          .map((key) => ({
            key: key,
            value: (_instance.experiments as any)[key],
          }))
          .reduce((r, v) => [...r, v], [] as any[]);

        _writer.writeRepeatedMessage(
          1,
          repeated_1,
          ExperimentConfig.ExperimentsEntry.serializeBinaryToWriter
        );
      }
    }
  }

  private _experiments: { [prop: string]: Experiment };

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ExperimentConfig to deeply clone from
   */
  constructor(_value?: RecursivePartial<ExperimentConfig.AsObject>) {
    _value = _value || {};
    (this.experiments = _value!.experiments
      ? Object.keys(_value!.experiments).reduce(
          (r, k) => ({
            ...r,
            [k]: _value!.experiments![k]
              ? new Experiment(_value!.experiments![k])
              : undefined,
          }),
          {}
        )
      : {}),
      ExperimentConfig.refineValues(this);
  }
  get experiments(): { [prop: string]: Experiment } {
    return this._experiments;
  }
  set experiments(value: { [prop: string]: Experiment }) {
    this._experiments = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ExperimentConfig.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ExperimentConfig.AsObject {
    return {
      experiments: this.experiments
        ? Object.keys(this.experiments).reduce(
            (r, k) => ({
              ...r,
              [k]: this.experiments![k]
                ? this.experiments![k].toObject()
                : undefined,
            }),
            {}
          )
        : {},
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ExperimentConfig.AsProtobufJSON {
    return {
      experiments: this.experiments
        ? Object.keys(this.experiments).reduce(
            (r, k) => ({
              ...r,
              [k]: this.experiments![k] ? this.experiments![k].toJSON() : null,
            }),
            {}
          )
        : {},
    };
  }
}
export module ExperimentConfig {
  /**
   * Standard JavaScript object representation for ExperimentConfig
   */
  export interface AsObject {
    experiments: { [prop: string]: Experiment };
  }

  /**
   * Protobuf JSON representation for ExperimentConfig
   */
  export interface AsProtobufJSON {
    experiments: { [prop: string]: Experiment };
  }

  /**
   * Message implementation for config.ExperimentConfig.ExperimentsEntry
   */
  export class ExperimentsEntry implements GrpcMessage {
    static id = 'config.ExperimentConfig.ExperimentsEntry';

    /**
     * Deserialize binary data to message
     * @param instance message instance
     */
    static deserializeBinary(bytes: ByteSource) {
      const instance = new ExperimentsEntry();
      ExperimentsEntry.deserializeBinaryFromReader(
        instance,
        new BinaryReader(bytes)
      );
      return instance;
    }

    /**
     * Check all the properties and set default protobuf values if necessary
     * @param _instance message instance
     */
    static refineValues(_instance: ExperimentsEntry) {
      _instance.key = _instance.key || '';
      _instance.value = _instance.value || undefined;
    }

    /**
     * Deserializes / reads binary message into message instance using provided binary reader
     * @param _instance message instance
     * @param _reader binary reader instance
     */
    static deserializeBinaryFromReader(
      _instance: ExperimentsEntry,
      _reader: BinaryReader
    ) {
      while (_reader.nextField()) {
        if (_reader.isEndGroup()) break;

        switch (_reader.getFieldNumber()) {
          case 1:
            _instance.key = _reader.readString();
            break;
          case 2:
            _instance.value = new Experiment();
            _reader.readMessage(
              _instance.value,
              Experiment.deserializeBinaryFromReader
            );
            break;
          default:
            _reader.skipField();
        }
      }

      ExperimentsEntry.refineValues(_instance);
    }

    /**
     * Serializes a message to binary format using provided binary reader
     * @param _instance message instance
     * @param _writer binary writer instance
     */
    static serializeBinaryToWriter(
      _instance: ExperimentsEntry,
      _writer: BinaryWriter
    ) {
      if (_instance.key) {
        _writer.writeString(1, _instance.key);
      }
      if (_instance.value) {
        _writer.writeMessage(
          2,
          _instance.value as any,
          Experiment.serializeBinaryToWriter
        );
      }
    }

    private _key: string;
    private _value?: Experiment;

    /**
     * Message constructor. Initializes the properties and applies default Protobuf values if necessary
     * @param _value initial values object or instance of ExperimentsEntry to deeply clone from
     */
    constructor(_value?: RecursivePartial<ExperimentsEntry.AsObject>) {
      _value = _value || {};
      this.key = _value.key;
      this.value = _value.value ? new Experiment(_value.value) : undefined;
      ExperimentsEntry.refineValues(this);
    }
    get key(): string {
      return this._key;
    }
    set key(value: string) {
      this._key = value;
    }
    get value(): Experiment | undefined {
      return this._value;
    }
    set value(value: Experiment | undefined) {
      this._value = value;
    }

    /**
     * Serialize message to binary data
     * @param instance message instance
     */
    serializeBinary() {
      const writer = new BinaryWriter();
      ExperimentsEntry.serializeBinaryToWriter(this, writer);
      return writer.getResultBuffer();
    }

    /**
     * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
     */
    toObject(): ExperimentsEntry.AsObject {
      return {
        key: this.key,
        value: this.value ? this.value.toObject() : undefined,
      };
    }

    /**
     * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
     */
    toJSON() {
      return this.toObject();
    }

    /**
     * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
     * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
     * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
     */
    toProtobufJSON(
      // @ts-ignore
      options?: ToProtobufJSONOptions
    ): ExperimentsEntry.AsProtobufJSON {
      return {
        key: this.key,
        value: this.value ? this.value.toProtobufJSON(options) : null,
      };
    }
  }
  export module ExperimentsEntry {
    /**
     * Standard JavaScript object representation for ExperimentsEntry
     */
    export interface AsObject {
      key: string;
      value?: Experiment.AsObject;
    }

    /**
     * Protobuf JSON representation for ExperimentsEntry
     */
    export interface AsProtobufJSON {
      key: string;
      value: Experiment.AsProtobufJSON | null;
    }
  }
}

/**
 * Message implementation for config.Experiment
 */
export class Experiment implements GrpcMessage {
  static id = 'config.Experiment';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new Experiment();
    Experiment.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: Experiment) {}

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: Experiment,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 2:
          _instance.binary = new BinaryExperiment();
          _reader.readMessage(
            _instance.binary,
            BinaryExperiment.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    Experiment.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(_instance: Experiment, _writer: BinaryWriter) {
    if (_instance.binary) {
      _writer.writeMessage(
        2,
        _instance.binary as any,
        BinaryExperiment.serializeBinaryToWriter
      );
    }
  }

  private _binary?: BinaryExperiment;

  private _type: Experiment.TypeCase = Experiment.TypeCase.none;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of Experiment to deeply clone from
   */
  constructor(_value?: RecursivePartial<Experiment.AsObject>) {
    _value = _value || {};
    this.binary = _value.binary
      ? new BinaryExperiment(_value.binary)
      : undefined;
    Experiment.refineValues(this);
  }
  get binary(): BinaryExperiment | undefined {
    return this._binary;
  }
  set binary(value: BinaryExperiment | undefined) {
    if (value !== undefined && value !== null) {
      this._type = Experiment.TypeCase.binary;
    }
    this._binary = value;
  }
  get type() {
    return this._type;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    Experiment.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): Experiment.AsObject {
    return {
      binary: this.binary ? this.binary.toObject() : undefined,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): Experiment.AsProtobufJSON {
    return {
      binary: this.binary ? this.binary.toProtobufJSON(options) : null,
    };
  }
}
export module Experiment {
  /**
   * Standard JavaScript object representation for Experiment
   */
  export interface AsObject {
    binary?: BinaryExperiment.AsObject;
  }

  /**
   * Protobuf JSON representation for Experiment
   */
  export interface AsProtobufJSON {
    binary: BinaryExperiment.AsProtobufJSON | null;
  }
  export enum TypeCase {
    none = 0,
    binary = 1,
  }
}

/**
 * Message implementation for config.BinaryExperiment
 */
export class BinaryExperiment implements GrpcMessage {
  static id = 'config.BinaryExperiment';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new BinaryExperiment();
    BinaryExperiment.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: BinaryExperiment) {
    _instance.enabled = _instance.enabled || false;
    _instance.features = _instance.features || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: BinaryExperiment,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.enabled = _reader.readBool();
          break;
        case 2:
          (_instance.features = _instance.features || []).push(
            ...(_reader.readPackedEnum() || [])
          );
          break;
        default:
          _reader.skipField();
      }
    }

    BinaryExperiment.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: BinaryExperiment,
    _writer: BinaryWriter
  ) {
    if (_instance.enabled) {
      _writer.writeBool(1, _instance.enabled);
    }
    if (_instance.features && _instance.features.length) {
      _writer.writePackedEnum(2, _instance.features);
    }
  }

  private _enabled: boolean;
  private _features: FeatureType[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of BinaryExperiment to deeply clone from
   */
  constructor(_value?: RecursivePartial<BinaryExperiment.AsObject>) {
    _value = _value || {};
    this.enabled = _value.enabled;
    this.features = (_value.features || []).slice();
    BinaryExperiment.refineValues(this);
  }
  get enabled(): boolean {
    return this._enabled;
  }
  set enabled(value: boolean) {
    this._enabled = value;
  }
  get features(): FeatureType[] {
    return this._features;
  }
  set features(value: FeatureType[]) {
    this._features = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    BinaryExperiment.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): BinaryExperiment.AsObject {
    return {
      enabled: this.enabled,
      features: (this.features || []).slice(),
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): BinaryExperiment.AsProtobufJSON {
    return {
      enabled: this.enabled,
      features: (this.features || []).map((v) => FeatureType[v]),
    };
  }
}
export module BinaryExperiment {
  /**
   * Standard JavaScript object representation for BinaryExperiment
   */
  export interface AsObject {
    enabled: boolean;
    features: FeatureType[];
  }

  /**
   * Protobuf JSON representation for BinaryExperiment
   */
  export interface AsProtobufJSON {
    enabled: boolean;
    features: string[];
  }
}

/**
 * Message implementation for config.RequestExperimentOverrides
 */
export class RequestExperimentOverrides implements GrpcMessage {
  static id = 'config.RequestExperimentOverrides';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new RequestExperimentOverrides();
    RequestExperimentOverrides.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: RequestExperimentOverrides) {
    _instance.enableExperimentNames = _instance.enableExperimentNames || [];
    _instance.disableExperimentNames = _instance.disableExperimentNames || [];
    _instance.enableFeatures = _instance.enableFeatures || [];
    _instance.disableFeatures = _instance.disableFeatures || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: RequestExperimentOverrides,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          (_instance.enableExperimentNames =
            _instance.enableExperimentNames || []).push(_reader.readString());
          break;
        case 2:
          (_instance.disableExperimentNames =
            _instance.disableExperimentNames || []).push(_reader.readString());
          break;
        case 3:
          (_instance.enableFeatures = _instance.enableFeatures || []).push(
            ...(_reader.readPackedEnum() || [])
          );
          break;
        case 4:
          (_instance.disableFeatures = _instance.disableFeatures || []).push(
            ...(_reader.readPackedEnum() || [])
          );
          break;
        default:
          _reader.skipField();
      }
    }

    RequestExperimentOverrides.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: RequestExperimentOverrides,
    _writer: BinaryWriter
  ) {
    if (
      _instance.enableExperimentNames &&
      _instance.enableExperimentNames.length
    ) {
      _writer.writeRepeatedString(1, _instance.enableExperimentNames);
    }
    if (
      _instance.disableExperimentNames &&
      _instance.disableExperimentNames.length
    ) {
      _writer.writeRepeatedString(2, _instance.disableExperimentNames);
    }
    if (_instance.enableFeatures && _instance.enableFeatures.length) {
      _writer.writePackedEnum(3, _instance.enableFeatures);
    }
    if (_instance.disableFeatures && _instance.disableFeatures.length) {
      _writer.writePackedEnum(4, _instance.disableFeatures);
    }
  }

  private _enableExperimentNames: string[];
  private _disableExperimentNames: string[];
  private _enableFeatures: FeatureType[];
  private _disableFeatures: FeatureType[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of RequestExperimentOverrides to deeply clone from
   */
  constructor(_value?: RecursivePartial<RequestExperimentOverrides.AsObject>) {
    _value = _value || {};
    this.enableExperimentNames = (_value.enableExperimentNames || []).slice();
    this.disableExperimentNames = (_value.disableExperimentNames || []).slice();
    this.enableFeatures = (_value.enableFeatures || []).slice();
    this.disableFeatures = (_value.disableFeatures || []).slice();
    RequestExperimentOverrides.refineValues(this);
  }
  get enableExperimentNames(): string[] {
    return this._enableExperimentNames;
  }
  set enableExperimentNames(value: string[]) {
    this._enableExperimentNames = value;
  }
  get disableExperimentNames(): string[] {
    return this._disableExperimentNames;
  }
  set disableExperimentNames(value: string[]) {
    this._disableExperimentNames = value;
  }
  get enableFeatures(): FeatureType[] {
    return this._enableFeatures;
  }
  set enableFeatures(value: FeatureType[]) {
    this._enableFeatures = value;
  }
  get disableFeatures(): FeatureType[] {
    return this._disableFeatures;
  }
  set disableFeatures(value: FeatureType[]) {
    this._disableFeatures = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    RequestExperimentOverrides.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): RequestExperimentOverrides.AsObject {
    return {
      enableExperimentNames: (this.enableExperimentNames || []).slice(),
      disableExperimentNames: (this.disableExperimentNames || []).slice(),
      enableFeatures: (this.enableFeatures || []).slice(),
      disableFeatures: (this.disableFeatures || []).slice(),
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): RequestExperimentOverrides.AsProtobufJSON {
    return {
      enableExperimentNames: (this.enableExperimentNames || []).slice(),
      disableExperimentNames: (this.disableExperimentNames || []).slice(),
      enableFeatures: (this.enableFeatures || []).map((v) => FeatureType[v]),
      disableFeatures: (this.disableFeatures || []).map((v) => FeatureType[v]),
    };
  }
}
export module RequestExperimentOverrides {
  /**
   * Standard JavaScript object representation for RequestExperimentOverrides
   */
  export interface AsObject {
    enableExperimentNames: string[];
    disableExperimentNames: string[];
    enableFeatures: FeatureType[];
    disableFeatures: FeatureType[];
  }

  /**
   * Protobuf JSON representation for RequestExperimentOverrides
   */
  export interface AsProtobufJSON {
    enableExperimentNames: string[];
    disableExperimentNames: string[];
    enableFeatures: string[];
    disableFeatures: string[];
  }
}
