import {
  AuditArea,
  AuditAreaApprovalState,
  BinaryState,
} from 'app/services/generated/src/main/proto/storage/approval.pb';

export const BINARY_STATE: Record<BinaryState, string> = {
  [BinaryState.BINARY_STATE_UNSPECIFIED]: 'unknown',
  [BinaryState.BINARY_STATE_ACTIVE]: 'active',
  [BinaryState.BINARY_STATE_INACTIVE]: 'inactive',
};

export const APPROVAL_STATE: Record<AuditAreaApprovalState, string> = {
  [AuditAreaApprovalState.AUDIT_AREA_APPROVAL_STATE_UNSPECIFIED]: 'unknown',
  [AuditAreaApprovalState.AUDIT_AREA_APPROVAL_STATE_PENDING]: 'pending',
  [AuditAreaApprovalState.AUDIT_AREA_APPROVAL_STATE_APPROVED]: 'approved',
  [AuditAreaApprovalState.AUDIT_AREA_APPROVAL_STATE_DISAPPROVED]: 'disapproved',
};

export const AUDIT_AREA: Record<AuditArea, string> = {
  [AuditArea.AUDIT_AREA_EXECUTION_ENVIRONMENT]: 'Trusted Execution Environment',
  [AuditArea.AUDIT_AREA_MEASUREMENT_ALGORITHMS]: 'Computation',
  [AuditArea.AUDIT_AREA_PRIVACY_ALGORITHMS]: 'Privacy Processing',
  [AuditArea.AUDIT_AREA_UNSPECIFIED]: 'Unknown',
};

export const AUDIT_AREA_DOCS_URL: Record<AuditArea, string> = {
  [AuditArea.AUDIT_AREA_EXECUTION_ENVIRONMENT]: '/tee',
  [AuditArea.AUDIT_AREA_MEASUREMENT_ALGORITHMS]: '/computation',
  [AuditArea.AUDIT_AREA_PRIVACY_ALGORITHMS]: '/privacy-processing',
  [AuditArea.AUDIT_AREA_UNSPECIFIED]: '#',
};

export const APPROVAL_STATE_ICONS: Record<AuditAreaApprovalState, string> = {
  [AuditAreaApprovalState.AUDIT_AREA_APPROVAL_STATE_UNSPECIFIED]: 'help',
  [AuditAreaApprovalState.AUDIT_AREA_APPROVAL_STATE_PENDING]: 'warning',
  [AuditAreaApprovalState.AUDIT_AREA_APPROVAL_STATE_APPROVED]: 'check_circle',
  [AuditAreaApprovalState.AUDIT_AREA_APPROVAL_STATE_DISAPPROVED]: 'cancel',
};

export const APPROVAL_STATE_LABEL: Record<AuditAreaApprovalState, string> = {
  [AuditAreaApprovalState.AUDIT_AREA_APPROVAL_STATE_UNSPECIFIED]: 'Unknown',
  [AuditAreaApprovalState.AUDIT_AREA_APPROVAL_STATE_PENDING]:
    'Pending Approval',
  [AuditAreaApprovalState.AUDIT_AREA_APPROVAL_STATE_APPROVED]: 'Approved',
  [AuditAreaApprovalState.AUDIT_AREA_APPROVAL_STATE_DISAPPROVED]: 'Disapproved',
};
