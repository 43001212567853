<app-container>
  <app-page-header title="Getting started"></app-page-header>

  <div *ngIf="isLoading" class="loader">
    <mat-spinner diameter="42"></mat-spinner>
  </div>
  <ul *ngIf="!isLoading">
    <li *ngFor="let step of steps; let index = index">
      <div>
        <p>
          <b>Step {{ index + 1 }}: {{ step.title }}</b>
        </p>
        <p [innerHTML]="step.description"></p>
      </div>
      <ul>
        <li *ngFor="let item of groups[step.id]">
          <mat-checkbox
            class="checkbox"
            *ngIf="groups[item.id]"
            (change)="onToggleParent(item.id, $event.checked)"
            [checked]="areAllChildrenChecked(item.id)"
            [indeterminate]="
              areSomeChildrenChecked(item.id) && !areAllChildrenChecked(item.id)
            "
            color="primary"
            ><label class="link" [innerHTML]="item.label"></label
          ></mat-checkbox>
          <mat-checkbox
            *ngIf="!groups[item.id]"
            [checked]="state[item.id]"
            (change)="onToggleTodo(item.id, $event.checked)"
            color="primary"
            class="checkbox"
            ><label class="link" [innerHTML]="item.label"></label
          ></mat-checkbox>
          <ul *ngIf="groups[item.id]">
            <li *ngFor="let child of groups[item.id]">
              <mat-checkbox
                class="checkbox"
                [checked]="state[child.id]"
                (change)="onToggleTodo(child.id, $event.checked)"
                color="primary"
                ><label class="link" [innerHTML]="child.label"></label
              ></mat-checkbox>
            </li>
          </ul>
        </li>
      </ul>
    </li>
  </ul>
</app-container>
