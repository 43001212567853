/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import { Inject, Injectable, Optional } from '@angular/core';
import {
  GrpcCallType,
  GrpcClient,
  GrpcClientFactory,
  GrpcEvent,
  GrpcMetadata,
} from '@ngx-grpc/common';
import {
  GRPC_CLIENT_FACTORY,
  GrpcHandler,
  takeMessages,
  throwStatusErrors,
} from '@ngx-grpc/core';
import { Observable } from 'rxjs';
import * as thisProto from './key-service.pb';
import * as googleProtobuf000 from '@ngx-grpc/well-known-types';
import * as storage001 from '../../../../src/main/proto/storage/schema-annotations.pb';
import * as api002 from '../../../../src/main/proto/api/headers.pb';
import * as storage003 from '../../../../src/main/proto/storage/key-config.pb';
import { GRPC_KEY_SERVICE_CLIENT_SETTINGS } from './key-service.pbconf';
/**
 * Service client implementation for api.key.KeyService
 */
@Injectable({ providedIn: 'any' })
export class KeyServiceClient {
  private client: GrpcClient<any>;

  /**
   * Raw RPC implementation for each service client method.
   * The raw methods provide more control on the incoming data and events. E.g. they can be useful to read status `OK` metadata.
   * Attention: these methods do not throw errors when non-zero status codes are received.
   */
  $raw = {
    /**
     * Unary call: /api.key.KeyService/GetPublicKey
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.GetPublicKeyResponse>>
     */
    getPublicKey: (
      requestData: thisProto.GetPublicKeyRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.GetPublicKeyResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/api.key.KeyService/GetPublicKey',
        requestData,
        requestMetadata,
        requestClass: thisProto.GetPublicKeyRequest,
        responseClass: thisProto.GetPublicKeyResponse,
      });
    },
    /**
     * Unary call: /api.key.KeyService/RegisterKey
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.RegisterKeyResponse>>
     */
    registerKey: (
      requestData: thisProto.RegisterKeyRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.RegisterKeyResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/api.key.KeyService/RegisterKey',
        requestData,
        requestMetadata,
        requestClass: thisProto.RegisterKeyRequest,
        responseClass: thisProto.RegisterKeyResponse,
      });
    },
    /**
     * Unary call: /api.key.KeyService/UnregisterKey
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.UnregisterKeyResponse>>
     */
    unregisterKey: (
      requestData: thisProto.UnregisterKeyRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.UnregisterKeyResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/api.key.KeyService/UnregisterKey',
        requestData,
        requestMetadata,
        requestClass: thisProto.UnregisterKeyRequest,
        responseClass: thisProto.UnregisterKeyResponse,
      });
    },
    /**
     * Unary call: /api.key.KeyService/ListKeys
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.ListKeysResponse>>
     */
    listKeys: (
      requestData: thisProto.ListKeysRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.ListKeysResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/api.key.KeyService/ListKeys',
        requestData,
        requestMetadata,
        requestClass: thisProto.ListKeysRequest,
        responseClass: thisProto.ListKeysResponse,
      });
    },
    /**
     * Unary call: /api.key.KeyService/ListAllKeys
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.ListAllKeysResponse>>
     */
    listAllKeys: (
      requestData: thisProto.ListAllKeysRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.ListAllKeysResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/api.key.KeyService/ListAllKeys',
        requestData,
        requestMetadata,
        requestClass: thisProto.ListAllKeysRequest,
        responseClass: thisProto.ListAllKeysResponse,
      });
    },
    /**
     * Unary call: /api.key.KeyService/ListKeyNames
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.ListKeyNamesResponse>>
     */
    listKeyNames: (
      requestData: thisProto.ListKeyNamesRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.ListKeyNamesResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/api.key.KeyService/ListKeyNames',
        requestData,
        requestMetadata,
        requestClass: thisProto.ListKeyNamesRequest,
        responseClass: thisProto.ListKeyNamesResponse,
      });
    },
    /**
     * Unary call: /api.key.KeyService/GetKey
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.GetKeyResponse>>
     */
    getKey: (
      requestData: thisProto.GetKeyRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.GetKeyResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/api.key.KeyService/GetKey',
        requestData,
        requestMetadata,
        requestClass: thisProto.GetKeyRequest,
        responseClass: thisProto.GetKeyResponse,
      });
    },
    /**
     * Unary call: /api.key.KeyService/UpdateKey
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.UpdateKeyResponse>>
     */
    updateKey: (
      requestData: thisProto.UpdateKeyRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.UpdateKeyResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/api.key.KeyService/UpdateKey',
        requestData,
        requestMetadata,
        requestClass: thisProto.UpdateKeyRequest,
        responseClass: thisProto.UpdateKeyResponse,
      });
    },
    /**
     * Unary call: /api.key.KeyService/RotateKey
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.RotateKeyResponse>>
     */
    rotateKey: (
      requestData: thisProto.RotateKeyRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.RotateKeyResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/api.key.KeyService/RotateKey',
        requestData,
        requestMetadata,
        requestClass: thisProto.RotateKeyRequest,
        responseClass: thisProto.RotateKeyResponse,
      });
    },
    /**
     * Unary call: /api.key.KeyService/DeleteExpired
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.DeleteExpiredResponse>>
     */
    deleteExpired: (
      requestData: thisProto.DeleteExpiredRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.DeleteExpiredResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/api.key.KeyService/DeleteExpired',
        requestData,
        requestMetadata,
        requestClass: thisProto.DeleteExpiredRequest,
        responseClass: thisProto.DeleteExpiredResponse,
      });
    },
  };

  constructor(
    @Optional() @Inject(GRPC_KEY_SERVICE_CLIENT_SETTINGS) settings: any,
    @Inject(GRPC_CLIENT_FACTORY) clientFactory: GrpcClientFactory<any>,
    private handler: GrpcHandler
  ) {
    this.client = clientFactory.createClient('api.key.KeyService', settings);
  }

  /**
   * Unary call @/api.key.KeyService/GetPublicKey
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.GetPublicKeyResponse>
   */
  getPublicKey(
    requestData: thisProto.GetPublicKeyRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.GetPublicKeyResponse> {
    return this.$raw
      .getPublicKey(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/api.key.KeyService/RegisterKey
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.RegisterKeyResponse>
   */
  registerKey(
    requestData: thisProto.RegisterKeyRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.RegisterKeyResponse> {
    return this.$raw
      .registerKey(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/api.key.KeyService/UnregisterKey
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.UnregisterKeyResponse>
   */
  unregisterKey(
    requestData: thisProto.UnregisterKeyRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.UnregisterKeyResponse> {
    return this.$raw
      .unregisterKey(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/api.key.KeyService/ListKeys
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.ListKeysResponse>
   */
  listKeys(
    requestData: thisProto.ListKeysRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.ListKeysResponse> {
    return this.$raw
      .listKeys(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/api.key.KeyService/ListAllKeys
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.ListAllKeysResponse>
   */
  listAllKeys(
    requestData: thisProto.ListAllKeysRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.ListAllKeysResponse> {
    return this.$raw
      .listAllKeys(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/api.key.KeyService/ListKeyNames
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.ListKeyNamesResponse>
   */
  listKeyNames(
    requestData: thisProto.ListKeyNamesRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.ListKeyNamesResponse> {
    return this.$raw
      .listKeyNames(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/api.key.KeyService/GetKey
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.GetKeyResponse>
   */
  getKey(
    requestData: thisProto.GetKeyRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.GetKeyResponse> {
    return this.$raw
      .getKey(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/api.key.KeyService/UpdateKey
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.UpdateKeyResponse>
   */
  updateKey(
    requestData: thisProto.UpdateKeyRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.UpdateKeyResponse> {
    return this.$raw
      .updateKey(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/api.key.KeyService/RotateKey
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.RotateKeyResponse>
   */
  rotateKey(
    requestData: thisProto.RotateKeyRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.RotateKeyResponse> {
    return this.$raw
      .rotateKey(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/api.key.KeyService/DeleteExpired
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.DeleteExpiredResponse>
   */
  deleteExpired(
    requestData: thisProto.DeleteExpiredRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.DeleteExpiredResponse> {
    return this.$raw
      .deleteExpired(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }
}
