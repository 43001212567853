<form [formGroup]="form">
  <mat-form-field class="input-half-width">
    <mat-label>{{ name }} Customer ID</mat-label>
    <mat-select formControlName="customerId">
      <mat-option
        *ngFor="let customer of customers | keyvalue"
        [value]="customer.key"
      >
        {{ customer.value.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field class="input-half-width">
    <mat-label>{{ name }} Dataset ID</mat-label>
    <mat-select formControlName="datasetId">
      <mat-option
        *ngFor="let dataset of datasets | keyvalue"
        [value]="dataset.key"
      >
        {{ dataset.value.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field class="input-half-width">
    <mat-label>Dataset Versions (comma-separated)</mat-label>
    <input matInput formControlName="version" />
  </mat-form-field>
  <mat-form-field class="input-half-width">
    <mat-label>Partitions</mat-label>
    <input type="number" matInput formControlName="partitions" />
  </mat-form-field>
</form>
