import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ContainerComponent } from 'app/views/shared/components/container/container.component';
import { PageHeaderComponent } from 'app/views/shared/components/page-header/page-header.component';
import { TodoListComponent } from 'app/views/todo-list/todo-list.component';

@NgModule({
  declarations: [TodoListComponent],
  imports: [
    CommonModule,
    ContainerComponent,
    PageHeaderComponent,
    MatButtonModule,
    MatCheckboxModule,
    FormsModule,
    MatProgressSpinnerModule,
  ],
})
export class TodoListModule {}
