<div class="row" [ngClass]="{ active: isOpen }">
  <div>
    <button
      mat-stroked-button
      [matMenuTriggerFor]="menu"
      [disabled]="updateInProgress || !isApprover"
    >
      <span class="status" [ngClass]="status"
        ><span class="label">{{ status }}</span
        ><mat-icon class="expand-icon">expand_more</mat-icon></span
      >
    </button>
    <mat-menu #menu="matMenu">
      <button [disabled]="isActive" mat-menu-item (click)="setActive()">
        Activate
      </button>
      <button [disabled]="!isActive" mat-menu-item (click)="setInactive()">
        Deactivate
      </button>
    </mat-menu>
    <mat-spinner
      class="updating-spinner"
      diameter="24"
      *ngIf="isUpdating"
    ></mat-spinner>
  </div>
  <div>
    <div>
      <div class="name" (click)="onToggle?.(approval?.id)">
        {{ approval?.approvalRequestDetail?.change?.binaryInfo?.name }}
      </div>
      <div class="date">Release Date: {{ releaseDate }}</div>
    </div>
  </div>
  <div>
    <div class="badge blue">
      Version
      {{ approval?.approvalRequestDetail?.change?.binaryInfo?.version }}
    </div>
  </div>

  <div>
    <div class="badge" [ngClass]="approvalState">
      {{ approvalStateLabel }}
    </div>
  </div>

  <div>
    <button
      mat-icon-button
      color="primary"
      (click)="onToggle?.(approval?.id)"
      matTooltip="Expand to approve binary"
      #tooltipTarget="matTooltip"
    >
      <mat-icon class="toggle-icon">{{
        isOpen ? 'expand_more' : 'expand_less'
      }}</mat-icon>
    </button>
  </div>
</div>
<div class="content" *ngIf="isOpen">
  <div class="container">
    <app-approve
      [decisions]="decisions"
      [approval]="approval"
      (approvalUpdatedEvent)="onApprovalUpdate?.($event)"
      [isApprover]="isApprover"
      [displaySourceIcon]="displaySourceIcon"
    ></app-approve>
  </div>
</div>
