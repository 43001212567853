import { Component } from '@angular/core';
import { Auth } from '@angular/fire/auth';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TenantInfo } from 'app/services/generated/src/main/proto/api/customer-service.pb';
import { LoggerService } from 'app/services/logger.service';

import { SamlBase } from '../../base/saml-base';
import { TenantSelectModalComponent } from '../../modals/tenant-select-modal/tenant-select.component';
import { CustomerService } from '../../services/customer.service';
import { UserService } from '../../services/user.service';
import { MessageBoxProvider } from '../shared/components/message-box/message-box.provider';

@Component({
  selector: 'app-sign-in-options',
  templateUrl: './sign-in-options.component.html',
  styleUrls: ['./sign-in-options.component.scss'],
})
export class SignInOptionsComponent extends SamlBase {
  form = new FormGroup({
    email: new FormControl(''),
  });
  redirect: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private auth: Auth,
    private customerService: CustomerService,
    private dialog: MatDialog,
    private router: Router,
    override logger: LoggerService,
    override messageBox: MessageBoxProvider,
    override userService: UserService
  ) {
    super(logger, messageBox, userService);

    this.redirect =
      this.activatedRoute.snapshot.queryParams['redirect'] || 'landing-page';

    const tenantId = this.activatedRoute.snapshot.queryParams['tenantId'];

    if (this.activatedRoute.snapshot.queryParams['signInWithSaml'] === 'true') {
      this.signInWithSaml(tenantId);
    }

    this.auth.onAuthStateChanged((user) => {
      if (user) {
        this.router.navigate([this.redirect]);
      }
    });
  }

  public async process() {
    const { email } = this.form.value;

    if (!email) {
      this.messageBox.show('Please enter your email', 'error');
      return;
    }

    const tenantResponse = await this.customerService.getTenantId(email);

    if (tenantResponse.tenantInfos?.length == 1) {
      this.redirectToSignInPage(tenantResponse.tenantInfos[0]);
    } else {
      this.openTenantSelectorModule(tenantResponse.tenantInfos);
    }
  }

  private redirectToSignInPage(tenantInfo: TenantInfo) {
    const { email } = this.form.value;

    if (tenantInfo.passwordSignInAllowed && tenantInfo.samlSignInAllowed) {
      this.router.navigate(['sign-in'], {
        queryParams: {
          redirect: this.redirect,
          email: email,
          tenantId: tenantInfo.tenantId,
          signInWithSaml: true,
        },
      });
    } else if (tenantInfo.passwordSignInAllowed) {
      this.router.navigate(['sign-in'], {
        queryParams: {
          redirect: this.redirect,
          email: email,
          tenantId: tenantInfo.tenantId,
        },
      });
    } else if (
      tenantInfo.samlSignInAllowed &&
      !tenantInfo.passwordSignInAllowed
    ) {
      this.signInWithSaml(tenantInfo.tenantId);
    } else {
      this.messageBox.error(
        'No sign in options found for this customer, please contact support.'
      );
      this.logger.error('No sign in options found.');
    }
  }

  private openTenantSelectorModule(tenantInfos: TenantInfo[] | undefined) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = tenantInfos;

    if (tenantInfos !== undefined) {
      this.dialog
        .open(TenantSelectModalComponent, dialogConfig)
        .afterClosed()
        .subscribe((tenantId: string) => {
          if (tenantId && tenantInfos) {
            const tenantInfo = tenantInfos.find(
              (tenantInfo) => tenantInfo.tenantId === tenantId
            );
            if (tenantInfo) {
              this.redirectToSignInPage(tenantInfo);
            }
          }
        });
    } else {
      this.messageBox.error('Server error, Tenant Id missing');
      this.logger.error('Tenant Id missing from server response');
    }
  }
}
