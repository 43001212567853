import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormHelpersService } from 'app/services/form-helpers.service';
import {
  JobSchedule,
  TimeOfDay,
} from 'app/services/generated/src/main/proto/storage/job-schedule.pb';

import { JobScheduleService } from '../../services/job-schedule.service';
import { MessageBoxProvider } from '../../views/shared/components/message-box/message-box.provider';

@Component({
  selector: 'app-add-job-schedule-modal',
  templateUrl: './add-job-schedule-modal.component.html',
  styleUrls: [
    './add-job-schedule-modal.component.scss',
    '../../shared/shared.scss',
  ],
})
export class AddJobScheduleModalComponent {
  jobSchedule: FormGroup;
  isLoading = false;
  title: string;

  constructor(
    private jobScheduleService: JobScheduleService,
    private dialogRef: MatDialogRef<AddJobScheduleModalComponent>,
    private formBuilder: FormBuilder,
    private formHelper: FormHelpersService,
    private messageBox: MessageBoxProvider,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.title = 'Add Job Schedule';

    this.jobSchedule = this.formBuilder.group({
      name: new FormControl(''),
      jobLogId: new FormControl(''),
      startTime: this.formBuilder.group({
        startHour: new FormControl(''),
        startMinute: new FormControl(''),
      }),
    });

    this.formHelper.setForm(this.jobSchedule);
  }

  addJobSchedule(): void {
    this.isLoading = true;
    const jobSchedule = this.buildJobSchedule();

    this.jobScheduleService
      .createJobSchedule(jobSchedule)
      .then(() => this.dialogRef.close())
      .catch((error) =>
        this.messageBox.error(
          'Failed to create job schedule: ' + error.statusMessage
        )
      )
      .finally(() => (this.isLoading = false));
  }

  buildJobSchedule(): JobSchedule {
    const { value } = this.jobSchedule;

    return new JobSchedule({
      name: value.name,
      jobLogId: value.jobLogId,
      startTime: new TimeOfDay({
        startHour: value.startTime.startHour,
        startMinute: value.startTime.startMinute,
      }),
    });
  }

  public checkError(controlName: string, errorName: string) {
    return this.formHelper.checkError(controlName, errorName);
  }

  public close() {
    this.dialogRef.close();
  }

  process() {
    this.addJobSchedule();
  }
}
