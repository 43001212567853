import { Injectable } from '@angular/core';

/**
 * Service to provide a reference to the global window object.
 */
@Injectable()
export class WindowService {
  /**
   * Retrieves a reference to the global window object.
   *
   * @returns The global window object.
   */
  get windowRef() {
    return window;
  }
}
