/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import {
  GrpcMessage,
  RecursivePartial,
  ToProtobufJSONOptions,
} from '@ngx-grpc/common';
import * as googleProtobuf000 from '@ngx-grpc/well-known-types';
import { BinaryReader, BinaryWriter, ByteSource } from 'google-protobuf';
export enum NotificationType {
  NOTIFICATION_TYPE_UNSPECIFIED = 0,
  NOTIFICATION_TYPE_PUSH = 1,
  NOTIFICATION_TYPE_EMAIL = 2,
}
export enum NotificationEvent {
  NOTIFICATION_EVENT_UNSPECIFIED = 0,
  NOTIFICATION_EVENT_BINARY_RELEASE = 1,
  NOTIFICATION_EVENT_BINARY_APPROVED = 2,
  NOTIFICATION_EVENT_JOB_COMPLETE = 3,
}
export enum NotificationStatus {
  NOTIFICATION_RESULT_UNSPECIFIED = 0,
  NOTIFICATION_RESULT_SUCCESS = 1,
  NOTIFICATION_RESULT_ERROR = 2,
}
export enum NotificationTopic {
  NOTIFICATION_TOPIC_UNSPECIFIED = 0,
  NOTIFICATION_TOPIC_GENERAL = 1,
  NOTIFICATION_TOPIC_BINARY_RELEASED = 2,
  NOTIFICATION_TOPIC_BINARY_APPROVED = 3,
  NOTIFICATION_TOPIC_JOB = 4,
}
/**
 * Message implementation for storage.Notification
 */
export class Notification implements GrpcMessage {
  static id = 'storage.Notification';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new Notification();
    Notification.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: Notification) {
    _instance.tenantId = _instance.tenantId || '';
    _instance.notificationType = _instance.notificationType || [];
    _instance.notificationEvent = _instance.notificationEvent || 0;
    _instance.createdAt = _instance.createdAt || undefined;
    _instance.message = _instance.message || undefined;
    _instance.etag = _instance.etag || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: Notification,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.uid = _reader.readString();
          break;
        case 2:
          _instance.notificationTopic = _reader.readEnum();
          break;
        case 3:
          _instance.tenantId = _reader.readString();
          break;
        case 4:
          (_instance.notificationType = _instance.notificationType || []).push(
            ...(_reader.readPackedEnum() || [])
          );
          break;
        case 5:
          _instance.notificationEvent = _reader.readEnum();
          break;
        case 6:
          _instance.createdAt = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.createdAt,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 7:
          _instance.message = new Message();
          _reader.readMessage(
            _instance.message,
            Message.deserializeBinaryFromReader
          );
          break;
        case 8:
          _instance.etag = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    Notification.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: Notification,
    _writer: BinaryWriter
  ) {
    if (_instance.uid || _instance.uid === '') {
      _writer.writeString(1, _instance.uid);
    }
    if (_instance.notificationTopic || _instance.notificationTopic === 0) {
      _writer.writeEnum(2, _instance.notificationTopic);
    }
    if (_instance.tenantId) {
      _writer.writeString(3, _instance.tenantId);
    }
    if (_instance.notificationType && _instance.notificationType.length) {
      _writer.writePackedEnum(4, _instance.notificationType);
    }
    if (_instance.notificationEvent) {
      _writer.writeEnum(5, _instance.notificationEvent);
    }
    if (_instance.createdAt) {
      _writer.writeMessage(
        6,
        _instance.createdAt as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.message) {
      _writer.writeMessage(
        7,
        _instance.message as any,
        Message.serializeBinaryToWriter
      );
    }
    if (_instance.etag) {
      _writer.writeString(8, _instance.etag);
    }
  }

  private _uid: string;
  private _notificationTopic: NotificationTopic;
  private _tenantId: string;
  private _notificationType: NotificationType[];
  private _notificationEvent: NotificationEvent;
  private _createdAt?: googleProtobuf000.Timestamp;
  private _message?: Message;
  private _etag: string;

  private _recipient: Notification.RecipientCase =
    Notification.RecipientCase.none;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of Notification to deeply clone from
   */
  constructor(_value?: RecursivePartial<Notification.AsObject>) {
    _value = _value || {};
    this.uid = _value.uid;
    this.notificationTopic = _value.notificationTopic;
    this.tenantId = _value.tenantId;
    this.notificationType = (_value.notificationType || []).slice();
    this.notificationEvent = _value.notificationEvent;
    this.createdAt = _value.createdAt
      ? new googleProtobuf000.Timestamp(_value.createdAt)
      : undefined;
    this.message = _value.message ? new Message(_value.message) : undefined;
    this.etag = _value.etag;
    Notification.refineValues(this);
  }
  get uid(): string {
    return this._uid;
  }
  set uid(value: string) {
    if (value !== undefined && value !== null) {
      this._notificationTopic = undefined;
      this._recipient = Notification.RecipientCase.uid;
    }
    this._uid = value;
  }
  get notificationTopic(): NotificationTopic {
    return this._notificationTopic;
  }
  set notificationTopic(value: NotificationTopic) {
    if (value !== undefined && value !== null) {
      this._uid = undefined;
      this._recipient = Notification.RecipientCase.notificationTopic;
    }
    this._notificationTopic = value;
  }
  get tenantId(): string {
    return this._tenantId;
  }
  set tenantId(value: string) {
    this._tenantId = value;
  }
  get notificationType(): NotificationType[] {
    return this._notificationType;
  }
  set notificationType(value: NotificationType[]) {
    this._notificationType = value;
  }
  get notificationEvent(): NotificationEvent {
    return this._notificationEvent;
  }
  set notificationEvent(value: NotificationEvent) {
    this._notificationEvent = value;
  }
  get createdAt(): googleProtobuf000.Timestamp | undefined {
    return this._createdAt;
  }
  set createdAt(value: googleProtobuf000.Timestamp | undefined) {
    this._createdAt = value;
  }
  get message(): Message | undefined {
    return this._message;
  }
  set message(value: Message | undefined) {
    this._message = value;
  }
  get etag(): string {
    return this._etag;
  }
  set etag(value: string) {
    this._etag = value;
  }
  get recipient() {
    return this._recipient;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    Notification.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): Notification.AsObject {
    return {
      uid: this.uid,
      notificationTopic: this.notificationTopic,
      tenantId: this.tenantId,
      notificationType: (this.notificationType || []).slice(),
      notificationEvent: this.notificationEvent,
      createdAt: this.createdAt ? this.createdAt.toObject() : undefined,
      message: this.message ? this.message.toObject() : undefined,
      etag: this.etag,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): Notification.AsProtobufJSON {
    return {
      uid: this.uid === null || this.uid === undefined ? null : this.uid,
      notificationTopic:
        this.notificationTopic === undefined
          ? null
          : NotificationTopic[
              this.notificationTopic === null ||
              this.notificationTopic === undefined
                ? 0
                : this.notificationTopic
            ],
      tenantId: this.tenantId,
      notificationType: (this.notificationType || []).map(
        (v) => NotificationType[v]
      ),
      notificationEvent:
        NotificationEvent[
          this.notificationEvent === null ||
          this.notificationEvent === undefined
            ? 0
            : this.notificationEvent
        ],
      createdAt: this.createdAt ? this.createdAt.toProtobufJSON(options) : null,
      message: this.message ? this.message.toProtobufJSON(options) : null,
      etag: this.etag,
    };
  }
}
export module Notification {
  /**
   * Standard JavaScript object representation for Notification
   */
  export interface AsObject {
    uid: string;
    notificationTopic: NotificationTopic;
    tenantId: string;
    notificationType: NotificationType[];
    notificationEvent: NotificationEvent;
    createdAt?: googleProtobuf000.Timestamp.AsObject;
    message?: Message.AsObject;
    etag: string;
  }

  /**
   * Protobuf JSON representation for Notification
   */
  export interface AsProtobufJSON {
    uid: string | null;
    notificationTopic: string | null;
    tenantId: string;
    notificationType: string[];
    notificationEvent: string;
    createdAt: googleProtobuf000.Timestamp.AsProtobufJSON | null;
    message: Message.AsProtobufJSON | null;
    etag: string;
  }
  export enum RecipientCase {
    none = 0,
    uid = 1,
    notificationTopic = 2,
  }
}

/**
 * Message implementation for storage.Message
 */
export class Message implements GrpcMessage {
  static id = 'storage.Message';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new Message();
    Message.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: Message) {
    _instance.title = _instance.title || '';
    _instance.body = _instance.body || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: Message,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.title = _reader.readString();
          break;
        case 2:
          _instance.body = _reader.readString();
          break;
        case 3:
          _instance.icon = _reader.readString();
          break;
        case 4:
          _instance.image = _reader.readString();
          break;
        case 5:
          _instance.link = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    Message.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(_instance: Message, _writer: BinaryWriter) {
    if (_instance.title) {
      _writer.writeString(1, _instance.title);
    }
    if (_instance.body) {
      _writer.writeString(2, _instance.body);
    }
    if (_instance.icon !== undefined && _instance.icon !== null) {
      _writer.writeString(3, _instance.icon);
    }
    if (_instance.image !== undefined && _instance.image !== null) {
      _writer.writeString(4, _instance.image);
    }
    if (_instance.link !== undefined && _instance.link !== null) {
      _writer.writeString(5, _instance.link);
    }
  }

  private _title: string;
  private _body: string;
  private _icon?: string;
  private _image?: string;
  private _link?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of Message to deeply clone from
   */
  constructor(_value?: RecursivePartial<Message.AsObject>) {
    _value = _value || {};
    this.title = _value.title;
    this.body = _value.body;
    this.icon = _value.icon;
    this.image = _value.image;
    this.link = _value.link;
    Message.refineValues(this);
  }
  get title(): string {
    return this._title;
  }
  set title(value: string) {
    this._title = value;
  }
  get body(): string {
    return this._body;
  }
  set body(value: string) {
    this._body = value;
  }
  get icon(): string | undefined {
    return this._icon;
  }
  set icon(value?: string) {
    this._icon = value;
  }
  get image(): string | undefined {
    return this._image;
  }
  set image(value?: string) {
    this._image = value;
  }
  get link(): string | undefined {
    return this._link;
  }
  set link(value?: string) {
    this._link = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    Message.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): Message.AsObject {
    return {
      title: this.title,
      body: this.body,
      icon: this.icon,
      image: this.image,
      link: this.link,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): Message.AsProtobufJSON {
    return {
      title: this.title,
      body: this.body,
      icon: this.icon === null || this.icon === undefined ? null : this.icon,
      image:
        this.image === null || this.image === undefined ? null : this.image,
      link: this.link === null || this.link === undefined ? null : this.link,
    };
  }
}
export module Message {
  /**
   * Standard JavaScript object representation for Message
   */
  export interface AsObject {
    title: string;
    body: string;
    icon?: string;
    image?: string;
    link?: string;
  }

  /**
   * Protobuf JSON representation for Message
   */
  export interface AsProtobufJSON {
    title: string;
    body: string;
    icon: string | null;
    image: string | null;
    link: string | null;
  }
}

/**
 * Message implementation for storage.NotificationResult
 */
export class NotificationResult implements GrpcMessage {
  static id = 'storage.NotificationResult';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new NotificationResult();
    NotificationResult.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: NotificationResult) {
    _instance.notificationType = _instance.notificationType || 0;
    _instance.notificationStatus = _instance.notificationStatus || 0;
    _instance.etag = _instance.etag || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: NotificationResult,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.notificationType = _reader.readEnum();
          break;
        case 2:
          _instance.notificationStatus = _reader.readEnum();
          break;
        case 3:
          _instance.etag = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    NotificationResult.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: NotificationResult,
    _writer: BinaryWriter
  ) {
    if (_instance.notificationType) {
      _writer.writeEnum(1, _instance.notificationType);
    }
    if (_instance.notificationStatus) {
      _writer.writeEnum(2, _instance.notificationStatus);
    }
    if (_instance.etag) {
      _writer.writeString(3, _instance.etag);
    }
  }

  private _notificationType: NotificationType;
  private _notificationStatus: NotificationStatus;
  private _etag: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of NotificationResult to deeply clone from
   */
  constructor(_value?: RecursivePartial<NotificationResult.AsObject>) {
    _value = _value || {};
    this.notificationType = _value.notificationType;
    this.notificationStatus = _value.notificationStatus;
    this.etag = _value.etag;
    NotificationResult.refineValues(this);
  }
  get notificationType(): NotificationType {
    return this._notificationType;
  }
  set notificationType(value: NotificationType) {
    this._notificationType = value;
  }
  get notificationStatus(): NotificationStatus {
    return this._notificationStatus;
  }
  set notificationStatus(value: NotificationStatus) {
    this._notificationStatus = value;
  }
  get etag(): string {
    return this._etag;
  }
  set etag(value: string) {
    this._etag = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    NotificationResult.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): NotificationResult.AsObject {
    return {
      notificationType: this.notificationType,
      notificationStatus: this.notificationStatus,
      etag: this.etag,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): NotificationResult.AsProtobufJSON {
    return {
      notificationType:
        NotificationType[
          this.notificationType === null || this.notificationType === undefined
            ? 0
            : this.notificationType
        ],
      notificationStatus:
        NotificationStatus[
          this.notificationStatus === null ||
          this.notificationStatus === undefined
            ? 0
            : this.notificationStatus
        ],
      etag: this.etag,
    };
  }
}
export module NotificationResult {
  /**
   * Standard JavaScript object representation for NotificationResult
   */
  export interface AsObject {
    notificationType: NotificationType;
    notificationStatus: NotificationStatus;
    etag: string;
  }

  /**
   * Protobuf JSON representation for NotificationResult
   */
  export interface AsProtobufJSON {
    notificationType: string;
    notificationStatus: string;
    etag: string;
  }
}
