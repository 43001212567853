/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
export enum PublisherEventType {
  PUBLISHER_EVENT_TYPE_UNSPECIFIED = 0,
  PUBLISHER_EVENT_TYPE_CLICK = 2,
  PUBLISHER_EVENT_TYPE_VIEW = 3,
  PUBLISHER_EVENT_TYPE_ENGAGED_VIEW = 4,
}
