<div class="modal">
  <div mat-dialog-title>
    <h2 class="modal-title">{{ title }}</h2>
    <button
      mat-icon-button
      aria-label="close dialog"
      mat-dialog-close
      class="modal-close-btn"
      (click)="close()"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div mat-dialog-content class="content">
    <p>
      By accessing or using this source code, you confirm your agreement to be
      bound by our <a href="terms-of-use">Terms of Use</a>, which prohibits:
    </p>

    <ol>
      <li>
        Using the source code for any purpose other than verifying its accuracy,
      </li>
      <li>
        Distributing the source code to third parties outside your organization
        and
      </li>
      <li>
        Publishing the code to a public forum without Anonym’s express and
        written approval.
      </li>
    </ol>

    <div class="toolbar">
      <button
        mat-flat-button
        color="primary"
        class="agree-btn"
        data-testid="agreeButton"
        (click)="process()"
      >
        Agree and continue
      </button>
    </div>
    <div class="footer">Copyright 2024 Anonym Inc. All rights reserved.</div>
  </div>
</div>
