/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import {
  GrpcMessage,
  RecursivePartial,
  ToProtobufJSONOptions,
} from '@ngx-grpc/common';
import { BinaryReader, BinaryWriter, ByteSource } from 'google-protobuf';
import * as api000 from '../../../../src/main/proto/api/headers.pb';
import * as api001 from '../../../../src/main/proto/api/pagination.pb';
/**
 * Message implementation for api.user.CreateRequest
 */
export class CreateRequest implements GrpcMessage {
  static id = 'api.user.CreateRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new CreateRequest();
    CreateRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: CreateRequest) {
    _instance.header = _instance.header || undefined;
    _instance.email = _instance.email || '';
    _instance.roles = _instance.roles || [];

    _instance.phoneNumber = _instance.phoneNumber || '';
    _instance.displayName = _instance.displayName || '';
    _instance.photoUrl = _instance.photoUrl || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: CreateRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api000.RequestHeader();
          _reader.readMessage(
            _instance.header,
            api000.RequestHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.email = _reader.readString();
          break;
        case 3:
          (_instance.roles = _instance.roles || []).push(_reader.readString());
          break;
        case 4:
          _instance.tenantId = _reader.readString();
          break;
        case 5:
          _instance.phoneNumber = _reader.readString();
          break;
        case 6:
          _instance.displayName = _reader.readString();
          break;
        case 7:
          _instance.photoUrl = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    CreateRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: CreateRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api000.RequestHeader.serializeBinaryToWriter
      );
    }
    if (_instance.email) {
      _writer.writeString(2, _instance.email);
    }
    if (_instance.roles && _instance.roles.length) {
      _writer.writeRepeatedString(3, _instance.roles);
    }
    if (_instance.tenantId !== undefined && _instance.tenantId !== null) {
      _writer.writeString(4, _instance.tenantId);
    }
    if (_instance.phoneNumber) {
      _writer.writeString(5, _instance.phoneNumber);
    }
    if (_instance.displayName) {
      _writer.writeString(6, _instance.displayName);
    }
    if (_instance.photoUrl) {
      _writer.writeString(7, _instance.photoUrl);
    }
  }

  private _header?: api000.RequestHeader;
  private _email: string;
  private _roles: string[];
  private _tenantId?: string;
  private _phoneNumber: string;
  private _displayName: string;
  private _photoUrl: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of CreateRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<CreateRequest.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api000.RequestHeader(_value.header)
      : undefined;
    this.email = _value.email;
    this.roles = (_value.roles || []).slice();
    this.tenantId = _value.tenantId;
    this.phoneNumber = _value.phoneNumber;
    this.displayName = _value.displayName;
    this.photoUrl = _value.photoUrl;
    CreateRequest.refineValues(this);
  }
  get header(): api000.RequestHeader | undefined {
    return this._header;
  }
  set header(value: api000.RequestHeader | undefined) {
    this._header = value;
  }
  get email(): string {
    return this._email;
  }
  set email(value: string) {
    this._email = value;
  }
  get roles(): string[] {
    return this._roles;
  }
  set roles(value: string[]) {
    this._roles = value;
  }
  get tenantId(): string | undefined {
    return this._tenantId;
  }
  set tenantId(value?: string) {
    this._tenantId = value;
  }
  get phoneNumber(): string {
    return this._phoneNumber;
  }
  set phoneNumber(value: string) {
    this._phoneNumber = value;
  }
  get displayName(): string {
    return this._displayName;
  }
  set displayName(value: string) {
    this._displayName = value;
  }
  get photoUrl(): string {
    return this._photoUrl;
  }
  set photoUrl(value: string) {
    this._photoUrl = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    CreateRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): CreateRequest.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      email: this.email,
      roles: (this.roles || []).slice(),
      tenantId: this.tenantId,
      phoneNumber: this.phoneNumber,
      displayName: this.displayName,
      photoUrl: this.photoUrl,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): CreateRequest.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      email: this.email,
      roles: (this.roles || []).slice(),
      tenantId:
        this.tenantId === null || this.tenantId === undefined
          ? null
          : this.tenantId,
      phoneNumber: this.phoneNumber,
      displayName: this.displayName,
      photoUrl: this.photoUrl,
    };
  }
}
export module CreateRequest {
  /**
   * Standard JavaScript object representation for CreateRequest
   */
  export interface AsObject {
    header?: api000.RequestHeader.AsObject;
    email: string;
    roles: string[];
    tenantId?: string;
    phoneNumber: string;
    displayName: string;
    photoUrl: string;
  }

  /**
   * Protobuf JSON representation for CreateRequest
   */
  export interface AsProtobufJSON {
    header: api000.RequestHeader.AsProtobufJSON | null;
    email: string;
    roles: string[];
    tenantId: string | null;
    phoneNumber: string;
    displayName: string;
    photoUrl: string;
  }
}

/**
 * Message implementation for api.user.CreateResponse
 */
export class CreateResponse implements GrpcMessage {
  static id = 'api.user.CreateResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new CreateResponse();
    CreateResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: CreateResponse) {
    _instance.header = _instance.header || undefined;
    _instance.uid = _instance.uid || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: CreateResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api000.ResponseHeader();
          _reader.readMessage(
            _instance.header,
            api000.ResponseHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.uid = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    CreateResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: CreateResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api000.ResponseHeader.serializeBinaryToWriter
      );
    }
    if (_instance.uid) {
      _writer.writeString(2, _instance.uid);
    }
  }

  private _header?: api000.ResponseHeader;
  private _uid: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of CreateResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<CreateResponse.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api000.ResponseHeader(_value.header)
      : undefined;
    this.uid = _value.uid;
    CreateResponse.refineValues(this);
  }
  get header(): api000.ResponseHeader | undefined {
    return this._header;
  }
  set header(value: api000.ResponseHeader | undefined) {
    this._header = value;
  }
  get uid(): string {
    return this._uid;
  }
  set uid(value: string) {
    this._uid = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    CreateResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): CreateResponse.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      uid: this.uid,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): CreateResponse.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      uid: this.uid,
    };
  }
}
export module CreateResponse {
  /**
   * Standard JavaScript object representation for CreateResponse
   */
  export interface AsObject {
    header?: api000.ResponseHeader.AsObject;
    uid: string;
  }

  /**
   * Protobuf JSON representation for CreateResponse
   */
  export interface AsProtobufJSON {
    header: api000.ResponseHeader.AsProtobufJSON | null;
    uid: string;
  }
}

/**
 * Message implementation for api.user.DeleteRequest
 */
export class DeleteRequest implements GrpcMessage {
  static id = 'api.user.DeleteRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new DeleteRequest();
    DeleteRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: DeleteRequest) {
    _instance.header = _instance.header || undefined;
    _instance.uid = _instance.uid || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: DeleteRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api000.RequestHeader();
          _reader.readMessage(
            _instance.header,
            api000.RequestHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.uid = _reader.readString();
          break;
        case 3:
          _instance.tenantId = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    DeleteRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: DeleteRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api000.RequestHeader.serializeBinaryToWriter
      );
    }
    if (_instance.uid) {
      _writer.writeString(2, _instance.uid);
    }
    if (_instance.tenantId !== undefined && _instance.tenantId !== null) {
      _writer.writeString(3, _instance.tenantId);
    }
  }

  private _header?: api000.RequestHeader;
  private _uid: string;
  private _tenantId?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of DeleteRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<DeleteRequest.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api000.RequestHeader(_value.header)
      : undefined;
    this.uid = _value.uid;
    this.tenantId = _value.tenantId;
    DeleteRequest.refineValues(this);
  }
  get header(): api000.RequestHeader | undefined {
    return this._header;
  }
  set header(value: api000.RequestHeader | undefined) {
    this._header = value;
  }
  get uid(): string {
    return this._uid;
  }
  set uid(value: string) {
    this._uid = value;
  }
  get tenantId(): string | undefined {
    return this._tenantId;
  }
  set tenantId(value?: string) {
    this._tenantId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    DeleteRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): DeleteRequest.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      uid: this.uid,
      tenantId: this.tenantId,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): DeleteRequest.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      uid: this.uid,
      tenantId:
        this.tenantId === null || this.tenantId === undefined
          ? null
          : this.tenantId,
    };
  }
}
export module DeleteRequest {
  /**
   * Standard JavaScript object representation for DeleteRequest
   */
  export interface AsObject {
    header?: api000.RequestHeader.AsObject;
    uid: string;
    tenantId?: string;
  }

  /**
   * Protobuf JSON representation for DeleteRequest
   */
  export interface AsProtobufJSON {
    header: api000.RequestHeader.AsProtobufJSON | null;
    uid: string;
    tenantId: string | null;
  }
}

/**
 * Message implementation for api.user.DeleteResponse
 */
export class DeleteResponse implements GrpcMessage {
  static id = 'api.user.DeleteResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new DeleteResponse();
    DeleteResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: DeleteResponse) {
    _instance.header = _instance.header || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: DeleteResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api000.ResponseHeader();
          _reader.readMessage(
            _instance.header,
            api000.ResponseHeader.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    DeleteResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: DeleteResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api000.ResponseHeader.serializeBinaryToWriter
      );
    }
  }

  private _header?: api000.ResponseHeader;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of DeleteResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<DeleteResponse.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api000.ResponseHeader(_value.header)
      : undefined;
    DeleteResponse.refineValues(this);
  }
  get header(): api000.ResponseHeader | undefined {
    return this._header;
  }
  set header(value: api000.ResponseHeader | undefined) {
    this._header = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    DeleteResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): DeleteResponse.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): DeleteResponse.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
    };
  }
}
export module DeleteResponse {
  /**
   * Standard JavaScript object representation for DeleteResponse
   */
  export interface AsObject {
    header?: api000.ResponseHeader.AsObject;
  }

  /**
   * Protobuf JSON representation for DeleteResponse
   */
  export interface AsProtobufJSON {
    header: api000.ResponseHeader.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for api.user.UpdateRequest
 */
export class UpdateRequest implements GrpcMessage {
  static id = 'api.user.UpdateRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new UpdateRequest();
    UpdateRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: UpdateRequest) {
    _instance.header = _instance.header || undefined;
    _instance.uid = _instance.uid || '';
    _instance.disable = _instance.disable || false;
    _instance.email = _instance.email || '';
    _instance.roles = _instance.roles || [];
    _instance.phoneNumber = _instance.phoneNumber || '';
    _instance.displayName = _instance.displayName || '';
    _instance.photoUrl = _instance.photoUrl || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: UpdateRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api000.RequestHeader();
          _reader.readMessage(
            _instance.header,
            api000.RequestHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.uid = _reader.readString();
          break;
        case 3:
          _instance.disable = _reader.readBool();
          break;
        case 4:
          _instance.email = _reader.readString();
          break;
        case 5:
          (_instance.roles = _instance.roles || []).push(_reader.readString());
          break;
        case 6:
          _instance.phoneNumber = _reader.readString();
          break;
        case 7:
          _instance.displayName = _reader.readString();
          break;
        case 8:
          _instance.photoUrl = _reader.readString();
          break;
        case 9:
          _instance.tenantId = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    UpdateRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: UpdateRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api000.RequestHeader.serializeBinaryToWriter
      );
    }
    if (_instance.uid) {
      _writer.writeString(2, _instance.uid);
    }
    if (_instance.disable) {
      _writer.writeBool(3, _instance.disable);
    }
    if (_instance.email) {
      _writer.writeString(4, _instance.email);
    }
    if (_instance.roles && _instance.roles.length) {
      _writer.writeRepeatedString(5, _instance.roles);
    }
    if (_instance.phoneNumber) {
      _writer.writeString(6, _instance.phoneNumber);
    }
    if (_instance.displayName) {
      _writer.writeString(7, _instance.displayName);
    }
    if (_instance.photoUrl) {
      _writer.writeString(8, _instance.photoUrl);
    }
    if (_instance.tenantId !== undefined && _instance.tenantId !== null) {
      _writer.writeString(9, _instance.tenantId);
    }
  }

  private _header?: api000.RequestHeader;
  private _uid: string;
  private _disable: boolean;
  private _email: string;
  private _roles: string[];
  private _phoneNumber: string;
  private _displayName: string;
  private _photoUrl: string;
  private _tenantId?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of UpdateRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<UpdateRequest.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api000.RequestHeader(_value.header)
      : undefined;
    this.uid = _value.uid;
    this.disable = _value.disable;
    this.email = _value.email;
    this.roles = (_value.roles || []).slice();
    this.phoneNumber = _value.phoneNumber;
    this.displayName = _value.displayName;
    this.photoUrl = _value.photoUrl;
    this.tenantId = _value.tenantId;
    UpdateRequest.refineValues(this);
  }
  get header(): api000.RequestHeader | undefined {
    return this._header;
  }
  set header(value: api000.RequestHeader | undefined) {
    this._header = value;
  }
  get uid(): string {
    return this._uid;
  }
  set uid(value: string) {
    this._uid = value;
  }
  get disable(): boolean {
    return this._disable;
  }
  set disable(value: boolean) {
    this._disable = value;
  }
  get email(): string {
    return this._email;
  }
  set email(value: string) {
    this._email = value;
  }
  get roles(): string[] {
    return this._roles;
  }
  set roles(value: string[]) {
    this._roles = value;
  }
  get phoneNumber(): string {
    return this._phoneNumber;
  }
  set phoneNumber(value: string) {
    this._phoneNumber = value;
  }
  get displayName(): string {
    return this._displayName;
  }
  set displayName(value: string) {
    this._displayName = value;
  }
  get photoUrl(): string {
    return this._photoUrl;
  }
  set photoUrl(value: string) {
    this._photoUrl = value;
  }
  get tenantId(): string | undefined {
    return this._tenantId;
  }
  set tenantId(value?: string) {
    this._tenantId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    UpdateRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): UpdateRequest.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      uid: this.uid,
      disable: this.disable,
      email: this.email,
      roles: (this.roles || []).slice(),
      phoneNumber: this.phoneNumber,
      displayName: this.displayName,
      photoUrl: this.photoUrl,
      tenantId: this.tenantId,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): UpdateRequest.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      uid: this.uid,
      disable: this.disable,
      email: this.email,
      roles: (this.roles || []).slice(),
      phoneNumber: this.phoneNumber,
      displayName: this.displayName,
      photoUrl: this.photoUrl,
      tenantId:
        this.tenantId === null || this.tenantId === undefined
          ? null
          : this.tenantId,
    };
  }
}
export module UpdateRequest {
  /**
   * Standard JavaScript object representation for UpdateRequest
   */
  export interface AsObject {
    header?: api000.RequestHeader.AsObject;
    uid: string;
    disable: boolean;
    email: string;
    roles: string[];
    phoneNumber: string;
    displayName: string;
    photoUrl: string;
    tenantId?: string;
  }

  /**
   * Protobuf JSON representation for UpdateRequest
   */
  export interface AsProtobufJSON {
    header: api000.RequestHeader.AsProtobufJSON | null;
    uid: string;
    disable: boolean;
    email: string;
    roles: string[];
    phoneNumber: string;
    displayName: string;
    photoUrl: string;
    tenantId: string | null;
  }
}

/**
 * Message implementation for api.user.UpdateResponse
 */
export class UpdateResponse implements GrpcMessage {
  static id = 'api.user.UpdateResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new UpdateResponse();
    UpdateResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: UpdateResponse) {
    _instance.header = _instance.header || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: UpdateResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api000.ResponseHeader();
          _reader.readMessage(
            _instance.header,
            api000.ResponseHeader.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    UpdateResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: UpdateResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api000.ResponseHeader.serializeBinaryToWriter
      );
    }
  }

  private _header?: api000.ResponseHeader;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of UpdateResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<UpdateResponse.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api000.ResponseHeader(_value.header)
      : undefined;
    UpdateResponse.refineValues(this);
  }
  get header(): api000.ResponseHeader | undefined {
    return this._header;
  }
  set header(value: api000.ResponseHeader | undefined) {
    this._header = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    UpdateResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): UpdateResponse.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): UpdateResponse.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
    };
  }
}
export module UpdateResponse {
  /**
   * Standard JavaScript object representation for UpdateResponse
   */
  export interface AsObject {
    header?: api000.ResponseHeader.AsObject;
  }

  /**
   * Protobuf JSON representation for UpdateResponse
   */
  export interface AsProtobufJSON {
    header: api000.ResponseHeader.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for api.user.SetPasswordRequest
 */
export class SetPasswordRequest implements GrpcMessage {
  static id = 'api.user.SetPasswordRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new SetPasswordRequest();
    SetPasswordRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: SetPasswordRequest) {
    _instance.header = _instance.header || undefined;
    _instance.password = _instance.password || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: SetPasswordRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api000.RequestHeader();
          _reader.readMessage(
            _instance.header,
            api000.RequestHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.password = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    SetPasswordRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: SetPasswordRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api000.RequestHeader.serializeBinaryToWriter
      );
    }
    if (_instance.password) {
      _writer.writeString(2, _instance.password);
    }
  }

  private _header?: api000.RequestHeader;
  private _password: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of SetPasswordRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<SetPasswordRequest.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api000.RequestHeader(_value.header)
      : undefined;
    this.password = _value.password;
    SetPasswordRequest.refineValues(this);
  }
  get header(): api000.RequestHeader | undefined {
    return this._header;
  }
  set header(value: api000.RequestHeader | undefined) {
    this._header = value;
  }
  get password(): string {
    return this._password;
  }
  set password(value: string) {
    this._password = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    SetPasswordRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): SetPasswordRequest.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      password: this.password,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): SetPasswordRequest.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      password: this.password,
    };
  }
}
export module SetPasswordRequest {
  /**
   * Standard JavaScript object representation for SetPasswordRequest
   */
  export interface AsObject {
    header?: api000.RequestHeader.AsObject;
    password: string;
  }

  /**
   * Protobuf JSON representation for SetPasswordRequest
   */
  export interface AsProtobufJSON {
    header: api000.RequestHeader.AsProtobufJSON | null;
    password: string;
  }
}

/**
 * Message implementation for api.user.SetPasswordResponse
 */
export class SetPasswordResponse implements GrpcMessage {
  static id = 'api.user.SetPasswordResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new SetPasswordResponse();
    SetPasswordResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: SetPasswordResponse) {
    _instance.header = _instance.header || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: SetPasswordResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api000.ResponseHeader();
          _reader.readMessage(
            _instance.header,
            api000.ResponseHeader.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    SetPasswordResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: SetPasswordResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api000.ResponseHeader.serializeBinaryToWriter
      );
    }
  }

  private _header?: api000.ResponseHeader;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of SetPasswordResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<SetPasswordResponse.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api000.ResponseHeader(_value.header)
      : undefined;
    SetPasswordResponse.refineValues(this);
  }
  get header(): api000.ResponseHeader | undefined {
    return this._header;
  }
  set header(value: api000.ResponseHeader | undefined) {
    this._header = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    SetPasswordResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): SetPasswordResponse.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): SetPasswordResponse.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
    };
  }
}
export module SetPasswordResponse {
  /**
   * Standard JavaScript object representation for SetPasswordResponse
   */
  export interface AsObject {
    header?: api000.ResponseHeader.AsObject;
  }

  /**
   * Protobuf JSON representation for SetPasswordResponse
   */
  export interface AsProtobufJSON {
    header: api000.ResponseHeader.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for api.user.GetRequest
 */
export class GetRequest implements GrpcMessage {
  static id = 'api.user.GetRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetRequest();
    GetRequest.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetRequest) {
    _instance.header = _instance.header || undefined;
    _instance.firebaseTenantId = _instance.firebaseTenantId || '';
    _instance.paginated = _instance.paginated || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api000.RequestHeader();
          _reader.readMessage(
            _instance.header,
            api000.RequestHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.firebaseTenantId = _reader.readString();
          break;
        case 3:
          _instance.paginated = new api001.GetPaginatedRequest();
          _reader.readMessage(
            _instance.paginated,
            api001.GetPaginatedRequest.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    GetRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(_instance: GetRequest, _writer: BinaryWriter) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api000.RequestHeader.serializeBinaryToWriter
      );
    }
    if (_instance.firebaseTenantId) {
      _writer.writeString(2, _instance.firebaseTenantId);
    }
    if (_instance.paginated) {
      _writer.writeMessage(
        3,
        _instance.paginated as any,
        api001.GetPaginatedRequest.serializeBinaryToWriter
      );
    }
  }

  private _header?: api000.RequestHeader;
  private _firebaseTenantId: string;
  private _paginated?: api001.GetPaginatedRequest;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetRequest.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api000.RequestHeader(_value.header)
      : undefined;
    this.firebaseTenantId = _value.firebaseTenantId;
    this.paginated = _value.paginated
      ? new api001.GetPaginatedRequest(_value.paginated)
      : undefined;
    GetRequest.refineValues(this);
  }
  get header(): api000.RequestHeader | undefined {
    return this._header;
  }
  set header(value: api000.RequestHeader | undefined) {
    this._header = value;
  }
  get firebaseTenantId(): string {
    return this._firebaseTenantId;
  }
  set firebaseTenantId(value: string) {
    this._firebaseTenantId = value;
  }
  get paginated(): api001.GetPaginatedRequest | undefined {
    return this._paginated;
  }
  set paginated(value: api001.GetPaginatedRequest | undefined) {
    this._paginated = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetRequest.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      firebaseTenantId: this.firebaseTenantId,
      paginated: this.paginated ? this.paginated.toObject() : undefined,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetRequest.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      firebaseTenantId: this.firebaseTenantId,
      paginated: this.paginated ? this.paginated.toProtobufJSON(options) : null,
    };
  }
}
export module GetRequest {
  /**
   * Standard JavaScript object representation for GetRequest
   */
  export interface AsObject {
    header?: api000.RequestHeader.AsObject;
    firebaseTenantId: string;
    paginated?: api001.GetPaginatedRequest.AsObject;
  }

  /**
   * Protobuf JSON representation for GetRequest
   */
  export interface AsProtobufJSON {
    header: api000.RequestHeader.AsProtobufJSON | null;
    firebaseTenantId: string;
    paginated: api001.GetPaginatedRequest.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for api.user.GetResponse
 */
export class GetResponse implements GrpcMessage {
  static id = 'api.user.GetResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetResponse();
    GetResponse.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetResponse) {
    _instance.header = _instance.header || undefined;
    _instance.continuationToken = _instance.continuationToken || '';
    _instance.users = _instance.users || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api000.ResponseHeader();
          _reader.readMessage(
            _instance.header,
            api000.ResponseHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.continuationToken = _reader.readString();
          break;
        case 3:
          const messageInitializer3 = new UserInfo();
          _reader.readMessage(
            messageInitializer3,
            UserInfo.deserializeBinaryFromReader
          );
          (_instance.users = _instance.users || []).push(messageInitializer3);
          break;
        default:
          _reader.skipField();
      }
    }

    GetResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api000.ResponseHeader.serializeBinaryToWriter
      );
    }
    if (_instance.continuationToken) {
      _writer.writeString(2, _instance.continuationToken);
    }
    if (_instance.users && _instance.users.length) {
      _writer.writeRepeatedMessage(
        3,
        _instance.users as any,
        UserInfo.serializeBinaryToWriter
      );
    }
  }

  private _header?: api000.ResponseHeader;
  private _continuationToken: string;
  private _users?: UserInfo[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetResponse.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api000.ResponseHeader(_value.header)
      : undefined;
    this.continuationToken = _value.continuationToken;
    this.users = (_value.users || []).map((m) => new UserInfo(m));
    GetResponse.refineValues(this);
  }
  get header(): api000.ResponseHeader | undefined {
    return this._header;
  }
  set header(value: api000.ResponseHeader | undefined) {
    this._header = value;
  }
  get continuationToken(): string {
    return this._continuationToken;
  }
  set continuationToken(value: string) {
    this._continuationToken = value;
  }
  get users(): UserInfo[] | undefined {
    return this._users;
  }
  set users(value: UserInfo[] | undefined) {
    this._users = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetResponse.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      continuationToken: this.continuationToken,
      users: (this.users || []).map((m) => m.toObject()),
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetResponse.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      continuationToken: this.continuationToken,
      users: (this.users || []).map((m) => m.toProtobufJSON(options)),
    };
  }
}
export module GetResponse {
  /**
   * Standard JavaScript object representation for GetResponse
   */
  export interface AsObject {
    header?: api000.ResponseHeader.AsObject;
    continuationToken: string;
    users?: UserInfo.AsObject[];
  }

  /**
   * Protobuf JSON representation for GetResponse
   */
  export interface AsProtobufJSON {
    header: api000.ResponseHeader.AsProtobufJSON | null;
    continuationToken: string;
    users: UserInfo.AsProtobufJSON[] | null;
  }
}

/**
 * Message implementation for api.user.UserInfo
 */
export class UserInfo implements GrpcMessage {
  static id = 'api.user.UserInfo';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new UserInfo();
    UserInfo.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: UserInfo) {
    _instance.uid = _instance.uid || '';
    _instance.email = _instance.email || '';
    _instance.roles = _instance.roles || [];
    _instance.tenantId = _instance.tenantId || '';
    _instance.phoneNumber = _instance.phoneNumber || '';
    _instance.displayName = _instance.displayName || '';
    _instance.photoUrl = _instance.photoUrl || '';
    _instance.disabled = _instance.disabled || false;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: UserInfo,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.uid = _reader.readString();
          break;
        case 2:
          _instance.email = _reader.readString();
          break;
        case 3:
          (_instance.roles = _instance.roles || []).push(_reader.readString());
          break;
        case 4:
          _instance.tenantId = _reader.readString();
          break;
        case 5:
          _instance.phoneNumber = _reader.readString();
          break;
        case 6:
          _instance.displayName = _reader.readString();
          break;
        case 7:
          _instance.photoUrl = _reader.readString();
          break;
        case 8:
          _instance.disabled = _reader.readBool();
          break;
        default:
          _reader.skipField();
      }
    }

    UserInfo.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(_instance: UserInfo, _writer: BinaryWriter) {
    if (_instance.uid) {
      _writer.writeString(1, _instance.uid);
    }
    if (_instance.email) {
      _writer.writeString(2, _instance.email);
    }
    if (_instance.roles && _instance.roles.length) {
      _writer.writeRepeatedString(3, _instance.roles);
    }
    if (_instance.tenantId) {
      _writer.writeString(4, _instance.tenantId);
    }
    if (_instance.phoneNumber) {
      _writer.writeString(5, _instance.phoneNumber);
    }
    if (_instance.displayName) {
      _writer.writeString(6, _instance.displayName);
    }
    if (_instance.photoUrl) {
      _writer.writeString(7, _instance.photoUrl);
    }
    if (_instance.disabled) {
      _writer.writeBool(8, _instance.disabled);
    }
  }

  private _uid: string;
  private _email: string;
  private _roles: string[];
  private _tenantId: string;
  private _phoneNumber: string;
  private _displayName: string;
  private _photoUrl: string;
  private _disabled: boolean;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of UserInfo to deeply clone from
   */
  constructor(_value?: RecursivePartial<UserInfo.AsObject>) {
    _value = _value || {};
    this.uid = _value.uid;
    this.email = _value.email;
    this.roles = (_value.roles || []).slice();
    this.tenantId = _value.tenantId;
    this.phoneNumber = _value.phoneNumber;
    this.displayName = _value.displayName;
    this.photoUrl = _value.photoUrl;
    this.disabled = _value.disabled;
    UserInfo.refineValues(this);
  }
  get uid(): string {
    return this._uid;
  }
  set uid(value: string) {
    this._uid = value;
  }
  get email(): string {
    return this._email;
  }
  set email(value: string) {
    this._email = value;
  }
  get roles(): string[] {
    return this._roles;
  }
  set roles(value: string[]) {
    this._roles = value;
  }
  get tenantId(): string {
    return this._tenantId;
  }
  set tenantId(value: string) {
    this._tenantId = value;
  }
  get phoneNumber(): string {
    return this._phoneNumber;
  }
  set phoneNumber(value: string) {
    this._phoneNumber = value;
  }
  get displayName(): string {
    return this._displayName;
  }
  set displayName(value: string) {
    this._displayName = value;
  }
  get photoUrl(): string {
    return this._photoUrl;
  }
  set photoUrl(value: string) {
    this._photoUrl = value;
  }
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    this._disabled = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    UserInfo.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): UserInfo.AsObject {
    return {
      uid: this.uid,
      email: this.email,
      roles: (this.roles || []).slice(),
      tenantId: this.tenantId,
      phoneNumber: this.phoneNumber,
      displayName: this.displayName,
      photoUrl: this.photoUrl,
      disabled: this.disabled,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): UserInfo.AsProtobufJSON {
    return {
      uid: this.uid,
      email: this.email,
      roles: (this.roles || []).slice(),
      tenantId: this.tenantId,
      phoneNumber: this.phoneNumber,
      displayName: this.displayName,
      photoUrl: this.photoUrl,
      disabled: this.disabled,
    };
  }
}
export module UserInfo {
  /**
   * Standard JavaScript object representation for UserInfo
   */
  export interface AsObject {
    uid: string;
    email: string;
    roles: string[];
    tenantId: string;
    phoneNumber: string;
    displayName: string;
    photoUrl: string;
    disabled: boolean;
  }

  /**
   * Protobuf JSON representation for UserInfo
   */
  export interface AsProtobufJSON {
    uid: string;
    email: string;
    roles: string[];
    tenantId: string;
    phoneNumber: string;
    displayName: string;
    photoUrl: string;
    disabled: boolean;
  }
}
