import { inject } from '@angular/core';
import { getAuth, onAuthStateChanged } from '@angular/fire/auth';
import { ActivatedRouteSnapshot, Router } from '@angular/router';

import { LoggerService } from '../services/logger.service';
import { PermissionService } from '../services/permission.service';

export async function userRolesGuard(route: ActivatedRouteSnapshot) {
  const router = inject(Router);
  const permissionService = inject(PermissionService);
  const logger = inject(LoggerService);

  return new Promise((resolve, reject) => {
    const auth = getAuth();

    onAuthStateChanged(
      auth,
      (user) => {
        if (user) {
          // Is logged in
          // Will check the matching roles
          permissionService
            .hasMatchingRoles(route.data['expectedRoles'])
            .then((hasRoles) => {
              if (hasRoles) {
                resolve(true);
              } else {
                logger.error(
                  'User does not have the required roles to view this page.'
                );
                router.navigate(['/landing-page']);
                resolve(false);
              }
            })
            .catch((error) => {
              auth.signOut();
              reject(error);
            });
        } else {
          router.navigate(['/sign-in-options'], {
            queryParams: { redirect: route.url[0] },
          });
          resolve(false);
        }
      },
      (error) => reject(error)
    );
  });
}
