import { Component, OnInit, Optional } from '@angular/core';
import { Auth } from '@angular/fire/auth';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { User as PublicUser } from 'types/user';

import { EventBusService, Events } from './services/event-bus.service';
import { NotificationService } from './services/notification.service';
import { UrlParamsService } from './services/url-params.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'attestation-portal';
  user: PublicUser | undefined;
  icons = [
    'home_menu',
    'job_activity',
    'knowledge_base',
    'prep_list',
    'your_binaries',
    'home_menu_selected',
    'job_activity_selected',
    'knowledge_base_selected',
    'prep_list_selected',
    'your_binaries_selected',
  ];

  constructor(
    @Optional() private auth: Auth,
    private events: EventBusService,
    private urlParamsService: UrlParamsService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private notificationService: NotificationService
  ) {
    this.auth.onAuthStateChanged((user) => {
      if (user) {
        const { photoURL, uid, email, emailVerified, displayName } = user;
        this.user = {
          photoURL: photoURL ?? './assets/default-profile.png',
          uid,
          email: email ?? '',
          emailVerified,
          displayName: displayName ?? email ?? 'Unknown',
          roles: '',
        };
      } else {
        this.user = undefined;
      }
    });

    // Initialize the service from the initial URL.
    this.urlParamsService.parseUrl(document.URL);
    this.notificationService.requestPermission();
    this.notificationService.listen();
  }

  private loadIcons(iconKeys: string[]): void {
    iconKeys.forEach((key) => {
      this.matIconRegistry.addSvgIcon(
        key,
        this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/${key}.svg`)
      );
    });
  }

  ngOnInit(): void {
    this.loadIcons(Object.values(this.icons));
    this.events.on(Events.UserProfileChange, (data: any) => {
      this.user = {
        ...this.user,
        displayName: `${data.firstName} ${data.lastName}`,
        photoURL: data.photoUrl,
      } as PublicUser;
    });
  }
}
