<div
  *ngIf="active"
  [class]="messageType === 'success' ? 'success' : 'error'"
  data-testid="message-box"
>
  <div>
    <mat-icon class="icon-success" *ngIf="messageType === 'success'"
      >check_circle</mat-icon
    >
    <mat-icon class="icon-error" *ngIf="messageType === 'error'"
      >cancel</mat-icon
    >
  </div>
  <h1 class="text-message">{{ message }}</h1>
</div>
