import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ActivatedRoute } from '@angular/router';
import { EventBusService, Events } from 'app/services/event-bus.service';
import { FormatService } from 'app/services/format.service';
import { LoggerService } from 'app/services/logger.service';
import { NotificationService } from 'app/services/notification.service';
import { NotificationType } from 'types/notification';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent implements OnInit {
  time: string = this.formatService.getLastUpdate();
  isLoading = false;
  notifications: NotificationType[] = [];
  isAllVisible = false;

  constructor(
    private cdr: ChangeDetectorRef,
    private events: EventBusService,
    private logger: LoggerService,
    private notificationService: NotificationService,
    private formatService: FormatService,
    private route: ActivatedRoute
  ) {
    this.events.on(Events.NotificationReceived, () => {
      this.getNotifications();
    });
  }

  ngOnInit(): void {
    this.getNotifications();
    this.route.fragment.subscribe((fragment: string | null) => {
      if (fragment) {
        document.querySelector(`#${fragment}`)?.scrollIntoView();
      }
    });
  }

  markAsRead(messageId: string) {
    this.notifications.forEach((notification) => {
      const message = notification as NotificationType;
      if (message.messagePayload.messageId == messageId) {
        message.viewed = !message.viewed;
        this.notificationService.saveNotification(message);
      }
    });
  }

  async getNotifications() {
    this.isLoading = true;
    const notifications = await this.notificationService.getNotifications();
    notifications.forEach((notification) => {
      this.notifications.push(notification as NotificationType);
    });
    this.isLoading = false;
  }

  formatISODate(timestamp: number) {
    return this.formatService.formatTimestamp(timestamp);
  }

  showAll(event: MatTabChangeEvent) {
    event.index === 0
      ? (this.isAllVisible = false)
      : (this.isAllVisible = true);
    this.cdr.detectChanges();
  }
}
