/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import { Inject, Injectable, Optional } from '@angular/core';
import {
  GrpcCallType,
  GrpcClient,
  GrpcClientFactory,
  GrpcEvent,
  GrpcMetadata,
} from '@ngx-grpc/common';
import {
  GRPC_CLIENT_FACTORY,
  GrpcHandler,
  takeMessages,
  throwStatusErrors,
} from '@ngx-grpc/core';
import { Observable } from 'rxjs';
import * as thisProto from './job-schedule-service.pb';
import * as googleProtobuf000 from '@ngx-grpc/well-known-types';
import * as storage001 from '../../../../src/main/proto/storage/schema-annotations.pb';
import * as api002 from '../../../../src/main/proto/api/headers.pb';
import * as api003 from '../../../../src/main/proto/api/pagination.pb';
import * as storage004 from '../../../../src/main/proto/storage/job-schedule.pb';
import { GRPC_JOB_SCHEDULE_SERVICE_CLIENT_SETTINGS } from './job-schedule-service.pbconf';
/**
 * Service client implementation for api.job_schedule.JobScheduleService
 */
@Injectable({ providedIn: 'any' })
export class JobScheduleServiceClient {
  private client: GrpcClient<any>;

  /**
   * Raw RPC implementation for each service client method.
   * The raw methods provide more control on the incoming data and events. E.g. they can be useful to read status `OK` metadata.
   * Attention: these methods do not throw errors when non-zero status codes are received.
   */
  $raw = {
    /**
     * Unary call: /api.job_schedule.JobScheduleService/Create
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.CreateResponse>>
     */
    create: (
      requestData: thisProto.CreateRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.CreateResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/api.job_schedule.JobScheduleService/Create',
        requestData,
        requestMetadata,
        requestClass: thisProto.CreateRequest,
        responseClass: thisProto.CreateResponse,
      });
    },
    /**
     * Unary call: /api.job_schedule.JobScheduleService/Get
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.GetResponse>>
     */
    get: (
      requestData: thisProto.GetRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.GetResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/api.job_schedule.JobScheduleService/Get',
        requestData,
        requestMetadata,
        requestClass: thisProto.GetRequest,
        responseClass: thisProto.GetResponse,
      });
    },
    /**
     * Unary call: /api.job_schedule.JobScheduleService/Update
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.UpdateResponse>>
     */
    update: (
      requestData: thisProto.UpdateRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.UpdateResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/api.job_schedule.JobScheduleService/Update',
        requestData,
        requestMetadata,
        requestClass: thisProto.UpdateRequest,
        responseClass: thisProto.UpdateResponse,
      });
    },
    /**
     * Unary call: /api.job_schedule.JobScheduleService/List
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.ListResponse>>
     */
    list: (
      requestData: thisProto.ListRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.ListResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/api.job_schedule.JobScheduleService/List',
        requestData,
        requestMetadata,
        requestClass: thisProto.ListRequest,
        responseClass: thisProto.ListResponse,
      });
    },
    /**
     * Unary call: /api.job_schedule.JobScheduleService/Archive
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.ArchiveResponse>>
     */
    archive: (
      requestData: thisProto.ArchiveRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.ArchiveResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/api.job_schedule.JobScheduleService/Archive',
        requestData,
        requestMetadata,
        requestClass: thisProto.ArchiveRequest,
        responseClass: thisProto.ArchiveResponse,
      });
    },
  };

  constructor(
    @Optional()
    @Inject(GRPC_JOB_SCHEDULE_SERVICE_CLIENT_SETTINGS)
    settings: any,
    @Inject(GRPC_CLIENT_FACTORY) clientFactory: GrpcClientFactory<any>,
    private handler: GrpcHandler
  ) {
    this.client = clientFactory.createClient(
      'api.job_schedule.JobScheduleService',
      settings
    );
  }

  /**
   * Unary call @/api.job_schedule.JobScheduleService/Create
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.CreateResponse>
   */
  create(
    requestData: thisProto.CreateRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.CreateResponse> {
    return this.$raw
      .create(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/api.job_schedule.JobScheduleService/Get
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.GetResponse>
   */
  get(
    requestData: thisProto.GetRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.GetResponse> {
    return this.$raw
      .get(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/api.job_schedule.JobScheduleService/Update
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.UpdateResponse>
   */
  update(
    requestData: thisProto.UpdateRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.UpdateResponse> {
    return this.$raw
      .update(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/api.job_schedule.JobScheduleService/List
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.ListResponse>
   */
  list(
    requestData: thisProto.ListRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.ListResponse> {
    return this.$raw
      .list(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/api.job_schedule.JobScheduleService/Archive
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.ArchiveResponse>
   */
  archive(
    requestData: thisProto.ArchiveRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.ArchiveResponse> {
    return this.$raw
      .archive(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }
}
