<app-container>
  <app-page-header title="Job Schedule" lastUpdated="{{ lastUpdate }}">
  </app-page-header>
  <app-message-box></app-message-box>

  <section class="filter-container">
    <div class="time-input">
      <label for="startTime">Start Time:</label>
      <input
        type="time"
        id="startTime"
        name="startTime"
        [(ngModel)]="filterStartTime"
      />
    </div>
    <div class="time-input">
      <label for="endTime">End Time:</label>
      <input
        type="time"
        id="endTime"
        name="endTime"
        [(ngModel)]="filterEndTime"
      />
    </div>
    <div class="filter-button">
      <button class="apply-filters-button" (click)="applyFilters()">
        Apply Filters
      </button>
    </div>
  </section>

  <section class="data-table-container" tabindex="0">
    <div class="data-table-title-container">
      <h2 class="data-users-title">Job Schedule</h2>
      <button class="add-user-button" (click)="createJobSchedule()">
        Add Job Schedule
      </button>
    </div>

    <table mat-table [dataSource]="dataSource" class="table-data" matSort>
      <ng-container matColumnDef="ID">
        <th class="row-header" mat-header-cell *matHeaderCellDef>ID</th>
        <td mat-cell *matCellDef="let element">{{ element.id }}</td>
      </ng-container>

      <ng-container matColumnDef="Name">
        <th class="row-header" mat-header-cell *matHeaderCellDef>Name</th>
        <td mat-cell *matCellDef="let element">{{ element.name }}</td>
      </ng-container>

      <ng-container matColumnDef="jobLogId">
        <th
          class="row-header"
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          sortActionDescription="Sort by joblog Id"
        >
          JobLog ID
        </th>
        <td mat-cell *matCellDef="let element">{{ element.jobLogId }}</td>
      </ng-container>

      <ng-container matColumnDef="startTime">
        <th mat-header-cell *matHeaderCellDef>Start Time</th>
        <td mat-cell *matCellDef="let element">
          {{ '0' + element.startTime.startHour | slice: -2 }}:{{
            '0' + element.startTime.startMinute | slice: -2
          }}
        </td>
      </ng-container>

      <ng-container matColumnDef="lastUpdate">
        <th
          class="row-header"
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          sortActionDescription="Sort by last update"
        >
          Last Updated
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.lastUpdate.seconds * 1000 | date: 'medium' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="menu">
        <th class="row-header" mat-header-cell *matHeaderCellDef>Actions</th>
        <td mat-cell *matCellDef="let element">
          <div class="user-name-col">
            <button mat-icon-button [matMenuTriggerFor]="actionsMenu">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #actionsMenu>
              <button mat-menu-item (click)="archive(element.id)">
                <mat-icon>delete</mat-icon>
                <span>Archive</span>
              </button>
            </mat-menu>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: columnsToDisplay"
        class="table-row"
      ></tr>
    </table>
  </section>

  <div class="footer">
    <div *ngIf="isLoading" class="loader">
      <mat-spinner [diameter]="dataSource.data.length ? 24 : 42"></mat-spinner>
    </div>
    <mat-paginator
      *ngIf="(!isLoading || dataSource.data.length) && totalRecords > pageSize"
      [length]="totalRecords"
      [pageSize]="pageSize"
      [pageIndex]="currentPageIndex"
      (page)="onPageChange($event)"
      [disabled]="isLoading"
    ></mat-paginator>
  </div>
</app-container>
