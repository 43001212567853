<app-container>
  <div class="grid">
    <div class="card featured">
      <div>
        <h1 data-testid="welcomeTitle">{{ welcomeTitle }}</h1>
        <p data-testid="welcomeContent">{{ welcomeText }}</p>
        <a color="primary" mat-flat-button routerLink="{{ welcomeLink }}"
          >Get Started</a
        >
      </div>
      <img #welcomeImage class="welcome-image" alt="" />
    </div>

    <div class="card">
      <img class="icon" #knowledgeBaseImage />
      <h2 data-testid="securityResourcesTitle">{{ knowledgeBaseTitle }}</h2>
      <p data-testid="securityResourcesContent">{{ knowledgeBaseText }}</p>
      <a class="arrow" routerLink="{{ knowledgeBaseLink }}"
        ><img src="assets/arrow-right.png"
      /></a>
    </div>

    <div class="card">
      <img class="icon" #auditResourcesImage />
      <h2 data-testid="auditResourcesTitle">{{ auditResourcesTitle }}</h2>
      <p data-testid="auditResourcesContent">{{ auditResourcesText }}</p>
      <a class="arrow" routerLink="{{ auditResourcesLink }}"
        ><img src="assets/arrow-right.png"
      /></a>
    </div>

    <div class="card">
      <img class="icon" #systemOverviewImage />
      <h2 data-testid="approvalTitle">{{ systemOverviewTitle }}</h2>
      <p data-testid="approvalContent">{{ systemOverviewText }}</p>
      <a class="arrow" routerLink="{{ systemOverviewLink }}"
        ><img src="assets/arrow-right.png"
      /></a>
    </div>

    <div class="card">
      <img class="icon" #jobLogImage />
      <h2 data-testid="jobLogTitle">{{ jobLogTitle }}</h2>
      <p data-testid="jobLogContent">{{ jobLogText }}</p>
      <a class="arrow" routerLink="{{ jobLogLink }}"
        ><img src="assets/arrow-right.png"
      /></a>
    </div>
  </div>
</app-container>
