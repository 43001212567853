import moment from 'moment';

export function validateKeyParameters(
  expiryTime?: string,
  beforeExpiryTime?: string
): boolean {
  // Either both or none of the two time periods should be set.
  if ((expiryTime && !beforeExpiryTime) || (!expiryTime && beforeExpiryTime)) {
    return false;
  }

  let expiryDays = 0;
  if (expiryTime) {
    const duration = moment.duration(expiryTime);
    if (!duration) {
      return false;
    }
    // HSM requires at least 30 days.
    expiryDays = duration.asDays();
    if (expiryDays < 30) {
      return false;
    }
  }
  if (beforeExpiryTime) {
    const duration = moment.duration(beforeExpiryTime);
    if (!duration) {
      return false;
    }
    const beforeExpiryDays = duration.asDays();
    if (beforeExpiryDays >= expiryDays || beforeExpiryDays === 0) {
      return false;
    }
  }

  return true;
}
