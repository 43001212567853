/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import {
  GrpcMessage,
  RecursivePartial,
  ToProtobufJSONOptions,
} from '@ngx-grpc/common';
import { BinaryReader, BinaryWriter, ByteSource } from 'google-protobuf';
import * as googleProtobuf000 from '@ngx-grpc/well-known-types';
import * as googleProtobuf001 from '@ngx-grpc/well-known-types';
import * as storage002 from '../../../../src/main/proto/storage/schema-annotations.pb';
/**
 * Message implementation for storage.KeyConfig
 */
export class KeyConfig implements GrpcMessage {
  static id = 'storage.KeyConfig';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new KeyConfig();
    KeyConfig.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: KeyConfig) {
    _instance.id = _instance.id || '';

    _instance.lastUpdate = _instance.lastUpdate || undefined;
    _instance.customerId = _instance.customerId || '';
    _instance.keyName = _instance.keyName || '';

    _instance.archived = _instance.archived || false;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: KeyConfig,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readString();
          break;
        case 9:
          _instance.etag = _reader.readString();
          break;
        case 10:
          _instance.lastUpdate = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.lastUpdate,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.customerId = _reader.readString();
          break;
        case 3:
          _instance.keyName = _reader.readString();
          break;
        case 4:
          _instance.expiryTime = _reader.readString();
          break;
        case 5:
          _instance.beforeExpiryTime = _reader.readString();
          break;
        case 6:
          _instance.hsm = new HsmKeyConfig();
          _reader.readMessage(
            _instance.hsm,
            HsmKeyConfig.deserializeBinaryFromReader
          );
          break;
        case 7:
          _instance.api = new ApiKeyConfig();
          _reader.readMessage(
            _instance.api,
            ApiKeyConfig.deserializeBinaryFromReader
          );
          break;
        case 8:
          _instance.archived = _reader.readBool();
          break;
        default:
          _reader.skipField();
      }
    }

    KeyConfig.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(_instance: KeyConfig, _writer: BinaryWriter) {
    if (_instance.id) {
      _writer.writeString(1, _instance.id);
    }
    if (_instance.etag !== undefined && _instance.etag !== null) {
      _writer.writeString(9, _instance.etag);
    }
    if (_instance.lastUpdate) {
      _writer.writeMessage(
        10,
        _instance.lastUpdate as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.customerId) {
      _writer.writeString(2, _instance.customerId);
    }
    if (_instance.keyName) {
      _writer.writeString(3, _instance.keyName);
    }
    if (_instance.expiryTime !== undefined && _instance.expiryTime !== null) {
      _writer.writeString(4, _instance.expiryTime);
    }
    if (
      _instance.beforeExpiryTime !== undefined &&
      _instance.beforeExpiryTime !== null
    ) {
      _writer.writeString(5, _instance.beforeExpiryTime);
    }
    if (_instance.hsm) {
      _writer.writeMessage(
        6,
        _instance.hsm as any,
        HsmKeyConfig.serializeBinaryToWriter
      );
    }
    if (_instance.api) {
      _writer.writeMessage(
        7,
        _instance.api as any,
        ApiKeyConfig.serializeBinaryToWriter
      );
    }
    if (_instance.archived) {
      _writer.writeBool(8, _instance.archived);
    }
  }

  private _id: string;
  private _etag?: string;
  private _lastUpdate?: googleProtobuf001.Timestamp;
  private _customerId: string;
  private _keyName: string;
  private _expiryTime?: string;
  private _beforeExpiryTime?: string;
  private _hsm?: HsmKeyConfig;
  private _api?: ApiKeyConfig;
  private _archived: boolean;

  private _config: KeyConfig.ConfigCase = KeyConfig.ConfigCase.none;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of KeyConfig to deeply clone from
   */
  constructor(_value?: RecursivePartial<KeyConfig.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    this.etag = _value.etag;
    this.lastUpdate = _value.lastUpdate
      ? new googleProtobuf001.Timestamp(_value.lastUpdate)
      : undefined;
    this.customerId = _value.customerId;
    this.keyName = _value.keyName;
    this.expiryTime = _value.expiryTime;
    this.beforeExpiryTime = _value.beforeExpiryTime;
    this.hsm = _value.hsm ? new HsmKeyConfig(_value.hsm) : undefined;
    this.api = _value.api ? new ApiKeyConfig(_value.api) : undefined;
    this.archived = _value.archived;
    KeyConfig.refineValues(this);
  }
  get id(): string {
    return this._id;
  }
  set id(value: string) {
    this._id = value;
  }
  get etag(): string | undefined {
    return this._etag;
  }
  set etag(value?: string) {
    this._etag = value;
  }
  get lastUpdate(): googleProtobuf001.Timestamp | undefined {
    return this._lastUpdate;
  }
  set lastUpdate(value: googleProtobuf001.Timestamp | undefined) {
    this._lastUpdate = value;
  }
  get customerId(): string {
    return this._customerId;
  }
  set customerId(value: string) {
    this._customerId = value;
  }
  get keyName(): string {
    return this._keyName;
  }
  set keyName(value: string) {
    this._keyName = value;
  }
  get expiryTime(): string | undefined {
    return this._expiryTime;
  }
  set expiryTime(value?: string) {
    this._expiryTime = value;
  }
  get beforeExpiryTime(): string | undefined {
    return this._beforeExpiryTime;
  }
  set beforeExpiryTime(value?: string) {
    this._beforeExpiryTime = value;
  }
  get hsm(): HsmKeyConfig | undefined {
    return this._hsm;
  }
  set hsm(value: HsmKeyConfig | undefined) {
    if (value !== undefined && value !== null) {
      this._api = undefined;
      this._config = KeyConfig.ConfigCase.hsm;
    }
    this._hsm = value;
  }
  get api(): ApiKeyConfig | undefined {
    return this._api;
  }
  set api(value: ApiKeyConfig | undefined) {
    if (value !== undefined && value !== null) {
      this._hsm = undefined;
      this._config = KeyConfig.ConfigCase.api;
    }
    this._api = value;
  }
  get archived(): boolean {
    return this._archived;
  }
  set archived(value: boolean) {
    this._archived = value;
  }
  get config() {
    return this._config;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    KeyConfig.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): KeyConfig.AsObject {
    return {
      id: this.id,
      etag: this.etag,
      lastUpdate: this.lastUpdate ? this.lastUpdate.toObject() : undefined,
      customerId: this.customerId,
      keyName: this.keyName,
      expiryTime: this.expiryTime,
      beforeExpiryTime: this.beforeExpiryTime,
      hsm: this.hsm ? this.hsm.toObject() : undefined,
      api: this.api ? this.api.toObject() : undefined,
      archived: this.archived,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): KeyConfig.AsProtobufJSON {
    return {
      id: this.id,
      etag: this.etag === null || this.etag === undefined ? null : this.etag,
      lastUpdate: this.lastUpdate
        ? this.lastUpdate.toProtobufJSON(options)
        : null,
      customerId: this.customerId,
      keyName: this.keyName,
      expiryTime:
        this.expiryTime === null || this.expiryTime === undefined
          ? null
          : this.expiryTime,
      beforeExpiryTime:
        this.beforeExpiryTime === null || this.beforeExpiryTime === undefined
          ? null
          : this.beforeExpiryTime,
      hsm: this.hsm ? this.hsm.toProtobufJSON(options) : null,
      api: this.api ? this.api.toProtobufJSON(options) : null,
      archived: this.archived,
    };
  }
}
export module KeyConfig {
  /**
   * Standard JavaScript object representation for KeyConfig
   */
  export interface AsObject {
    id: string;
    etag?: string;
    lastUpdate?: googleProtobuf001.Timestamp.AsObject;
    customerId: string;
    keyName: string;
    expiryTime?: string;
    beforeExpiryTime?: string;
    hsm?: HsmKeyConfig.AsObject;
    api?: ApiKeyConfig.AsObject;
    archived: boolean;
  }

  /**
   * Protobuf JSON representation for KeyConfig
   */
  export interface AsProtobufJSON {
    id: string;
    etag: string | null;
    lastUpdate: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    customerId: string;
    keyName: string;
    expiryTime: string | null;
    beforeExpiryTime: string | null;
    hsm: HsmKeyConfig.AsProtobufJSON | null;
    api: ApiKeyConfig.AsProtobufJSON | null;
    archived: boolean;
  }
  export enum ConfigCase {
    none = 0,
    hsm = 1,
    api = 2,
  }
}

/**
 * Message implementation for storage.HsmKeyConfig
 */
export class HsmKeyConfig implements GrpcMessage {
  static id = 'storage.HsmKeyConfig';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new HsmKeyConfig();
    HsmKeyConfig.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: HsmKeyConfig) {
    _instance.hsmUrl = _instance.hsmUrl || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: HsmKeyConfig,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.hsmUrl = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    HsmKeyConfig.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: HsmKeyConfig,
    _writer: BinaryWriter
  ) {
    if (_instance.hsmUrl) {
      _writer.writeString(1, _instance.hsmUrl);
    }
  }

  private _hsmUrl: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of HsmKeyConfig to deeply clone from
   */
  constructor(_value?: RecursivePartial<HsmKeyConfig.AsObject>) {
    _value = _value || {};
    this.hsmUrl = _value.hsmUrl;
    HsmKeyConfig.refineValues(this);
  }
  get hsmUrl(): string {
    return this._hsmUrl;
  }
  set hsmUrl(value: string) {
    this._hsmUrl = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    HsmKeyConfig.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): HsmKeyConfig.AsObject {
    return {
      hsmUrl: this.hsmUrl,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): HsmKeyConfig.AsProtobufJSON {
    return {
      hsmUrl: this.hsmUrl,
    };
  }
}
export module HsmKeyConfig {
  /**
   * Standard JavaScript object representation for HsmKeyConfig
   */
  export interface AsObject {
    hsmUrl: string;
  }

  /**
   * Protobuf JSON representation for HsmKeyConfig
   */
  export interface AsProtobufJSON {
    hsmUrl: string;
  }
}

/**
 * Message implementation for storage.ApiKeyConfig
 */
export class ApiKeyConfig implements GrpcMessage {
  static id = 'storage.ApiKeyConfig';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ApiKeyConfig();
    ApiKeyConfig.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ApiKeyConfig) {
    _instance.apiUrl = _instance.apiUrl || '';
    _instance.apiType = _instance.apiType || 0;
    _instance.apiKeyAuth = _instance.apiKeyAuth || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ApiKeyConfig,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.apiUrl = _reader.readString();
          break;
        case 2:
          _instance.apiType = _reader.readEnum();
          break;
        case 3:
          _instance.apiKeyAuth = new ApiKeyAuth();
          _reader.readMessage(
            _instance.apiKeyAuth,
            ApiKeyAuth.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    ApiKeyConfig.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ApiKeyConfig,
    _writer: BinaryWriter
  ) {
    if (_instance.apiUrl) {
      _writer.writeString(1, _instance.apiUrl);
    }
    if (_instance.apiType) {
      _writer.writeEnum(2, _instance.apiType);
    }
    if (_instance.apiKeyAuth) {
      _writer.writeMessage(
        3,
        _instance.apiKeyAuth as any,
        ApiKeyAuth.serializeBinaryToWriter
      );
    }
  }

  private _apiUrl: string;
  private _apiType: ApiKeyConfig.KeyApiType;
  private _apiKeyAuth?: ApiKeyAuth;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ApiKeyConfig to deeply clone from
   */
  constructor(_value?: RecursivePartial<ApiKeyConfig.AsObject>) {
    _value = _value || {};
    this.apiUrl = _value.apiUrl;
    this.apiType = _value.apiType;
    this.apiKeyAuth = _value.apiKeyAuth
      ? new ApiKeyAuth(_value.apiKeyAuth)
      : undefined;
    ApiKeyConfig.refineValues(this);
  }
  get apiUrl(): string {
    return this._apiUrl;
  }
  set apiUrl(value: string) {
    this._apiUrl = value;
  }
  get apiType(): ApiKeyConfig.KeyApiType {
    return this._apiType;
  }
  set apiType(value: ApiKeyConfig.KeyApiType) {
    this._apiType = value;
  }
  get apiKeyAuth(): ApiKeyAuth | undefined {
    return this._apiKeyAuth;
  }
  set apiKeyAuth(value: ApiKeyAuth | undefined) {
    this._apiKeyAuth = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ApiKeyConfig.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ApiKeyConfig.AsObject {
    return {
      apiUrl: this.apiUrl,
      apiType: this.apiType,
      apiKeyAuth: this.apiKeyAuth ? this.apiKeyAuth.toObject() : undefined,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ApiKeyConfig.AsProtobufJSON {
    return {
      apiUrl: this.apiUrl,
      apiType:
        ApiKeyConfig.KeyApiType[
          this.apiType === null || this.apiType === undefined ? 0 : this.apiType
        ],
      apiKeyAuth: this.apiKeyAuth
        ? this.apiKeyAuth.toProtobufJSON(options)
        : null,
    };
  }
}
export module ApiKeyConfig {
  /**
   * Standard JavaScript object representation for ApiKeyConfig
   */
  export interface AsObject {
    apiUrl: string;
    apiType: ApiKeyConfig.KeyApiType;
    apiKeyAuth?: ApiKeyAuth.AsObject;
  }

  /**
   * Protobuf JSON representation for ApiKeyConfig
   */
  export interface AsProtobufJSON {
    apiUrl: string;
    apiType: string;
    apiKeyAuth: ApiKeyAuth.AsProtobufJSON | null;
  }
  export enum KeyApiType {
    KEY_API_TYPE_UNSPECIFIED = 0,
    KEY_API_TYPE_UNWRAP_GRPC = 1,
  }
}

/**
 * Message implementation for storage.ApiKeyAuth
 */
export class ApiKeyAuth implements GrpcMessage {
  static id = 'storage.ApiKeyAuth';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ApiKeyAuth();
    ApiKeyAuth.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ApiKeyAuth) {}

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ApiKeyAuth,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.apiKey = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    ApiKeyAuth.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(_instance: ApiKeyAuth, _writer: BinaryWriter) {
    if (_instance.apiKey || _instance.apiKey === '') {
      _writer.writeString(1, _instance.apiKey);
    }
  }

  private _apiKey: string;

  private _auth: ApiKeyAuth.AuthCase = ApiKeyAuth.AuthCase.none;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ApiKeyAuth to deeply clone from
   */
  constructor(_value?: RecursivePartial<ApiKeyAuth.AsObject>) {
    _value = _value || {};
    this.apiKey = _value.apiKey;
    ApiKeyAuth.refineValues(this);
  }
  get apiKey(): string {
    return this._apiKey;
  }
  set apiKey(value: string) {
    if (value !== undefined && value !== null) {
      this._auth = ApiKeyAuth.AuthCase.apiKey;
    }
    this._apiKey = value;
  }
  get auth() {
    return this._auth;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ApiKeyAuth.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ApiKeyAuth.AsObject {
    return {
      apiKey: this.apiKey,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ApiKeyAuth.AsProtobufJSON {
    return {
      apiKey:
        this.apiKey === null || this.apiKey === undefined ? null : this.apiKey,
    };
  }
}
export module ApiKeyAuth {
  /**
   * Standard JavaScript object representation for ApiKeyAuth
   */
  export interface AsObject {
    apiKey: string;
  }

  /**
   * Protobuf JSON representation for ApiKeyAuth
   */
  export interface AsProtobufJSON {
    apiKey: string | null;
  }
  export enum AuthCase {
    none = 0,
    apiKey = 1,
  }
}
