<div class="modal">
  <div mat-dialog-title>
    <h2 class="modal-title">{{ title }}</h2>
    <button
      mat-icon-button
      aria-label="close dialog"
      mat-dialog-close
      class="modal-close-btn"
      (click)="close(false)"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <form ngNativeValidate [formGroup]="mfaForm" id="mfaForm">
    <div mat-dialog-content>
      <p>
        Thank you for choosing to enable two-factor authentication (2FA) for
        added security. To complete your login process, please press send to
        receive a validation code.
      </p>
      <div class="modal-body">
        <div class="input-container">
          <label class="pass-label" for="phone">Phone Number</label>
          <div
            class="sign-in-row"
            *ngFor="let multiFactorInfo of phoneMultiFactorInfos"
          >
            <p class="tel-input">
              {{ multiFactorInfo.phoneNumber }}
            </p>
            <button
              mat-flat-button
              class="sign-in-button"
              color="primary"
              (click)="sendLoginCode(multiFactorInfo)"
            >
              {{ isLoading ? null : 'Send' }}
              <span *ngIf="isLoading" class="spinner">
                <mat-spinner diameter="24"></mat-spinner>
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-body" *ngIf="smsSent">
      <div mat-dialog-content>
        <p>
          Please enter the verification code sent to your mobile number ending
          in {{ phoneNumberEndingIn }}
        </p>
        <div class="input-container">
          <div class="sign-in-row">
            <input
              class="code-input"
              type="text"
              name="codeVerification"
              formControlName="codeVerification"
              required
              size="6"
              [maxLength]="6"
              [minLength]="6"
              data-testid="codeVerification"
            />
            <button
              class="sign-in-button"
              mat-flat-button
              color="primary"
              (click)="verifyLoginCode()"
              form="mfaForm"
            >
              {{ isLoading ? null : 'Verify' }}
              <span *ngIf="isLoading" class="spinner">
                <mat-spinner diameter="24"></mat-spinner>
              </span>
            </button>
          </div>
        </div>
      </div>
      <app-message-box></app-message-box>
    </div>
  </form>
  <div id="recaptcha-container"></div>
</div>
