/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import {
  GrpcMessage,
  RecursivePartial,
  ToProtobufJSONOptions,
} from '@ngx-grpc/common';
import { BinaryReader, BinaryWriter, ByteSource } from 'google-protobuf';
import * as googleProtobuf000 from '@ngx-grpc/well-known-types';
/**
 * Message implementation for storage.CustomerDataSetReference
 */
export class CustomerDataSetReference implements GrpcMessage {
  static id = 'storage.CustomerDataSetReference';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new CustomerDataSetReference();
    CustomerDataSetReference.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: CustomerDataSetReference) {
    _instance.id = _instance.id || '';
    _instance.version = _instance.version || '';
    _instance.versions = _instance.versions || [];
    _instance.desiredPartitions = _instance.desiredPartitions || 0;
    _instance.startTime = _instance.startTime || undefined;
    _instance.endTime = _instance.endTime || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: CustomerDataSetReference,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readString();
          break;
        case 2:
          _instance.version = _reader.readString();
          break;
        case 4:
          (_instance.versions = _instance.versions || []).push(
            _reader.readString()
          );
          break;
        case 3:
          _instance.desiredPartitions = _reader.readInt32();
          break;
        case 5:
          _instance.startTime = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.startTime,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 6:
          _instance.endTime = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.endTime,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    CustomerDataSetReference.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: CustomerDataSetReference,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeString(1, _instance.id);
    }
    if (_instance.version) {
      _writer.writeString(2, _instance.version);
    }
    if (_instance.versions && _instance.versions.length) {
      _writer.writeRepeatedString(4, _instance.versions);
    }
    if (_instance.desiredPartitions) {
      _writer.writeInt32(3, _instance.desiredPartitions);
    }
    if (_instance.startTime) {
      _writer.writeMessage(
        5,
        _instance.startTime as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.endTime) {
      _writer.writeMessage(
        6,
        _instance.endTime as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
  }

  private _id: string;
  private _version: string;
  private _versions: string[];
  private _desiredPartitions: number;
  private _startTime?: googleProtobuf000.Timestamp;
  private _endTime?: googleProtobuf000.Timestamp;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of CustomerDataSetReference to deeply clone from
   */
  constructor(_value?: RecursivePartial<CustomerDataSetReference.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    this.version = _value.version;
    this.versions = (_value.versions || []).slice();
    this.desiredPartitions = _value.desiredPartitions;
    this.startTime = _value.startTime
      ? new googleProtobuf000.Timestamp(_value.startTime)
      : undefined;
    this.endTime = _value.endTime
      ? new googleProtobuf000.Timestamp(_value.endTime)
      : undefined;
    CustomerDataSetReference.refineValues(this);
  }
  get id(): string {
    return this._id;
  }
  set id(value: string) {
    this._id = value;
  }
  get version(): string {
    return this._version;
  }
  set version(value: string) {
    this._version = value;
  }
  get versions(): string[] {
    return this._versions;
  }
  set versions(value: string[]) {
    this._versions = value;
  }
  get desiredPartitions(): number {
    return this._desiredPartitions;
  }
  set desiredPartitions(value: number) {
    this._desiredPartitions = value;
  }
  get startTime(): googleProtobuf000.Timestamp | undefined {
    return this._startTime;
  }
  set startTime(value: googleProtobuf000.Timestamp | undefined) {
    this._startTime = value;
  }
  get endTime(): googleProtobuf000.Timestamp | undefined {
    return this._endTime;
  }
  set endTime(value: googleProtobuf000.Timestamp | undefined) {
    this._endTime = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    CustomerDataSetReference.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): CustomerDataSetReference.AsObject {
    return {
      id: this.id,
      version: this.version,
      versions: (this.versions || []).slice(),
      desiredPartitions: this.desiredPartitions,
      startTime: this.startTime ? this.startTime.toObject() : undefined,
      endTime: this.endTime ? this.endTime.toObject() : undefined,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): CustomerDataSetReference.AsProtobufJSON {
    return {
      id: this.id,
      version: this.version,
      versions: (this.versions || []).slice(),
      desiredPartitions: this.desiredPartitions,
      startTime: this.startTime ? this.startTime.toProtobufJSON(options) : null,
      endTime: this.endTime ? this.endTime.toProtobufJSON(options) : null,
    };
  }
}
export module CustomerDataSetReference {
  /**
   * Standard JavaScript object representation for CustomerDataSetReference
   */
  export interface AsObject {
    id: string;
    version: string;
    versions: string[];
    desiredPartitions: number;
    startTime?: googleProtobuf000.Timestamp.AsObject;
    endTime?: googleProtobuf000.Timestamp.AsObject;
  }

  /**
   * Protobuf JSON representation for CustomerDataSetReference
   */
  export interface AsProtobufJSON {
    id: string;
    version: string;
    versions: string[];
    desiredPartitions: number;
    startTime: googleProtobuf000.Timestamp.AsProtobufJSON | null;
    endTime: googleProtobuf000.Timestamp.AsProtobufJSON | null;
  }
}
