/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
export enum AdvertiserEventType {
  ADVERTISER_EVENT_TYPE_UNSPECIFIED = 0,
  ADVERTISER_EVENT_TYPE_CUSTOM1 = 1,
  ADVERTISER_EVENT_TYPE_CUSTOM2 = 2,
  ADVERTISER_EVENT_TYPE_CUSTOM3 = 3,
  ADVERTISER_EVENT_TYPE_CUSTOM4 = 4,
  ADVERTISER_EVENT_TYPE_CUSTOM5 = 5,
  ADVERTISER_EVENT_TYPE_PAGE_VIEW = 6,
  ADVERTISER_EVENT_TYPE_ADD_CART = 7,
  ADVERTISER_EVENT_TYPE_PURCHASE = 8,
  ADVERTISER_EVENT_TYPE_APP_INSTALL = 9,
  ADVERTISER_EVENT_TYPE_APP_OPEN = 10,
  ADVERTISER_EVENT_TYPE_SIGN_UP = 11,
  ADVERTISER_EVENT_TYPE_ADD_BILLING = 12,
  ADVERTISER_EVENT_TYPE_START_CHECKOUT = 13,
  ADVERTISER_EVENT_TYPE_LEVEL_COMPLETE = 14,
  ADVERTISER_EVENT_TYPE_SPENT_CREDITS = 15,
  ADVERTISER_EVENT_TYPE_START_TRIAL = 16,
  ADVERTISER_EVENT_TYPE_SEARCH = 17,
  ADVERTISER_EVENT_TYPE_SUBSCRIBE = 18,
  ADVERTISER_EVENT_TYPE_SHARE = 19,
  ADVERTISER_EVENT_TYPE_LOGIN = 20,
}
