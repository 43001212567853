<p>
  <mat-toolbar>
    <span>Create Storage Credential</span>
  </mat-toolbar>
</p>

<div class="wrapper">
  <div>
    <form [formGroup]="storageCredentialForm">
      <h2>Storage Credential</h2>
      <mat-form-field class="input-full-width">
        <mat-label>Storage type</mat-label>
        <mat-select formControlName="storage_type">
          <mat-option
            *ngFor="let storageType of storageTypeToName | keyvalue"
            [value]="storageType.key"
          >
            {{ storageType.value }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="checkError('storage_type', 'required')">
          Storage type is required</mat-error
        >
      </mat-form-field>
      <div *ngIf="isStorageType(StorageType.AWS_S3)">
        <mat-form-field class="input-full-width">
          <mat-label>Access Key ID</mat-label>
          <input matInput formControlName="access_key_id" />
        </mat-form-field>
        <mat-form-field class="input-full-width">
          <mat-label>Secret Access Key</mat-label>
          <input matInput formControlName="secret_access_key" />
        </mat-form-field>
      </div>
      <button (click)="handleSubmit()" mat-button color="primary">
        Create Storage Credential
      </button>
    </form>
  </div>
</div>
