<div class="modal">
  <div class="header">
    <h1 class="mat-custom-dialog-title">
      {{ data.title }}
    </h1>
    <button
      mat-icon-button
      aria-label="close dialog"
      mat-dialog-close
      class="modal-close-btn"
      (click)="close()"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div mat-dialog-content class="mat-custom-dialog-content">
    <p>
      {{ data.message }}
    </p>
  </div>

  <div mat-dialog-actions>
    <button
      mat-flat-button
      color="primary"
      type="submit"
      class="pass-input"
      (click)="save()"
    >
      {{ data.buttonText }}
    </button>
  </div>
</div>
