import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Any } from '@ngx-grpc/well-known-types';
import { GRPC_MESSAGE_POOL, LocationList } from 'app/constants/lookups';
import { ApprovalService } from 'app/services/approval.service';
import { BinaryTypeService } from 'app/services/binary-type.service';
import {
  GetRequest,
  UpdateStateRequest,
} from 'app/services/generated/src/main/proto/api/job-log-service.pb';
import { AdvertiserEventType } from 'app/services/generated/src/main/proto/attribution/advertiser.pb';
import { BinaryType } from 'app/services/generated/src/main/proto/storage/binary-type.pb';
import { Location } from 'app/services/generated/src/main/proto/storage/commons.pb';
import {
  BinaryTypeConfig,
  DaskConfig,
  JobBinary,
  JobLog,
  JobLogCustomerRole,
  JobMetadata,
  JobState,
} from 'app/services/generated/src/main/proto/storage/job-log.pb';
import {
  AttributionComponent,
  AttributionInfo,
} from 'app/views/binary-type/attribution/attribution.component';
import {
  LiftComponent,
  LiftInfo,
} from 'app/views/binary-type/lift/lift.component';

import { JobLogService } from '../../services/job-log.service';
import {
  EnclaveEncryptionComponent,
  EnclaveEncryptionInfo,
} from '../binary-type/enclave-encryption/enclave-encryption.component';
import {
  RedditLiftComponent,
  RedditLiftInfo,
} from '../binary-type/reddit-lift/reddit-lift.component';
import {
  TiktokTargetingComponent,
  TiktokTargetingInfo,
} from '../binary-type/tiktok-targeting/tiktok-targeting.component';
import {
  ValidationComponent,
  ValidationInfo,
} from '../binary-type/validation/validation.component';

interface BinaryVersion {
  name: string;
  approvalRequestId: string;
}

@Component({
  selector: 'app-job-log-creator',
  templateUrl: './job-log-creator.component.html',
  styleUrls: ['./job-log-creator.component.scss'],
})
export class JobLogCreatorComponent implements AfterViewInit {
  @ViewChild('jsonPreview') jsonPreview!: ElementRef;
  @ViewChild(LiftComponent) liftComponent!: LiftComponent;
  @ViewChild(RedditLiftComponent) redditLiftComponent!: RedditLiftComponent;
  @ViewChild(TiktokTargetingComponent)
  tiktokTargetingComponent!: TiktokTargetingComponent;
  @ViewChild(AttributionComponent) attributionComponent!: AttributionComponent;
  @ViewChild(EnclaveEncryptionComponent)
  enclaveEncryptionComponent!: EnclaveEncryptionComponent;
  @ViewChild(ValidationComponent) validationComponent!: ValidationComponent;
  jobLog: JobLog = new JobLog({
    state: JobState.JOB_STATE_DRAFT,
  });
  inputJobLog: JobLog = new JobLog();
  binaryTypeToName = new Map<BinaryType, string>();
  binaryVersions: BinaryVersion[] = [];
  locations = LocationList;
  customerIds: Set<string> = new Set();

  // Make BinaryType available in the template.
  readonly BinaryType = BinaryType;

  jobLogForm: FormGroup = this.formBuilder.group({
    binaryType: '',
    binaryApprovalRequestId: '',
    location: '',
    description: '',
    numWorkers: '',
  });

  constructor(
    private jobLogService: JobLogService,
    private approvalService: ApprovalService,
    private binaryTypeService: BinaryTypeService,
    private snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  updateCustomers(customerIds: string[]) {
    this.customerIds = new Set(customerIds);
    this.customerIds.delete('');
    this.updateApprovedBinariesSelect();
    this.updateCustomerRoles();
  }

  receiveLiftInfo(liftInfo: LiftInfo) {
    this.updateCustomers(liftInfo.customerIds);
    this.jobLog.binaryConfig = new Any();
    this.jobLog.binaryConfig.pack(liftInfo.liftConfig);
    this.jobLog.binaryTypeConfig = new BinaryTypeConfig({
      liftConfig: liftInfo.liftConfig,
    });
    this.updateJsonPreview();
  }

  receiveTiktokTargetingInfo(tiktokTargetingInfo: TiktokTargetingInfo) {
    this.updateCustomers(tiktokTargetingInfo.customerIds);
    this.jobLog.binaryConfig = new Any();
    this.jobLog.binaryConfig.pack(tiktokTargetingInfo.tiktokTargetingConfig);
    this.jobLog.binaryTypeConfig = new BinaryTypeConfig({
      tiktokTargetingConfig: tiktokTargetingInfo.tiktokTargetingConfig,
    });
    this.updateJsonPreview();
  }

  receiveRedditLiftInfo(redditLiftInfo: RedditLiftInfo) {
    this.updateCustomers(redditLiftInfo.customerIds);
    this.jobLog.binaryConfig = new Any();
    this.jobLog.binaryConfig.pack(redditLiftInfo.redditLiftConfig);
    this.jobLog.binaryTypeConfig = new BinaryTypeConfig({
      redditLiftConfig: redditLiftInfo.redditLiftConfig,
    });
    this.updateJsonPreview();
  }

  receiveAttributionInfo(attributionInfo: AttributionInfo) {
    this.updateCustomers(attributionInfo.customerIds);
    this.jobLog.binaryConfig = new Any();
    this.jobLog.binaryConfig.pack(attributionInfo.attributionConfig);
    this.jobLog.binaryTypeConfig = new BinaryTypeConfig({
      attribution: attributionInfo.attributionConfig,
    });
    this.updateJsonPreview();
  }

  receiveValidationInfo(validationInfo: ValidationInfo) {
    this.updateCustomers(validationInfo.customerIds);
    this.jobLog.binaryConfig = new Any();
    this.jobLog.binaryConfig.pack(validationInfo.validationConfig);
    this.jobLog.binaryTypeConfig = new BinaryTypeConfig({
      validationConfig: validationInfo.validationConfig,
    });
    this.updateJsonPreview();
  }

  receiveEnclaveEncryptionInfo(enclaveEncryptionInfo: EnclaveEncryptionInfo) {
    this.updateCustomers(enclaveEncryptionInfo.customerIds);
    this.jobLog.binaryConfig = new Any();
    this.jobLog.binaryConfig.pack(
      enclaveEncryptionInfo.enclaveEncryptionConfig
    );
    this.jobLog.binaryTypeConfig = new BinaryTypeConfig({
      enclaveEncryptionConfig: enclaveEncryptionInfo.enclaveEncryptionConfig,
    });
    this.updateJsonPreview();
  }

  metricName(index: number) {
    const value = AdvertiserEventType[index];
    return value.substring('ADVERTISER_EVENT_TYPE_'.length);
  }

  updateCustomerRoles() {
    const customerRoles: JobLogCustomerRole[] = [];
    for (const customerId of this.customerIds) {
      customerRoles.push(
        new JobLogCustomerRole({
          role: JobLogCustomerRole.JobLogRole.JOB_LOG_ROLE_READER,
          customerId: customerId,
        })
      );
      customerRoles.push(
        new JobLogCustomerRole({
          role: JobLogCustomerRole.JobLogRole.JOB_LOG_ROLE_APPROVER,
          customerId: customerId,
        })
      );
    }
    this.jobLog.customerRoles = customerRoles;
  }

  updateJobLog() {
    const form = this.jobLogForm.value;
    this.jobLog.daskConfig = new DaskConfig({
      numWorkers: parseInt(form.numWorkers!),
    });
    this.jobLog.location = parseInt(
      form.location ?? Location.LOCATION_UNSPECIFIED.toString()
    );
    (this.jobLog.metadata = new JobMetadata({
      description: form.description ?? '',
    })),
      (this.jobLog.binary = new JobBinary({
        approvalRequestId: form.binaryApprovalRequestId ?? '',
      }));
    this.jobLog.binaryType = parseInt(form.binaryType!);
    if (this.inputJobLog.id) {
      this.jobLog.id = this.inputJobLog.id;
      this.jobLog.state = this.inputJobLog.state;
      this.jobLog.archived = this.inputJobLog.archived;
      if (this.inputJobLog.etag) {
        this.jobLog.etag = this.inputJobLog.etag;
      }
    }
  }

  async handleSubmit(startJob: boolean, validations: boolean) {
    if (!validations) {
      return;
    }
    let jobId = this.inputJobLog.id;
    try {
      if (this.inputJobLog.id) {
        this.jobLog.id = this.inputJobLog.id;
        await this.jobLogService.update(this.jobLog, this.inputJobLog.etag!);
      } else {
        const response = await this.jobLogService.create(this.jobLog);
        jobId = response.id;
      }
      if (startJob) {
        this.jobLogService.updateState(
          new UpdateStateRequest({
            jobLogId: jobId,
            state: JobState.JOB_STATE_PENDING,
          })
        );
      }
      this.router.navigate(['/jobs']);
    } catch (error: any) {
      this.snackBar.open(error.statusMessage, 'Dismiss', {
        verticalPosition: 'top',
      });
    }
  }

  isBinaryType(binaryType: BinaryType) {
    if (!this.jobLogForm.value.binaryType) {
      return false;
    }
    return binaryType == parseInt(this.jobLogForm.value.binaryType);
  }

  async updateApprovedBinariesSelect() {
    if (!this.jobLogForm.value.binaryType) {
      return;
    }
    const binaryType = parseInt(this.jobLogForm.value.binaryType);

    if (this.customerIds.size == 0) {
      return;
    }

    // Compute intersection of approval requests.
    // TODO(kenny): Consider moving this to server side logic.
    const customerIter = this.customerIds.values();
    const firstCustomerId = customerIter.next().value;
    const approvalResponse =
      await this.approvalService.getApprovedBinaries(firstCustomerId);
    let approvals = approvalResponse.approvedBinaryChange!;
    const remainingCustomerIds = new Set(this.customerIds);
    remainingCustomerIds.delete(firstCustomerId);
    for (const customerId of customerIter) {
      const approvalResponse =
        await this.approvalService.getApprovedBinaries(customerId);
      const customerApprovalIds = new Set(
        approvalResponse.approvedBinaryChange!.map((x) => x.approvalId)
      );
      approvals = approvals.filter((x) =>
        customerApprovalIds.has(x.approvalId)
      );
    }

    this.binaryVersions.splice(0);
    approvals.forEach((approval) => {
      const binaryInfo = approval.change
        ? approval.change.binaryInfo
        : undefined;
      if (!binaryInfo || binaryInfo.binaryType != binaryType) {
        return;
      }
      this.binaryVersions.push({
        name: `${binaryInfo.name} ${binaryInfo.version}`,
        approvalRequestId: approval.approvalId,
      });
    });
  }

  loadBinaryTypes() {
    this.binaryTypeService.getBinaryTypes().then((resp) => {
      if (resp.binaryTypeInfos) {
        resp.binaryTypeInfos!.forEach((value) => {
          this.binaryTypeToName.set(value.binaryType, value.name);
        });
      }
    });
  }

  updateJsonPreview() {
    this.jsonPreview.nativeElement.textContent = JSON.stringify(
      this.jobLog.toProtobufJSON({
        messagePool: GRPC_MESSAGE_POOL,
      }),
      undefined,
      2
    );
  }

  async loadJobFromParam() {
    const jobId = this.route.snapshot.paramMap.get('id');
    if (jobId) {
      const response = await this.jobLogService.get(
        new GetRequest({
          jobLogId: jobId,
        })
      );
      const jobLog = response!.jobLogs![0].jobLog!;
      this.inputJobLog = jobLog;
      this.jobLogForm.setValue({
        binaryType: jobLog.binaryType,
        binaryApprovalRequestId: jobLog?.binary?.approvalRequestId,
        location: jobLog.location,
        description: jobLog.metadata?.description,
        numWorkers: jobLog.daskConfig?.numWorkers,
      });
    }
  }

  async ngAfterViewInit() {
    this.loadJobFromParam();
    this.loadBinaryTypes();
    this.jobLogForm.valueChanges.subscribe(() => {
      this.updateJobLog();
      this.updateJsonPreview();
    });
    this.updateJobLog();
  }
}
