import { NestedTreeControl } from '@angular/cdk/tree';
import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { Router } from '@angular/router';
import { DocumentService } from 'app/services/document.service';
import { ContentType } from 'app/services/generated/src/main/proto/api/auth-service.pb';
import { LoggerService } from 'app/services/logger.service';
import {
  PermissionRoles,
  PermissionService,
} from 'app/services/permission.service';
import { environment } from 'environments/environment';
import { firstValueFrom } from 'rxjs';
import { DocumentType } from 'types/document';

import { NODES } from './constants';
import { Node } from './model';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  documentationTreeLeaf = 'Knowledge Base';
  allowedBinaryTypes: ContentType[] | undefined;
  treeControl: NestedTreeControl<Node, Node>;
  dataSource: MatTreeNestedDataSource<Node>;
  @ViewChildren(MatIcon) sideMenuList!: QueryList<MatIcon>;

  constructor(
    private router: Router,
    private permissionService: PermissionService,
    private documentService: DocumentService,
    private logger: LoggerService
  ) {
    this.treeControl = new NestedTreeControl<Node>((node) => node.children);
    this.dataSource = new MatTreeNestedDataSource<Node>();
  }

  async ngOnInit() {
    const nodeList = await this.getBookTitles();
    NODES.forEach((node) => {
      if (node.name === this.documentationTreeLeaf) {
        node.children = nodeList;
      }
    });

    this.filterByPermission(NODES)
      .then((result) => {
        const route = this.router.url.slice(1);
        this.dataSource.data = result;
        this.treeControl.dataNodes = this.dataSource.data;
        this.expand(route, this.treeControl.dataNodes);
      })
      .catch((error) => {
        this.logger.error('Error loading menu items', error);
      });
  }

  expand(route: string, data: Node[], parents: Node[] = []): Node | undefined {
    for (const node of data) {
      if (node.route === route) {
        parents.forEach((parent) => this.treeControl.expand(parent));
        return node;
      }

      if (node.children) {
        const found = this.expand(route, node.children, [...parents, node]);
        if (found) return found;
      }
    }
    return undefined;
  }

  private async filterByPermission(arr: Array<Node>): Promise<Node[]> {
    const result = [];

    for (const item of arr) {
      if (
        (await this.permissionService.hasMatchingRoles(
          item.data.expectedRoles
        )) &&
        (await this.permissionService.hasMatchingContentTypes(
          item.data.expectedContentType
        )) &&
        !(item.only_dev && environment.production)
      ) {
        result.push(item);
        if (item.children) {
          result[result.length - 1].children = await this.filterByPermission(
            item.children
          );
        }
      }
    }
    return result;
  }

  resetIcons(node: Node) {
    this.sideMenuList.forEach((matIcon) => {
      if (node.icon === matIcon.svgIcon && node.custom_icon) {
        matIcon.svgIcon = node.icon + '_selected';
      } else if (
        matIcon.svgIcon &&
        node.custom_icon &&
        matIcon.svgIcon.replace('_selected', '') != node.icon
      ) {
        matIcon.svgIcon = matIcon.svgIcon.replace('_selected', '');
      }
    });
  }

  onParenNodeClick(node: Node) {
    if (!this.treeControl.isExpanded(node)) {
      this.treeControl.expand(node);
    } else {
      this.treeControl.collapse(node);
    }
    this.resetIcons(node);
  }

  async getBookTitles() {
    const bookNodes: Node[] = [];

    try {
      const documents = await firstValueFrom(
        this.documentService.getDocuments().valueChanges()
      );

      const latestDocuments = this.getLatestDocuments(documents);
      const latestGroupedDocuments = this.groupDocuments(latestDocuments);

      latestGroupedDocuments.forEach(async (docs, category) => {
        if (category) {
          const node: Node = {
            name: category,
            route: '',
            data: {
              expectedRoles: [PermissionRoles.PortalUser],
            },
            children: this.getCategoryChildren(docs),
          };
          bookNodes.push(node);
        } else {
          docs.forEach((doc: DocumentType) => {
            const node: Node = {
              name: doc.title,
              route: `documentation-view/${doc.path}`,
              data: {
                expectedRoles: [PermissionRoles.PortalUser],
              },
            };
            bookNodes.push(node);
          });
        }
      });
    } catch (error) {
      this.logger.error('Error loading menu items', error);
    }
    return bookNodes;
  }

  getCategoryChildren(documents: DocumentType[]) {
    const bookNodes: Node[] = [];
    documents.forEach((doc) => {
      const node: Node = {
        name: doc.title,
        route: `documentation-view/${doc.path}`,
        data: {
          expectedRoles: [PermissionRoles.PortalUser],
        },
      };
      bookNodes.push(node);
    });
    return bookNodes;
  }

  getLatestDocuments(documents: DocumentType[]) {
    const latestDocuments: DocumentType[] = [];
    const orderedDocuments = documents
      .filter((row) => row.docType == 'html' && row.publish)
      .sort(function (a, b) {
        return a.id.localeCompare(b.id) || b.release.localeCompare(a.release);
      });

    let currentId: string | undefined = undefined;

    orderedDocuments.forEach((document) => {
      if (currentId !== document.id) {
        currentId = document.id;
        latestDocuments.push(document);
      }
    });

    return latestDocuments;
  }

  groupDocuments(documents: DocumentType[]) {
    const groupedDocuments = new Map();
    const categories = new Set();

    // We order the elements first so that it will order the categories as well
    documents
      .sort((a, b) => a.order - b.order)
      .forEach((document) => {
        categories.add(document.category);
      });

    categories.forEach((category) => {
      const categoryDocument = documents.filter((document) => {
        return document.category === category;
      });
      groupedDocuments.set(category, categoryDocument);
    });

    return groupedDocuments;
  }

  hasChild = (_: number, node: Node) =>
    !!node.children && node.children.length > 0;
}
