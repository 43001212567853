<div class="modal">
  <div mat-dialog-title>
    <button
      mat-icon-button
      aria-label="close dialog"
      mat-dialog-close
      class="modal-close-btn"
      (click)="close()"
    >
      <mat-icon>close</mat-icon>
    </button>

    <h2 class="modal-title title">{{ title }}</h2>
  </div>

  <div mat-dialog-content>
    <div class="content">
      <span class="description"
        >Lets talk about what the user can do in a little onboarding slideshow
        for each user.</span
      >
    </div>
  </div>
</div>
