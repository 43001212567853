import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { SamlProviderConfig } from 'app/services/generated/src/main/proto/storage/customer.pb';
import { environment } from 'environments/environment';

import { LoggerService } from '../../../services/logger.service';
import { MessageBoxProvider } from '../../../views/shared/components/message-box/message-box.provider';

@Component({
  selector: 'app-saml',
  templateUrl: './saml-provider-configuration.component.html',
  styleUrls: [
    './saml-provider-configuration.component.scss',
    '../../../shared/shared.scss',
  ],
})
export class SamlProviderConfigrationComponent implements OnInit {
  @Input() samlProviderConfig: SamlProviderConfig | undefined;
  @Input() tenantId: string | undefined;
  @Output() samlProviderConfigEvent = new EventEmitter<SamlProviderConfig>();

  samlRpEntityIdBaseUrl = `https://${environment.firebase.authDomain}/`;
  callbackUrlDefault = `https://${environment.firebase.authDomain}/__/auth/handler`;

  samlForm = this.formBuilder.group({
    samlSsoUrl: this.formBuilder.control('', {
      validators: [Validators.required, Validators.pattern('^https://.*')],
    }),
    samlDisplayName: this.formBuilder.control('', {
      validators: [Validators.required, Validators.minLength(3)],
    }),
    samlProviderId: this.formBuilder.control({ value: '', disabled: true }),
    samlIdpEntityId: this.formBuilder.control('', {
      validators: [Validators.required, Validators.minLength(3)],
    }),
    samlCallbackUrl: this.formBuilder.control({ value: '', disabled: true }),
    samlRpEntityId: this.formBuilder.control({ value: '', disabled: true }),
    fileUpload: this.formBuilder.control('', {
      validators: [],
    }),
  });

  x509Certificates: string[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private logger: LoggerService,
    private messageBox: MessageBoxProvider
  ) {}

  ngOnInit(): void {
    if (this.samlProviderConfig) {
      this.x509Certificates = this.samlProviderConfig.x509Certificates;
      this.samlForm.setValue({
        samlCallbackUrl: this.samlProviderConfig.callbackUrl,
        samlDisplayName: this.samlProviderConfig.displayName,
        samlIdpEntityId: this.samlProviderConfig.idpEntityId,
        samlProviderId: `saml.${this.tenantId?.toLowerCase()}`,
        samlRpEntityId:
          this.samlProviderConfig.rpEntityId ||
          `${this.samlRpEntityIdBaseUrl}${this.tenantId?.toLowerCase()}`,
        samlSsoUrl: this.samlProviderConfig.ssoUrl,
        fileUpload: null,
      });
    } else {
      this.samlProviderConfig = new SamlProviderConfig();
      this.samlForm.setValue({
        samlCallbackUrl: this.callbackUrlDefault,
        samlProviderId: `saml.${this.tenantId?.toLowerCase()}`,
        samlRpEntityId:
          this.samlProviderConfig.rpEntityId ||
          `${this.samlRpEntityIdBaseUrl}${this.tenantId?.toLowerCase()}`,
        samlSsoUrl: null,
        samlDisplayName: null,
        samlIdpEntityId: null,
        fileUpload: null,
      });
    }
  }

  onFileSelectionChange(event: any) {
    const files: File[] = event.target.files;
    const fileReader = new FileReader();

    fileReader.onload = () => {
      const certificateValue = (fileReader.result as string).trim();
      if (
        certificateValue.startsWith('-----BEGIN CERTIFICATE----') &&
        certificateValue
          .replaceAll('\n', '')
          .endsWith('-----END CERTIFICATE-----')
      ) {
        this.x509Certificates.push(certificateValue);
      } else {
        this.messageBox.error(
          'Invalid certificate format, please verify the certificate is valid.'
        );
      }
    };
    fileReader.onerror = (error) => {
      this.logger.error('Error reading file from local path.', error);
      this.messageBox.error('Error reading file from local path.');
    };

    if (files.length) {
      for (let i = 0; i < files.length; i++) {
        fileReader.readAsText(files[i]);
      }
    }
  }

  set() {
    const { value } = this.samlForm;
    const samlProviderConfig = new SamlProviderConfig();
    samlProviderConfig.callbackUrl = this.callbackUrlDefault;
    samlProviderConfig.displayName = value.samlDisplayName?.trim() || '';
    samlProviderConfig.idpEntityId = value.samlIdpEntityId?.trim() || '';
    samlProviderConfig.rpEntityId = `${
      this.samlRpEntityIdBaseUrl
    }${this.tenantId?.toLowerCase()}`;
    samlProviderConfig.ssoUrl = value.samlSsoUrl || '';
    samlProviderConfig.x509Certificates = this.x509Certificates;
    this.samlProviderConfigEvent.emit(samlProviderConfig);
  }

  onChange() {
    if (this.samlForm.valid && this.x509Certificates.length > 0) {
      this.set();
    } else if (this.x509Certificates.length == 0) {
      this.messageBox.error('At least one certificate is required for SAML');
    }
  }

  public checkError(controlName: string, errorName: string) {
    if (
      controlName == 'samlDisplayName' ||
      controlName == 'samlIdpEntityId' ||
      controlName == 'samlSsoUrl'
    ) {
      const hasError = this.samlForm.controls[controlName].hasError(errorName);
      return (
        hasError &&
        this.samlForm.controls[controlName].invalid &&
        (this.samlForm.controls[controlName].dirty ||
          this.samlForm.controls[controlName].touched)
      );
    } else {
      this.logger.error('Unable to validate on non existent form control.');
      return true;
    }
  }

  deleteX509Certificate(i: number) {
    this.x509Certificates.splice(i, 1);
  }
}
