<mat-card class="saml-card">
  <mat-card-title>Saml Provider Configuration</mat-card-title>
  <mat-card-content>
    <form ngNativeValidate (ngSubmit)="set()" [formGroup]="samlForm">
      <mat-form-field class="input-full-width">
        <mat-label>Name</mat-label>
        <input
          matInput
          type="text"
          formControlName="samlDisplayName"
          minlength="3"
          (input)="onChange()"
        />
        <mat-error *ngIf="checkError('samlDisplayName', 'required')">
          Name is required</mat-error
        >
        <mat-error *ngIf="checkError('samlDisplayName', 'minlength')">
          Display name is too short.</mat-error
        >
      </mat-form-field>
      <mat-form-field class="input-full-width">
        <mat-label>Provider Id (auto-assigned)</mat-label>
        <input matInput type="text" formControlName="samlProviderId" />
      </mat-form-field>
      <mat-form-field class="input-full-width">
        <mat-label>Entity Id</mat-label>
        <input
          matInput
          formControlName="samlIdpEntityId"
          minlength="3"
          (input)="onChange()"
        />
        <mat-error *ngIf="checkError('samlIdpEntityId', 'required')">
          Entity Id is required.</mat-error
        >
        <mat-error *ngIf="checkError('samlIdpEntityId', 'minlength')">
          Entity Id is too short.</mat-error
        >
      </mat-form-field>
      <mat-form-field class="input-full-width">
        <mat-label>SSO URL</mat-label>
        <input
          matInput
          type="text"
          formControlName="samlSsoUrl"
          minlength="6"
          (input)="onChange()"
          placeholder="https://"
        />
        <mat-error *ngIf="checkError('samlSsoUrl', 'pattern')">
          Url must use https.</mat-error
        >
        <mat-error *ngIf="checkError('samlSsoUrl', 'required')">
          SSO Url is required.</mat-error
        >
      </mat-form-field>
      <mat-card-title>Certificates</mat-card-title>
      <div class="file-upload">
        <input
          type="file"
          class="file-input"
          data-testid="fileUploadControl"
          multiple
          name="fileUpload"
          fileControlName="fileUpload"
          hidden
          (change)="onFileSelectionChange($event)"
          #fileUpload
        />
        <div (click)="fileUpload.click()">
          <button class="upload-btn">
            <mat-icon class="upload-icon">cloud_upload</mat-icon>
            <div class="upload-title">Add certificate</div>
          </button>
        </div>
      </div>
      <div *ngFor="let x509Certificate of x509Certificates; let i = index">
        <mat-form-field class="input-full-width">
          <mat-label>x509 Certificate {{ i + 1 }}</mat-label>
          <textarea matInput rows="4" cols="80" readonly>{{
            x509Certificate
          }}</textarea>
          <mat-icon class="delete-icon" (click)="deleteX509Certificate(i)"
            >delete</mat-icon
          >
        </mat-form-field>
      </div>

      <mat-card-title>Service Provider</mat-card-title>
      <mat-form-field class="input-full-width">
        <mat-label>Entity Id (auto-assigned)</mat-label>
        <input matInput type="text" formControlName="samlRpEntityId" />
      </mat-form-field>
      <mat-form-field class="input-full-width">
        <mat-label>ACS URL (auto-assigned)</mat-label>
        <input
          matInput
          type="text"
          formControlName="samlCallbackUrl"
          placeholder="{{ callbackUrlDefault }}"
        />
      </mat-form-field>
    </form>
  </mat-card-content>
  <mat-card-footer> </mat-card-footer>
</mat-card>
