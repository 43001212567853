import { LiveAnnouncer } from '@angular/cdk/a11y';
import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { LoggerService } from 'app/services/logger.service';
import { firstValueFrom } from 'rxjs/internal/firstValueFrom';
import { CustomerAccount } from 'types/customerAccount';

import { DocumentType } from '../../../../src/types/document';
import { DocumentService } from '../../services/document.service';
import { FormatService } from '../../services/format.service';
import { UserService } from '../../services/user.service';
import { MessageBoxProvider } from '../../views/shared/components/message-box/message-box.provider';

@Component({
  selector: 'app-account',
  templateUrl: './documentation-page.component.html',
  styleUrls: ['./documentation-page.component.scss'],
})
export class DocumentationPageComponent implements AfterViewInit {
  public columnsToDisplay: string[] = [
    'Title',
    'Description',
    'Category',
    'Release',
    'Date',
    'Link',
  ];
  public generalDocs: string | undefined;
  public isLoading = false;
  public docs: MatTableDataSource<DocumentType> = new MatTableDataSource();
  customerData: CustomerAccount | undefined;
  @ViewChild(MatSort)
  sort: MatSort = new MatSort();

  public time: string = new Intl.DateTimeFormat('en-US', {
    dateStyle: 'full',
  }).format(Date.now());

  constructor(
    private analytics: AngularFireAnalytics,
    private documentService: DocumentService,
    private formatService: FormatService,
    private liveAnnouncer: LiveAnnouncer,
    private logger: LoggerService,
    private messageBox: MessageBoxProvider,
    public userService: UserService
  ) {
    this.getDocs();
  }

  ngAfterViewInit() {
    this.docs.sort = this.sort;
  }

  private async getDocs() {
    this.isLoading = true;
    try {
      const rows = await firstValueFrom(
        this.documentService.getDocuments().valueChanges()
      );
      this.docs.data = rows.filter((row) => row.docType == 'pdf');
    } catch (error) {
      this.logger.error('Unable to load document', error);
      this.messageBox.error('Unable to load document list.');
    } finally {
      this.isLoading = false;
    }
  }

  formatDate(time: any) {
    return this.formatService.formatProtoDateTime(time);
  }

  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this.liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this.liveAnnouncer.announce('Sorting cleared');
    }
  }

  public async openPdf(doc: DocumentType) {
    try {
      window.open(await this.signPdf(doc), '_blank');
      this.analytics.logEvent('opened-pdf', {
        path: doc.title,
        version: doc.version,
      });
    } catch (error) {
      this.analytics.logEvent('error-loading-pdf', {
        path: doc.title,
        version: doc.version,
      });
      this.logger.error('Unable to open file', error);
      this.messageBox.error('Unable to find file.');
    }
  }

  public async openHtml(doc: DocumentType) {
    try {
      window.open(doc.path, '_blank');
      this.analytics.logEvent('opened-html', {
        name: doc.title,
        version: doc.version,
      });
    } catch (error) {
      this.analytics.logEvent('error-loading-pdf', {
        path: doc.title,
        version: doc.version,
      });
      this.logger.error('Unable to open file', error);
      this.messageBox.error('Unable to find file.');
    }
  }

  public async signPdf(doc: DocumentType) {
    return await this.documentService.getSignedURL(doc.path);
  }
}
