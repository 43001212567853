import { LiveAnnouncer } from '@angular/cdk/a11y';
import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { BinaryTypeService } from 'app/services/binary-type.service';
import { CustomerService } from 'app/services/customer.service';
import { FormatService } from 'app/services/format.service';
import { DisplayJob } from 'app/services/generated/src/main/proto/api/job-log-service.pb';
import {
  BinaryType,
  BinaryTypeInfo,
} from 'app/services/generated/src/main/proto/storage/binary-type.pb';
import { JobState } from 'app/services/generated/src/main/proto/storage/job-log.pb';
import { LoggerService } from 'app/services/logger.service';

import { JOB_STATE_TO_STRING } from '../../constants/lookups';
import { JobLogService } from '../../services/job-log.service';
import { JobGridData } from './grid-data.model';

interface Page {
  continuationToken: string | null;
}

type JobLabel = {
  id: JobState;
  name: string;
};

const RECORDS_PER_PAGE = 15;
const REFRESH_TIME = 5 * 60 * 1000; // 5 minutes

@Component({
  templateUrl: './job-log.component.html',
  styleUrls: ['./job-log.component.scss'],
})
export class JobLogComponent implements AfterViewInit, OnInit, OnDestroy {
  allowedBinaryTypes: BinaryType[] | undefined;
  binaryTypeInfos: BinaryTypeInfo[] = [];
  dataSource: MatTableDataSource<JobGridData> = new MatTableDataSource();
  selectedBinaryType: BinaryType = BinaryType.BINARY_TYPE_UNSPECIFIED;
  selectedJobState: JobState = JobState.JOB_STATE_UNSPECIFIED;
  lastUpdate = this.FormatService.getLastUpdate();
  isLoading = false;
  currentPageIndex = 0;
  totalRecords = 0;
  pageSize = RECORDS_PER_PAGE;
  refreshInterval: number | undefined;
  pages: Page[] = [];
  jobs: DisplayJob[] = [];
  jobStates: JobLabel[] | undefined;
  @ViewChild(MatSort)
  sort: MatSort = new MatSort();

  public columnsToDisplay: string[] = [
    'JobId',
    'BinaryType',
    'BinaryVersion',
    'Description',
    'Status',
    'StartTime',
    'CompleteTime',
    'Error',
  ];

  constructor(
    private binaryTypeService: BinaryTypeService,
    private customerService: CustomerService,
    private jobLogService: JobLogService,
    private liveAnnouncer: LiveAnnouncer,
    private FormatService: FormatService,
    private logger: LoggerService,
    public dialog: MatDialog
  ) {
    this.jobStates = [];

    for (const key in JOB_STATE_TO_STRING) {
      const jobState = parseInt(key) as JobState;
      if (jobState != JobState.JOB_STATE_UNSPECIFIED)
        this.jobStates.push({
          id: jobState,
          name: JOB_STATE_TO_STRING[jobState],
        });
    }

    this.customerService
      .getCustomer()
      .then((response) => {
        return response.customer?.allowedBinaryTypes;
      })
      .then((allowedBinaryTypes) => {
        this.allowedBinaryTypes = allowedBinaryTypes;
        this.binaryTypeService.getBinaryTypes(true).then((response) => {
          response.binaryTypeInfos?.forEach((binaryType) => {
            if (allowedBinaryTypes?.includes(binaryType.binaryType))
              this.binaryTypeInfos.push(binaryType);
          });
        });
      })
      .then(() => this.getDefaultChanges(true));
  }

  ngOnInit(): void {
    this.refreshInterval = window.setInterval(() => {
      if (this.isLoading) return;
      this.getDefaultChanges(false);
    }, REFRESH_TIME);
  }

  ngOnDestroy(): void {
    this.refreshInterval && window.clearInterval(this.refreshInterval);
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  getBinaryTypeName(BinaryType: BinaryType) {
    let result = '-';
    this.binaryTypeInfos.forEach((binaryTypeInfo) => {
      if (binaryTypeInfo.binaryType === BinaryType) {
        result = binaryTypeInfo.name;
      }
    });
    return result;
  }

  getDefaultChanges(ignorePages: boolean) {
    const pages = ignorePages
      ? null
      : this.pages[this.currentPageIndex].continuationToken;

    this.getChanges(
      this.currentPageIndex,
      pages,
      this.selectedBinaryType,
      this.selectedJobState
    );
  }

  getChanges(
    pageIndex: number,
    token: string | null = null,
    binaryType: BinaryType,
    jobState: JobState
  ) {
    this.isLoading = true;

    return this.jobLogService
      .getJobLog(this.pageSize, binaryType, jobState, token)
      .then((data) => {
        this.jobs = data.jobs ?? [];
        if (!token) {
          // When we fetch the first page, (no continuation token), we receive the
          // total records count.
          this.totalRecords = data.paginatedResponse?.count ?? this.jobs.length;
        }
        const gridData = data.jobs?.map((log) => {
          let startTime: string | undefined;
          let completeTime: string | undefined;

          if (log.startTime) {
            startTime = this.FormatService.formatProtoDateTime(log.startTime);
          }

          if (log.completeTime) {
            completeTime = this.FormatService.formatProtoDateTime(
              log.completeTime
            );
          }

          const job: JobGridData = {
            JobId: log.id,
            BinaryVersion: log.binaryVersion,
            BinaryType: this.getBinaryTypeName(log.binaryType),
            Status:
              JOB_STATE_TO_STRING[log.state ?? JobState.JOB_STATE_UNSPECIFIED],
            Description: log.description,
            ErrorCode: log.error?.code,
            ErrorReason: log.error?.reason,
            StartTime: startTime,
            CompleteTime: completeTime,
            Error: log.error?.reason,
          };

          return job;
        });

        this.dataSource.data = gridData ?? [];
        this.pages[pageIndex] = {
          continuationToken: token,
        };

        if (data.paginatedResponse?.continuationToken) {
          this.pages[pageIndex + 1] = {
            continuationToken: data.paginatedResponse.continuationToken,
          };
        }
        this.lastUpdate = this.FormatService.getLastUpdate();
      })
      .catch((error) => {
        this.logger.error('Error loading job log', error);
      })
      .finally(() => (this.isLoading = false));
  }

  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this.liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this.liveAnnouncer.announce('Sorting cleared');
    }
  }

  async onPageChange(
    event: PageEvent,
    binaryType: BinaryType,
    jobState: JobState
  ) {
    const page = this.pages[event.pageIndex];
    await this.getChanges(
      event.pageIndex,
      page.continuationToken,
      binaryType,
      jobState
    );
    this.currentPageIndex = event.pageIndex;
  }

  onFilterByBinaryType(binaryType: number) {
    this.selectedBinaryType = binaryType;
    this.liveAnnouncer.announce('Filtering by Binary Type');
    this.getDefaultChanges(true);
  }

  onFilterByJobState(jobState: number) {
    this.selectedJobState = jobState;
    this.liveAnnouncer.announce('Filtering by Job State');
    this.getDefaultChanges(true);
  }
}
