/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import {
  GrpcMessage,
  RecursivePartial,
  ToProtobufJSONOptions,
} from '@ngx-grpc/common';
import { BinaryReader, BinaryWriter, ByteSource } from 'google-protobuf';
import * as googleProtobuf000 from '@ngx-grpc/well-known-types';
import * as storage001 from '../../../../src/main/proto/storage/schema-annotations.pb';
import * as api002 from '../../../../src/main/proto/api/headers.pb';
import * as api003 from '../../../../src/main/proto/api/pagination.pb';
import * as storage004 from '../../../../src/main/proto/storage/job-schedule.pb';
/**
 * Message implementation for api.job_schedule.CreateRequest
 */
export class CreateRequest implements GrpcMessage {
  static id = 'api.job_schedule.CreateRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new CreateRequest();
    CreateRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: CreateRequest) {
    _instance.header = _instance.header || undefined;
    _instance.jobSchedule = _instance.jobSchedule || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: CreateRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api002.RequestHeader();
          _reader.readMessage(
            _instance.header,
            api002.RequestHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.jobSchedule = new storage004.JobSchedule();
          _reader.readMessage(
            _instance.jobSchedule,
            storage004.JobSchedule.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    CreateRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: CreateRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api002.RequestHeader.serializeBinaryToWriter
      );
    }
    if (_instance.jobSchedule) {
      _writer.writeMessage(
        2,
        _instance.jobSchedule as any,
        storage004.JobSchedule.serializeBinaryToWriter
      );
    }
  }

  private _header?: api002.RequestHeader;
  private _jobSchedule?: storage004.JobSchedule;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of CreateRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<CreateRequest.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api002.RequestHeader(_value.header)
      : undefined;
    this.jobSchedule = _value.jobSchedule
      ? new storage004.JobSchedule(_value.jobSchedule)
      : undefined;
    CreateRequest.refineValues(this);
  }
  get header(): api002.RequestHeader | undefined {
    return this._header;
  }
  set header(value: api002.RequestHeader | undefined) {
    this._header = value;
  }
  get jobSchedule(): storage004.JobSchedule | undefined {
    return this._jobSchedule;
  }
  set jobSchedule(value: storage004.JobSchedule | undefined) {
    this._jobSchedule = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    CreateRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): CreateRequest.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      jobSchedule: this.jobSchedule ? this.jobSchedule.toObject() : undefined,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): CreateRequest.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      jobSchedule: this.jobSchedule
        ? this.jobSchedule.toProtobufJSON(options)
        : null,
    };
  }
}
export module CreateRequest {
  /**
   * Standard JavaScript object representation for CreateRequest
   */
  export interface AsObject {
    header?: api002.RequestHeader.AsObject;
    jobSchedule?: storage004.JobSchedule.AsObject;
  }

  /**
   * Protobuf JSON representation for CreateRequest
   */
  export interface AsProtobufJSON {
    header: api002.RequestHeader.AsProtobufJSON | null;
    jobSchedule: storage004.JobSchedule.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for api.job_schedule.CreateResponse
 */
export class CreateResponse implements GrpcMessage {
  static id = 'api.job_schedule.CreateResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new CreateResponse();
    CreateResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: CreateResponse) {
    _instance.header = _instance.header || undefined;
    _instance.id = _instance.id || '';
    _instance.etag = _instance.etag || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: CreateResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api002.ResponseHeader();
          _reader.readMessage(
            _instance.header,
            api002.ResponseHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.id = _reader.readString();
          break;
        case 3:
          _instance.etag = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    CreateResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: CreateResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api002.ResponseHeader.serializeBinaryToWriter
      );
    }
    if (_instance.id) {
      _writer.writeString(2, _instance.id);
    }
    if (_instance.etag) {
      _writer.writeString(3, _instance.etag);
    }
  }

  private _header?: api002.ResponseHeader;
  private _id: string;
  private _etag: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of CreateResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<CreateResponse.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api002.ResponseHeader(_value.header)
      : undefined;
    this.id = _value.id;
    this.etag = _value.etag;
    CreateResponse.refineValues(this);
  }
  get header(): api002.ResponseHeader | undefined {
    return this._header;
  }
  set header(value: api002.ResponseHeader | undefined) {
    this._header = value;
  }
  get id(): string {
    return this._id;
  }
  set id(value: string) {
    this._id = value;
  }
  get etag(): string {
    return this._etag;
  }
  set etag(value: string) {
    this._etag = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    CreateResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): CreateResponse.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      id: this.id,
      etag: this.etag,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): CreateResponse.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      id: this.id,
      etag: this.etag,
    };
  }
}
export module CreateResponse {
  /**
   * Standard JavaScript object representation for CreateResponse
   */
  export interface AsObject {
    header?: api002.ResponseHeader.AsObject;
    id: string;
    etag: string;
  }

  /**
   * Protobuf JSON representation for CreateResponse
   */
  export interface AsProtobufJSON {
    header: api002.ResponseHeader.AsProtobufJSON | null;
    id: string;
    etag: string;
  }
}

/**
 * Message implementation for api.job_schedule.GetRequest
 */
export class GetRequest implements GrpcMessage {
  static id = 'api.job_schedule.GetRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetRequest();
    GetRequest.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetRequest) {
    _instance.header = _instance.header || undefined;
    _instance.scheduleJobLogId = _instance.scheduleJobLogId || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api002.RequestHeader();
          _reader.readMessage(
            _instance.header,
            api002.RequestHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.scheduleJobLogId = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    GetRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(_instance: GetRequest, _writer: BinaryWriter) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api002.RequestHeader.serializeBinaryToWriter
      );
    }
    if (_instance.scheduleJobLogId) {
      _writer.writeString(2, _instance.scheduleJobLogId);
    }
  }

  private _header?: api002.RequestHeader;
  private _scheduleJobLogId: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetRequest.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api002.RequestHeader(_value.header)
      : undefined;
    this.scheduleJobLogId = _value.scheduleJobLogId;
    GetRequest.refineValues(this);
  }
  get header(): api002.RequestHeader | undefined {
    return this._header;
  }
  set header(value: api002.RequestHeader | undefined) {
    this._header = value;
  }
  get scheduleJobLogId(): string {
    return this._scheduleJobLogId;
  }
  set scheduleJobLogId(value: string) {
    this._scheduleJobLogId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetRequest.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      scheduleJobLogId: this.scheduleJobLogId,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetRequest.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      scheduleJobLogId: this.scheduleJobLogId,
    };
  }
}
export module GetRequest {
  /**
   * Standard JavaScript object representation for GetRequest
   */
  export interface AsObject {
    header?: api002.RequestHeader.AsObject;
    scheduleJobLogId: string;
  }

  /**
   * Protobuf JSON representation for GetRequest
   */
  export interface AsProtobufJSON {
    header: api002.RequestHeader.AsProtobufJSON | null;
    scheduleJobLogId: string;
  }
}

/**
 * Message implementation for api.job_schedule.GetResponse
 */
export class GetResponse implements GrpcMessage {
  static id = 'api.job_schedule.GetResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetResponse();
    GetResponse.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetResponse) {
    _instance.header = _instance.header || undefined;
    _instance.jobSchedule = _instance.jobSchedule || undefined;
    _instance.etag = _instance.etag || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api002.ResponseHeader();
          _reader.readMessage(
            _instance.header,
            api002.ResponseHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.jobSchedule = new storage004.JobSchedule();
          _reader.readMessage(
            _instance.jobSchedule,
            storage004.JobSchedule.deserializeBinaryFromReader
          );
          break;
        case 3:
          _instance.etag = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    GetResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api002.ResponseHeader.serializeBinaryToWriter
      );
    }
    if (_instance.jobSchedule) {
      _writer.writeMessage(
        2,
        _instance.jobSchedule as any,
        storage004.JobSchedule.serializeBinaryToWriter
      );
    }
    if (_instance.etag) {
      _writer.writeString(3, _instance.etag);
    }
  }

  private _header?: api002.ResponseHeader;
  private _jobSchedule?: storage004.JobSchedule;
  private _etag: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetResponse.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api002.ResponseHeader(_value.header)
      : undefined;
    this.jobSchedule = _value.jobSchedule
      ? new storage004.JobSchedule(_value.jobSchedule)
      : undefined;
    this.etag = _value.etag;
    GetResponse.refineValues(this);
  }
  get header(): api002.ResponseHeader | undefined {
    return this._header;
  }
  set header(value: api002.ResponseHeader | undefined) {
    this._header = value;
  }
  get jobSchedule(): storage004.JobSchedule | undefined {
    return this._jobSchedule;
  }
  set jobSchedule(value: storage004.JobSchedule | undefined) {
    this._jobSchedule = value;
  }
  get etag(): string {
    return this._etag;
  }
  set etag(value: string) {
    this._etag = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetResponse.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      jobSchedule: this.jobSchedule ? this.jobSchedule.toObject() : undefined,
      etag: this.etag,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetResponse.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      jobSchedule: this.jobSchedule
        ? this.jobSchedule.toProtobufJSON(options)
        : null,
      etag: this.etag,
    };
  }
}
export module GetResponse {
  /**
   * Standard JavaScript object representation for GetResponse
   */
  export interface AsObject {
    header?: api002.ResponseHeader.AsObject;
    jobSchedule?: storage004.JobSchedule.AsObject;
    etag: string;
  }

  /**
   * Protobuf JSON representation for GetResponse
   */
  export interface AsProtobufJSON {
    header: api002.ResponseHeader.AsProtobufJSON | null;
    jobSchedule: storage004.JobSchedule.AsProtobufJSON | null;
    etag: string;
  }
}

/**
 * Message implementation for api.job_schedule.UpdateRequest
 */
export class UpdateRequest implements GrpcMessage {
  static id = 'api.job_schedule.UpdateRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new UpdateRequest();
    UpdateRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: UpdateRequest) {
    _instance.header = _instance.header || undefined;
    _instance.etag = _instance.etag || '';
    _instance.jobSchedule = _instance.jobSchedule || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: UpdateRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api002.RequestHeader();
          _reader.readMessage(
            _instance.header,
            api002.RequestHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.etag = _reader.readString();
          break;
        case 3:
          _instance.jobSchedule = new storage004.JobSchedule();
          _reader.readMessage(
            _instance.jobSchedule,
            storage004.JobSchedule.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    UpdateRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: UpdateRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api002.RequestHeader.serializeBinaryToWriter
      );
    }
    if (_instance.etag) {
      _writer.writeString(2, _instance.etag);
    }
    if (_instance.jobSchedule) {
      _writer.writeMessage(
        3,
        _instance.jobSchedule as any,
        storage004.JobSchedule.serializeBinaryToWriter
      );
    }
  }

  private _header?: api002.RequestHeader;
  private _etag: string;
  private _jobSchedule?: storage004.JobSchedule;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of UpdateRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<UpdateRequest.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api002.RequestHeader(_value.header)
      : undefined;
    this.etag = _value.etag;
    this.jobSchedule = _value.jobSchedule
      ? new storage004.JobSchedule(_value.jobSchedule)
      : undefined;
    UpdateRequest.refineValues(this);
  }
  get header(): api002.RequestHeader | undefined {
    return this._header;
  }
  set header(value: api002.RequestHeader | undefined) {
    this._header = value;
  }
  get etag(): string {
    return this._etag;
  }
  set etag(value: string) {
    this._etag = value;
  }
  get jobSchedule(): storage004.JobSchedule | undefined {
    return this._jobSchedule;
  }
  set jobSchedule(value: storage004.JobSchedule | undefined) {
    this._jobSchedule = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    UpdateRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): UpdateRequest.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      etag: this.etag,
      jobSchedule: this.jobSchedule ? this.jobSchedule.toObject() : undefined,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): UpdateRequest.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      etag: this.etag,
      jobSchedule: this.jobSchedule
        ? this.jobSchedule.toProtobufJSON(options)
        : null,
    };
  }
}
export module UpdateRequest {
  /**
   * Standard JavaScript object representation for UpdateRequest
   */
  export interface AsObject {
    header?: api002.RequestHeader.AsObject;
    etag: string;
    jobSchedule?: storage004.JobSchedule.AsObject;
  }

  /**
   * Protobuf JSON representation for UpdateRequest
   */
  export interface AsProtobufJSON {
    header: api002.RequestHeader.AsProtobufJSON | null;
    etag: string;
    jobSchedule: storage004.JobSchedule.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for api.job_schedule.UpdateResponse
 */
export class UpdateResponse implements GrpcMessage {
  static id = 'api.job_schedule.UpdateResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new UpdateResponse();
    UpdateResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: UpdateResponse) {
    _instance.header = _instance.header || undefined;
    _instance.etag = _instance.etag || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: UpdateResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api002.ResponseHeader();
          _reader.readMessage(
            _instance.header,
            api002.ResponseHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.etag = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    UpdateResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: UpdateResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api002.ResponseHeader.serializeBinaryToWriter
      );
    }
    if (_instance.etag) {
      _writer.writeString(2, _instance.etag);
    }
  }

  private _header?: api002.ResponseHeader;
  private _etag: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of UpdateResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<UpdateResponse.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api002.ResponseHeader(_value.header)
      : undefined;
    this.etag = _value.etag;
    UpdateResponse.refineValues(this);
  }
  get header(): api002.ResponseHeader | undefined {
    return this._header;
  }
  set header(value: api002.ResponseHeader | undefined) {
    this._header = value;
  }
  get etag(): string {
    return this._etag;
  }
  set etag(value: string) {
    this._etag = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    UpdateResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): UpdateResponse.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      etag: this.etag,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): UpdateResponse.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      etag: this.etag,
    };
  }
}
export module UpdateResponse {
  /**
   * Standard JavaScript object representation for UpdateResponse
   */
  export interface AsObject {
    header?: api002.ResponseHeader.AsObject;
    etag: string;
  }

  /**
   * Protobuf JSON representation for UpdateResponse
   */
  export interface AsProtobufJSON {
    header: api002.ResponseHeader.AsProtobufJSON | null;
    etag: string;
  }
}

/**
 * Message implementation for api.job_schedule.ListRequest
 */
export class ListRequest implements GrpcMessage {
  static id = 'api.job_schedule.ListRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ListRequest();
    ListRequest.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ListRequest) {
    _instance.header = _instance.header || undefined;
    _instance.filter = _instance.filter || undefined;
    _instance.paginated = _instance.paginated || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ListRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api002.RequestHeader();
          _reader.readMessage(
            _instance.header,
            api002.RequestHeader.deserializeBinaryFromReader
          );
          break;
        case 4:
          _instance.filter = new ListRequestFilter();
          _reader.readMessage(
            _instance.filter,
            ListRequestFilter.deserializeBinaryFromReader
          );
          break;
        case 3:
          _instance.paginated = new api003.GetPaginatedRequest();
          _reader.readMessage(
            _instance.paginated,
            api003.GetPaginatedRequest.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    ListRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ListRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api002.RequestHeader.serializeBinaryToWriter
      );
    }
    if (_instance.filter) {
      _writer.writeMessage(
        4,
        _instance.filter as any,
        ListRequestFilter.serializeBinaryToWriter
      );
    }
    if (_instance.paginated) {
      _writer.writeMessage(
        3,
        _instance.paginated as any,
        api003.GetPaginatedRequest.serializeBinaryToWriter
      );
    }
  }

  private _header?: api002.RequestHeader;
  private _filter?: ListRequestFilter;
  private _paginated?: api003.GetPaginatedRequest;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ListRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<ListRequest.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api002.RequestHeader(_value.header)
      : undefined;
    this.filter = _value.filter
      ? new ListRequestFilter(_value.filter)
      : undefined;
    this.paginated = _value.paginated
      ? new api003.GetPaginatedRequest(_value.paginated)
      : undefined;
    ListRequest.refineValues(this);
  }
  get header(): api002.RequestHeader | undefined {
    return this._header;
  }
  set header(value: api002.RequestHeader | undefined) {
    this._header = value;
  }
  get filter(): ListRequestFilter | undefined {
    return this._filter;
  }
  set filter(value: ListRequestFilter | undefined) {
    this._filter = value;
  }
  get paginated(): api003.GetPaginatedRequest | undefined {
    return this._paginated;
  }
  set paginated(value: api003.GetPaginatedRequest | undefined) {
    this._paginated = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ListRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ListRequest.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      filter: this.filter ? this.filter.toObject() : undefined,
      paginated: this.paginated ? this.paginated.toObject() : undefined,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ListRequest.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      filter: this.filter ? this.filter.toProtobufJSON(options) : null,
      paginated: this.paginated ? this.paginated.toProtobufJSON(options) : null,
    };
  }
}
export module ListRequest {
  /**
   * Standard JavaScript object representation for ListRequest
   */
  export interface AsObject {
    header?: api002.RequestHeader.AsObject;
    filter?: ListRequestFilter.AsObject;
    paginated?: api003.GetPaginatedRequest.AsObject;
  }

  /**
   * Protobuf JSON representation for ListRequest
   */
  export interface AsProtobufJSON {
    header: api002.RequestHeader.AsProtobufJSON | null;
    filter: ListRequestFilter.AsProtobufJSON | null;
    paginated: api003.GetPaginatedRequest.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for api.job_schedule.ListRequestFilter
 */
export class ListRequestFilter implements GrpcMessage {
  static id = 'api.job_schedule.ListRequestFilter';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ListRequestFilter();
    ListRequestFilter.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ListRequestFilter) {
    _instance.startTime = _instance.startTime || undefined;
    _instance.endTime = _instance.endTime || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ListRequestFilter,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.startTime = new storage004.TimeOfDay();
          _reader.readMessage(
            _instance.startTime,
            storage004.TimeOfDay.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.endTime = new storage004.TimeOfDay();
          _reader.readMessage(
            _instance.endTime,
            storage004.TimeOfDay.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    ListRequestFilter.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ListRequestFilter,
    _writer: BinaryWriter
  ) {
    if (_instance.startTime) {
      _writer.writeMessage(
        1,
        _instance.startTime as any,
        storage004.TimeOfDay.serializeBinaryToWriter
      );
    }
    if (_instance.endTime) {
      _writer.writeMessage(
        2,
        _instance.endTime as any,
        storage004.TimeOfDay.serializeBinaryToWriter
      );
    }
  }

  private _startTime?: storage004.TimeOfDay;
  private _endTime?: storage004.TimeOfDay;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ListRequestFilter to deeply clone from
   */
  constructor(_value?: RecursivePartial<ListRequestFilter.AsObject>) {
    _value = _value || {};
    this.startTime = _value.startTime
      ? new storage004.TimeOfDay(_value.startTime)
      : undefined;
    this.endTime = _value.endTime
      ? new storage004.TimeOfDay(_value.endTime)
      : undefined;
    ListRequestFilter.refineValues(this);
  }
  get startTime(): storage004.TimeOfDay | undefined {
    return this._startTime;
  }
  set startTime(value: storage004.TimeOfDay | undefined) {
    this._startTime = value;
  }
  get endTime(): storage004.TimeOfDay | undefined {
    return this._endTime;
  }
  set endTime(value: storage004.TimeOfDay | undefined) {
    this._endTime = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ListRequestFilter.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ListRequestFilter.AsObject {
    return {
      startTime: this.startTime ? this.startTime.toObject() : undefined,
      endTime: this.endTime ? this.endTime.toObject() : undefined,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ListRequestFilter.AsProtobufJSON {
    return {
      startTime: this.startTime ? this.startTime.toProtobufJSON(options) : null,
      endTime: this.endTime ? this.endTime.toProtobufJSON(options) : null,
    };
  }
}
export module ListRequestFilter {
  /**
   * Standard JavaScript object representation for ListRequestFilter
   */
  export interface AsObject {
    startTime?: storage004.TimeOfDay.AsObject;
    endTime?: storage004.TimeOfDay.AsObject;
  }

  /**
   * Protobuf JSON representation for ListRequestFilter
   */
  export interface AsProtobufJSON {
    startTime: storage004.TimeOfDay.AsProtobufJSON | null;
    endTime: storage004.TimeOfDay.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for api.job_schedule.ApiJobSchedule
 */
export class ApiJobSchedule implements GrpcMessage {
  static id = 'api.job_schedule.ApiJobSchedule';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ApiJobSchedule();
    ApiJobSchedule.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ApiJobSchedule) {
    _instance.jobSchedule = _instance.jobSchedule || undefined;
    _instance.etag = _instance.etag || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ApiJobSchedule,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.jobSchedule = new storage004.JobSchedule();
          _reader.readMessage(
            _instance.jobSchedule,
            storage004.JobSchedule.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.etag = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    ApiJobSchedule.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ApiJobSchedule,
    _writer: BinaryWriter
  ) {
    if (_instance.jobSchedule) {
      _writer.writeMessage(
        1,
        _instance.jobSchedule as any,
        storage004.JobSchedule.serializeBinaryToWriter
      );
    }
    if (_instance.etag) {
      _writer.writeString(2, _instance.etag);
    }
  }

  private _jobSchedule?: storage004.JobSchedule;
  private _etag: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ApiJobSchedule to deeply clone from
   */
  constructor(_value?: RecursivePartial<ApiJobSchedule.AsObject>) {
    _value = _value || {};
    this.jobSchedule = _value.jobSchedule
      ? new storage004.JobSchedule(_value.jobSchedule)
      : undefined;
    this.etag = _value.etag;
    ApiJobSchedule.refineValues(this);
  }
  get jobSchedule(): storage004.JobSchedule | undefined {
    return this._jobSchedule;
  }
  set jobSchedule(value: storage004.JobSchedule | undefined) {
    this._jobSchedule = value;
  }
  get etag(): string {
    return this._etag;
  }
  set etag(value: string) {
    this._etag = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ApiJobSchedule.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ApiJobSchedule.AsObject {
    return {
      jobSchedule: this.jobSchedule ? this.jobSchedule.toObject() : undefined,
      etag: this.etag,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ApiJobSchedule.AsProtobufJSON {
    return {
      jobSchedule: this.jobSchedule
        ? this.jobSchedule.toProtobufJSON(options)
        : null,
      etag: this.etag,
    };
  }
}
export module ApiJobSchedule {
  /**
   * Standard JavaScript object representation for ApiJobSchedule
   */
  export interface AsObject {
    jobSchedule?: storage004.JobSchedule.AsObject;
    etag: string;
  }

  /**
   * Protobuf JSON representation for ApiJobSchedule
   */
  export interface AsProtobufJSON {
    jobSchedule: storage004.JobSchedule.AsProtobufJSON | null;
    etag: string;
  }
}

/**
 * Message implementation for api.job_schedule.ListResponse
 */
export class ListResponse implements GrpcMessage {
  static id = 'api.job_schedule.ListResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ListResponse();
    ListResponse.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ListResponse) {
    _instance.header = _instance.header || undefined;
    _instance.jobSchedules = _instance.jobSchedules || [];
    _instance.paginatedResponse = _instance.paginatedResponse || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ListResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api002.ResponseHeader();
          _reader.readMessage(
            _instance.header,
            api002.ResponseHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          const messageInitializer2 = new ApiJobSchedule();
          _reader.readMessage(
            messageInitializer2,
            ApiJobSchedule.deserializeBinaryFromReader
          );
          (_instance.jobSchedules = _instance.jobSchedules || []).push(
            messageInitializer2
          );
          break;
        case 3:
          _instance.paginatedResponse = new api003.PaginatedResponse();
          _reader.readMessage(
            _instance.paginatedResponse,
            api003.PaginatedResponse.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    ListResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ListResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api002.ResponseHeader.serializeBinaryToWriter
      );
    }
    if (_instance.jobSchedules && _instance.jobSchedules.length) {
      _writer.writeRepeatedMessage(
        2,
        _instance.jobSchedules as any,
        ApiJobSchedule.serializeBinaryToWriter
      );
    }
    if (_instance.paginatedResponse) {
      _writer.writeMessage(
        3,
        _instance.paginatedResponse as any,
        api003.PaginatedResponse.serializeBinaryToWriter
      );
    }
  }

  private _header?: api002.ResponseHeader;
  private _jobSchedules?: ApiJobSchedule[];
  private _paginatedResponse?: api003.PaginatedResponse;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ListResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<ListResponse.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api002.ResponseHeader(_value.header)
      : undefined;
    this.jobSchedules = (_value.jobSchedules || []).map(
      (m) => new ApiJobSchedule(m)
    );
    this.paginatedResponse = _value.paginatedResponse
      ? new api003.PaginatedResponse(_value.paginatedResponse)
      : undefined;
    ListResponse.refineValues(this);
  }
  get header(): api002.ResponseHeader | undefined {
    return this._header;
  }
  set header(value: api002.ResponseHeader | undefined) {
    this._header = value;
  }
  get jobSchedules(): ApiJobSchedule[] | undefined {
    return this._jobSchedules;
  }
  set jobSchedules(value: ApiJobSchedule[] | undefined) {
    this._jobSchedules = value;
  }
  get paginatedResponse(): api003.PaginatedResponse | undefined {
    return this._paginatedResponse;
  }
  set paginatedResponse(value: api003.PaginatedResponse | undefined) {
    this._paginatedResponse = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ListResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ListResponse.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      jobSchedules: (this.jobSchedules || []).map((m) => m.toObject()),
      paginatedResponse: this.paginatedResponse
        ? this.paginatedResponse.toObject()
        : undefined,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ListResponse.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      jobSchedules: (this.jobSchedules || []).map((m) =>
        m.toProtobufJSON(options)
      ),
      paginatedResponse: this.paginatedResponse
        ? this.paginatedResponse.toProtobufJSON(options)
        : null,
    };
  }
}
export module ListResponse {
  /**
   * Standard JavaScript object representation for ListResponse
   */
  export interface AsObject {
    header?: api002.ResponseHeader.AsObject;
    jobSchedules?: ApiJobSchedule.AsObject[];
    paginatedResponse?: api003.PaginatedResponse.AsObject;
  }

  /**
   * Protobuf JSON representation for ListResponse
   */
  export interface AsProtobufJSON {
    header: api002.ResponseHeader.AsProtobufJSON | null;
    jobSchedules: ApiJobSchedule.AsProtobufJSON[] | null;
    paginatedResponse: api003.PaginatedResponse.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for api.job_schedule.ArchiveRequest
 */
export class ArchiveRequest implements GrpcMessage {
  static id = 'api.job_schedule.ArchiveRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ArchiveRequest();
    ArchiveRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ArchiveRequest) {
    _instance.header = _instance.header || undefined;
    _instance.jobScheduleId = _instance.jobScheduleId || '';
    _instance.archive = _instance.archive || false;
    _instance.etag = _instance.etag || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ArchiveRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api002.RequestHeader();
          _reader.readMessage(
            _instance.header,
            api002.RequestHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.jobScheduleId = _reader.readString();
          break;
        case 3:
          _instance.archive = _reader.readBool();
          break;
        case 4:
          _instance.etag = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    ArchiveRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ArchiveRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api002.RequestHeader.serializeBinaryToWriter
      );
    }
    if (_instance.jobScheduleId) {
      _writer.writeString(2, _instance.jobScheduleId);
    }
    if (_instance.archive) {
      _writer.writeBool(3, _instance.archive);
    }
    if (_instance.etag) {
      _writer.writeString(4, _instance.etag);
    }
  }

  private _header?: api002.RequestHeader;
  private _jobScheduleId: string;
  private _archive: boolean;
  private _etag: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ArchiveRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<ArchiveRequest.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api002.RequestHeader(_value.header)
      : undefined;
    this.jobScheduleId = _value.jobScheduleId;
    this.archive = _value.archive;
    this.etag = _value.etag;
    ArchiveRequest.refineValues(this);
  }
  get header(): api002.RequestHeader | undefined {
    return this._header;
  }
  set header(value: api002.RequestHeader | undefined) {
    this._header = value;
  }
  get jobScheduleId(): string {
    return this._jobScheduleId;
  }
  set jobScheduleId(value: string) {
    this._jobScheduleId = value;
  }
  get archive(): boolean {
    return this._archive;
  }
  set archive(value: boolean) {
    this._archive = value;
  }
  get etag(): string {
    return this._etag;
  }
  set etag(value: string) {
    this._etag = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ArchiveRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ArchiveRequest.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      jobScheduleId: this.jobScheduleId,
      archive: this.archive,
      etag: this.etag,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ArchiveRequest.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      jobScheduleId: this.jobScheduleId,
      archive: this.archive,
      etag: this.etag,
    };
  }
}
export module ArchiveRequest {
  /**
   * Standard JavaScript object representation for ArchiveRequest
   */
  export interface AsObject {
    header?: api002.RequestHeader.AsObject;
    jobScheduleId: string;
    archive: boolean;
    etag: string;
  }

  /**
   * Protobuf JSON representation for ArchiveRequest
   */
  export interface AsProtobufJSON {
    header: api002.RequestHeader.AsProtobufJSON | null;
    jobScheduleId: string;
    archive: boolean;
    etag: string;
  }
}

/**
 * Message implementation for api.job_schedule.ArchiveResponse
 */
export class ArchiveResponse implements GrpcMessage {
  static id = 'api.job_schedule.ArchiveResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ArchiveResponse();
    ArchiveResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ArchiveResponse) {
    _instance.header = _instance.header || undefined;
    _instance.etag = _instance.etag || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ArchiveResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api002.ResponseHeader();
          _reader.readMessage(
            _instance.header,
            api002.ResponseHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.etag = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    ArchiveResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ArchiveResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api002.ResponseHeader.serializeBinaryToWriter
      );
    }
    if (_instance.etag) {
      _writer.writeString(2, _instance.etag);
    }
  }

  private _header?: api002.ResponseHeader;
  private _etag: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ArchiveResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<ArchiveResponse.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api002.ResponseHeader(_value.header)
      : undefined;
    this.etag = _value.etag;
    ArchiveResponse.refineValues(this);
  }
  get header(): api002.ResponseHeader | undefined {
    return this._header;
  }
  set header(value: api002.ResponseHeader | undefined) {
    this._header = value;
  }
  get etag(): string {
    return this._etag;
  }
  set etag(value: string) {
    this._etag = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ArchiveResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ArchiveResponse.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      etag: this.etag,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ArchiveResponse.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      etag: this.etag,
    };
  }
}
export module ArchiveResponse {
  /**
   * Standard JavaScript object representation for ArchiveResponse
   */
  export interface AsObject {
    header?: api002.ResponseHeader.AsObject;
    etag: string;
  }

  /**
   * Protobuf JSON representation for ArchiveResponse
   */
  export interface AsProtobufJSON {
    header: api002.ResponseHeader.AsProtobufJSON | null;
    etag: string;
  }
}
