<div class="background">
  <div class="card">
    <app-anonym-logo></app-anonym-logo>
    <hr />
    <div class="body">
      <h1>Login</h1>
      <div class="note">
        After entering your email account below you will be prompted to
        authenticate.
      </div>
      <form ngNativeValidate (ngSubmit)="process()" [formGroup]="form">
        <input
          type="email"
          tabindex="1"
          placeholder="Please enter your email"
          data-testid="email"
          formControlName="email"
          autocomplete="email"
          required
        />
        <app-message-box></app-message-box>
        <button
          type="submit"
          tabindex="2"
          data-testid="signInOptionsSubmit"
          color="primary"
          mat-flat-button
        >
          Next
        </button>
      </form>
    </div>
  </div>
</div>
