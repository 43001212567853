import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AppRoutingModule } from 'app/routing/app-routing.module';
import { ContentPageComponent } from 'app/views/content-page/content-page.component';
import { KeyRendererComponent } from 'app/views/public-key/key-renderer/key-renderer.component';
import { PublicKeyComponent } from 'app/views/public-key/public-key.component';
import { ContainerComponent } from 'app/views/shared/components/container/container.component';
import { MessageBoxProvider } from 'app/views/shared/components/message-box/message-box.provider';
import { PageHeaderComponent } from 'app/views/shared/components/page-header/page-header.component';
import { MarkdownModule } from 'ngx-markdown';

import { MessageBoxComponent } from '../../views/shared/components/message-box/message-box.component';

@NgModule({
  declarations: [
    ContentPageComponent,
    KeyRendererComponent,
    PublicKeyComponent,
  ],
  imports: [
    AppRoutingModule,
    CommonModule,
    ContainerComponent,
    PageHeaderComponent,
    MarkdownModule.forChild(),
    MatButtonModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MessageBoxComponent,
  ],
  providers: [MessageBoxProvider],
})
export class ContentPageModule {}
