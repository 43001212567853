/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import { Inject, Injectable, Optional } from '@angular/core';
import {
  GrpcCallType,
  GrpcClient,
  GrpcClientFactory,
  GrpcEvent,
  GrpcMetadata,
} from '@ngx-grpc/common';
import {
  GRPC_CLIENT_FACTORY,
  GrpcHandler,
  takeMessages,
  throwStatusErrors,
} from '@ngx-grpc/core';
import { Observable } from 'rxjs';
import * as thisProto from './customer-data-set-service.pb';
import * as googleProtobuf000 from '@ngx-grpc/well-known-types';
import * as storage001 from '../../../../src/main/proto/storage/commons.pb';
import * as storage002 from '../../../../src/main/proto/storage/schema-annotations.pb';
import * as api003 from '../../../../src/main/proto/api/headers.pb';
import * as api004 from '../../../../src/main/proto/api/pagination.pb';
import * as storage005 from '../../../../src/main/proto/storage/customer-data-set.pb';
import { GRPC_CUSTOMER_DATA_SET_SERVICE_CLIENT_SETTINGS } from './customer-data-set-service.pbconf';
/**
 * Service client implementation for api.customerdataset.CustomerDataSetService
 */
@Injectable({ providedIn: 'any' })
export class CustomerDataSetServiceClient {
  private client: GrpcClient<any>;

  /**
   * Raw RPC implementation for each service client method.
   * The raw methods provide more control on the incoming data and events. E.g. they can be useful to read status `OK` metadata.
   * Attention: these methods do not throw errors when non-zero status codes are received.
   */
  $raw = {
    /**
     * Unary call: /api.customerdataset.CustomerDataSetService/Create
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.CreateResponse>>
     */
    create: (
      requestData: thisProto.CreateRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.CreateResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/api.customerdataset.CustomerDataSetService/Create',
        requestData,
        requestMetadata,
        requestClass: thisProto.CreateRequest,
        responseClass: thisProto.CreateResponse,
      });
    },
    /**
     * Unary call: /api.customerdataset.CustomerDataSetService/CreateSasTokens
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.CreateSasTokensResponse>>
     */
    createSasTokens: (
      requestData: thisProto.CreateSasTokensRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.CreateSasTokensResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/api.customerdataset.CustomerDataSetService/CreateSasTokens',
        requestData,
        requestMetadata,
        requestClass: thisProto.CreateSasTokensRequest,
        responseClass: thisProto.CreateSasTokensResponse,
      });
    },
    /**
     * Unary call: /api.customerdataset.CustomerDataSetService/Finalize
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.FinalizeResponse>>
     */
    finalize: (
      requestData: thisProto.FinalizeRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.FinalizeResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/api.customerdataset.CustomerDataSetService/Finalize',
        requestData,
        requestMetadata,
        requestClass: thisProto.FinalizeRequest,
        responseClass: thisProto.FinalizeResponse,
      });
    },
    /**
     * Unary call: /api.customerdataset.CustomerDataSetService/List
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.ListResponse>>
     */
    list: (
      requestData: thisProto.ListRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.ListResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/api.customerdataset.CustomerDataSetService/List',
        requestData,
        requestMetadata,
        requestClass: thisProto.ListRequest,
        responseClass: thisProto.ListResponse,
      });
    },
    /**
     * Unary call: /api.customerdataset.CustomerDataSetService/Get
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.GetResponse>>
     */
    get: (
      requestData: thisProto.GetRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.GetResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/api.customerdataset.CustomerDataSetService/Get',
        requestData,
        requestMetadata,
        requestClass: thisProto.GetRequest,
        responseClass: thisProto.GetResponse,
      });
    },
    /**
     * Unary call: /api.customerdataset.CustomerDataSetService/StartExternalTransfer
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.StartExternalTransferResponse>>
     */
    startExternalTransfer: (
      requestData: thisProto.StartExternalTransferRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.StartExternalTransferResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/api.customerdataset.CustomerDataSetService/StartExternalTransfer',
        requestData,
        requestMetadata,
        requestClass: thisProto.StartExternalTransferRequest,
        responseClass: thisProto.StartExternalTransferResponse,
      });
    },
    /**
     * Unary call: /api.customerdataset.CustomerDataSetService/Archive
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.ArchiveResponse>>
     */
    archive: (
      requestData: thisProto.ArchiveRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.ArchiveResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/api.customerdataset.CustomerDataSetService/Archive',
        requestData,
        requestMetadata,
        requestClass: thisProto.ArchiveRequest,
        responseClass: thisProto.ArchiveResponse,
      });
    },
    /**
     * Unary call: /api.customerdataset.CustomerDataSetService/CreateTrigger
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.CreateTriggerResponse>>
     */
    createTrigger: (
      requestData: thisProto.CreateTriggerRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.CreateTriggerResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/api.customerdataset.CustomerDataSetService/CreateTrigger',
        requestData,
        requestMetadata,
        requestClass: thisProto.CreateTriggerRequest,
        responseClass: thisProto.CreateTriggerResponse,
      });
    },
    /**
     * Unary call: /api.customerdataset.CustomerDataSetService/DeleteTrigger
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.DeleteTriggerResponse>>
     */
    deleteTrigger: (
      requestData: thisProto.DeleteTriggerRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.DeleteTriggerResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/api.customerdataset.CustomerDataSetService/DeleteTrigger',
        requestData,
        requestMetadata,
        requestClass: thisProto.DeleteTriggerRequest,
        responseClass: thisProto.DeleteTriggerResponse,
      });
    },
    /**
     * Unary call: /api.customerdataset.CustomerDataSetService/Update
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.UpdateResponse>>
     */
    update: (
      requestData: thisProto.UpdateRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.UpdateResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/api.customerdataset.CustomerDataSetService/Update',
        requestData,
        requestMetadata,
        requestClass: thisProto.UpdateRequest,
        responseClass: thisProto.UpdateResponse,
      });
    },
  };

  constructor(
    @Optional()
    @Inject(GRPC_CUSTOMER_DATA_SET_SERVICE_CLIENT_SETTINGS)
    settings: any,
    @Inject(GRPC_CLIENT_FACTORY) clientFactory: GrpcClientFactory<any>,
    private handler: GrpcHandler
  ) {
    this.client = clientFactory.createClient(
      'api.customerdataset.CustomerDataSetService',
      settings
    );
  }

  /**
   * Unary call @/api.customerdataset.CustomerDataSetService/Create
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.CreateResponse>
   */
  create(
    requestData: thisProto.CreateRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.CreateResponse> {
    return this.$raw
      .create(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/api.customerdataset.CustomerDataSetService/CreateSasTokens
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.CreateSasTokensResponse>
   */
  createSasTokens(
    requestData: thisProto.CreateSasTokensRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.CreateSasTokensResponse> {
    return this.$raw
      .createSasTokens(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/api.customerdataset.CustomerDataSetService/Finalize
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.FinalizeResponse>
   */
  finalize(
    requestData: thisProto.FinalizeRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.FinalizeResponse> {
    return this.$raw
      .finalize(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/api.customerdataset.CustomerDataSetService/List
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.ListResponse>
   */
  list(
    requestData: thisProto.ListRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.ListResponse> {
    return this.$raw
      .list(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/api.customerdataset.CustomerDataSetService/Get
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.GetResponse>
   */
  get(
    requestData: thisProto.GetRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.GetResponse> {
    return this.$raw
      .get(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/api.customerdataset.CustomerDataSetService/StartExternalTransfer
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.StartExternalTransferResponse>
   */
  startExternalTransfer(
    requestData: thisProto.StartExternalTransferRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.StartExternalTransferResponse> {
    return this.$raw
      .startExternalTransfer(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/api.customerdataset.CustomerDataSetService/Archive
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.ArchiveResponse>
   */
  archive(
    requestData: thisProto.ArchiveRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.ArchiveResponse> {
    return this.$raw
      .archive(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/api.customerdataset.CustomerDataSetService/CreateTrigger
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.CreateTriggerResponse>
   */
  createTrigger(
    requestData: thisProto.CreateTriggerRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.CreateTriggerResponse> {
    return this.$raw
      .createTrigger(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/api.customerdataset.CustomerDataSetService/DeleteTrigger
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.DeleteTriggerResponse>
   */
  deleteTrigger(
    requestData: thisProto.DeleteTriggerRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.DeleteTriggerResponse> {
    return this.$raw
      .deleteTrigger(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/api.customerdataset.CustomerDataSetService/Update
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.UpdateResponse>
   */
  update(
    requestData: thisProto.UpdateRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.UpdateResponse> {
    return this.$raw
      .update(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }
}
