import { Component, Inject, OnInit, Optional } from '@angular/core';
import {
  Auth,
  getMultiFactorResolver,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  User,
} from '@angular/fire/auth';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  MultiFactorError,
  MultiFactorResolver,
  PhoneMultiFactorInfo,
  RecaptchaVerifier,
} from 'firebase/auth';

import { LoggerService } from '../../services/logger.service';
import { UserService } from '../../services/user.service';
import { WindowService } from '../../services/window.service';
import { MessageBoxProvider } from '../../views/shared/components/message-box/message-box.provider';

@Component({
  selector: 'app-multi-factor-login-modal',
  templateUrl: './multifactor-login-modal.component.html',
  styleUrls: [
    './multifactor-login-modal.component.scss',
    '../../shared/shared.scss',
  ],
  providers: [WindowService],
})
export class MultifactorLoginModalComponent implements OnInit {
  smsSent = false;
  title: string;
  windowRef: any;
  user: User | undefined;
  phoneNumber: string | undefined;
  codeVerification: string | undefined;
  isLoading = false;
  verificationId: string | undefined;
  resolver: MultiFactorResolver | undefined;
  phoneMultiFactorInfos: PhoneMultiFactorInfo[] | undefined;
  mfaPhone = 'mfaphone';
  phoneNumberEndingIn: string | undefined;
  public mfaForm: FormGroup;

  constructor(
    private dialogRef: MatDialogRef<MultifactorLoginModalComponent>,
    private analytics: AngularFireAnalytics,
    public userService: UserService,
    private formBuilder: FormBuilder,
    private messageBox: MessageBoxProvider,
    private logger: LoggerService,
    @Optional() private auth: Auth,
    private win: WindowService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      error: MultiFactorError | undefined;
    }
  ) {
    this.title = 'Login with two-factor authentication';

    this.mfaForm = this.formBuilder.group({
      codeVerification: new FormControl('', {
        validators: [Validators.required],
        nonNullable: true,
      }),
    });
  }

  async ngOnInit() {
    this.windowRef = this.win.windowRef;
    this.auth.onAuthStateChanged((user) => {
      if (!user) {
        this.resolver = getMultiFactorResolver(this.auth, this.data.error!);
        this.phoneMultiFactorInfos = this.getMultiFactorInfos();

        const recaptchaVerifier = new RecaptchaVerifier(
          this.auth,
          'recaptcha-container',
          {
            size: 'invisible',
            callback: () => {
              this.messageBox.success('reCaptcha success');
            },
            'expired-callback': () => {
              console.error('Expired reCaptch');
              this.messageBox.success(
                'reCaptcha timeout, please retry reCaptcha'
              );
            },
          }
        );
        this.windowRef.recaptchaVerifier = recaptchaVerifier;
      }
    });
  }

  async sendLoginCode(phoneMultiFactorInfo: PhoneMultiFactorInfo) {
    this.isLoading = true;

    const phoneInfoOptions = {
      multiFactorHint: phoneMultiFactorInfo,
      session: this.resolver!.session,
    };

    const phoneAuthProvider = new PhoneAuthProvider(this.auth);

    // Send SMS verification code.
    phoneAuthProvider
      .verifyPhoneNumber(phoneInfoOptions, this.windowRef.recaptchaVerifier)
      .then((verificationId: string) => {
        this.verificationId = verificationId;
        this.smsSent = true;
        this.messageBox.success('A code has been sent to your mobile device');
        this.phoneNumberEndingIn = phoneMultiFactorInfo.phoneNumber;
        this.analytics.logEvent('sent-2fa-sms-code');
      })
      .catch((error) => {
        this.smsSent = false;
        switch (error.code) {
          case 'auth/invalid-phone-number':
            this.messageBox.error('Invalid phone number');
            break;
          case 'auth/requires-recent-login':
            this.messageBox.error('Please login again before removing factor');
            break;
          default:
            this.logger.error(error);
            this.messageBox.error(error.message);
            break;
        }
        this.analytics.logEvent('error-sending-2fa-sms-code');
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  async verifyLoginCode() {
    this.isLoading = true;
    const { codeVerification } = this.mfaForm.value;

    const cred = PhoneAuthProvider.credential(
      this.verificationId!,
      codeVerification
    );

    try {
      const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);
      await this.resolver!.resolveSignIn(multiFactorAssertion);
      this.analytics.logEvent('validated-2fa-sms-code');
      this.close(true);
    } catch (error: Error | any) {
      let message = 'Unknown error';
      if (error instanceof Error) message = error.message;
      this.logger.error(message);
      this.analytics.logEvent('error-validating-2fa-sms-code');
    } finally {
      this.isLoading = false;
    }
  }

  getMultiFactorInfos(): PhoneMultiFactorInfo[] {
    const phoneMultiFactorInfos: PhoneMultiFactorInfo[] = [];
    this.resolver?.hints.forEach((multiFactorInfo: any) => {
      if (
        multiFactorInfo.factorId === PhoneMultiFactorGenerator.FACTOR_ID &&
        multiFactorInfo.displayName == this.mfaPhone
      ) {
        phoneMultiFactorInfos.push(multiFactorInfo);
      }
    });
    return phoneMultiFactorInfos;
  }

  public close(mfaSuccess: boolean) {
    this.dialogRef.close({ mfaSuccess: mfaSuccess });
  }
}
