import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { Router } from '@angular/router';
import { EventBusService, Events } from 'app/services/event-bus.service';
import { LoggerService } from 'app/services/logger.service';
import { NotificationService } from 'app/services/notification.service';
import { NotificationType } from 'types/notification';

import { SupportDialogComponent } from './support-dialog/support-dialog.component';

@Component({
  selector: 'app-top-navigation',
  templateUrl: './top-navigation.component.html',
  styleUrls: ['./top-navigation.component.scss'],
})
export class TopNavigationComponent implements OnInit {
  @Input() userName: string | undefined | null;
  @Input() userPhotoUrl: string | undefined | null;
  @ViewChild(MatMenuTrigger) notificationBtn: MatMenuTrigger | undefined;

  showNotification = false;
  notifications: NotificationType[] = [];

  constructor(
    private dialog: MatDialog,
    private router: Router,
    private notificationService: NotificationService,
    private events: EventBusService,
    private logger: LoggerService
  ) {
    this.events.on(Events.NotificationReceived, () => {
      this.showNotification = true;
      this.getNotifications();
    });
  }

  ngOnInit(): void {
    this.getNotifications();
  }

  openSupportDialog() {
    this.dialog.open(SupportDialogComponent);
  }

  openNotificationPage(mesageId: string) {
    this.router.navigate(['/notifications', { fragment: mesageId }]);
  }

  async getNotifications() {
    try {
      const notifications = await this.notificationService.getNotifications();
      this.notifications = [];
      notifications.forEach((message) => {
        const notification = message as NotificationType;
        this.notifications.push(notification);
        if (!notification.viewed) {
          this.showNotification = true;
        }
      });
    } catch (error) {
      this.logger.error('unable to get notifications', error as Error);
    }
  }
}
