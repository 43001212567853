<div class="field-dropdown-label">
  <span class="field-title">Location</span>
</div>
<div class="field-row">
  <mat-form-field class="field-name-container" appearance="outline">
    <mat-select
      placeholder="--Select--"
      name="location"
      data-testid="location"
      (selectionChange)="onLocationSelect($event.value)"
      required
      [multiple]="multiple"
      [(value)]="selectedLocation"
    >
      <mat-option *ngFor="let location of locations" [value]="location.value">
        {{ location.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
