/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import {
  GrpcMessage,
  RecursivePartial,
  ToProtobufJSONOptions,
} from '@ngx-grpc/common';
import { BinaryReader, BinaryWriter, ByteSource } from 'google-protobuf';
import * as googleProtobuf000 from '@ngx-grpc/well-known-types';
import * as storage001 from '../../../../src/main/proto/storage/customer-data-set-reference.pb';
export enum ColumnType {
  COLUMN_TYPE_UNSPECIFIED = 0,
  COLUMN_TYPE_STRING = 1,
  COLUMN_TYPE_NUMERIC = 2,
  COLUMN_TYPE_BOOLEAN = 3,
  COLUMN_TYPE_MATCH_KEY = 4,
  COLUMN_TYPE_TIME = 5,
  COLUMN_TYPE_ADVERTISER_EVENT_TYPE = 6,
  COLUMN_TYPE_PUBLISHER_EVENT_TYPE = 7,
}
/**
 * Message implementation for validation.ValidationConfig
 */
export class ValidationConfig implements GrpcMessage {
  static id = 'validation.ValidationConfig';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ValidationConfig();
    ValidationConfig.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ValidationConfig) {
    _instance.customerDataSet = _instance.customerDataSet || undefined;
    _instance.numShards = _instance.numShards || 0;
    _instance.expectedColumns = _instance.expectedColumns || {};
    _instance.emitSummaryStatistics = _instance.emitSummaryStatistics || false;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ValidationConfig,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 6:
          _instance.customerDataSet = new storage001.CustomerDataSetReference();
          _reader.readMessage(
            _instance.customerDataSet,
            storage001.CustomerDataSetReference.deserializeBinaryFromReader
          );
          break;
        case 5:
          _instance.numShards = _reader.readInt32();
          break;
        case 7:
          const msg_7 = {} as any;
          _reader.readMessage(
            msg_7,
            ValidationConfig.ExpectedColumnsEntry.deserializeBinaryFromReader
          );
          _instance.expectedColumns = _instance.expectedColumns || {};
          _instance.expectedColumns[msg_7.key] = msg_7.value;
          break;
        case 8:
          _instance.emitSummaryStatistics = _reader.readBool();
          break;
        default:
          _reader.skipField();
      }
    }

    ValidationConfig.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ValidationConfig,
    _writer: BinaryWriter
  ) {
    if (_instance.customerDataSet) {
      _writer.writeMessage(
        6,
        _instance.customerDataSet as any,
        storage001.CustomerDataSetReference.serializeBinaryToWriter
      );
    }
    if (_instance.numShards) {
      _writer.writeInt32(5, _instance.numShards);
    }
    if (!!_instance.expectedColumns) {
      const keys_7 = Object.keys(_instance.expectedColumns as any);

      if (keys_7.length) {
        const repeated_7 = keys_7
          .map((key) => ({
            key: key,
            value: (_instance.expectedColumns as any)[key],
          }))
          .reduce((r, v) => [...r, v], [] as any[]);

        _writer.writeRepeatedMessage(
          7,
          repeated_7,
          ValidationConfig.ExpectedColumnsEntry.serializeBinaryToWriter
        );
      }
    }
    if (_instance.emitSummaryStatistics) {
      _writer.writeBool(8, _instance.emitSummaryStatistics);
    }
  }

  private _customerDataSet?: storage001.CustomerDataSetReference;
  private _numShards: number;
  private _expectedColumns: { [prop: string]: ColumnType };
  private _emitSummaryStatistics: boolean;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ValidationConfig to deeply clone from
   */
  constructor(_value?: RecursivePartial<ValidationConfig.AsObject>) {
    _value = _value || {};
    this.customerDataSet = _value.customerDataSet
      ? new storage001.CustomerDataSetReference(_value.customerDataSet)
      : undefined;
    this.numShards = _value.numShards;
    (this.expectedColumns = _value!.expectedColumns
      ? Object.keys(_value!.expectedColumns).reduce(
          (r, k) => ({ ...r, [k]: _value!.expectedColumns![k] }),
          {}
        )
      : {}),
      (this.emitSummaryStatistics = _value.emitSummaryStatistics);
    ValidationConfig.refineValues(this);
  }
  get customerDataSet(): storage001.CustomerDataSetReference | undefined {
    return this._customerDataSet;
  }
  set customerDataSet(value: storage001.CustomerDataSetReference | undefined) {
    this._customerDataSet = value;
  }
  get numShards(): number {
    return this._numShards;
  }
  set numShards(value: number) {
    this._numShards = value;
  }
  get expectedColumns(): { [prop: string]: ColumnType } {
    return this._expectedColumns;
  }
  set expectedColumns(value: { [prop: string]: ColumnType }) {
    this._expectedColumns = value;
  }
  get emitSummaryStatistics(): boolean {
    return this._emitSummaryStatistics;
  }
  set emitSummaryStatistics(value: boolean) {
    this._emitSummaryStatistics = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ValidationConfig.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ValidationConfig.AsObject {
    return {
      customerDataSet: this.customerDataSet
        ? this.customerDataSet.toObject()
        : undefined,
      numShards: this.numShards,
      expectedColumns: this.expectedColumns
        ? Object.keys(this.expectedColumns).reduce(
            (r, k) => ({ ...r, [k]: this.expectedColumns![k] }),
            {}
          )
        : {},
      emitSummaryStatistics: this.emitSummaryStatistics,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ValidationConfig.AsProtobufJSON {
    return {
      customerDataSet: this.customerDataSet
        ? this.customerDataSet.toProtobufJSON(options)
        : null,
      numShards: this.numShards,
      expectedColumns: this.expectedColumns
        ? Object.keys(this.expectedColumns).reduce(
            (r, k) => ({ ...r, [k]: this.expectedColumns![k] }),
            {}
          )
        : {},
      emitSummaryStatistics: this.emitSummaryStatistics,
    };
  }
}
export module ValidationConfig {
  /**
   * Standard JavaScript object representation for ValidationConfig
   */
  export interface AsObject {
    customerDataSet?: storage001.CustomerDataSetReference.AsObject;
    numShards: number;
    expectedColumns: { [prop: string]: ColumnType };
    emitSummaryStatistics: boolean;
  }

  /**
   * Protobuf JSON representation for ValidationConfig
   */
  export interface AsProtobufJSON {
    customerDataSet: storage001.CustomerDataSetReference.AsProtobufJSON | null;
    numShards: number;
    expectedColumns: { [prop: string]: ColumnType };
    emitSummaryStatistics: boolean;
  }

  /**
   * Message implementation for validation.ValidationConfig.ExpectedColumnsEntry
   */
  export class ExpectedColumnsEntry implements GrpcMessage {
    static id = 'validation.ValidationConfig.ExpectedColumnsEntry';

    /**
     * Deserialize binary data to message
     * @param instance message instance
     */
    static deserializeBinary(bytes: ByteSource) {
      const instance = new ExpectedColumnsEntry();
      ExpectedColumnsEntry.deserializeBinaryFromReader(
        instance,
        new BinaryReader(bytes)
      );
      return instance;
    }

    /**
     * Check all the properties and set default protobuf values if necessary
     * @param _instance message instance
     */
    static refineValues(_instance: ExpectedColumnsEntry) {
      _instance.key = _instance.key || '';
      _instance.value = _instance.value || 0;
    }

    /**
     * Deserializes / reads binary message into message instance using provided binary reader
     * @param _instance message instance
     * @param _reader binary reader instance
     */
    static deserializeBinaryFromReader(
      _instance: ExpectedColumnsEntry,
      _reader: BinaryReader
    ) {
      while (_reader.nextField()) {
        if (_reader.isEndGroup()) break;

        switch (_reader.getFieldNumber()) {
          case 1:
            _instance.key = _reader.readString();
            break;
          case 2:
            _instance.value = _reader.readEnum();
            break;
          default:
            _reader.skipField();
        }
      }

      ExpectedColumnsEntry.refineValues(_instance);
    }

    /**
     * Serializes a message to binary format using provided binary reader
     * @param _instance message instance
     * @param _writer binary writer instance
     */
    static serializeBinaryToWriter(
      _instance: ExpectedColumnsEntry,
      _writer: BinaryWriter
    ) {
      if (_instance.key) {
        _writer.writeString(1, _instance.key);
      }
      if (_instance.value) {
        _writer.writeEnum(2, _instance.value);
      }
    }

    private _key: string;
    private _value: ColumnType;

    /**
     * Message constructor. Initializes the properties and applies default Protobuf values if necessary
     * @param _value initial values object or instance of ExpectedColumnsEntry to deeply clone from
     */
    constructor(_value?: RecursivePartial<ExpectedColumnsEntry.AsObject>) {
      _value = _value || {};
      this.key = _value.key;
      this.value = _value.value;
      ExpectedColumnsEntry.refineValues(this);
    }
    get key(): string {
      return this._key;
    }
    set key(value: string) {
      this._key = value;
    }
    get value(): ColumnType {
      return this._value;
    }
    set value(value: ColumnType) {
      this._value = value;
    }

    /**
     * Serialize message to binary data
     * @param instance message instance
     */
    serializeBinary() {
      const writer = new BinaryWriter();
      ExpectedColumnsEntry.serializeBinaryToWriter(this, writer);
      return writer.getResultBuffer();
    }

    /**
     * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
     */
    toObject(): ExpectedColumnsEntry.AsObject {
      return {
        key: this.key,
        value: this.value,
      };
    }

    /**
     * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
     */
    toJSON() {
      return this.toObject();
    }

    /**
     * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
     * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
     * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
     */
    toProtobufJSON(
      // @ts-ignore
      options?: ToProtobufJSONOptions
    ): ExpectedColumnsEntry.AsProtobufJSON {
      return {
        key: this.key,
        value:
          ColumnType[
            this.value === null || this.value === undefined ? 0 : this.value
          ],
      };
    }
  }
  export module ExpectedColumnsEntry {
    /**
     * Standard JavaScript object representation for ExpectedColumnsEntry
     */
    export interface AsObject {
      key: string;
      value: ColumnType;
    }

    /**
     * Protobuf JSON representation for ExpectedColumnsEntry
     */
    export interface AsProtobufJSON {
      key: string;
      value: string;
    }
  }
}
