<div class="background">
  <div class="card">
    <div class="card-header">
      <div class="header-section">
        <div class="header-close-btn">
          <button (click)="goBack()" class="close-btn">
            <mat-icon>close</mat-icon>
          </button>
        </div>
      </div>
      <hr />
    </div>

    <div class="body">
      <div class="body-title">
        <h1>Account selection</h1>
        <h2>
          Your user is associated with several accounts; kindly pick one to
          proceed the login.
        </h2>
      </div>

      <form
        ngNativeValidate
        (ngSubmit)="tenantSelectPopup()"
        [formGroup]="form"
      >
        <mat-form-field appearance="fill">
          <mat-label>Select an account</mat-label>
          <mat-select
            data-testid="customerSelect"
            name="customerSelect"
            formControlName="customerSelect"
            [(value)]="selectedTenantId"
          >
            <mat-option
              *ngFor="let tenant of tenantInfos"
              [value]="tenant.tenantId"
              >{{ tenant.companyName }}</mat-option
            >
          </mat-select>
        </mat-form-field>
        <button
          type="submit"
          color="primary"
          mat-flat-button
          data-testid="customerSubmit"
        >
          Select
        </button>
      </form>
    </div>
  </div>
</div>
