import { Injectable } from '@angular/core';
import { FirebaseApp } from '@angular/fire/app';
import { Auth, User } from '@angular/fire/auth';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from '@angular/fire/compat/firestore';
import {
  getMessaging,
  getToken,
  Messaging,
  onMessage,
} from '@angular/fire/messaging';
import {
  EventBusService,
  EventData,
  Events,
} from 'app/services/event-bus.service';
import { environment } from 'environments/environment';
import { Timestamp } from 'firebase/firestore';
import { firstValueFrom } from 'rxjs';

import { NotificationType } from '../../types/notification';
import { ApiAuthService } from './api-auth.service';
import {
  CreateRequest,
  CreateResponse,
} from './generated/src/main/proto/api/notification-service.pb';
import { NotificationServiceClient } from './generated/src/main/proto/api/notification-service.pbsc';
import {
  Notification as grpcNotification,
  NotificationTopic,
} from './generated/src/main/proto/storage/notification.pb';
import { LoggerService } from './logger.service';

/**
 * Service to manage notifications, including listening for, saving, and sending notifications.
 */
@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  messaging: Messaging;
  private currentUser: User | undefined;

  constructor(
    public afs: AngularFirestore,
    private analytics: AngularFireAnalytics,
    private auth: Auth,
    public firebaseApp: FirebaseApp,
    private events: EventBusService,
    private logger: LoggerService,
    private apiAuthService: ApiAuthService,
    private notificationServiceClient: NotificationServiceClient
  ) {
    // Initialize Firebase messaging and set up authentication state listener
    // https://firebase.google.com/docs/auth/web/manage-users#get_the_currently_signed-in_user
    this.messaging = getMessaging(this.firebaseApp);
    this.auth.onAuthStateChanged((user) => {
      if (user) {
        this.currentUser = user;
        this.logger.info('Logged in');
      } else {
        this.logger.info('Not logged in');
      }
    });
  }

  /**
   * Listens for incoming messages and handles notification storage and event emission.
   */
  public listen() {
    onMessage(this.messaging, async (payload) => {
      const notification: NotificationType = {
        receivedAt: Timestamp.now(),
        expiresAt: Timestamp.fromMillis(Date.now() + 2592000000),
        viewed: false,
        messagePayload: payload,
      };
      await this.saveNotification(notification);
      this.events.emit(
        new EventData(Events.NotificationReceived, notification)
      );
    });
  }

  /**
   * Requests permission to show notifications and retrieves a registration token.
   */
  public requestPermission() {
    Notification.requestPermission().then((permission) => {
      if (permission == 'granted') {
        getToken(this.messaging, { vapidKey: environment.firebase.vapidKey })
          .then(async (currentToken) => {
            if (currentToken) {
              if (environment.firebase.outputNotificationToken) {
                this.logger.info('current token for client: ', currentToken);
              }
              await this.updateToken(currentToken);
            } else {
              this.logger.info(
                'No registration token available. Request permission to generate one.'
              );
            }
          })
          .catch(() => {
            this.logger.info(
              'An error occurred while retrieving token. User might not be logged in.'
            );
          });
      }
    });
  }

  /**
   * Retrieves notifications for the current user.
   *
   * @returns A promise that resolves to the list of notifications.
   */
  public async getNotifications() {
    this.analytics.logEvent('get-notifications');
    const notificationRef: AngularFirestoreDocument<unknown> = this.afs.doc(
      `notification/${this.currentUser?.tenantId}/users/${this.currentUser?.uid}`
    );
    const notifications = await firstValueFrom(
      notificationRef.collection('messages').valueChanges()
    );
    return notifications.sort((a, b) => b['receivedAt'] - a['receivedAt']);
  }

  /**
   * Saves a notification to the Firestore.
   *
   * @param notification - The notification to save.
   * @returns A promise that resolves when the notification is saved.
   */
  public saveNotification(notification: NotificationType) {
    if (this.currentUser) {
      this.analytics.logEvent('save-notification', {
        'tenant-id': this.currentUser.tenantId,
      });
      const notificationRef: AngularFirestoreDocument<unknown> = this.afs.doc(
        `notification/${this.currentUser?.tenantId}/users/${this.currentUser?.uid}/messages/${notification.messagePayload.messageId}`
      );
      notification.messagePayload.collapseKey = '';

      return notificationRef.set(notification, { merge: true });
    } else {
      return Promise.reject('user-not-logged-in');
    }
  }

  /**
   * Updates the notification token for the current user.
   *
   * @param token - The new notification token.
   * @returns A promise that resolves when the token is updated.
   */
  public updateToken(token: string) {
    if (this.currentUser)
      this.analytics.logEvent('save-notification', {
        'tenant-id': this.currentUser.tenantId,
      });
    const notificationRef: AngularFirestoreDocument<unknown> = this.afs.doc(
      `notification/${this.currentUser?.tenantId}/users/${this.currentUser?.uid}`
    );

    // For the time being, auto-subscribe users to topic general.
    return notificationRef.set(
      { token: token, topic: [NotificationTopic.NOTIFICATION_TOPIC_GENERAL] },
      { merge: true }
    );
  }

  /**
   * Sends a notification.
   *
   * @param notification - The notification to send.
   * @returns A promise that resolves to a CreateResponse containing the result of the send operation.
   */
  async sendNotification(
    notification: grpcNotification
  ): Promise<CreateResponse> {
    const grpcMetaData =
      await this.apiAuthService.getAuthenticatedRequestHeader();

    const createRequest = new CreateRequest();
    createRequest.notifications = [notification];

    const createResponse = this.notificationServiceClient.send(
      createRequest,
      grpcMetaData
    );

    if (this.currentUser) {
      this.analytics.logEvent('send-notification', {
        'tenant-id': this.currentUser.tenantId,
      });
    }
    return await firstValueFrom(createResponse);
  }
}
