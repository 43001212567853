import { Component, HostBinding, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

type State = 'approved' | 'disapproved';

@Component({
  selector: 'app-decision-flag',
  templateUrl: './decision-flag.component.html',
  styleUrls: ['./decision-flag.component.scss'],
  standalone: true,
  imports: [MatIconModule],
})
export class DecisionFlagComponent {
  icon = 'check_circle';
  @Input()
  set state(state: State) {
    this._state = state;
    this.icon = { approved: 'check_circle', disapproved: 'cancel' }[state];
  }
  get state() {
    return this._state;
  }
  private _state: State = 'approved';

  @HostBinding('class') get className() {
    return this.state;
  }
}
