/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import {
  GrpcMessage,
  RecursivePartial,
  ToProtobufJSONOptions,
} from '@ngx-grpc/common';
import { BinaryReader, BinaryWriter, ByteSource } from 'google-protobuf';
import * as api000 from '../../../../src/main/proto/api/headers.pb';
export enum Permission {
  PERMISSION_UNSPECIFIED = 0,
  PERMISSION_APPROVAL_DECISION_WRITE = 1,
  PERMISSION_APPROVAL_DECISION_WRITE_MANAGED_CUSTOMERS = 2,
  PERMISSION_APPROVAL_REQUEST_READ = 3,
  PERMISSION_APPROVAL_REQUEST_READ_ALL_CUSTOMERS = 4,
  PERMISSION_APPROVAL_REQUEST_WRITE = 5,
  PERMISSION_APPROVAL_UPDATE_POLICY_STATE = 6,
  PERMISSION_APPROVAL_UPDATE_VIEWERS = 7,
  PERMISSION_APPROVAL_DECISION_WRITE_TEST = 8,
  PERMISSION_BINARY_TYPES_READ = 9,
  PERMISSION_CONTENT_TYPE_READ = 10,
  PERMISSION_CONTENT_READ = 11,
  PERMISSION_CUSTOMER_APP_ASSIGN_ROLES = 36,
  PERMISSION_CUSTOMER_APP_READ = 37,
  PERMISSION_CUSTOMER_APP_WRITE = 38,
  PERMISSION_CUSTOMER_APP_ROTATE_KEY = 39,
  PERMISSION_CUSTOMER_READ = 12,
  PERMISSION_CUSTOMER_SELF_READ = 13,
  PERMISSION_CUSTOMER_WRITE = 14,
  PERMISSION_DATA_SET_ARCHIVE = 15,
  PERMISSION_DATA_SET_CREATE = 16,
  PERMISSION_DATA_SET_FINALIZE = 17,
  PERMISSION_DATA_SET_READ = 18,
  PERMISSION_DATA_SET_READ_ALL = 19,
  PERMISSION_DATA_SET_TRIGGER = 47,
  PERMISSION_DATA_SET_START_EXTERNAL_TRANSFER = 45,
  PERMISSION_JOB_LOG_READ = 21,
  PERMISSION_JOB_LOG_READ_ALL = 51,
  PERMISSION_JOB_LOG_READ_RESULTS = 52,
  PERMISSION_JOB_LOG_WRITE = 22,
  PERMISSION_JOB_SCHEDULE_READ = 48,
  PERMISSION_JOB_SCHEDULE_READ_ALL = 49,
  PERMISSION_JOB_SCHEDULE_WRITE = 50,
  PERMISSION_KEY_EXPIRED_VERSIONS_DELETE = 23,
  PERMISSION_KEY_LIST_NAMES = 24,
  PERMISSION_KEY_READ = 25,
  PERMISSION_KEY_READ_PUBLIC_KEY = 26,
  PERMISSION_KEYS_MANAGE = 27,
  PERMISSION_KEYS_READ = 28,
  PERMISSION_KEYS_READ_ALL = 29,
  PERMISSION_MANAGED_CUSTOMER_WRITE = 30,
  PERMISSION_PUBLIC_SAS_TOKEN_GET = 40,
  PERMISSION_STORAGE_CREDENTIAL_ARCHIVE = 46,
  PERMISSION_STORAGE_CREDENTIAL_CREATE = 41,
  PERMISSION_STORAGE_CREDENTIAL_READ = 42,
  PERMISSION_STORAGE_CREDENTIAL_READ_ALL = 43,
  PERMISSION_USER_PASSWORD_SET = 31,
  PERMISSION_USER_PARTNER_USER_WRITE = 33,
  PERMISSION_USER_READ = 34,
  PERMISSION_ANONYM_USER_WRITE = 35,
}
export enum ContentType {
  CONTENT_TYPE_UNSPECIFIED = 0,
  CONTENT_TYPE_PORTAL_LOGIN = 1,
  CONTENT_TYPE_SYSTEM_OVERVIEW = 2,
  CONTENT_TYPE_SECURITY_RESOURCES = 3,
  CONTENT_TYPE_AUDIT_RESOURCES = 4,
  CONTENT_TYPE_AUDIT_APPROVAL = 5,
  CONTENT_TYPE_HISTORY = 6,
  CONTENT_TYPE_ATTRIBUTION_BINARY = 7,
  CONTENT_TYPE_LIFT_BINARY = 8,
}
/**
 * Message implementation for api.auth.GetAllowedContentTypesRequest
 */
export class GetAllowedContentTypesRequest implements GrpcMessage {
  static id = 'api.auth.GetAllowedContentTypesRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetAllowedContentTypesRequest();
    GetAllowedContentTypesRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetAllowedContentTypesRequest) {
    _instance.header = _instance.header || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetAllowedContentTypesRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api000.RequestHeader();
          _reader.readMessage(
            _instance.header,
            api000.RequestHeader.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    GetAllowedContentTypesRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetAllowedContentTypesRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api000.RequestHeader.serializeBinaryToWriter
      );
    }
  }

  private _header?: api000.RequestHeader;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetAllowedContentTypesRequest to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<GetAllowedContentTypesRequest.AsObject>
  ) {
    _value = _value || {};
    this.header = _value.header
      ? new api000.RequestHeader(_value.header)
      : undefined;
    GetAllowedContentTypesRequest.refineValues(this);
  }
  get header(): api000.RequestHeader | undefined {
    return this._header;
  }
  set header(value: api000.RequestHeader | undefined) {
    this._header = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetAllowedContentTypesRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetAllowedContentTypesRequest.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetAllowedContentTypesRequest.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
    };
  }
}
export module GetAllowedContentTypesRequest {
  /**
   * Standard JavaScript object representation for GetAllowedContentTypesRequest
   */
  export interface AsObject {
    header?: api000.RequestHeader.AsObject;
  }

  /**
   * Protobuf JSON representation for GetAllowedContentTypesRequest
   */
  export interface AsProtobufJSON {
    header: api000.RequestHeader.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for api.auth.GetAllowedContentTypesResponse
 */
export class GetAllowedContentTypesResponse implements GrpcMessage {
  static id = 'api.auth.GetAllowedContentTypesResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetAllowedContentTypesResponse();
    GetAllowedContentTypesResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetAllowedContentTypesResponse) {
    _instance.header = _instance.header || undefined;
    _instance.contentTypes = _instance.contentTypes || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetAllowedContentTypesResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api000.ResponseHeader();
          _reader.readMessage(
            _instance.header,
            api000.ResponseHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          (_instance.contentTypes = _instance.contentTypes || []).push(
            ...(_reader.readPackedEnum() || [])
          );
          break;
        default:
          _reader.skipField();
      }
    }

    GetAllowedContentTypesResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetAllowedContentTypesResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api000.ResponseHeader.serializeBinaryToWriter
      );
    }
    if (_instance.contentTypes && _instance.contentTypes.length) {
      _writer.writePackedEnum(2, _instance.contentTypes);
    }
  }

  private _header?: api000.ResponseHeader;
  private _contentTypes: ContentType[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetAllowedContentTypesResponse to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<GetAllowedContentTypesResponse.AsObject>
  ) {
    _value = _value || {};
    this.header = _value.header
      ? new api000.ResponseHeader(_value.header)
      : undefined;
    this.contentTypes = (_value.contentTypes || []).slice();
    GetAllowedContentTypesResponse.refineValues(this);
  }
  get header(): api000.ResponseHeader | undefined {
    return this._header;
  }
  set header(value: api000.ResponseHeader | undefined) {
    this._header = value;
  }
  get contentTypes(): ContentType[] {
    return this._contentTypes;
  }
  set contentTypes(value: ContentType[]) {
    this._contentTypes = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetAllowedContentTypesResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetAllowedContentTypesResponse.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      contentTypes: (this.contentTypes || []).slice(),
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetAllowedContentTypesResponse.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      contentTypes: (this.contentTypes || []).map((v) => ContentType[v]),
    };
  }
}
export module GetAllowedContentTypesResponse {
  /**
   * Standard JavaScript object representation for GetAllowedContentTypesResponse
   */
  export interface AsObject {
    header?: api000.ResponseHeader.AsObject;
    contentTypes: ContentType[];
  }

  /**
   * Protobuf JSON representation for GetAllowedContentTypesResponse
   */
  export interface AsProtobufJSON {
    header: api000.ResponseHeader.AsProtobufJSON | null;
    contentTypes: string[];
  }
}

/**
 * Message implementation for api.auth.AuthConfig
 */
export class AuthConfig implements GrpcMessage {
  static id = 'api.auth.AuthConfig';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new AuthConfig();
    AuthConfig.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: AuthConfig) {
    _instance.roles = _instance.roles || {};
    _instance.binaryContentTypes = _instance.binaryContentTypes || {};
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: AuthConfig,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          const msg_1 = {} as any;
          _reader.readMessage(
            msg_1,
            AuthConfig.RolesEntry.deserializeBinaryFromReader
          );
          _instance.roles = _instance.roles || {};
          _instance.roles[msg_1.key] = msg_1.value;
          break;
        case 2:
          const msg_2 = {} as any;
          _reader.readMessage(
            msg_2,
            AuthConfig.BinaryContentTypesEntry.deserializeBinaryFromReader
          );
          _instance.binaryContentTypes = _instance.binaryContentTypes || {};
          _instance.binaryContentTypes[msg_2.key] = msg_2.value;
          break;
        default:
          _reader.skipField();
      }
    }

    AuthConfig.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(_instance: AuthConfig, _writer: BinaryWriter) {
    if (!!_instance.roles) {
      const keys_1 = Object.keys(_instance.roles as any);

      if (keys_1.length) {
        const repeated_1 = keys_1
          .map((key) => ({ key: key, value: (_instance.roles as any)[key] }))
          .reduce((r, v) => [...r, v], [] as any[]);

        _writer.writeRepeatedMessage(
          1,
          repeated_1,
          AuthConfig.RolesEntry.serializeBinaryToWriter
        );
      }
    }
    if (!!_instance.binaryContentTypes) {
      const keys_2 = Object.keys(_instance.binaryContentTypes as any);

      if (keys_2.length) {
        const repeated_2 = keys_2
          .map((key) => ({
            key: key,
            value: (_instance.binaryContentTypes as any)[key],
          }))
          .reduce((r, v) => [...r, v], [] as any[]);

        _writer.writeRepeatedMessage(
          2,
          repeated_2,
          AuthConfig.BinaryContentTypesEntry.serializeBinaryToWriter
        );
      }
    }
  }

  private _roles: { [prop: string]: Role };
  private _binaryContentTypes: { [prop: string]: BinaryContentTypes };

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of AuthConfig to deeply clone from
   */
  constructor(_value?: RecursivePartial<AuthConfig.AsObject>) {
    _value = _value || {};
    (this.roles = _value!.roles
      ? Object.keys(_value!.roles).reduce(
          (r, k) => ({
            ...r,
            [k]: _value!.roles![k] ? new Role(_value!.roles![k]) : undefined,
          }),
          {}
        )
      : {}),
      (this.binaryContentTypes = _value!.binaryContentTypes
        ? Object.keys(_value!.binaryContentTypes).reduce(
            (r, k) => ({
              ...r,
              [k]: _value!.binaryContentTypes![k]
                ? new BinaryContentTypes(_value!.binaryContentTypes![k])
                : undefined,
            }),
            {}
          )
        : {}),
      AuthConfig.refineValues(this);
  }
  get roles(): { [prop: string]: Role } {
    return this._roles;
  }
  set roles(value: { [prop: string]: Role }) {
    this._roles = value;
  }
  get binaryContentTypes(): { [prop: string]: BinaryContentTypes } {
    return this._binaryContentTypes;
  }
  set binaryContentTypes(value: { [prop: string]: BinaryContentTypes }) {
    this._binaryContentTypes = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    AuthConfig.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): AuthConfig.AsObject {
    return {
      roles: this.roles
        ? Object.keys(this.roles).reduce(
            (r, k) => ({
              ...r,
              [k]: this.roles![k] ? this.roles![k].toObject() : undefined,
            }),
            {}
          )
        : {},
      binaryContentTypes: this.binaryContentTypes
        ? Object.keys(this.binaryContentTypes).reduce(
            (r, k) => ({
              ...r,
              [k]: this.binaryContentTypes![k]
                ? this.binaryContentTypes![k].toObject()
                : undefined,
            }),
            {}
          )
        : {},
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): AuthConfig.AsProtobufJSON {
    return {
      roles: this.roles
        ? Object.keys(this.roles).reduce(
            (r, k) => ({
              ...r,
              [k]: this.roles![k] ? this.roles![k].toJSON() : null,
            }),
            {}
          )
        : {},
      binaryContentTypes: this.binaryContentTypes
        ? Object.keys(this.binaryContentTypes).reduce(
            (r, k) => ({
              ...r,
              [k]: this.binaryContentTypes![k]
                ? this.binaryContentTypes![k].toJSON()
                : null,
            }),
            {}
          )
        : {},
    };
  }
}
export module AuthConfig {
  /**
   * Standard JavaScript object representation for AuthConfig
   */
  export interface AsObject {
    roles: { [prop: string]: Role };
    binaryContentTypes: { [prop: string]: BinaryContentTypes };
  }

  /**
   * Protobuf JSON representation for AuthConfig
   */
  export interface AsProtobufJSON {
    roles: { [prop: string]: Role };
    binaryContentTypes: { [prop: string]: BinaryContentTypes };
  }

  /**
   * Message implementation for api.auth.AuthConfig.RolesEntry
   */
  export class RolesEntry implements GrpcMessage {
    static id = 'api.auth.AuthConfig.RolesEntry';

    /**
     * Deserialize binary data to message
     * @param instance message instance
     */
    static deserializeBinary(bytes: ByteSource) {
      const instance = new RolesEntry();
      RolesEntry.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
      return instance;
    }

    /**
     * Check all the properties and set default protobuf values if necessary
     * @param _instance message instance
     */
    static refineValues(_instance: RolesEntry) {
      _instance.key = _instance.key || '';
      _instance.value = _instance.value || undefined;
    }

    /**
     * Deserializes / reads binary message into message instance using provided binary reader
     * @param _instance message instance
     * @param _reader binary reader instance
     */
    static deserializeBinaryFromReader(
      _instance: RolesEntry,
      _reader: BinaryReader
    ) {
      while (_reader.nextField()) {
        if (_reader.isEndGroup()) break;

        switch (_reader.getFieldNumber()) {
          case 1:
            _instance.key = _reader.readString();
            break;
          case 2:
            _instance.value = new Role();
            _reader.readMessage(
              _instance.value,
              Role.deserializeBinaryFromReader
            );
            break;
          default:
            _reader.skipField();
        }
      }

      RolesEntry.refineValues(_instance);
    }

    /**
     * Serializes a message to binary format using provided binary reader
     * @param _instance message instance
     * @param _writer binary writer instance
     */
    static serializeBinaryToWriter(
      _instance: RolesEntry,
      _writer: BinaryWriter
    ) {
      if (_instance.key) {
        _writer.writeString(1, _instance.key);
      }
      if (_instance.value) {
        _writer.writeMessage(
          2,
          _instance.value as any,
          Role.serializeBinaryToWriter
        );
      }
    }

    private _key: string;
    private _value?: Role;

    /**
     * Message constructor. Initializes the properties and applies default Protobuf values if necessary
     * @param _value initial values object or instance of RolesEntry to deeply clone from
     */
    constructor(_value?: RecursivePartial<RolesEntry.AsObject>) {
      _value = _value || {};
      this.key = _value.key;
      this.value = _value.value ? new Role(_value.value) : undefined;
      RolesEntry.refineValues(this);
    }
    get key(): string {
      return this._key;
    }
    set key(value: string) {
      this._key = value;
    }
    get value(): Role | undefined {
      return this._value;
    }
    set value(value: Role | undefined) {
      this._value = value;
    }

    /**
     * Serialize message to binary data
     * @param instance message instance
     */
    serializeBinary() {
      const writer = new BinaryWriter();
      RolesEntry.serializeBinaryToWriter(this, writer);
      return writer.getResultBuffer();
    }

    /**
     * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
     */
    toObject(): RolesEntry.AsObject {
      return {
        key: this.key,
        value: this.value ? this.value.toObject() : undefined,
      };
    }

    /**
     * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
     */
    toJSON() {
      return this.toObject();
    }

    /**
     * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
     * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
     * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
     */
    toProtobufJSON(
      // @ts-ignore
      options?: ToProtobufJSONOptions
    ): RolesEntry.AsProtobufJSON {
      return {
        key: this.key,
        value: this.value ? this.value.toProtobufJSON(options) : null,
      };
    }
  }
  export module RolesEntry {
    /**
     * Standard JavaScript object representation for RolesEntry
     */
    export interface AsObject {
      key: string;
      value?: Role.AsObject;
    }

    /**
     * Protobuf JSON representation for RolesEntry
     */
    export interface AsProtobufJSON {
      key: string;
      value: Role.AsProtobufJSON | null;
    }
  }

  /**
   * Message implementation for api.auth.AuthConfig.BinaryContentTypesEntry
   */
  export class BinaryContentTypesEntry implements GrpcMessage {
    static id = 'api.auth.AuthConfig.BinaryContentTypesEntry';

    /**
     * Deserialize binary data to message
     * @param instance message instance
     */
    static deserializeBinary(bytes: ByteSource) {
      const instance = new BinaryContentTypesEntry();
      BinaryContentTypesEntry.deserializeBinaryFromReader(
        instance,
        new BinaryReader(bytes)
      );
      return instance;
    }

    /**
     * Check all the properties and set default protobuf values if necessary
     * @param _instance message instance
     */
    static refineValues(_instance: BinaryContentTypesEntry) {
      _instance.key = _instance.key || '';
      _instance.value = _instance.value || undefined;
    }

    /**
     * Deserializes / reads binary message into message instance using provided binary reader
     * @param _instance message instance
     * @param _reader binary reader instance
     */
    static deserializeBinaryFromReader(
      _instance: BinaryContentTypesEntry,
      _reader: BinaryReader
    ) {
      while (_reader.nextField()) {
        if (_reader.isEndGroup()) break;

        switch (_reader.getFieldNumber()) {
          case 1:
            _instance.key = _reader.readString();
            break;
          case 2:
            _instance.value = new BinaryContentTypes();
            _reader.readMessage(
              _instance.value,
              BinaryContentTypes.deserializeBinaryFromReader
            );
            break;
          default:
            _reader.skipField();
        }
      }

      BinaryContentTypesEntry.refineValues(_instance);
    }

    /**
     * Serializes a message to binary format using provided binary reader
     * @param _instance message instance
     * @param _writer binary writer instance
     */
    static serializeBinaryToWriter(
      _instance: BinaryContentTypesEntry,
      _writer: BinaryWriter
    ) {
      if (_instance.key) {
        _writer.writeString(1, _instance.key);
      }
      if (_instance.value) {
        _writer.writeMessage(
          2,
          _instance.value as any,
          BinaryContentTypes.serializeBinaryToWriter
        );
      }
    }

    private _key: string;
    private _value?: BinaryContentTypes;

    /**
     * Message constructor. Initializes the properties and applies default Protobuf values if necessary
     * @param _value initial values object or instance of BinaryContentTypesEntry to deeply clone from
     */
    constructor(_value?: RecursivePartial<BinaryContentTypesEntry.AsObject>) {
      _value = _value || {};
      this.key = _value.key;
      this.value = _value.value
        ? new BinaryContentTypes(_value.value)
        : undefined;
      BinaryContentTypesEntry.refineValues(this);
    }
    get key(): string {
      return this._key;
    }
    set key(value: string) {
      this._key = value;
    }
    get value(): BinaryContentTypes | undefined {
      return this._value;
    }
    set value(value: BinaryContentTypes | undefined) {
      this._value = value;
    }

    /**
     * Serialize message to binary data
     * @param instance message instance
     */
    serializeBinary() {
      const writer = new BinaryWriter();
      BinaryContentTypesEntry.serializeBinaryToWriter(this, writer);
      return writer.getResultBuffer();
    }

    /**
     * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
     */
    toObject(): BinaryContentTypesEntry.AsObject {
      return {
        key: this.key,
        value: this.value ? this.value.toObject() : undefined,
      };
    }

    /**
     * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
     */
    toJSON() {
      return this.toObject();
    }

    /**
     * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
     * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
     * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
     */
    toProtobufJSON(
      // @ts-ignore
      options?: ToProtobufJSONOptions
    ): BinaryContentTypesEntry.AsProtobufJSON {
      return {
        key: this.key,
        value: this.value ? this.value.toProtobufJSON(options) : null,
      };
    }
  }
  export module BinaryContentTypesEntry {
    /**
     * Standard JavaScript object representation for BinaryContentTypesEntry
     */
    export interface AsObject {
      key: string;
      value?: BinaryContentTypes.AsObject;
    }

    /**
     * Protobuf JSON representation for BinaryContentTypesEntry
     */
    export interface AsProtobufJSON {
      key: string;
      value: BinaryContentTypes.AsProtobufJSON | null;
    }
  }
}

/**
 * Message implementation for api.auth.Role
 */
export class Role implements GrpcMessage {
  static id = 'api.auth.Role';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new Role();
    Role.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: Role) {
    _instance.description = _instance.description || '';
    _instance.permissions = _instance.permissions || [];
    _instance.contentTypes = _instance.contentTypes || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(_instance: Role, _reader: BinaryReader) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.description = _reader.readString();
          break;
        case 2:
          (_instance.permissions = _instance.permissions || []).push(
            ...(_reader.readPackedEnum() || [])
          );
          break;
        case 3:
          (_instance.contentTypes = _instance.contentTypes || []).push(
            ...(_reader.readPackedEnum() || [])
          );
          break;
        default:
          _reader.skipField();
      }
    }

    Role.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(_instance: Role, _writer: BinaryWriter) {
    if (_instance.description) {
      _writer.writeString(1, _instance.description);
    }
    if (_instance.permissions && _instance.permissions.length) {
      _writer.writePackedEnum(2, _instance.permissions);
    }
    if (_instance.contentTypes && _instance.contentTypes.length) {
      _writer.writePackedEnum(3, _instance.contentTypes);
    }
  }

  private _description: string;
  private _permissions: Permission[];
  private _contentTypes: ContentType[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of Role to deeply clone from
   */
  constructor(_value?: RecursivePartial<Role.AsObject>) {
    _value = _value || {};
    this.description = _value.description;
    this.permissions = (_value.permissions || []).slice();
    this.contentTypes = (_value.contentTypes || []).slice();
    Role.refineValues(this);
  }
  get description(): string {
    return this._description;
  }
  set description(value: string) {
    this._description = value;
  }
  get permissions(): Permission[] {
    return this._permissions;
  }
  set permissions(value: Permission[]) {
    this._permissions = value;
  }
  get contentTypes(): ContentType[] {
    return this._contentTypes;
  }
  set contentTypes(value: ContentType[]) {
    this._contentTypes = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    Role.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): Role.AsObject {
    return {
      description: this.description,
      permissions: (this.permissions || []).slice(),
      contentTypes: (this.contentTypes || []).slice(),
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): Role.AsProtobufJSON {
    return {
      description: this.description,
      permissions: (this.permissions || []).map((v) => Permission[v]),
      contentTypes: (this.contentTypes || []).map((v) => ContentType[v]),
    };
  }
}
export module Role {
  /**
   * Standard JavaScript object representation for Role
   */
  export interface AsObject {
    description: string;
    permissions: Permission[];
    contentTypes: ContentType[];
  }

  /**
   * Protobuf JSON representation for Role
   */
  export interface AsProtobufJSON {
    description: string;
    permissions: string[];
    contentTypes: string[];
  }
}

/**
 * Message implementation for api.auth.BinaryContentTypes
 */
export class BinaryContentTypes implements GrpcMessage {
  static id = 'api.auth.BinaryContentTypes';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new BinaryContentTypes();
    BinaryContentTypes.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: BinaryContentTypes) {
    _instance.contentTypes = _instance.contentTypes || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: BinaryContentTypes,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          (_instance.contentTypes = _instance.contentTypes || []).push(
            ...(_reader.readPackedEnum() || [])
          );
          break;
        default:
          _reader.skipField();
      }
    }

    BinaryContentTypes.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: BinaryContentTypes,
    _writer: BinaryWriter
  ) {
    if (_instance.contentTypes && _instance.contentTypes.length) {
      _writer.writePackedEnum(1, _instance.contentTypes);
    }
  }

  private _contentTypes: ContentType[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of BinaryContentTypes to deeply clone from
   */
  constructor(_value?: RecursivePartial<BinaryContentTypes.AsObject>) {
    _value = _value || {};
    this.contentTypes = (_value.contentTypes || []).slice();
    BinaryContentTypes.refineValues(this);
  }
  get contentTypes(): ContentType[] {
    return this._contentTypes;
  }
  set contentTypes(value: ContentType[]) {
    this._contentTypes = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    BinaryContentTypes.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): BinaryContentTypes.AsObject {
    return {
      contentTypes: (this.contentTypes || []).slice(),
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): BinaryContentTypes.AsProtobufJSON {
    return {
      contentTypes: (this.contentTypes || []).map((v) => ContentType[v]),
    };
  }
}
export module BinaryContentTypes {
  /**
   * Standard JavaScript object representation for BinaryContentTypes
   */
  export interface AsObject {
    contentTypes: ContentType[];
  }

  /**
   * Protobuf JSON representation for BinaryContentTypes
   */
  export interface AsProtobufJSON {
    contentTypes: string[];
  }
}
