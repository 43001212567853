<div class="container" [ngClass]="user && 'container--has-top-nav'">
  <app-top-navigation
    *ngIf="user"
    [userName]="user.displayName"
    [userPhotoUrl]="user.photoURL"
  ></app-top-navigation>
  <app-sidebar *ngIf="user"></app-sidebar>
  <div [class]="user && 'content'">
    <router-outlet></router-outlet>
  </div>
</div>
