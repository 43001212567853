<app-container>
  <app-message-box></app-message-box>
  <mat-spinner
    data-testid="contentSpinner"
    *ngIf="isLoading"
    diameter="24"
  ></mat-spinner>
  <div class="frame">
    <markdown class="content" [data]="markdown" clipboard></markdown>

    <ng-container *ngFor="let publicKey of publicKeys">
      <app-key-renderer
        class="key-renderer"
        keyId="{{ publicKey.keyId }}"
        title="{{ publicKey.title }}"
      ></app-key-renderer>
    </ng-container>
    <ng-container *ngIf="publicKeys.length === 0">
      <div class="no-key">
        <h2>Keys have not been created for this customer.</h2>
        <div class="label">
          <p>
            Please contact support at
            <a href="mailto:contact@anonymco.com">contact&#64;anonymco.com</a>
            to generate a new key.
          </p>
        </div>
      </div>
    </ng-container>
  </div>
</app-container>
