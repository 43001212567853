import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CompanyAccountCardComponent } from 'app/views/shared/components/company-account-card/company-account-card.component';
import { PageHeaderComponent } from 'app/views/shared/components/page-header/page-header.component';

import { MultifactorSetupModalComponent } from '../../modals/multifactor-setup-modal.component/multifactor-setup-modal.component';
import { UserProfileEditModalComponent } from '../../modals/user-profile-edit-modal/user-profile-edit-modal.component';
import { UserService } from '../../services/user.service';
import { AccountComponent } from '../../views/account/account.component';
import { ContainerComponent } from '../../views/shared/components/container/container.component';
import { MessageBoxComponent } from '../../views/shared/components/message-box/message-box.component';

@NgModule({
  declarations: [
    AccountComponent,
    UserProfileEditModalComponent,
    MultifactorSetupModalComponent,
  ],
  providers: [UserService],
  exports: [
    AccountComponent,
    FormsModule,
    ReactiveFormsModule,
    UserProfileEditModalComponent,
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatInputModule,
    MatCardModule,
    FormsModule,
    ReactiveFormsModule,
    ContainerComponent,
    MatGridListModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    CompanyAccountCardComponent,
    PageHeaderComponent,
    MessageBoxComponent,
    MatButtonModule,
  ],
})
export class UserProfileModule {}
