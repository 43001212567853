import { Injectable } from '@angular/core';

import { MessageType, OnCloseCallback } from './message-box.component';

@Injectable()
export class MessageBoxProvider {
  /*eslint-disable */
  show(
    message: string,
    messageType: MessageType = 'success',
    onClose: OnCloseCallback = undefined,
    duration = 5000
  ): void {}

  success(
    message: string,
    onClose: OnCloseCallback = undefined,
    duration = 5000
  ): void {}

  error(
    message: string,
    onClose: OnCloseCallback = undefined,
    duration = 5000
  ): void {}

  hide() {}
  /*eslint-enable */
}
