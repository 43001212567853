/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
export enum Location {
  LOCATION_UNSPECIFIED = 0,
  LOCATION_CUS = 1,
  LOCATION_JPE = 2,
  LOCATION_NEU = 3,
}
