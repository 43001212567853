import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AddUserModalComponent } from 'app/modals/add-user-modal/add-user-modal.component';
import { SettingsPageComponent } from 'app/views/settings-page/settings-page.component';
import { CompanyAccountCardComponent } from 'app/views/shared/components/company-account-card/company-account-card.component';
import { MessageBoxComponent } from 'app/views/shared/components/message-box/message-box.component';
import { MessageBoxProvider } from 'app/views/shared/components/message-box/message-box.provider';
import { PageHeaderComponent } from 'app/views/shared/components/page-header/page-header.component';

import { UserService } from '../../services/user.service';
import { ContainerComponent } from '../../views/shared/components/container/container.component';

@NgModule({
  declarations: [SettingsPageComponent, AddUserModalComponent],
  imports: [
    CommonModule,
    MatIconModule,
    MatInputModule,
    MatCardModule,
    FormsModule,
    ReactiveFormsModule,
    ContainerComponent,
    MatGridListModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    CompanyAccountCardComponent,
    MatTableModule,
    MatTooltipModule,
    MatSlideToggleModule,
    PageHeaderComponent,
    MessageBoxComponent,
    MatSortModule,
  ],
  providers: [UserService, MessageBoxProvider],
  exports: [
    SettingsPageComponent,
    FormsModule,
    ReactiveFormsModule,
    CompanyAccountCardComponent,
    AddUserModalComponent,
  ],
})
export class SettingsModule {}
