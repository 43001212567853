import { Component, Inject } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LocationList } from 'app/constants/lookups';
import { FormHelpersService } from 'app/services/form-helpers.service';
import { Location } from 'app/services/generated/src/main/proto/storage/commons.pb';

import { BinaryTypeService } from '../../services/binary-type.service';
import { CustomerService } from '../../services/customer.service';
import { BinaryType } from '../../services/generated/src/main/proto/storage/binary-type.pb';
import {
  Customer,
  CustomerType,
  SamlProviderConfig,
} from '../../services/generated/src/main/proto/storage/customer.pb';
import { LoggerService } from '../../services/logger.service';
import { UserService } from '../../services/user.service';
import { MessageBoxProvider } from '../../views/shared/components/message-box/message-box.provider';

@Component({
  selector: 'app-add-customer-modal',
  templateUrl: './add-customer-modal.component.html',
  styleUrls: [
    './add-customer-modal.component.scss',
    '../../shared/shared.scss',
  ],
})
export class AddCustomerModalComponent {
  UPDATE_CUSTOMER_LABEL = 'update';
  CREATE_CUSTOMER_LABEL = 'create';
  customerForm: FormGroup;
  displaySamlConfiguration = false;
  isLoading = false;
  locations = LocationList;
  tenantId: string | undefined = undefined;
  title: string;
  update = false;
  samlProviderConfig: SamlProviderConfig | undefined;
  customerTypes: Array<{
    value: CustomerType;
    name: string;
    selected: boolean;
  }> = [
    {
      value: CustomerType.CUSTOMER_TYPE_ADVERTISER,
      name: 'Advertiser',
      selected: false,
    },
    {
      value: CustomerType.CUSTOMER_TYPE_MEDIA_PLATFORM,
      name: 'Platform',
      selected: false,
    },
    { value: CustomerType.CUSTOMER_TYPE_OTHER, name: 'Other', selected: false },
  ];
  binaryTypeInfos: Array<{
    value: BinaryType;
    name: string;
    selected: boolean;
  }> = [];

  constructor(
    private analytics: AngularFireAnalytics,
    public binaryTypeService: BinaryTypeService,
    public customerService: CustomerService,
    private dialogRef: MatDialogRef<AddCustomerModalComponent>,
    private formBuilder: FormBuilder,
    private formHelper: FormHelpersService,
    private logger: LoggerService,
    private messageBox: MessageBoxProvider,
    public userService: UserService,
    @Inject(MAT_DIALOG_DATA)
    public data: Customer
  ) {
    if (this.data?.companyName) {
      this.update = true;
    }
    this.title = `${
      this.getActionLabel().charAt(0).toUpperCase() +
      this.getActionLabel().slice(1)
    } Customer`;

    const isManagedCustomer = this.update ? this.data?.isManagedCustomer : true;

    this.tenantId = this.data?.tenantId;

    this.displaySamlConfiguration = this.data?.samlProviderConfig
      ? true
      : false;

    if (this.data && this.data.samlProviderConfig) {
      this.samlProviderConfig = this.data.samlProviderConfig;
    }

    this.customerForm = this.formBuilder.group({
      customerName: new FormControl(this.data?.companyName, {
        validators: [Validators.required],
      }),
      domainNames: new FormControl(this.data?.domainNames.join(', '), {
        validators: [Validators.required],
      }),
      customerTypes: new FormArray([], {
        validators: [Validators.required],
      }),
      binaryTypes: new FormArray([], {}),
      disabled: new FormControl(this.data?.disabled, {}),
      isManagedCustomer: new FormControl(isManagedCustomer, {}),
      enableSamlConfiguration: new FormControl(
        this.data?.samlProviderConfig?.enabled,
        {}
      ),
      locations: new FormArray([], { validators: [Validators.required] }),
      signInAllowed: new FormControl(this.data?.passwordSignInAllowed, {}),
      webUploadCsvFormattingEnabled: new FormControl(
        this.data?.webUploadCsvFormattingEnabled,
        {}
      ),
    });

    this.formHelper.setForm(this.customerForm);
    this.loadFormSettings();
  }

  buildCustomerData(customer: Customer): Customer {
    const { value } = this.customerForm;

    let domainNames = [];
    value.domainNames = value.domainNames.replaceAll(' ', '');

    if (value.domainNames.includes(',')) {
      domainNames = value.domainNames.split(',');
    } else {
      domainNames.push(value.domainNames);
    }

    customer.domainNames = domainNames;
    customer.companyName = value.customerName;
    customer.customerTypes = this.convertCustomerType(value.customerTypes);
    customer.allowedBinaryTypes = this.convertBinaryType(value.binaryTypes);
    customer.locations = this.convertLocation(value.locations);
    customer.disabled = value.disabled;
    customer.webUploadCsvFormattingEnabled =
      value.webUploadCsvFormattingEnabled;
    customer.passwordSignInAllowed = value.signInAllowed;
    customer.isManagedCustomer = value.isManagedCustomer;

    if (this.samlProviderConfig) {
      this.samlProviderConfig.enabled = value.enableSamlConfiguration;
      customer.samlProviderConfig = this.samlProviderConfig;
    }
    return customer;
  }

  public checkError(controlName: string, errorName: string) {
    return this.formHelper.checkError(controlName, errorName);
  }

  public checkLocations() {
    const { value } = this.customerForm;
    return value.locations.length == 0 ? true : false;
  }

  public close() {
    this.dialogRef.close();
  }

  convertCustomerType(arr: string[]): CustomerType[] {
    const typeArray: CustomerType[] = [];

    arr.forEach((value) => {
      typeArray.push(parseInt(value));
    });

    return typeArray;
  }

  convertBinaryType(arr: string[]): BinaryType[] {
    const typeArray: BinaryType[] = [];

    arr.forEach((value) => {
      typeArray.push(parseInt(value));
    });

    return typeArray;
  }

  convertLocation(arr: string[]): Location[] {
    const typeArray: Location[] = [];

    arr.forEach((value) => {
      typeArray.push(parseInt(value));
    });

    return typeArray;
  }

  createCustomer(): void {
    this.isLoading = true;

    const { value } = this.customerForm;
    const customer = new Customer();
    const newCustomer = this.buildCustomerData(customer);
    this.customerService
      .createCustomer(newCustomer)
      .then(() => {
        this.analytics.logEvent('customer-created');
        this.messageBox.show(
          `Customer successfully created with name: ${value.customerName}.`
        );
      })
      .catch((error) => {
        this.displayError(error, value.customerName);
      })
      .finally(() => (this.isLoading = false));
  }

  displayError(error: any, customerName: string) {
    switch (error.statusCode) {
      case 3:
        this.messageBox.error(
          `Failed to ${this.getActionLabel()} customer: ${error.statusMessage}`
        );
        break;
      default:
        this.messageBox.error(
          `Failed ${this.getActionLabel()} customer with name: ${customerName}`
        );
        this.logger.error('Error creating new customer', error);
    }
  }

  getActionLabel() {
    return this.update
      ? this.UPDATE_CUSTOMER_LABEL
      : this.CREATE_CUSTOMER_LABEL;
  }

  async loadFormSettings() {
    const getBinaryTypeResponse = await this.binaryTypeService.getBinaryTypes();

    getBinaryTypeResponse.binaryTypeInfos?.forEach((binaryTypeInfo) => {
      let checked = false;
      if (this.data?.allowedBinaryTypes.includes(binaryTypeInfo.binaryType)) {
        checked = true;
      }
      this.binaryTypeInfos.push({
        name: binaryTypeInfo.name,
        value: binaryTypeInfo.binaryType,
        selected: checked,
      });
    });

    const binaryTypesArray: FormArray = this.customerForm.get(
      'binaryTypes'
    ) as FormArray;
    this.binaryTypeInfos.forEach((binaryTypeInfo) => {
      if (binaryTypeInfo.selected) {
        binaryTypesArray.push(new FormControl(binaryTypeInfo.value));
      }
    });

    this.locations.forEach((location) => {
      this.data?.locations.includes(location.value)
        ? (location.selected = true)
        : (location.selected = false);
    });

    const locationsArray: FormArray = this.customerForm.get(
      'locations'
    ) as FormArray;

    this.locations.forEach((location) => {
      if (location.selected) {
        locationsArray.push(new FormControl(location.value));
      }
    });

    this.customerTypes.forEach((checkBoxType) => {
      this.data?.customerTypes.includes(checkBoxType.value)
        ? (checkBoxType.selected = true)
        : (checkBoxType.selected = false);
    });

    const customerTypesArray: FormArray = this.customerForm.get(
      'customerTypes'
    ) as FormArray;
    this.customerTypes.forEach((customerType) => {
      if (customerType.selected) {
        customerTypesArray.push(new FormControl(customerType.value));
      }
    });
  }

  onCheckboxChange(e: Event, inputType: string) {
    const component = <HTMLInputElement>e.target;
    const typesArray: FormArray = this.customerForm.get(inputType) as FormArray;

    if (component.checked) {
      typesArray.push(new FormControl(component.value));
    } else {
      let i = 0;
      typesArray.controls.forEach((item: AbstractControl) => {
        if (item.value == component.value) {
          typesArray.removeAt(i);
          return;
        }
        i++;
      });
    }
  }

  process() {
    const { value } = this.customerForm;

    if (value.enableSamlConfiguration && !this.samlProviderConfig) {
      this.messageBox.error(
        'Saml configuration is enabled but the configuration is incomplete.'
      );
      return;
    }

    if (this.update) {
      this.updateCustomer();
    } else {
      this.createCustomer();
    }
  }

  updateCustomer(): void {
    const { value } = this.customerForm;
    const customer = this.data;

    if (!value.isManagedCustomer && customer.isManagedCustomer) {
      if (!confirm('Disable managed mode? This action cannot be undone!')) {
        return;
      }
    }

    this.isLoading = true;

    const updatedCustomer = this.buildCustomerData(customer);

    this.customerService
      .updateCustomer(updatedCustomer)
      .then(() => {
        this.analytics.logEvent('customer-updated');
        this.messageBox.show(
          `Customer successfully updated with name: ${value.customerName}.`
        );
      })
      .catch((error) => {
        this.displayError(error, value.customerName);
      })
      .finally(() => (this.isLoading = false));
  }

  updateSamlProviderConfig(samlProviderConfig: SamlProviderConfig) {
    this.samlProviderConfig = samlProviderConfig;
  }

  validatePhoneNumForE164(phoneNum: string) {
    return this.formHelper.validatePhoneNumForE164(phoneNum);
  }

  enableSamlConfiguration() {
    const { value } = this.customerForm;
    this.displaySamlConfiguration = !value.enableSamlConfiguration;
  }
}
