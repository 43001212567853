import { Component } from '@angular/core';
import {
  Auth,
  isSignInWithEmailLink,
  MultiFactorError,
  signInWithEmailLink,
  UserCredential,
} from '@angular/fire/auth';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MultifactorLoginModalComponent } from 'app/modals/multifactor-login-modal.component/multifactor-login-modal.component';
import { FormHelpersService } from 'app/services/form-helpers.service';
import { TenantInfo } from 'app/services/generated/src/main/proto/api/customer-service.pb';
import { LoggerService } from 'app/services/logger.service';
import { MessageBoxProvider } from 'app/views/shared/components/message-box/message-box.provider';

import { TenantSelectModalComponent } from '../../modals/tenant-select-modal/tenant-select.component';
import { CustomerService } from '../../services/customer.service';

@Component({
  selector: 'app-email-sign-in-component',
  templateUrl: './email-sign-in.component.html',
  styleUrls: ['./email-sign-in.component.scss', '../../shared/shared.scss'],
})
export class EmailSignInComponent {
  emailForm: FormGroup;
  isPasswordReset = false;
  isInviteValid = true;
  codeExpired = false;

  constructor(
    private analytics: AngularFireAnalytics,
    private auth: Auth,
    private customerService: CustomerService,
    private dialog: MatDialog,
    private formHelper: FormHelpersService,
    private logger: LoggerService,
    private messageBox: MessageBoxProvider,
    private router: Router
  ) {
    this.emailForm = new FormGroup({
      email: new FormControl<string>('', [Validators.required]),
    });

    this.formHelper.setForm(this.emailForm);
  }

  signIn() {
    if (!isSignInWithEmailLink(this.auth, window.location.href)) {
      this.logger.error('Invalid sign-in link.');
      this.isInviteValid = false;
    }
  }

  public checkError(controlName: string, errorName: string) {
    return this.formHelper.checkError(controlName, errorName);
  }

  async getTenantId(email: string) {
    const tenantResponse = await this.customerService.getTenantId(email);

    if (tenantResponse.tenantInfos) {
      if (tenantResponse.tenantInfos?.length == 1) {
        return tenantResponse.tenantInfos[0].tenantId;
      } else {
        return await this.openTenantSelectorModule(tenantResponse.tenantInfos);
      }
    } else {
      this.messageBox.error('Invalid email address.');
      return null;
    }
  }

  async confirm() {
    if (this.emailForm?.value) {
      const { email } = this.emailForm.value;
      const tenantId = await this.getTenantId(email);

      if (typeof tenantId === 'string') {
        this.signInWithLink(email, tenantId);
      } else {
        this.messageBox.error('Invalid email address.');
      }
    }
  }

  private openTenantSelectorModule(tenantInfos: TenantInfo[] | undefined) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = tenantInfos;

    return new Promise<string>((resolve, reject) => {
      if (tenantInfos !== undefined) {
        this.dialog
          .open(TenantSelectModalComponent, dialogConfig)
          .afterClosed()
          .subscribe((tenantId: string) => {
            return resolve(tenantId);
          });
      } else {
        this.logger.error('Tenant Id missing from server response');
        return reject('Tenant Id missing from server response.');
      }
    });
  }

  async signInWithLink(email: string, tenantId: string) {
    if (email && tenantId) {
      this.auth.tenantId = tenantId;
      signInWithEmailLink(this.auth, email, window.location.href)
        .then(() => {
          this.messageBox.success('Successfully signed in.');
          this.analytics.logEvent('user sign-up with email link', {
            tenantId: this.auth.tenantId,
          });
          this.router.navigate(['set-password']);
        })
        .catch((error) => {
          this.logger.error(error.message);
          switch (error.code) {
            case 'auth/multi-factor-auth-required':
              this.openMultiFactorLoginModule(error);
              break;
            case 'auth/invalid-email':
              this.messageBox.error('Invalid email address.');
              break;
            case 'auth/tenant-id-mismatch':
              this.messageBox.error('Email domain is incorrect.');
              break;
            case 'auth/invalid-action-code':
              this.messageBox.error('Email link is invalid or expired.');
              this.isInviteValid = false;
              break;
            default:
              this.messageBox.error(
                `Unable to sign in using email link: ${error.message}.`
              );
          }
        });
    } else {
      this.messageBox.error('Invalid email address.');
    }
  }

  private openMultiFactorLoginModule(error: MultiFactorError) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = { error: error };

    this.dialog
      .open(MultifactorLoginModalComponent, dialogConfig)
      .afterClosed()
      .subscribe((user: UserCredential) => {
        if (user) {
          this.router.navigate(['set-password']);
        }
      });
  }
}
