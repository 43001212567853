import { Injectable, NgZone } from '@angular/core';
import { FirebaseApp } from '@angular/fire/app';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { Router } from '@angular/router';
import { GrpcMetadata } from '@ngx-grpc/common';
import { firstValueFrom } from 'rxjs';

import { ApiAuthService } from './api-auth.service';
import {
  ArchiveRequest,
  ArchiveResponse,
  AssignRolesRequest,
  AssignRolesResponse,
  CreateRequest,
  CreateResponse,
  GetAllRequest,
  GetAllResponse,
  RotateApiKeyRequest,
  RotateApiKeyResponse,
  UpdateRequest,
  UpdateResponse,
} from './generated/src/main/proto/api/app-service.pb';
import { AppServiceClient } from './generated/src/main/proto/api/app-service.pbsc';

/**
 * Service to manage applications and their secrets for direct API calls.
 */
@Injectable({
  providedIn: 'root',
})
export class AppService {
  constructor(
    public router: Router,
    public ngZone: NgZone,
    public firebaseApp: FirebaseApp,
    private analytics: AngularFireAnalytics,
    private apiAuthService: ApiAuthService,
    private appServiceClient: AppServiceClient
  ) {}

  /**
   * Creates a new application.
   *
   * @param appName - The name of the application to be created.
   * @returns A promise that resolves to a CreateResponse containing the result of the operation.
   */
  async create(appName: string): Promise<CreateResponse> {
    const grpcMetaData: GrpcMetadata =
      await this.apiAuthService.getAuthenticatedRequestHeader();
    const createRequest = new CreateRequest();
    createRequest.appName = appName;

    this.analytics.logEvent('create-app');

    return firstValueFrom(
      this.appServiceClient.create(createRequest, grpcMetaData)
    );
  }

  /**
   * Assigns roles to an application.
   *
   * @param appId - The ID of the application.
   * @param etag - The entity tag of the application.
   * @param roles - An array of roles to be assigned to the application.
   * @returns A promise that resolves to an AssignRolesResponse containing the result of the operation.
   */
  async assignRoles(
    appId: string,
    etag: string,
    roles: string[]
  ): Promise<AssignRolesResponse> {
    const grpcMetaData: GrpcMetadata =
      await this.apiAuthService.getAuthenticatedRequestHeader();
    const assignRolesRequest = new AssignRolesRequest();
    assignRolesRequest.roles = roles;
    assignRolesRequest.etag = etag;
    assignRolesRequest.appId = appId;

    this.analytics.logEvent('update-roles');

    return firstValueFrom(
      this.appServiceClient.assignRoles(assignRolesRequest, grpcMetaData)
    );
  }

  /**
   * Archives an application.
   *
   * @param appId - The ID of the application to be archived.
   * @param etag - The entity tag of the application.
   * @returns A promise that resolves to an ArchiveResponse containing the result of the operation.
   */
  async archive(appId: string, etag: string): Promise<ArchiveResponse> {
    const grpcMetaData: GrpcMetadata =
      await this.apiAuthService.getAuthenticatedRequestHeader();

    const archiveRequest = new ArchiveRequest();
    archiveRequest.appId = appId;
    archiveRequest.etag = etag;

    this.analytics.logEvent('archive-app', { appId: appId });

    return firstValueFrom(
      this.appServiceClient.archive(archiveRequest, grpcMetaData)
    );
  }

  /**
   * Rotates the API key of an application.
   *
   * @param appId - The ID of the application whose API key is to be rotated.
   * @param etag - The entity tag of the application.
   * @returns A promise that resolves to a RotateApiKeyResponse containing the result of the operation.
   */
  async rotateApiKey(
    appId: string,
    etag: string
  ): Promise<RotateApiKeyResponse> {
    const grpcMetaData: GrpcMetadata =
      await this.apiAuthService.getAuthenticatedRequestHeader();

    const rotateApiKeyRequest = new RotateApiKeyRequest();
    rotateApiKeyRequest.appId = appId;
    rotateApiKeyRequest.etag = etag;

    this.analytics.logEvent('rotate-app-key', { appId: appId });

    return firstValueFrom(
      this.appServiceClient.rotateApiKey(rotateApiKeyRequest, grpcMetaData)
    );
  }

  /**
   * Retrieves all applications visible to the user.
   *
   * @returns A promise that resolves to a GetAllResponse containing the list of applications.
   */
  async getAll(): Promise<GetAllResponse> {
    const grpcMetaData: GrpcMetadata =
      await this.apiAuthService.getAuthenticatedRequestHeader();

    const getAllRequest = new GetAllRequest();

    this.analytics.logEvent('get-all-apps');

    return firstValueFrom(
      this.appServiceClient.getAll(getAllRequest, grpcMetaData)
    );
  }

  /**
   * Updates an application's metadata.
   *
   * @param appId - The ID of the application to be updated.
   * @param appName - The new name of the application.
   * @param etag - The entity tag of the application.
   * @returns A promise that resolves to an UpdateResponse containing the result of the operation.
   */
  async update(
    appId: string,
    appName: string,
    etag: string
  ): Promise<UpdateResponse | undefined> {
    const grpcMetaData: GrpcMetadata =
      await this.apiAuthService.getAuthenticatedRequestHeader();

    const updateRequest = new UpdateRequest();
    updateRequest.appId = appId;
    updateRequest.appName = appName;
    updateRequest.etag = etag;

    this.analytics.logEvent('update-app');
    return firstValueFrom(
      this.appServiceClient.update(updateRequest, grpcMetaData)
    );
  }
}
