import { Injectable } from '@angular/core';
import {
  CountryCode,
  isSupportedCountry,
  ParseError,
  parsePhoneNumber,
} from 'libphonenumber-js';
import moment from 'moment';
import Papa from 'papaparse';
import { BehaviorSubject } from 'rxjs';

import { LoggerService } from '../services/logger.service';

const DEFAULT_COUNTRY: CountryCode = 'US';

const phoneAlternativeNamingOptions = new Set([
  'phone',
  'billing_phone',
  'shipping_phone',
]);
const PIIAlternativeNamingOptions = new Set([
  'email',
  'idfa',
  'ip_address',
  'gaid',
  'device_id',
]);

/**
 * Formats CSV files uploaded by partners.
 */
@Injectable({
  providedIn: 'root',
})
export class CsvFormatService {
  private static textEncoder = new TextEncoder();
  static _formatStatusBehaviorSubject = new BehaviorSubject<number>(0);
  static formatStatus$ =
    CsvFormatService._formatStatusBehaviorSubject.asObservable();

  constructor(private logger: LoggerService) {}

  public getFormatStatusObserver() {
    return CsvFormatService.formatStatus$;
  }

  /**
   * Tries to format CSV files by:
   * - Normalizing and hashing identity fields.
   * - Dropping unused columns.
   * - Renaming columns.
   *
   * @param csv The contents of the CSV file.
   * @returns The formatted CSV file.
   */
  public async formatCsv(csv: string): Promise<string> {
    if (CsvFormatService.isShopifyOrdersCsv(csv)) {
      this.logger.info('formatting shopify CSV file');
      return CsvFormatService.formatShopifyOrdersCsv(csv);
    } else {
      this.logger.info('formatting default CSV file');
      return CsvFormatService.formatDefaultCsv(csv);
    }
  }

  /**
   * Returns true if the CSV file is likely to be a Shopify Orders CSV file.
   *
   * @param csv The contents of the CSV file.
   * @returns True when the file contains Shopify Orders.
   */
  public static isShopifyOrdersCsv(csv: string): boolean {
    const requiredColumns = [
      'Billing Phone',
      'Created at',
      'Email',
      'Id',
      'Shipping Phone',
      'Subtotal',
    ];
    const actualColumns = this.extractColumnNames(csv);
    return requiredColumns.every((columnName) =>
      actualColumns.includes(columnName)
    );
  }

  /**
   * Extracts column names from a CSV file.
   *
   * @param csv The contents of the CSV file.
   * @returns Array of column names.
   */
  public static extractColumnNames(csv: string): string[] {
    const endOfLine = csv.indexOf('\n');
    const firstLine = endOfLine > 0 ? csv.substring(0, endOfLine) : csv;
    return firstLine
      .split(',')
      .map((columnName) => columnName.trim().replaceAll('"', ''));
  }

  /**
   * Formats a CSV file containing Shopify Orders:
   * - Normalizes email address and phone number.
   * - Computes SHA-256 hash of email address and phone number.
   * - Drops unused columns, per data minimization best-practice.
   * See this documentation:
   * - https://help.shopify.com/en/manual/orders/manage-orders/exporting-orders#export-orders
   * - https://help.shopify.com/en/manual/shopify-admin/productivity-tools/csv-files
   * - https://youtu.be/qJ0EVZGq23g?feature=shared&t=82
   * - https://youtu.be/PcWhNUgd-6k?feature=shared&t=96
   * - https://youtu.be/RwLimm_roUE?feature=shared&t=161
   * - https://youtu.be/KrDRDryWXUQ?feature=shared&t=93
   * - https://www.highviewapps.com/kb/importing-customer-phone-numbers/
   *
   * @param csv The contents of the CSV file exported from Shopify's Orders page.
   * @returns The formatted CSV file.
   */
  public static async formatShopifyOrdersCsv(csv: string): Promise<string> {
    return new Promise((resolve, reject) => {
      let batch = 0;
      let count = 0;
      let displayHeader = true;
      let eventCount = 0;
      let response = '';

      Papa.parse(csv, {
        header: true,
        worker: false,
        chunkSize: 500_000,
        skipEmptyLines: true,
        chunk: async (chunk: any, parser: Papa.Parser) => {
          const formattedRecords: any[] = [];

          if (eventCount >= 10_000) {
            CsvFormatService._formatStatusBehaviorSubject.next(count);
            eventCount = 0;
          }

          /* 
          Pausing and resuming the parser allow us to run async code in the observer. 
          Otherwise async functions would not be resolved and the complete callback 
          function would be run before the process is finished.
          */
          parser.pause();
          // Rows with an Id are orders. Rows without an Id are line items.
          chunk.data = chunk.data.filter((row: any) => row['Id']);
          for (const row in chunk.data) {
            let phoneNumber = '';
            let countryCode = '';

            if (chunk.data[row]['Billing Phone']) {
              phoneNumber = chunk.data[row]['Billing Phone'];
              countryCode = chunk.data[row]['Billing Country'];
            } else if (chunk.data[row]['Shipping Phone']) {
              phoneNumber = chunk.data[row]['Shipping Phone'];
              countryCode = chunk.data[row]['Shipping Country'];
            } else {
              phoneNumber = chunk.data[row]['Phone'];
              countryCode =
                chunk.data[row]['Billing Country'] ||
                chunk.data[row]['Shipping Country'];
            }

            formattedRecords.push({
              phone: await CsvFormatService.formatPhoneNumber(
                phoneNumber,
                countryCode
              ),
              email: await CsvFormatService.formatPiiString(
                chunk.data[row]['Email']
              ),
              event_type: 'purchase',
              event_time: CsvFormatService.formatTimestamp(
                chunk.data[row]['Created at']
              ),
              amount: chunk.data[row]['Subtotal'],
              record_id: chunk.data[row]['Id'],
            });
            count++;
            eventCount++;
          }
          if (batch !== 0) {
            displayHeader = false;
          }

          response +=
            Papa.unparse(formattedRecords, {
              header: displayHeader,
              newline: '\n',
              skipEmptyLines: true,
            }) + '\n';

          batch += 1;
          parser.resume();
        },
        complete: () => {
          resolve(response);
        },
        error: (error: any) => {
          reject(error);
        },
      });
    });
  }

  /**
   * Formats a CSV file containing advertiser data:
   * - Normalizes email address, phone number, ip address and device id.
   * - Computes SHA-256 hash of email address and phone number.
   *
   * @param csv The contents of the CSV file.
   * @returns The formatted CSV file.
   */
  public static async formatDefaultCsv(csv: string): Promise<string> {
    const normalizedHeaders: any = [];

    return new Promise((resolve, reject) => {
      let batch = 0;
      let count = 0;
      let displayHeader = true;
      let eventCount = 0;
      let response = '';

      Papa.parse(csv, {
        header: true,
        worker: false,
        skipEmptyLines: true,
        chunkSize: 500_000,
        chunk: async (chunk: any, parser: Papa.Parser) => {
          if (eventCount >= 10_000) {
            CsvFormatService._formatStatusBehaviorSubject.next(count);
            eventCount = 0;
          }

          if (batch === 0) {
            for (const header of chunk.meta.fields) {
              normalizedHeaders[header] =
                CsvFormatService.normalizeFieldName(header);
            }
          }

          /* 
          Pausing and resuming the parser allow us to run async code in the observer. 
          Otherwise async functions would not be resolved and the complete callback 
          function would be run before the process is finished.
          */
          parser.pause();
          for (const key in chunk.data) {
            for (const header of chunk.meta.fields) {
              const normalizedHeader = normalizedHeaders[header];
              if (
                chunk.data[key][header] &&
                phoneAlternativeNamingOptions.has(normalizedHeader)
              ) {
                chunk.data[key][header] =
                  await CsvFormatService.formatPhoneNumber(
                    chunk.data[key][header]
                  );
              } else if (
                chunk.data[key][header] &&
                PIIAlternativeNamingOptions.has(normalizedHeader)
              ) {
                chunk.data[key][header] =
                  await CsvFormatService.formatPiiString(
                    chunk.data[key][header]
                  );
              }
            }
            count++;
            eventCount++;
          }
          if (batch !== 0) {
            displayHeader = false;
          }
          response +=
            Papa.unparse(chunk.data, {
              header: displayHeader,
              newline: '\n',
              skipEmptyLines: true,
            }) + '\n';
          batch += 1;
          parser.resume();
        },
        complete: () => {
          if (count == 0) {
            reject('no records found in file.');
          }
          resolve(response);
        },
        error: (error: any) => {
          reject(error);
        },
      });
    });
  }

  /**
   * Check if field is numeric
   *
   * @param value value to check.
   * @returns boolean
   */
  public static isNumber(value: string | number): boolean {
    return value != null && value !== '' && !isNaN(Number(value.toString()));
  }

  /**
   * Formats a phone number by converting to E.164 format then hashing it with SHA-256.
   * If the phone number is already a SHA-256 hash then letters in the hash are lowercased.
   * If the phone number is empty or cannot be parsed then an empty string is returned.
   *
   * @param phoneNumber The phone number to format.
   * @param countryCode The ISO 3166-1 Alpha-2 (2-letter) country code for the phone number. Defaults to 'US'.
   * @returns The formatted phone number, or empty string if the phone number could not be parsed.
   */
  public static async formatPhoneNumber(
    phoneNumber: string,
    countryCode = ''
  ): Promise<string> {
    if (this.isSha256Hash(phoneNumber)) {
      return phoneNumber.toLowerCase();
    }
    try {
      let defaultCountry = DEFAULT_COUNTRY;
      if (countryCode) {
        if (isSupportedCountry(countryCode)) {
          defaultCountry = countryCode as CountryCode;
        } else {
          throw new Error(
            `unrecognized country code '${countryCode}' associated with phone number.`
          );
        }
      }
      const parsedPhoneNumber = parsePhoneNumber(phoneNumber, {
        defaultCountry: defaultCountry,
      });
      return this.convertArrayToHex(
        await crypto.subtle.digest(
          'SHA-256',
          this.textEncoder.encode(parsedPhoneNumber.number)
        )
      );
    } catch (e: unknown) {
      if (e instanceof ParseError) {
        // Ignore invalid phone numbers.
      } else {
        throw e;
      }
    }
    return '';
  }

  /**
   * Formats a Shopify's "Created at" timestamp to a UNIX timestamp.
   *
   * @param timestamp The "Created at" timestamp.
   * @returns A UNIX timestamp.
   */
  public static formatTimestamp(timestamp: string): number {
    return moment(timestamp, 'YYYY-MM-DD HH:mm:ss ZZ').unix();
  }

  /**
   * Formats an a string by normalizing then hashing with SHA-256. Normalization will:
   * - Trim leading and trailing whitespace.
   * - Convert to lowercase.
   * If the value is already a SHA-256 hash then letters in the hash are lowercased.
   *
   * @param string The value to format.
   * @returns The normalized hash value.
   */
  public static async formatPiiString(value: string): Promise<string> {
    if (this.isSha256Hash(value)) {
      return value.toLowerCase();
    }
    return this.convertArrayToHex(
      await crypto.subtle.digest(
        'SHA-256',
        this.textEncoder.encode(value.trim().toLowerCase())
      )
    );
  }

  /**
   * Converts an array to a lowercase hexadecimal string.
   *
   * @param array The array to convert.
   * @returns A hexadecimal string.
   */
  public static convertArrayToHex(array: ArrayBuffer): string {
    return Array.from(new Uint8Array(array))
      .map((b) => b.toString(16).padStart(2, '0'))
      .join('');
  }

  /**
   * Returns true if the string is a SHA-256 hash.
   *
   * @param string The string to check.
   * @returns True if the string is a SHA-256 hash.
   */
  public static isSha256Hash(string: string): boolean {
    return string.length == 64 && /^[a-fA-F0-9]+$/.test(string);
  }

  /**
   * Returns normalized field name.
   *
   * @param string The field name to check.
   * @returns normalized string.
   */
  public static normalizeFieldName(string: string): string {
    return string.trim().toLowerCase().replace(' ', '_').replace('-', '_');
  }
}
