/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import {
  GrpcMessage,
  RecursivePartial,
  ToProtobufJSONOptions,
} from '@ngx-grpc/common';
import { BinaryReader, BinaryWriter, ByteSource } from 'google-protobuf';
import * as googleProtobuf000 from '@ngx-grpc/well-known-types';
import * as googleProtobuf001 from '@ngx-grpc/well-known-types';
import * as storage002 from '../../../../src/main/proto/storage/binary-type.pb';
import * as storage003 from '../../../../src/main/proto/storage/commons.pb';
import * as storage004 from '../../../../src/main/proto/storage/schema-annotations.pb';
export enum CustomerType {
  CUSTOMER_TYPE_UNSPECIFIED = 0,
  CUSTOMER_TYPE_ADVERTISER = 1,
  CUSTOMER_TYPE_MEDIA_PLATFORM = 2,
  CUSTOMER_TYPE_OTHER = 3,
}
/**
 * Message implementation for storage.Customer
 */
export class Customer implements GrpcMessage {
  static id = 'storage.Customer';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new Customer();
    Customer.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: Customer) {
    _instance.id = _instance.id || '';

    _instance.lastUpdate = _instance.lastUpdate || undefined;
    _instance.companyName = _instance.companyName || '';
    _instance.creationTime = _instance.creationTime || undefined;
    _instance.disabled = _instance.disabled || false;
    _instance.customerTypes = _instance.customerTypes || [];
    _instance.domainNames = _instance.domainNames || [];
    _instance.tenantId = _instance.tenantId || '';
    _instance.allowedBinaryTypes = _instance.allowedBinaryTypes || [];
    _instance.isManagedCustomer = _instance.isManagedCustomer || false;
    _instance.passwordSignInAllowed = _instance.passwordSignInAllowed || false;
    _instance.samlProviderConfig = _instance.samlProviderConfig || undefined;
    _instance.locations = _instance.locations || [];
    _instance.webUploadCsvFormattingEnabled =
      _instance.webUploadCsvFormattingEnabled || false;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: Customer,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readString();
          break;
        case 14:
          _instance.etag = _reader.readString();
          break;
        case 15:
          _instance.lastUpdate = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.lastUpdate,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 3:
          _instance.companyName = _reader.readString();
          break;
        case 6:
          _instance.creationTime = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.creationTime,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 9:
          _instance.disabled = _reader.readBool();
          break;
        case 10:
          (_instance.customerTypes = _instance.customerTypes || []).push(
            ...(_reader.readPackedEnum() || [])
          );
          break;
        case 11:
          (_instance.domainNames = _instance.domainNames || []).push(
            _reader.readString()
          );
          break;
        case 12:
          _instance.tenantId = _reader.readString();
          break;
        case 13:
          (_instance.allowedBinaryTypes =
            _instance.allowedBinaryTypes || []).push(
            ...(_reader.readPackedEnum() || [])
          );
          break;
        case 16:
          _instance.isManagedCustomer = _reader.readBool();
          break;
        case 17:
          _instance.passwordSignInAllowed = _reader.readBool();
          break;
        case 18:
          _instance.samlProviderConfig = new SamlProviderConfig();
          _reader.readMessage(
            _instance.samlProviderConfig,
            SamlProviderConfig.deserializeBinaryFromReader
          );
          break;
        case 19:
          (_instance.locations = _instance.locations || []).push(
            ...(_reader.readPackedEnum() || [])
          );
          break;
        case 20:
          _instance.webUploadCsvFormattingEnabled = _reader.readBool();
          break;
        default:
          _reader.skipField();
      }
    }

    Customer.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(_instance: Customer, _writer: BinaryWriter) {
    if (_instance.id) {
      _writer.writeString(1, _instance.id);
    }
    if (_instance.etag !== undefined && _instance.etag !== null) {
      _writer.writeString(14, _instance.etag);
    }
    if (_instance.lastUpdate) {
      _writer.writeMessage(
        15,
        _instance.lastUpdate as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.companyName) {
      _writer.writeString(3, _instance.companyName);
    }
    if (_instance.creationTime) {
      _writer.writeMessage(
        6,
        _instance.creationTime as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.disabled) {
      _writer.writeBool(9, _instance.disabled);
    }
    if (_instance.customerTypes && _instance.customerTypes.length) {
      _writer.writePackedEnum(10, _instance.customerTypes);
    }
    if (_instance.domainNames && _instance.domainNames.length) {
      _writer.writeRepeatedString(11, _instance.domainNames);
    }
    if (_instance.tenantId) {
      _writer.writeString(12, _instance.tenantId);
    }
    if (_instance.allowedBinaryTypes && _instance.allowedBinaryTypes.length) {
      _writer.writePackedEnum(13, _instance.allowedBinaryTypes);
    }
    if (_instance.isManagedCustomer) {
      _writer.writeBool(16, _instance.isManagedCustomer);
    }
    if (_instance.passwordSignInAllowed) {
      _writer.writeBool(17, _instance.passwordSignInAllowed);
    }
    if (_instance.samlProviderConfig) {
      _writer.writeMessage(
        18,
        _instance.samlProviderConfig as any,
        SamlProviderConfig.serializeBinaryToWriter
      );
    }
    if (_instance.locations && _instance.locations.length) {
      _writer.writePackedEnum(19, _instance.locations);
    }
    if (_instance.webUploadCsvFormattingEnabled) {
      _writer.writeBool(20, _instance.webUploadCsvFormattingEnabled);
    }
  }

  private _id: string;
  private _etag?: string;
  private _lastUpdate?: googleProtobuf000.Timestamp;
  private _companyName: string;
  private _creationTime?: googleProtobuf000.Timestamp;
  private _disabled: boolean;
  private _customerTypes: CustomerType[];
  private _domainNames: string[];
  private _tenantId: string;
  private _allowedBinaryTypes: storage002.BinaryType[];
  private _isManagedCustomer: boolean;
  private _passwordSignInAllowed: boolean;
  private _samlProviderConfig?: SamlProviderConfig;
  private _locations: storage003.Location[];
  private _webUploadCsvFormattingEnabled: boolean;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of Customer to deeply clone from
   */
  constructor(_value?: RecursivePartial<Customer.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    this.etag = _value.etag;
    this.lastUpdate = _value.lastUpdate
      ? new googleProtobuf000.Timestamp(_value.lastUpdate)
      : undefined;
    this.companyName = _value.companyName;
    this.creationTime = _value.creationTime
      ? new googleProtobuf000.Timestamp(_value.creationTime)
      : undefined;
    this.disabled = _value.disabled;
    this.customerTypes = (_value.customerTypes || []).slice();
    this.domainNames = (_value.domainNames || []).slice();
    this.tenantId = _value.tenantId;
    this.allowedBinaryTypes = (_value.allowedBinaryTypes || []).slice();
    this.isManagedCustomer = _value.isManagedCustomer;
    this.passwordSignInAllowed = _value.passwordSignInAllowed;
    this.samlProviderConfig = _value.samlProviderConfig
      ? new SamlProviderConfig(_value.samlProviderConfig)
      : undefined;
    this.locations = (_value.locations || []).slice();
    this.webUploadCsvFormattingEnabled = _value.webUploadCsvFormattingEnabled;
    Customer.refineValues(this);
  }
  get id(): string {
    return this._id;
  }
  set id(value: string) {
    this._id = value;
  }
  get etag(): string | undefined {
    return this._etag;
  }
  set etag(value?: string) {
    this._etag = value;
  }
  get lastUpdate(): googleProtobuf000.Timestamp | undefined {
    return this._lastUpdate;
  }
  set lastUpdate(value: googleProtobuf000.Timestamp | undefined) {
    this._lastUpdate = value;
  }
  get companyName(): string {
    return this._companyName;
  }
  set companyName(value: string) {
    this._companyName = value;
  }
  get creationTime(): googleProtobuf000.Timestamp | undefined {
    return this._creationTime;
  }
  set creationTime(value: googleProtobuf000.Timestamp | undefined) {
    this._creationTime = value;
  }
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    this._disabled = value;
  }
  get customerTypes(): CustomerType[] {
    return this._customerTypes;
  }
  set customerTypes(value: CustomerType[]) {
    this._customerTypes = value;
  }
  get domainNames(): string[] {
    return this._domainNames;
  }
  set domainNames(value: string[]) {
    this._domainNames = value;
  }
  get tenantId(): string {
    return this._tenantId;
  }
  set tenantId(value: string) {
    this._tenantId = value;
  }
  get allowedBinaryTypes(): storage002.BinaryType[] {
    return this._allowedBinaryTypes;
  }
  set allowedBinaryTypes(value: storage002.BinaryType[]) {
    this._allowedBinaryTypes = value;
  }
  get isManagedCustomer(): boolean {
    return this._isManagedCustomer;
  }
  set isManagedCustomer(value: boolean) {
    this._isManagedCustomer = value;
  }
  get passwordSignInAllowed(): boolean {
    return this._passwordSignInAllowed;
  }
  set passwordSignInAllowed(value: boolean) {
    this._passwordSignInAllowed = value;
  }
  get samlProviderConfig(): SamlProviderConfig | undefined {
    return this._samlProviderConfig;
  }
  set samlProviderConfig(value: SamlProviderConfig | undefined) {
    this._samlProviderConfig = value;
  }
  get locations(): storage003.Location[] {
    return this._locations;
  }
  set locations(value: storage003.Location[]) {
    this._locations = value;
  }
  get webUploadCsvFormattingEnabled(): boolean {
    return this._webUploadCsvFormattingEnabled;
  }
  set webUploadCsvFormattingEnabled(value: boolean) {
    this._webUploadCsvFormattingEnabled = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    Customer.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): Customer.AsObject {
    return {
      id: this.id,
      etag: this.etag,
      lastUpdate: this.lastUpdate ? this.lastUpdate.toObject() : undefined,
      companyName: this.companyName,
      creationTime: this.creationTime
        ? this.creationTime.toObject()
        : undefined,
      disabled: this.disabled,
      customerTypes: (this.customerTypes || []).slice(),
      domainNames: (this.domainNames || []).slice(),
      tenantId: this.tenantId,
      allowedBinaryTypes: (this.allowedBinaryTypes || []).slice(),
      isManagedCustomer: this.isManagedCustomer,
      passwordSignInAllowed: this.passwordSignInAllowed,
      samlProviderConfig: this.samlProviderConfig
        ? this.samlProviderConfig.toObject()
        : undefined,
      locations: (this.locations || []).slice(),
      webUploadCsvFormattingEnabled: this.webUploadCsvFormattingEnabled,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): Customer.AsProtobufJSON {
    return {
      id: this.id,
      etag: this.etag === null || this.etag === undefined ? null : this.etag,
      lastUpdate: this.lastUpdate
        ? this.lastUpdate.toProtobufJSON(options)
        : null,
      companyName: this.companyName,
      creationTime: this.creationTime
        ? this.creationTime.toProtobufJSON(options)
        : null,
      disabled: this.disabled,
      customerTypes: (this.customerTypes || []).map((v) => CustomerType[v]),
      domainNames: (this.domainNames || []).slice(),
      tenantId: this.tenantId,
      allowedBinaryTypes: (this.allowedBinaryTypes || []).map(
        (v) => storage002.BinaryType[v]
      ),
      isManagedCustomer: this.isManagedCustomer,
      passwordSignInAllowed: this.passwordSignInAllowed,
      samlProviderConfig: this.samlProviderConfig
        ? this.samlProviderConfig.toProtobufJSON(options)
        : null,
      locations: (this.locations || []).map((v) => storage003.Location[v]),
      webUploadCsvFormattingEnabled: this.webUploadCsvFormattingEnabled,
    };
  }
}
export module Customer {
  /**
   * Standard JavaScript object representation for Customer
   */
  export interface AsObject {
    id: string;
    etag?: string;
    lastUpdate?: googleProtobuf000.Timestamp.AsObject;
    companyName: string;
    creationTime?: googleProtobuf000.Timestamp.AsObject;
    disabled: boolean;
    customerTypes: CustomerType[];
    domainNames: string[];
    tenantId: string;
    allowedBinaryTypes: storage002.BinaryType[];
    isManagedCustomer: boolean;
    passwordSignInAllowed: boolean;
    samlProviderConfig?: SamlProviderConfig.AsObject;
    locations: storage003.Location[];
    webUploadCsvFormattingEnabled: boolean;
  }

  /**
   * Protobuf JSON representation for Customer
   */
  export interface AsProtobufJSON {
    id: string;
    etag: string | null;
    lastUpdate: googleProtobuf000.Timestamp.AsProtobufJSON | null;
    companyName: string;
    creationTime: googleProtobuf000.Timestamp.AsProtobufJSON | null;
    disabled: boolean;
    customerTypes: string[];
    domainNames: string[];
    tenantId: string;
    allowedBinaryTypes: string[];
    isManagedCustomer: boolean;
    passwordSignInAllowed: boolean;
    samlProviderConfig: SamlProviderConfig.AsProtobufJSON | null;
    locations: string[];
    webUploadCsvFormattingEnabled: boolean;
  }
}

/**
 * Message implementation for storage.SamlProviderConfig
 */
export class SamlProviderConfig implements GrpcMessage {
  static id = 'storage.SamlProviderConfig';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new SamlProviderConfig();
    SamlProviderConfig.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: SamlProviderConfig) {
    _instance.displayName = _instance.displayName || '';
    _instance.enabled = _instance.enabled || false;
    _instance.providerId = _instance.providerId || '';
    _instance.idpEntityId = _instance.idpEntityId || '';
    _instance.ssoUrl = _instance.ssoUrl || '';
    _instance.x509Certificates = _instance.x509Certificates || [];
    _instance.rpEntityId = _instance.rpEntityId || '';
    _instance.callbackUrl = _instance.callbackUrl || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: SamlProviderConfig,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.displayName = _reader.readString();
          break;
        case 2:
          _instance.enabled = _reader.readBool();
          break;
        case 3:
          _instance.providerId = _reader.readString();
          break;
        case 4:
          _instance.idpEntityId = _reader.readString();
          break;
        case 5:
          _instance.ssoUrl = _reader.readString();
          break;
        case 6:
          (_instance.x509Certificates = _instance.x509Certificates || []).push(
            _reader.readString()
          );
          break;
        case 7:
          _instance.rpEntityId = _reader.readString();
          break;
        case 8:
          _instance.callbackUrl = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    SamlProviderConfig.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: SamlProviderConfig,
    _writer: BinaryWriter
  ) {
    if (_instance.displayName) {
      _writer.writeString(1, _instance.displayName);
    }
    if (_instance.enabled) {
      _writer.writeBool(2, _instance.enabled);
    }
    if (_instance.providerId) {
      _writer.writeString(3, _instance.providerId);
    }
    if (_instance.idpEntityId) {
      _writer.writeString(4, _instance.idpEntityId);
    }
    if (_instance.ssoUrl) {
      _writer.writeString(5, _instance.ssoUrl);
    }
    if (_instance.x509Certificates && _instance.x509Certificates.length) {
      _writer.writeRepeatedString(6, _instance.x509Certificates);
    }
    if (_instance.rpEntityId) {
      _writer.writeString(7, _instance.rpEntityId);
    }
    if (_instance.callbackUrl) {
      _writer.writeString(8, _instance.callbackUrl);
    }
  }

  private _displayName: string;
  private _enabled: boolean;
  private _providerId: string;
  private _idpEntityId: string;
  private _ssoUrl: string;
  private _x509Certificates: string[];
  private _rpEntityId: string;
  private _callbackUrl: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of SamlProviderConfig to deeply clone from
   */
  constructor(_value?: RecursivePartial<SamlProviderConfig.AsObject>) {
    _value = _value || {};
    this.displayName = _value.displayName;
    this.enabled = _value.enabled;
    this.providerId = _value.providerId;
    this.idpEntityId = _value.idpEntityId;
    this.ssoUrl = _value.ssoUrl;
    this.x509Certificates = (_value.x509Certificates || []).slice();
    this.rpEntityId = _value.rpEntityId;
    this.callbackUrl = _value.callbackUrl;
    SamlProviderConfig.refineValues(this);
  }
  get displayName(): string {
    return this._displayName;
  }
  set displayName(value: string) {
    this._displayName = value;
  }
  get enabled(): boolean {
    return this._enabled;
  }
  set enabled(value: boolean) {
    this._enabled = value;
  }
  get providerId(): string {
    return this._providerId;
  }
  set providerId(value: string) {
    this._providerId = value;
  }
  get idpEntityId(): string {
    return this._idpEntityId;
  }
  set idpEntityId(value: string) {
    this._idpEntityId = value;
  }
  get ssoUrl(): string {
    return this._ssoUrl;
  }
  set ssoUrl(value: string) {
    this._ssoUrl = value;
  }
  get x509Certificates(): string[] {
    return this._x509Certificates;
  }
  set x509Certificates(value: string[]) {
    this._x509Certificates = value;
  }
  get rpEntityId(): string {
    return this._rpEntityId;
  }
  set rpEntityId(value: string) {
    this._rpEntityId = value;
  }
  get callbackUrl(): string {
    return this._callbackUrl;
  }
  set callbackUrl(value: string) {
    this._callbackUrl = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    SamlProviderConfig.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): SamlProviderConfig.AsObject {
    return {
      displayName: this.displayName,
      enabled: this.enabled,
      providerId: this.providerId,
      idpEntityId: this.idpEntityId,
      ssoUrl: this.ssoUrl,
      x509Certificates: (this.x509Certificates || []).slice(),
      rpEntityId: this.rpEntityId,
      callbackUrl: this.callbackUrl,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): SamlProviderConfig.AsProtobufJSON {
    return {
      displayName: this.displayName,
      enabled: this.enabled,
      providerId: this.providerId,
      idpEntityId: this.idpEntityId,
      ssoUrl: this.ssoUrl,
      x509Certificates: (this.x509Certificates || []).slice(),
      rpEntityId: this.rpEntityId,
      callbackUrl: this.callbackUrl,
    };
  }
}
export module SamlProviderConfig {
  /**
   * Standard JavaScript object representation for SamlProviderConfig
   */
  export interface AsObject {
    displayName: string;
    enabled: boolean;
    providerId: string;
    idpEntityId: string;
    ssoUrl: string;
    x509Certificates: string[];
    rpEntityId: string;
    callbackUrl: string;
  }

  /**
   * Protobuf JSON representation for SamlProviderConfig
   */
  export interface AsProtobufJSON {
    displayName: string;
    enabled: boolean;
    providerId: string;
    idpEntityId: string;
    ssoUrl: string;
    x509Certificates: string[];
    rpEntityId: string;
    callbackUrl: string;
  }
}
