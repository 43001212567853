import { Component, OnInit } from '@angular/core';
import { MultiFactorError, UserCredential } from '@angular/fire/auth';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { LoggerService } from 'app/services/logger.service';

import { SamlBase } from '../../base/saml-base';
import { MultifactorLoginModalComponent } from '../../modals/multifactor-login-modal.component/multifactor-login-modal.component';
import { UserService } from '../../services/user.service';
import { MessageBoxProvider } from '../shared/components/message-box/message-box.provider';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
})
export class SignInComponent extends SamlBase implements OnInit {
  redirect: string;
  signInForm = new FormGroup({
    email: new FormControl(''),
    password: new FormControl(''),
  });
  isLoading = false;
  tenantId: string | undefined;
  allowSignInWithSaml = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private analytics: AngularFireAnalytics,
    private dialog: MatDialog,
    private router: Router,
    override logger: LoggerService,
    override messageBox: MessageBoxProvider,
    override userService: UserService
  ) {
    super(logger, messageBox, userService);
    this.redirect =
      this.activatedRoute.snapshot.queryParams['redirect'] || 'landing-page';
  }

  async ngOnInit() {
    this.tenantId = this.activatedRoute.snapshot.queryParams['tenantId'];

    if (!this.tenantId) {
      this.router.navigate(['sign-in-options']);
    }

    if (this.activatedRoute.snapshot.queryParams['signInWithSaml'] === 'true') {
      this.allowSignInWithSaml = true;
    }

    this.signInForm.setValue({
      email: this.activatedRoute.snapshot.queryParams['email'],
      password: '',
    });
  }

  navigate(user: UserCredential) {
    this.analytics.setUserId(user.user.uid).then(() =>
      this.analytics.setUserProperties({
        signInType: 'email_password',
        email: user.user.email,
      })
    );
    this.router.navigate([this.redirect]);
  }

  public async process() {
    const { value } = this.signInForm;
    if (!value.email || !value.password) return;

    if (this.tenantId) {
      this.signIn(value.email, value.password, this.tenantId);
    } else {
      this.router.navigate(['sign-in-options']);
    }
  }

  private signIn(email: string, password: string, tenantId: string) {
    this.isLoading = true;
    this.userService
      .signIn(email, password, tenantId)
      .then((user) => {
        this.navigate(user);
      })
      .catch((error) => {
        switch (error.code) {
          case 'auth/user-not-found':
            this.messageBox.show('Invalid email or password', 'error');
            this.logger.error('User not found', error);
            break;
          case 'auth/multi-factor-auth-required':
            this.openMultiFactorLoginModule(error);
            break;
          default:
            this.messageBox.show('Invalid email or password', 'error');
            this.logger.error('Unmapped error', error);
        }
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  public signInWithIdentityProvider() {
    if (this.tenantId) {
      this.signInWithSaml(this.tenantId);
    } else {
      this.router.navigate(['sign-in-options']);
    }
  }

  public resetPassword() {
    const { value } = this.signInForm;
    this.router.navigate(['forgot-password'], {
      queryParams: { email: value.email },
    });
  }

  private openMultiFactorLoginModule(error: MultiFactorError) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = { error: error };

    this.dialog
      .open(MultifactorLoginModalComponent, dialogConfig)
      .afterClosed()
      .subscribe((user: UserCredential) => {
        if (user) {
          this.router.navigate([this.redirect]);
        }
      });
  }
}
