import { Injectable } from '@angular/core';
import { JobSchedule } from 'app/services/generated/src/main/proto/storage/job-schedule.pb';
import { firstValueFrom } from 'rxjs';

import { LoggerService } from '../services/logger.service';
import { ApiAuthService } from './api-auth.service';
import {
  ArchiveRequest,
  ArchiveResponse,
  CreateRequest,
  CreateResponse,
  ListRequest,
  ListResponse,
} from './generated/src/main/proto/api/job-schedule-service.pb';
import { JobScheduleServiceClient } from './generated/src/main/proto/api/job-schedule-service.pbsc';

/**
 * Service to manage job schedules, including creating, listing, and archiving job schedules.
 */
@Injectable({
  providedIn: 'root',
})
export class JobScheduleService {
  constructor(
    private jobScheduleServiceClient: JobScheduleServiceClient,
    private apiAuthService: ApiAuthService,
    private logger: LoggerService
  ) {}

  /**
   * Creates a new job schedule.
   *
   * @param jobSchedule - The job schedule data to create.
   * @returns A promise that resolves to a CreateResponse containing the result of the creation operation.
   * @throws An error if the creation operation fails.
   */
  public async createJobSchedule(
    jobSchedule: JobSchedule
  ): Promise<CreateResponse> {
    try {
      const grpcMetaData =
        await this.apiAuthService.getAuthenticatedRequestHeader();
      const createRequest = new CreateRequest();
      createRequest.jobSchedule = jobSchedule;
      const createResponse = await firstValueFrom(
        this.jobScheduleServiceClient.create(createRequest, grpcMetaData)
      );
      return createResponse;
    } catch (error) {
      this.logger.error(String(error));
      throw error;
    }
  }

  /**
   * Lists all job schedules based on the provided request.
   *
   * @param listRequest - The request parameters for listing job schedules.
   * @returns A promise that resolves to a ListResponse containing the list of job schedules.
   */
  public async listAllJobSchedules(
    listRequest: ListRequest
  ): Promise<ListResponse> {
    const grpcMetadata =
      await this.apiAuthService.getAuthenticatedRequestHeader();
    return firstValueFrom(
      this.jobScheduleServiceClient.list(listRequest, grpcMetadata)
    );
  }

  /**
   * Archives a specified job schedule.
   *
   * @param id - The ID of the job schedule to archive.
   * @returns A promise that resolves to an ArchiveResponse containing the result of the archive operation.
   * @throws An error if the archive operation fails.
   */
  public async archiveJobSchedule(id: string): Promise<ArchiveResponse> {
    try {
      const grpcMetaData =
        await this.apiAuthService.getAuthenticatedRequestHeader();
      const archiveRequest = new ArchiveRequest();
      archiveRequest.jobScheduleId = id;
      archiveRequest.archive = true;
      const archiveResponse = await firstValueFrom(
        this.jobScheduleServiceClient.archive(archiveRequest, grpcMetaData)
      );
      return archiveResponse;
    } catch (error) {
      this.logger.error(String(error));
      throw error;
    }
  }
}
