<app-container>
  <app-page-header
    title="Binary Assignments"
    lastUpdated="{{ time }}"
  ></app-page-header>
  <app-message-box></app-message-box>

  <section class="team-table-container" tabindex="0">
    <app-binary-type-dropdown
      [displayBinaryDescription]="true"
      (selectBinaryTypeEvent)="onBinaryTypeSelect($event)"
    ></app-binary-type-dropdown>
    <app-archive-select
      (selectArchiveEvent)="onArchiveSelect($event)"
    ></app-archive-select>

    <table
      mat-table
      [dataSource]="approvalRequests"
      matSort
      class="table-data"
      (matSortChange)="announceSortChange($event)"
    >
      <ng-container matColumnDef="Id">
        <th
          class="row-header"
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header="id"
        >
          ID
        </th>
        <td mat-cell *matCellDef="let user">
          <div class="user-name-col">
            <span>{{ user.id }}</span>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="Name">
        <th
          mat-header-cell
          *matHeaderCellDef
          class="row-header"
          mat-sort-header="name"
        >
          Name
        </th>
        <td mat-cell *matCellDef="let user">
          {{ user.name }}
        </td>
      </ng-container>

      <ng-container matColumnDef="BinaryType">
        <th
          mat-header-cell
          *matHeaderCellDef
          class="row-header"
          mat-sort-header="binaryType"
        >
          Binary type
        </th>
        <td mat-cell *matCellDef="let user" class="text-center">
          {{ displayBinaryTypes([user.binaryType]) }}
        </td>
      </ng-container>

      <ng-container matColumnDef="Version">
        <th
          mat-header-cell
          *matHeaderCellDef
          class="row-header"
          mat-sort-header="version"
        >
          Version
        </th>
        <td mat-cell *matCellDef="let user" class="text-center">
          <label class="badge">{{ user.version }}</label>
        </td>
      </ng-container>

      <ng-container matColumnDef="CreationTime">
        <th
          mat-header-cell
          *matHeaderCellDef
          class="row-header"
          mat-sort-header="creationTime"
        >
          Date
        </th>
        <td mat-cell *matCellDef="let user">
          {{ formatDate(user.creationTime) }}
        </td>
      </ng-container>

      <ng-container matColumnDef="Assigned">
        <th
          mat-header-cell
          *matHeaderCellDef
          class="row-header"
          mat-sort-header="assigned"
        >
          Actions
        </th>
        <td mat-cell *matCellDef="let user" class="text-center">
          <div class="user-name-col">
            <button mat-icon-button [matMenuTriggerFor]="actionsMenu">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #actionsMenu>
              <button
                mat-menu-item
                (click)="
                  openDetails(
                    user.id,
                    user.etag,
                    user.approvedViewers,
                    user.decisionRollups
                  )
                "
              >
                <mat-icon>settings</mat-icon>
                <span>Manage assignments</span>
              </button>
              <button mat-menu-item (click)="archive(user.id, user.etag)">
                <mat-icon>delete</mat-icon>
                <span>Archive</span>
              </button>
            </mat-menu>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: columnsToDisplay"
        class="table-row"
      ></tr>
    </table>

    <div *ngIf="isLoadingApprovals" class="loader">
      <mat-spinner [diameter]="42"></mat-spinner>
    </div>

    <div class="footer">
      <mat-paginator
        *ngIf="
          (!isLoadingApprovals || this.approvalRequests.data.length) &&
          totalRecords > pageSize
        "
        [length]="totalRecords"
        [pageSize]="pageSize"
        [pageIndex]="currentPageIndex"
        (page)="onPageChange($event)"
        [disabled]="isLoadingApprovals"
      ></mat-paginator>
    </div>
  </section>
</app-container>
