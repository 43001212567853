import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-anonym-logo',
  templateUrl: './anonym-logo.component.html',
  styleUrls: ['./anonym-logo.component.scss'],
})
export class AnonymLogoComponent {
  @Input() showWelcomeMessage = true;
}
