<div class="background">
  <div class="card">
    <app-anonym-logo [showWelcomeMessage]="false"></app-anonym-logo>
    <hr />
    <div class="body">
      <h1>Forgot Password</h1>
      <p>Please enter your email address to receive a verification code</p>

      <form ngNativeValidate (ngSubmit)="process()" [formGroup]="form">
        <label>Email</label>
        <input
          type="text"
          tabindex="1"
          formControlName="email"
          [disabled]="true"
          autocomplete="email"
        />

        <app-message-box> </app-message-box>

        <button
          type="submit"
          color="primary"
          tabindex="2"
          mat-flat-button
          [disabled]="isLoading"
        >
          {{ isLoading ? null : 'Send' }}
          <span *ngIf="isLoading" class="spinner">
            <mat-spinner diameter="24"></mat-spinner>
          </span>
        </button>
      </form>
      <hr />

      <p>Have an issue? <a href="mailto:contact@anonymco.com">Contact Us</a></p>
    </div>
  </div>
</div>
