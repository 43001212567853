<app-container>
  <app-page-header
    title="Binary Approval"
    lastUpdated="TODAY {{ lastUpdate }}"
  ></app-page-header>

  <div
    class="binary-type"
    *ngIf="!isFetchingAllowedBinaryTypes || allowedBinaryTypes!.length > 1"
  >
    <!--<mat-label>Filter by Binary</mat-label>-->
    <mat-select
      matNativeControl
      required
      placeholder="Select"
      formControlName="binaryTypeSelect"
      (selectionChange)="filterByBinaryType(+$event.value)"
    >
      <mat-option value="0">All</mat-option>
      <mat-option
        *ngFor="let binaryTypeInfo of binaryTypeInfos"
        value="{{ binaryTypeInfo.binaryType }}"
        >{{ binaryTypeInfo.name }}</mat-option
      >
    </mat-select>
  </div>

  <div *ngIf="!isFetching || approvals" class="grid">
    <div class="header row">
      <div>Binary State</div>
      <div>Name</div>
      <div>Binary Version</div>
      <div>Approval State</div>
      <div></div>
    </div>

    <!-- ### APPROVALS #### -->

    <app-binary
      *ngFor="let approval of approvals ?? []"
      [approval]="approval"
      [isOpen]="activeId === approval.id"
      [onToggle]="handleRowToggle"
      [onApprovalUpdate]="onApprovalUpdate"
      [updateInProgress]="updateInProgress"
      [setUpdateInProgress]="setUpdateInProgress"
      [isApprover]="isApprover"
      [displaySourceIcon]="
        displaySourceCodeIcon(
          approval?.approvalRequestDetail?.change?.binaryInfo?.version,
          approval?.approvalRequestDetail?.change?.binaryInfo?.binaryType
        )
      "
    >
    </app-binary>
  </div>

  <div class="footer">
    <div *ngIf="isFetching" class="loader">
      <mat-spinner [diameter]="approvals ? 24 : 42"></mat-spinner>
    </div>
    <mat-paginator
      *ngIf="(!isFetching || approvals) && totalRecords > pageSize"
      [length]="totalRecords"
      [pageSize]="pageSize"
      [pageIndex]="currentPageIndex"
      (page)="onPageChange($event)"
      [disabled]="isFetching"
    ></mat-paginator>
  </div>
</app-container>
