/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import {
  GrpcMessage,
  RecursivePartial,
  ToProtobufJSONOptions,
} from '@ngx-grpc/common';
import { BinaryReader, BinaryWriter, ByteSource } from 'google-protobuf';
import * as googleProtobuf000 from '@ngx-grpc/well-known-types';
/**
 * Message implementation for storage.App
 */
export class App implements GrpcMessage {
  static id = 'storage.App';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new App();
    App.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: App) {
    _instance.id = _instance.id || '';
    _instance.tenantId = _instance.tenantId || '';
    _instance.appName = _instance.appName || '';
    _instance.roles = _instance.roles || [];
    _instance.apiKeyData = _instance.apiKeyData || undefined;
    _instance.creationTime = _instance.creationTime || undefined;
    _instance.lastUpdate = _instance.lastUpdate || undefined;
    _instance.archived = _instance.archived || false;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(_instance: App, _reader: BinaryReader) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readString();
          break;
        case 2:
          _instance.tenantId = _reader.readString();
          break;
        case 3:
          _instance.appName = _reader.readString();
          break;
        case 4:
          (_instance.roles = _instance.roles || []).push(_reader.readString());
          break;
        case 5:
          _instance.apiKeyData = new ApiKeyData();
          _reader.readMessage(
            _instance.apiKeyData,
            ApiKeyData.deserializeBinaryFromReader
          );
          break;
        case 6:
          _instance.creationTime = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.creationTime,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 7:
          _instance.lastUpdate = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.lastUpdate,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 8:
          _instance.archived = _reader.readBool();
          break;
        case 9:
          _instance.etag = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    App.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(_instance: App, _writer: BinaryWriter) {
    if (_instance.id) {
      _writer.writeString(1, _instance.id);
    }
    if (_instance.tenantId) {
      _writer.writeString(2, _instance.tenantId);
    }
    if (_instance.appName) {
      _writer.writeString(3, _instance.appName);
    }
    if (_instance.roles && _instance.roles.length) {
      _writer.writeRepeatedString(4, _instance.roles);
    }
    if (_instance.apiKeyData) {
      _writer.writeMessage(
        5,
        _instance.apiKeyData as any,
        ApiKeyData.serializeBinaryToWriter
      );
    }
    if (_instance.creationTime) {
      _writer.writeMessage(
        6,
        _instance.creationTime as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.lastUpdate) {
      _writer.writeMessage(
        7,
        _instance.lastUpdate as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.archived) {
      _writer.writeBool(8, _instance.archived);
    }
    if (_instance.etag !== undefined && _instance.etag !== null) {
      _writer.writeString(9, _instance.etag);
    }
  }

  private _id: string;
  private _tenantId: string;
  private _appName: string;
  private _roles: string[];
  private _apiKeyData?: ApiKeyData;
  private _creationTime?: googleProtobuf000.Timestamp;
  private _lastUpdate?: googleProtobuf000.Timestamp;
  private _archived: boolean;
  private _etag?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of App to deeply clone from
   */
  constructor(_value?: RecursivePartial<App.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    this.tenantId = _value.tenantId;
    this.appName = _value.appName;
    this.roles = (_value.roles || []).slice();
    this.apiKeyData = _value.apiKeyData
      ? new ApiKeyData(_value.apiKeyData)
      : undefined;
    this.creationTime = _value.creationTime
      ? new googleProtobuf000.Timestamp(_value.creationTime)
      : undefined;
    this.lastUpdate = _value.lastUpdate
      ? new googleProtobuf000.Timestamp(_value.lastUpdate)
      : undefined;
    this.archived = _value.archived;
    this.etag = _value.etag;
    App.refineValues(this);
  }
  get id(): string {
    return this._id;
  }
  set id(value: string) {
    this._id = value;
  }
  get tenantId(): string {
    return this._tenantId;
  }
  set tenantId(value: string) {
    this._tenantId = value;
  }
  get appName(): string {
    return this._appName;
  }
  set appName(value: string) {
    this._appName = value;
  }
  get roles(): string[] {
    return this._roles;
  }
  set roles(value: string[]) {
    this._roles = value;
  }
  get apiKeyData(): ApiKeyData | undefined {
    return this._apiKeyData;
  }
  set apiKeyData(value: ApiKeyData | undefined) {
    this._apiKeyData = value;
  }
  get creationTime(): googleProtobuf000.Timestamp | undefined {
    return this._creationTime;
  }
  set creationTime(value: googleProtobuf000.Timestamp | undefined) {
    this._creationTime = value;
  }
  get lastUpdate(): googleProtobuf000.Timestamp | undefined {
    return this._lastUpdate;
  }
  set lastUpdate(value: googleProtobuf000.Timestamp | undefined) {
    this._lastUpdate = value;
  }
  get archived(): boolean {
    return this._archived;
  }
  set archived(value: boolean) {
    this._archived = value;
  }
  get etag(): string | undefined {
    return this._etag;
  }
  set etag(value?: string) {
    this._etag = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    App.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): App.AsObject {
    return {
      id: this.id,
      tenantId: this.tenantId,
      appName: this.appName,
      roles: (this.roles || []).slice(),
      apiKeyData: this.apiKeyData ? this.apiKeyData.toObject() : undefined,
      creationTime: this.creationTime
        ? this.creationTime.toObject()
        : undefined,
      lastUpdate: this.lastUpdate ? this.lastUpdate.toObject() : undefined,
      archived: this.archived,
      etag: this.etag,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): App.AsProtobufJSON {
    return {
      id: this.id,
      tenantId: this.tenantId,
      appName: this.appName,
      roles: (this.roles || []).slice(),
      apiKeyData: this.apiKeyData
        ? this.apiKeyData.toProtobufJSON(options)
        : null,
      creationTime: this.creationTime
        ? this.creationTime.toProtobufJSON(options)
        : null,
      lastUpdate: this.lastUpdate
        ? this.lastUpdate.toProtobufJSON(options)
        : null,
      archived: this.archived,
      etag: this.etag === null || this.etag === undefined ? null : this.etag,
    };
  }
}
export module App {
  /**
   * Standard JavaScript object representation for App
   */
  export interface AsObject {
    id: string;
    tenantId: string;
    appName: string;
    roles: string[];
    apiKeyData?: ApiKeyData.AsObject;
    creationTime?: googleProtobuf000.Timestamp.AsObject;
    lastUpdate?: googleProtobuf000.Timestamp.AsObject;
    archived: boolean;
    etag?: string;
  }

  /**
   * Protobuf JSON representation for App
   */
  export interface AsProtobufJSON {
    id: string;
    tenantId: string;
    appName: string;
    roles: string[];
    apiKeyData: ApiKeyData.AsProtobufJSON | null;
    creationTime: googleProtobuf000.Timestamp.AsProtobufJSON | null;
    lastUpdate: googleProtobuf000.Timestamp.AsProtobufJSON | null;
    archived: boolean;
    etag: string | null;
  }
}

/**
 * Message implementation for storage.ApiKeyData
 */
export class ApiKeyData implements GrpcMessage {
  static id = 'storage.ApiKeyData';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ApiKeyData();
    ApiKeyData.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ApiKeyData) {
    _instance.schemeVersion = _instance.schemeVersion || 0;
    _instance.keyHash = _instance.keyHash || '';
    _instance.salt = _instance.salt || '';
    _instance.rev = _instance.rev || 0;
    _instance.creationTime = _instance.creationTime || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ApiKeyData,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.schemeVersion = _reader.readInt32();
          break;
        case 2:
          _instance.keyHash = _reader.readString();
          break;
        case 3:
          _instance.salt = _reader.readString();
          break;
        case 4:
          _instance.rev = _reader.readInt32();
          break;
        case 5:
          _instance.creationTime = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.creationTime,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    ApiKeyData.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(_instance: ApiKeyData, _writer: BinaryWriter) {
    if (_instance.schemeVersion) {
      _writer.writeInt32(1, _instance.schemeVersion);
    }
    if (_instance.keyHash) {
      _writer.writeString(2, _instance.keyHash);
    }
    if (_instance.salt) {
      _writer.writeString(3, _instance.salt);
    }
    if (_instance.rev) {
      _writer.writeInt32(4, _instance.rev);
    }
    if (_instance.creationTime) {
      _writer.writeMessage(
        5,
        _instance.creationTime as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
  }

  private _schemeVersion: number;
  private _keyHash: string;
  private _salt: string;
  private _rev: number;
  private _creationTime?: googleProtobuf000.Timestamp;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ApiKeyData to deeply clone from
   */
  constructor(_value?: RecursivePartial<ApiKeyData.AsObject>) {
    _value = _value || {};
    this.schemeVersion = _value.schemeVersion;
    this.keyHash = _value.keyHash;
    this.salt = _value.salt;
    this.rev = _value.rev;
    this.creationTime = _value.creationTime
      ? new googleProtobuf000.Timestamp(_value.creationTime)
      : undefined;
    ApiKeyData.refineValues(this);
  }
  get schemeVersion(): number {
    return this._schemeVersion;
  }
  set schemeVersion(value: number) {
    this._schemeVersion = value;
  }
  get keyHash(): string {
    return this._keyHash;
  }
  set keyHash(value: string) {
    this._keyHash = value;
  }
  get salt(): string {
    return this._salt;
  }
  set salt(value: string) {
    this._salt = value;
  }
  get rev(): number {
    return this._rev;
  }
  set rev(value: number) {
    this._rev = value;
  }
  get creationTime(): googleProtobuf000.Timestamp | undefined {
    return this._creationTime;
  }
  set creationTime(value: googleProtobuf000.Timestamp | undefined) {
    this._creationTime = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ApiKeyData.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ApiKeyData.AsObject {
    return {
      schemeVersion: this.schemeVersion,
      keyHash: this.keyHash,
      salt: this.salt,
      rev: this.rev,
      creationTime: this.creationTime
        ? this.creationTime.toObject()
        : undefined,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ApiKeyData.AsProtobufJSON {
    return {
      schemeVersion: this.schemeVersion,
      keyHash: this.keyHash,
      salt: this.salt,
      rev: this.rev,
      creationTime: this.creationTime
        ? this.creationTime.toProtobufJSON(options)
        : null,
    };
  }
}
export module ApiKeyData {
  /**
   * Standard JavaScript object representation for ApiKeyData
   */
  export interface AsObject {
    schemeVersion: number;
    keyHash: string;
    salt: string;
    rev: number;
    creationTime?: googleProtobuf000.Timestamp.AsObject;
  }

  /**
   * Protobuf JSON representation for ApiKeyData
   */
  export interface AsProtobufJSON {
    schemeVersion: number;
    keyHash: string;
    salt: string;
    rev: number;
    creationTime: googleProtobuf000.Timestamp.AsProtobufJSON | null;
  }
}
