<div class="header">
  <h2 class="modal-title">{{ title }}</h2>
  <button mat-icon-button (click)="close()">
    <mat-icon>close</mat-icon>
  </button>
</div>
<div class="grid">
  <div class="row">
    <div class="cell">Name</div>
    <div class="cell">{{ details!.name }}</div>
  </div>
  <div class="row">
    <div class="cell">Version</div>
    <div class="cell">{{ details!.version }}</div>
  </div>
  <div class="row">
    <div class="cell">Binary Type</div>
    <div class="cell">{{ getBinaryTypeName(details!.binaryType) }}</div>
  </div>
  <div class="row">
    <div class="cell">Target</div>
    <div class="cell">{{ details!.target }}</div>
  </div>
  <div class="row">
    <div class="cell">MrEnclave</div>
    <div class="cell">{{ details!.mrEnclave }}</div>
  </div>
  <div class="row">
    <div class="cell">MrSigner</div>
    <div class="cell">{{ details!.mrSigner }}</div>
  </div>
  <div class="row">
    <div class="cell">Commit ID</div>
    <div class="cell">{{ details!.commitId }}</div>
  </div>
</div>
