import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatSort, MatSortable } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { BinaryTypeService } from 'app/services/binary-type.service';
import { FormatService } from 'app/services/format.service';
import {
  GetRequest,
  GetRequestFilter,
} from 'app/services/generated/src/main/proto/api/job-log-service.pb';
import { GetPaginatedRequest } from 'app/services/generated/src/main/proto/api/pagination.pb';
import { Customer } from 'app/services/generated/src/main/proto/storage/customer.pb';
import {
  JobLog,
  JobState,
} from 'app/services/generated/src/main/proto/storage/job-log.pb';
import { JobLogService } from 'app/services/job-log.service';

import { JOB_STATE_TO_STRING } from '../../constants/lookups';
import { BinaryType } from '../../services/generated/src/main/proto/storage/binary-type.pb';

const RECORDS_PER_PAGE = 15;

interface Page {
  continuationToken: string | null;
}

interface JobRow {
  id: string;
  description: string;
  binaryType: string;
  customers: string;
  state: string;
  creationTimestamp: string;
}

@Component({
  selector: 'app-admin-job-list',
  providers: [DatePipe],
  templateUrl: './admin-job-list.component.html',
  styleUrls: ['./admin-job-list.component.scss'],
})
export class AdminJobListComponent implements AfterViewInit, OnInit {
  binaryTypeToName = new Map<BinaryType, string>();
  customers: Customer[] | undefined;
  dataSource: MatTableDataSource<JobRow> = new MatTableDataSource();
  public columnsToDisplay: string[] = [
    'id',
    'description',
    'state',
    'binaryType',
    'customers',
    'creationTimestamp',
    'menu',
  ];
  isLoading = false;
  currentPageIndex = 0;
  jobLogs: JobLog[];
  totalRecords = 0;
  pages: Page[] = [];
  pageSize = RECORDS_PER_PAGE;
  selectedCustomerId: string | undefined;
  @ViewChild(MatSort)
  sort: MatSort = new MatSort();
  // Make BinaryType available in the template.
  readonly BinaryType = BinaryType;

  lastUpdate = this.formatService.getLastUpdate();

  constructor(
    private binaryTypeService: BinaryTypeService,
    private jobLogService: JobLogService,
    private formatService: FormatService,
    private router: Router
  ) {
    this.jobLogs = [];
  }

  async ngOnInit() {
    await this.loadBinaryTypes();
    this.loadJobs(this.currentPageIndex);
  }

  onCustomerSelect(customer: Customer) {
    if (customer) {
      this.selectedCustomerId = customer.id;
      this.loadJobs(0);
    }
  }

  async onPageChange(event: PageEvent) {
    if (event.pageIndex == 0) {
      this.loadJobs(0);
      this.currentPageIndex = 0;
    } else {
      const page = this.pages[event.pageIndex];
      if (page.continuationToken) {
        this.loadJobs(event.pageIndex, page.continuationToken);
        this.currentPageIndex = event.pageIndex;
      }
    }
  }

  async loadBinaryTypes() {
    const resp = await this.binaryTypeService.getBinaryTypes();
    if (resp.binaryTypeInfos) {
      resp.binaryTypeInfos.forEach((value) => {
        this.binaryTypeToName.set(value.binaryType, value.name);
      });
    }
  }

  async loadJobs(pageIndex = 0, token: string | null = null) {
    this.isLoading = true;
    this.dataSource.data.splice(0);
    this.jobLogs.splice(0);

    const getRequest = new GetRequest({
      paginated: new GetPaginatedRequest({
        numRecords: this.pageSize,
      }),
    });

    if (token && getRequest.paginated) {
      getRequest.paginated.continuationToken = token;
    }

    if (this.selectedCustomerId) {
      getRequest.filter = new GetRequestFilter();
      getRequest.filter.customerIds = [this.selectedCustomerId];
    }

    const response = await this.jobLogService.get(getRequest);
    response.jobLogs?.forEach((apiJobLog) => {
      const jobLog = apiJobLog!.jobLog!;
      this.jobLogs.push(jobLog);
      const roles: string[] = [];
      jobLog.customerRoles?.forEach((customerRole) => {
        if (roles.indexOf(customerRole.customerId) == -1) {
          roles.push(customerRole.customerId);
        }
      });
      this.dataSource.data.push({
        id: jobLog.id,
        description: jobLog.metadata!.description,
        binaryType: this.binaryTypeToName.get(jobLog.binaryType!) ?? '',
        customers: roles.join(', '),
        state: JOB_STATE_TO_STRING[jobLog.state!],
        creationTimestamp: jobLog.creationTimestamp
          ? jobLog.creationTimestamp.seconds
          : '',
      });
    });
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'id':
          return item.id;
        case 'description':
          return item.description;
        case 'binaryType':
          return item.binaryType;
        case 'customers':
          return item.customers;
        case 'state':
          return item.state;
        case 'creationTimestamp':
          return item.creationTimestamp;
        default:
          // Sort by creation time by default.
          return item.creationTimestamp;
      }
    };
    this.dataSource.sort = this.sort;
    this.totalRecords =
      response.paginatedResponse?.count ?? this.dataSource.data.length;

    if (response.paginatedResponse?.continuationToken) {
      this.pages[pageIndex + 1] = {
        continuationToken: response.paginatedResponse.continuationToken,
      };
    }
    this.isLoading = false;
  }

  ngAfterViewInit() {
    this.sort.sort({ id: 'creationTimestamp', start: 'desc' } as MatSortable);
  }

  async copy(id: string) {
    await this.jobLogService.clone(id);
    this.ngOnInit();
  }

  async cancelJob(id: string) {
    if (confirm('Are you sure you want to cancel this job?')) {
      try {
        await this.jobLogService.cancelJob(id);
        this.loadJobs(this.currentPageIndex);
      } catch (error) {
        console.error('Error cancelling job:', error);
      }
    }
  }

  canCancelJob(state: string): boolean {
    return state === 'Running' || state === 'Pending';
  }

  viewResults(id: string) {
    this.router.navigate([`/job-results/${id}/view`]);
  }

  displayResults(id: string) {
    const result = this.jobLogs.find((jobLog) => jobLog.id === id);

    if (result) {
      return (
        (result.state === JobState.JOB_STATE_COMPLETE ||
          result.state === JobState.JOB_STATE_ERROR) &&
        result.binaryType !== BinaryType.BINARY_TYPE_ENCLAVE_ENCRYPTION
      );
    } else {
      return false;
    }
  }

  edit(id: string, state: string) {
    if (state === 'Draft') {
      this.router.navigate([`/jobs/${id}/edit`]);
    } else {
      this.router.navigate([`/jobs/${id}/view`]);
    }
  }

  create() {
    this.router.navigate(['/jobs/new']);
  }
}
