import { Component, OnInit, Optional } from '@angular/core';
import {
  Auth,
  multiFactor,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  User,
} from '@angular/fire/auth';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import {
  MultiFactorInfo,
  PhoneMultiFactorInfo,
  RecaptchaVerifier,
} from 'firebase/auth';

import { FormHelpersService } from '../../services/form-helpers.service';
import { LoggerService } from '../../services/logger.service';
import { UserService } from '../../services/user.service';
import { WindowService } from '../../services/window.service';
import { MessageBoxProvider } from '../../views/shared/components/message-box/message-box.provider';

@Component({
  selector: 'app-multi-factor-setup-modal',
  templateUrl: './multifactor-setup-modal.component.html',
  styleUrls: [
    './multifactor-setup-modal.component.scss',
    '../../shared/shared.scss',
  ],
  providers: [WindowService],
})
export class MultifactorSetupModalComponent implements OnInit {
  multiFactorIsSet = false;
  assignedMultiFactorCount = 0;
  mfaKeyName = 'mfaphone';
  title: string;
  windowRef: any;
  user: User | undefined;
  phoneNumber: string | undefined;
  codeVerification: string | undefined;
  isLoading = false;
  verificationId: string | undefined;
  public smsSent = false;
  public multiFactorInfoList: PhoneMultiFactorInfo[] = [];
  public mfaForm: FormGroup;

  constructor(
    private dialogRef: MatDialogRef<MultifactorSetupModalComponent>,
    private analytics: AngularFireAnalytics,
    public userService: UserService,
    private formBuilder: FormBuilder,
    private messageBox: MessageBoxProvider,
    private logger: LoggerService,
    private router: Router,
    @Optional() private auth: Auth,
    private win: WindowService,
    private formHelpers: FormHelpersService
  ) {
    this.title = 'Two-Factor Setup';

    this.mfaForm = this.formBuilder.group({
      phoneNumber: new FormControl('', {
        validators: [Validators.required, Validators.pattern('\\+[0-9]14')],
        nonNullable: true,
      }),
      codeVerification: new FormControl('', {
        validators: [Validators.required],
        nonNullable: true,
      }),
    });
    this.mfaForm.get('codeVerification')?.disable();
  }

  async ngOnInit() {
    this.windowRef = this.win.windowRef;

    this.auth.onAuthStateChanged((user) => {
      if (user === null) {
        this.router.navigate(['sign-in-options'], {
          queryParams: { redirect: this.router.url[0] },
        });
      } else {
        this.user = user;
        this.auth.tenantId = user.tenantId;

        this.getAssignedFactors();

        this.mfaForm.setValue({
          phoneNumber: user.phoneNumber,
          codeVerification: '',
        });

        const recaptchaVerifier = new RecaptchaVerifier(
          this.auth,
          'recaptcha-container',
          {
            size: 'invisible',
            callback: () => {
              this.messageBox.success('reCaptcha success');
            },
            'expired-callback': () => {
              this.logger.error('Expired reCaptch');
              this.messageBox.success(
                'reCaptcha timeout, please retry reCaptcha'
              );
            },
          }
        );
        this.windowRef.recaptchaVerifier = recaptchaVerifier;
      }
    });
  }

  public checkPhoneNumber() {
    const { phoneNumber } = this.mfaForm.value;
    return this.formHelpers.validatePhoneNumForE164(
      this.cleanPhoneNumber(phoneNumber)
    );
  }

  private cleanPhoneNumber(phoneNumber: string): string {
    return phoneNumber
      .replace('-', '')
      .replace('(', '')
      .replace(')', '')
      .replace(' ', '');
  }

  async sendLoginCode() {
    this.isLoading = true;
    if (this.user) {
      const multiFactorSession = await multiFactor(this.user).getSession();
      // Specify the phone number and pass the MFA session.
      const { phoneNumber } = this.mfaForm.value;
      const phoneInfoOptions = {
        phoneNumber: this.cleanPhoneNumber(phoneNumber),
        session: multiFactorSession,
      };

      const phoneAuthProvider = new PhoneAuthProvider(this.auth);

      // Send SMS verification code.
      phoneAuthProvider
        .verifyPhoneNumber(phoneInfoOptions, this.windowRef.recaptchaVerifier)
        .then((verificationId: string) => {
          this.verificationId = verificationId;
          this.smsSent = true;
          this.mfaForm.get('codeVerification')?.enable();
          this.mfaForm.get('codeVerification')?.markAsTouched();
          this.messageBox.success('A code has been sent to your mobile device');
          this.analytics.logEvent('veryfing-2FA-phone-number');
        })
        .catch((error) => {
          this.smsSent = false;
          this.windowRef.recaptchaVerifier.clear();
          switch (error.code) {
            case 'auth/invalid-phone-number':
              this.messageBox.error('Phone number is invalid');
              break;
            case 'auth/requires-recent-login':
              // This can be improved by automatically logging the user out
              // then redirecting to login, a redirect param has to be added for this to work.
              this.messageBox.error('Please re-login before setting MFA');
              break;
            case 'auth/second-factor-already-in-use':
              this.messageBox.error('This phone number is already set for MFA');
              break;
            default:
              this.logger.error(error);
              this.messageBox.error(error.message);
          }
          this.analytics.logEvent('error-veryfing-2FA-phone-number');
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  }

  async verifyLoginCode() {
    this.isLoading = true;
    const { codeVerification } = this.mfaForm.value;

    const cred = PhoneAuthProvider.credential(
      this.verificationId!,
      codeVerification
    );
    const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);

    multiFactor(this.user!)
      .enroll(multiFactorAssertion, this.mfaKeyName)
      .then(() => {
        this.messageBox.success(
          'Enrolled two-factor phone number authentication.'
        );
        this.getAssignedFactors();
        this.analytics.logEvent('enrolled-2FA-phone-number');
      })
      .catch((error) => {
        if (error.code === 'auth/invalid-verification-code') {
          this.messageBox.error('Invalid verification code');
        } else {
          this.logger.error(error);
          this.messageBox.error(
            'Failed to enroll in two-factor authentication.'
          );
        }
        this.analytics.logEvent('error-enrolling-2FA-phone-number');
      })
      .finally(() => {
        this.isLoading = false;
        this.mfaForm.setValue({
          codeVerification: '',
          phoneNumber: this.user!.phoneNumber,
        });
      });
  }

  disableMultiFactor(multiFactorInfo: MultiFactorInfo) {
    this.isLoading = true;
    multiFactor(this.user!)
      .unenroll(multiFactorInfo)
      .then(() => {
        this.messageBox.success(
          'Disabled two-factor authentication for: ' + multiFactorInfo.uid
        );
        this.analytics.logEvent('unenrolled-2FA-phone-number');
        this.userService.signOut();
        this.close();
      })
      .catch((error) => {
        if (error.code === 'auth/requires-recent-login') {
          this.messageBox.error('Please login again before removing factor');
        } else {
          this.messageBox.error(
            'Unable to remove factor with Id: ' + multiFactorInfo.uid
          );
        }
        this.analytics.logEvent('error-unenrolling-2FA-phone-number');
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  getAssignedFactors() {
    this.multiFactorIsSet = false;
    this.multiFactorInfoList = [];
    multiFactor(this.user!).enrolledFactors.forEach((factor) => {
      if (
        factor.factorId === PhoneAuthProvider.PROVIDER_ID &&
        factor.displayName === this.mfaKeyName
      ) {
        this.multiFactorIsSet = true;
        this.multiFactorInfoList.push(factor as PhoneMultiFactorInfo);
      }
    });
    this.assignedMultiFactorCount = multiFactor(
      this.user!
    ).enrolledFactors.length;
  }

  public close() {
    this.dialogRef.close();
  }
}
