/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import {
  GrpcMessage,
  RecursivePartial,
  ToProtobufJSONOptions,
} from '@ngx-grpc/common';
import { BinaryReader, BinaryWriter, ByteSource } from 'google-protobuf';
import * as googleProtobuf000 from '@ngx-grpc/well-known-types';
import * as googleProtobuf001 from '@ngx-grpc/well-known-types';
import * as storage002 from '../../../../src/main/proto/storage/schema-annotations.pb';
/**
 * Message implementation for storage.JobSchedule
 */
export class JobSchedule implements GrpcMessage {
  static id = 'storage.JobSchedule';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new JobSchedule();
    JobSchedule.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: JobSchedule) {
    _instance.id = _instance.id || '';
    _instance.name = _instance.name || '';
    _instance.jobLogId = _instance.jobLogId || '';
    _instance.enabled = _instance.enabled || false;
    _instance.archived = _instance.archived || false;
    _instance.startTime = _instance.startTime || undefined;
    _instance.etag = _instance.etag || '';
    _instance.lastUpdate = _instance.lastUpdate || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: JobSchedule,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readString();
          break;
        case 2:
          _instance.name = _reader.readString();
          break;
        case 3:
          _instance.jobLogId = _reader.readString();
          break;
        case 6:
          _instance.enabled = _reader.readBool();
          break;
        case 7:
          _instance.archived = _reader.readBool();
          break;
        case 8:
          _instance.startTime = new TimeOfDay();
          _reader.readMessage(
            _instance.startTime,
            TimeOfDay.deserializeBinaryFromReader
          );
          break;
        case 4:
          _instance.etag = _reader.readString();
          break;
        case 5:
          _instance.lastUpdate = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.lastUpdate,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    JobSchedule.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: JobSchedule,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeString(1, _instance.id);
    }
    if (_instance.name) {
      _writer.writeString(2, _instance.name);
    }
    if (_instance.jobLogId) {
      _writer.writeString(3, _instance.jobLogId);
    }
    if (_instance.enabled) {
      _writer.writeBool(6, _instance.enabled);
    }
    if (_instance.archived) {
      _writer.writeBool(7, _instance.archived);
    }
    if (_instance.startTime) {
      _writer.writeMessage(
        8,
        _instance.startTime as any,
        TimeOfDay.serializeBinaryToWriter
      );
    }
    if (_instance.etag) {
      _writer.writeString(4, _instance.etag);
    }
    if (_instance.lastUpdate) {
      _writer.writeMessage(
        5,
        _instance.lastUpdate as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
  }

  private _id: string;
  private _name: string;
  private _jobLogId: string;
  private _enabled: boolean;
  private _archived: boolean;
  private _startTime?: TimeOfDay;
  private _etag: string;
  private _lastUpdate?: googleProtobuf001.Timestamp;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of JobSchedule to deeply clone from
   */
  constructor(_value?: RecursivePartial<JobSchedule.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    this.name = _value.name;
    this.jobLogId = _value.jobLogId;
    this.enabled = _value.enabled;
    this.archived = _value.archived;
    this.startTime = _value.startTime
      ? new TimeOfDay(_value.startTime)
      : undefined;
    this.etag = _value.etag;
    this.lastUpdate = _value.lastUpdate
      ? new googleProtobuf001.Timestamp(_value.lastUpdate)
      : undefined;
    JobSchedule.refineValues(this);
  }
  get id(): string {
    return this._id;
  }
  set id(value: string) {
    this._id = value;
  }
  get name(): string {
    return this._name;
  }
  set name(value: string) {
    this._name = value;
  }
  get jobLogId(): string {
    return this._jobLogId;
  }
  set jobLogId(value: string) {
    this._jobLogId = value;
  }
  get enabled(): boolean {
    return this._enabled;
  }
  set enabled(value: boolean) {
    this._enabled = value;
  }
  get archived(): boolean {
    return this._archived;
  }
  set archived(value: boolean) {
    this._archived = value;
  }
  get startTime(): TimeOfDay | undefined {
    return this._startTime;
  }
  set startTime(value: TimeOfDay | undefined) {
    this._startTime = value;
  }
  get etag(): string {
    return this._etag;
  }
  set etag(value: string) {
    this._etag = value;
  }
  get lastUpdate(): googleProtobuf001.Timestamp | undefined {
    return this._lastUpdate;
  }
  set lastUpdate(value: googleProtobuf001.Timestamp | undefined) {
    this._lastUpdate = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    JobSchedule.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): JobSchedule.AsObject {
    return {
      id: this.id,
      name: this.name,
      jobLogId: this.jobLogId,
      enabled: this.enabled,
      archived: this.archived,
      startTime: this.startTime ? this.startTime.toObject() : undefined,
      etag: this.etag,
      lastUpdate: this.lastUpdate ? this.lastUpdate.toObject() : undefined,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): JobSchedule.AsProtobufJSON {
    return {
      id: this.id,
      name: this.name,
      jobLogId: this.jobLogId,
      enabled: this.enabled,
      archived: this.archived,
      startTime: this.startTime ? this.startTime.toProtobufJSON(options) : null,
      etag: this.etag,
      lastUpdate: this.lastUpdate
        ? this.lastUpdate.toProtobufJSON(options)
        : null,
    };
  }
}
export module JobSchedule {
  /**
   * Standard JavaScript object representation for JobSchedule
   */
  export interface AsObject {
    id: string;
    name: string;
    jobLogId: string;
    enabled: boolean;
    archived: boolean;
    startTime?: TimeOfDay.AsObject;
    etag: string;
    lastUpdate?: googleProtobuf001.Timestamp.AsObject;
  }

  /**
   * Protobuf JSON representation for JobSchedule
   */
  export interface AsProtobufJSON {
    id: string;
    name: string;
    jobLogId: string;
    enabled: boolean;
    archived: boolean;
    startTime: TimeOfDay.AsProtobufJSON | null;
    etag: string;
    lastUpdate: googleProtobuf001.Timestamp.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for storage.TimeOfDay
 */
export class TimeOfDay implements GrpcMessage {
  static id = 'storage.TimeOfDay';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new TimeOfDay();
    TimeOfDay.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: TimeOfDay) {
    _instance.startHour = _instance.startHour || 0;
    _instance.startMinute = _instance.startMinute || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: TimeOfDay,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 4:
          _instance.startHour = _reader.readInt32();
          break;
        case 5:
          _instance.startMinute = _reader.readInt32();
          break;
        default:
          _reader.skipField();
      }
    }

    TimeOfDay.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(_instance: TimeOfDay, _writer: BinaryWriter) {
    if (_instance.startHour) {
      _writer.writeInt32(4, _instance.startHour);
    }
    if (_instance.startMinute) {
      _writer.writeInt32(5, _instance.startMinute);
    }
  }

  private _startHour: number;
  private _startMinute: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of TimeOfDay to deeply clone from
   */
  constructor(_value?: RecursivePartial<TimeOfDay.AsObject>) {
    _value = _value || {};
    this.startHour = _value.startHour;
    this.startMinute = _value.startMinute;
    TimeOfDay.refineValues(this);
  }
  get startHour(): number {
    return this._startHour;
  }
  set startHour(value: number) {
    this._startHour = value;
  }
  get startMinute(): number {
    return this._startMinute;
  }
  set startMinute(value: number) {
    this._startMinute = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    TimeOfDay.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): TimeOfDay.AsObject {
    return {
      startHour: this.startHour,
      startMinute: this.startMinute,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): TimeOfDay.AsProtobufJSON {
    return {
      startHour: this.startHour,
      startMinute: this.startMinute,
    };
  }
}
export module TimeOfDay {
  /**
   * Standard JavaScript object representation for TimeOfDay
   */
  export interface AsObject {
    startHour: number;
    startMinute: number;
  }

  /**
   * Protobuf JSON representation for TimeOfDay
   */
  export interface AsProtobufJSON {
    startHour: number;
    startMinute: number;
  }
}
