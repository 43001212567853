<mat-form-field>
  <mat-label>Filter</mat-label>
  <input
    matInput
    (keyup)="applyFilter($event)"
    placeholder="Type in anything ..."
    #input
  />
</mat-form-field>

<mat-card *ngIf="displayColumnFilter">
  <div class="field-dropdown-label">
    <span class="field-title">Column selection</span>
  </div>
  <div>
    <ng-container *ngFor="let column of canonicalColumns">
      <span class="field-checkbox-spacer">
        <mat-checkbox
          checked="true"
          (change)="onToggleColumn(column, $event.checked)"
          >{{ getColumnLabel(column) }}</mat-checkbox
        >
      </span>
    </ng-container>
  </div>
</mat-card>

<app-message-box></app-message-box>
<p><i>Data displayed is limited to 1000 records.</i></p>
<div class="table">
  <table
    mat-table
    [dataSource]="dataSource"
    matSort
    (matSortChange)="announceSortChange($event)"
  >
    <div *ngFor="let displayedColumn of displayedColumns">
      <!-- {{ displayColumn }} Column -->
      <ng-container matColumnDef="{{ displayedColumn }}">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header="{{ displayedColumn }}"
        >
          <b>{{ getColumnLabel(displayedColumn) }}</b>
        </th>
        <td mat-cell *matCellDef="let row">{{ row[displayedColumn] }}</td>
      </ng-container>
    </div>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

    <!-- Row shown when there is no matching data. -->
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4">
        No data matching the filter "{{ input.value }}"
      </td>
    </tr>
  </table>

  <mat-paginator
    [pageSizeOptions]="[10, 25, 50, 100]"
    aria-label="Select page of document."
  ></mat-paginator>
</div>
