import { Component } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { TenantInfo } from 'app/services/generated/src/main/proto/api/customer-service.pb';
import { LoggerService } from 'app/services/logger.service';

import { TenantSelectModalComponent } from '../../modals/tenant-select-modal/tenant-select.component';
import { CustomerService } from '../../services/customer.service';
import { UserService } from '../../services/user.service';
import { MessageBoxProvider } from '../shared/components/message-box/message-box.provider';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent {
  isLoading = false;
  form: FormGroup = new FormGroup({
    email: new FormControl<string>('', [Validators.required]),
  });

  constructor(
    private activatedRoute: ActivatedRoute,
    private analytics: AngularFireAnalytics,
    private customerService: CustomerService,
    private dialog: MatDialog,
    private logger: LoggerService,
    private messageBox: MessageBoxProvider,
    public userService: UserService
  ) {
    const email = this.activatedRoute.snapshot.queryParams['email'];
    if (email) {
      this.form.setValue({
        email: email,
      });
    }
  }

  async process() {
    const { email } = this.form.value;

    const cleanEmail = email.trim();

    if (!cleanEmail) {
      this.messageBox.error('Please, type in your email first.');
      this.isLoading = false;
      return;
    }

    const tenantResponse = await this.customerService.getTenantId(cleanEmail);

    if (tenantResponse.tenantInfos?.length == 1) {
      this.resetPassword(cleanEmail, tenantResponse.tenantInfos[0].tenantId);
    } else {
      this.openTenantSelectorModule(cleanEmail, tenantResponse.tenantInfos);
    }
  }

  resetPassword(email: string, tenantId: string) {
    this.isLoading = true;

    this.userService
      .resetPasswordInit(email, tenantId)
      .then(
        () => {
          this.analytics.logEvent('reset-password-email-sent');
          this.messageBox.success(
            'A password reset link has been sent to your email address.'
          );
        },
        (rejectionReason) => {
          this.logger.error(
            'Error sending the reset password link email',
            rejectionReason
          );
          this.messageBox.error(
            'An error occurred while attempting to send the reset password link'
          );
        }
      )
      .catch((error) => {
        this.logger.error('Error sending the reset password link email', error);
        this.messageBox.error(
          'An error occurred while attempting to send the reset password link'
        );
      })
      .finally(() => (this.isLoading = false));
  }

  public openTenantSelectorModule(
    email: string,
    tenantInfos: TenantInfo[] | undefined
  ) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = tenantInfos;

    if (tenantInfos !== undefined) {
      this.dialog
        .open(TenantSelectModalComponent, dialogConfig)
        .afterClosed()
        .subscribe((tenantId: string) => {
          if (tenantId) {
            this.resetPassword(email, tenantId);
          }
        });
    } else {
      this.messageBox.error('Server error, Tenant Id missing');
      this.logger.error('Tenant Id missing from server response');
    }
  }
}
