<app-container>
  <app-page-header title="Job Results" lastUpdated="{{ lastUpdate }}">
  </app-page-header>
  <app-message-box></app-message-box>

  <section class="data-table-container" tabindex="0">
    <div *ngIf="isLoading" class="loader">
      <mat-spinner data-testid="datasetsSpinner" diameter="42"></mat-spinner>
    </div>

    <mat-tab-group>
      <mat-tab
        *ngIf="
          isDisplayed(jobResultFileTypeList.JOB_RESULT_FILE_TYPE_LOGS_JSON)
        "
        label="{{
          getJobFileTypeResultLabel(
            jobResultFileTypeList.JOB_RESULT_FILE_TYPE_LOGS_JSON
          )
        }}"
      >
        <mat-card appearance="outlined">
          <div class="card-header">
            <div>
              <mat-card-header>
                <h2 class="data-users-title">
                  {{
                    getJobFileTypeResultLabel(
                      jobResultFileTypeList.JOB_RESULT_FILE_TYPE_LOGS_JSON
                    )
                  }}
                </h2>
              </mat-card-header>
            </div>
            <div>
              <button
                class="toggle-btn"
                mat-icon-button
                color="primary"
                (click)="
                  download(jobResultFileTypeList.JOB_RESULT_FILE_TYPE_LOGS_JSON)
                "
                matTooltip="Download Json log file."
                #tooltipTarget="matTooltip"
                (click)="tooltipTarget.show()"
              >
                <mat-icon class="download-icon">cloud_download</mat-icon>
              </button>
            </div>
          </div>
          <pre><code [highlight]="formatJson(getContent(jobResultFileTypeList.JOB_RESULT_FILE_TYPE_LOGS_JSON))" language="json"></code></pre>
        </mat-card>
      </mat-tab>
      <mat-tab
        *ngIf="
          isDisplayed(
            jobResultFileTypeList.JOB_RESULT_FILE_TYPE_MEASUREMENTS_CSV
          )
        "
        label="{{
          getJobFileTypeResultLabel(
            jobResultFileTypeList.JOB_RESULT_FILE_TYPE_MEASUREMENTS_CSV
          )
        }}"
      >
        <mat-card appearance="outlined">
          <div class="card-header">
            <div>
              <mat-card-header>
                <h2 class="data-users-title">
                  {{
                    getJobFileTypeResultLabel(
                      jobResultFileTypeList.JOB_RESULT_FILE_TYPE_MEASUREMENTS_CSV
                    )
                  }}
                </h2>
              </mat-card-header>
            </div>
            <div>
              <button
                class="toggle-btn"
                mat-icon-button
                color="primary"
                (click)="
                  download(
                    jobResultFileTypeList.JOB_RESULT_FILE_TYPE_MEASUREMENTS_CSV
                  )
                "
                matTooltip="Download CSV file."
                #tooltipTarget="matTooltip"
                (click)="tooltipTarget.show()"
              >
                <mat-icon class="download-icon">cloud_download</mat-icon>
              </button>
            </div>
          </div>
          <app-csv-viewer
            [csv]="
              getContent(
                jobResultFileTypeList.JOB_RESULT_FILE_TYPE_MEASUREMENTS_CSV
              )
            "
            [title]="
              getJobFileTypeResultLabel(
                jobResultFileTypeList.JOB_RESULT_FILE_TYPE_MEASUREMENTS_CSV
              )
            "
            [displayColumnFilter]="true"
            class="viewer"
          ></app-csv-viewer>
        </mat-card>
      </mat-tab>
      <mat-tab
        *ngIf="
          isDisplayed(
            jobResultFileTypeList.JOB_RESULT_FILE_TYPE_PERFORMANCE_CSV
          )
        "
        label="{{
          getJobFileTypeResultLabel(
            jobResultFileTypeList.JOB_RESULT_FILE_TYPE_PERFORMANCE_CSV
          )
        }}"
      >
        <mat-card appearance="outlined">
          <div class="card-header">
            <div>
              <mat-card-header>
                <h2 class="data-users-title">
                  {{
                    getJobFileTypeResultLabel(
                      jobResultFileTypeList.JOB_RESULT_FILE_TYPE_PERFORMANCE_CSV
                    )
                  }}
                </h2>
              </mat-card-header>
            </div>
            <div>
              <button
                class="toggle-btn"
                mat-icon-button
                color="primary"
                (click)="
                  download(
                    jobResultFileTypeList.JOB_RESULT_FILE_TYPE_PERFORMANCE_CSV
                  )
                "
                matTooltip="Download csv file."
                #tooltipTarget="matTooltip"
                (click)="tooltipTarget.show()"
              >
                <mat-icon class="download-icon">cloud_download</mat-icon>
              </button>
            </div>
          </div>
          <app-csv-viewer
            [csv]="
              getContent(
                jobResultFileTypeList.JOB_RESULT_FILE_TYPE_PERFORMANCE_CSV
              )
            "
            [title]="
              getJobFileTypeResultLabel(
                jobResultFileTypeList.JOB_RESULT_FILE_TYPE_PERFORMANCE_CSV
              )
            "
            [displayColumnFilter]="true"
            class="viewer"
          ></app-csv-viewer>
        </mat-card>
      </mat-tab>
      <mat-tab
        *ngIf="
          isDisplayed(jobResultFileTypeList.JOB_RESULT_FILE_TYPE_OUTPUT_JSON)
        "
        label="{{
          getJobFileTypeResultLabel(
            jobResultFileTypeList.JOB_RESULT_FILE_TYPE_OUTPUT_JSON
          )
        }}"
      >
        <mat-card appearance="outlined">
          <div class="card-header">
            <div>
              <mat-card-header>
                <h2 class="data-users-title">
                  {{
                    getJobFileTypeResultLabel(
                      jobResultFileTypeList.JOB_RESULT_FILE_TYPE_OUTPUT_JSON
                    )
                  }}
                </h2>
              </mat-card-header>
            </div>
            <div>
              <button
                class="toggle-btn"
                mat-icon-button
                color="primary"
                (click)="
                  download(
                    jobResultFileTypeList.JOB_RESULT_FILE_TYPE_OUTPUT_JSON
                  )
                "
                matTooltip="Download json file."
                #tooltipTarget="matTooltip"
                (click)="tooltipTarget.show()"
              >
                <mat-icon class="download-icon">cloud_download</mat-icon>
              </button>
            </div>
          </div>
          <pre><code [highlight]="formatJson(getContent(jobResultFileTypeList.JOB_RESULT_FILE_TYPE_OUTPUT_JSON))" language="json"></code></pre>
        </mat-card>
      </mat-tab>
    </mat-tab-group>
  </section>
</app-container>
