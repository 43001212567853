import { Component, Inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

import { validateKeyParameters } from './keys-validation';

// Wraps Set class to check for existing keys.
export class KeyLookup {
  private keyNames = new Set<string>();

  add(customerId: string, name: string) {
    this.keyNames.add(this.format(customerId, name));
  }

  hasKey(customerId: string, name: string): boolean {
    return this.keyNames.has(this.format(customerId, name));
  }

  private format(customerId: string, name: string): string {
    return `${customerId}+${name}`;
  }
}

export interface RegisterKeyModalData {
  customerId: string;
  keyName: string;
  hsmUrl: string;
  existingKeyNames?: KeyLookup;
  expiry?: string;
  beforeExpiry?: string;
}

/** Key registration dialog. */
@Component({
  selector: 'app-register-key-modal',
  templateUrl: 'register-key-modal.html',
  styleUrls: ['register-key-modal.scss'],
  standalone: true,
  imports: [
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatButtonModule,
  ],
})
export class RegisterKeyModalComponent {
  constructor(
    public dialogRef: MatDialogRef<RegisterKeyModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: RegisterKeyModalData
  ) {}

  onCancel(): void {
    this.dialogRef.close();
  }

  isDataValid(): boolean {
    // Key name must not be empty and should not exist.
    if (
      !this.data.customerId ||
      !this.data.keyName ||
      (this.data.existingKeyNames &&
        this.data.existingKeyNames.hasKey(
          this.data.customerId,
          this.data.keyName
        ))
    ) {
      return false;
    }

    return validateKeyParameters(this.data.expiry, this.data.beforeExpiry);
  }
}
