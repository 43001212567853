<app-container>
  <app-page-header
    title="Notifications"
    lastUpdated="{{ time }}"
  ></app-page-header>
  <app-message-box></app-message-box>
  <div *ngIf="!notifications || notifications.length === 0">
    <h3>You don't have any notifications.</h3>
  </div>
  <div *ngIf="isLoading" class="loader">
    <mat-spinner diameter="42"></mat-spinner>
  </div>

  <div class="box">
    <mat-tab-group
      (selectedTabChange)="showAll($event)"
      mat-stretch-tabs="false"
      mat-align-tabs="start"
    >
      <mat-tab label="Unread"> </mat-tab>
      <mat-tab label="All"></mat-tab>
    </mat-tab-group>

    <div *ngFor="let notification of notifications">
      <mat-card
        appearance="outlined"
        id="{{ notification.messagePayload.messageId }}"
        *ngIf="(!isAllVisible && !notification.viewed) || isAllVisible"
      >
        <div
          class="card-header"
          (click)="markAsRead(notification.messagePayload.messageId)"
        >
          <mat-card-header class="notification-title">
            <img
              class="icon"
              src="./assets/unread.svg"
              *ngIf="!notification.viewed"
            />
            <div class="icon" *ngIf="notification.viewed"></div>
            {{ notification.messagePayload.notification?.title }}
          </mat-card-header>
          <div class="notification-footer">
            Received:
            {{ notification.receivedAt.toDate().toLocaleDateString() }}
          </div>
        </div>
        <mat-card-content class="notification-body">
          <p>{{ notification.messagePayload.notification?.body }}</p>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</app-container>
