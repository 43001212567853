import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormatService } from 'app/services/format.service';
import {
  GetJobResultsRequest,
  JobResultFile,
  JobResultFileType,
} from 'app/services/generated/src/main/proto/api/job-log-service.pb';
import { JobLogService } from 'app/services/job-log.service';
import { LoggerService } from 'app/services/logger.service';
import { Highlight, HighlightAuto } from 'ngx-highlightjs';

import { JobResultFileTypeList } from '../../constants/lookups';
import { MessageBoxProvider } from '../shared/components/message-box/message-box.provider';

@Component({
  selector: 'app-admin-job-results',
  providers: [DatePipe, Highlight, HighlightAuto],
  templateUrl: './admin-job-results.component.html',
  styleUrls: ['./admin-job-results.component.scss'],
})
export class AdminJobResultsComponent implements OnInit {
  contents = new Map();
  currentFileView: JobResultFile | undefined;
  isLoading = false;
  jobId: string | null = null;
  jobResultFileTypeList = JobResultFileType;
  jobResults: JobResultFile[] | undefined;
  outputJson: any;
  lastUpdate = this.formatService.getLastUpdate();
  jobResultFileTypeDisplay = new Map();
  csvRecords: any;
  header: boolean = false;

  constructor(
    private formatService: FormatService,
    private jobLogService: JobLogService,
    private logger: LoggerService,
    private messageBox: MessageBoxProvider,
    private router: ActivatedRoute
  ) {
    this.jobResultFileTypeDisplay.set(
      JobResultFileType.JOB_RESULT_FILE_TYPE_LOGS_JSON,
      false
    );
    this.jobResultFileTypeDisplay.set(
      JobResultFileType.JOB_RESULT_FILE_TYPE_MEASUREMENTS_CSV,
      false
    );
    this.jobResultFileTypeDisplay.set(
      JobResultFileType.JOB_RESULT_FILE_TYPE_OUTPUT_JSON,
      false
    );
    this.jobResultFileTypeDisplay.set(
      JobResultFileType.JOB_RESULT_FILE_TYPE_PERFORMANCE_CSV,
      false
    );
  }

  async ngOnInit() {
    this.jobId = this.router.snapshot.paramMap.get('id');

    if (this.jobId) {
      this.viewResults(this.jobId);
    } else {
      this.messageBox.error(
        'The Job Id parameter is missing from the request.'
      );
    }
  }

  async viewResults(id: string) {
    this.isLoading = true;

    try {
      const getJobsResultsRequest = new GetJobResultsRequest();
      getJobsResultsRequest.jobLogId = id;

      const response = await this.jobLogService.getJobResults(
        getJobsResultsRequest
      );
      this.jobResults = response.jobResultFiles;
      if (this.jobResults) {
        this.jobResults.forEach((result) => {
          this.jobResultFileTypeDisplay.set(result.jobResultFileType, true);
        });
      }
    } catch (error) {
      this.logger.error('Failed to load job results with error: {}', error);
    } finally {
      this.isLoading = false;
    }
  }

  decodeFileType(jobResultFileType: JobResultFileType) {
    return JobResultFileTypeList.find(
      (fileType) => fileType.value === jobResultFileType
    )?.name;
  }

  getJobFileTypeResultLabel(selectedJobResultFileType: JobResultFileType) {
    const result = JobResultFileTypeList.find(
      (jobResultFileType) =>
        jobResultFileType.value == selectedJobResultFileType
    );
    if (result) {
      return result.name;
    } else {
      return 'Unknown';
    }
  }

  getDownloadFileName(selectedJobResultFileType: JobResultFileType) {
    const result = JobResultFileTypeList.find(
      (jobResultFileType) =>
        jobResultFileType.value == selectedJobResultFileType
    );
    if (result) {
      return result.fileName;
    } else {
      return undefined;
    }
  }

  view(jobResultFile: JobResultFile) {
    this.currentFileView = jobResultFile;
  }

  download(selectedJobResultFileType: JobResultFileType) {
    if (this.jobResults) {
      const result = this.jobResults.find(
        (jobResult) => jobResult.jobResultFileType == selectedJobResultFileType
      );
      if (result) {
        const hiddenElement = document.createElement('a');
        hiddenElement.href =
          'data:attachment/text,' + encodeURI(result.fileContents);
        hiddenElement.target = '_blank';
        const downloadFileName = this.getDownloadFileName(
          selectedJobResultFileType
        );
        if (downloadFileName) {
          hiddenElement.download = `${this.jobId}-${downloadFileName}`;
          hiddenElement.click();
        } else {
          this.messageBox.error('Filename not available for this file type.');
        }
      } else {
        this.messageBox.error('Content is not available for download.');
      }
    }
  }

  getContent(selectedJobResultFileType: JobResultFileType) {
    if (this.jobResults) {
      const result = this.jobResults.find(
        (jobResult) => jobResult.jobResultFileType == selectedJobResultFileType
      );
      if (result) {
        return result.fileContents;
      }
    }
    return undefined;
  }

  formatJson(content: string | undefined) {
    return content ? JSON.stringify(JSON.parse(content), null, 2) : '{}';
  }

  isDisplayed(jobResultFileType: JobResultFileType) {
    return this.jobResultFileTypeDisplay.get(jobResultFileType);
  }
}
