<app-container>
  <app-page-header title="Job Debugger"></app-page-header>
  <div mat-dialog-content>
    <div class="field-row">
      <div class="field-name-container">
        <div>
          <label class="field-title">Job Id: </label>
          <input
            class="app-input"
            type="text"
            placeholder="Paste Job Log ID here"
            size="40"
            #filter
          />
        </div>
        <div class="save-btn" mat-dialog-actions>
          <button
            mat-flat-button
            color="primary"
            [disabled]="isLoading"
            type="submit"
            data-testid="saveBtn"
            (click)="search(filter.value)"
          >
            Search
            <span *ngIf="isLoading" class="spinner">
              <mat-spinner diameter="24"></mat-spinner>
            </span>
          </button>
        </div>
      </div>
    </div>
    <app-message-box></app-message-box>
  </div>

  <div>
    Job Log:
    <mat-card appearance="outlined">
      <mat-card-content>
        <pre #jobLog></pre>
      </mat-card-content>
    </mat-card>

    Approval Request:
    <mat-card appearance="outlined">
      <mat-card-content>
        <pre #approvalRequest></pre>
      </mat-card-content>
    </mat-card>

    Publisher Customer Data Set:
    <mat-card appearance="outlined">
      <mat-card-content>
        <pre #publisherCustomerDataSet></pre>
      </mat-card-content>
    </mat-card>

    Advertiser Customer Data Set:
    <mat-card appearance="outlined">
      <mat-card-content>
        <pre #advertiserCustomerDataSet></pre>
      </mat-card-content>
    </mat-card>

    Publisher Key:
    <mat-card appearance="outlined">
      <mat-card-content>
        <pre #publisherKey></pre>
      </mat-card-content>
    </mat-card>

    Advertiser Key:
    <mat-card appearance="outlined">
      <mat-card-content>
        <pre #advertiserKey></pre>
      </mat-card-content>
    </mat-card>
  </div>
</app-container>
