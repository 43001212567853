/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import {
  GrpcMessage,
  RecursivePartial,
  ToProtobufJSONOptions,
} from '@ngx-grpc/common';
import { BinaryReader, BinaryWriter, ByteSource } from 'google-protobuf';
import * as googleProtobuf000 from '@ngx-grpc/well-known-types';
import * as storage001 from '../../../../src/main/proto/storage/binary-type.pb';
import * as storage002 from '../../../../src/main/proto/storage/schema-annotations.pb';
import * as api003 from '../../../../src/main/proto/api/headers.pb';
import * as api004 from '../../../../src/main/proto/api/pagination.pb';
import * as storage005 from '../../../../src/main/proto/storage/approval.pb';
/**
 * Message implementation for api.approval.CreateRequest
 */
export class CreateRequest implements GrpcMessage {
  static id = 'api.approval.CreateRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new CreateRequest();
    CreateRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: CreateRequest) {
    _instance.header = _instance.header || undefined;
    _instance.approvalRequest = _instance.approvalRequest || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: CreateRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api003.RequestHeader();
          _reader.readMessage(
            _instance.header,
            api003.RequestHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.approvalRequest = new storage005.ApprovalRequest();
          _reader.readMessage(
            _instance.approvalRequest,
            storage005.ApprovalRequest.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    CreateRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: CreateRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api003.RequestHeader.serializeBinaryToWriter
      );
    }
    if (_instance.approvalRequest) {
      _writer.writeMessage(
        2,
        _instance.approvalRequest as any,
        storage005.ApprovalRequest.serializeBinaryToWriter
      );
    }
  }

  private _header?: api003.RequestHeader;
  private _approvalRequest?: storage005.ApprovalRequest;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of CreateRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<CreateRequest.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api003.RequestHeader(_value.header)
      : undefined;
    this.approvalRequest = _value.approvalRequest
      ? new storage005.ApprovalRequest(_value.approvalRequest)
      : undefined;
    CreateRequest.refineValues(this);
  }
  get header(): api003.RequestHeader | undefined {
    return this._header;
  }
  set header(value: api003.RequestHeader | undefined) {
    this._header = value;
  }
  get approvalRequest(): storage005.ApprovalRequest | undefined {
    return this._approvalRequest;
  }
  set approvalRequest(value: storage005.ApprovalRequest | undefined) {
    this._approvalRequest = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    CreateRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): CreateRequest.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      approvalRequest: this.approvalRequest
        ? this.approvalRequest.toObject()
        : undefined,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): CreateRequest.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      approvalRequest: this.approvalRequest
        ? this.approvalRequest.toProtobufJSON(options)
        : null,
    };
  }
}
export module CreateRequest {
  /**
   * Standard JavaScript object representation for CreateRequest
   */
  export interface AsObject {
    header?: api003.RequestHeader.AsObject;
    approvalRequest?: storage005.ApprovalRequest.AsObject;
  }

  /**
   * Protobuf JSON representation for CreateRequest
   */
  export interface AsProtobufJSON {
    header: api003.RequestHeader.AsProtobufJSON | null;
    approvalRequest: storage005.ApprovalRequest.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for api.approval.CreateResponse
 */
export class CreateResponse implements GrpcMessage {
  static id = 'api.approval.CreateResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new CreateResponse();
    CreateResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: CreateResponse) {
    _instance.header = _instance.header || undefined;
    _instance.id = _instance.id || '';
    _instance.etag = _instance.etag || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: CreateResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api003.ResponseHeader();
          _reader.readMessage(
            _instance.header,
            api003.ResponseHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.id = _reader.readString();
          break;
        case 3:
          _instance.etag = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    CreateResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: CreateResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api003.ResponseHeader.serializeBinaryToWriter
      );
    }
    if (_instance.id) {
      _writer.writeString(2, _instance.id);
    }
    if (_instance.etag) {
      _writer.writeString(3, _instance.etag);
    }
  }

  private _header?: api003.ResponseHeader;
  private _id: string;
  private _etag: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of CreateResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<CreateResponse.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api003.ResponseHeader(_value.header)
      : undefined;
    this.id = _value.id;
    this.etag = _value.etag;
    CreateResponse.refineValues(this);
  }
  get header(): api003.ResponseHeader | undefined {
    return this._header;
  }
  set header(value: api003.ResponseHeader | undefined) {
    this._header = value;
  }
  get id(): string {
    return this._id;
  }
  set id(value: string) {
    this._id = value;
  }
  get etag(): string {
    return this._etag;
  }
  set etag(value: string) {
    this._etag = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    CreateResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): CreateResponse.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      id: this.id,
      etag: this.etag,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): CreateResponse.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      id: this.id,
      etag: this.etag,
    };
  }
}
export module CreateResponse {
  /**
   * Standard JavaScript object representation for CreateResponse
   */
  export interface AsObject {
    header?: api003.ResponseHeader.AsObject;
    id: string;
    etag: string;
  }

  /**
   * Protobuf JSON representation for CreateResponse
   */
  export interface AsProtobufJSON {
    header: api003.ResponseHeader.AsProtobufJSON | null;
    id: string;
    etag: string;
  }
}

/**
 * Message implementation for api.approval.UpdateViewersRequest
 */
export class UpdateViewersRequest implements GrpcMessage {
  static id = 'api.approval.UpdateViewersRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new UpdateViewersRequest();
    UpdateViewersRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: UpdateViewersRequest) {
    _instance.header = _instance.header || undefined;
    _instance.id = _instance.id || '';
    _instance.etag = _instance.etag || '';
    _instance.viewers = _instance.viewers || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: UpdateViewersRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api003.RequestHeader();
          _reader.readMessage(
            _instance.header,
            api003.RequestHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.id = _reader.readString();
          break;
        case 3:
          _instance.etag = _reader.readString();
          break;
        case 4:
          _instance.viewers = new storage005.ApprovedViewers();
          _reader.readMessage(
            _instance.viewers,
            storage005.ApprovedViewers.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    UpdateViewersRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: UpdateViewersRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api003.RequestHeader.serializeBinaryToWriter
      );
    }
    if (_instance.id) {
      _writer.writeString(2, _instance.id);
    }
    if (_instance.etag) {
      _writer.writeString(3, _instance.etag);
    }
    if (_instance.viewers) {
      _writer.writeMessage(
        4,
        _instance.viewers as any,
        storage005.ApprovedViewers.serializeBinaryToWriter
      );
    }
  }

  private _header?: api003.RequestHeader;
  private _id: string;
  private _etag: string;
  private _viewers?: storage005.ApprovedViewers;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of UpdateViewersRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<UpdateViewersRequest.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api003.RequestHeader(_value.header)
      : undefined;
    this.id = _value.id;
    this.etag = _value.etag;
    this.viewers = _value.viewers
      ? new storage005.ApprovedViewers(_value.viewers)
      : undefined;
    UpdateViewersRequest.refineValues(this);
  }
  get header(): api003.RequestHeader | undefined {
    return this._header;
  }
  set header(value: api003.RequestHeader | undefined) {
    this._header = value;
  }
  get id(): string {
    return this._id;
  }
  set id(value: string) {
    this._id = value;
  }
  get etag(): string {
    return this._etag;
  }
  set etag(value: string) {
    this._etag = value;
  }
  get viewers(): storage005.ApprovedViewers | undefined {
    return this._viewers;
  }
  set viewers(value: storage005.ApprovedViewers | undefined) {
    this._viewers = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    UpdateViewersRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): UpdateViewersRequest.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      id: this.id,
      etag: this.etag,
      viewers: this.viewers ? this.viewers.toObject() : undefined,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): UpdateViewersRequest.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      id: this.id,
      etag: this.etag,
      viewers: this.viewers ? this.viewers.toProtobufJSON(options) : null,
    };
  }
}
export module UpdateViewersRequest {
  /**
   * Standard JavaScript object representation for UpdateViewersRequest
   */
  export interface AsObject {
    header?: api003.RequestHeader.AsObject;
    id: string;
    etag: string;
    viewers?: storage005.ApprovedViewers.AsObject;
  }

  /**
   * Protobuf JSON representation for UpdateViewersRequest
   */
  export interface AsProtobufJSON {
    header: api003.RequestHeader.AsProtobufJSON | null;
    id: string;
    etag: string;
    viewers: storage005.ApprovedViewers.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for api.approval.UpdateViewersResponse
 */
export class UpdateViewersResponse implements GrpcMessage {
  static id = 'api.approval.UpdateViewersResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new UpdateViewersResponse();
    UpdateViewersResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: UpdateViewersResponse) {
    _instance.header = _instance.header || undefined;
    _instance.etag = _instance.etag || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: UpdateViewersResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api003.ResponseHeader();
          _reader.readMessage(
            _instance.header,
            api003.ResponseHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.etag = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    UpdateViewersResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: UpdateViewersResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api003.ResponseHeader.serializeBinaryToWriter
      );
    }
    if (_instance.etag) {
      _writer.writeString(2, _instance.etag);
    }
  }

  private _header?: api003.ResponseHeader;
  private _etag: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of UpdateViewersResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<UpdateViewersResponse.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api003.ResponseHeader(_value.header)
      : undefined;
    this.etag = _value.etag;
    UpdateViewersResponse.refineValues(this);
  }
  get header(): api003.ResponseHeader | undefined {
    return this._header;
  }
  set header(value: api003.ResponseHeader | undefined) {
    this._header = value;
  }
  get etag(): string {
    return this._etag;
  }
  set etag(value: string) {
    this._etag = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    UpdateViewersResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): UpdateViewersResponse.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      etag: this.etag,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): UpdateViewersResponse.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      etag: this.etag,
    };
  }
}
export module UpdateViewersResponse {
  /**
   * Standard JavaScript object representation for UpdateViewersResponse
   */
  export interface AsObject {
    header?: api003.ResponseHeader.AsObject;
    etag: string;
  }

  /**
   * Protobuf JSON representation for UpdateViewersResponse
   */
  export interface AsProtobufJSON {
    header: api003.ResponseHeader.AsProtobufJSON | null;
    etag: string;
  }
}

/**
 * Message implementation for api.approval.GetRequest
 */
export class GetRequest implements GrpcMessage {
  static id = 'api.approval.GetRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetRequest();
    GetRequest.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetRequest) {
    _instance.header = _instance.header || undefined;
    _instance.paginated = _instance.paginated || undefined;
    _instance.getRequestFilter = _instance.getRequestFilter || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api003.RequestHeader();
          _reader.readMessage(
            _instance.header,
            api003.RequestHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.paginated = new api004.GetPaginatedRequest();
          _reader.readMessage(
            _instance.paginated,
            api004.GetPaginatedRequest.deserializeBinaryFromReader
          );
          break;
        case 3:
          _instance.getRequestFilter = new ApprovalRequestFilter();
          _reader.readMessage(
            _instance.getRequestFilter,
            ApprovalRequestFilter.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    GetRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(_instance: GetRequest, _writer: BinaryWriter) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api003.RequestHeader.serializeBinaryToWriter
      );
    }
    if (_instance.paginated) {
      _writer.writeMessage(
        2,
        _instance.paginated as any,
        api004.GetPaginatedRequest.serializeBinaryToWriter
      );
    }
    if (_instance.getRequestFilter) {
      _writer.writeMessage(
        3,
        _instance.getRequestFilter as any,
        ApprovalRequestFilter.serializeBinaryToWriter
      );
    }
  }

  private _header?: api003.RequestHeader;
  private _paginated?: api004.GetPaginatedRequest;
  private _getRequestFilter?: ApprovalRequestFilter;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetRequest.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api003.RequestHeader(_value.header)
      : undefined;
    this.paginated = _value.paginated
      ? new api004.GetPaginatedRequest(_value.paginated)
      : undefined;
    this.getRequestFilter = _value.getRequestFilter
      ? new ApprovalRequestFilter(_value.getRequestFilter)
      : undefined;
    GetRequest.refineValues(this);
  }
  get header(): api003.RequestHeader | undefined {
    return this._header;
  }
  set header(value: api003.RequestHeader | undefined) {
    this._header = value;
  }
  get paginated(): api004.GetPaginatedRequest | undefined {
    return this._paginated;
  }
  set paginated(value: api004.GetPaginatedRequest | undefined) {
    this._paginated = value;
  }
  get getRequestFilter(): ApprovalRequestFilter | undefined {
    return this._getRequestFilter;
  }
  set getRequestFilter(value: ApprovalRequestFilter | undefined) {
    this._getRequestFilter = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetRequest.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      paginated: this.paginated ? this.paginated.toObject() : undefined,
      getRequestFilter: this.getRequestFilter
        ? this.getRequestFilter.toObject()
        : undefined,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetRequest.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      paginated: this.paginated ? this.paginated.toProtobufJSON(options) : null,
      getRequestFilter: this.getRequestFilter
        ? this.getRequestFilter.toProtobufJSON(options)
        : null,
    };
  }
}
export module GetRequest {
  /**
   * Standard JavaScript object representation for GetRequest
   */
  export interface AsObject {
    header?: api003.RequestHeader.AsObject;
    paginated?: api004.GetPaginatedRequest.AsObject;
    getRequestFilter?: ApprovalRequestFilter.AsObject;
  }

  /**
   * Protobuf JSON representation for GetRequest
   */
  export interface AsProtobufJSON {
    header: api003.RequestHeader.AsProtobufJSON | null;
    paginated: api004.GetPaginatedRequest.AsProtobufJSON | null;
    getRequestFilter: ApprovalRequestFilter.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for api.approval.GetResponse
 */
export class GetResponse implements GrpcMessage {
  static id = 'api.approval.GetResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetResponse();
    GetResponse.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetResponse) {
    _instance.header = _instance.header || undefined;
    _instance.approvalRequests = _instance.approvalRequests || [];
    _instance.paginatedResponse = _instance.paginatedResponse || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api003.ResponseHeader();
          _reader.readMessage(
            _instance.header,
            api003.ResponseHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          const messageInitializer2 = new ApiApprovalRequest();
          _reader.readMessage(
            messageInitializer2,
            ApiApprovalRequest.deserializeBinaryFromReader
          );
          (_instance.approvalRequests = _instance.approvalRequests || []).push(
            messageInitializer2
          );
          break;
        case 3:
          _instance.paginatedResponse = new api004.PaginatedResponse();
          _reader.readMessage(
            _instance.paginatedResponse,
            api004.PaginatedResponse.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    GetResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api003.ResponseHeader.serializeBinaryToWriter
      );
    }
    if (_instance.approvalRequests && _instance.approvalRequests.length) {
      _writer.writeRepeatedMessage(
        2,
        _instance.approvalRequests as any,
        ApiApprovalRequest.serializeBinaryToWriter
      );
    }
    if (_instance.paginatedResponse) {
      _writer.writeMessage(
        3,
        _instance.paginatedResponse as any,
        api004.PaginatedResponse.serializeBinaryToWriter
      );
    }
  }

  private _header?: api003.ResponseHeader;
  private _approvalRequests?: ApiApprovalRequest[];
  private _paginatedResponse?: api004.PaginatedResponse;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetResponse.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api003.ResponseHeader(_value.header)
      : undefined;
    this.approvalRequests = (_value.approvalRequests || []).map(
      (m) => new ApiApprovalRequest(m)
    );
    this.paginatedResponse = _value.paginatedResponse
      ? new api004.PaginatedResponse(_value.paginatedResponse)
      : undefined;
    GetResponse.refineValues(this);
  }
  get header(): api003.ResponseHeader | undefined {
    return this._header;
  }
  set header(value: api003.ResponseHeader | undefined) {
    this._header = value;
  }
  get approvalRequests(): ApiApprovalRequest[] | undefined {
    return this._approvalRequests;
  }
  set approvalRequests(value: ApiApprovalRequest[] | undefined) {
    this._approvalRequests = value;
  }
  get paginatedResponse(): api004.PaginatedResponse | undefined {
    return this._paginatedResponse;
  }
  set paginatedResponse(value: api004.PaginatedResponse | undefined) {
    this._paginatedResponse = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetResponse.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      approvalRequests: (this.approvalRequests || []).map((m) => m.toObject()),
      paginatedResponse: this.paginatedResponse
        ? this.paginatedResponse.toObject()
        : undefined,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetResponse.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      approvalRequests: (this.approvalRequests || []).map((m) =>
        m.toProtobufJSON(options)
      ),
      paginatedResponse: this.paginatedResponse
        ? this.paginatedResponse.toProtobufJSON(options)
        : null,
    };
  }
}
export module GetResponse {
  /**
   * Standard JavaScript object representation for GetResponse
   */
  export interface AsObject {
    header?: api003.ResponseHeader.AsObject;
    approvalRequests?: ApiApprovalRequest.AsObject[];
    paginatedResponse?: api004.PaginatedResponse.AsObject;
  }

  /**
   * Protobuf JSON representation for GetResponse
   */
  export interface AsProtobufJSON {
    header: api003.ResponseHeader.AsProtobufJSON | null;
    approvalRequests: ApiApprovalRequest.AsProtobufJSON[] | null;
    paginatedResponse: api004.PaginatedResponse.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for api.approval.GetAllCustomersResponse
 */
export class GetAllCustomersResponse implements GrpcMessage {
  static id = 'api.approval.GetAllCustomersResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetAllCustomersResponse();
    GetAllCustomersResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetAllCustomersResponse) {
    _instance.header = _instance.header || undefined;
    _instance.approvalRequests = _instance.approvalRequests || [];
    _instance.paginatedResponse = _instance.paginatedResponse || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetAllCustomersResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api003.ResponseHeader();
          _reader.readMessage(
            _instance.header,
            api003.ResponseHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          const messageInitializer2 = new ApiApprovalRequestAllCustomers();
          _reader.readMessage(
            messageInitializer2,
            ApiApprovalRequestAllCustomers.deserializeBinaryFromReader
          );
          (_instance.approvalRequests = _instance.approvalRequests || []).push(
            messageInitializer2
          );
          break;
        case 3:
          _instance.paginatedResponse = new api004.PaginatedResponse();
          _reader.readMessage(
            _instance.paginatedResponse,
            api004.PaginatedResponse.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    GetAllCustomersResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetAllCustomersResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api003.ResponseHeader.serializeBinaryToWriter
      );
    }
    if (_instance.approvalRequests && _instance.approvalRequests.length) {
      _writer.writeRepeatedMessage(
        2,
        _instance.approvalRequests as any,
        ApiApprovalRequestAllCustomers.serializeBinaryToWriter
      );
    }
    if (_instance.paginatedResponse) {
      _writer.writeMessage(
        3,
        _instance.paginatedResponse as any,
        api004.PaginatedResponse.serializeBinaryToWriter
      );
    }
  }

  private _header?: api003.ResponseHeader;
  private _approvalRequests?: ApiApprovalRequestAllCustomers[];
  private _paginatedResponse?: api004.PaginatedResponse;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetAllCustomersResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetAllCustomersResponse.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api003.ResponseHeader(_value.header)
      : undefined;
    this.approvalRequests = (_value.approvalRequests || []).map(
      (m) => new ApiApprovalRequestAllCustomers(m)
    );
    this.paginatedResponse = _value.paginatedResponse
      ? new api004.PaginatedResponse(_value.paginatedResponse)
      : undefined;
    GetAllCustomersResponse.refineValues(this);
  }
  get header(): api003.ResponseHeader | undefined {
    return this._header;
  }
  set header(value: api003.ResponseHeader | undefined) {
    this._header = value;
  }
  get approvalRequests(): ApiApprovalRequestAllCustomers[] | undefined {
    return this._approvalRequests;
  }
  set approvalRequests(value: ApiApprovalRequestAllCustomers[] | undefined) {
    this._approvalRequests = value;
  }
  get paginatedResponse(): api004.PaginatedResponse | undefined {
    return this._paginatedResponse;
  }
  set paginatedResponse(value: api004.PaginatedResponse | undefined) {
    this._paginatedResponse = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetAllCustomersResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetAllCustomersResponse.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      approvalRequests: (this.approvalRequests || []).map((m) => m.toObject()),
      paginatedResponse: this.paginatedResponse
        ? this.paginatedResponse.toObject()
        : undefined,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetAllCustomersResponse.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      approvalRequests: (this.approvalRequests || []).map((m) =>
        m.toProtobufJSON(options)
      ),
      paginatedResponse: this.paginatedResponse
        ? this.paginatedResponse.toProtobufJSON(options)
        : null,
    };
  }
}
export module GetAllCustomersResponse {
  /**
   * Standard JavaScript object representation for GetAllCustomersResponse
   */
  export interface AsObject {
    header?: api003.ResponseHeader.AsObject;
    approvalRequests?: ApiApprovalRequestAllCustomers.AsObject[];
    paginatedResponse?: api004.PaginatedResponse.AsObject;
  }

  /**
   * Protobuf JSON representation for GetAllCustomersResponse
   */
  export interface AsProtobufJSON {
    header: api003.ResponseHeader.AsProtobufJSON | null;
    approvalRequests: ApiApprovalRequestAllCustomers.AsProtobufJSON[] | null;
    paginatedResponse: api004.PaginatedResponse.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for api.approval.GetApprovedBinariesRequest
 */
export class GetApprovedBinariesRequest implements GrpcMessage {
  static id = 'api.approval.GetApprovedBinariesRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetApprovedBinariesRequest();
    GetApprovedBinariesRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetApprovedBinariesRequest) {
    _instance.header = _instance.header || undefined;
    _instance.customerId = _instance.customerId || '';
    _instance.getRequestFilter = _instance.getRequestFilter || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetApprovedBinariesRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api003.RequestHeader();
          _reader.readMessage(
            _instance.header,
            api003.RequestHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.customerId = _reader.readString();
          break;
        case 4:
          _instance.getRequestFilter = new ApprovalRequestFilter();
          _reader.readMessage(
            _instance.getRequestFilter,
            ApprovalRequestFilter.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    GetApprovedBinariesRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetApprovedBinariesRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api003.RequestHeader.serializeBinaryToWriter
      );
    }
    if (_instance.customerId) {
      _writer.writeString(2, _instance.customerId);
    }
    if (_instance.getRequestFilter) {
      _writer.writeMessage(
        4,
        _instance.getRequestFilter as any,
        ApprovalRequestFilter.serializeBinaryToWriter
      );
    }
  }

  private _header?: api003.RequestHeader;
  private _customerId: string;
  private _getRequestFilter?: ApprovalRequestFilter;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetApprovedBinariesRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetApprovedBinariesRequest.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api003.RequestHeader(_value.header)
      : undefined;
    this.customerId = _value.customerId;
    this.getRequestFilter = _value.getRequestFilter
      ? new ApprovalRequestFilter(_value.getRequestFilter)
      : undefined;
    GetApprovedBinariesRequest.refineValues(this);
  }
  get header(): api003.RequestHeader | undefined {
    return this._header;
  }
  set header(value: api003.RequestHeader | undefined) {
    this._header = value;
  }
  get customerId(): string {
    return this._customerId;
  }
  set customerId(value: string) {
    this._customerId = value;
  }
  get getRequestFilter(): ApprovalRequestFilter | undefined {
    return this._getRequestFilter;
  }
  set getRequestFilter(value: ApprovalRequestFilter | undefined) {
    this._getRequestFilter = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetApprovedBinariesRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetApprovedBinariesRequest.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      customerId: this.customerId,
      getRequestFilter: this.getRequestFilter
        ? this.getRequestFilter.toObject()
        : undefined,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetApprovedBinariesRequest.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      customerId: this.customerId,
      getRequestFilter: this.getRequestFilter
        ? this.getRequestFilter.toProtobufJSON(options)
        : null,
    };
  }
}
export module GetApprovedBinariesRequest {
  /**
   * Standard JavaScript object representation for GetApprovedBinariesRequest
   */
  export interface AsObject {
    header?: api003.RequestHeader.AsObject;
    customerId: string;
    getRequestFilter?: ApprovalRequestFilter.AsObject;
  }

  /**
   * Protobuf JSON representation for GetApprovedBinariesRequest
   */
  export interface AsProtobufJSON {
    header: api003.RequestHeader.AsProtobufJSON | null;
    customerId: string;
    getRequestFilter: ApprovalRequestFilter.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for api.approval.GetApprovedBinariesResponse
 */
export class GetApprovedBinariesResponse implements GrpcMessage {
  static id = 'api.approval.GetApprovedBinariesResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetApprovedBinariesResponse();
    GetApprovedBinariesResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetApprovedBinariesResponse) {
    _instance.header = _instance.header || undefined;
    _instance.approvedBinaryChange = _instance.approvedBinaryChange || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetApprovedBinariesResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api003.RequestHeader();
          _reader.readMessage(
            _instance.header,
            api003.RequestHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          const messageInitializer2 = new ApprovedBinaryChange();
          _reader.readMessage(
            messageInitializer2,
            ApprovedBinaryChange.deserializeBinaryFromReader
          );
          (_instance.approvedBinaryChange =
            _instance.approvedBinaryChange || []).push(messageInitializer2);
          break;
        default:
          _reader.skipField();
      }
    }

    GetApprovedBinariesResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetApprovedBinariesResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api003.RequestHeader.serializeBinaryToWriter
      );
    }
    if (
      _instance.approvedBinaryChange &&
      _instance.approvedBinaryChange.length
    ) {
      _writer.writeRepeatedMessage(
        2,
        _instance.approvedBinaryChange as any,
        ApprovedBinaryChange.serializeBinaryToWriter
      );
    }
  }

  private _header?: api003.RequestHeader;
  private _approvedBinaryChange?: ApprovedBinaryChange[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetApprovedBinariesResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetApprovedBinariesResponse.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api003.RequestHeader(_value.header)
      : undefined;
    this.approvedBinaryChange = (_value.approvedBinaryChange || []).map(
      (m) => new ApprovedBinaryChange(m)
    );
    GetApprovedBinariesResponse.refineValues(this);
  }
  get header(): api003.RequestHeader | undefined {
    return this._header;
  }
  set header(value: api003.RequestHeader | undefined) {
    this._header = value;
  }
  get approvedBinaryChange(): ApprovedBinaryChange[] | undefined {
    return this._approvedBinaryChange;
  }
  set approvedBinaryChange(value: ApprovedBinaryChange[] | undefined) {
    this._approvedBinaryChange = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetApprovedBinariesResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetApprovedBinariesResponse.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      approvedBinaryChange: (this.approvedBinaryChange || []).map((m) =>
        m.toObject()
      ),
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetApprovedBinariesResponse.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      approvedBinaryChange: (this.approvedBinaryChange || []).map((m) =>
        m.toProtobufJSON(options)
      ),
    };
  }
}
export module GetApprovedBinariesResponse {
  /**
   * Standard JavaScript object representation for GetApprovedBinariesResponse
   */
  export interface AsObject {
    header?: api003.RequestHeader.AsObject;
    approvedBinaryChange?: ApprovedBinaryChange.AsObject[];
  }

  /**
   * Protobuf JSON representation for GetApprovedBinariesResponse
   */
  export interface AsProtobufJSON {
    header: api003.RequestHeader.AsProtobufJSON | null;
    approvedBinaryChange: ApprovedBinaryChange.AsProtobufJSON[] | null;
  }
}

/**
 * Message implementation for api.approval.ApprovedBinaryChange
 */
export class ApprovedBinaryChange implements GrpcMessage {
  static id = 'api.approval.ApprovedBinaryChange';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ApprovedBinaryChange();
    ApprovedBinaryChange.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ApprovedBinaryChange) {
    _instance.approvalId = _instance.approvalId || '';
    _instance.approvalTime = _instance.approvalTime || undefined;
    _instance.change = _instance.change || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ApprovedBinaryChange,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.approvalId = _reader.readString();
          break;
        case 2:
          _instance.approvalTime = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.approvalTime,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 3:
          _instance.change = new storage005.RequestedBinaryChange();
          _reader.readMessage(
            _instance.change,
            storage005.RequestedBinaryChange.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    ApprovedBinaryChange.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ApprovedBinaryChange,
    _writer: BinaryWriter
  ) {
    if (_instance.approvalId) {
      _writer.writeString(1, _instance.approvalId);
    }
    if (_instance.approvalTime) {
      _writer.writeMessage(
        2,
        _instance.approvalTime as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.change) {
      _writer.writeMessage(
        3,
        _instance.change as any,
        storage005.RequestedBinaryChange.serializeBinaryToWriter
      );
    }
  }

  private _approvalId: string;
  private _approvalTime?: googleProtobuf000.Timestamp;
  private _change?: storage005.RequestedBinaryChange;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ApprovedBinaryChange to deeply clone from
   */
  constructor(_value?: RecursivePartial<ApprovedBinaryChange.AsObject>) {
    _value = _value || {};
    this.approvalId = _value.approvalId;
    this.approvalTime = _value.approvalTime
      ? new googleProtobuf000.Timestamp(_value.approvalTime)
      : undefined;
    this.change = _value.change
      ? new storage005.RequestedBinaryChange(_value.change)
      : undefined;
    ApprovedBinaryChange.refineValues(this);
  }
  get approvalId(): string {
    return this._approvalId;
  }
  set approvalId(value: string) {
    this._approvalId = value;
  }
  get approvalTime(): googleProtobuf000.Timestamp | undefined {
    return this._approvalTime;
  }
  set approvalTime(value: googleProtobuf000.Timestamp | undefined) {
    this._approvalTime = value;
  }
  get change(): storage005.RequestedBinaryChange | undefined {
    return this._change;
  }
  set change(value: storage005.RequestedBinaryChange | undefined) {
    this._change = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ApprovedBinaryChange.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ApprovedBinaryChange.AsObject {
    return {
      approvalId: this.approvalId,
      approvalTime: this.approvalTime
        ? this.approvalTime.toObject()
        : undefined,
      change: this.change ? this.change.toObject() : undefined,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ApprovedBinaryChange.AsProtobufJSON {
    return {
      approvalId: this.approvalId,
      approvalTime: this.approvalTime
        ? this.approvalTime.toProtobufJSON(options)
        : null,
      change: this.change ? this.change.toProtobufJSON(options) : null,
    };
  }
}
export module ApprovedBinaryChange {
  /**
   * Standard JavaScript object representation for ApprovedBinaryChange
   */
  export interface AsObject {
    approvalId: string;
    approvalTime?: googleProtobuf000.Timestamp.AsObject;
    change?: storage005.RequestedBinaryChange.AsObject;
  }

  /**
   * Protobuf JSON representation for ApprovedBinaryChange
   */
  export interface AsProtobufJSON {
    approvalId: string;
    approvalTime: googleProtobuf000.Timestamp.AsProtobufJSON | null;
    change: storage005.RequestedBinaryChange.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for api.approval.AddDecisionRequest
 */
export class AddDecisionRequest implements GrpcMessage {
  static id = 'api.approval.AddDecisionRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new AddDecisionRequest();
    AddDecisionRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: AddDecisionRequest) {
    _instance.header = _instance.header || undefined;
    _instance.approvalRequestId = _instance.approvalRequestId || '';
    _instance.etag = _instance.etag || '';
    _instance.decision = _instance.decision || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: AddDecisionRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api003.RequestHeader();
          _reader.readMessage(
            _instance.header,
            api003.RequestHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.approvalRequestId = _reader.readString();
          break;
        case 3:
          _instance.etag = _reader.readString();
          break;
        case 4:
          _instance.decision = new storage005.ApprovalDecision();
          _reader.readMessage(
            _instance.decision,
            storage005.ApprovalDecision.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    AddDecisionRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: AddDecisionRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api003.RequestHeader.serializeBinaryToWriter
      );
    }
    if (_instance.approvalRequestId) {
      _writer.writeString(2, _instance.approvalRequestId);
    }
    if (_instance.etag) {
      _writer.writeString(3, _instance.etag);
    }
    if (_instance.decision) {
      _writer.writeMessage(
        4,
        _instance.decision as any,
        storage005.ApprovalDecision.serializeBinaryToWriter
      );
    }
  }

  private _header?: api003.RequestHeader;
  private _approvalRequestId: string;
  private _etag: string;
  private _decision?: storage005.ApprovalDecision;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of AddDecisionRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<AddDecisionRequest.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api003.RequestHeader(_value.header)
      : undefined;
    this.approvalRequestId = _value.approvalRequestId;
    this.etag = _value.etag;
    this.decision = _value.decision
      ? new storage005.ApprovalDecision(_value.decision)
      : undefined;
    AddDecisionRequest.refineValues(this);
  }
  get header(): api003.RequestHeader | undefined {
    return this._header;
  }
  set header(value: api003.RequestHeader | undefined) {
    this._header = value;
  }
  get approvalRequestId(): string {
    return this._approvalRequestId;
  }
  set approvalRequestId(value: string) {
    this._approvalRequestId = value;
  }
  get etag(): string {
    return this._etag;
  }
  set etag(value: string) {
    this._etag = value;
  }
  get decision(): storage005.ApprovalDecision | undefined {
    return this._decision;
  }
  set decision(value: storage005.ApprovalDecision | undefined) {
    this._decision = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    AddDecisionRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): AddDecisionRequest.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      approvalRequestId: this.approvalRequestId,
      etag: this.etag,
      decision: this.decision ? this.decision.toObject() : undefined,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): AddDecisionRequest.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      approvalRequestId: this.approvalRequestId,
      etag: this.etag,
      decision: this.decision ? this.decision.toProtobufJSON(options) : null,
    };
  }
}
export module AddDecisionRequest {
  /**
   * Standard JavaScript object representation for AddDecisionRequest
   */
  export interface AsObject {
    header?: api003.RequestHeader.AsObject;
    approvalRequestId: string;
    etag: string;
    decision?: storage005.ApprovalDecision.AsObject;
  }

  /**
   * Protobuf JSON representation for AddDecisionRequest
   */
  export interface AsProtobufJSON {
    header: api003.RequestHeader.AsProtobufJSON | null;
    approvalRequestId: string;
    etag: string;
    decision: storage005.ApprovalDecision.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for api.approval.AddDecisionResponse
 */
export class AddDecisionResponse implements GrpcMessage {
  static id = 'api.approval.AddDecisionResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new AddDecisionResponse();
    AddDecisionResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: AddDecisionResponse) {
    _instance.header = _instance.header || undefined;
    _instance.etag = _instance.etag || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: AddDecisionResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api003.ResponseHeader();
          _reader.readMessage(
            _instance.header,
            api003.ResponseHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.etag = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    AddDecisionResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: AddDecisionResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api003.ResponseHeader.serializeBinaryToWriter
      );
    }
    if (_instance.etag) {
      _writer.writeString(2, _instance.etag);
    }
  }

  private _header?: api003.ResponseHeader;
  private _etag: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of AddDecisionResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<AddDecisionResponse.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api003.ResponseHeader(_value.header)
      : undefined;
    this.etag = _value.etag;
    AddDecisionResponse.refineValues(this);
  }
  get header(): api003.ResponseHeader | undefined {
    return this._header;
  }
  set header(value: api003.ResponseHeader | undefined) {
    this._header = value;
  }
  get etag(): string {
    return this._etag;
  }
  set etag(value: string) {
    this._etag = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    AddDecisionResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): AddDecisionResponse.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      etag: this.etag,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): AddDecisionResponse.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      etag: this.etag,
    };
  }
}
export module AddDecisionResponse {
  /**
   * Standard JavaScript object representation for AddDecisionResponse
   */
  export interface AsObject {
    header?: api003.ResponseHeader.AsObject;
    etag: string;
  }

  /**
   * Protobuf JSON representation for AddDecisionResponse
   */
  export interface AsProtobufJSON {
    header: api003.ResponseHeader.AsProtobufJSON | null;
    etag: string;
  }
}

/**
 * Message implementation for api.approval.AddDecisionForCustomersRequest
 */
export class AddDecisionForCustomersRequest implements GrpcMessage {
  static id = 'api.approval.AddDecisionForCustomersRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new AddDecisionForCustomersRequest();
    AddDecisionForCustomersRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: AddDecisionForCustomersRequest) {
    _instance.header = _instance.header || undefined;
    _instance.customerIds = _instance.customerIds || [];
    _instance.addDecisionRequest = _instance.addDecisionRequest || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: AddDecisionForCustomersRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api003.RequestHeader();
          _reader.readMessage(
            _instance.header,
            api003.RequestHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          (_instance.customerIds = _instance.customerIds || []).push(
            _reader.readString()
          );
          break;
        case 3:
          _instance.addDecisionRequest = new AddDecisionRequest();
          _reader.readMessage(
            _instance.addDecisionRequest,
            AddDecisionRequest.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    AddDecisionForCustomersRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: AddDecisionForCustomersRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api003.RequestHeader.serializeBinaryToWriter
      );
    }
    if (_instance.customerIds && _instance.customerIds.length) {
      _writer.writeRepeatedString(2, _instance.customerIds);
    }
    if (_instance.addDecisionRequest) {
      _writer.writeMessage(
        3,
        _instance.addDecisionRequest as any,
        AddDecisionRequest.serializeBinaryToWriter
      );
    }
  }

  private _header?: api003.RequestHeader;
  private _customerIds: string[];
  private _addDecisionRequest?: AddDecisionRequest;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of AddDecisionForCustomersRequest to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<AddDecisionForCustomersRequest.AsObject>
  ) {
    _value = _value || {};
    this.header = _value.header
      ? new api003.RequestHeader(_value.header)
      : undefined;
    this.customerIds = (_value.customerIds || []).slice();
    this.addDecisionRequest = _value.addDecisionRequest
      ? new AddDecisionRequest(_value.addDecisionRequest)
      : undefined;
    AddDecisionForCustomersRequest.refineValues(this);
  }
  get header(): api003.RequestHeader | undefined {
    return this._header;
  }
  set header(value: api003.RequestHeader | undefined) {
    this._header = value;
  }
  get customerIds(): string[] {
    return this._customerIds;
  }
  set customerIds(value: string[]) {
    this._customerIds = value;
  }
  get addDecisionRequest(): AddDecisionRequest | undefined {
    return this._addDecisionRequest;
  }
  set addDecisionRequest(value: AddDecisionRequest | undefined) {
    this._addDecisionRequest = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    AddDecisionForCustomersRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): AddDecisionForCustomersRequest.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      customerIds: (this.customerIds || []).slice(),
      addDecisionRequest: this.addDecisionRequest
        ? this.addDecisionRequest.toObject()
        : undefined,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): AddDecisionForCustomersRequest.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      customerIds: (this.customerIds || []).slice(),
      addDecisionRequest: this.addDecisionRequest
        ? this.addDecisionRequest.toProtobufJSON(options)
        : null,
    };
  }
}
export module AddDecisionForCustomersRequest {
  /**
   * Standard JavaScript object representation for AddDecisionForCustomersRequest
   */
  export interface AsObject {
    header?: api003.RequestHeader.AsObject;
    customerIds: string[];
    addDecisionRequest?: AddDecisionRequest.AsObject;
  }

  /**
   * Protobuf JSON representation for AddDecisionForCustomersRequest
   */
  export interface AsProtobufJSON {
    header: api003.RequestHeader.AsProtobufJSON | null;
    customerIds: string[];
    addDecisionRequest: AddDecisionRequest.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for api.approval.SetBinaryStateRequest
 */
export class SetBinaryStateRequest implements GrpcMessage {
  static id = 'api.approval.SetBinaryStateRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new SetBinaryStateRequest();
    SetBinaryStateRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: SetBinaryStateRequest) {
    _instance.header = _instance.header || undefined;
    _instance.approvalRequestId = _instance.approvalRequestId || '';
    _instance.binaryStateDecision = _instance.binaryStateDecision || undefined;
    _instance.etag = _instance.etag || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: SetBinaryStateRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api003.RequestHeader();
          _reader.readMessage(
            _instance.header,
            api003.RequestHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.approvalRequestId = _reader.readString();
          break;
        case 3:
          _instance.binaryStateDecision = new storage005.BinaryStateDecision();
          _reader.readMessage(
            _instance.binaryStateDecision,
            storage005.BinaryStateDecision.deserializeBinaryFromReader
          );
          break;
        case 4:
          _instance.etag = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    SetBinaryStateRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: SetBinaryStateRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api003.RequestHeader.serializeBinaryToWriter
      );
    }
    if (_instance.approvalRequestId) {
      _writer.writeString(2, _instance.approvalRequestId);
    }
    if (_instance.binaryStateDecision) {
      _writer.writeMessage(
        3,
        _instance.binaryStateDecision as any,
        storage005.BinaryStateDecision.serializeBinaryToWriter
      );
    }
    if (_instance.etag) {
      _writer.writeString(4, _instance.etag);
    }
  }

  private _header?: api003.RequestHeader;
  private _approvalRequestId: string;
  private _binaryStateDecision?: storage005.BinaryStateDecision;
  private _etag: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of SetBinaryStateRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<SetBinaryStateRequest.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api003.RequestHeader(_value.header)
      : undefined;
    this.approvalRequestId = _value.approvalRequestId;
    this.binaryStateDecision = _value.binaryStateDecision
      ? new storage005.BinaryStateDecision(_value.binaryStateDecision)
      : undefined;
    this.etag = _value.etag;
    SetBinaryStateRequest.refineValues(this);
  }
  get header(): api003.RequestHeader | undefined {
    return this._header;
  }
  set header(value: api003.RequestHeader | undefined) {
    this._header = value;
  }
  get approvalRequestId(): string {
    return this._approvalRequestId;
  }
  set approvalRequestId(value: string) {
    this._approvalRequestId = value;
  }
  get binaryStateDecision(): storage005.BinaryStateDecision | undefined {
    return this._binaryStateDecision;
  }
  set binaryStateDecision(value: storage005.BinaryStateDecision | undefined) {
    this._binaryStateDecision = value;
  }
  get etag(): string {
    return this._etag;
  }
  set etag(value: string) {
    this._etag = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    SetBinaryStateRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): SetBinaryStateRequest.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      approvalRequestId: this.approvalRequestId,
      binaryStateDecision: this.binaryStateDecision
        ? this.binaryStateDecision.toObject()
        : undefined,
      etag: this.etag,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): SetBinaryStateRequest.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      approvalRequestId: this.approvalRequestId,
      binaryStateDecision: this.binaryStateDecision
        ? this.binaryStateDecision.toProtobufJSON(options)
        : null,
      etag: this.etag,
    };
  }
}
export module SetBinaryStateRequest {
  /**
   * Standard JavaScript object representation for SetBinaryStateRequest
   */
  export interface AsObject {
    header?: api003.RequestHeader.AsObject;
    approvalRequestId: string;
    binaryStateDecision?: storage005.BinaryStateDecision.AsObject;
    etag: string;
  }

  /**
   * Protobuf JSON representation for SetBinaryStateRequest
   */
  export interface AsProtobufJSON {
    header: api003.RequestHeader.AsProtobufJSON | null;
    approvalRequestId: string;
    binaryStateDecision: storage005.BinaryStateDecision.AsProtobufJSON | null;
    etag: string;
  }
}

/**
 * Message implementation for api.approval.SetBinaryStateResponse
 */
export class SetBinaryStateResponse implements GrpcMessage {
  static id = 'api.approval.SetBinaryStateResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new SetBinaryStateResponse();
    SetBinaryStateResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: SetBinaryStateResponse) {
    _instance.header = _instance.header || undefined;
    _instance.etag = _instance.etag || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: SetBinaryStateResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api003.ResponseHeader();
          _reader.readMessage(
            _instance.header,
            api003.ResponseHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.etag = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    SetBinaryStateResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: SetBinaryStateResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api003.ResponseHeader.serializeBinaryToWriter
      );
    }
    if (_instance.etag) {
      _writer.writeString(2, _instance.etag);
    }
  }

  private _header?: api003.ResponseHeader;
  private _etag: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of SetBinaryStateResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<SetBinaryStateResponse.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api003.ResponseHeader(_value.header)
      : undefined;
    this.etag = _value.etag;
    SetBinaryStateResponse.refineValues(this);
  }
  get header(): api003.ResponseHeader | undefined {
    return this._header;
  }
  set header(value: api003.ResponseHeader | undefined) {
    this._header = value;
  }
  get etag(): string {
    return this._etag;
  }
  set etag(value: string) {
    this._etag = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    SetBinaryStateResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): SetBinaryStateResponse.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      etag: this.etag,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): SetBinaryStateResponse.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      etag: this.etag,
    };
  }
}
export module SetBinaryStateResponse {
  /**
   * Standard JavaScript object representation for SetBinaryStateResponse
   */
  export interface AsObject {
    header?: api003.ResponseHeader.AsObject;
    etag: string;
  }

  /**
   * Protobuf JSON representation for SetBinaryStateResponse
   */
  export interface AsProtobufJSON {
    header: api003.ResponseHeader.AsProtobufJSON | null;
    etag: string;
  }
}

/**
 * Message implementation for api.approval.SetBinaryStateForCustomersRequest
 */
export class SetBinaryStateForCustomersRequest implements GrpcMessage {
  static id = 'api.approval.SetBinaryStateForCustomersRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new SetBinaryStateForCustomersRequest();
    SetBinaryStateForCustomersRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: SetBinaryStateForCustomersRequest) {
    _instance.header = _instance.header || undefined;
    _instance.customerIds = _instance.customerIds || [];
    _instance.setBinaryStateRequest =
      _instance.setBinaryStateRequest || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: SetBinaryStateForCustomersRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api003.RequestHeader();
          _reader.readMessage(
            _instance.header,
            api003.RequestHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          (_instance.customerIds = _instance.customerIds || []).push(
            _reader.readString()
          );
          break;
        case 3:
          _instance.setBinaryStateRequest = new SetBinaryStateRequest();
          _reader.readMessage(
            _instance.setBinaryStateRequest,
            SetBinaryStateRequest.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    SetBinaryStateForCustomersRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: SetBinaryStateForCustomersRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api003.RequestHeader.serializeBinaryToWriter
      );
    }
    if (_instance.customerIds && _instance.customerIds.length) {
      _writer.writeRepeatedString(2, _instance.customerIds);
    }
    if (_instance.setBinaryStateRequest) {
      _writer.writeMessage(
        3,
        _instance.setBinaryStateRequest as any,
        SetBinaryStateRequest.serializeBinaryToWriter
      );
    }
  }

  private _header?: api003.RequestHeader;
  private _customerIds: string[];
  private _setBinaryStateRequest?: SetBinaryStateRequest;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of SetBinaryStateForCustomersRequest to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<SetBinaryStateForCustomersRequest.AsObject>
  ) {
    _value = _value || {};
    this.header = _value.header
      ? new api003.RequestHeader(_value.header)
      : undefined;
    this.customerIds = (_value.customerIds || []).slice();
    this.setBinaryStateRequest = _value.setBinaryStateRequest
      ? new SetBinaryStateRequest(_value.setBinaryStateRequest)
      : undefined;
    SetBinaryStateForCustomersRequest.refineValues(this);
  }
  get header(): api003.RequestHeader | undefined {
    return this._header;
  }
  set header(value: api003.RequestHeader | undefined) {
    this._header = value;
  }
  get customerIds(): string[] {
    return this._customerIds;
  }
  set customerIds(value: string[]) {
    this._customerIds = value;
  }
  get setBinaryStateRequest(): SetBinaryStateRequest | undefined {
    return this._setBinaryStateRequest;
  }
  set setBinaryStateRequest(value: SetBinaryStateRequest | undefined) {
    this._setBinaryStateRequest = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    SetBinaryStateForCustomersRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): SetBinaryStateForCustomersRequest.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      customerIds: (this.customerIds || []).slice(),
      setBinaryStateRequest: this.setBinaryStateRequest
        ? this.setBinaryStateRequest.toObject()
        : undefined,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): SetBinaryStateForCustomersRequest.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      customerIds: (this.customerIds || []).slice(),
      setBinaryStateRequest: this.setBinaryStateRequest
        ? this.setBinaryStateRequest.toProtobufJSON(options)
        : null,
    };
  }
}
export module SetBinaryStateForCustomersRequest {
  /**
   * Standard JavaScript object representation for SetBinaryStateForCustomersRequest
   */
  export interface AsObject {
    header?: api003.RequestHeader.AsObject;
    customerIds: string[];
    setBinaryStateRequest?: SetBinaryStateRequest.AsObject;
  }

  /**
   * Protobuf JSON representation for SetBinaryStateForCustomersRequest
   */
  export interface AsProtobufJSON {
    header: api003.RequestHeader.AsProtobufJSON | null;
    customerIds: string[];
    setBinaryStateRequest: SetBinaryStateRequest.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for api.approval.AddAttestationPolicyStateRequest
 */
export class AddAttestationPolicyStateRequest implements GrpcMessage {
  static id = 'api.approval.AddAttestationPolicyStateRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new AddAttestationPolicyStateRequest();
    AddAttestationPolicyStateRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: AddAttestationPolicyStateRequest) {
    _instance.header = _instance.header || undefined;
    _instance.approvalRequestId = _instance.approvalRequestId || '';
    _instance.customerId = _instance.customerId || '';
    _instance.state = _instance.state || 0;
    _instance.etag = _instance.etag || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: AddAttestationPolicyStateRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api003.RequestHeader();
          _reader.readMessage(
            _instance.header,
            api003.RequestHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.approvalRequestId = _reader.readString();
          break;
        case 3:
          _instance.customerId = _reader.readString();
          break;
        case 4:
          _instance.state = _reader.readEnum();
          break;
        case 5:
          _instance.etag = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    AddAttestationPolicyStateRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: AddAttestationPolicyStateRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api003.RequestHeader.serializeBinaryToWriter
      );
    }
    if (_instance.approvalRequestId) {
      _writer.writeString(2, _instance.approvalRequestId);
    }
    if (_instance.customerId) {
      _writer.writeString(3, _instance.customerId);
    }
    if (_instance.state) {
      _writer.writeEnum(4, _instance.state);
    }
    if (_instance.etag) {
      _writer.writeString(5, _instance.etag);
    }
  }

  private _header?: api003.RequestHeader;
  private _approvalRequestId: string;
  private _customerId: string;
  private _state: storage005.AttestationPolicyState;
  private _etag: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of AddAttestationPolicyStateRequest to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<AddAttestationPolicyStateRequest.AsObject>
  ) {
    _value = _value || {};
    this.header = _value.header
      ? new api003.RequestHeader(_value.header)
      : undefined;
    this.approvalRequestId = _value.approvalRequestId;
    this.customerId = _value.customerId;
    this.state = _value.state;
    this.etag = _value.etag;
    AddAttestationPolicyStateRequest.refineValues(this);
  }
  get header(): api003.RequestHeader | undefined {
    return this._header;
  }
  set header(value: api003.RequestHeader | undefined) {
    this._header = value;
  }
  get approvalRequestId(): string {
    return this._approvalRequestId;
  }
  set approvalRequestId(value: string) {
    this._approvalRequestId = value;
  }
  get customerId(): string {
    return this._customerId;
  }
  set customerId(value: string) {
    this._customerId = value;
  }
  get state(): storage005.AttestationPolicyState {
    return this._state;
  }
  set state(value: storage005.AttestationPolicyState) {
    this._state = value;
  }
  get etag(): string {
    return this._etag;
  }
  set etag(value: string) {
    this._etag = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    AddAttestationPolicyStateRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): AddAttestationPolicyStateRequest.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      approvalRequestId: this.approvalRequestId,
      customerId: this.customerId,
      state: this.state,
      etag: this.etag,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): AddAttestationPolicyStateRequest.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      approvalRequestId: this.approvalRequestId,
      customerId: this.customerId,
      state:
        storage005.AttestationPolicyState[
          this.state === null || this.state === undefined ? 0 : this.state
        ],
      etag: this.etag,
    };
  }
}
export module AddAttestationPolicyStateRequest {
  /**
   * Standard JavaScript object representation for AddAttestationPolicyStateRequest
   */
  export interface AsObject {
    header?: api003.RequestHeader.AsObject;
    approvalRequestId: string;
    customerId: string;
    state: storage005.AttestationPolicyState;
    etag: string;
  }

  /**
   * Protobuf JSON representation for AddAttestationPolicyStateRequest
   */
  export interface AsProtobufJSON {
    header: api003.RequestHeader.AsProtobufJSON | null;
    approvalRequestId: string;
    customerId: string;
    state: string;
    etag: string;
  }
}

/**
 * Message implementation for api.approval.AddAttestationPolicyStateResponse
 */
export class AddAttestationPolicyStateResponse implements GrpcMessage {
  static id = 'api.approval.AddAttestationPolicyStateResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new AddAttestationPolicyStateResponse();
    AddAttestationPolicyStateResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: AddAttestationPolicyStateResponse) {
    _instance.header = _instance.header || undefined;
    _instance.etag = _instance.etag || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: AddAttestationPolicyStateResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api003.ResponseHeader();
          _reader.readMessage(
            _instance.header,
            api003.ResponseHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.etag = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    AddAttestationPolicyStateResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: AddAttestationPolicyStateResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api003.ResponseHeader.serializeBinaryToWriter
      );
    }
    if (_instance.etag) {
      _writer.writeString(2, _instance.etag);
    }
  }

  private _header?: api003.ResponseHeader;
  private _etag: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of AddAttestationPolicyStateResponse to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<AddAttestationPolicyStateResponse.AsObject>
  ) {
    _value = _value || {};
    this.header = _value.header
      ? new api003.ResponseHeader(_value.header)
      : undefined;
    this.etag = _value.etag;
    AddAttestationPolicyStateResponse.refineValues(this);
  }
  get header(): api003.ResponseHeader | undefined {
    return this._header;
  }
  set header(value: api003.ResponseHeader | undefined) {
    this._header = value;
  }
  get etag(): string {
    return this._etag;
  }
  set etag(value: string) {
    this._etag = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    AddAttestationPolicyStateResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): AddAttestationPolicyStateResponse.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      etag: this.etag,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): AddAttestationPolicyStateResponse.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      etag: this.etag,
    };
  }
}
export module AddAttestationPolicyStateResponse {
  /**
   * Standard JavaScript object representation for AddAttestationPolicyStateResponse
   */
  export interface AsObject {
    header?: api003.ResponseHeader.AsObject;
    etag: string;
  }

  /**
   * Protobuf JSON representation for AddAttestationPolicyStateResponse
   */
  export interface AsProtobufJSON {
    header: api003.ResponseHeader.AsProtobufJSON | null;
    etag: string;
  }
}

/**
 * Message implementation for api.approval.ArchiveRequest
 */
export class ArchiveRequest implements GrpcMessage {
  static id = 'api.approval.ArchiveRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ArchiveRequest();
    ArchiveRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ArchiveRequest) {
    _instance.header = _instance.header || undefined;
    _instance.approvalRequestId = _instance.approvalRequestId || '';
    _instance.etag = _instance.etag || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ArchiveRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api003.RequestHeader();
          _reader.readMessage(
            _instance.header,
            api003.RequestHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.approvalRequestId = _reader.readString();
          break;
        case 3:
          _instance.etag = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    ArchiveRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ArchiveRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api003.RequestHeader.serializeBinaryToWriter
      );
    }
    if (_instance.approvalRequestId) {
      _writer.writeString(2, _instance.approvalRequestId);
    }
    if (_instance.etag) {
      _writer.writeString(3, _instance.etag);
    }
  }

  private _header?: api003.RequestHeader;
  private _approvalRequestId: string;
  private _etag: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ArchiveRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<ArchiveRequest.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api003.RequestHeader(_value.header)
      : undefined;
    this.approvalRequestId = _value.approvalRequestId;
    this.etag = _value.etag;
    ArchiveRequest.refineValues(this);
  }
  get header(): api003.RequestHeader | undefined {
    return this._header;
  }
  set header(value: api003.RequestHeader | undefined) {
    this._header = value;
  }
  get approvalRequestId(): string {
    return this._approvalRequestId;
  }
  set approvalRequestId(value: string) {
    this._approvalRequestId = value;
  }
  get etag(): string {
    return this._etag;
  }
  set etag(value: string) {
    this._etag = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ArchiveRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ArchiveRequest.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      approvalRequestId: this.approvalRequestId,
      etag: this.etag,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ArchiveRequest.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      approvalRequestId: this.approvalRequestId,
      etag: this.etag,
    };
  }
}
export module ArchiveRequest {
  /**
   * Standard JavaScript object representation for ArchiveRequest
   */
  export interface AsObject {
    header?: api003.RequestHeader.AsObject;
    approvalRequestId: string;
    etag: string;
  }

  /**
   * Protobuf JSON representation for ArchiveRequest
   */
  export interface AsProtobufJSON {
    header: api003.RequestHeader.AsProtobufJSON | null;
    approvalRequestId: string;
    etag: string;
  }
}

/**
 * Message implementation for api.approval.ArchiveResponse
 */
export class ArchiveResponse implements GrpcMessage {
  static id = 'api.approval.ArchiveResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ArchiveResponse();
    ArchiveResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ArchiveResponse) {
    _instance.header = _instance.header || undefined;
    _instance.etag = _instance.etag || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ArchiveResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api003.ResponseHeader();
          _reader.readMessage(
            _instance.header,
            api003.ResponseHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.etag = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    ArchiveResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ArchiveResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api003.ResponseHeader.serializeBinaryToWriter
      );
    }
    if (_instance.etag) {
      _writer.writeString(2, _instance.etag);
    }
  }

  private _header?: api003.ResponseHeader;
  private _etag: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ArchiveResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<ArchiveResponse.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api003.ResponseHeader(_value.header)
      : undefined;
    this.etag = _value.etag;
    ArchiveResponse.refineValues(this);
  }
  get header(): api003.ResponseHeader | undefined {
    return this._header;
  }
  set header(value: api003.ResponseHeader | undefined) {
    this._header = value;
  }
  get etag(): string {
    return this._etag;
  }
  set etag(value: string) {
    this._etag = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ArchiveResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ArchiveResponse.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      etag: this.etag,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ArchiveResponse.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      etag: this.etag,
    };
  }
}
export module ArchiveResponse {
  /**
   * Standard JavaScript object representation for ArchiveResponse
   */
  export interface AsObject {
    header?: api003.ResponseHeader.AsObject;
    etag: string;
  }

  /**
   * Protobuf JSON representation for ArchiveResponse
   */
  export interface AsProtobufJSON {
    header: api003.ResponseHeader.AsProtobufJSON | null;
    etag: string;
  }
}

/**
 * Message implementation for api.approval.ApiApprovalRequest
 */
export class ApiApprovalRequest implements GrpcMessage {
  static id = 'api.approval.ApiApprovalRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ApiApprovalRequest();
    ApiApprovalRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ApiApprovalRequest) {
    _instance.id = _instance.id || '';
    _instance.approvalRequestDetail =
      _instance.approvalRequestDetail || undefined;
    _instance.decisionRollup = _instance.decisionRollup || undefined;
    _instance.etag = _instance.etag || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ApiApprovalRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readString();
          break;
        case 2:
          _instance.approvalRequestDetail =
            new storage005.ApprovalRequestDetail();
          _reader.readMessage(
            _instance.approvalRequestDetail,
            storage005.ApprovalRequestDetail.deserializeBinaryFromReader
          );
          break;
        case 3:
          _instance.decisionRollup = new DecisionRollup();
          _reader.readMessage(
            _instance.decisionRollup,
            DecisionRollup.deserializeBinaryFromReader
          );
          break;
        case 4:
          _instance.etag = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    ApiApprovalRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ApiApprovalRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeString(1, _instance.id);
    }
    if (_instance.approvalRequestDetail) {
      _writer.writeMessage(
        2,
        _instance.approvalRequestDetail as any,
        storage005.ApprovalRequestDetail.serializeBinaryToWriter
      );
    }
    if (_instance.decisionRollup) {
      _writer.writeMessage(
        3,
        _instance.decisionRollup as any,
        DecisionRollup.serializeBinaryToWriter
      );
    }
    if (_instance.etag) {
      _writer.writeString(4, _instance.etag);
    }
  }

  private _id: string;
  private _approvalRequestDetail?: storage005.ApprovalRequestDetail;
  private _decisionRollup?: DecisionRollup;
  private _etag: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ApiApprovalRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<ApiApprovalRequest.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    this.approvalRequestDetail = _value.approvalRequestDetail
      ? new storage005.ApprovalRequestDetail(_value.approvalRequestDetail)
      : undefined;
    this.decisionRollup = _value.decisionRollup
      ? new DecisionRollup(_value.decisionRollup)
      : undefined;
    this.etag = _value.etag;
    ApiApprovalRequest.refineValues(this);
  }
  get id(): string {
    return this._id;
  }
  set id(value: string) {
    this._id = value;
  }
  get approvalRequestDetail(): storage005.ApprovalRequestDetail | undefined {
    return this._approvalRequestDetail;
  }
  set approvalRequestDetail(
    value: storage005.ApprovalRequestDetail | undefined
  ) {
    this._approvalRequestDetail = value;
  }
  get decisionRollup(): DecisionRollup | undefined {
    return this._decisionRollup;
  }
  set decisionRollup(value: DecisionRollup | undefined) {
    this._decisionRollup = value;
  }
  get etag(): string {
    return this._etag;
  }
  set etag(value: string) {
    this._etag = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ApiApprovalRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ApiApprovalRequest.AsObject {
    return {
      id: this.id,
      approvalRequestDetail: this.approvalRequestDetail
        ? this.approvalRequestDetail.toObject()
        : undefined,
      decisionRollup: this.decisionRollup
        ? this.decisionRollup.toObject()
        : undefined,
      etag: this.etag,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ApiApprovalRequest.AsProtobufJSON {
    return {
      id: this.id,
      approvalRequestDetail: this.approvalRequestDetail
        ? this.approvalRequestDetail.toProtobufJSON(options)
        : null,
      decisionRollup: this.decisionRollup
        ? this.decisionRollup.toProtobufJSON(options)
        : null,
      etag: this.etag,
    };
  }
}
export module ApiApprovalRequest {
  /**
   * Standard JavaScript object representation for ApiApprovalRequest
   */
  export interface AsObject {
    id: string;
    approvalRequestDetail?: storage005.ApprovalRequestDetail.AsObject;
    decisionRollup?: DecisionRollup.AsObject;
    etag: string;
  }

  /**
   * Protobuf JSON representation for ApiApprovalRequest
   */
  export interface AsProtobufJSON {
    id: string;
    approvalRequestDetail: storage005.ApprovalRequestDetail.AsProtobufJSON | null;
    decisionRollup: DecisionRollup.AsProtobufJSON | null;
    etag: string;
  }
}

/**
 * Message implementation for api.approval.ApiApprovalRequestAllCustomers
 */
export class ApiApprovalRequestAllCustomers implements GrpcMessage {
  static id = 'api.approval.ApiApprovalRequestAllCustomers';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ApiApprovalRequestAllCustomers();
    ApiApprovalRequestAllCustomers.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ApiApprovalRequestAllCustomers) {
    _instance.id = _instance.id || '';
    _instance.approvalRequestDetail =
      _instance.approvalRequestDetail || undefined;
    _instance.decisionRollups = _instance.decisionRollups || [];
    _instance.etag = _instance.etag || '';
    _instance.approvedViewers = _instance.approvedViewers || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ApiApprovalRequestAllCustomers,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readString();
          break;
        case 2:
          _instance.approvalRequestDetail =
            new storage005.ApprovalRequestDetail();
          _reader.readMessage(
            _instance.approvalRequestDetail,
            storage005.ApprovalRequestDetail.deserializeBinaryFromReader
          );
          break;
        case 3:
          const messageInitializer3 = new DecisionRollup();
          _reader.readMessage(
            messageInitializer3,
            DecisionRollup.deserializeBinaryFromReader
          );
          (_instance.decisionRollups = _instance.decisionRollups || []).push(
            messageInitializer3
          );
          break;
        case 4:
          _instance.etag = _reader.readString();
          break;
        case 5:
          _instance.approvedViewers = new storage005.ApprovedViewers();
          _reader.readMessage(
            _instance.approvedViewers,
            storage005.ApprovedViewers.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    ApiApprovalRequestAllCustomers.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ApiApprovalRequestAllCustomers,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeString(1, _instance.id);
    }
    if (_instance.approvalRequestDetail) {
      _writer.writeMessage(
        2,
        _instance.approvalRequestDetail as any,
        storage005.ApprovalRequestDetail.serializeBinaryToWriter
      );
    }
    if (_instance.decisionRollups && _instance.decisionRollups.length) {
      _writer.writeRepeatedMessage(
        3,
        _instance.decisionRollups as any,
        DecisionRollup.serializeBinaryToWriter
      );
    }
    if (_instance.etag) {
      _writer.writeString(4, _instance.etag);
    }
    if (_instance.approvedViewers) {
      _writer.writeMessage(
        5,
        _instance.approvedViewers as any,
        storage005.ApprovedViewers.serializeBinaryToWriter
      );
    }
  }

  private _id: string;
  private _approvalRequestDetail?: storage005.ApprovalRequestDetail;
  private _decisionRollups?: DecisionRollup[];
  private _etag: string;
  private _approvedViewers?: storage005.ApprovedViewers;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ApiApprovalRequestAllCustomers to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<ApiApprovalRequestAllCustomers.AsObject>
  ) {
    _value = _value || {};
    this.id = _value.id;
    this.approvalRequestDetail = _value.approvalRequestDetail
      ? new storage005.ApprovalRequestDetail(_value.approvalRequestDetail)
      : undefined;
    this.decisionRollups = (_value.decisionRollups || []).map(
      (m) => new DecisionRollup(m)
    );
    this.etag = _value.etag;
    this.approvedViewers = _value.approvedViewers
      ? new storage005.ApprovedViewers(_value.approvedViewers)
      : undefined;
    ApiApprovalRequestAllCustomers.refineValues(this);
  }
  get id(): string {
    return this._id;
  }
  set id(value: string) {
    this._id = value;
  }
  get approvalRequestDetail(): storage005.ApprovalRequestDetail | undefined {
    return this._approvalRequestDetail;
  }
  set approvalRequestDetail(
    value: storage005.ApprovalRequestDetail | undefined
  ) {
    this._approvalRequestDetail = value;
  }
  get decisionRollups(): DecisionRollup[] | undefined {
    return this._decisionRollups;
  }
  set decisionRollups(value: DecisionRollup[] | undefined) {
    this._decisionRollups = value;
  }
  get etag(): string {
    return this._etag;
  }
  set etag(value: string) {
    this._etag = value;
  }
  get approvedViewers(): storage005.ApprovedViewers | undefined {
    return this._approvedViewers;
  }
  set approvedViewers(value: storage005.ApprovedViewers | undefined) {
    this._approvedViewers = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ApiApprovalRequestAllCustomers.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ApiApprovalRequestAllCustomers.AsObject {
    return {
      id: this.id,
      approvalRequestDetail: this.approvalRequestDetail
        ? this.approvalRequestDetail.toObject()
        : undefined,
      decisionRollups: (this.decisionRollups || []).map((m) => m.toObject()),
      etag: this.etag,
      approvedViewers: this.approvedViewers
        ? this.approvedViewers.toObject()
        : undefined,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ApiApprovalRequestAllCustomers.AsProtobufJSON {
    return {
      id: this.id,
      approvalRequestDetail: this.approvalRequestDetail
        ? this.approvalRequestDetail.toProtobufJSON(options)
        : null,
      decisionRollups: (this.decisionRollups || []).map((m) =>
        m.toProtobufJSON(options)
      ),
      etag: this.etag,
      approvedViewers: this.approvedViewers
        ? this.approvedViewers.toProtobufJSON(options)
        : null,
    };
  }
}
export module ApiApprovalRequestAllCustomers {
  /**
   * Standard JavaScript object representation for ApiApprovalRequestAllCustomers
   */
  export interface AsObject {
    id: string;
    approvalRequestDetail?: storage005.ApprovalRequestDetail.AsObject;
    decisionRollups?: DecisionRollup.AsObject[];
    etag: string;
    approvedViewers?: storage005.ApprovedViewers.AsObject;
  }

  /**
   * Protobuf JSON representation for ApiApprovalRequestAllCustomers
   */
  export interface AsProtobufJSON {
    id: string;
    approvalRequestDetail: storage005.ApprovalRequestDetail.AsProtobufJSON | null;
    decisionRollups: DecisionRollup.AsProtobufJSON[] | null;
    etag: string;
    approvedViewers: storage005.ApprovedViewers.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for api.approval.DecisionRollup
 */
export class DecisionRollup implements GrpcMessage {
  static id = 'api.approval.DecisionRollup';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new DecisionRollup();
    DecisionRollup.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: DecisionRollup) {
    _instance.customerId = _instance.customerId || '';
    _instance.binaryStateDecision = _instance.binaryStateDecision || undefined;
    _instance.approvalDecision = _instance.approvalDecision || undefined;
    _instance.attestationPolicyState = _instance.attestationPolicyState || 0;
    _instance.binaryType = _instance.binaryType || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: DecisionRollup,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.customerId = _reader.readString();
          break;
        case 6:
          _instance.binaryStateDecision = new storage005.BinaryStateDecision();
          _reader.readMessage(
            _instance.binaryStateDecision,
            storage005.BinaryStateDecision.deserializeBinaryFromReader
          );
          break;
        case 3:
          _instance.approvalDecision = new ApprovalDecisionRollup();
          _reader.readMessage(
            _instance.approvalDecision,
            ApprovalDecisionRollup.deserializeBinaryFromReader
          );
          break;
        case 4:
          _instance.attestationPolicyState = _reader.readEnum();
          break;
        case 5:
          _instance.binaryType = _reader.readEnum();
          break;
        default:
          _reader.skipField();
      }
    }

    DecisionRollup.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: DecisionRollup,
    _writer: BinaryWriter
  ) {
    if (_instance.customerId) {
      _writer.writeString(1, _instance.customerId);
    }
    if (_instance.binaryStateDecision) {
      _writer.writeMessage(
        6,
        _instance.binaryStateDecision as any,
        storage005.BinaryStateDecision.serializeBinaryToWriter
      );
    }
    if (_instance.approvalDecision) {
      _writer.writeMessage(
        3,
        _instance.approvalDecision as any,
        ApprovalDecisionRollup.serializeBinaryToWriter
      );
    }
    if (_instance.attestationPolicyState) {
      _writer.writeEnum(4, _instance.attestationPolicyState);
    }
    if (_instance.binaryType) {
      _writer.writeEnum(5, _instance.binaryType);
    }
  }

  private _customerId: string;
  private _binaryStateDecision?: storage005.BinaryStateDecision;
  private _approvalDecision?: ApprovalDecisionRollup;
  private _attestationPolicyState: storage005.AttestationPolicyState;
  private _binaryType: storage001.BinaryType;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of DecisionRollup to deeply clone from
   */
  constructor(_value?: RecursivePartial<DecisionRollup.AsObject>) {
    _value = _value || {};
    this.customerId = _value.customerId;
    this.binaryStateDecision = _value.binaryStateDecision
      ? new storage005.BinaryStateDecision(_value.binaryStateDecision)
      : undefined;
    this.approvalDecision = _value.approvalDecision
      ? new ApprovalDecisionRollup(_value.approvalDecision)
      : undefined;
    this.attestationPolicyState = _value.attestationPolicyState;
    this.binaryType = _value.binaryType;
    DecisionRollup.refineValues(this);
  }
  get customerId(): string {
    return this._customerId;
  }
  set customerId(value: string) {
    this._customerId = value;
  }
  get binaryStateDecision(): storage005.BinaryStateDecision | undefined {
    return this._binaryStateDecision;
  }
  set binaryStateDecision(value: storage005.BinaryStateDecision | undefined) {
    this._binaryStateDecision = value;
  }
  get approvalDecision(): ApprovalDecisionRollup | undefined {
    return this._approvalDecision;
  }
  set approvalDecision(value: ApprovalDecisionRollup | undefined) {
    this._approvalDecision = value;
  }
  get attestationPolicyState(): storage005.AttestationPolicyState {
    return this._attestationPolicyState;
  }
  set attestationPolicyState(value: storage005.AttestationPolicyState) {
    this._attestationPolicyState = value;
  }
  get binaryType(): storage001.BinaryType {
    return this._binaryType;
  }
  set binaryType(value: storage001.BinaryType) {
    this._binaryType = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    DecisionRollup.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): DecisionRollup.AsObject {
    return {
      customerId: this.customerId,
      binaryStateDecision: this.binaryStateDecision
        ? this.binaryStateDecision.toObject()
        : undefined,
      approvalDecision: this.approvalDecision
        ? this.approvalDecision.toObject()
        : undefined,
      attestationPolicyState: this.attestationPolicyState,
      binaryType: this.binaryType,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): DecisionRollup.AsProtobufJSON {
    return {
      customerId: this.customerId,
      binaryStateDecision: this.binaryStateDecision
        ? this.binaryStateDecision.toProtobufJSON(options)
        : null,
      approvalDecision: this.approvalDecision
        ? this.approvalDecision.toProtobufJSON(options)
        : null,
      attestationPolicyState:
        storage005.AttestationPolicyState[
          this.attestationPolicyState === null ||
          this.attestationPolicyState === undefined
            ? 0
            : this.attestationPolicyState
        ],
      binaryType:
        storage001.BinaryType[
          this.binaryType === null || this.binaryType === undefined
            ? 0
            : this.binaryType
        ],
    };
  }
}
export module DecisionRollup {
  /**
   * Standard JavaScript object representation for DecisionRollup
   */
  export interface AsObject {
    customerId: string;
    binaryStateDecision?: storage005.BinaryStateDecision.AsObject;
    approvalDecision?: ApprovalDecisionRollup.AsObject;
    attestationPolicyState: storage005.AttestationPolicyState;
    binaryType: storage001.BinaryType;
  }

  /**
   * Protobuf JSON representation for DecisionRollup
   */
  export interface AsProtobufJSON {
    customerId: string;
    binaryStateDecision: storage005.BinaryStateDecision.AsProtobufJSON | null;
    approvalDecision: ApprovalDecisionRollup.AsProtobufJSON | null;
    attestationPolicyState: string;
    binaryType: string;
  }
}

/**
 * Message implementation for api.approval.ApprovalDecisionRollup
 */
export class ApprovalDecisionRollup implements GrpcMessage {
  static id = 'api.approval.ApprovalDecisionRollup';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ApprovalDecisionRollup();
    ApprovalDecisionRollup.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ApprovalDecisionRollup) {
    _instance.approvalState = _instance.approvalState || 0;
    _instance.time = _instance.time || undefined;
    _instance.approvalDecisionByArea = _instance.approvalDecisionByArea || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ApprovalDecisionRollup,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.approvalState = _reader.readEnum();
          break;
        case 2:
          _instance.time = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.time,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 3:
          const messageInitializer3 = new storage005.ApprovalDecision();
          _reader.readMessage(
            messageInitializer3,
            storage005.ApprovalDecision.deserializeBinaryFromReader
          );
          (_instance.approvalDecisionByArea =
            _instance.approvalDecisionByArea || []).push(messageInitializer3);
          break;
        default:
          _reader.skipField();
      }
    }

    ApprovalDecisionRollup.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ApprovalDecisionRollup,
    _writer: BinaryWriter
  ) {
    if (_instance.approvalState) {
      _writer.writeEnum(1, _instance.approvalState);
    }
    if (_instance.time) {
      _writer.writeMessage(
        2,
        _instance.time as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
    if (
      _instance.approvalDecisionByArea &&
      _instance.approvalDecisionByArea.length
    ) {
      _writer.writeRepeatedMessage(
        3,
        _instance.approvalDecisionByArea as any,
        storage005.ApprovalDecision.serializeBinaryToWriter
      );
    }
  }

  private _approvalState: storage005.AuditAreaApprovalState;
  private _time?: googleProtobuf000.Timestamp;
  private _approvalDecisionByArea?: storage005.ApprovalDecision[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ApprovalDecisionRollup to deeply clone from
   */
  constructor(_value?: RecursivePartial<ApprovalDecisionRollup.AsObject>) {
    _value = _value || {};
    this.approvalState = _value.approvalState;
    this.time = _value.time
      ? new googleProtobuf000.Timestamp(_value.time)
      : undefined;
    this.approvalDecisionByArea = (_value.approvalDecisionByArea || []).map(
      (m) => new storage005.ApprovalDecision(m)
    );
    ApprovalDecisionRollup.refineValues(this);
  }
  get approvalState(): storage005.AuditAreaApprovalState {
    return this._approvalState;
  }
  set approvalState(value: storage005.AuditAreaApprovalState) {
    this._approvalState = value;
  }
  get time(): googleProtobuf000.Timestamp | undefined {
    return this._time;
  }
  set time(value: googleProtobuf000.Timestamp | undefined) {
    this._time = value;
  }
  get approvalDecisionByArea(): storage005.ApprovalDecision[] | undefined {
    return this._approvalDecisionByArea;
  }
  set approvalDecisionByArea(value: storage005.ApprovalDecision[] | undefined) {
    this._approvalDecisionByArea = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ApprovalDecisionRollup.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ApprovalDecisionRollup.AsObject {
    return {
      approvalState: this.approvalState,
      time: this.time ? this.time.toObject() : undefined,
      approvalDecisionByArea: (this.approvalDecisionByArea || []).map((m) =>
        m.toObject()
      ),
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ApprovalDecisionRollup.AsProtobufJSON {
    return {
      approvalState:
        storage005.AuditAreaApprovalState[
          this.approvalState === null || this.approvalState === undefined
            ? 0
            : this.approvalState
        ],
      time: this.time ? this.time.toProtobufJSON(options) : null,
      approvalDecisionByArea: (this.approvalDecisionByArea || []).map((m) =>
        m.toProtobufJSON(options)
      ),
    };
  }
}
export module ApprovalDecisionRollup {
  /**
   * Standard JavaScript object representation for ApprovalDecisionRollup
   */
  export interface AsObject {
    approvalState: storage005.AuditAreaApprovalState;
    time?: googleProtobuf000.Timestamp.AsObject;
    approvalDecisionByArea?: storage005.ApprovalDecision.AsObject[];
  }

  /**
   * Protobuf JSON representation for ApprovalDecisionRollup
   */
  export interface AsProtobufJSON {
    approvalState: string;
    time: googleProtobuf000.Timestamp.AsProtobufJSON | null;
    approvalDecisionByArea: storage005.ApprovalDecision.AsProtobufJSON[] | null;
  }
}

/**
 * Message implementation for api.approval.ApprovalRequestFilter
 */
export class ApprovalRequestFilter implements GrpcMessage {
  static id = 'api.approval.ApprovalRequestFilter';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ApprovalRequestFilter();
    ApprovalRequestFilter.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ApprovalRequestFilter) {
    _instance.approvalRequestId = _instance.approvalRequestId || [];
    _instance.customerId = _instance.customerId || [];
    _instance.binaryType = _instance.binaryType || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ApprovalRequestFilter,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          (_instance.approvalRequestId =
            _instance.approvalRequestId || []).push(_reader.readString());
          break;
        case 2:
          (_instance.customerId = _instance.customerId || []).push(
            _reader.readString()
          );
          break;
        case 3:
          (_instance.binaryType = _instance.binaryType || []).push(
            ...(_reader.readPackedEnum() || [])
          );
          break;
        case 4:
          _instance.binaryState = _reader.readEnum();
          break;
        case 5:
          _instance.archived = _reader.readBool();
          break;
        default:
          _reader.skipField();
      }
    }

    ApprovalRequestFilter.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ApprovalRequestFilter,
    _writer: BinaryWriter
  ) {
    if (_instance.approvalRequestId && _instance.approvalRequestId.length) {
      _writer.writeRepeatedString(1, _instance.approvalRequestId);
    }
    if (_instance.customerId && _instance.customerId.length) {
      _writer.writeRepeatedString(2, _instance.customerId);
    }
    if (_instance.binaryType && _instance.binaryType.length) {
      _writer.writePackedEnum(3, _instance.binaryType);
    }
    if (_instance.binaryState !== undefined && _instance.binaryState !== null) {
      _writer.writeEnum(4, _instance.binaryState);
    }
    if (_instance.archived !== undefined && _instance.archived !== null) {
      _writer.writeBool(5, _instance.archived);
    }
  }

  private _approvalRequestId: string[];
  private _customerId: string[];
  private _binaryType: storage001.BinaryType[];
  private _binaryState?: storage005.BinaryState;
  private _archived?: boolean;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ApprovalRequestFilter to deeply clone from
   */
  constructor(_value?: RecursivePartial<ApprovalRequestFilter.AsObject>) {
    _value = _value || {};
    this.approvalRequestId = (_value.approvalRequestId || []).slice();
    this.customerId = (_value.customerId || []).slice();
    this.binaryType = (_value.binaryType || []).slice();
    this.binaryState = _value.binaryState;
    this.archived = _value.archived;
    ApprovalRequestFilter.refineValues(this);
  }
  get approvalRequestId(): string[] {
    return this._approvalRequestId;
  }
  set approvalRequestId(value: string[]) {
    this._approvalRequestId = value;
  }
  get customerId(): string[] {
    return this._customerId;
  }
  set customerId(value: string[]) {
    this._customerId = value;
  }
  get binaryType(): storage001.BinaryType[] {
    return this._binaryType;
  }
  set binaryType(value: storage001.BinaryType[]) {
    this._binaryType = value;
  }
  get binaryState(): storage005.BinaryState | undefined {
    return this._binaryState;
  }
  set binaryState(value?: storage005.BinaryState) {
    this._binaryState = value;
  }
  get archived(): boolean | undefined {
    return this._archived;
  }
  set archived(value?: boolean) {
    this._archived = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ApprovalRequestFilter.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ApprovalRequestFilter.AsObject {
    return {
      approvalRequestId: (this.approvalRequestId || []).slice(),
      customerId: (this.customerId || []).slice(),
      binaryType: (this.binaryType || []).slice(),
      binaryState: this.binaryState,
      archived: this.archived,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ApprovalRequestFilter.AsProtobufJSON {
    return {
      approvalRequestId: (this.approvalRequestId || []).slice(),
      customerId: (this.customerId || []).slice(),
      binaryType: (this.binaryType || []).map((v) => storage001.BinaryType[v]),
      binaryState:
        this.binaryState === undefined
          ? null
          : storage005.BinaryState[
              this.binaryState === null || this.binaryState === undefined
                ? 0
                : this.binaryState
            ],
      archived: this.archived === undefined ? null : this.archived,
    };
  }
}
export module ApprovalRequestFilter {
  /**
   * Standard JavaScript object representation for ApprovalRequestFilter
   */
  export interface AsObject {
    approvalRequestId: string[];
    customerId: string[];
    binaryType: storage001.BinaryType[];
    binaryState?: storage005.BinaryState;
    archived?: boolean;
  }

  /**
   * Protobuf JSON representation for ApprovalRequestFilter
   */
  export interface AsProtobufJSON {
    approvalRequestId: string[];
    customerId: string[];
    binaryType: string[];
    binaryState: string | null;
    archived: boolean | null;
  }
}
