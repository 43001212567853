/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import {
  GrpcMessage,
  RecursivePartial,
  ToProtobufJSONOptions,
} from '@ngx-grpc/common';
import { BinaryReader, BinaryWriter, ByteSource } from 'google-protobuf';

/**
 * Message implementation for matching.MatchingConfig
 */
export class MatchingConfig implements GrpcMessage {
  static id = 'matching.MatchingConfig';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new MatchingConfig();
    MatchingConfig.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: MatchingConfig) {
    _instance.matchingColumns = _instance.matchingColumns || [];
    _instance.maxConversionsPerKeyValue =
      _instance.maxConversionsPerKeyValue || 0;
    _instance.maxImpressionsPerKeyValue =
      _instance.maxImpressionsPerKeyValue || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: MatchingConfig,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          (_instance.matchingColumns = _instance.matchingColumns || []).push(
            _reader.readString()
          );
          break;
        case 2:
          _instance.maxConversionsPerKeyValue = _reader.readInt32();
          break;
        case 3:
          _instance.maxImpressionsPerKeyValue = _reader.readInt32();
          break;
        default:
          _reader.skipField();
      }
    }

    MatchingConfig.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: MatchingConfig,
    _writer: BinaryWriter
  ) {
    if (_instance.matchingColumns && _instance.matchingColumns.length) {
      _writer.writeRepeatedString(1, _instance.matchingColumns);
    }
    if (_instance.maxConversionsPerKeyValue) {
      _writer.writeInt32(2, _instance.maxConversionsPerKeyValue);
    }
    if (_instance.maxImpressionsPerKeyValue) {
      _writer.writeInt32(3, _instance.maxImpressionsPerKeyValue);
    }
  }

  private _matchingColumns: string[];
  private _maxConversionsPerKeyValue: number;
  private _maxImpressionsPerKeyValue: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of MatchingConfig to deeply clone from
   */
  constructor(_value?: RecursivePartial<MatchingConfig.AsObject>) {
    _value = _value || {};
    this.matchingColumns = (_value.matchingColumns || []).slice();
    this.maxConversionsPerKeyValue = _value.maxConversionsPerKeyValue;
    this.maxImpressionsPerKeyValue = _value.maxImpressionsPerKeyValue;
    MatchingConfig.refineValues(this);
  }
  get matchingColumns(): string[] {
    return this._matchingColumns;
  }
  set matchingColumns(value: string[]) {
    this._matchingColumns = value;
  }
  get maxConversionsPerKeyValue(): number {
    return this._maxConversionsPerKeyValue;
  }
  set maxConversionsPerKeyValue(value: number) {
    this._maxConversionsPerKeyValue = value;
  }
  get maxImpressionsPerKeyValue(): number {
    return this._maxImpressionsPerKeyValue;
  }
  set maxImpressionsPerKeyValue(value: number) {
    this._maxImpressionsPerKeyValue = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    MatchingConfig.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): MatchingConfig.AsObject {
    return {
      matchingColumns: (this.matchingColumns || []).slice(),
      maxConversionsPerKeyValue: this.maxConversionsPerKeyValue,
      maxImpressionsPerKeyValue: this.maxImpressionsPerKeyValue,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): MatchingConfig.AsProtobufJSON {
    return {
      matchingColumns: (this.matchingColumns || []).slice(),
      maxConversionsPerKeyValue: this.maxConversionsPerKeyValue,
      maxImpressionsPerKeyValue: this.maxImpressionsPerKeyValue,
    };
  }
}
export module MatchingConfig {
  /**
   * Standard JavaScript object representation for MatchingConfig
   */
  export interface AsObject {
    matchingColumns: string[];
    maxConversionsPerKeyValue: number;
    maxImpressionsPerKeyValue: number;
  }

  /**
   * Protobuf JSON representation for MatchingConfig
   */
  export interface AsProtobufJSON {
    matchingColumns: string[];
    maxConversionsPerKeyValue: number;
    maxImpressionsPerKeyValue: number;
  }
}
