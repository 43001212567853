/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import {
  GrpcMessage,
  RecursivePartial,
  ToProtobufJSONOptions,
} from '@ngx-grpc/common';
import { BinaryReader, BinaryWriter, ByteSource } from 'google-protobuf';
import * as googleProtobuf000 from '@ngx-grpc/well-known-types';
import * as storage001 from '../../../../src/main/proto/storage/schema-annotations.pb';
import * as api002 from '../../../../src/main/proto/api/headers.pb';
import * as storage003 from '../../../../src/main/proto/storage/key-config.pb';
/**
 * Message implementation for api.key.GetPublicKeyRequest
 */
export class GetPublicKeyRequest implements GrpcMessage {
  static id = 'api.key.GetPublicKeyRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetPublicKeyRequest();
    GetPublicKeyRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetPublicKeyRequest) {
    _instance.header = _instance.header || undefined;
    _instance.keyName = _instance.keyName || '';
    _instance.keyId = _instance.keyId || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetPublicKeyRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api002.RequestHeader();
          _reader.readMessage(
            _instance.header,
            api002.RequestHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.keyName = _reader.readString();
          break;
        case 4:
          _instance.keyId = _reader.readString();
          break;
        case 3:
          _instance.keyVersion = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    GetPublicKeyRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetPublicKeyRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api002.RequestHeader.serializeBinaryToWriter
      );
    }
    if (_instance.keyName) {
      _writer.writeString(2, _instance.keyName);
    }
    if (_instance.keyId) {
      _writer.writeString(4, _instance.keyId);
    }
    if (_instance.keyVersion !== undefined && _instance.keyVersion !== null) {
      _writer.writeString(3, _instance.keyVersion);
    }
  }

  private _header?: api002.RequestHeader;
  private _keyName: string;
  private _keyId: string;
  private _keyVersion?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetPublicKeyRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetPublicKeyRequest.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api002.RequestHeader(_value.header)
      : undefined;
    this.keyName = _value.keyName;
    this.keyId = _value.keyId;
    this.keyVersion = _value.keyVersion;
    GetPublicKeyRequest.refineValues(this);
  }
  get header(): api002.RequestHeader | undefined {
    return this._header;
  }
  set header(value: api002.RequestHeader | undefined) {
    this._header = value;
  }
  get keyName(): string {
    return this._keyName;
  }
  set keyName(value: string) {
    this._keyName = value;
  }
  get keyId(): string {
    return this._keyId;
  }
  set keyId(value: string) {
    this._keyId = value;
  }
  get keyVersion(): string | undefined {
    return this._keyVersion;
  }
  set keyVersion(value?: string) {
    this._keyVersion = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetPublicKeyRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetPublicKeyRequest.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      keyName: this.keyName,
      keyId: this.keyId,
      keyVersion: this.keyVersion,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetPublicKeyRequest.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      keyName: this.keyName,
      keyId: this.keyId,
      keyVersion:
        this.keyVersion === null || this.keyVersion === undefined
          ? null
          : this.keyVersion,
    };
  }
}
export module GetPublicKeyRequest {
  /**
   * Standard JavaScript object representation for GetPublicKeyRequest
   */
  export interface AsObject {
    header?: api002.RequestHeader.AsObject;
    keyName: string;
    keyId: string;
    keyVersion?: string;
  }

  /**
   * Protobuf JSON representation for GetPublicKeyRequest
   */
  export interface AsProtobufJSON {
    header: api002.RequestHeader.AsProtobufJSON | null;
    keyName: string;
    keyId: string;
    keyVersion: string | null;
  }
}

/**
 * Message implementation for api.key.GetPublicKeyResponse
 */
export class GetPublicKeyResponse implements GrpcMessage {
  static id = 'api.key.GetPublicKeyResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetPublicKeyResponse();
    GetPublicKeyResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetPublicKeyResponse) {
    _instance.header = _instance.header || undefined;
    _instance.keyVersion = _instance.keyVersion || '';
    _instance.keyJsonValue = _instance.keyJsonValue || '';
    _instance.keyDerValue = _instance.keyDerValue || '';
    _instance.createdTime = _instance.createdTime || undefined;
    _instance.expiresTime = _instance.expiresTime || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetPublicKeyResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api002.ResponseHeader();
          _reader.readMessage(
            _instance.header,
            api002.ResponseHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.keyVersion = _reader.readString();
          break;
        case 3:
          _instance.keyJsonValue = _reader.readString();
          break;
        case 4:
          _instance.keyDerValue = _reader.readString();
          break;
        case 5:
          _instance.createdTime = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.createdTime,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 6:
          _instance.expiresTime = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.expiresTime,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    GetPublicKeyResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetPublicKeyResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api002.ResponseHeader.serializeBinaryToWriter
      );
    }
    if (_instance.keyVersion) {
      _writer.writeString(2, _instance.keyVersion);
    }
    if (_instance.keyJsonValue) {
      _writer.writeString(3, _instance.keyJsonValue);
    }
    if (_instance.keyDerValue) {
      _writer.writeString(4, _instance.keyDerValue);
    }
    if (_instance.createdTime) {
      _writer.writeMessage(
        5,
        _instance.createdTime as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.expiresTime) {
      _writer.writeMessage(
        6,
        _instance.expiresTime as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
  }

  private _header?: api002.ResponseHeader;
  private _keyVersion: string;
  private _keyJsonValue: string;
  private _keyDerValue: string;
  private _createdTime?: googleProtobuf000.Timestamp;
  private _expiresTime?: googleProtobuf000.Timestamp;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetPublicKeyResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetPublicKeyResponse.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api002.ResponseHeader(_value.header)
      : undefined;
    this.keyVersion = _value.keyVersion;
    this.keyJsonValue = _value.keyJsonValue;
    this.keyDerValue = _value.keyDerValue;
    this.createdTime = _value.createdTime
      ? new googleProtobuf000.Timestamp(_value.createdTime)
      : undefined;
    this.expiresTime = _value.expiresTime
      ? new googleProtobuf000.Timestamp(_value.expiresTime)
      : undefined;
    GetPublicKeyResponse.refineValues(this);
  }
  get header(): api002.ResponseHeader | undefined {
    return this._header;
  }
  set header(value: api002.ResponseHeader | undefined) {
    this._header = value;
  }
  get keyVersion(): string {
    return this._keyVersion;
  }
  set keyVersion(value: string) {
    this._keyVersion = value;
  }
  get keyJsonValue(): string {
    return this._keyJsonValue;
  }
  set keyJsonValue(value: string) {
    this._keyJsonValue = value;
  }
  get keyDerValue(): string {
    return this._keyDerValue;
  }
  set keyDerValue(value: string) {
    this._keyDerValue = value;
  }
  get createdTime(): googleProtobuf000.Timestamp | undefined {
    return this._createdTime;
  }
  set createdTime(value: googleProtobuf000.Timestamp | undefined) {
    this._createdTime = value;
  }
  get expiresTime(): googleProtobuf000.Timestamp | undefined {
    return this._expiresTime;
  }
  set expiresTime(value: googleProtobuf000.Timestamp | undefined) {
    this._expiresTime = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetPublicKeyResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetPublicKeyResponse.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      keyVersion: this.keyVersion,
      keyJsonValue: this.keyJsonValue,
      keyDerValue: this.keyDerValue,
      createdTime: this.createdTime ? this.createdTime.toObject() : undefined,
      expiresTime: this.expiresTime ? this.expiresTime.toObject() : undefined,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetPublicKeyResponse.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      keyVersion: this.keyVersion,
      keyJsonValue: this.keyJsonValue,
      keyDerValue: this.keyDerValue,
      createdTime: this.createdTime
        ? this.createdTime.toProtobufJSON(options)
        : null,
      expiresTime: this.expiresTime
        ? this.expiresTime.toProtobufJSON(options)
        : null,
    };
  }
}
export module GetPublicKeyResponse {
  /**
   * Standard JavaScript object representation for GetPublicKeyResponse
   */
  export interface AsObject {
    header?: api002.ResponseHeader.AsObject;
    keyVersion: string;
    keyJsonValue: string;
    keyDerValue: string;
    createdTime?: googleProtobuf000.Timestamp.AsObject;
    expiresTime?: googleProtobuf000.Timestamp.AsObject;
  }

  /**
   * Protobuf JSON representation for GetPublicKeyResponse
   */
  export interface AsProtobufJSON {
    header: api002.ResponseHeader.AsProtobufJSON | null;
    keyVersion: string;
    keyJsonValue: string;
    keyDerValue: string;
    createdTime: googleProtobuf000.Timestamp.AsProtobufJSON | null;
    expiresTime: googleProtobuf000.Timestamp.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for api.key.RegisterKeyRequest
 */
export class RegisterKeyRequest implements GrpcMessage {
  static id = 'api.key.RegisterKeyRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new RegisterKeyRequest();
    RegisterKeyRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: RegisterKeyRequest) {
    _instance.header = _instance.header || undefined;
    _instance.keyConfig = _instance.keyConfig || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: RegisterKeyRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api002.RequestHeader();
          _reader.readMessage(
            _instance.header,
            api002.RequestHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.keyConfig = new storage003.KeyConfig();
          _reader.readMessage(
            _instance.keyConfig,
            storage003.KeyConfig.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    RegisterKeyRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: RegisterKeyRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api002.RequestHeader.serializeBinaryToWriter
      );
    }
    if (_instance.keyConfig) {
      _writer.writeMessage(
        2,
        _instance.keyConfig as any,
        storage003.KeyConfig.serializeBinaryToWriter
      );
    }
  }

  private _header?: api002.RequestHeader;
  private _keyConfig?: storage003.KeyConfig;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of RegisterKeyRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<RegisterKeyRequest.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api002.RequestHeader(_value.header)
      : undefined;
    this.keyConfig = _value.keyConfig
      ? new storage003.KeyConfig(_value.keyConfig)
      : undefined;
    RegisterKeyRequest.refineValues(this);
  }
  get header(): api002.RequestHeader | undefined {
    return this._header;
  }
  set header(value: api002.RequestHeader | undefined) {
    this._header = value;
  }
  get keyConfig(): storage003.KeyConfig | undefined {
    return this._keyConfig;
  }
  set keyConfig(value: storage003.KeyConfig | undefined) {
    this._keyConfig = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    RegisterKeyRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): RegisterKeyRequest.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      keyConfig: this.keyConfig ? this.keyConfig.toObject() : undefined,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): RegisterKeyRequest.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      keyConfig: this.keyConfig ? this.keyConfig.toProtobufJSON(options) : null,
    };
  }
}
export module RegisterKeyRequest {
  /**
   * Standard JavaScript object representation for RegisterKeyRequest
   */
  export interface AsObject {
    header?: api002.RequestHeader.AsObject;
    keyConfig?: storage003.KeyConfig.AsObject;
  }

  /**
   * Protobuf JSON representation for RegisterKeyRequest
   */
  export interface AsProtobufJSON {
    header: api002.RequestHeader.AsProtobufJSON | null;
    keyConfig: storage003.KeyConfig.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for api.key.RegisterKeyResponse
 */
export class RegisterKeyResponse implements GrpcMessage {
  static id = 'api.key.RegisterKeyResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new RegisterKeyResponse();
    RegisterKeyResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: RegisterKeyResponse) {
    _instance.header = _instance.header || undefined;
    _instance.id = _instance.id || '';
    _instance.keyId = _instance.keyId || '';
    _instance.etag = _instance.etag || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: RegisterKeyResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api002.ResponseHeader();
          _reader.readMessage(
            _instance.header,
            api002.ResponseHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.id = _reader.readString();
          break;
        case 3:
          _instance.keyId = _reader.readString();
          break;
        case 4:
          _instance.etag = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    RegisterKeyResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: RegisterKeyResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api002.ResponseHeader.serializeBinaryToWriter
      );
    }
    if (_instance.id) {
      _writer.writeString(2, _instance.id);
    }
    if (_instance.keyId) {
      _writer.writeString(3, _instance.keyId);
    }
    if (_instance.etag) {
      _writer.writeString(4, _instance.etag);
    }
  }

  private _header?: api002.ResponseHeader;
  private _id: string;
  private _keyId: string;
  private _etag: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of RegisterKeyResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<RegisterKeyResponse.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api002.ResponseHeader(_value.header)
      : undefined;
    this.id = _value.id;
    this.keyId = _value.keyId;
    this.etag = _value.etag;
    RegisterKeyResponse.refineValues(this);
  }
  get header(): api002.ResponseHeader | undefined {
    return this._header;
  }
  set header(value: api002.ResponseHeader | undefined) {
    this._header = value;
  }
  get id(): string {
    return this._id;
  }
  set id(value: string) {
    this._id = value;
  }
  get keyId(): string {
    return this._keyId;
  }
  set keyId(value: string) {
    this._keyId = value;
  }
  get etag(): string {
    return this._etag;
  }
  set etag(value: string) {
    this._etag = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    RegisterKeyResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): RegisterKeyResponse.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      id: this.id,
      keyId: this.keyId,
      etag: this.etag,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): RegisterKeyResponse.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      id: this.id,
      keyId: this.keyId,
      etag: this.etag,
    };
  }
}
export module RegisterKeyResponse {
  /**
   * Standard JavaScript object representation for RegisterKeyResponse
   */
  export interface AsObject {
    header?: api002.ResponseHeader.AsObject;
    id: string;
    keyId: string;
    etag: string;
  }

  /**
   * Protobuf JSON representation for RegisterKeyResponse
   */
  export interface AsProtobufJSON {
    header: api002.ResponseHeader.AsProtobufJSON | null;
    id: string;
    keyId: string;
    etag: string;
  }
}

/**
 * Message implementation for api.key.UnregisterKeyRequest
 */
export class UnregisterKeyRequest implements GrpcMessage {
  static id = 'api.key.UnregisterKeyRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new UnregisterKeyRequest();
    UnregisterKeyRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: UnregisterKeyRequest) {
    _instance.header = _instance.header || undefined;
    _instance.customerId = _instance.customerId || '';
    _instance.name = _instance.name || '';
    _instance.keyId = _instance.keyId || '';
    _instance.etag = _instance.etag || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: UnregisterKeyRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api002.RequestHeader();
          _reader.readMessage(
            _instance.header,
            api002.RequestHeader.deserializeBinaryFromReader
          );
          break;
        case 3:
          _instance.customerId = _reader.readString();
          break;
        case 2:
          _instance.name = _reader.readString();
          break;
        case 4:
          _instance.keyId = _reader.readString();
          break;
        case 5:
          _instance.etag = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    UnregisterKeyRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: UnregisterKeyRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api002.RequestHeader.serializeBinaryToWriter
      );
    }
    if (_instance.customerId) {
      _writer.writeString(3, _instance.customerId);
    }
    if (_instance.name) {
      _writer.writeString(2, _instance.name);
    }
    if (_instance.keyId) {
      _writer.writeString(4, _instance.keyId);
    }
    if (_instance.etag) {
      _writer.writeString(5, _instance.etag);
    }
  }

  private _header?: api002.RequestHeader;
  private _customerId: string;
  private _name: string;
  private _keyId: string;
  private _etag: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of UnregisterKeyRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<UnregisterKeyRequest.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api002.RequestHeader(_value.header)
      : undefined;
    this.customerId = _value.customerId;
    this.name = _value.name;
    this.keyId = _value.keyId;
    this.etag = _value.etag;
    UnregisterKeyRequest.refineValues(this);
  }
  get header(): api002.RequestHeader | undefined {
    return this._header;
  }
  set header(value: api002.RequestHeader | undefined) {
    this._header = value;
  }
  get customerId(): string {
    return this._customerId;
  }
  set customerId(value: string) {
    this._customerId = value;
  }
  get name(): string {
    return this._name;
  }
  set name(value: string) {
    this._name = value;
  }
  get keyId(): string {
    return this._keyId;
  }
  set keyId(value: string) {
    this._keyId = value;
  }
  get etag(): string {
    return this._etag;
  }
  set etag(value: string) {
    this._etag = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    UnregisterKeyRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): UnregisterKeyRequest.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      customerId: this.customerId,
      name: this.name,
      keyId: this.keyId,
      etag: this.etag,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): UnregisterKeyRequest.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      customerId: this.customerId,
      name: this.name,
      keyId: this.keyId,
      etag: this.etag,
    };
  }
}
export module UnregisterKeyRequest {
  /**
   * Standard JavaScript object representation for UnregisterKeyRequest
   */
  export interface AsObject {
    header?: api002.RequestHeader.AsObject;
    customerId: string;
    name: string;
    keyId: string;
    etag: string;
  }

  /**
   * Protobuf JSON representation for UnregisterKeyRequest
   */
  export interface AsProtobufJSON {
    header: api002.RequestHeader.AsProtobufJSON | null;
    customerId: string;
    name: string;
    keyId: string;
    etag: string;
  }
}

/**
 * Message implementation for api.key.UnregisterKeyResponse
 */
export class UnregisterKeyResponse implements GrpcMessage {
  static id = 'api.key.UnregisterKeyResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new UnregisterKeyResponse();
    UnregisterKeyResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: UnregisterKeyResponse) {
    _instance.header = _instance.header || undefined;
    _instance.id = _instance.id || '';
    _instance.etag = _instance.etag || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: UnregisterKeyResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api002.ResponseHeader();
          _reader.readMessage(
            _instance.header,
            api002.ResponseHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.id = _reader.readString();
          break;
        case 3:
          _instance.etag = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    UnregisterKeyResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: UnregisterKeyResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api002.ResponseHeader.serializeBinaryToWriter
      );
    }
    if (_instance.id) {
      _writer.writeString(2, _instance.id);
    }
    if (_instance.etag) {
      _writer.writeString(3, _instance.etag);
    }
  }

  private _header?: api002.ResponseHeader;
  private _id: string;
  private _etag: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of UnregisterKeyResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<UnregisterKeyResponse.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api002.ResponseHeader(_value.header)
      : undefined;
    this.id = _value.id;
    this.etag = _value.etag;
    UnregisterKeyResponse.refineValues(this);
  }
  get header(): api002.ResponseHeader | undefined {
    return this._header;
  }
  set header(value: api002.ResponseHeader | undefined) {
    this._header = value;
  }
  get id(): string {
    return this._id;
  }
  set id(value: string) {
    this._id = value;
  }
  get etag(): string {
    return this._etag;
  }
  set etag(value: string) {
    this._etag = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    UnregisterKeyResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): UnregisterKeyResponse.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      id: this.id,
      etag: this.etag,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): UnregisterKeyResponse.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      id: this.id,
      etag: this.etag,
    };
  }
}
export module UnregisterKeyResponse {
  /**
   * Standard JavaScript object representation for UnregisterKeyResponse
   */
  export interface AsObject {
    header?: api002.ResponseHeader.AsObject;
    id: string;
    etag: string;
  }

  /**
   * Protobuf JSON representation for UnregisterKeyResponse
   */
  export interface AsProtobufJSON {
    header: api002.ResponseHeader.AsProtobufJSON | null;
    id: string;
    etag: string;
  }
}

/**
 * Message implementation for api.key.ListKeysRequest
 */
export class ListKeysRequest implements GrpcMessage {
  static id = 'api.key.ListKeysRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ListKeysRequest();
    ListKeysRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ListKeysRequest) {
    _instance.header = _instance.header || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ListKeysRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api002.RequestHeader();
          _reader.readMessage(
            _instance.header,
            api002.RequestHeader.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    ListKeysRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ListKeysRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api002.RequestHeader.serializeBinaryToWriter
      );
    }
  }

  private _header?: api002.RequestHeader;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ListKeysRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<ListKeysRequest.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api002.RequestHeader(_value.header)
      : undefined;
    ListKeysRequest.refineValues(this);
  }
  get header(): api002.RequestHeader | undefined {
    return this._header;
  }
  set header(value: api002.RequestHeader | undefined) {
    this._header = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ListKeysRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ListKeysRequest.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ListKeysRequest.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
    };
  }
}
export module ListKeysRequest {
  /**
   * Standard JavaScript object representation for ListKeysRequest
   */
  export interface AsObject {
    header?: api002.RequestHeader.AsObject;
  }

  /**
   * Protobuf JSON representation for ListKeysRequest
   */
  export interface AsProtobufJSON {
    header: api002.RequestHeader.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for api.key.ListKeysResponse
 */
export class ListKeysResponse implements GrpcMessage {
  static id = 'api.key.ListKeysResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ListKeysResponse();
    ListKeysResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ListKeysResponse) {
    _instance.header = _instance.header || undefined;
    _instance.keyVersions = _instance.keyVersions || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ListKeysResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api002.ResponseHeader();
          _reader.readMessage(
            _instance.header,
            api002.ResponseHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          const messageInitializer2 = new KeyVersions();
          _reader.readMessage(
            messageInitializer2,
            KeyVersions.deserializeBinaryFromReader
          );
          (_instance.keyVersions = _instance.keyVersions || []).push(
            messageInitializer2
          );
          break;
        default:
          _reader.skipField();
      }
    }

    ListKeysResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ListKeysResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api002.ResponseHeader.serializeBinaryToWriter
      );
    }
    if (_instance.keyVersions && _instance.keyVersions.length) {
      _writer.writeRepeatedMessage(
        2,
        _instance.keyVersions as any,
        KeyVersions.serializeBinaryToWriter
      );
    }
  }

  private _header?: api002.ResponseHeader;
  private _keyVersions?: KeyVersions[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ListKeysResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<ListKeysResponse.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api002.ResponseHeader(_value.header)
      : undefined;
    this.keyVersions = (_value.keyVersions || []).map(
      (m) => new KeyVersions(m)
    );
    ListKeysResponse.refineValues(this);
  }
  get header(): api002.ResponseHeader | undefined {
    return this._header;
  }
  set header(value: api002.ResponseHeader | undefined) {
    this._header = value;
  }
  get keyVersions(): KeyVersions[] | undefined {
    return this._keyVersions;
  }
  set keyVersions(value: KeyVersions[] | undefined) {
    this._keyVersions = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ListKeysResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ListKeysResponse.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      keyVersions: (this.keyVersions || []).map((m) => m.toObject()),
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ListKeysResponse.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      keyVersions: (this.keyVersions || []).map((m) =>
        m.toProtobufJSON(options)
      ),
    };
  }
}
export module ListKeysResponse {
  /**
   * Standard JavaScript object representation for ListKeysResponse
   */
  export interface AsObject {
    header?: api002.ResponseHeader.AsObject;
    keyVersions?: KeyVersions.AsObject[];
  }

  /**
   * Protobuf JSON representation for ListKeysResponse
   */
  export interface AsProtobufJSON {
    header: api002.ResponseHeader.AsProtobufJSON | null;
    keyVersions: KeyVersions.AsProtobufJSON[] | null;
  }
}

/**
 * Message implementation for api.key.ListAllKeysRequest
 */
export class ListAllKeysRequest implements GrpcMessage {
  static id = 'api.key.ListAllKeysRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ListAllKeysRequest();
    ListAllKeysRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ListAllKeysRequest) {
    _instance.header = _instance.header || undefined;
    _instance.customerIds = _instance.customerIds || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ListAllKeysRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api002.RequestHeader();
          _reader.readMessage(
            _instance.header,
            api002.RequestHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          (_instance.customerIds = _instance.customerIds || []).push(
            _reader.readString()
          );
          break;
        default:
          _reader.skipField();
      }
    }

    ListAllKeysRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ListAllKeysRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api002.RequestHeader.serializeBinaryToWriter
      );
    }
    if (_instance.customerIds && _instance.customerIds.length) {
      _writer.writeRepeatedString(2, _instance.customerIds);
    }
  }

  private _header?: api002.RequestHeader;
  private _customerIds: string[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ListAllKeysRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<ListAllKeysRequest.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api002.RequestHeader(_value.header)
      : undefined;
    this.customerIds = (_value.customerIds || []).slice();
    ListAllKeysRequest.refineValues(this);
  }
  get header(): api002.RequestHeader | undefined {
    return this._header;
  }
  set header(value: api002.RequestHeader | undefined) {
    this._header = value;
  }
  get customerIds(): string[] {
    return this._customerIds;
  }
  set customerIds(value: string[]) {
    this._customerIds = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ListAllKeysRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ListAllKeysRequest.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      customerIds: (this.customerIds || []).slice(),
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ListAllKeysRequest.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      customerIds: (this.customerIds || []).slice(),
    };
  }
}
export module ListAllKeysRequest {
  /**
   * Standard JavaScript object representation for ListAllKeysRequest
   */
  export interface AsObject {
    header?: api002.RequestHeader.AsObject;
    customerIds: string[];
  }

  /**
   * Protobuf JSON representation for ListAllKeysRequest
   */
  export interface AsProtobufJSON {
    header: api002.RequestHeader.AsProtobufJSON | null;
    customerIds: string[];
  }
}

/**
 * Message implementation for api.key.ListAllKeysResponse
 */
export class ListAllKeysResponse implements GrpcMessage {
  static id = 'api.key.ListAllKeysResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ListAllKeysResponse();
    ListAllKeysResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ListAllKeysResponse) {
    _instance.header = _instance.header || undefined;
    _instance.keyVersions = _instance.keyVersions || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ListAllKeysResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api002.ResponseHeader();
          _reader.readMessage(
            _instance.header,
            api002.ResponseHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          const messageInitializer2 = new KeyVersions();
          _reader.readMessage(
            messageInitializer2,
            KeyVersions.deserializeBinaryFromReader
          );
          (_instance.keyVersions = _instance.keyVersions || []).push(
            messageInitializer2
          );
          break;
        default:
          _reader.skipField();
      }
    }

    ListAllKeysResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ListAllKeysResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api002.ResponseHeader.serializeBinaryToWriter
      );
    }
    if (_instance.keyVersions && _instance.keyVersions.length) {
      _writer.writeRepeatedMessage(
        2,
        _instance.keyVersions as any,
        KeyVersions.serializeBinaryToWriter
      );
    }
  }

  private _header?: api002.ResponseHeader;
  private _keyVersions?: KeyVersions[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ListAllKeysResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<ListAllKeysResponse.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api002.ResponseHeader(_value.header)
      : undefined;
    this.keyVersions = (_value.keyVersions || []).map(
      (m) => new KeyVersions(m)
    );
    ListAllKeysResponse.refineValues(this);
  }
  get header(): api002.ResponseHeader | undefined {
    return this._header;
  }
  set header(value: api002.ResponseHeader | undefined) {
    this._header = value;
  }
  get keyVersions(): KeyVersions[] | undefined {
    return this._keyVersions;
  }
  set keyVersions(value: KeyVersions[] | undefined) {
    this._keyVersions = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ListAllKeysResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ListAllKeysResponse.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      keyVersions: (this.keyVersions || []).map((m) => m.toObject()),
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ListAllKeysResponse.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      keyVersions: (this.keyVersions || []).map((m) =>
        m.toProtobufJSON(options)
      ),
    };
  }
}
export module ListAllKeysResponse {
  /**
   * Standard JavaScript object representation for ListAllKeysResponse
   */
  export interface AsObject {
    header?: api002.ResponseHeader.AsObject;
    keyVersions?: KeyVersions.AsObject[];
  }

  /**
   * Protobuf JSON representation for ListAllKeysResponse
   */
  export interface AsProtobufJSON {
    header: api002.ResponseHeader.AsProtobufJSON | null;
    keyVersions: KeyVersions.AsProtobufJSON[] | null;
  }
}

/**
 * Message implementation for api.key.ListKeyNamesRequest
 */
export class ListKeyNamesRequest implements GrpcMessage {
  static id = 'api.key.ListKeyNamesRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ListKeyNamesRequest();
    ListKeyNamesRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ListKeyNamesRequest) {
    _instance.header = _instance.header || undefined;
    _instance.customerIds = _instance.customerIds || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ListKeyNamesRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api002.RequestHeader();
          _reader.readMessage(
            _instance.header,
            api002.RequestHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          (_instance.customerIds = _instance.customerIds || []).push(
            _reader.readString()
          );
          break;
        default:
          _reader.skipField();
      }
    }

    ListKeyNamesRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ListKeyNamesRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api002.RequestHeader.serializeBinaryToWriter
      );
    }
    if (_instance.customerIds && _instance.customerIds.length) {
      _writer.writeRepeatedString(2, _instance.customerIds);
    }
  }

  private _header?: api002.RequestHeader;
  private _customerIds: string[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ListKeyNamesRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<ListKeyNamesRequest.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api002.RequestHeader(_value.header)
      : undefined;
    this.customerIds = (_value.customerIds || []).slice();
    ListKeyNamesRequest.refineValues(this);
  }
  get header(): api002.RequestHeader | undefined {
    return this._header;
  }
  set header(value: api002.RequestHeader | undefined) {
    this._header = value;
  }
  get customerIds(): string[] {
    return this._customerIds;
  }
  set customerIds(value: string[]) {
    this._customerIds = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ListKeyNamesRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ListKeyNamesRequest.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      customerIds: (this.customerIds || []).slice(),
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ListKeyNamesRequest.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      customerIds: (this.customerIds || []).slice(),
    };
  }
}
export module ListKeyNamesRequest {
  /**
   * Standard JavaScript object representation for ListKeyNamesRequest
   */
  export interface AsObject {
    header?: api002.RequestHeader.AsObject;
    customerIds: string[];
  }

  /**
   * Protobuf JSON representation for ListKeyNamesRequest
   */
  export interface AsProtobufJSON {
    header: api002.RequestHeader.AsProtobufJSON | null;
    customerIds: string[];
  }
}

/**
 * Message implementation for api.key.ListKeyNamesResponse
 */
export class ListKeyNamesResponse implements GrpcMessage {
  static id = 'api.key.ListKeyNamesResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ListKeyNamesResponse();
    ListKeyNamesResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ListKeyNamesResponse) {
    _instance.header = _instance.header || undefined;
    _instance.keyNameVersions = _instance.keyNameVersions || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ListKeyNamesResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api002.ResponseHeader();
          _reader.readMessage(
            _instance.header,
            api002.ResponseHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          const messageInitializer2 = new KeyNameVersion();
          _reader.readMessage(
            messageInitializer2,
            KeyNameVersion.deserializeBinaryFromReader
          );
          (_instance.keyNameVersions = _instance.keyNameVersions || []).push(
            messageInitializer2
          );
          break;
        default:
          _reader.skipField();
      }
    }

    ListKeyNamesResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ListKeyNamesResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api002.ResponseHeader.serializeBinaryToWriter
      );
    }
    if (_instance.keyNameVersions && _instance.keyNameVersions.length) {
      _writer.writeRepeatedMessage(
        2,
        _instance.keyNameVersions as any,
        KeyNameVersion.serializeBinaryToWriter
      );
    }
  }

  private _header?: api002.ResponseHeader;
  private _keyNameVersions?: KeyNameVersion[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ListKeyNamesResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<ListKeyNamesResponse.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api002.ResponseHeader(_value.header)
      : undefined;
    this.keyNameVersions = (_value.keyNameVersions || []).map(
      (m) => new KeyNameVersion(m)
    );
    ListKeyNamesResponse.refineValues(this);
  }
  get header(): api002.ResponseHeader | undefined {
    return this._header;
  }
  set header(value: api002.ResponseHeader | undefined) {
    this._header = value;
  }
  get keyNameVersions(): KeyNameVersion[] | undefined {
    return this._keyNameVersions;
  }
  set keyNameVersions(value: KeyNameVersion[] | undefined) {
    this._keyNameVersions = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ListKeyNamesResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ListKeyNamesResponse.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      keyNameVersions: (this.keyNameVersions || []).map((m) => m.toObject()),
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ListKeyNamesResponse.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      keyNameVersions: (this.keyNameVersions || []).map((m) =>
        m.toProtobufJSON(options)
      ),
    };
  }
}
export module ListKeyNamesResponse {
  /**
   * Standard JavaScript object representation for ListKeyNamesResponse
   */
  export interface AsObject {
    header?: api002.ResponseHeader.AsObject;
    keyNameVersions?: KeyNameVersion.AsObject[];
  }

  /**
   * Protobuf JSON representation for ListKeyNamesResponse
   */
  export interface AsProtobufJSON {
    header: api002.ResponseHeader.AsProtobufJSON | null;
    keyNameVersions: KeyNameVersion.AsProtobufJSON[] | null;
  }
}

/**
 * Message implementation for api.key.KeyVersions
 */
export class KeyVersions implements GrpcMessage {
  static id = 'api.key.KeyVersions';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new KeyVersions();
    KeyVersions.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: KeyVersions) {
    _instance.config = _instance.config || undefined;
    _instance.versions = _instance.versions || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: KeyVersions,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.config = new storage003.KeyConfig();
          _reader.readMessage(
            _instance.config,
            storage003.KeyConfig.deserializeBinaryFromReader
          );
          break;
        case 2:
          const messageInitializer2 = new KeyVersion();
          _reader.readMessage(
            messageInitializer2,
            KeyVersion.deserializeBinaryFromReader
          );
          (_instance.versions = _instance.versions || []).push(
            messageInitializer2
          );
          break;
        case 3:
          _instance.releasePolicy = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    KeyVersions.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: KeyVersions,
    _writer: BinaryWriter
  ) {
    if (_instance.config) {
      _writer.writeMessage(
        1,
        _instance.config as any,
        storage003.KeyConfig.serializeBinaryToWriter
      );
    }
    if (_instance.versions && _instance.versions.length) {
      _writer.writeRepeatedMessage(
        2,
        _instance.versions as any,
        KeyVersion.serializeBinaryToWriter
      );
    }
    if (
      _instance.releasePolicy !== undefined &&
      _instance.releasePolicy !== null
    ) {
      _writer.writeString(3, _instance.releasePolicy);
    }
  }

  private _config?: storage003.KeyConfig;
  private _versions?: KeyVersion[];
  private _releasePolicy?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of KeyVersions to deeply clone from
   */
  constructor(_value?: RecursivePartial<KeyVersions.AsObject>) {
    _value = _value || {};
    this.config = _value.config
      ? new storage003.KeyConfig(_value.config)
      : undefined;
    this.versions = (_value.versions || []).map((m) => new KeyVersion(m));
    this.releasePolicy = _value.releasePolicy;
    KeyVersions.refineValues(this);
  }
  get config(): storage003.KeyConfig | undefined {
    return this._config;
  }
  set config(value: storage003.KeyConfig | undefined) {
    this._config = value;
  }
  get versions(): KeyVersion[] | undefined {
    return this._versions;
  }
  set versions(value: KeyVersion[] | undefined) {
    this._versions = value;
  }
  get releasePolicy(): string | undefined {
    return this._releasePolicy;
  }
  set releasePolicy(value?: string) {
    this._releasePolicy = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    KeyVersions.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): KeyVersions.AsObject {
    return {
      config: this.config ? this.config.toObject() : undefined,
      versions: (this.versions || []).map((m) => m.toObject()),
      releasePolicy: this.releasePolicy,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): KeyVersions.AsProtobufJSON {
    return {
      config: this.config ? this.config.toProtobufJSON(options) : null,
      versions: (this.versions || []).map((m) => m.toProtobufJSON(options)),
      releasePolicy:
        this.releasePolicy === null || this.releasePolicy === undefined
          ? null
          : this.releasePolicy,
    };
  }
}
export module KeyVersions {
  /**
   * Standard JavaScript object representation for KeyVersions
   */
  export interface AsObject {
    config?: storage003.KeyConfig.AsObject;
    versions?: KeyVersion.AsObject[];
    releasePolicy?: string;
  }

  /**
   * Protobuf JSON representation for KeyVersions
   */
  export interface AsProtobufJSON {
    config: storage003.KeyConfig.AsProtobufJSON | null;
    versions: KeyVersion.AsProtobufJSON[] | null;
    releasePolicy: string | null;
  }
}

/**
 * Message implementation for api.key.KeyVersion
 */
export class KeyVersion implements GrpcMessage {
  static id = 'api.key.KeyVersion';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new KeyVersion();
    KeyVersion.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: KeyVersion) {
    _instance.version = _instance.version || '';
    _instance.expiresTime = _instance.expiresTime || undefined;
    _instance.createdTime = _instance.createdTime || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: KeyVersion,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.version = _reader.readString();
          break;
        case 2:
          _instance.expiresTime = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.expiresTime,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 3:
          _instance.createdTime = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.createdTime,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    KeyVersion.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(_instance: KeyVersion, _writer: BinaryWriter) {
    if (_instance.version) {
      _writer.writeString(1, _instance.version);
    }
    if (_instance.expiresTime) {
      _writer.writeMessage(
        2,
        _instance.expiresTime as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.createdTime) {
      _writer.writeMessage(
        3,
        _instance.createdTime as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
  }

  private _version: string;
  private _expiresTime?: googleProtobuf000.Timestamp;
  private _createdTime?: googleProtobuf000.Timestamp;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of KeyVersion to deeply clone from
   */
  constructor(_value?: RecursivePartial<KeyVersion.AsObject>) {
    _value = _value || {};
    this.version = _value.version;
    this.expiresTime = _value.expiresTime
      ? new googleProtobuf000.Timestamp(_value.expiresTime)
      : undefined;
    this.createdTime = _value.createdTime
      ? new googleProtobuf000.Timestamp(_value.createdTime)
      : undefined;
    KeyVersion.refineValues(this);
  }
  get version(): string {
    return this._version;
  }
  set version(value: string) {
    this._version = value;
  }
  get expiresTime(): googleProtobuf000.Timestamp | undefined {
    return this._expiresTime;
  }
  set expiresTime(value: googleProtobuf000.Timestamp | undefined) {
    this._expiresTime = value;
  }
  get createdTime(): googleProtobuf000.Timestamp | undefined {
    return this._createdTime;
  }
  set createdTime(value: googleProtobuf000.Timestamp | undefined) {
    this._createdTime = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    KeyVersion.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): KeyVersion.AsObject {
    return {
      version: this.version,
      expiresTime: this.expiresTime ? this.expiresTime.toObject() : undefined,
      createdTime: this.createdTime ? this.createdTime.toObject() : undefined,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): KeyVersion.AsProtobufJSON {
    return {
      version: this.version,
      expiresTime: this.expiresTime
        ? this.expiresTime.toProtobufJSON(options)
        : null,
      createdTime: this.createdTime
        ? this.createdTime.toProtobufJSON(options)
        : null,
    };
  }
}
export module KeyVersion {
  /**
   * Standard JavaScript object representation for KeyVersion
   */
  export interface AsObject {
    version: string;
    expiresTime?: googleProtobuf000.Timestamp.AsObject;
    createdTime?: googleProtobuf000.Timestamp.AsObject;
  }

  /**
   * Protobuf JSON representation for KeyVersion
   */
  export interface AsProtobufJSON {
    version: string;
    expiresTime: googleProtobuf000.Timestamp.AsProtobufJSON | null;
    createdTime: googleProtobuf000.Timestamp.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for api.key.KeyNameVersion
 */
export class KeyNameVersion implements GrpcMessage {
  static id = 'api.key.KeyNameVersion';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new KeyNameVersion();
    KeyNameVersion.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: KeyNameVersion) {
    _instance.keyId = _instance.keyId || '';
    _instance.customerId = _instance.customerId || '';
    _instance.keyName = _instance.keyName || '';
    _instance.version = _instance.version || '';
    _instance.expiresTime = _instance.expiresTime || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: KeyNameVersion,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.keyId = _reader.readString();
          break;
        case 2:
          _instance.customerId = _reader.readString();
          break;
        case 3:
          _instance.keyName = _reader.readString();
          break;
        case 4:
          _instance.version = _reader.readString();
          break;
        case 5:
          _instance.expiresTime = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.expiresTime,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    KeyNameVersion.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: KeyNameVersion,
    _writer: BinaryWriter
  ) {
    if (_instance.keyId) {
      _writer.writeString(1, _instance.keyId);
    }
    if (_instance.customerId) {
      _writer.writeString(2, _instance.customerId);
    }
    if (_instance.keyName) {
      _writer.writeString(3, _instance.keyName);
    }
    if (_instance.version) {
      _writer.writeString(4, _instance.version);
    }
    if (_instance.expiresTime) {
      _writer.writeMessage(
        5,
        _instance.expiresTime as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
  }

  private _keyId: string;
  private _customerId: string;
  private _keyName: string;
  private _version: string;
  private _expiresTime?: googleProtobuf000.Timestamp;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of KeyNameVersion to deeply clone from
   */
  constructor(_value?: RecursivePartial<KeyNameVersion.AsObject>) {
    _value = _value || {};
    this.keyId = _value.keyId;
    this.customerId = _value.customerId;
    this.keyName = _value.keyName;
    this.version = _value.version;
    this.expiresTime = _value.expiresTime
      ? new googleProtobuf000.Timestamp(_value.expiresTime)
      : undefined;
    KeyNameVersion.refineValues(this);
  }
  get keyId(): string {
    return this._keyId;
  }
  set keyId(value: string) {
    this._keyId = value;
  }
  get customerId(): string {
    return this._customerId;
  }
  set customerId(value: string) {
    this._customerId = value;
  }
  get keyName(): string {
    return this._keyName;
  }
  set keyName(value: string) {
    this._keyName = value;
  }
  get version(): string {
    return this._version;
  }
  set version(value: string) {
    this._version = value;
  }
  get expiresTime(): googleProtobuf000.Timestamp | undefined {
    return this._expiresTime;
  }
  set expiresTime(value: googleProtobuf000.Timestamp | undefined) {
    this._expiresTime = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    KeyNameVersion.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): KeyNameVersion.AsObject {
    return {
      keyId: this.keyId,
      customerId: this.customerId,
      keyName: this.keyName,
      version: this.version,
      expiresTime: this.expiresTime ? this.expiresTime.toObject() : undefined,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): KeyNameVersion.AsProtobufJSON {
    return {
      keyId: this.keyId,
      customerId: this.customerId,
      keyName: this.keyName,
      version: this.version,
      expiresTime: this.expiresTime
        ? this.expiresTime.toProtobufJSON(options)
        : null,
    };
  }
}
export module KeyNameVersion {
  /**
   * Standard JavaScript object representation for KeyNameVersion
   */
  export interface AsObject {
    keyId: string;
    customerId: string;
    keyName: string;
    version: string;
    expiresTime?: googleProtobuf000.Timestamp.AsObject;
  }

  /**
   * Protobuf JSON representation for KeyNameVersion
   */
  export interface AsProtobufJSON {
    keyId: string;
    customerId: string;
    keyName: string;
    version: string;
    expiresTime: googleProtobuf000.Timestamp.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for api.key.GetKeyRequest
 */
export class GetKeyRequest implements GrpcMessage {
  static id = 'api.key.GetKeyRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetKeyRequest();
    GetKeyRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetKeyRequest) {
    _instance.header = _instance.header || undefined;
    _instance.keyId = _instance.keyId || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetKeyRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api002.RequestHeader();
          _reader.readMessage(
            _instance.header,
            api002.RequestHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.keyId = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    GetKeyRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetKeyRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api002.RequestHeader.serializeBinaryToWriter
      );
    }
    if (_instance.keyId) {
      _writer.writeString(2, _instance.keyId);
    }
  }

  private _header?: api002.RequestHeader;
  private _keyId: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetKeyRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetKeyRequest.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api002.RequestHeader(_value.header)
      : undefined;
    this.keyId = _value.keyId;
    GetKeyRequest.refineValues(this);
  }
  get header(): api002.RequestHeader | undefined {
    return this._header;
  }
  set header(value: api002.RequestHeader | undefined) {
    this._header = value;
  }
  get keyId(): string {
    return this._keyId;
  }
  set keyId(value: string) {
    this._keyId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetKeyRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetKeyRequest.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      keyId: this.keyId,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetKeyRequest.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      keyId: this.keyId,
    };
  }
}
export module GetKeyRequest {
  /**
   * Standard JavaScript object representation for GetKeyRequest
   */
  export interface AsObject {
    header?: api002.RequestHeader.AsObject;
    keyId: string;
  }

  /**
   * Protobuf JSON representation for GetKeyRequest
   */
  export interface AsProtobufJSON {
    header: api002.RequestHeader.AsProtobufJSON | null;
    keyId: string;
  }
}

/**
 * Message implementation for api.key.GetKeyResponse
 */
export class GetKeyResponse implements GrpcMessage {
  static id = 'api.key.GetKeyResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetKeyResponse();
    GetKeyResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetKeyResponse) {
    _instance.header = _instance.header || undefined;
    _instance.keyConfig = _instance.keyConfig || undefined;
    _instance.etag = _instance.etag || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetKeyResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api002.ResponseHeader();
          _reader.readMessage(
            _instance.header,
            api002.ResponseHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.keyConfig = new storage003.KeyConfig();
          _reader.readMessage(
            _instance.keyConfig,
            storage003.KeyConfig.deserializeBinaryFromReader
          );
          break;
        case 3:
          _instance.etag = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    GetKeyResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetKeyResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api002.ResponseHeader.serializeBinaryToWriter
      );
    }
    if (_instance.keyConfig) {
      _writer.writeMessage(
        2,
        _instance.keyConfig as any,
        storage003.KeyConfig.serializeBinaryToWriter
      );
    }
    if (_instance.etag) {
      _writer.writeString(3, _instance.etag);
    }
  }

  private _header?: api002.ResponseHeader;
  private _keyConfig?: storage003.KeyConfig;
  private _etag: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetKeyResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetKeyResponse.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api002.ResponseHeader(_value.header)
      : undefined;
    this.keyConfig = _value.keyConfig
      ? new storage003.KeyConfig(_value.keyConfig)
      : undefined;
    this.etag = _value.etag;
    GetKeyResponse.refineValues(this);
  }
  get header(): api002.ResponseHeader | undefined {
    return this._header;
  }
  set header(value: api002.ResponseHeader | undefined) {
    this._header = value;
  }
  get keyConfig(): storage003.KeyConfig | undefined {
    return this._keyConfig;
  }
  set keyConfig(value: storage003.KeyConfig | undefined) {
    this._keyConfig = value;
  }
  get etag(): string {
    return this._etag;
  }
  set etag(value: string) {
    this._etag = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetKeyResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetKeyResponse.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      keyConfig: this.keyConfig ? this.keyConfig.toObject() : undefined,
      etag: this.etag,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetKeyResponse.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      keyConfig: this.keyConfig ? this.keyConfig.toProtobufJSON(options) : null,
      etag: this.etag,
    };
  }
}
export module GetKeyResponse {
  /**
   * Standard JavaScript object representation for GetKeyResponse
   */
  export interface AsObject {
    header?: api002.ResponseHeader.AsObject;
    keyConfig?: storage003.KeyConfig.AsObject;
    etag: string;
  }

  /**
   * Protobuf JSON representation for GetKeyResponse
   */
  export interface AsProtobufJSON {
    header: api002.ResponseHeader.AsProtobufJSON | null;
    keyConfig: storage003.KeyConfig.AsProtobufJSON | null;
    etag: string;
  }
}

/**
 * Message implementation for api.key.UpdateKeyRequest
 */
export class UpdateKeyRequest implements GrpcMessage {
  static id = 'api.key.UpdateKeyRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new UpdateKeyRequest();
    UpdateKeyRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: UpdateKeyRequest) {
    _instance.header = _instance.header || undefined;
    _instance.keyConfig = _instance.keyConfig || undefined;
    _instance.etag = _instance.etag || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: UpdateKeyRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api002.RequestHeader();
          _reader.readMessage(
            _instance.header,
            api002.RequestHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.keyConfig = new storage003.KeyConfig();
          _reader.readMessage(
            _instance.keyConfig,
            storage003.KeyConfig.deserializeBinaryFromReader
          );
          break;
        case 3:
          _instance.etag = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    UpdateKeyRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: UpdateKeyRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api002.RequestHeader.serializeBinaryToWriter
      );
    }
    if (_instance.keyConfig) {
      _writer.writeMessage(
        2,
        _instance.keyConfig as any,
        storage003.KeyConfig.serializeBinaryToWriter
      );
    }
    if (_instance.etag) {
      _writer.writeString(3, _instance.etag);
    }
  }

  private _header?: api002.RequestHeader;
  private _keyConfig?: storage003.KeyConfig;
  private _etag: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of UpdateKeyRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<UpdateKeyRequest.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api002.RequestHeader(_value.header)
      : undefined;
    this.keyConfig = _value.keyConfig
      ? new storage003.KeyConfig(_value.keyConfig)
      : undefined;
    this.etag = _value.etag;
    UpdateKeyRequest.refineValues(this);
  }
  get header(): api002.RequestHeader | undefined {
    return this._header;
  }
  set header(value: api002.RequestHeader | undefined) {
    this._header = value;
  }
  get keyConfig(): storage003.KeyConfig | undefined {
    return this._keyConfig;
  }
  set keyConfig(value: storage003.KeyConfig | undefined) {
    this._keyConfig = value;
  }
  get etag(): string {
    return this._etag;
  }
  set etag(value: string) {
    this._etag = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    UpdateKeyRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): UpdateKeyRequest.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      keyConfig: this.keyConfig ? this.keyConfig.toObject() : undefined,
      etag: this.etag,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): UpdateKeyRequest.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      keyConfig: this.keyConfig ? this.keyConfig.toProtobufJSON(options) : null,
      etag: this.etag,
    };
  }
}
export module UpdateKeyRequest {
  /**
   * Standard JavaScript object representation for UpdateKeyRequest
   */
  export interface AsObject {
    header?: api002.RequestHeader.AsObject;
    keyConfig?: storage003.KeyConfig.AsObject;
    etag: string;
  }

  /**
   * Protobuf JSON representation for UpdateKeyRequest
   */
  export interface AsProtobufJSON {
    header: api002.RequestHeader.AsProtobufJSON | null;
    keyConfig: storage003.KeyConfig.AsProtobufJSON | null;
    etag: string;
  }
}

/**
 * Message implementation for api.key.UpdateKeyResponse
 */
export class UpdateKeyResponse implements GrpcMessage {
  static id = 'api.key.UpdateKeyResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new UpdateKeyResponse();
    UpdateKeyResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: UpdateKeyResponse) {
    _instance.header = _instance.header || undefined;
    _instance.etag = _instance.etag || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: UpdateKeyResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api002.ResponseHeader();
          _reader.readMessage(
            _instance.header,
            api002.ResponseHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.etag = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    UpdateKeyResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: UpdateKeyResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api002.ResponseHeader.serializeBinaryToWriter
      );
    }
    if (_instance.etag) {
      _writer.writeString(2, _instance.etag);
    }
  }

  private _header?: api002.ResponseHeader;
  private _etag: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of UpdateKeyResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<UpdateKeyResponse.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api002.ResponseHeader(_value.header)
      : undefined;
    this.etag = _value.etag;
    UpdateKeyResponse.refineValues(this);
  }
  get header(): api002.ResponseHeader | undefined {
    return this._header;
  }
  set header(value: api002.ResponseHeader | undefined) {
    this._header = value;
  }
  get etag(): string {
    return this._etag;
  }
  set etag(value: string) {
    this._etag = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    UpdateKeyResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): UpdateKeyResponse.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      etag: this.etag,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): UpdateKeyResponse.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      etag: this.etag,
    };
  }
}
export module UpdateKeyResponse {
  /**
   * Standard JavaScript object representation for UpdateKeyResponse
   */
  export interface AsObject {
    header?: api002.ResponseHeader.AsObject;
    etag: string;
  }

  /**
   * Protobuf JSON representation for UpdateKeyResponse
   */
  export interface AsProtobufJSON {
    header: api002.ResponseHeader.AsProtobufJSON | null;
    etag: string;
  }
}

/**
 * Message implementation for api.key.RotateKeyRequest
 */
export class RotateKeyRequest implements GrpcMessage {
  static id = 'api.key.RotateKeyRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new RotateKeyRequest();
    RotateKeyRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: RotateKeyRequest) {
    _instance.header = _instance.header || undefined;
    _instance.customerId = _instance.customerId || '';
    _instance.keyName = _instance.keyName || '';
    _instance.keyId = _instance.keyId || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: RotateKeyRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api002.RequestHeader();
          _reader.readMessage(
            _instance.header,
            api002.RequestHeader.deserializeBinaryFromReader
          );
          break;
        case 3:
          _instance.customerId = _reader.readString();
          break;
        case 2:
          _instance.keyName = _reader.readString();
          break;
        case 4:
          _instance.keyId = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    RotateKeyRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: RotateKeyRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api002.RequestHeader.serializeBinaryToWriter
      );
    }
    if (_instance.customerId) {
      _writer.writeString(3, _instance.customerId);
    }
    if (_instance.keyName) {
      _writer.writeString(2, _instance.keyName);
    }
    if (_instance.keyId) {
      _writer.writeString(4, _instance.keyId);
    }
  }

  private _header?: api002.RequestHeader;
  private _customerId: string;
  private _keyName: string;
  private _keyId: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of RotateKeyRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<RotateKeyRequest.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api002.RequestHeader(_value.header)
      : undefined;
    this.customerId = _value.customerId;
    this.keyName = _value.keyName;
    this.keyId = _value.keyId;
    RotateKeyRequest.refineValues(this);
  }
  get header(): api002.RequestHeader | undefined {
    return this._header;
  }
  set header(value: api002.RequestHeader | undefined) {
    this._header = value;
  }
  get customerId(): string {
    return this._customerId;
  }
  set customerId(value: string) {
    this._customerId = value;
  }
  get keyName(): string {
    return this._keyName;
  }
  set keyName(value: string) {
    this._keyName = value;
  }
  get keyId(): string {
    return this._keyId;
  }
  set keyId(value: string) {
    this._keyId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    RotateKeyRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): RotateKeyRequest.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      customerId: this.customerId,
      keyName: this.keyName,
      keyId: this.keyId,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): RotateKeyRequest.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      customerId: this.customerId,
      keyName: this.keyName,
      keyId: this.keyId,
    };
  }
}
export module RotateKeyRequest {
  /**
   * Standard JavaScript object representation for RotateKeyRequest
   */
  export interface AsObject {
    header?: api002.RequestHeader.AsObject;
    customerId: string;
    keyName: string;
    keyId: string;
  }

  /**
   * Protobuf JSON representation for RotateKeyRequest
   */
  export interface AsProtobufJSON {
    header: api002.RequestHeader.AsProtobufJSON | null;
    customerId: string;
    keyName: string;
    keyId: string;
  }
}

/**
 * Message implementation for api.key.RotateKeyResponse
 */
export class RotateKeyResponse implements GrpcMessage {
  static id = 'api.key.RotateKeyResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new RotateKeyResponse();
    RotateKeyResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: RotateKeyResponse) {
    _instance.header = _instance.header || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: RotateKeyResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api002.ResponseHeader();
          _reader.readMessage(
            _instance.header,
            api002.ResponseHeader.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    RotateKeyResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: RotateKeyResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api002.ResponseHeader.serializeBinaryToWriter
      );
    }
  }

  private _header?: api002.ResponseHeader;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of RotateKeyResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<RotateKeyResponse.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api002.ResponseHeader(_value.header)
      : undefined;
    RotateKeyResponse.refineValues(this);
  }
  get header(): api002.ResponseHeader | undefined {
    return this._header;
  }
  set header(value: api002.ResponseHeader | undefined) {
    this._header = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    RotateKeyResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): RotateKeyResponse.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): RotateKeyResponse.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
    };
  }
}
export module RotateKeyResponse {
  /**
   * Standard JavaScript object representation for RotateKeyResponse
   */
  export interface AsObject {
    header?: api002.ResponseHeader.AsObject;
  }

  /**
   * Protobuf JSON representation for RotateKeyResponse
   */
  export interface AsProtobufJSON {
    header: api002.ResponseHeader.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for api.key.DeleteExpiredRequest
 */
export class DeleteExpiredRequest implements GrpcMessage {
  static id = 'api.key.DeleteExpiredRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new DeleteExpiredRequest();
    DeleteExpiredRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: DeleteExpiredRequest) {
    _instance.header = _instance.header || undefined;
    _instance.keyId = _instance.keyId || '';
    _instance.etag = _instance.etag || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: DeleteExpiredRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api002.RequestHeader();
          _reader.readMessage(
            _instance.header,
            api002.RequestHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.keyId = _reader.readString();
          break;
        case 3:
          _instance.etag = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    DeleteExpiredRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: DeleteExpiredRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api002.RequestHeader.serializeBinaryToWriter
      );
    }
    if (_instance.keyId) {
      _writer.writeString(2, _instance.keyId);
    }
    if (_instance.etag) {
      _writer.writeString(3, _instance.etag);
    }
  }

  private _header?: api002.RequestHeader;
  private _keyId: string;
  private _etag: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of DeleteExpiredRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<DeleteExpiredRequest.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api002.RequestHeader(_value.header)
      : undefined;
    this.keyId = _value.keyId;
    this.etag = _value.etag;
    DeleteExpiredRequest.refineValues(this);
  }
  get header(): api002.RequestHeader | undefined {
    return this._header;
  }
  set header(value: api002.RequestHeader | undefined) {
    this._header = value;
  }
  get keyId(): string {
    return this._keyId;
  }
  set keyId(value: string) {
    this._keyId = value;
  }
  get etag(): string {
    return this._etag;
  }
  set etag(value: string) {
    this._etag = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    DeleteExpiredRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): DeleteExpiredRequest.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      keyId: this.keyId,
      etag: this.etag,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): DeleteExpiredRequest.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      keyId: this.keyId,
      etag: this.etag,
    };
  }
}
export module DeleteExpiredRequest {
  /**
   * Standard JavaScript object representation for DeleteExpiredRequest
   */
  export interface AsObject {
    header?: api002.RequestHeader.AsObject;
    keyId: string;
    etag: string;
  }

  /**
   * Protobuf JSON representation for DeleteExpiredRequest
   */
  export interface AsProtobufJSON {
    header: api002.RequestHeader.AsProtobufJSON | null;
    keyId: string;
    etag: string;
  }
}

/**
 * Message implementation for api.key.DeleteExpiredResponse
 */
export class DeleteExpiredResponse implements GrpcMessage {
  static id = 'api.key.DeleteExpiredResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new DeleteExpiredResponse();
    DeleteExpiredResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: DeleteExpiredResponse) {
    _instance.header = _instance.header || undefined;
    _instance.etag = _instance.etag || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: DeleteExpiredResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api002.ResponseHeader();
          _reader.readMessage(
            _instance.header,
            api002.ResponseHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.etag = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    DeleteExpiredResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: DeleteExpiredResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api002.ResponseHeader.serializeBinaryToWriter
      );
    }
    if (_instance.etag) {
      _writer.writeString(2, _instance.etag);
    }
  }

  private _header?: api002.ResponseHeader;
  private _etag: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of DeleteExpiredResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<DeleteExpiredResponse.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api002.ResponseHeader(_value.header)
      : undefined;
    this.etag = _value.etag;
    DeleteExpiredResponse.refineValues(this);
  }
  get header(): api002.ResponseHeader | undefined {
    return this._header;
  }
  set header(value: api002.ResponseHeader | undefined) {
    this._header = value;
  }
  get etag(): string {
    return this._etag;
  }
  set etag(value: string) {
    this._etag = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    DeleteExpiredResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): DeleteExpiredResponse.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      etag: this.etag,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): DeleteExpiredResponse.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      etag: this.etag,
    };
  }
}
export module DeleteExpiredResponse {
  /**
   * Standard JavaScript object representation for DeleteExpiredResponse
   */
  export interface AsObject {
    header?: api002.ResponseHeader.AsObject;
    etag: string;
  }

  /**
   * Protobuf JSON representation for DeleteExpiredResponse
   */
  export interface AsProtobufJSON {
    header: api002.ResponseHeader.AsProtobufJSON | null;
    etag: string;
  }
}
