<div>
  <!-- Error display -->
  <app-message-box></app-message-box>

  <!-- Instructions -->
  <section class="instructions">
    <span
      >Register/unregister keys here, download public PEM using 'attachment'
      button.</span
    >
  </section>
  <!-- Register key -->
  <section>
    <button
      mat-icon-button
      *ngIf="hasManagerRole"
      color="primary"
      aria-label="Register key"
      matTooltip="Register new key"
      (click)="registerKey()"
    >
      <mat-icon>library_add</mat-icon>
    </button>
  </section>

  <!-- List of keys -->
  <cdk-accordion>
    <cdk-accordion-item
      *ngFor="let key of keys; let index = index"
      #accordionItem="cdkAccordionItem"
      class="accordion-item"
      role="button"
      tabindex="0"
      [attr.id]="key.config!.id"
      [attr.aria-expanded]="accordionItem.expanded"
      [attr.aria-controls]="'accordion-body-' + index"
    >
      <!-- Header -->
      <div class="accordion-item-header" (click)="accordionItem.toggle()">
        <div>
          <!-- Key Name -->
          <span class="accordion-item-header-item">{{
            key.config!.keyName
          }}</span>

          <!-- Customer id -->
          <span
            *ngIf="userHasAccessToAllKeys"
            class="accordion-item-header-item"
            >{{ key.config!.customerId }}</span
          >

          <!-- Last update date -->
          <span class="accordion-item-header-item">{{
            formatTime(key.config!.lastUpdate, '')
          }}</span>
        </div>

        <span class="accordion-item-description">
          Click to {{ accordionItem.expanded ? 'close' : 'open' }}
        </span>
      </div>

      <!-- Expanded -->
      <div
        class="accordion-item-body"
        role="region"
        [style.display]="accordionItem.expanded ? '' : 'none'"
        [attr.id]="key.config!.id"
        [attr.aria-labelledby]="'accordion-header-' + index"
      >
        <mat-card>
          <mat-card-header>
            <mat-card-title>{{ key.config!.keyName }}</mat-card-title>

            <!-- HSM url -->
            <mat-card-subtitle *ngIf="key.config?.hsm?.hsmUrl">
              <div class="row-center">
                <span>{{ 'HSM: ' + key.config!.hsm!.hsmUrl }}</span>
                <!-- Copy to clipboard -->
                <button
                  mat-icon-button
                  matTooltip="Copy HSM URL"
                  [cdkCopyToClipboard]="key.config!.hsm!.hsmUrl"
                >
                  <mat-icon>content_copy</mat-icon>
                </button>
              </div>
            </mat-card-subtitle>

            <!-- API url -->
            <mat-card-subtitle *ngIf="key.config?.api?.apiUrl"
              ><div class="row-center">
                <span>{{ 'API: ' + key.config!.api!.apiUrl }}</span>
                <!-- Copy to clipboard -->
                <button
                  mat-icon-button
                  matTooltip="Copy API URL"
                  [cdkCopyToClipboard]="key.config!.api!.apiUrl"
                >
                  <mat-icon>content_copy</mat-icon>
                </button>
              </div></mat-card-subtitle
            >
          </mat-card-header>
          <!-- TODO: add API parameters -->
          <mat-card-content>
            <table>
              <tr>
                <td>
                  <div class="key-props">
                    <!-- Id -->
                    <div class="row-center">
                      <b>Key Id:</b>
                      <span class="key-id">{{ key.config!.id }}</span>
                      <!-- Copy to clipboard -->
                      <button
                        mat-icon-button
                        matTooltip="Copy key id"
                        [cdkCopyToClipboard]="key.config!.id"
                      >
                        <mat-icon>content_copy</mat-icon>
                      </button>
                    </div>

                    <!-- Expiration period -->
                    <div>
                      <mat-form-field class="expiry-field">
                        <mat-label>Expiration</mat-label>
                        <input
                          matInput
                          [readonly]="!hasManagerRole"
                          [(ngModel)]="key.config!.expiryTime"
                        />
                      </mat-form-field>
                      <span class="description"
                        >When it expires, example: P365D</span
                      >
                    </div>

                    <!-- Before expiration time period -->
                    <div>
                      <mat-form-field class="expiry-field">
                        <mat-label>Before Expiration</mat-label>
                        <input
                          matInput
                          [readonly]="!hasManagerRole"
                          [(ngModel)]="key.config!.beforeExpiryTime"
                        />
                      </mat-form-field>
                      <span class="description"
                        >When to rotate, example: P30D</span
                      >
                    </div>
                  </div>
                </td>
                <td>
                  <!-- Release Policy -->
                  <div>
                    <span class="release-policy-title" *ngIf="key.releasePolicy"
                      ><u>Key Release Policy</u></span
                    >
                    <a
                      href="https://learn.microsoft.com/en-us/azure/key-vault/managed-hsm/policy-grammar"
                      ><mat-icon>help_outline</mat-icon></a
                    >
                  </div>
                  <div class="release-policy" *ngIf="key.releasePolicy">
                    <pre>
                      {{ formatReleasePolicy(key.releasePolicy) | json }}
                    </pre>
                  </div>
                </td>
              </tr>
            </table>

            <!-- Save/Rotate/Unregister buttons -->
            <div class="key-buttons" *ngIf="hasManagerRole">
              <!-- Save button -->
              <button
                mat-icon-button
                color="primary"
                class="left-align"
                matTooltip="{{ 'Update key ' + key.config!.keyName }}"
                [disabled]="!validateKeyFields(key.config!)"
                (click)="updateKey(key.config!)"
              >
                <mat-icon>save</mat-icon>
              </button>

              <!-- Rotate key button -->
              <button
                mat-icon-button
                color="primary"
                class="left-align"
                matTooltip="{{ 'Rotate key ' + key.config!.keyName }}"
                [disabled]="!canRotateKey(key.config!)"
                (click)="rotateKey(key.config!)"
              >
                <mat-icon>refresh</mat-icon>
              </button>

              <!-- Unregister button -->
              <button
                mat-icon-button
                color="warning"
                class="right-align"
                matTooltip="{{ 'Unregister key ' + key.config!.keyName }}"
                (click)="unregisterKey(key.config!.customerId, key.config!.id)"
              >
                <mat-icon>delete_outline</mat-icon>
              </button>
            </div>

            <!-- Versions -->
            <table mat-table [dataSource]="key!.versions!">
              <!-- Version Column -->
              <ng-container matColumnDef="Version">
                <th mat-header-cell *matHeaderCellDef>Version</th>
                <td mat-cell *matCellDef="let version">
                  <div>
                    <span>{{ version.version }}</span>
                    <!-- Copy to clipboard -->
                    <button
                      mat-icon-button
                      matTooltip="Copy version"
                      [cdkCopyToClipboard]="version.version"
                    >
                      <mat-icon>content_copy</mat-icon>
                    </button>
                  </div>
                </td>
              </ng-container>

              <!-- Public Key Column -->
              <ng-container matColumnDef="Download Public Key">
                <th mat-header-cell *matHeaderCellDef>Download Public Key</th>
                <td mat-cell *matCellDef="let version">
                  <!-- Anonym public key -->
                  <div *ngIf="hasAnonymPublicKey(key.config!)">
                    <!-- Download public key button -->
                    <button
                      mat-icon-button
                      matTooltip="Download PEM file"
                      (click)="
                        downloadPublicKey(key.config!.id, version.version)
                      "
                    >
                      <mat-icon>attachment</mat-icon>
                    </button>
                  </div>
                  <!-- Anonym public key -->
                  <div *ngIf="!hasAnonymPublicKey(key.config!)">
                    <span>N/A</span>
                  </div>
                </td>
              </ng-container>

              <!-- Created Column -->
              <ng-container matColumnDef="Created">
                <th mat-header-cell *matHeaderCellDef>Created On</th>
                <td mat-cell *matCellDef="let version">
                  {{ formatTime(version.createdTime, '') }}
                </td>
              </ng-container>

              <!-- Expires Column -->
              <ng-container matColumnDef="Expires">
                <th mat-header-cell *matHeaderCellDef>Expires On</th>
                <td mat-cell *matCellDef="let version">
                  {{ formatDate(version.expiresTime, 'No expiration') }}
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
          </mat-card-content>
        </mat-card>
      </div>
    </cdk-accordion-item>
  </cdk-accordion>
</div>
