import { Injectable, NgZone } from '@angular/core';
import { FirebaseApp } from '@angular/fire/app';
import { Auth, User } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { GrpcMetadata } from '@ngx-grpc/common';

import {
  FeatureType,
  RequestExperimentOverrides,
} from './generated/src/main/proto/config/config.pb';
import { LoggerService } from './logger.service';
import { UrlParamsService } from './url-params.service';

@Injectable({
  providedIn: 'root',
})
export class ApiAuthService {
  private currentUser: User | undefined;

  constructor(
    public router: Router,
    public ngZone: NgZone,
    public firebaseApp: FirebaseApp,
    private auth: Auth,
    private urlParamsService: UrlParamsService,
    private logger: LoggerService
  ) {
    // Following firebase docs
    // https://firebase.google.com/docs/auth/web/manage-users#get_the_currently_signed-in_user
    this.auth.onAuthStateChanged((user) => {
      if (user) {
        this.currentUser = user;
        this.logger.info('Logged in');
      } else {
        this.logger.info('Not logged in');
      }
    });
  }

  /**
   * Retrieves a header that can be used to authenticate
   * with the API.
   *
   * @returns Promise<GrpcMetadata> Metadata header.
   */
  public getAuthenticatedRequestHeader(): Promise<GrpcMetadata> {
    return new Promise((resolve, reject) => {
      if (this.currentUser) {
        this.currentUser.getIdToken().then((token: string) => {
          const grpcMetaData: GrpcMetadata = new GrpcMetadata();
          grpcMetaData.set('Authorization', 'Bearer ' + token);
          if (this.currentUser && this.currentUser.tenantId) {
            grpcMetaData.set('Tenant-Id', this.currentUser.tenantId);
          }
          this.addExperimentOverrides(grpcMetaData);
          return resolve(grpcMetaData);
        });
      } else {
        return reject('MISSING_CURRENT_USER');
      }
    });
  }

  /**
   * Allows to run logic on the API based on experimental Flags
   *
   * @returns null.
   */
  private addExperimentOverrides(metadata: GrpcMetadata) {
    const params = this.urlParamsService.params;
    const overrides = new RequestExperimentOverrides();
    let added = false;

    // Experiment names.
    params.getAll('experiment').forEach((expName) => {
      if (expName.startsWith('~')) {
        overrides.disableExperimentNames.push(expName.slice(1));
      } else {
        overrides.enableExperimentNames.push(expName);
      }
      added = true;
    });

    // Feature names.
    params.getAll('feature').forEach((featureName) => {
      const disable = featureName.startsWith('~');
      if (disable) {
        featureName = featureName.slice(1);
      }
      const featureType = (<any>FeatureType)[
        'FEATURE_TYPE_' + featureName.toUpperCase()
      ];
      if (featureType) {
        if (disable) {
          overrides.disableFeatures.push(featureType);
        } else {
          overrides.enableFeatures.push(featureType);
        }
        added = true;
      }
    });

    // Update metadata.
    if (added) {
      metadata.set('exp-override', JSON.stringify(overrides.toJSON()));
    }
  }
}
