<div class="header">
  <mat-icon class="icon">{{ stateIcon }}</mat-icon>
  Details
</div>
<div class="body">
  <div class="callout">
    <div><b>Binary Description</b></div>
    <p></p>
    <div>{{ binaryDescription }}</div>
  </div>
  <div class="callout" *ngIf="isReleaseText">
    <div><b>Release Notes</b></div>
    <div class="markdown">
      <markdown [data]="changeLogText" ngPreserveWhitespaces></markdown>
    </div>
  </div>
  <div class="footer">
    <mat-spinner *ngIf="isLoading" diameter="28"></mat-spinner>
  </div>
  <div class="footer">
    <i *ngIf="decision?.approverEmail"
      >{{ stateLabel }} by {{ decision?.approverEmail }} &#64;
      {{ decisionTime }}</i
    >
    <div class="buttons">
      <button
        class="toggle-btn"
        mat-icon-button
        color="primary"
        (click)="redirectToDocumentation()"
        matTooltip="View binary documentation"
        #tooltipTarget="matTooltip"
        *ngIf="binaryDocumentation"
      >
        <mat-icon class="toggle-icon" svgIcon="knowledge_base"></mat-icon>
      </button>
      <div *ngIf="!displaySourceIcon" class="empty-icon"></div>
      <div *ngIf="displaySourceIcon">
        <button
          class="toggle-btn"
          mat-icon-button
          color="primary"
          (click)="prepareDownloadSourceCode()"
          matTooltip="Download source code"
          #tooltipTarget="matTooltip"
          (click)="tooltipTarget.show()"
        >
          <mat-icon class="download-icon">cloud_download</mat-icon>
        </button>
      </div>
      <button
        class="toggle-btn"
        mat-icon-button
        color="primary"
        (click)="openDetails()"
        matTooltip="View binary details"
        #tooltipTarget="matTooltip"
      >
        <mat-icon class="toggle-icon">info_outline</mat-icon>
      </button>
      <app-decision-flag
        *ngIf="isDisapproved && !isLoading"
        state="disapproved"
      ></app-decision-flag>
      <button
        *ngIf="!isDisapproved && !isLoading"
        (click)="disapprove()"
        mat-button
        [class.mat-stroked-button]="!isApproved"
        color="primary"
        [disabled]="!isApprover"
      >
        {{ isApproved ? 'Revoke Approval' : 'Disapprove' }}
      </button>

      <button
        *ngIf="!isApproved && !isLoading"
        (click)="approve()"
        mat-flat-button
        [class.mat-flat-button]="!isDisapproved"
        color="primary"
        [disabled]="!isApprover"
      >
        Approve
      </button>
      <app-decision-flag
        *ngIf="isApproved && !isLoading"
        state="approved"
      ></app-decision-flag>
    </div>
  </div>
  <div *ngIf="!isApprover" class="not-approver">
    Your user role is not allowed to modify the binary state.
  </div>
  <app-message-box></app-message-box>
</div>
