<app-container>
  <app-message-box></app-message-box>
  <mat-spinner
    data-testid="contentSpinner"
    *ngIf="isLoading"
    diameter="24"
  ></mat-spinner>
  <div class="frame">
    <markdown
      class="content"
      [data]="markdown"
      katex
      clipboard
      lineHighlight
    ></markdown>
  </div>
</app-container>
