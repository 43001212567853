import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { LoggerService } from 'app/services/logger.service';
import { UserService } from 'app/services/user.service';
import { MessageBoxProvider } from 'app/views/shared/components/message-box/message-box.provider';

@Component({
  selector: 'app-support-dialog',
  templateUrl: './support-dialog.component.html',
  styleUrls: ['./support-dialog.component.scss'],
})
export class SupportDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<SupportDialogComponent>,
    private logger: LoggerService,
    private messageBox: MessageBoxProvider,
    private router: Router,
    public userService: UserService
  ) {}

  submittedSuccesfully = false;
  isLoading = false;

  ticket = new FormGroup({
    description: new FormControl(''),
  });

  close() {
    this.dialogRef.close();
  }

  submit() {
    const { description } = this.ticket.value;
    const category = this.router.routerState.snapshot.url;
    if (!category || !description) return;
    this.isLoading = true;
    this.userService
      .createSupportTicket(category, description)
      .then(() => {
        this.submittedSuccesfully = true;
      })
      .catch((error) => {
        this.messageBox.error(error.message);
        this.logger.error(error);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }
}
