<app-container>
  <app-page-header
    title="Documentation"
    lastUpdated="{{ time }}"
  ></app-page-header>

  <app-message-box></app-message-box>

  <table
    data-testid="documentTable"
    mat-table
    [dataSource]="docs"
    matSort
    class="table-data"
    (matSortChange)="announceSortChange($event)"
  >
    <ng-container matColumnDef="Title">
      <th
        class="row-header"
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header="title"
      >
        Title
      </th>
      <td mat-cell *matCellDef="let doc">{{ doc.title }}</td>
    </ng-container>

    <ng-container matColumnDef="Description">
      <th
        mat-header-cell
        *matHeaderCellDef
        class="row-header"
        mat-sort-header="description"
      >
        Description
      </th>
      <td mat-cell *matCellDef="let doc">{{ doc.description }}</td>
    </ng-container>

    <ng-container matColumnDef="Category">
      <th
        mat-header-cell
        *matHeaderCellDef
        class="row-header"
        mat-sort-header="category"
      >
        Category
      </th>
      <td mat-cell *matCellDef="let doc">{{ doc.category }}</td>
    </ng-container>

    <ng-container matColumnDef="Release">
      <th
        mat-header-cell
        *matHeaderCellDef
        class="row-header"
        mat-sort-header="release"
      >
        Release
      </th>
      <td mat-cell *matCellDef="let doc" class="text-center">
        <span class="badge">{{ doc.release }}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="Type">
      <th
        mat-header-cell
        *matHeaderCellDef
        class="row-header"
        mat-sort-header="type"
      >
        Type
      </th>
      <td mat-cell *matCellDef="let doc">{{ doc.type }}</td>
    </ng-container>

    <ng-container matColumnDef="Date">
      <th
        mat-header-cell
        *matHeaderCellDef
        class="row-header"
        mat-sort-header="updatedAt"
      >
        Date
      </th>
      <td mat-cell *matCellDef="let doc">{{ formatDate(doc.updatedAt) }}</td>
    </ng-container>

    <ng-container matColumnDef="Link">
      <th mat-header-cell *matHeaderCellDef class="row-header"></th>
      <td mat-cell *matCellDef="let doc" class="text-center">
        <button
          (click)="openPdf(doc)"
          class="delete"
          data-testid="openPdf"
          *ngIf="doc.docType === 'pdf'"
        >
          <mat-icon class="delete-icon">picture_as_pdf</mat-icon>
        </button>
        <button
          (click)="openHtml(doc)"
          class="delete"
          data-testid="openHtml"
          *ngIf="doc.docType === 'html'"
        >
          <mat-icon class="delete-icon">web</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: columnsToDisplay"
      class="table-row"
    ></tr>
  </table>
  <div *ngIf="isLoading" class="loader">
    <mat-spinner diameter="42"></mat-spinner>
  </div>
</app-container>
