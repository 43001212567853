<app-container class="background">
  <app-page-header
    title="Account Information"
    lastUpdated="{{ time }}"
  ></app-page-header>

  <mat-card appearance="outlined">
    <div class="card-header">
      <div>
        <mat-card-header>Update Password</mat-card-header>
      </div>
    </div>

    <mat-card-content>
      <div class="personal-info-card-data">
        <form
          ngNativeValidate
          (ngSubmit)="confirmPasswordReset()"
          [formGroup]="setPasswordForm"
          class="reset-pass-form"
        >
          <div class="input-container">
            <label class="form-label">New Password</label>
            <input
              class="app-input"
              type="password"
              placeholder="Type in your new password"
              formControlName="password"
              minlength="12"
              required
              [ngClass]="{
                'app-input-error': checkError('password', 'required'),
              }"
              data-testid="passInput"
            />
            <mat-error *ngIf="checkError('password', 'minlength')"
              >Password should consist of at least 12 characters.</mat-error
            >
            <mat-error *ngIf="checkError('password', 'required')"
              >Password is required.</mat-error
            >
          </div>

          <div class="input-container">
            <label class="form-label">Confirmation Password</label>
            <input
              class="app-input"
              type="password"
              placeholder="Confirm your new password"
              formControlName="confirmation"
              minlength="12"
              required
              [ngClass]="{
                'app-input-error':
                  checkError('confirmation', 'required') ||
                  checkError('confirmation', 'notMatch'),
              }"
              data-testid="confirmationInput"
            />
            <mat-error *ngIf="checkError('confirmation', 'required')"
              >Confirmation Password is required.</mat-error
            >
            <mat-error *ngIf="checkError('confirmation', 'notMatch')"
              >Please make sure your passwords match.</mat-error
            >

            <div class="button">
              <button
                type="submit"
                color="primary"
                mat-flat-button
                [disabled]="isLoading"
                data-testid="saveBtn"
              >
                {{ isLoading ? null : 'Save' }}
                <span *ngIf="isLoading" class="spinner">
                  <mat-spinner diameter="24"></mat-spinner>
                </span>
              </button>
            </div>
            <app-message-box> </app-message-box>
          </div>
        </form>
      </div>
    </mat-card-content>
  </mat-card>
</app-container>
