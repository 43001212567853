import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { getDownloadURL, getStorage, ref } from '@angular/fire/storage';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { LoggerService } from 'app/services/logger.service';

import { DocumentService } from '../../services/document.service';
import { UserService } from '../../services/user.service';
import { MessageBoxProvider } from '../../views/shared/components/message-box/message-box.provider';

@Component({
  selector: 'app-account',
  templateUrl: './documentation-view.component.html',
  styleUrls: ['./documentation-view.component.scss'],
})
export class DocumentationViewComponent implements OnInit {
  externalHtml: SafeHtml = '';
  path = '';
  isLoading = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private documentService: DocumentService,
    private logger: LoggerService,
    public messageBox: MessageBoxProvider,
    private sanitized: DomSanitizer,
    public userService: UserService,
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      const path = [];
      for (const param of Object.entries(params)) {
        path.push(param[1]);
      }
      this.openDoc(path.join('/'));
    });
  }

  public async openPdf(path: string) {
    this.isLoading = true;
    this.path = path;
    try {
      this.document.location.href =
        await this.documentService.getSignedURL(path);
    } catch (error) {
      this.messageBox.error('Unable to retrieve document');
      this.logger.error('Unable to retrieve document', error);
    } finally {
      this.isLoading = false;
    }
  }

  public async openDoc(path: string) {
    this.path = path;
    this.isLoading = true;

    const storage = getStorage();
    const storageRef = ref(storage, path);

    try {
      const downloadUrl = await getDownloadURL(storageRef);
      const response = await fetch(downloadUrl);
      const text = await response.text();
      if (text.includes('http-equiv="Refresh"')) {
        // This is a redirect header, navigate to new address
        const result = text.match(/url=[^"]*/g);
        if (result) {
          const url = result[0].split('=')[1];
          this.openDoc(this.getLinksWhenUsingRelativeLinks(url));
        }
      } else {
        const updatedHref = await this.getHrefSignedUrls(text);
        const updatedLinks = await this.getStaticAssetsSignedUrls(updatedHref);
        const updatedAnchors = this.updateAnchors(updatedLinks);
        this.externalHtml =
          this.sanitized.bypassSecurityTrustHtml(updatedAnchors);
      }
    } catch (error) {
      this.messageBox.error('Unable to retrieve document');
      this.logger.error('Unable to retrieve document', error);
    } finally {
      this.isLoading = false;
    }
  }

  getBasePath() {
    return this.path.split('/').slice(0, -1).join('/');
  }

  async getHrefSignedUrls(html: string) {
    const hrefMatches = html.match(/href="[^"]*"/g);

    if (hrefMatches) {
      for (const match of hrefMatches) {
        // Skip anchors and external links
        if (
          !match.includes('https://') &&
          !match.includes('http://') &&
          !match.includes('#')
        ) {
          // Clean URL so we can sign it
          const cleanUrl = match
            .replace('href=', '')
            .replaceAll('"', '')
            .split('?')[0];

          try {
            const signedLink =
              'documentation-view?get=' +
              this.getLinksWhenUsingRelativeLinks(cleanUrl);
            html = html.replace(match, 'href="' + signedLink + '"');
          } catch (error) {
            this.logger.error('Unable to retrieved signed url links', error);
          }
        }
      }
    }
    return html;
  }

  updateAnchors(html: string) {
    const hrefMatches = html.match(/href="[^"]*"/g);

    if (hrefMatches) {
      for (const match of hrefMatches) {
        // Skip anchors and external links
        if (
          !match.includes('https://') &&
          !match.includes('http://') &&
          match.includes('#')
        ) {
          // Link is an anchor
          const anchor = match.split('#')[1].replace('"', '');
          const updatedAnchor = `href="documentation-view?get=${encodeURIComponent(
            this.path
          )}#${anchor}"`;
          html = html.replace(match, updatedAnchor);
        }
      }
    }
    return html;
  }

  async getStaticAssetsSignedUrls(html: string) {
    // Get static asssets
    const srcMatches = html.match(/src="[^"]*"/g);
    if (srcMatches) {
      for (const match of srcMatches) {
        if (
          !match.includes('#') &&
          !match.includes('https://') &&
          !match.includes('http://')
        ) {
          // Clean URL so we can sign it
          const cleanUrl = match
            .replace('src=', '')
            .replaceAll('"', '')
            .split('?')[0];

          try {
            const signedLink = await this.documentService.getSignedURL(
              this.getLinksWhenUsingRelativeLinks(cleanUrl)
            );
            html = html.replace(match, `src="${signedLink}"`);
          } catch (error) {
            this.logger.error('Unable to get image signed url', error);
          }
        }
      }
    }
    return html;
  }

  getLinksWhenUsingRelativeLinks(url: string) {
    // Use a real URL stringbuilder here, this is temp logic
    if (url.startsWith('../')) {
      const stripUrl = this.getBasePath().split('/');
      stripUrl.pop();
      return stripUrl.join('/') + '/' + url.replace('../', '');
    } else {
      return this.getBasePath() + '/' + url;
    }
  }
}
