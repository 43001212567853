import { NgModule } from '@angular/core';
import {
  AuthGuard,
  redirectLoggedInTo,
  redirectUnauthorizedTo,
} from '@angular/fire/auth-guard';
import { RouterModule, Routes } from '@angular/router';
import { AdminJobListComponent } from 'app/views/admin-job-list/admin-job-list.component';
import { AdminJobResultsComponent } from 'app/views/admin-job-results/admin-job-results.component';
import { KeysComponent } from 'app/views/keys/keys.component';
import { TodoListComponent } from 'app/views/todo-list/todo-list.component';

import { userRolesGuard } from '../guards/permission.guard';
import { PermissionRoles } from '../services/permission.service';
import { AccountComponent } from '../views/account/account.component';
import { AdminAuditApprovalComponent } from '../views/admin-audit-approval/admin-audit-approval.component';
import { AdminNotificationComponent } from '../views/admin-notification/admin-notification.component';
import { AdminStorageCredentialComponent } from '../views/admin-storage-credential/admin-storage-credential.component';
import { AdminUsersManagerComponent } from '../views/admin-users-manager/admin-users-manager.component';
import { AppManagerComponent } from '../views/app-manager/app-manager.component';
import { AuditApprovalComponent } from '../views/audit-approval/audit-approval.component';
import { ContentPageComponent } from '../views/content-page/content-page.component';
import { CustomerDataComponent } from '../views/customer-data/customer-data.component';
import { CustomerComponent } from '../views/customer-page/customer.component';
import { DataManagementComponent } from '../views/data-management/data-management.component';
import { DocumentationPageComponent } from '../views/documentation-page/documentation-page.component';
import { DocumentationViewComponent } from '../views/documentation-view/documentation-view.component';
import { EmailSignInComponent } from '../views/email-sign-in/email-sign-in.component';
import { ForgotPasswordComponent } from '../views/forgot-password/forgot-password.component';
import { JobDebuggerComponent } from '../views/job-debugger/job-debugger.component';
import { JobLogComponent } from '../views/job-log/job-log.component';
import { JobLogCreatorComponent } from '../views/job-log-creator/job-log-creator.component';
import { JobScheduleComponent } from '../views/job-schedule/job-schedule.component';
import { LandingPageComponent } from '../views/landing-page/landing-page.component';
import { NewUserPasswordComponent } from '../views/new-user-password/new-user-password.component';
import { NotificationComponent } from '../views/notifications-page/notification.component';
import { PublicKeyComponent } from '../views/public-key/public-key.component';
import { ResetPasswordComponent } from '../views/reset-password/reset-password.component';
import { SetPasswordComponent } from '../views/set-password/set-password.component';
import { SettingsPageComponent } from '../views/settings-page/settings-page.component';
import { SignInComponent } from '../views/sign-in/sign-in.component';
import { SignInOptionsComponent } from '../views/sign-in-options/sign-in-options.component';
import { StorageCredentialCreatorComponent } from '../views/storage-credential-creator/storage-credential-creator.component';

const redirectUnauthorizedToLogin = () =>
  redirectUnauthorizedTo(['sign-in-options']);
const redirectLoggedInToHome = () => redirectLoggedInTo(['landing-page']);

// Keep this list ordered alphabetically.
const routes: Routes = [
  { path: '', redirectTo: '/landing-page', pathMatch: 'full' },
  { path: 'account', component: AccountComponent, canActivate: [AuthGuard] },
  {
    path: 'admin-storage-credential',
    component: AdminStorageCredentialComponent,
    canActivate: [AuthGuard, userRolesGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
      expectedRoles: [PermissionRoles.AnonymJobAdmin],
    },
  },
  {
    path: 'anonym-public-key',
    component: PublicKeyComponent,
    canActivate: [AuthGuard, userRolesGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
      fileName: 'anonym_public_key.md',
      expectedRoles: [PermissionRoles.PortalUser],
    },
  },
  {
    path: 'app-data-manager',
    component: DataManagementComponent,
    canActivate: [AuthGuard, userRolesGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
      expectedRoles: [PermissionRoles.PortalContributor],
    },
  },
  {
    path: 'app-manager',
    component: AppManagerComponent,
    canActivate: [AuthGuard, userRolesGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
      expectedRoles: [PermissionRoles.PartnerAdmin],
    },
  },
  {
    path: 'audit-approval',
    component: AuditApprovalComponent,
    canActivate: [AuthGuard, userRolesGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
      expectedRoles: [PermissionRoles.PortalUser],
    },
  },
  {
    path: 'audit-approvals-manager',
    component: AdminAuditApprovalComponent,
    canActivate: [AuthGuard, userRolesGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
      expectedRoles: [PermissionRoles.AnonymPartnerManager],
    },
  },
  {
    path: 'customer-data',
    component: CustomerDataComponent,
    canActivate: [AuthGuard, userRolesGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
      expectedRoles: [PermissionRoles.AnonymJobAdmin],
    },
  },
  {
    path: 'customer-page',
    component: CustomerComponent,
    canActivate: [AuthGuard, userRolesGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
      expectedRoles: [PermissionRoles.AnonymAdmin],
    },
  },
  {
    path: 'documentation-page',
    component: DocumentationPageComponent,
    canActivate: [AuthGuard, userRolesGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
      expectedRoles: [PermissionRoles.PortalUser],
    },
  },
  {
    path: 'documentation-view',
    component: DocumentationViewComponent,
    canActivate: [AuthGuard, userRolesGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
      expectedRoles: [PermissionRoles.PortalUser],
    },
  },
  {
    path: 'documentation-view/:docs/:type:/:name/:version/:index',
    component: DocumentationViewComponent,
    canActivate: [AuthGuard, userRolesGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
      expectedRoles: [PermissionRoles.PortalUser],
    },
  },
  {
    path: 'email-sign-in',
    component: EmailSignInComponent,
    canActivate: [AuthGuard],
    data: { authGuardPipe: redirectLoggedInToHome },
  },

  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
    canActivate: [AuthGuard],
    data: { authGuardPipe: redirectLoggedInToHome },
  },
  {
    path: 'getting-started',
    component: TodoListComponent,
    canActivate: [AuthGuard, userRolesGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
      expectedRoles: [PermissionRoles.PortalUser],
    },
  },
  {
    path: 'jobs/view',
    component: JobDebuggerComponent,
    canActivate: [AuthGuard, userRolesGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
      expectedRoles: [
        PermissionRoles.AnonymAdmin,
        PermissionRoles.AnonymJobAdmin,
      ],
    },
  },
  {
    path: 'jobs/:id/view',
    component: JobDebuggerComponent,
    canActivate: [AuthGuard, userRolesGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
      expectedRoles: [
        PermissionRoles.AnonymAdmin,
        PermissionRoles.AnonymJobAdmin,
      ],
    },
  },
  {
    path: 'job',
    component: JobLogComponent,
    canActivate: [AuthGuard, userRolesGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
      expectedRoles: [PermissionRoles.PortalUser],
    },
  },
  {
    path: 'job-results/:id/view',
    component: AdminJobResultsComponent,
    canActivate: [AuthGuard, userRolesGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
      expectedRoles: [
        PermissionRoles.AnonymAdmin,
        PermissionRoles.AnonymJobAdmin,
      ],
    },
  },
  {
    path: 'jobs',
    component: AdminJobListComponent,
    canActivate: [AuthGuard, userRolesGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
      expectedRoles: [PermissionRoles.AnonymJobAdmin],
    },
  },
  {
    path: 'jobs/:id/edit',
    component: JobLogCreatorComponent,
    canActivate: [AuthGuard, userRolesGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
      expectedRoles: [PermissionRoles.AnonymJobAdmin],
    },
  },
  {
    path: 'jobs/new',
    component: JobLogCreatorComponent,
    canActivate: [AuthGuard, userRolesGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
      expectedRoles: [PermissionRoles.AnonymJobAdmin],
    },
  },
  {
    path: 'job-schedule',
    component: JobScheduleComponent,
    canActivate: [AuthGuard, userRolesGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
      expectedRoles: [PermissionRoles.PortalUser],
    },
  },
  {
    path: 'keys',
    component: KeysComponent,
    canActivate: [AuthGuard, userRolesGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
      expectedRoles: [PermissionRoles.AnonymJobAdmin],
    },
  },
  {
    path: 'landing-page',
    component: LandingPageComponent,
    canActivate: [AuthGuard, userRolesGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
      expectedRoles: [PermissionRoles.PortalUser],
      fileName: 'landing_page.json',
    },
  },
  {
    path: 'new-user-password',
    component: NewUserPasswordComponent,
    canActivate: [AuthGuard],
    data: { authGuardPipe: redirectLoggedInToHome },
  },
  {
    path: 'notifications',
    component: NotificationComponent,
    canActivate: [AuthGuard, userRolesGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
      expectedRoles: [PermissionRoles.PortalUser],
    },
  },
  {
    path: 'notifications-manager',
    component: AdminNotificationComponent,
    canActivate: [AuthGuard, userRolesGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
      expectedRoles: [
        PermissionRoles.AnonymJobAdmin,
        PermissionRoles.AnonymAdmin,
      ],
    },
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent,
    canActivate: [AuthGuard],
    data: { authGuardPipe: redirectLoggedInToHome },
  },
  {
    path: 'settings-page',
    component: SettingsPageComponent,
    canActivate: [AuthGuard, userRolesGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
      expectedRoles: [PermissionRoles.PortalUser],
    },
  },
  {
    path: 'set-password',
    component: SetPasswordComponent,
    canActivate: [AuthGuard, userRolesGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
      expectedRoles: [PermissionRoles.PortalUser],
    },
  },
  {
    path: 'sign-in/:redirect',
    component: SignInComponent,
    canActivate: [AuthGuard],
    data: { authGuardPipe: redirectLoggedInToHome },
  },
  {
    path: 'sign-in',
    component: SignInComponent,
    canActivate: [AuthGuard],
    data: { authGuardPipe: redirectLoggedInToHome },
  },
  {
    path: 'sign-in-options/:redirect',
    component: SignInOptionsComponent,
    canActivate: [AuthGuard],
    data: { authGuardPipe: redirectLoggedInToHome },
  },
  {
    path: 'sign-in-options',
    component: SignInOptionsComponent,
    data: { authGuardPipe: redirectLoggedInToHome },
  },
  {
    path: 'storage-credential-creator',
    component: StorageCredentialCreatorComponent,
    canActivate: [AuthGuard, userRolesGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
      expectedRoles: [PermissionRoles.AnonymJobAdmin],
    },
  },
  {
    path: 'terms-of-use',
    component: ContentPageComponent,
    canActivate: [AuthGuard, userRolesGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
      fileName: 'tos.md',
      expectedRoles: [PermissionRoles.PortalUser],
    },
  },
  {
    path: 'users-manager',
    component: AdminUsersManagerComponent,
    canActivate: [AuthGuard, userRolesGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
      expectedRoles: [PermissionRoles.AnonymAdmin],
    },
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
