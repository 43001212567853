<form [formGroup]="form">
  <app-dataset-picker
    name="Publisher Feed"
    [inputCustomerId]="pubCustomerId"
    [customerDataSetReference]="pubCustomerDataSetReference"
    [location]="location"
    (customerDataSetInfo)="receivePublisherCustomerDataSetInfo($event)"
  >
  </app-dataset-picker>
  <app-dataset-picker
    name="Publisher User Data"
    [inputCustomerId]="pubUserCustomerId"
    [customerDataSetReference]="pubUserCustomerDataSetReference"
    [location]="location"
    (customerDataSetInfo)="receivePublisherUserCustomerDataSetInfo($event)"
  >
  </app-dataset-picker>
  <app-dataset-picker
    name="Advertiser"
    [inputCustomerId]="advCustomerId"
    [customerDataSetReference]="advCustomerDataSetReference"
    [location]="location"
    (customerDataSetInfo)="receiveAdvertiserCustomerDataSetInfo($event)"
  >
  </app-dataset-picker>
  <mat-form-field class="input-half-width">
    <mat-label>Advertiser start date</mat-label>
    <input type="date" matInput formControlName="advertiserStartDate" />
  </mat-form-field>
  <mat-form-field class="input-half-width">
    <mat-label>Advertiser end date</mat-label>
    <input type="date" matInput formControlName="advertiserEndDate" />
  </mat-form-field>
  <mat-form-field class="input-half-width">
    <mat-label>Publisher start date</mat-label>
    <input type="date" matInput formControlName="publisherStartDate" />
  </mat-form-field>
  <mat-form-field class="input-half-width">
    <mat-label>Publisher end date</mat-label>
    <input type="date" matInput formControlName="publisherEndDate" />
  </mat-form-field>
  <mat-form-field class="input-half-width">
    <mat-label>MVI ID</mat-label>
    <input matInput formControlName="mviId" />
  </mat-form-field>
  <mat-form-field class="input-half-width">
    <mat-label>Lookback window (days)</mat-label>
    <input matInput formControlName="lookbackWindowDays" />
  </mat-form-field>
  <mat-form-field class="input-full-width">
    <mat-label>Match keys (comma-separated)</mat-label>
    <input matInput formControlName="matchingColumns" />
  </mat-form-field>
  <mat-form-field class="input-half-width">
    <mat-label>Max impressions per key value</mat-label>
    <input matInput type="number" formControlName="maxImpressionsPerKeyValue" />
  </mat-form-field>
  <mat-form-field class="input-half-width">
    <mat-label>Max conversions per key value</mat-label>
    <input matInput type="number" formControlName="maxConversionsPerKeyValue" />
  </mat-form-field>
  <div formArrayName="groups">
    <div *ngFor="let groupForm of groups.controls; let i = index">
      <div [formGroupName]="i">
        <mat-form-field class="input-half-width">
          <mat-label>Publisher attributes (comma-separated)</mat-label>
          <input formControlName="publisherAttributes" matInput />
        </mat-form-field>
        <mat-checkbox formControlName="isEventLevel">
          Event-level
        </mat-checkbox>
        <button (click)="deleteGroup(i)" mat-button color="primary">
          Delete
        </button>
      </div>
    </div>
  </div>
  <button (click)="addGroup()" mat-button color="primary">Add Group</button>
  <h2>Metrics</h2>
  <table
    mat-table
    [dataSource]="liftMetricsDataSource"
    formArrayName="liftMetrics"
    class="mat-elevation-z1"
  >
    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef>Event type</th>
      <td mat-cell *matCellDef="let index = index">
        {{ metricName(index + 1) }}
      </td>
    </ng-container>
    <ng-container matColumnDef="counts">
      <th mat-header-cell *matHeaderCellDef>Counts</th>
      <td mat-cell *matCellDef="let index = index" [formGroupName]="index">
        <mat-checkbox formControlName="counts"></mat-checkbox>
      </td>
    </ng-container>
    <ng-container matColumnDef="users">
      <th mat-header-cell *matHeaderCellDef>Users</th>
      <td mat-cell *matCellDef="let index = index" [formGroupName]="index">
        <mat-checkbox formControlName="users"></mat-checkbox>
      </td>
    </ng-container>
    <ng-container matColumnDef="amounts">
      <th mat-header-cell *matHeaderCellDef>Amounts</th>
      <td mat-cell *matCellDef="let index = index" [formGroupName]="index">
        <mat-checkbox formControlName="amounts"></mat-checkbox>
      </td>
    </ng-container>
    <ng-container matColumnDef="num_units">
      <th mat-header-cell *matHeaderCellDef>Num units</th>
      <td mat-cell *matCellDef="let index = index" [formGroupName]="index">
        <mat-checkbox formControlName="num_units"></mat-checkbox>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="liftMetricsColumns"></tr>
    <tr
      mat-row
      *matRowDef="let row; let i = index; columns: liftMetricsColumns"
    ></tr>
  </table>

  <h2>Lift estimation configuration</h2>
  <div formArrayName="liftEstimationConfigs">
    <div
      *ngFor="
        let groupForm of liftEstimationConfigs.controls;
        let index = index
      "
    >
      <div [formGroupName]="index">
        <mat-form-field class="input-full-width">
          <mat-label>Lift algorithm</mat-label>
          <mat-select formControlName="liftAlgorithm">
            <mat-option
              *ngFor="let ordinal of liftAlgorithms"
              [value]="ordinal"
            >
              {{ liftAlgorithmName(ordinal) }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="input-half-width">
          <mat-label>Alpha</mat-label>
          <input type="number" matInput formControlName="alpha" />
        </mat-form-field>
        <mat-checkbox formControlName="twoSided"> Two-sided </mat-checkbox>
        <mat-form-field class="input-half-width">
          <mat-label>Rho</mat-label>
          <input type="number" matInput formControlName="rho" />
        </mat-form-field>
        <mat-form-field class="input-half-width">
          <mat-label>Proportion of Rho to spend on capping bound</mat-label>
          <input type="number" matInput formControlName="proportionRhoCap" />
        </mat-form-field>
        <mat-form-field class="input-half-width">
          <mat-label>Sensitivity percentile</mat-label>
          <input type="number" matInput formControlName="percentile" />
        </mat-form-field>
        <mat-form-field class="input-half-width">
          <mat-label>Weight sensitivity percentile</mat-label>
          <input type="number" matInput formControlName="weightPercentile" />
        </mat-form-field>
        <mat-form-field class="input-half-width">
          <mat-label>N Largest</mat-label>
          <input type="number" matInput formControlName="nLargest" />
        </mat-form-field>
        <mat-form-field class="input-half-width">
          <mat-label>Weight N Largest</mat-label>
          <input type="number" matInput formControlName="weightNLargest" />
        </mat-form-field>
        <br />
        <button
          (click)="deleteLiftEstimationConfig(index)"
          mat-button
          color="primary"
        >
          Delete
        </button>
      </div>
    </div>
  </div>
  <button (click)="addLiftEstimationConfig()" mat-button color="primary">
    Add Lift estimation configuration
  </button>
  <h2>Data Cleaning Config</h2>
  <mat-form-field class="input-half-width">
    <mat-label>Maximum impressions per user</mat-label>
    <input matInput formControlName="maximumImpressionsPerUser" />
  </mat-form-field>
  <br />
  <mat-form-field class="input-full-width">
    <mat-label>Advertiser event limits</mat-label>
    <textarea matInput formControlName="advertiserEventsLimits"></textarea>
  </mat-form-field>
  <br />
  <mat-checkbox formControlName="enableDebugLogging">
    Enable debug logging (not differentially private)
  </mat-checkbox>
</form>
