import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormArray, FormBuilder } from '@angular/forms';
import { Timestamp } from '@ngx-grpc/well-known-types';
import { CustomerDataService } from 'app/services/customer-data.service';
import { BinaryType } from 'app/services/generated/src/main/proto/storage/binary-type.pb';
import { Location } from 'app/services/generated/src/main/proto/storage/commons.pb';
import { CustomerDataSetReference } from 'app/services/generated/src/main/proto/storage/customer-data-set-reference.pb';
import {
  ColumnType,
  ValidationConfig,
} from 'app/services/generated/src/main/proto/validation/validation-config.pb';

import {
  CustomerDataSetInfo,
  DatasetPickerComponent,
} from '../common/dataset-picker/dataset-picker.component';

export interface ValidationInfo {
  validationConfig: ValidationConfig;
  customerIds: string[];
}

@Component({
  selector: 'app-validation',
  templateUrl: './validation.component.html',
  styleUrls: ['./validation.component.scss'],
})
export class ValidationComponent implements OnChanges {
  @ViewChild(DatasetPickerComponent) datasetPicker!: DatasetPickerComponent;
  @Input() location: Location = Location.LOCATION_UNSPECIFIED;
  @Input() validationConfig: ValidationConfig | undefined =
    new ValidationConfig();
  @Output() validationInfo = new EventEmitter<ValidationInfo>();
  customerId = '';
  customerDataSetReference: CustomerDataSetReference =
    new CustomerDataSetReference();
  readonly BinaryType = BinaryType;
  customerDataSetInfo: CustomerDataSetInfo = {
    customerId: '',
    customerDataSetReference: new CustomerDataSetReference(),
  };
  form = this.fb.group({
    expectedColumns: this.fb.array([
      this.fb.group({
        name: '',
        columnType: '',
      }),
    ]),
    emitSummaryStatistics: false,
    numShards: 0,
  });
  columnTypes: ColumnType[] = [];

  constructor(
    private fb: FormBuilder,
    private customerDataService: CustomerDataService
  ) {
    for (const value in ColumnType) {
      if ((parseInt(value) || 0) < 1) {
        // Skip COLUMN_TYPE_UNSPECIFIED
        continue;
      }
      this.columnTypes.push(parseInt(value));
    }
    this.deleteExpectedColumn(0);
    this.form.valueChanges.subscribe(() => this.emitValidationInfo());
  }

  get expectedColumns() {
    return this.form.controls['expectedColumns'] as FormArray;
  }

  addExpectedColumn() {
    this.expectedColumns.push(
      this.fb.group({
        name: '',
        columnType: '',
      })
    );
  }

  columnTypeName(index: number) {
    return ColumnType[index];
  }

  deleteExpectedColumn(index: number) {
    this.expectedColumns.removeAt(index);
  }

  receiveCustomerDataSet(customerDataSetInfo: CustomerDataSetInfo) {
    this.customerDataSetInfo = customerDataSetInfo;
    this.emitValidationInfo();
  }

  emitValidationInfo() {
    this.validationInfo.emit({
      validationConfig: this.createValidationConfig(),
      customerIds: [this.customerDataSetInfo.customerId],
    });
  }

  createValidationConfig(): ValidationConfig {
    const form = this.form.value;
    const expectedColumns: { [key: string]: ColumnType } = {};
    form.expectedColumns!.forEach((val) => {
      expectedColumns[val.name!] = parseInt(val.columnType!);
    });
    return new ValidationConfig({
      customerDataSet: this.customerDataSetInfo.customerDataSetReference,
      emitSummaryStatistics: form.emitSummaryStatistics ?? false,
      numShards: form.numShards!,
      expectedColumns: expectedColumns,
    });
  }

  formatTimestamp(timestampProto: Timestamp | undefined): string {
    if (!timestampProto) {
      return '';
    }
    const date = timestampProto.toDate();
    const year = date.getUTCFullYear();
    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
    const day = date.getUTCDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes['validationConfig']) {
      const validationConfig: ValidationConfig =
        changes['validationConfig'].currentValue;
      if (!validationConfig) {
        return;
      }
      const controls = this.form.controls;
      this.expectedColumns.clear();
      for (const [key, value] of Object.entries(
        validationConfig.expectedColumns
      )) {
        this.expectedColumns.push(
          this.fb.group({
            name: key,
            columnType: value,
          })
        );
      }
      controls.emitSummaryStatistics.setValue(
        validationConfig.emitSummaryStatistics ?? false
      );
      if (validationConfig.numShards) {
        controls.numShards.setValue(validationConfig.numShards);
      }
      const response = await this.customerDataService.get(
        validationConfig.customerDataSet!.id
      );
      this.customerId = response.customerDataSet!.customerId;
      this.customerDataSetReference = validationConfig.customerDataSet!;
    }
  }
}
