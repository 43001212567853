<h1 mat-dialog-title>Register Key</h1>
<div mat-dialog-content>
  <div class="form">
    <table>
      <!-- Customer id -->
      <!-- TODO: call customer service to populate list of customer ids. -->
      <tr>
        <td>
          <mat-form-field>
            <mat-label>Customer Id</mat-label>
            <input matInput [(ngModel)]="data.customerId" />
          </mat-form-field>
        </td>
        <td class="description">
          <span>Customer id from the database.</span>
        </td>
      </tr>
      <!-- Key name -->
      <tr>
        <td>
          <mat-form-field>
            <mat-label>Key Name</mat-label>
            <input matInput [(ngModel)]="data.keyName" />
          </mat-form-field>
        </td>
        <td class="description">
          <span
            >Key name is any non-empty string. Recommendation: use lower case
            without spaces.</span
          >
        </td>
      </tr>
      <!-- Expiration period -->
      <tr>
        <td colspan="2">
          <span
            >Optional parameters are as follows: If the Expiration Period
            parameter is specified, the Expiration Period (Key Rotation)
            parameter must also be provided.</span
          >
        </td>
      </tr>
      <tr>
        <td colspan="2"><br /></td>
      </tr>
      <tr>
        <td>
          <mat-form-field>
            <mat-label>Expiration Period</mat-label>
            <input matInput [(ngModel)]="data.expiry" />
          </mat-form-field>
        </td>
        <td class="description">
          <span
            >Expiry time period in ISO 8601 format. For example: 'P365D' to
            expire the key 365 days after creation. Must be greater than 30
            days.</span
          >
        </td>
      </tr>
      <!-- Before expiration time period -->
      <tr>
        <td>
          <mat-form-field>
            <mat-label>Before Expiration Period</mat-label>
            <input matInput [(ngModel)]="data.beforeExpiry" />
          </mat-form-field>
        </td>
        <td class="description">
          <span
            >Key rotation time period before expiration in ISO 8601 format. For
            example: 'P30D' to auto-rotate the key 30 days before expiration.
            Must be smaller than the rotation period.</span
          >
        </td>
      </tr>
    </table>
  </div>
</div>
<div mat-dialog-actions>
  <button
    #registerKeyRegister
    mat-button
    color="primary"
    [disabled]="!isDataValid()"
    [mat-dialog-close]="data"
    cdkFocusInitial
  >
    Register
  </button>
  <button #registerKeyCancel mat-button color="warning" (click)="onCancel()">
    Cancel
  </button>
</div>
