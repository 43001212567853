/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import {
  GrpcMessage,
  RecursivePartial,
  ToProtobufJSONOptions,
} from '@ngx-grpc/common';
import { BinaryReader, BinaryWriter, ByteSource } from 'google-protobuf';
import * as googleProtobuf000 from '@ngx-grpc/well-known-types';
import * as googleProtobuf001 from '@ngx-grpc/well-known-types';
import * as storage002 from '../../../../src/main/proto/storage/schema-annotations.pb';
/**
 * Message implementation for storage.CustomerStorageCredential
 */
export class CustomerStorageCredential implements GrpcMessage {
  static id = 'storage.CustomerStorageCredential';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new CustomerStorageCredential();
    CustomerStorageCredential.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: CustomerStorageCredential) {
    _instance.id = _instance.id || '';
    _instance.customerId = _instance.customerId || '';
    _instance.keyName = _instance.keyName || '';
    _instance.keyVersion = _instance.keyVersion || '';

    _instance.etag = _instance.etag || '';
    _instance.lastUpdate = _instance.lastUpdate || undefined;
    _instance.archived = _instance.archived || false;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: CustomerStorageCredential,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readString();
          break;
        case 2:
          _instance.customerId = _reader.readString();
          break;
        case 3:
          _instance.keyName = _reader.readString();
          break;
        case 4:
          _instance.keyVersion = _reader.readString();
          break;
        case 5:
          _instance.awsCredential = new AwsS3Credential();
          _reader.readMessage(
            _instance.awsCredential,
            AwsS3Credential.deserializeBinaryFromReader
          );
          break;
        case 9:
          _instance.awsAssumeRole = new AwsAssumeRole();
          _reader.readMessage(
            _instance.awsAssumeRole,
            AwsAssumeRole.deserializeBinaryFromReader
          );
          break;
        case 6:
          _instance.etag = _reader.readString();
          break;
        case 7:
          _instance.lastUpdate = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.lastUpdate,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 8:
          _instance.archived = _reader.readBool();
          break;
        default:
          _reader.skipField();
      }
    }

    CustomerStorageCredential.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: CustomerStorageCredential,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeString(1, _instance.id);
    }
    if (_instance.customerId) {
      _writer.writeString(2, _instance.customerId);
    }
    if (_instance.keyName) {
      _writer.writeString(3, _instance.keyName);
    }
    if (_instance.keyVersion) {
      _writer.writeString(4, _instance.keyVersion);
    }
    if (_instance.awsCredential) {
      _writer.writeMessage(
        5,
        _instance.awsCredential as any,
        AwsS3Credential.serializeBinaryToWriter
      );
    }
    if (_instance.awsAssumeRole) {
      _writer.writeMessage(
        9,
        _instance.awsAssumeRole as any,
        AwsAssumeRole.serializeBinaryToWriter
      );
    }
    if (_instance.etag) {
      _writer.writeString(6, _instance.etag);
    }
    if (_instance.lastUpdate) {
      _writer.writeMessage(
        7,
        _instance.lastUpdate as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.archived) {
      _writer.writeBool(8, _instance.archived);
    }
  }

  private _id: string;
  private _customerId: string;
  private _keyName: string;
  private _keyVersion: string;
  private _awsCredential?: AwsS3Credential;
  private _awsAssumeRole?: AwsAssumeRole;
  private _etag: string;
  private _lastUpdate?: googleProtobuf001.Timestamp;
  private _archived: boolean;

  private _storageCredential: CustomerStorageCredential.StorageCredentialCase =
    CustomerStorageCredential.StorageCredentialCase.none;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of CustomerStorageCredential to deeply clone from
   */
  constructor(_value?: RecursivePartial<CustomerStorageCredential.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    this.customerId = _value.customerId;
    this.keyName = _value.keyName;
    this.keyVersion = _value.keyVersion;
    this.awsCredential = _value.awsCredential
      ? new AwsS3Credential(_value.awsCredential)
      : undefined;
    this.awsAssumeRole = _value.awsAssumeRole
      ? new AwsAssumeRole(_value.awsAssumeRole)
      : undefined;
    this.etag = _value.etag;
    this.lastUpdate = _value.lastUpdate
      ? new googleProtobuf001.Timestamp(_value.lastUpdate)
      : undefined;
    this.archived = _value.archived;
    CustomerStorageCredential.refineValues(this);
  }
  get id(): string {
    return this._id;
  }
  set id(value: string) {
    this._id = value;
  }
  get customerId(): string {
    return this._customerId;
  }
  set customerId(value: string) {
    this._customerId = value;
  }
  get keyName(): string {
    return this._keyName;
  }
  set keyName(value: string) {
    this._keyName = value;
  }
  get keyVersion(): string {
    return this._keyVersion;
  }
  set keyVersion(value: string) {
    this._keyVersion = value;
  }
  get awsCredential(): AwsS3Credential | undefined {
    return this._awsCredential;
  }
  set awsCredential(value: AwsS3Credential | undefined) {
    if (value !== undefined && value !== null) {
      this._awsAssumeRole = undefined;
      this._storageCredential =
        CustomerStorageCredential.StorageCredentialCase.awsCredential;
    }
    this._awsCredential = value;
  }
  get awsAssumeRole(): AwsAssumeRole | undefined {
    return this._awsAssumeRole;
  }
  set awsAssumeRole(value: AwsAssumeRole | undefined) {
    if (value !== undefined && value !== null) {
      this._awsCredential = undefined;
      this._storageCredential =
        CustomerStorageCredential.StorageCredentialCase.awsAssumeRole;
    }
    this._awsAssumeRole = value;
  }
  get etag(): string {
    return this._etag;
  }
  set etag(value: string) {
    this._etag = value;
  }
  get lastUpdate(): googleProtobuf001.Timestamp | undefined {
    return this._lastUpdate;
  }
  set lastUpdate(value: googleProtobuf001.Timestamp | undefined) {
    this._lastUpdate = value;
  }
  get archived(): boolean {
    return this._archived;
  }
  set archived(value: boolean) {
    this._archived = value;
  }
  get storageCredential() {
    return this._storageCredential;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    CustomerStorageCredential.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): CustomerStorageCredential.AsObject {
    return {
      id: this.id,
      customerId: this.customerId,
      keyName: this.keyName,
      keyVersion: this.keyVersion,
      awsCredential: this.awsCredential
        ? this.awsCredential.toObject()
        : undefined,
      awsAssumeRole: this.awsAssumeRole
        ? this.awsAssumeRole.toObject()
        : undefined,
      etag: this.etag,
      lastUpdate: this.lastUpdate ? this.lastUpdate.toObject() : undefined,
      archived: this.archived,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): CustomerStorageCredential.AsProtobufJSON {
    return {
      id: this.id,
      customerId: this.customerId,
      keyName: this.keyName,
      keyVersion: this.keyVersion,
      awsCredential: this.awsCredential
        ? this.awsCredential.toProtobufJSON(options)
        : null,
      awsAssumeRole: this.awsAssumeRole
        ? this.awsAssumeRole.toProtobufJSON(options)
        : null,
      etag: this.etag,
      lastUpdate: this.lastUpdate
        ? this.lastUpdate.toProtobufJSON(options)
        : null,
      archived: this.archived,
    };
  }
}
export module CustomerStorageCredential {
  /**
   * Standard JavaScript object representation for CustomerStorageCredential
   */
  export interface AsObject {
    id: string;
    customerId: string;
    keyName: string;
    keyVersion: string;
    awsCredential?: AwsS3Credential.AsObject;
    awsAssumeRole?: AwsAssumeRole.AsObject;
    etag: string;
    lastUpdate?: googleProtobuf001.Timestamp.AsObject;
    archived: boolean;
  }

  /**
   * Protobuf JSON representation for CustomerStorageCredential
   */
  export interface AsProtobufJSON {
    id: string;
    customerId: string;
    keyName: string;
    keyVersion: string;
    awsCredential: AwsS3Credential.AsProtobufJSON | null;
    awsAssumeRole: AwsAssumeRole.AsProtobufJSON | null;
    etag: string;
    lastUpdate: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    archived: boolean;
  }
  export enum StorageCredentialCase {
    none = 0,
    awsCredential = 1,
    awsAssumeRole = 2,
  }
}

/**
 * Message implementation for storage.AwsS3Credential
 */
export class AwsS3Credential implements GrpcMessage {
  static id = 'storage.AwsS3Credential';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new AwsS3Credential();
    AwsS3Credential.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: AwsS3Credential) {
    _instance.accessKeyId = _instance.accessKeyId || '';
    _instance.secretAccessKey = _instance.secretAccessKey || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: AwsS3Credential,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.accessKeyId = _reader.readString();
          break;
        case 2:
          _instance.secretAccessKey = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    AwsS3Credential.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: AwsS3Credential,
    _writer: BinaryWriter
  ) {
    if (_instance.accessKeyId) {
      _writer.writeString(1, _instance.accessKeyId);
    }
    if (_instance.secretAccessKey) {
      _writer.writeString(2, _instance.secretAccessKey);
    }
  }

  private _accessKeyId: string;
  private _secretAccessKey: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of AwsS3Credential to deeply clone from
   */
  constructor(_value?: RecursivePartial<AwsS3Credential.AsObject>) {
    _value = _value || {};
    this.accessKeyId = _value.accessKeyId;
    this.secretAccessKey = _value.secretAccessKey;
    AwsS3Credential.refineValues(this);
  }
  get accessKeyId(): string {
    return this._accessKeyId;
  }
  set accessKeyId(value: string) {
    this._accessKeyId = value;
  }
  get secretAccessKey(): string {
    return this._secretAccessKey;
  }
  set secretAccessKey(value: string) {
    this._secretAccessKey = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    AwsS3Credential.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): AwsS3Credential.AsObject {
    return {
      accessKeyId: this.accessKeyId,
      secretAccessKey: this.secretAccessKey,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): AwsS3Credential.AsProtobufJSON {
    return {
      accessKeyId: this.accessKeyId,
      secretAccessKey: this.secretAccessKey,
    };
  }
}
export module AwsS3Credential {
  /**
   * Standard JavaScript object representation for AwsS3Credential
   */
  export interface AsObject {
    accessKeyId: string;
    secretAccessKey: string;
  }

  /**
   * Protobuf JSON representation for AwsS3Credential
   */
  export interface AsProtobufJSON {
    accessKeyId: string;
    secretAccessKey: string;
  }
}

/**
 * Message implementation for storage.AwsAssumeRole
 */
export class AwsAssumeRole implements GrpcMessage {
  static id = 'storage.AwsAssumeRole';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new AwsAssumeRole();
    AwsAssumeRole.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: AwsAssumeRole) {
    _instance.roleArn = _instance.roleArn || '';
    _instance.useExternalId = _instance.useExternalId || false;
    _instance.externalId = _instance.externalId || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: AwsAssumeRole,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.roleArn = _reader.readString();
          break;
        case 3:
          _instance.useExternalId = _reader.readBool();
          break;
        case 2:
          _instance.externalId = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    AwsAssumeRole.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: AwsAssumeRole,
    _writer: BinaryWriter
  ) {
    if (_instance.roleArn) {
      _writer.writeString(1, _instance.roleArn);
    }
    if (_instance.useExternalId) {
      _writer.writeBool(3, _instance.useExternalId);
    }
    if (_instance.externalId) {
      _writer.writeString(2, _instance.externalId);
    }
  }

  private _roleArn: string;
  private _useExternalId: boolean;
  private _externalId: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of AwsAssumeRole to deeply clone from
   */
  constructor(_value?: RecursivePartial<AwsAssumeRole.AsObject>) {
    _value = _value || {};
    this.roleArn = _value.roleArn;
    this.useExternalId = _value.useExternalId;
    this.externalId = _value.externalId;
    AwsAssumeRole.refineValues(this);
  }
  get roleArn(): string {
    return this._roleArn;
  }
  set roleArn(value: string) {
    this._roleArn = value;
  }
  get useExternalId(): boolean {
    return this._useExternalId;
  }
  set useExternalId(value: boolean) {
    this._useExternalId = value;
  }
  get externalId(): string {
    return this._externalId;
  }
  set externalId(value: string) {
    this._externalId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    AwsAssumeRole.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): AwsAssumeRole.AsObject {
    return {
      roleArn: this.roleArn,
      useExternalId: this.useExternalId,
      externalId: this.externalId,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): AwsAssumeRole.AsProtobufJSON {
    return {
      roleArn: this.roleArn,
      useExternalId: this.useExternalId,
      externalId: this.externalId,
    };
  }
}
export module AwsAssumeRole {
  /**
   * Standard JavaScript object representation for AwsAssumeRole
   */
  export interface AsObject {
    roleArn: string;
    useExternalId: boolean;
    externalId: string;
  }

  /**
   * Protobuf JSON representation for AwsAssumeRole
   */
  export interface AsProtobufJSON {
    roleArn: string;
    useExternalId: boolean;
    externalId: string;
  }
}
