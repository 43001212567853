import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ApprovalService } from 'app/services/approval.service';
import { FormatService } from 'app/services/format.service';
import { ApiApprovalRequest } from 'app/services/generated/src/main/proto/api/approval-service.pb';
import {
  ApprovalDecision,
  AuditArea,
  AuditAreaApprovalState,
  BinaryState,
} from 'app/services/generated/src/main/proto/storage/approval.pb';

import { ApprovalDetailComponent } from '../approval-detail/approval-detail.component';
import {
  APPROVAL_STATE,
  APPROVAL_STATE_LABEL,
  BINARY_STATE,
} from '../constants';

@Component({
  selector: 'app-binary',
  templateUrl: './binary.component.html',
  styleUrls: ['./binary.component.scss', '../audit-approval.component.scss'],
  standalone: true,
  imports: [
    ApprovalDetailComponent,
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatTooltipModule,
    MatMenuModule,
    MatProgressSpinnerModule,
  ],
})
export class BinaryComponent {
  constructor(
    private approvalService: ApprovalService,
    public dialog: MatDialog,
    private formatService: FormatService
  ) {}

  decisions: ApprovalDecision[] = [];
  isUpdating = false;
  isLoading = false;
  status = BINARY_STATE[BinaryState.BINARY_STATE_UNSPECIFIED];
  isActive = false;
  approvalState =
    APPROVAL_STATE[
      AuditAreaApprovalState.AUDIT_AREA_APPROVAL_STATE_UNSPECIFIED
    ];
  approvalStateLabel =
    APPROVAL_STATE_LABEL[
      AuditAreaApprovalState.AUDIT_AREA_APPROVAL_STATE_UNSPECIFIED
    ];
  releaseDate: string | undefined;
  @Input() displaySourceIcon = false;
  @Input() isOpen = false;
  @Input() isApprover = false;
  @Input() onToggle: ((id: string | undefined) => void) | undefined;
  @Input() onApprovalUpdate:
    | ((approval: ApiApprovalRequest | undefined) => void)
    | undefined;
  @Input() setUpdateInProgress: ((isUpdating: boolean) => void) | undefined;
  @Input() sourceDownloadPath: string | undefined;
  @Input() updateInProgress = false;
  @Input()
  set approval(approval: ApiApprovalRequest | undefined) {
    const binaryState =
      approval?.decisionRollup?.binaryStateDecision?.binaryState;
    const approvalState =
      approval?.decisionRollup?.approvalDecision?.approvalState;
    const date = approval?.approvalRequestDetail?.creationTime;
    this.decisions =
      approval?.decisionRollup?.approvalDecision?.approvalDecisionByArea ?? [];
    this._approval = approval;
    if (binaryState) {
      this.status = BINARY_STATE[binaryState];
      this.isActive = binaryState === BinaryState.BINARY_STATE_ACTIVE;
    }
    if (approvalState) {
      this.approvalState = APPROVAL_STATE[approvalState];
      this.approvalStateLabel = APPROVAL_STATE_LABEL[approvalState];
    }
    this.releaseDate = this.formatService.formatProtoDateTime(date);
  }

  get approval() {
    return this._approval;
  }

  private _approval: ApiApprovalRequest | undefined;
  openArea: AuditArea | undefined;
  handleToggleOpenArea = (id: AuditArea | undefined) => {
    if (!id) return;
    this.openArea = this.openArea === id ? undefined : id;
  };

  setBinaryState(state: BinaryState): void {
    const approval = this.approval;
    if (!approval || !approval?.decisionRollup?.customerId || !this.isApprover)
      return;
    this.setUpdateInProgress?.(true);
    this.isUpdating = true;
    this.approvalService
      .setBinaryState(
        approval.id,
        approval.decisionRollup?.customerId,
        state,
        'reason'
      )
      .then(() => {
        return this.approvalService.getApproval(approval.id).then((res) => {
          const updated = res.approvalRequests?.[0];
          this.onApprovalUpdate?.(updated);
        });
      })
      .catch((error) => {
        console.error(JSON.stringify(error));
      })
      .finally(() => {
        this.setUpdateInProgress?.(false);
        this.isUpdating = false;
      });
  }

  setActive(): void {
    this.setBinaryState(BinaryState.BINARY_STATE_ACTIVE);
  }

  setInactive(): void {
    this.setBinaryState(BinaryState.BINARY_STATE_INACTIVE);
  }
}
