/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import { Inject, Injectable, Optional } from '@angular/core';
import {
  GrpcCallType,
  GrpcClient,
  GrpcClientFactory,
  GrpcEvent,
  GrpcMetadata,
} from '@ngx-grpc/common';
import {
  GRPC_CLIENT_FACTORY,
  GrpcHandler,
  takeMessages,
  throwStatusErrors,
} from '@ngx-grpc/core';
import { Observable } from 'rxjs';
import * as thisProto from './app-service.pb';
import * as googleProtobuf000 from '@ngx-grpc/well-known-types';
import * as api001 from '../../../../src/main/proto/api/headers.pb';
import * as storage002 from '../../../../src/main/proto/storage/app.pb';
import { GRPC_APP_SERVICE_CLIENT_SETTINGS } from './app-service.pbconf';
/**
 * Service client implementation for api.app.AppService
 */
@Injectable({ providedIn: 'any' })
export class AppServiceClient {
  private client: GrpcClient<any>;

  /**
   * Raw RPC implementation for each service client method.
   * The raw methods provide more control on the incoming data and events. E.g. they can be useful to read status `OK` metadata.
   * Attention: these methods do not throw errors when non-zero status codes are received.
   */
  $raw = {
    /**
     * Unary call: /api.app.AppService/Create
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.CreateResponse>>
     */
    create: (
      requestData: thisProto.CreateRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.CreateResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/api.app.AppService/Create',
        requestData,
        requestMetadata,
        requestClass: thisProto.CreateRequest,
        responseClass: thisProto.CreateResponse,
      });
    },
    /**
     * Unary call: /api.app.AppService/Update
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.UpdateResponse>>
     */
    update: (
      requestData: thisProto.UpdateRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.UpdateResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/api.app.AppService/Update',
        requestData,
        requestMetadata,
        requestClass: thisProto.UpdateRequest,
        responseClass: thisProto.UpdateResponse,
      });
    },
    /**
     * Unary call: /api.app.AppService/AssignRoles
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.AssignRolesResponse>>
     */
    assignRoles: (
      requestData: thisProto.AssignRolesRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.AssignRolesResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/api.app.AppService/AssignRoles',
        requestData,
        requestMetadata,
        requestClass: thisProto.AssignRolesRequest,
        responseClass: thisProto.AssignRolesResponse,
      });
    },
    /**
     * Unary call: /api.app.AppService/Archive
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.ArchiveResponse>>
     */
    archive: (
      requestData: thisProto.ArchiveRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.ArchiveResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/api.app.AppService/Archive',
        requestData,
        requestMetadata,
        requestClass: thisProto.ArchiveRequest,
        responseClass: thisProto.ArchiveResponse,
      });
    },
    /**
     * Unary call: /api.app.AppService/RotateApiKey
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.RotateApiKeyResponse>>
     */
    rotateApiKey: (
      requestData: thisProto.RotateApiKeyRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.RotateApiKeyResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/api.app.AppService/RotateApiKey',
        requestData,
        requestMetadata,
        requestClass: thisProto.RotateApiKeyRequest,
        responseClass: thisProto.RotateApiKeyResponse,
      });
    },
    /**
     * Unary call: /api.app.AppService/GetAll
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.GetAllResponse>>
     */
    getAll: (
      requestData: thisProto.GetAllRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.GetAllResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/api.app.AppService/GetAll',
        requestData,
        requestMetadata,
        requestClass: thisProto.GetAllRequest,
        responseClass: thisProto.GetAllResponse,
      });
    },
  };

  constructor(
    @Optional() @Inject(GRPC_APP_SERVICE_CLIENT_SETTINGS) settings: any,
    @Inject(GRPC_CLIENT_FACTORY) clientFactory: GrpcClientFactory<any>,
    private handler: GrpcHandler
  ) {
    this.client = clientFactory.createClient('api.app.AppService', settings);
  }

  /**
   * Unary call @/api.app.AppService/Create
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.CreateResponse>
   */
  create(
    requestData: thisProto.CreateRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.CreateResponse> {
    return this.$raw
      .create(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/api.app.AppService/Update
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.UpdateResponse>
   */
  update(
    requestData: thisProto.UpdateRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.UpdateResponse> {
    return this.$raw
      .update(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/api.app.AppService/AssignRoles
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.AssignRolesResponse>
   */
  assignRoles(
    requestData: thisProto.AssignRolesRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.AssignRolesResponse> {
    return this.$raw
      .assignRoles(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/api.app.AppService/Archive
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.ArchiveResponse>
   */
  archive(
    requestData: thisProto.ArchiveRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.ArchiveResponse> {
    return this.$raw
      .archive(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/api.app.AppService/RotateApiKey
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.RotateApiKeyResponse>
   */
  rotateApiKey(
    requestData: thisProto.RotateApiKeyRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.RotateApiKeyResponse> {
    return this.$raw
      .rotateApiKey(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/api.app.AppService/GetAll
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.GetAllResponse>
   */
  getAll(
    requestData: thisProto.GetAllRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.GetAllResponse> {
    return this.$raw
      .getAll(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }
}
