import { Clipboard } from '@angular/cdk/clipboard';
import { Component } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { FormHelpersService } from 'app/services/form-helpers.service';

import { AppService } from '../../services/app.service';
import { LoggerService } from '../../services/logger.service';
import { MessageBoxProvider } from '../../views/shared/components/message-box/message-box.provider';

@Component({
  selector: 'app-add-app-modal',
  templateUrl: './add-app-modal.component.html',
  styleUrls: ['./add-app-modal.component.scss', '../../shared/shared.scss'],
})
export class AddAppModalComponent {
  isLoading = false;
  appForm: FormGroup;
  title = 'Add New App';
  key: string | undefined;
  appId: string | undefined;
  appName: string | undefined;
  isReadOnly = false;

  constructor(
    private analytics: AngularFireAnalytics,
    private appService: AppService,
    private clipboard: Clipboard,
    private dialogRef: MatDialogRef<AddAppModalComponent>,
    private formBuilder: FormBuilder,
    private formHelper: FormHelpersService,
    private logger: LoggerService,
    private messageBox: MessageBoxProvider
  ) {
    this.appForm = this.formBuilder.group({
      appName: new FormControl('', {}),
    });

    this.formHelper.setForm(this.appForm);
  }

  public close() {
    this.dialogRef.close();
  }

  async process() {
    this.isLoading = true;
    const { appName } = this.appForm.value;

    try {
      const createResponse = await this.appService.create(appName);
      this.key = createResponse.apiKey;
      this.appId = createResponse.appId;
      this.appName = appName;
      this.title = `App ${appName} created`;
      this.messageBox.show('App created successfully', 'success');
      this.analytics.logEvent('app-created', { appName: appName });
      this.appForm.get('appName')?.disable();
    } catch (error) {
      this.logger.error('Error creating new app: [%s]', error);
      this.analytics.logEvent('app-creation-failed', { appName: appName });
      this.messageBox.error('Unable to create new app');
    } finally {
      this.isLoading = false;
    }
  }

  public checkError(controlName: string, errorName: string) {
    return this.formHelper.checkError(controlName, errorName);
  }

  public copyToClipboard(value: string) {
    this.clipboard.copy(value);
  }
}
