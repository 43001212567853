<div class="background">
  <div class="card">
    <app-anonym-logo [showWelcomeMessage]="false"></app-anonym-logo>
    <hr />
    <div class="body">
      <h1>Create New Password</h1>

      <p class="description">
        Your code has been verified. Create new password. It must be different
        from previously used password.
      </p>

      <form
        ngNativeValidate
        (ngSubmit)="confirmPasswordReset()"
        [formGroup]="resetPasswordForm"
        class="reset-pass-form"
      >
        <div class="input-container">
          <label class="form-label">New Password</label>
          <input
            class="app-input"
            type="password"
            placeholder="Type in your new password"
            formControlName="password"
            required
            [minLength]="12"
            [disabled]="codeExpired"
            [ngClass]="{
              'app-input-error': checkError('password', 'required'),
            }"
            data-testid="passInput"
          />
          <mat-error *ngIf="checkError('password', 'minlength')"
            >Password should consist of at least 12 characters.</mat-error
          >
          <mat-error *ngIf="checkError('password', 'required')"
            >Password is required.</mat-error
          >
        </div>

        <div class="input-container">
          <label class="form-label">Confirmation Password</label>
          <input
            class="app-input"
            type="password"
            placeholder="Confirm your new password"
            formControlName="confirmation"
            required
            [disabled]="codeExpired"
            [ngClass]="{
              'app-input-error':
                checkError('confirmation', 'required') ||
                checkError('confirmation', 'notMatch'),
            }"
            data-testid="confirmationInput"
          />
          <mat-error *ngIf="checkError('confirmation', 'required')"
            >Confirmation Password is required</mat-error
          >
          <mat-error *ngIf="checkError('confirmation', 'notMatch')"
            >Please make sure your passwords match</mat-error
          >
        </div>

        <app-message-box> </app-message-box>

        <button
          type="submit"
          color="primary"
          mat-flat-button
          [disabled]="isLoading || codeExpired || !resetPasswordForm.valid"
          data-testid="saveBtn"
        >
          {{ isLoading ? null : 'Save' }}
          <span *ngIf="isLoading" class="spinner">
            <mat-spinner diameter="24"></mat-spinner>
          </span>
        </button>
      </form>
      <hr />
      <p>
        Have an issue?
        <a class="link" href="mailto:eddie@anonymco.com">Contact Us</a>
      </p>
    </div>
  </div>
</div>
