/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import {
  GrpcMessage,
  RecursivePartial,
  ToProtobufJSONOptions,
} from '@ngx-grpc/common';
import { BinaryReader, BinaryWriter, ByteSource } from 'google-protobuf';
import * as googleProtobuf000 from '@ngx-grpc/well-known-types';
import * as api001 from '../../../../src/main/proto/api/headers.pb';
import * as storage002 from '../../../../src/main/proto/storage/app.pb';
/**
 * Message implementation for api.app.CreateRequest
 */
export class CreateRequest implements GrpcMessage {
  static id = 'api.app.CreateRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new CreateRequest();
    CreateRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: CreateRequest) {
    _instance.header = _instance.header || undefined;
    _instance.appName = _instance.appName || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: CreateRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api001.RequestHeader();
          _reader.readMessage(
            _instance.header,
            api001.RequestHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.appName = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    CreateRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: CreateRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api001.RequestHeader.serializeBinaryToWriter
      );
    }
    if (_instance.appName) {
      _writer.writeString(2, _instance.appName);
    }
  }

  private _header?: api001.RequestHeader;
  private _appName: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of CreateRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<CreateRequest.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api001.RequestHeader(_value.header)
      : undefined;
    this.appName = _value.appName;
    CreateRequest.refineValues(this);
  }
  get header(): api001.RequestHeader | undefined {
    return this._header;
  }
  set header(value: api001.RequestHeader | undefined) {
    this._header = value;
  }
  get appName(): string {
    return this._appName;
  }
  set appName(value: string) {
    this._appName = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    CreateRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): CreateRequest.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      appName: this.appName,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): CreateRequest.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      appName: this.appName,
    };
  }
}
export module CreateRequest {
  /**
   * Standard JavaScript object representation for CreateRequest
   */
  export interface AsObject {
    header?: api001.RequestHeader.AsObject;
    appName: string;
  }

  /**
   * Protobuf JSON representation for CreateRequest
   */
  export interface AsProtobufJSON {
    header: api001.RequestHeader.AsProtobufJSON | null;
    appName: string;
  }
}

/**
 * Message implementation for api.app.CreateResponse
 */
export class CreateResponse implements GrpcMessage {
  static id = 'api.app.CreateResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new CreateResponse();
    CreateResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: CreateResponse) {
    _instance.header = _instance.header || undefined;
    _instance.appId = _instance.appId || '';
    _instance.apiKey = _instance.apiKey || '';
    _instance.apiKeyRev = _instance.apiKeyRev || '';
    _instance.etag = _instance.etag || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: CreateResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api001.ResponseHeader();
          _reader.readMessage(
            _instance.header,
            api001.ResponseHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.appId = _reader.readString();
          break;
        case 3:
          _instance.apiKey = _reader.readString();
          break;
        case 4:
          _instance.apiKeyRev = _reader.readString();
          break;
        case 5:
          _instance.etag = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    CreateResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: CreateResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api001.ResponseHeader.serializeBinaryToWriter
      );
    }
    if (_instance.appId) {
      _writer.writeString(2, _instance.appId);
    }
    if (_instance.apiKey) {
      _writer.writeString(3, _instance.apiKey);
    }
    if (_instance.apiKeyRev) {
      _writer.writeString(4, _instance.apiKeyRev);
    }
    if (_instance.etag) {
      _writer.writeString(5, _instance.etag);
    }
  }

  private _header?: api001.ResponseHeader;
  private _appId: string;
  private _apiKey: string;
  private _apiKeyRev: string;
  private _etag: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of CreateResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<CreateResponse.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api001.ResponseHeader(_value.header)
      : undefined;
    this.appId = _value.appId;
    this.apiKey = _value.apiKey;
    this.apiKeyRev = _value.apiKeyRev;
    this.etag = _value.etag;
    CreateResponse.refineValues(this);
  }
  get header(): api001.ResponseHeader | undefined {
    return this._header;
  }
  set header(value: api001.ResponseHeader | undefined) {
    this._header = value;
  }
  get appId(): string {
    return this._appId;
  }
  set appId(value: string) {
    this._appId = value;
  }
  get apiKey(): string {
    return this._apiKey;
  }
  set apiKey(value: string) {
    this._apiKey = value;
  }
  get apiKeyRev(): string {
    return this._apiKeyRev;
  }
  set apiKeyRev(value: string) {
    this._apiKeyRev = value;
  }
  get etag(): string {
    return this._etag;
  }
  set etag(value: string) {
    this._etag = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    CreateResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): CreateResponse.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      appId: this.appId,
      apiKey: this.apiKey,
      apiKeyRev: this.apiKeyRev,
      etag: this.etag,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): CreateResponse.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      appId: this.appId,
      apiKey: this.apiKey,
      apiKeyRev: this.apiKeyRev,
      etag: this.etag,
    };
  }
}
export module CreateResponse {
  /**
   * Standard JavaScript object representation for CreateResponse
   */
  export interface AsObject {
    header?: api001.ResponseHeader.AsObject;
    appId: string;
    apiKey: string;
    apiKeyRev: string;
    etag: string;
  }

  /**
   * Protobuf JSON representation for CreateResponse
   */
  export interface AsProtobufJSON {
    header: api001.ResponseHeader.AsProtobufJSON | null;
    appId: string;
    apiKey: string;
    apiKeyRev: string;
    etag: string;
  }
}

/**
 * Message implementation for api.app.UpdateRequest
 */
export class UpdateRequest implements GrpcMessage {
  static id = 'api.app.UpdateRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new UpdateRequest();
    UpdateRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: UpdateRequest) {
    _instance.header = _instance.header || undefined;
    _instance.appId = _instance.appId || '';
    _instance.appName = _instance.appName || '';
    _instance.etag = _instance.etag || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: UpdateRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api001.RequestHeader();
          _reader.readMessage(
            _instance.header,
            api001.RequestHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.appId = _reader.readString();
          break;
        case 3:
          _instance.appName = _reader.readString();
          break;
        case 4:
          _instance.etag = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    UpdateRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: UpdateRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api001.RequestHeader.serializeBinaryToWriter
      );
    }
    if (_instance.appId) {
      _writer.writeString(2, _instance.appId);
    }
    if (_instance.appName) {
      _writer.writeString(3, _instance.appName);
    }
    if (_instance.etag) {
      _writer.writeString(4, _instance.etag);
    }
  }

  private _header?: api001.RequestHeader;
  private _appId: string;
  private _appName: string;
  private _etag: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of UpdateRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<UpdateRequest.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api001.RequestHeader(_value.header)
      : undefined;
    this.appId = _value.appId;
    this.appName = _value.appName;
    this.etag = _value.etag;
    UpdateRequest.refineValues(this);
  }
  get header(): api001.RequestHeader | undefined {
    return this._header;
  }
  set header(value: api001.RequestHeader | undefined) {
    this._header = value;
  }
  get appId(): string {
    return this._appId;
  }
  set appId(value: string) {
    this._appId = value;
  }
  get appName(): string {
    return this._appName;
  }
  set appName(value: string) {
    this._appName = value;
  }
  get etag(): string {
    return this._etag;
  }
  set etag(value: string) {
    this._etag = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    UpdateRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): UpdateRequest.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      appId: this.appId,
      appName: this.appName,
      etag: this.etag,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): UpdateRequest.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      appId: this.appId,
      appName: this.appName,
      etag: this.etag,
    };
  }
}
export module UpdateRequest {
  /**
   * Standard JavaScript object representation for UpdateRequest
   */
  export interface AsObject {
    header?: api001.RequestHeader.AsObject;
    appId: string;
    appName: string;
    etag: string;
  }

  /**
   * Protobuf JSON representation for UpdateRequest
   */
  export interface AsProtobufJSON {
    header: api001.RequestHeader.AsProtobufJSON | null;
    appId: string;
    appName: string;
    etag: string;
  }
}

/**
 * Message implementation for api.app.UpdateResponse
 */
export class UpdateResponse implements GrpcMessage {
  static id = 'api.app.UpdateResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new UpdateResponse();
    UpdateResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: UpdateResponse) {
    _instance.header = _instance.header || undefined;
    _instance.etag = _instance.etag || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: UpdateResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api001.ResponseHeader();
          _reader.readMessage(
            _instance.header,
            api001.ResponseHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.etag = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    UpdateResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: UpdateResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api001.ResponseHeader.serializeBinaryToWriter
      );
    }
    if (_instance.etag) {
      _writer.writeString(2, _instance.etag);
    }
  }

  private _header?: api001.ResponseHeader;
  private _etag: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of UpdateResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<UpdateResponse.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api001.ResponseHeader(_value.header)
      : undefined;
    this.etag = _value.etag;
    UpdateResponse.refineValues(this);
  }
  get header(): api001.ResponseHeader | undefined {
    return this._header;
  }
  set header(value: api001.ResponseHeader | undefined) {
    this._header = value;
  }
  get etag(): string {
    return this._etag;
  }
  set etag(value: string) {
    this._etag = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    UpdateResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): UpdateResponse.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      etag: this.etag,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): UpdateResponse.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      etag: this.etag,
    };
  }
}
export module UpdateResponse {
  /**
   * Standard JavaScript object representation for UpdateResponse
   */
  export interface AsObject {
    header?: api001.ResponseHeader.AsObject;
    etag: string;
  }

  /**
   * Protobuf JSON representation for UpdateResponse
   */
  export interface AsProtobufJSON {
    header: api001.ResponseHeader.AsProtobufJSON | null;
    etag: string;
  }
}

/**
 * Message implementation for api.app.AssignRolesRequest
 */
export class AssignRolesRequest implements GrpcMessage {
  static id = 'api.app.AssignRolesRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new AssignRolesRequest();
    AssignRolesRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: AssignRolesRequest) {
    _instance.header = _instance.header || undefined;
    _instance.appId = _instance.appId || '';
    _instance.roles = _instance.roles || [];
    _instance.etag = _instance.etag || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: AssignRolesRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api001.RequestHeader();
          _reader.readMessage(
            _instance.header,
            api001.RequestHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.appId = _reader.readString();
          break;
        case 3:
          (_instance.roles = _instance.roles || []).push(_reader.readString());
          break;
        case 4:
          _instance.etag = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    AssignRolesRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: AssignRolesRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api001.RequestHeader.serializeBinaryToWriter
      );
    }
    if (_instance.appId) {
      _writer.writeString(2, _instance.appId);
    }
    if (_instance.roles && _instance.roles.length) {
      _writer.writeRepeatedString(3, _instance.roles);
    }
    if (_instance.etag) {
      _writer.writeString(4, _instance.etag);
    }
  }

  private _header?: api001.RequestHeader;
  private _appId: string;
  private _roles: string[];
  private _etag: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of AssignRolesRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<AssignRolesRequest.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api001.RequestHeader(_value.header)
      : undefined;
    this.appId = _value.appId;
    this.roles = (_value.roles || []).slice();
    this.etag = _value.etag;
    AssignRolesRequest.refineValues(this);
  }
  get header(): api001.RequestHeader | undefined {
    return this._header;
  }
  set header(value: api001.RequestHeader | undefined) {
    this._header = value;
  }
  get appId(): string {
    return this._appId;
  }
  set appId(value: string) {
    this._appId = value;
  }
  get roles(): string[] {
    return this._roles;
  }
  set roles(value: string[]) {
    this._roles = value;
  }
  get etag(): string {
    return this._etag;
  }
  set etag(value: string) {
    this._etag = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    AssignRolesRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): AssignRolesRequest.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      appId: this.appId,
      roles: (this.roles || []).slice(),
      etag: this.etag,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): AssignRolesRequest.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      appId: this.appId,
      roles: (this.roles || []).slice(),
      etag: this.etag,
    };
  }
}
export module AssignRolesRequest {
  /**
   * Standard JavaScript object representation for AssignRolesRequest
   */
  export interface AsObject {
    header?: api001.RequestHeader.AsObject;
    appId: string;
    roles: string[];
    etag: string;
  }

  /**
   * Protobuf JSON representation for AssignRolesRequest
   */
  export interface AsProtobufJSON {
    header: api001.RequestHeader.AsProtobufJSON | null;
    appId: string;
    roles: string[];
    etag: string;
  }
}

/**
 * Message implementation for api.app.AssignRolesResponse
 */
export class AssignRolesResponse implements GrpcMessage {
  static id = 'api.app.AssignRolesResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new AssignRolesResponse();
    AssignRolesResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: AssignRolesResponse) {
    _instance.header = _instance.header || undefined;
    _instance.etag = _instance.etag || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: AssignRolesResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api001.ResponseHeader();
          _reader.readMessage(
            _instance.header,
            api001.ResponseHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.etag = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    AssignRolesResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: AssignRolesResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api001.ResponseHeader.serializeBinaryToWriter
      );
    }
    if (_instance.etag) {
      _writer.writeString(2, _instance.etag);
    }
  }

  private _header?: api001.ResponseHeader;
  private _etag: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of AssignRolesResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<AssignRolesResponse.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api001.ResponseHeader(_value.header)
      : undefined;
    this.etag = _value.etag;
    AssignRolesResponse.refineValues(this);
  }
  get header(): api001.ResponseHeader | undefined {
    return this._header;
  }
  set header(value: api001.ResponseHeader | undefined) {
    this._header = value;
  }
  get etag(): string {
    return this._etag;
  }
  set etag(value: string) {
    this._etag = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    AssignRolesResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): AssignRolesResponse.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      etag: this.etag,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): AssignRolesResponse.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      etag: this.etag,
    };
  }
}
export module AssignRolesResponse {
  /**
   * Standard JavaScript object representation for AssignRolesResponse
   */
  export interface AsObject {
    header?: api001.ResponseHeader.AsObject;
    etag: string;
  }

  /**
   * Protobuf JSON representation for AssignRolesResponse
   */
  export interface AsProtobufJSON {
    header: api001.ResponseHeader.AsProtobufJSON | null;
    etag: string;
  }
}

/**
 * Message implementation for api.app.ArchiveRequest
 */
export class ArchiveRequest implements GrpcMessage {
  static id = 'api.app.ArchiveRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ArchiveRequest();
    ArchiveRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ArchiveRequest) {
    _instance.header = _instance.header || undefined;
    _instance.appId = _instance.appId || '';
    _instance.etag = _instance.etag || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ArchiveRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api001.RequestHeader();
          _reader.readMessage(
            _instance.header,
            api001.RequestHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.appId = _reader.readString();
          break;
        case 3:
          _instance.etag = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    ArchiveRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ArchiveRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api001.RequestHeader.serializeBinaryToWriter
      );
    }
    if (_instance.appId) {
      _writer.writeString(2, _instance.appId);
    }
    if (_instance.etag) {
      _writer.writeString(3, _instance.etag);
    }
  }

  private _header?: api001.RequestHeader;
  private _appId: string;
  private _etag: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ArchiveRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<ArchiveRequest.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api001.RequestHeader(_value.header)
      : undefined;
    this.appId = _value.appId;
    this.etag = _value.etag;
    ArchiveRequest.refineValues(this);
  }
  get header(): api001.RequestHeader | undefined {
    return this._header;
  }
  set header(value: api001.RequestHeader | undefined) {
    this._header = value;
  }
  get appId(): string {
    return this._appId;
  }
  set appId(value: string) {
    this._appId = value;
  }
  get etag(): string {
    return this._etag;
  }
  set etag(value: string) {
    this._etag = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ArchiveRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ArchiveRequest.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      appId: this.appId,
      etag: this.etag,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ArchiveRequest.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      appId: this.appId,
      etag: this.etag,
    };
  }
}
export module ArchiveRequest {
  /**
   * Standard JavaScript object representation for ArchiveRequest
   */
  export interface AsObject {
    header?: api001.RequestHeader.AsObject;
    appId: string;
    etag: string;
  }

  /**
   * Protobuf JSON representation for ArchiveRequest
   */
  export interface AsProtobufJSON {
    header: api001.RequestHeader.AsProtobufJSON | null;
    appId: string;
    etag: string;
  }
}

/**
 * Message implementation for api.app.ArchiveResponse
 */
export class ArchiveResponse implements GrpcMessage {
  static id = 'api.app.ArchiveResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ArchiveResponse();
    ArchiveResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ArchiveResponse) {
    _instance.header = _instance.header || undefined;
    _instance.etag = _instance.etag || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ArchiveResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api001.RequestHeader();
          _reader.readMessage(
            _instance.header,
            api001.RequestHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.etag = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    ArchiveResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ArchiveResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api001.RequestHeader.serializeBinaryToWriter
      );
    }
    if (_instance.etag) {
      _writer.writeString(2, _instance.etag);
    }
  }

  private _header?: api001.RequestHeader;
  private _etag: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ArchiveResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<ArchiveResponse.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api001.RequestHeader(_value.header)
      : undefined;
    this.etag = _value.etag;
    ArchiveResponse.refineValues(this);
  }
  get header(): api001.RequestHeader | undefined {
    return this._header;
  }
  set header(value: api001.RequestHeader | undefined) {
    this._header = value;
  }
  get etag(): string {
    return this._etag;
  }
  set etag(value: string) {
    this._etag = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ArchiveResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ArchiveResponse.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      etag: this.etag,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ArchiveResponse.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      etag: this.etag,
    };
  }
}
export module ArchiveResponse {
  /**
   * Standard JavaScript object representation for ArchiveResponse
   */
  export interface AsObject {
    header?: api001.RequestHeader.AsObject;
    etag: string;
  }

  /**
   * Protobuf JSON representation for ArchiveResponse
   */
  export interface AsProtobufJSON {
    header: api001.RequestHeader.AsProtobufJSON | null;
    etag: string;
  }
}

/**
 * Message implementation for api.app.RotateApiKeyRequest
 */
export class RotateApiKeyRequest implements GrpcMessage {
  static id = 'api.app.RotateApiKeyRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new RotateApiKeyRequest();
    RotateApiKeyRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: RotateApiKeyRequest) {
    _instance.header = _instance.header || undefined;
    _instance.appId = _instance.appId || '';
    _instance.etag = _instance.etag || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: RotateApiKeyRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api001.RequestHeader();
          _reader.readMessage(
            _instance.header,
            api001.RequestHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.appId = _reader.readString();
          break;
        case 3:
          _instance.etag = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    RotateApiKeyRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: RotateApiKeyRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api001.RequestHeader.serializeBinaryToWriter
      );
    }
    if (_instance.appId) {
      _writer.writeString(2, _instance.appId);
    }
    if (_instance.etag) {
      _writer.writeString(3, _instance.etag);
    }
  }

  private _header?: api001.RequestHeader;
  private _appId: string;
  private _etag: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of RotateApiKeyRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<RotateApiKeyRequest.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api001.RequestHeader(_value.header)
      : undefined;
    this.appId = _value.appId;
    this.etag = _value.etag;
    RotateApiKeyRequest.refineValues(this);
  }
  get header(): api001.RequestHeader | undefined {
    return this._header;
  }
  set header(value: api001.RequestHeader | undefined) {
    this._header = value;
  }
  get appId(): string {
    return this._appId;
  }
  set appId(value: string) {
    this._appId = value;
  }
  get etag(): string {
    return this._etag;
  }
  set etag(value: string) {
    this._etag = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    RotateApiKeyRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): RotateApiKeyRequest.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      appId: this.appId,
      etag: this.etag,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): RotateApiKeyRequest.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      appId: this.appId,
      etag: this.etag,
    };
  }
}
export module RotateApiKeyRequest {
  /**
   * Standard JavaScript object representation for RotateApiKeyRequest
   */
  export interface AsObject {
    header?: api001.RequestHeader.AsObject;
    appId: string;
    etag: string;
  }

  /**
   * Protobuf JSON representation for RotateApiKeyRequest
   */
  export interface AsProtobufJSON {
    header: api001.RequestHeader.AsProtobufJSON | null;
    appId: string;
    etag: string;
  }
}

/**
 * Message implementation for api.app.RotateApiKeyResponse
 */
export class RotateApiKeyResponse implements GrpcMessage {
  static id = 'api.app.RotateApiKeyResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new RotateApiKeyResponse();
    RotateApiKeyResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: RotateApiKeyResponse) {
    _instance.header = _instance.header || undefined;
    _instance.apiKey = _instance.apiKey || '';
    _instance.apiKeyRev = _instance.apiKeyRev || 0;
    _instance.etag = _instance.etag || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: RotateApiKeyResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api001.ResponseHeader();
          _reader.readMessage(
            _instance.header,
            api001.ResponseHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.apiKey = _reader.readString();
          break;
        case 3:
          _instance.apiKeyRev = _reader.readInt32();
          break;
        case 4:
          _instance.etag = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    RotateApiKeyResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: RotateApiKeyResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api001.ResponseHeader.serializeBinaryToWriter
      );
    }
    if (_instance.apiKey) {
      _writer.writeString(2, _instance.apiKey);
    }
    if (_instance.apiKeyRev) {
      _writer.writeInt32(3, _instance.apiKeyRev);
    }
    if (_instance.etag) {
      _writer.writeString(4, _instance.etag);
    }
  }

  private _header?: api001.ResponseHeader;
  private _apiKey: string;
  private _apiKeyRev: number;
  private _etag: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of RotateApiKeyResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<RotateApiKeyResponse.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api001.ResponseHeader(_value.header)
      : undefined;
    this.apiKey = _value.apiKey;
    this.apiKeyRev = _value.apiKeyRev;
    this.etag = _value.etag;
    RotateApiKeyResponse.refineValues(this);
  }
  get header(): api001.ResponseHeader | undefined {
    return this._header;
  }
  set header(value: api001.ResponseHeader | undefined) {
    this._header = value;
  }
  get apiKey(): string {
    return this._apiKey;
  }
  set apiKey(value: string) {
    this._apiKey = value;
  }
  get apiKeyRev(): number {
    return this._apiKeyRev;
  }
  set apiKeyRev(value: number) {
    this._apiKeyRev = value;
  }
  get etag(): string {
    return this._etag;
  }
  set etag(value: string) {
    this._etag = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    RotateApiKeyResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): RotateApiKeyResponse.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      apiKey: this.apiKey,
      apiKeyRev: this.apiKeyRev,
      etag: this.etag,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): RotateApiKeyResponse.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      apiKey: this.apiKey,
      apiKeyRev: this.apiKeyRev,
      etag: this.etag,
    };
  }
}
export module RotateApiKeyResponse {
  /**
   * Standard JavaScript object representation for RotateApiKeyResponse
   */
  export interface AsObject {
    header?: api001.ResponseHeader.AsObject;
    apiKey: string;
    apiKeyRev: number;
    etag: string;
  }

  /**
   * Protobuf JSON representation for RotateApiKeyResponse
   */
  export interface AsProtobufJSON {
    header: api001.ResponseHeader.AsProtobufJSON | null;
    apiKey: string;
    apiKeyRev: number;
    etag: string;
  }
}

/**
 * Message implementation for api.app.GetAllRequest
 */
export class GetAllRequest implements GrpcMessage {
  static id = 'api.app.GetAllRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetAllRequest();
    GetAllRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetAllRequest) {
    _instance.header = _instance.header || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetAllRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api001.ResponseHeader();
          _reader.readMessage(
            _instance.header,
            api001.ResponseHeader.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    GetAllRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetAllRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api001.ResponseHeader.serializeBinaryToWriter
      );
    }
  }

  private _header?: api001.ResponseHeader;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetAllRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetAllRequest.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api001.ResponseHeader(_value.header)
      : undefined;
    GetAllRequest.refineValues(this);
  }
  get header(): api001.ResponseHeader | undefined {
    return this._header;
  }
  set header(value: api001.ResponseHeader | undefined) {
    this._header = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetAllRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetAllRequest.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetAllRequest.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
    };
  }
}
export module GetAllRequest {
  /**
   * Standard JavaScript object representation for GetAllRequest
   */
  export interface AsObject {
    header?: api001.ResponseHeader.AsObject;
  }

  /**
   * Protobuf JSON representation for GetAllRequest
   */
  export interface AsProtobufJSON {
    header: api001.ResponseHeader.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for api.app.GetAllResponse
 */
export class GetAllResponse implements GrpcMessage {
  static id = 'api.app.GetAllResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetAllResponse();
    GetAllResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetAllResponse) {
    _instance.header = _instance.header || undefined;
    _instance.apps = _instance.apps || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetAllResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api001.ResponseHeader();
          _reader.readMessage(
            _instance.header,
            api001.ResponseHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          const messageInitializer2 = new storage002.App();
          _reader.readMessage(
            messageInitializer2,
            storage002.App.deserializeBinaryFromReader
          );
          (_instance.apps = _instance.apps || []).push(messageInitializer2);
          break;
        default:
          _reader.skipField();
      }
    }

    GetAllResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetAllResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api001.ResponseHeader.serializeBinaryToWriter
      );
    }
    if (_instance.apps && _instance.apps.length) {
      _writer.writeRepeatedMessage(
        2,
        _instance.apps as any,
        storage002.App.serializeBinaryToWriter
      );
    }
  }

  private _header?: api001.ResponseHeader;
  private _apps?: storage002.App[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetAllResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetAllResponse.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api001.ResponseHeader(_value.header)
      : undefined;
    this.apps = (_value.apps || []).map((m) => new storage002.App(m));
    GetAllResponse.refineValues(this);
  }
  get header(): api001.ResponseHeader | undefined {
    return this._header;
  }
  set header(value: api001.ResponseHeader | undefined) {
    this._header = value;
  }
  get apps(): storage002.App[] | undefined {
    return this._apps;
  }
  set apps(value: storage002.App[] | undefined) {
    this._apps = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetAllResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetAllResponse.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      apps: (this.apps || []).map((m) => m.toObject()),
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetAllResponse.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      apps: (this.apps || []).map((m) => m.toProtobufJSON(options)),
    };
  }
}
export module GetAllResponse {
  /**
   * Standard JavaScript object representation for GetAllResponse
   */
  export interface AsObject {
    header?: api001.ResponseHeader.AsObject;
    apps?: storage002.App.AsObject[];
  }

  /**
   * Protobuf JSON representation for GetAllResponse
   */
  export interface AsProtobufJSON {
    header: api001.ResponseHeader.AsProtobufJSON | null;
    apps: storage002.App.AsProtobufJSON[] | null;
  }
}
