import { Injectable, Optional } from '@angular/core';
import { Auth } from '@angular/fire/auth';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { GrpcMetadata } from '@ngx-grpc/common';
import { Timestamp } from '@ngx-grpc/well-known-types';
import { firstValueFrom } from 'rxjs';

import { ApiAuthService } from './api-auth.service';
import {
  AddDecisionRequest,
  AddDecisionResponse,
  ApprovalRequestFilter,
  ArchiveRequest,
  ArchiveResponse,
  GetAllCustomersResponse,
  GetApprovedBinariesRequest,
  GetApprovedBinariesResponse,
  GetRequest,
  GetResponse,
  SetBinaryStateRequest,
  SetBinaryStateResponse,
  UpdateViewersRequest,
  UpdateViewersResponse,
} from './generated/src/main/proto/api/approval-service.pb';
import { ApprovalServiceClient } from './generated/src/main/proto/api/approval-service.pbsc';
import { GetPaginatedRequest } from './generated/src/main/proto/api/pagination.pb';
import {
  ApprovalDecision,
  ApprovalDecisionReason,
  ApprovedViewers,
  AuditArea,
  AuditAreaApprovalState,
  BinaryState,
  BinaryStateDecision,
  BinaryStateDecisionReason,
  CustomerIdList,
} from './generated/src/main/proto/storage/approval.pb';
import { BinaryType } from './generated/src/main/proto/storage/binary-type.pb';

export interface AddApprovalDecisionArgs {
  approvalRequestId: string;
  customerId: string;
  auditAppprovalState: AuditAreaApprovalState;
  auditArea: AuditArea;
  reason: string;
}

/**
 * Service to manage approval workflows, including creating, updating, and archiving approvals.
 */
@Injectable()
export class ApprovalService {
  private email!: string | null;

  constructor(
    private approvalServiceClient: ApprovalServiceClient,
    private analytics: AngularFireAnalytics,
    public apiAuthService: ApiAuthService,
    @Optional() private auth: Auth
  ) {
    this.auth.onAuthStateChanged((user) => {
      if (user) {
        this.email = user.email;
      }
    });
  }

  /**
   * Retrieves a list of approved binaries for a specified customer.
   *
   * @param customerId - The ID of the customer.
   * @returns A promise that resolves to a GetApprovedBinariesResponse containing the approved binaries.
   */
  public async getApprovedBinaries(
    customerId: string
  ): Promise<GetApprovedBinariesResponse> {
    const grpcMetaData: GrpcMetadata =
      await this.apiAuthService.getAuthenticatedRequestHeader();

    const request = new GetApprovedBinariesRequest();
    request.customerId = customerId;

    return await firstValueFrom(
      this.approvalServiceClient.getApprovedBinaries(request, grpcMetaData)
    );
  }

  /**
   * Retrieves a list of approvals based on filters such as the number of records, continuation token, binary type, and customer IDs.
   *
   * @param numRecords - The number of records to retrieve.
   * @param continuationToken - The token for paginated requests.
   * @param binaryType - The type of binary to filter by.
   * @param customerIds - The list of customer IDs to filter by.
   * @returns A promise that resolves to a GetResponse containing the approvals.
   */
  public async getApprovals(
    numRecords: number,
    continuationToken: string | null,
    binaryType: BinaryType | BinaryType.BINARY_TYPE_UNSPECIFIED,
    customerIds: string[]
  ): Promise<GetResponse> {
    const grpcMetaData: GrpcMetadata =
      await this.apiAuthService.getAuthenticatedRequestHeader();

    const getRequest = new GetRequest();
    const getPaginatedRequest = new GetPaginatedRequest();
    getPaginatedRequest.numRecords = numRecords;

    if (continuationToken) {
      getPaginatedRequest.continuationToken = continuationToken;
    }

    getRequest.paginated = getPaginatedRequest;

    // Only filter by binary type if value is not undefined
    if (binaryType !== BinaryType.BINARY_TYPE_UNSPECIFIED) {
      const approvalRequestFilter = new ApprovalRequestFilter();
      approvalRequestFilter.binaryType = [binaryType];
      approvalRequestFilter.customerId = customerIds;
      getRequest.getRequestFilter = approvalRequestFilter;
    }

    return await firstValueFrom(
      this.approvalServiceClient.get(getRequest, grpcMetaData)
    );
  }

  /**
   * Retrieves a specific approval by its request ID.
   *
   * @param approvalRequestId - The ID of the approval request.
   * @returns A promise that resolves to a GetResponse containing the approval details.
   */
  public async getApproval(approvalRequestId: string): Promise<GetResponse> {
    const grpcMetaData: GrpcMetadata =
      await this.apiAuthService.getAuthenticatedRequestHeader();

    const getRequest = new GetRequest();
    getRequest.getRequestFilter = new ApprovalRequestFilter();
    getRequest.getRequestFilter.approvalRequestId.push(approvalRequestId);
    return await firstValueFrom(
      this.approvalServiceClient.get(getRequest, grpcMetaData)
    );
  }

  /**
   * Retrieves all customers.
   *
   * @param getRequest - The request parameters.
   * @returns A promise that resolves to a GetAllCustomersResponse containing the list of customers.
   */
  public async getAllCustomers(
    getRequest: GetRequest
  ): Promise<GetAllCustomersResponse> {
    const grpcMetaData: GrpcMetadata =
      await this.apiAuthService.getAuthenticatedRequestHeader();
    return await firstValueFrom(
      this.approvalServiceClient.getAllCustomers(getRequest, grpcMetaData)
    );
  }

  /**
   * Retrieves customer approval details.
   *
   * @params numRecords - number of records to get per page.
   * @params continuationToken - Token to identify next page.
   * @params binaryType[] - Optional list of binary types, response will only include records with matching type.
   * @params archived - Optional archived flag, response will include all items including archived.
   * @returns A promise that resolves to a GetAllCustomersResponse containing customer approval details.
   */
  public async getCustomerApproval(
    numRecords: number,
    continuationToken: string | null,
    binaryType: BinaryType[] | undefined,
    archived: boolean | undefined
  ): Promise<GetAllCustomersResponse> {
    const grpcMetaData: GrpcMetadata =
      await this.apiAuthService.getAuthenticatedRequestHeader();

    const getRequest = new GetRequest();
    if (binaryType || archived) {
      const getRequestFilter = new ApprovalRequestFilter();
      if (archived) {
        getRequestFilter.archived = archived;
      }
      if (binaryType) {
        getRequestFilter.binaryType = binaryType;
      }
      getRequest.getRequestFilter = getRequestFilter;
    }

    if (numRecords) {
      const getPaginatedRequest: GetPaginatedRequest =
        new GetPaginatedRequest();
      getPaginatedRequest.numRecords = numRecords;
      if (continuationToken) {
        getPaginatedRequest.continuationToken = continuationToken;
      }
      getRequest.paginated = getPaginatedRequest;
    }

    return await firstValueFrom(
      this.approvalServiceClient.getAllCustomers(getRequest, grpcMetaData)
    );
  }

  /**
   * Adds an approval decision.
   *
   * @param approvalRequestId
   * @param customerId
   * @param auditAppprovalState
   * @param auditArea
   * @param reason
   *
   * @returns A promise that resolves to an AddDecisionResponse containing the result of the operation.
   */
  public async addApprovalDecision({
    approvalRequestId,
    customerId,
    auditAppprovalState,
    auditArea,
    reason,
  }: AddApprovalDecisionArgs): Promise<AddDecisionResponse> {
    const grpcMetaData: GrpcMetadata =
      await this.apiAuthService.getAuthenticatedRequestHeader();

    //Set Binary State Decision
    const approvalDecision = new ApprovalDecision();

    /* Options
      AuditAreaApprovalState.AUDIT_AREA_APPROVAL_STATE_APPROVED 2
      AuditAreaApprovalState.AUDIT_AREA_APPROVAL_STATE_DISAPPROVED 3
      AuditAreaApprovalState.AUDIT_AREA_APPROVAL_STATE_PENDING 1
      AuditAreaApprovalState.AUDIT_AREA_APPROVAL_STATE_UNSPECIFIED 0
      */
    approvalDecision.auditAreaApprovalState = auditAppprovalState;

    /* Options
      AuditArea.AUDIT_AREA_EXECUTION_ENVIRONMENT 1
      AuditArea.AUDIT_AREA_MEASUREMENT_ALGORITHMS 2
      AuditArea.AUDIT_AREA_PRIVACY_ALGORITHMS 3
      AuditArea.AUDIT_AREA_UNSPECIFIED 0
      */
    approvalDecision.auditArea = auditArea;

    if (this.email) {
      approvalDecision.approverEmail = this.email;
      const event =
        approvalDecision.auditAreaApprovalState ===
        AuditAreaApprovalState.AUDIT_AREA_APPROVAL_STATE_APPROVED
          ? 'approve'
          : 'disapprove';
      this.analytics.logEvent(event);
    }
    //We only have Tenant ID?? Customer request is being implemented
    approvalDecision.customerId = customerId;

    //Set Approval Decision Reason
    const approvalDecisionReason = new ApprovalDecisionReason();
    approvalDecisionReason.note = reason;
    approvalDecision.reason = approvalDecisionReason;

    const time = Date.now();
    const timestamp = new Timestamp();
    timestamp.seconds = Math.trunc(time / 1000).toString();
    timestamp.nanos = (time % 1000) * 1e6;
    approvalDecision.time = timestamp;

    const addDecisionRequest = new AddDecisionRequest();
    addDecisionRequest.decision = approvalDecision;
    addDecisionRequest.approvalRequestId = approvalRequestId;

    const approval: GetResponse = await this.getApproval(approvalRequestId);
    const row = approval.approvalRequests?.pop();

    if (row) {
      addDecisionRequest.etag = row.etag;
    }

    return firstValueFrom(
      this.approvalServiceClient.addDecision(addDecisionRequest, grpcMetaData)
    );
  }

  /**
   * Sets the binary state of an approval.
   *
   * @param approvalRequestId - The ID of the approval request.
   * @param customerId - The ID of the customer.
   * @param binaryState - The binary state to be set.
   * @param reason - The reason for the binary state change.
   * @returns A promise that resolves to a SetBinaryStateResponse containing the result of the operation.
   */
  public async setBinaryState(
    approvalRequestId: string,
    customerId: string,
    binaryState: BinaryState,
    reason: string
  ): Promise<SetBinaryStateResponse> {
    const approvalResponse: GetResponse =
      await this.getApproval(approvalRequestId);
    const grpcMetaData: GrpcMetadata =
      await this.apiAuthService.getAuthenticatedRequestHeader();

    //Set Binary State Decision
    const binaryStateDecision = new BinaryStateDecision();

    /* Options
    BinaryState.BINARY_STATE_ACTIVE  1
    BinaryState.BINARY_STATE_INACTIVE 2
    BinaryState.BINARY_STATE_UNSPECIFIED 0
    */
    binaryStateDecision.binaryState = binaryState;

    if (this.email) {
      binaryStateDecision.approverEmail = this.email;
      const event =
        binaryStateDecision.binaryState === BinaryState.BINARY_STATE_ACTIVE
          ? 'activate'
          : 'deactivate';
      this.analytics.logEvent(event);
    }
    //We only have Tenant ID?? Customer request is being implemented
    binaryStateDecision.customerId = customerId;

    //Set Binary State Decision Reason
    const binaryStateDecisionReason = new BinaryStateDecisionReason();
    binaryStateDecisionReason.note = reason;
    binaryStateDecision.reason = binaryStateDecisionReason;

    const time = Date.now();
    const timestamp = new Timestamp();
    timestamp.seconds = Math.trunc(time / 1000).toString();
    timestamp.nanos = (time % 1000) * 1e6;
    binaryStateDecision.time = timestamp;

    const setBinaryState = new SetBinaryStateRequest();
    setBinaryState.binaryStateDecision = binaryStateDecision;
    setBinaryState.approvalRequestId = approvalRequestId;

    const row = approvalResponse.approvalRequests?.pop();

    if (row) {
      setBinaryState.etag = row.etag;
    }

    return await firstValueFrom(
      this.approvalServiceClient.setBinaryState(setBinaryState, grpcMetaData)
    );
  }

  /**
   * Updates the viewers for a specified approval request.
   *
   * @param approvalRequestId - The ID of the approval request.
   * @param etag - The entity tag of the approval request.
   * @param customerIds - An array of customer IDs to be set as viewers.
   * @param all - A boolean indicating whether to set all customers as viewers. If undefined, customerIds will be used.
   * @returns A promise that resolves to an UpdateViewersResponse containing the result of the operation.
   */
  async updateViewers(
    approvalRequestId: string,
    etag: string,
    customerIds: string[],
    all: boolean | undefined
  ): Promise<UpdateViewersResponse> {
    const grpcMetaData: GrpcMetadata =
      await this.apiAuthService.getAuthenticatedRequestHeader();

    const requestData = new UpdateViewersRequest();
    requestData.id = approvalRequestId;
    requestData.etag = etag;
    const approvedViewers = new ApprovedViewers();

    if (all === undefined) {
      approvedViewers.customerIdList = new CustomerIdList();

      customerIds.forEach((customerId) => {
        if (approvedViewers.customerIdList) {
          approvedViewers.customerIdList.customerIds.push(customerId);
        }
      });
    } else {
      approvedViewers.all = all;
    }

    requestData.viewers = approvedViewers;
    return await firstValueFrom(
      this.approvalServiceClient.updateViewers(requestData, grpcMetaData)
    );
  }

  /**
   * Archives a specified approval request.
   *
   * @param approvalRequestId - The ID of the approval request to be archived.
   * @param etag - The entity tag of the approval request.
   * @returns A promise that resolves to an ArchiveResponse containing the result of the operation.
   */
  async archive(
    approvalRequestId: string,
    etag: string
  ): Promise<ArchiveResponse> {
    const grpcMetaData: GrpcMetadata =
      await this.apiAuthService.getAuthenticatedRequestHeader();

    return await firstValueFrom(
      this.approvalServiceClient.archive(
        new ArchiveRequest({
          approvalRequestId: approvalRequestId,
          etag: etag,
        }),
        grpcMetaData
      )
    );
  }
}
