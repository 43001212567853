<div class="modal">
  <div mat-dialog-title>
    <h2 class="modal-title">{{ title }}</h2>
    <button
      mat-icon-button
      aria-label="close dialog"
      mat-dialog-close
      class="modal-close-btn"
      (click)="close()"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div mat-dialog-content>
    <form
      ngNativeValidate
      (ngSubmit)="process()"
      [formGroup]="jobSchedule"
      id="jobScheduleForm"
    >
      <div class="field-row">
        <div class="field-name-container">
          <label class="field-title">Job Schedule Name</label>
          <div>
            <input
              class="app-input"
              type="text"
              placeholder="Job Schedule Name"
              formControlName="name"
              size="40"
            />
          </div>
        </div>
      </div>

      <div class="field-row">
        <div class="field-name-container">
          <label class="field-title">Job Log ID</label>
          <div>
            <input
              class="app-input"
              type="text"
              placeholder="Job Log ID"
              formControlName="jobLogId"
              size="40"
            />
          </div>
        </div>
      </div>
      <div formGroupName="startTime" class="time-container">
        <div class="time-field">
          <label class="field-title">Start Hour</label>
          <input
            class="app-input"
            type="number"
            placeholder="UTC Time: Hour (0-23)"
            formControlName="startHour"
            min="0"
            max="23"
          />
        </div>
        <div class="time-field">
          <label class="field-title">Start Minute</label>
          <input
            class="app-input"
            type="number"
            placeholder="UTC Time: Minute (0-59)"
            formControlName="startMinute"
            min="0"
            max="59"
          />
        </div>
      </div>
      <app-message-box></app-message-box>
    </form>
  </div>

  <div class="save-btn" mat-dialog-actions>
    <button
      mat-flat-button
      color="primary"
      [disabled]="isLoading"
      form="jobScheduleForm"
      type="submit"
      data-testid="saveBtn"
    >
      {{ isLoading ? null : 'Save' }}
      <span *ngIf="isLoading" class="spinner">
        <mat-spinner diameter="24"></mat-spinner>
      </span>
    </button>
  </div>
</div>
