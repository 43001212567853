<div class="modal">
  <div mat-dialog-title>
    <h2 class="modal-title">{{ title }}</h2>
    <button
      mat-icon-button
      aria-label="close dialog"
      mat-dialog-close
      class="modal-close-btn"
      (click)="close()"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div mat-dialog-content>
    <form
      ngNativeValidate
      (ngSubmit)="process()"
      [formGroup]="appForm"
      id="addAppForm"
    >
      <div class="field-row">
        <div class="field-types-container">
          <label class="field-title">Name</label>
          <div class="field-roles-checkboxes">
            <input
              *ngIf="!appName"
              class="app-input"
              type="text"
              placeholder="Type in your app name..."
              formControlName="appName"
              required
              size="40"
              maxlength="40"
              [ngClass]="{
                'app-input-error': checkError('appName', 'required'),
              }"
            />
            <span *ngIf="appName" class="app-name">{{ appName }}</span>
          </div>
          <mat-error *ngIf="checkError('appName', 'required')"
            >App Name is required
          </mat-error>
        </div>
      </div>
      <div class="footer" *ngIf="!key">
        <button
          mat-flat-button
          color="primary"
          class="save-btn"
          data-testid="addButton"
        >
          Add
        </button>
      </div>
    </form>
    <div class="field-row" *ngIf="appId">
      <div class="field-roles-container">
        <label class="field-title">App Id</label>
        <div class="field-roles-checkboxes-row">
          <input
            type="text"
            size="50"
            maxlength="50"
            readonly
            value="{{ appId }}"
          />
          <button
            data-testid="viewKey"
            class="tool-btn"
            matTooltip="Copy"
            size="50"
            (click)="copyToClipboard(appId)"
          >
            <mat-icon color="#000000">content_copy</mat-icon>
          </button>
        </div>
      </div>
    </div>
    <div class="field-row" *ngIf="key">
      <div class="field-roles-container">
        <label class="field-title"
          >Below is your Api Key. Copy the value and securely store it in a safe
          place. The Api Key cannot be retrieved once this window is
          closed.</label
        >
        <div class="field-roles-checkboxes-row">
          <input
            type="text"
            size="50"
            maxlength="50"
            readonly
            value="{{ key }}"
          />
          <button
            data-testid="viewKey"
            class="tool-btn"
            matTooltip="Copy"
            size="50"
            (click)="copyToClipboard(key)"
          >
            <mat-icon color="#000000">content_copy</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
  <app-message-box></app-message-box>
</div>
