<div class="modal">
  <div mat-dialog-title>
    <h2 class="modal-title">{{ title }}</h2>
    <button
      mat-icon-button
      aria-label="close dialog"
      mat-dialog-close
      class="modal-close-btn"
      (click)="close()"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div mat-dialog-content>
    <form
      ngNativeValidate
      [formGroup]="userEditForm"
      (ngSubmit)="save()"
      id="userEditForm"
    >
      <div class="modal-body">
        <div class="content-container">
          <div class="profile-picture-container">
            <div>
              <img
                mat-card-avatar
                class="profile-avatar"
                src="{{ data.userProfileIcon }}"
              />
              <div *ngIf="isUploadingPicture">
                <mat-spinner diameter="20"></mat-spinner>
              </div>
            </div>
            <div>
              <label for="user-profile-pic" class="change-picture-btn">
                Change
              </label>
              <input
                id="user-profile-pic"
                type="file"
                formControlName="photoURL"
                hidden
                (change)="uploadFile($event, data.uid)"
              />
            </div>
          </div>

          <div class="form-container">
            <div class="fields-container">
              <div class="first-name">
                <label class="field-title" for="firstName">
                  FIRST NAME
                  <p>
                    <input
                      name="firstName"
                      type="text"
                      formControlName="firstName"
                      class="app-input"
                      [ngClass]="{
                        'app-input-error': checkError('firstName', 'required'),
                      }"
                    />
                  </p>
                </label>
                <mat-error *ngIf="checkError('firstName', 'required')"
                  >First Name is required</mat-error
                >
              </div>

              <div class="last-name">
                <label class="field-title" for="lastName">
                  LAST NAME
                  <p>
                    <input
                      name="lastName"
                      type="text"
                      formControlName="lastName"
                      class="app-input"
                      [ngClass]="{
                        'app-input-error': checkError('lastName', 'required'),
                      }"
                    />
                  </p>
                </label>
                <mat-error *ngIf="checkError('lastName', 'required')"
                  >Last Name is required</mat-error
                >
              </div>
            </div>

            <div class="locked-info">
              <div class="locked-info-title-container">
                <mat-icon>lock</mat-icon>
                <span class="locked-info-title"
                  >Locked Personal Information</span
                >
              </div>

              <div class="locked-info-container">
                <div>
                  <span class="field-title">EMAIL</span>
                  <p class="field-data">{{ data.email }}</p>
                </div>

                <div>
                  <span class="field-title">ROLE</span>
                  <p class="field-data">{{ data.roles }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
    <app-message-box></app-message-box>
  </div>

  <div mat-dialog-actions>
    <button
      mat-flat-button
      color="primary"
      type="submit"
      form="userEditForm"
      [disabled]="!userEditForm.valid || isLoading"
      data-testid="saveBtn"
    >
      {{ isLoading ? null : 'Save' }}
      <span *ngIf="isLoading" class="spinner">
        <mat-spinner diameter="24"></mat-spinner>
      </span>
    </button>
  </div>
</div>
