<div class="modal">
  <div mat-dialog-title>
    <h2 class="modal-title">{{ title }}</h2>
    <button
      mat-icon-button
      aria-label="close dialog"
      mat-dialog-close
      class="modal-close-btn"
      (click)="close()"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div mat-dialog-content>
    <label class="pass-label" for="phone">Active phone numbers</label>
    <div class="modal-body">
      <div class="factor-container">
        <p
          class
          class="factor"
          *ngFor="let multiFactorInfo of multiFactorInfoList"
        >
          {{ multiFactorInfo.phoneNumber }} -
          {{ multiFactorInfo.enrollmentTime }}
          <button
            mat-flat-button
            class="disable"
            color="primary"
            [disabled]="assignedMultiFactorCount < 2"
            (click)="disableMultiFactor(multiFactorInfo)"
          >
            Remove
          </button>
        </p>
        <br />
      </div>
    </div>

    <form ngNativeValidate [formGroup]="mfaForm" id="mfaForm">
      <label class="pass-label" for="phone"
        >Add a new phone number<br /><i
          >Enter phone number starting with + and country code.</i
        ></label
      >
      <div class="modal-body">
        <div class="input-container">
          <label class="pass-label" for="phone">Phone Number</label>
          <p>
            <input
              class="tel-input"
              type="tel"
              name="phoneNumber"
              formControlName="phoneNumber"
              required
              [minLength]="8"
              size="15"
              data-testid="phoneNumber"
              matInput
              placeholder="+15555551234"
            />
          </p>
          <mat-error class="error" *ngIf="!checkPhoneNumber()"
            >Invalid phone number, are you missing the + sign or the country
            code?</mat-error
          >
        </div>

        <div class="input-container">
          <label class="pass-label" for="code">Input code</label>
          <p>
            <input
              class="code-input"
              type="text"
              name="codeVerification"
              formControlName="codeVerification"
              [minLength]="6"
              [maxLength]="6"
              size="6"
              data-testid="codeVerification"
            />
          </p>
        </div>
      </div>
      <app-message-box></app-message-box>
    </form>
  </div>

  <div mat-dialog-actions>
    <div id="sign-in-button"></div>
    <button
      mat-flat-button
      color="primary"
      (click)="sendLoginCode()"
      form="mfaForm"
      *ngIf="!smsSent"
      [disabled]="!checkPhoneNumber()"
    >
      {{ isLoading ? null : 'Send Code' }}
      <span *ngIf="isLoading" class="spinner">
        <mat-spinner diameter="24"></mat-spinner>
      </span>
    </button>
    <button
      mat-flat-button
      class="disable"
      color="primary"
      (click)="verifyLoginCode()"
      form="mfaForm"
      *ngIf="smsSent"
    >
      {{ isLoading ? null : 'Enable Two-Factor' }}
      <span *ngIf="isLoading" class="spinner">
        <mat-spinner diameter="24"></mat-spinner>
      </span>
    </button>
  </div>
  <div id="recaptcha-container"></div>
</div>
