import { Injectable } from '@angular/core';

/**
 * Type for log levels.
 */
export type LogType = 'INFO' | 'ERROR';

/**
 * Service for logging information and errors.
 */
@Injectable({
  providedIn: 'root',
})
export class LoggerService {
  /**
   * Logs a message with the specified log type.
   *
   * @param type - The type of log ('INFO' or 'ERROR').
   * @param message - The message to log.
   * @param optional - Optional additional parameters to log.
   */
  private log(type: LogType, message: string, ...optional: any[]) {
    const dt = new Date().toLocaleString();

    const infoLog = () => {
      // nosemgrep: javascript.lang.security.audit.unsafe-formatstring.unsafe-formatstring
      console.info(`[%s] - INFO - %s`, dt, message, ...optional);
    };

    switch (type) {
      case 'INFO': {
        infoLog();
        break;
      }
      case 'ERROR': {
        // nosemgrep: javascript.lang.security.audit.unsafe-formatstring.unsafe-formatstring
        console.error(`[%s] - ERROR - %s`, dt, message, ...optional);
        break;
      }
      default: {
        infoLog();
      }
    }
  }

  /**
   * Logs an informational message.
   *
   * @param message - The message to log.
   * @param optional - Optional additional parameters to log.
   */
  public info(message: string, ...optional: any[]) {
    this.log('INFO', message, ...optional);
  }

  /**
   * Logs an error message.
   *
   * @param message - The message to log.
   * @param optional - Optional additional parameters to log.
   */
  public error(message: string, ...optional: any[]) {
    this.log('ERROR', message, ...optional);
  }
}
