<app-container>
  <app-page-header
    title="Job Activity"
    lastUpdated="{{ lastUpdate }}"
  ></app-page-header>
  <section [hidden]="isLoading" class="job-table-container" tabindex="0">
    <!--Todo: Make a component out of this, also used in approvals-->
    <div class="binary-type">
      <mat-form-field class="mat-select" *ngIf="allowedBinaryTypes!">
        <mat-label>Binary Type</mat-label>
        <mat-select
          matInput
          required
          placeholder="Select"
          formControlName="binaryTypeSelect"
          (selectionChange)="onFilterByBinaryType($event.value)"
        >
          <mat-option value="">All</mat-option>
          <mat-option
            *ngFor="let binaryTypeInfo of binaryTypeInfos"
            value="{{ binaryTypeInfo.binaryType }}"
            >{{ binaryTypeInfo.name }}</mat-option
          >
        </mat-select>
      </mat-form-field>

      <mat-form-field class="mat-select">
        <mat-label>Job State</mat-label>
        <mat-select
          matInput
          required
          placeholder="Select"
          formControlName="jobStateSelect"
          (selectionChange)="onFilterByJobState($event.value)"
        >
          <p>Job State:</p>
          <mat-option value="">All</mat-option>
          <mat-option
            *ngFor="let jobState of jobStates"
            value="{{ jobState.id }}"
            >{{ jobState.name }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </div>

    <table
      mat-table
      [dataSource]="dataSource"
      matSort
      class="table-data"
      (matSortChange)="announceSortChange($event)"
    >
      <ng-container matColumnDef="JobId">
        <th class="row-header" mat-header-cell *matHeaderCellDef>Job ID</th>
        <td mat-cell *matCellDef="let change">
          {{ change.JobId }}
        </td>
      </ng-container>

      <ng-container matColumnDef="BinaryType">
        <th
          mat-header-cell
          *matHeaderCellDef
          class="row-header"
          mat-sort-header
          sortActionDescription="Sort by Binary Type"
        >
          Binary Type
        </th>
        <td mat-cell class="cell-center" *matCellDef="let change">
          <span class="binary-type-column">
            {{ change.BinaryType }}
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="BinaryVersion">
        <th
          mat-header-cell
          *matHeaderCellDef
          class="row-header"
          mat-sort-header
          sortActionDescription="Sort by Binary Version"
        >
          Binary Version
        </th>
        <td mat-cell class="cell-center" *matCellDef="let change">
          <span class="binary-version-column">
            {{ change.BinaryVersion }}
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="Description">
        <th
          mat-header-cell
          *matHeaderCellDef
          class="row-header"
          mat-sort-header
          sortActionDescription="Sort by Description"
        >
          Description
        </th>
        <td mat-cell *matCellDef="let change">{{ change.Description }}</td>
      </ng-container>

      <ng-container matColumnDef="Status">
        <th
          mat-header-cell
          *matHeaderCellDef
          class="row-header"
          mat-sort-header
          sortActionDescription="Sort by Status"
        >
          Status
        </th>
        <td mat-cell class="cell-center" *matCellDef="let change">
          {{ change.Status }}
        </td>
      </ng-container>

      <ng-container matColumnDef="StartTime">
        <th
          mat-header-cell
          *matHeaderCellDef
          class="date-row-header"
          mat-sort-header
          sortActionDescription="Sort by start time"
        >
          Start Time
        </th>
        <td mat-cell *matCellDef="let change" class="cell-center">
          {{ change.StartTime }}
        </td>
      </ng-container>

      <ng-container matColumnDef="CompleteTime">
        <th
          mat-header-cell
          *matHeaderCellDef
          class="date-row-header"
          mat-sort-header
          sortActionDescription="Sort by complete date"
        >
          Complete Time
        </th>
        <td mat-cell *matCellDef="let change" class="cell-center">
          {{ change.CompleteTime }}
        </td>
      </ng-container>

      <ng-container matColumnDef="Error">
        <th mat-header-cell *matHeaderCellDef class="row-header">Error</th>
        <td mat-cell *matCellDef="let change">
          {{ change.Error }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: columnsToDisplay"
        class="table-row"
      ></tr>
    </table>
  </section>

  <div class="footer">
    <div *ngIf="isLoading" class="loader">
      <mat-spinner [diameter]="dataSource.data.length ? 24 : 42"></mat-spinner>
    </div>
    <mat-paginator
      *ngIf="(!isLoading || dataSource.data.length) && totalRecords > pageSize"
      [length]="totalRecords"
      [pageSize]="pageSize"
      [pageIndex]="currentPageIndex"
      (page)="onPageChange($event, selectedBinaryType, selectedJobState)"
      [disabled]="isLoading"
    ></mat-paginator>
  </div>
</app-container>
