import { Injectable } from '@angular/core';
import { FirebaseApp } from '@angular/fire/app';
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from '@angular/fire/compat/firestore';
import { getDownloadURL, ref } from '@angular/fire/storage';
import { getStorage } from 'firebase/storage';

import { DocumentType } from '../../types/document';
import { BinaryTypeService } from './binary-type.service';
import {
  BinaryType,
  BinaryTypeInfo,
} from './generated/src/main/proto/storage/binary-type.pb';
import { LoggerService } from './logger.service';

/**
 * Service to manage source code files, including retrieving signed URLs, caching, and verifying existence.
 */
@Injectable({
  providedIn: 'root',
})
export class SourceCodeService {
  binaryTypeInfos: BinaryTypeInfo[] | undefined;
  docsRef: AngularFirestoreCollection<DocumentType>;

  constructor(
    public afs: AngularFirestore,
    private binaryTypeService: BinaryTypeService,
    public firebaseApp: FirebaseApp,
    private logger: LoggerService
  ) {
    this.docsRef = this.afs.collection('source');
    this.loadBinaryTypes();
  }

  /**
   * Retrieves a signed URL for the given path.
   *
   * @param path - The path to retrieve the signed URL for.
   * @returns A promise that resolves to the signed URL.
   */
  public getSignedURL(path: string) {
    return getDownloadURL(ref(getStorage(), path));
  }

  /**
   * Generates a cache key for storing the signed URL of a source file.
   *
   * @param binaryType - The binary type of the source file.
   * @param version - The version of the source file.
   * @returns The generated cache key.
   */
  getCacheKey(binaryType: BinaryType, version: string) {
    return `source-file-cache_${binaryType.toString()}-${version}`;
  }

  /**
   * Retrieves the cached signed URL for a source file if available; otherwise, fetches a new URL and caches it.
   *
   * @param path - The path to retrieve the signed URL for.
   * @param version - The version of the source file.
   * @param binaryType - The binary type of the source file.
   * @returns A promise that resolves to the signed URL.
   */
  public getCachedSignedURL(
    path: string,
    version: string,
    binaryType: BinaryType
  ) {
    const cachedPath = localStorage.getItem(
      this.getCacheKey(binaryType, version)
    );
    let response = undefined;
    if (cachedPath) {
      response = Promise.resolve(cachedPath);
    } else {
      response = this.getSignedURL(path).then((path) => {
        localStorage.setItem(this.getCacheKey(binaryType, version), path);
      });
    }
    return response;
  }

  /**
   * Checks if a source file exists for the given binary type and version.
   *
   * @param binaryType - The binary type of the source file.
   * @param version - The version of the source file.
   * @returns A promise that resolves to the signed URL if the source file exists, otherwise rejects with an error message.
   */
  public sourceFileExists(
    binaryType: BinaryType | undefined,
    version: string | undefined
  ) {
    if (version && binaryType) {
      return this.getCachedSignedURL(
        `source/${this.getBinaryTypePathName(
          binaryType
        )}/${version}/source.zip`,
        version,
        binaryType
      );
    } else {
      return new Promise((reject) => {
        return reject('No binary type or version specified');
      });
    }
  }

  /**
   * Loads the binary types from the BinaryTypeService.
   */
  loadBinaryTypes() {
    this.binaryTypeService.getBinaryTypes().then((response) => {
      this.binaryTypeInfos = response.binaryTypeInfos;
    });
  }

  /**
   * Retrieves the path name for a given binary type.
   *
   * @param binaryType - The binary type to retrieve the path name for.
   * @returns The path name of the binary type.
   */
  getBinaryTypePathName(binaryType: BinaryType) {
    return this.binaryTypeInfos
      ?.find(
        (binaryTypeInfo: BinaryTypeInfo) =>
          binaryTypeInfo.binaryType === binaryType
      )
      ?.name.toLowerCase()
      .replace(' ', '_');
  }

  /**
   * Retrieves the signed URL for downloading the source file of a given binary type and version.
   *
   * @param binaryType - The binary type of the source file.
   * @param version - The version of the source file.
   * @returns A promise that resolves to the signed URL for the source file.
   */
  async downloadSourceFileLink(
    binaryType: BinaryType | undefined,
    version: string | undefined
  ) {
    if (version && binaryType) {
      return this.getCachedSignedURL(
        `source/${this.getBinaryTypePathName(
          binaryType
        )}/${version}/source.zip`,
        version,
        binaryType
      );
    } else {
      this.logger.error('Binary Type and Version should not be empty');
      return undefined;
    }
  }

  /**
   * Retrieves the signed URL for the release notes of a given binary type and version.
   *
   * @param binaryType - The binary type of the release notes.
   * @param version - The version of the release notes.
   * @returns A promise that resolves to the signed URL for the release notes.
   */
  async getReleaseNotesLink(
    binaryType: BinaryType | undefined,
    version: string | undefined
  ) {
    if (version && binaryType) {
      return await this.getSignedURL(
        `source/${this.getBinaryTypePathName(
          binaryType
        )}/${version}/CHANGELOG.md`
      );
    } else {
      return undefined;
    }
  }
}
