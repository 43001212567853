import { Injectable } from '@angular/core';
import { Auth, User } from '@angular/fire/auth';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';

import { ApiAuthService } from './api-auth.service';
import {
  GetKeyRequest,
  GetKeyResponse,
  GetPublicKeyRequest,
  GetPublicKeyResponse,
  ListAllKeysRequest,
  ListAllKeysResponse,
  ListKeyNamesRequest,
  ListKeyNamesResponse,
  ListKeysRequest,
  ListKeysResponse,
  RegisterKeyRequest,
  RegisterKeyResponse,
  RotateKeyRequest,
  RotateKeyResponse,
  UnregisterKeyRequest,
  UnregisterKeyResponse,
  UpdateKeyRequest,
  UpdateKeyResponse,
} from './generated/src/main/proto/api/key-service.pb';
import { KeyServiceClient } from './generated/src/main/proto/api/key-service.pbsc';
import { KeyConfig } from './generated/src/main/proto/storage/key-config.pb';

/**
 * Service to manage key configurations and public keys.
 */
@Injectable({
  providedIn: 'root',
})
export class KeyService {
  private currentUser: User | undefined;
  private tenantId: string | undefined;

  constructor(
    private auth: Auth,
    private keyServiceClient: KeyServiceClient,
    public apiAuthService: ApiAuthService,
    private analytics: AngularFireAnalytics,
    public router: Router
  ) {
    this.auth.onAuthStateChanged((user) => {
      if (user) {
        this.currentUser = user;
        this.tenantId = this.currentUser.tenantId!;
      }
    });
  }

  /**
   * Retrieves the public key for the given key name in Anonym's HSM.
   *
   * @param keyId - The ID of the key.
   * @param version - The version of the key (optional).
   * @returns A promise that resolves to a GetPublicKeyResponse containing the public key.
   */
  public async getPublicKey(
    keyId: string,
    version?: string
  ): Promise<GetPublicKeyResponse> {
    const grpcMetaData =
      await this.apiAuthService.getAuthenticatedRequestHeader();
    const request = new GetPublicKeyRequest();
    request.keyId = keyId;
    if (version) {
      request.keyVersion = version;
    }
    this.analytics.logEvent('load-public-key', {
      name: keyId,
    });
    return firstValueFrom(
      this.keyServiceClient.getPublicKey(request, grpcMetaData)
    );
  }

  /**
   * Registers a key with the provided configuration.
   *
   * @param keyConfig - The configuration of the key to register.
   * @returns A promise that resolves to a RegisterKeyResponse containing the result of the registration.
   */
  public async registerKey(keyConfig: KeyConfig): Promise<RegisterKeyResponse> {
    const grpcMetaData =
      await this.apiAuthService.getAuthenticatedRequestHeader();
    const request = new RegisterKeyRequest();
    request.keyConfig = keyConfig;
    this.analytics.logEvent('register-key', {
      tenantId: this.tenantId,
      keyId: keyConfig.id,
    });
    return firstValueFrom(
      this.keyServiceClient.registerKey(request, grpcMetaData)
    );
  }

  /**
   * Unregisters a key.
   *
   * @param customerId - The ID of the customer.
   * @param keyId - The ID of the key to unregister.
   * @returns A promise that resolves to an UnregisterKeyResponse containing the result of the unregistration.
   */
  public async unregisterKey(
    customerId: string,
    keyId: string
  ): Promise<UnregisterKeyResponse> {
    const grpcMetaData =
      await this.apiAuthService.getAuthenticatedRequestHeader();
    const request = new UnregisterKeyRequest();
    request.customerId = customerId;
    request.keyId = keyId;
    this.analytics.logEvent('unregister-key', {
      tenantId: this.tenantId,
      keyId: keyId,
    });
    return firstValueFrom(
      this.keyServiceClient.unregisterKey(request, grpcMetaData)
    );
  }

  /**
   * Lists keys for the logged-in customer.
   *
   * @returns A promise that resolves to a ListKeysResponse containing the list of keys.
   */
  public async listKeys(): Promise<ListKeysResponse> {
    const grpcMetaData =
      await this.apiAuthService.getAuthenticatedRequestHeader();
    const request = new ListKeysRequest();
    this.analytics.logEvent('list-keys', {
      tenantId: this.tenantId,
    });
    return firstValueFrom(
      this.keyServiceClient.listKeys(request, grpcMetaData)
    );
  }

  /**
   * Lists keys for all customers.
   *
   * @returns A promise that resolves to a ListAllKeysResponse containing the list of all keys.
   */
  public async listAllKeys(
    customerIds: string[] = []
  ): Promise<ListAllKeysResponse> {
    const grpcMetaData =
      await this.apiAuthService.getAuthenticatedRequestHeader();

    const request = new ListAllKeysRequest();
    if (customerIds.length > 0) {
      request.customerIds = customerIds;
    }

    this.analytics.logEvent('list-all-keys', {
      tenantId: this.tenantId,
    });
    return firstValueFrom(
      this.keyServiceClient.listAllKeys(request, grpcMetaData)
    );
  }

  /**
   * Lists key names and versions for all customers, for use in JobCreator.
   *
   * @param customerIds - The list of customer IDs to list key names for.
   * @returns A promise that resolves to a ListKeyNamesResponse containing the list of key names and versions.
   */
  public async listKeyNames(
    customerIds: string[]
  ): Promise<ListKeyNamesResponse> {
    const grpcMetaData =
      await this.apiAuthService.getAuthenticatedRequestHeader();
    const request = new ListKeyNamesRequest();
    request.customerIds = customerIds;
    this.analytics.logEvent('list-key-names');
    return firstValueFrom(
      this.keyServiceClient.listKeyNames(request, grpcMetaData)
    );
  }

  /**
   * Retrieves a key by its ID.
   *
   * @param id - The ID of the key to retrieve.
   * @returns A promise that resolves to a GetKeyResponse containing the key details.
   */
  public async getKey(id: string): Promise<GetKeyResponse> {
    const grpcMetaData =
      await this.apiAuthService.getAuthenticatedRequestHeader();
    const request = new GetKeyRequest();
    request.keyId = id;
    this.analytics.logEvent('get-key', { id: id });
    return firstValueFrom(this.keyServiceClient.getKey(request, grpcMetaData));
  }

  /**
   * Updates a key with the provided configuration.
   *
   * @param keyConfig - The configuration of the key to update.
   * @returns A promise that resolves to an UpdateKeyResponse containing the result of the update.
   */
  public async updateKey(keyConfig: KeyConfig): Promise<UpdateKeyResponse> {
    const grpcMetaData =
      await this.apiAuthService.getAuthenticatedRequestHeader();
    const request = new UpdateKeyRequest();
    request.keyConfig = keyConfig;
    if (keyConfig.etag) {
      request.etag = keyConfig.etag; // etag must be set
    }
    this.analytics.logEvent('update-key', { id: keyConfig.id });
    return firstValueFrom(
      this.keyServiceClient.updateKey(request, grpcMetaData)
    );
  }

  /**
   * Rotates a key.
   *
   * @param customerId - The ID of the customer.
   * @param keyId - The ID of the key to rotate.
   * @returns A promise that resolves to a RotateKeyResponse containing the result of the rotation.
   */
  public async rotateKey(
    customerId: string,
    keyId: string
  ): Promise<RotateKeyResponse> {
    const grpcMetaData =
      await this.apiAuthService.getAuthenticatedRequestHeader();
    const request = new RotateKeyRequest();
    request.customerId = customerId;
    request.keyId = keyId;
    this.analytics.logEvent('roate-key', {
      tenantId: this.tenantId,
      keyId: keyId,
    });
    return firstValueFrom(
      this.keyServiceClient.rotateKey(request, grpcMetaData)
    );
  }

  /**
   * Downloads the public key file as a PEM.
   *
   * @param keyId - The ID of the key.
   * @param version - The version of the key.
   */
  public async downloadPublicKey(keyId: string, version: string) {
    await this.getPublicKey(keyId, version).then((r) => {
      const a = document.createElement('a');
      const file = new Blob([this.createPem(r.keyDerValue)], {
        type: 'text/plain',
      });
      a.href = URL.createObjectURL(file);
      a.download = `${keyId.toLowerCase()}.pem`;
      a.click();
    });
  }

  /**
   * Retrieves the public key as a PEM-formatted string.
   *
   * @param keyId - The ID of the key.
   * @param version - The version of the key.
   * @returns A promise that resolves to a string containing the PEM-formatted public key.
   * @throws An error if fetching the public key fails.
   */
  public async getPublicKeyPem(
    keyId: string,
    version: string
  ): Promise<string> {
    try {
      const publicKeyResponse = await this.getPublicKey(keyId, version);
      return this.createPem(publicKeyResponse.keyDerValue);
    } catch (error) {
      console.error('Error fetching public key:', error);
      throw error;
    }
  }

  /**
   * Creates a PEM-formatted string from a DER-encoded key.
   *
   * @param derValue - The DER-encoded key.
   * @returns A PEM-formatted string.
   */
  private createPem(derValue: string): string {
    derValue = derValue.replace(/\n/g, '');
    return `-----BEGIN PUBLIC KEY-----\n${derValue
      .match(/.{1,64}/g)
      ?.join('\n')}\n-----END PUBLIC KEY-----`;
  }
}
