/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import {
  GrpcMessage,
  RecursivePartial,
  ToProtobufJSONOptions,
} from '@ngx-grpc/common';
import { BinaryReader, BinaryWriter, ByteSource } from 'google-protobuf';
import * as api001 from '../../../../src/main/proto/api/headers.pb';
import * as storage002 from '../../../../src/main/proto/storage/notification.pb';
/**
 * Message implementation for api.notification.CreateRequest
 */
export class CreateRequest implements GrpcMessage {
  static id = 'api.notification.CreateRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new CreateRequest();
    CreateRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: CreateRequest) {
    _instance.header = _instance.header || undefined;
    _instance.notifications = _instance.notifications || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: CreateRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api001.RequestHeader();
          _reader.readMessage(
            _instance.header,
            api001.RequestHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          const messageInitializer2 = new storage002.Notification();
          _reader.readMessage(
            messageInitializer2,
            storage002.Notification.deserializeBinaryFromReader
          );
          (_instance.notifications = _instance.notifications || []).push(
            messageInitializer2
          );
          break;
        default:
          _reader.skipField();
      }
    }

    CreateRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: CreateRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api001.RequestHeader.serializeBinaryToWriter
      );
    }
    if (_instance.notifications && _instance.notifications.length) {
      _writer.writeRepeatedMessage(
        2,
        _instance.notifications as any,
        storage002.Notification.serializeBinaryToWriter
      );
    }
  }

  private _header?: api001.RequestHeader;
  private _notifications?: storage002.Notification[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of CreateRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<CreateRequest.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api001.RequestHeader(_value.header)
      : undefined;
    this.notifications = (_value.notifications || []).map(
      (m) => new storage002.Notification(m)
    );
    CreateRequest.refineValues(this);
  }
  get header(): api001.RequestHeader | undefined {
    return this._header;
  }
  set header(value: api001.RequestHeader | undefined) {
    this._header = value;
  }
  get notifications(): storage002.Notification[] | undefined {
    return this._notifications;
  }
  set notifications(value: storage002.Notification[] | undefined) {
    this._notifications = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    CreateRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): CreateRequest.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      notifications: (this.notifications || []).map((m) => m.toObject()),
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): CreateRequest.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      notifications: (this.notifications || []).map((m) =>
        m.toProtobufJSON(options)
      ),
    };
  }
}
export module CreateRequest {
  /**
   * Standard JavaScript object representation for CreateRequest
   */
  export interface AsObject {
    header?: api001.RequestHeader.AsObject;
    notifications?: storage002.Notification.AsObject[];
  }

  /**
   * Protobuf JSON representation for CreateRequest
   */
  export interface AsProtobufJSON {
    header: api001.RequestHeader.AsProtobufJSON | null;
    notifications: storage002.Notification.AsProtobufJSON[] | null;
  }
}

/**
 * Message implementation for api.notification.CreateResponse
 */
export class CreateResponse implements GrpcMessage {
  static id = 'api.notification.CreateResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new CreateResponse();
    CreateResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: CreateResponse) {
    _instance.header = _instance.header || undefined;
    _instance.notificationResult = _instance.notificationResult || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: CreateResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api001.ResponseHeader();
          _reader.readMessage(
            _instance.header,
            api001.ResponseHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          const messageInitializer2 = new storage002.NotificationResult();
          _reader.readMessage(
            messageInitializer2,
            storage002.NotificationResult.deserializeBinaryFromReader
          );
          (_instance.notificationResult =
            _instance.notificationResult || []).push(messageInitializer2);
          break;
        default:
          _reader.skipField();
      }
    }

    CreateResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: CreateResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api001.ResponseHeader.serializeBinaryToWriter
      );
    }
    if (_instance.notificationResult && _instance.notificationResult.length) {
      _writer.writeRepeatedMessage(
        2,
        _instance.notificationResult as any,
        storage002.NotificationResult.serializeBinaryToWriter
      );
    }
  }

  private _header?: api001.ResponseHeader;
  private _notificationResult?: storage002.NotificationResult[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of CreateResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<CreateResponse.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api001.ResponseHeader(_value.header)
      : undefined;
    this.notificationResult = (_value.notificationResult || []).map(
      (m) => new storage002.NotificationResult(m)
    );
    CreateResponse.refineValues(this);
  }
  get header(): api001.ResponseHeader | undefined {
    return this._header;
  }
  set header(value: api001.ResponseHeader | undefined) {
    this._header = value;
  }
  get notificationResult(): storage002.NotificationResult[] | undefined {
    return this._notificationResult;
  }
  set notificationResult(value: storage002.NotificationResult[] | undefined) {
    this._notificationResult = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    CreateResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): CreateResponse.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      notificationResult: (this.notificationResult || []).map((m) =>
        m.toObject()
      ),
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): CreateResponse.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      notificationResult: (this.notificationResult || []).map((m) =>
        m.toProtobufJSON(options)
      ),
    };
  }
}
export module CreateResponse {
  /**
   * Standard JavaScript object representation for CreateResponse
   */
  export interface AsObject {
    header?: api001.ResponseHeader.AsObject;
    notificationResult?: storage002.NotificationResult.AsObject[];
  }

  /**
   * Protobuf JSON representation for CreateResponse
   */
  export interface AsProtobufJSON {
    header: api001.ResponseHeader.AsProtobufJSON | null;
    notificationResult: storage002.NotificationResult.AsProtobufJSON[] | null;
  }
}
