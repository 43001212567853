import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';

import { ApiAuthService } from './api-auth.service';
import { GetRequest } from './generated/src/main/proto/api/content-service.pb';
import { ContentServiceClient } from './generated/src/main/proto/api/content-service.pbsc';

/**
 * Service to interact with the content API, providing methods to fetch content.
 */
@Injectable({
  providedIn: 'root',
})
export class ContentService {
  constructor(
    private contentServiceClient: ContentServiceClient,
    public apiAuthService: ApiAuthService
  ) {}

  /**
   * Retrieves content by file name from the API.
   *
   * @param fileName - The name of the file to retrieve.
   * @returns A promise that resolves to the content of the specified file.
   */
  public async getContent(fileName: string) {
    const grpcMetaData =
      await this.apiAuthService.getAuthenticatedRequestHeader();

    const contentRequest = new GetRequest();
    contentRequest.fileName = fileName;

    return firstValueFrom(
      this.contentServiceClient.get(contentRequest, grpcMetaData)
    );
  }
}
