<app-container>
  <app-page-header title="Storage Manager" lastUpdated="{{ lastUpdate }}">
  </app-page-header>
  <app-message-box></app-message-box>

  <app-customer-dropdown
    [showArchiveToggle]="true"
    [showSearchBox]="true"
    (selectCustomerEvent)="onCustomerChange($event)"
  ></app-customer-dropdown>

  <section class="data-table-container" tabindex="0">
    <div class="data-table-title-container">
      <h2 class="data-users-title">Customer Data</h2>
      <button class="add-user-button" (click)="createStorageCredential()">
        Add Storage Credential
      </button>
    </div>

    <table mat-table [dataSource]="dataSource" class="table-data" matSort>
      <ng-container matColumnDef="ID">
        <th class="row-header" mat-header-cell *matHeaderCellDef>ID</th>
        <td mat-cell *matCellDef="let element">
          <div class="user-name-col">
            <a
              class="storage-credential-id"
              (click)="editStorageCredential(element)"
              >{{ element.id }}</a
            >
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="customerId">
        <th
          class="row-header"
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          sortActionDescription="Sort by customer"
        >
          Customer ID
        </th>
        <td mat-cell *matCellDef="let element">{{ element.customerId }}</td>
      </ng-container>

      <ng-container matColumnDef="credentialInfo">
        <th
          class="row-header"
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
        >
          Credential Info
        </th>
        <td mat-cell *matCellDef="let element">
          {{
            element.awsCredential?.accessKeyId ||
              element.awsAssumeRole?.roleArn ||
              'N/A'
          }}
        </td>
      </ng-container>

      <ng-container matColumnDef="lastUpdate">
        <th
          class="row-header"
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          sortActionDescription="Sort by last update"
        >
          Last Updated
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.lastUpdate.seconds * 1000 | date: 'medium' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="menu">
        <th class="row-header" mat-header-cell *matHeaderCellDef>Actions</th>
        <td mat-cell *matCellDef="let element">
          <div class="user-name-col">
            <button mat-icon-button [matMenuTriggerFor]="actionsMenu">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #actionsMenu>
              <button mat-menu-item (click)="archive(element.id)">
                <mat-icon>delete</mat-icon>
                <span>Archive</span>
              </button>
            </mat-menu>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: columnsToDisplay"
        class="table-row"
      ></tr>
    </table>
  </section>

  <div class="footer">
    <div *ngIf="isLoading" class="loader">
      <mat-spinner [diameter]="dataSource.data.length ? 24 : 42"></mat-spinner>
    </div>
    <mat-paginator
      *ngIf="(!isLoading || dataSource.data.length) && totalRecords > pageSize"
      [length]="totalRecords"
      [pageSize]="pageSize"
      [pageIndex]="currentPageIndex"
      (page)="onPageChange($event)"
      [disabled]="isLoading"
    ></mat-paginator>
  </div>
</app-container>
