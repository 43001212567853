/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import { Inject, Injectable, Optional } from '@angular/core';
import {
  GrpcCallType,
  GrpcClient,
  GrpcClientFactory,
  GrpcEvent,
  GrpcMetadata,
} from '@ngx-grpc/common';
import {
  GRPC_CLIENT_FACTORY,
  GrpcHandler,
  takeMessages,
  throwStatusErrors,
} from '@ngx-grpc/core';
import { Observable } from 'rxjs';
import * as thisProto from './approval-service.pb';
import * as googleProtobuf000 from '@ngx-grpc/well-known-types';
import * as storage001 from '../../../../src/main/proto/storage/binary-type.pb';
import * as storage002 from '../../../../src/main/proto/storage/schema-annotations.pb';
import * as api003 from '../../../../src/main/proto/api/headers.pb';
import * as api004 from '../../../../src/main/proto/api/pagination.pb';
import * as storage005 from '../../../../src/main/proto/storage/approval.pb';
import { GRPC_APPROVAL_SERVICE_CLIENT_SETTINGS } from './approval-service.pbconf';
/**
 * Service client implementation for api.approval.ApprovalService
 */
@Injectable({ providedIn: 'any' })
export class ApprovalServiceClient {
  private client: GrpcClient<any>;

  /**
   * Raw RPC implementation for each service client method.
   * The raw methods provide more control on the incoming data and events. E.g. they can be useful to read status `OK` metadata.
   * Attention: these methods do not throw errors when non-zero status codes are received.
   */
  $raw = {
    /**
     * Unary call: /api.approval.ApprovalService/Create
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.CreateResponse>>
     */
    create: (
      requestData: thisProto.CreateRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.CreateResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/api.approval.ApprovalService/Create',
        requestData,
        requestMetadata,
        requestClass: thisProto.CreateRequest,
        responseClass: thisProto.CreateResponse,
      });
    },
    /**
     * Unary call: /api.approval.ApprovalService/UpdateViewers
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.UpdateViewersResponse>>
     */
    updateViewers: (
      requestData: thisProto.UpdateViewersRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.UpdateViewersResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/api.approval.ApprovalService/UpdateViewers',
        requestData,
        requestMetadata,
        requestClass: thisProto.UpdateViewersRequest,
        responseClass: thisProto.UpdateViewersResponse,
      });
    },
    /**
     * Unary call: /api.approval.ApprovalService/Get
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.GetResponse>>
     */
    get: (
      requestData: thisProto.GetRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.GetResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/api.approval.ApprovalService/Get',
        requestData,
        requestMetadata,
        requestClass: thisProto.GetRequest,
        responseClass: thisProto.GetResponse,
      });
    },
    /**
     * Unary call: /api.approval.ApprovalService/GetAllCustomers
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.GetAllCustomersResponse>>
     */
    getAllCustomers: (
      requestData: thisProto.GetRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.GetAllCustomersResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/api.approval.ApprovalService/GetAllCustomers',
        requestData,
        requestMetadata,
        requestClass: thisProto.GetRequest,
        responseClass: thisProto.GetAllCustomersResponse,
      });
    },
    /**
     * Unary call: /api.approval.ApprovalService/GetApprovedBinaries
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.GetApprovedBinariesResponse>>
     */
    getApprovedBinaries: (
      requestData: thisProto.GetApprovedBinariesRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.GetApprovedBinariesResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/api.approval.ApprovalService/GetApprovedBinaries',
        requestData,
        requestMetadata,
        requestClass: thisProto.GetApprovedBinariesRequest,
        responseClass: thisProto.GetApprovedBinariesResponse,
      });
    },
    /**
     * Unary call: /api.approval.ApprovalService/AddDecision
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.AddDecisionResponse>>
     */
    addDecision: (
      requestData: thisProto.AddDecisionRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.AddDecisionResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/api.approval.ApprovalService/AddDecision',
        requestData,
        requestMetadata,
        requestClass: thisProto.AddDecisionRequest,
        responseClass: thisProto.AddDecisionResponse,
      });
    },
    /**
     * Unary call: /api.approval.ApprovalService/AddDecisionForCustomers
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.AddDecisionResponse>>
     */
    addDecisionForCustomers: (
      requestData: thisProto.AddDecisionForCustomersRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.AddDecisionResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/api.approval.ApprovalService/AddDecisionForCustomers',
        requestData,
        requestMetadata,
        requestClass: thisProto.AddDecisionForCustomersRequest,
        responseClass: thisProto.AddDecisionResponse,
      });
    },
    /**
     * Unary call: /api.approval.ApprovalService/SetBinaryState
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.SetBinaryStateResponse>>
     */
    setBinaryState: (
      requestData: thisProto.SetBinaryStateRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.SetBinaryStateResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/api.approval.ApprovalService/SetBinaryState',
        requestData,
        requestMetadata,
        requestClass: thisProto.SetBinaryStateRequest,
        responseClass: thisProto.SetBinaryStateResponse,
      });
    },
    /**
     * Unary call: /api.approval.ApprovalService/SetBinaryStateForCustomers
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.SetBinaryStateResponse>>
     */
    setBinaryStateForCustomers: (
      requestData: thisProto.SetBinaryStateForCustomersRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.SetBinaryStateResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/api.approval.ApprovalService/SetBinaryStateForCustomers',
        requestData,
        requestMetadata,
        requestClass: thisProto.SetBinaryStateForCustomersRequest,
        responseClass: thisProto.SetBinaryStateResponse,
      });
    },
    /**
     * Unary call: /api.approval.ApprovalService/AddAttestationPolicyState
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.AddAttestationPolicyStateResponse>>
     */
    addAttestationPolicyState: (
      requestData: thisProto.AddAttestationPolicyStateRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.AddAttestationPolicyStateResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/api.approval.ApprovalService/AddAttestationPolicyState',
        requestData,
        requestMetadata,
        requestClass: thisProto.AddAttestationPolicyStateRequest,
        responseClass: thisProto.AddAttestationPolicyStateResponse,
      });
    },
    /**
     * Unary call: /api.approval.ApprovalService/Archive
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.ArchiveResponse>>
     */
    archive: (
      requestData: thisProto.ArchiveRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.ArchiveResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/api.approval.ApprovalService/Archive',
        requestData,
        requestMetadata,
        requestClass: thisProto.ArchiveRequest,
        responseClass: thisProto.ArchiveResponse,
      });
    },
    /**
     * Unary call: /api.approval.ApprovalService/AddDecisionTest
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.AddDecisionResponse>>
     */
    addDecisionTest: (
      requestData: thisProto.AddDecisionRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.AddDecisionResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/api.approval.ApprovalService/AddDecisionTest',
        requestData,
        requestMetadata,
        requestClass: thisProto.AddDecisionRequest,
        responseClass: thisProto.AddDecisionResponse,
      });
    },
    /**
     * Unary call: /api.approval.ApprovalService/SetBinaryStateTest
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.SetBinaryStateResponse>>
     */
    setBinaryStateTest: (
      requestData: thisProto.SetBinaryStateRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.SetBinaryStateResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/api.approval.ApprovalService/SetBinaryStateTest',
        requestData,
        requestMetadata,
        requestClass: thisProto.SetBinaryStateRequest,
        responseClass: thisProto.SetBinaryStateResponse,
      });
    },
  };

  constructor(
    @Optional() @Inject(GRPC_APPROVAL_SERVICE_CLIENT_SETTINGS) settings: any,
    @Inject(GRPC_CLIENT_FACTORY) clientFactory: GrpcClientFactory<any>,
    private handler: GrpcHandler
  ) {
    this.client = clientFactory.createClient(
      'api.approval.ApprovalService',
      settings
    );
  }

  /**
   * Unary call @/api.approval.ApprovalService/Create
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.CreateResponse>
   */
  create(
    requestData: thisProto.CreateRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.CreateResponse> {
    return this.$raw
      .create(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/api.approval.ApprovalService/UpdateViewers
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.UpdateViewersResponse>
   */
  updateViewers(
    requestData: thisProto.UpdateViewersRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.UpdateViewersResponse> {
    return this.$raw
      .updateViewers(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/api.approval.ApprovalService/Get
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.GetResponse>
   */
  get(
    requestData: thisProto.GetRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.GetResponse> {
    return this.$raw
      .get(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/api.approval.ApprovalService/GetAllCustomers
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.GetAllCustomersResponse>
   */
  getAllCustomers(
    requestData: thisProto.GetRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.GetAllCustomersResponse> {
    return this.$raw
      .getAllCustomers(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/api.approval.ApprovalService/GetApprovedBinaries
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.GetApprovedBinariesResponse>
   */
  getApprovedBinaries(
    requestData: thisProto.GetApprovedBinariesRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.GetApprovedBinariesResponse> {
    return this.$raw
      .getApprovedBinaries(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/api.approval.ApprovalService/AddDecision
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.AddDecisionResponse>
   */
  addDecision(
    requestData: thisProto.AddDecisionRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.AddDecisionResponse> {
    return this.$raw
      .addDecision(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/api.approval.ApprovalService/AddDecisionForCustomers
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.AddDecisionResponse>
   */
  addDecisionForCustomers(
    requestData: thisProto.AddDecisionForCustomersRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.AddDecisionResponse> {
    return this.$raw
      .addDecisionForCustomers(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/api.approval.ApprovalService/SetBinaryState
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.SetBinaryStateResponse>
   */
  setBinaryState(
    requestData: thisProto.SetBinaryStateRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.SetBinaryStateResponse> {
    return this.$raw
      .setBinaryState(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/api.approval.ApprovalService/SetBinaryStateForCustomers
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.SetBinaryStateResponse>
   */
  setBinaryStateForCustomers(
    requestData: thisProto.SetBinaryStateForCustomersRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.SetBinaryStateResponse> {
    return this.$raw
      .setBinaryStateForCustomers(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/api.approval.ApprovalService/AddAttestationPolicyState
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.AddAttestationPolicyStateResponse>
   */
  addAttestationPolicyState(
    requestData: thisProto.AddAttestationPolicyStateRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.AddAttestationPolicyStateResponse> {
    return this.$raw
      .addAttestationPolicyState(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/api.approval.ApprovalService/Archive
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.ArchiveResponse>
   */
  archive(
    requestData: thisProto.ArchiveRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.ArchiveResponse> {
    return this.$raw
      .archive(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/api.approval.ApprovalService/AddDecisionTest
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.AddDecisionResponse>
   */
  addDecisionTest(
    requestData: thisProto.AddDecisionRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.AddDecisionResponse> {
    return this.$raw
      .addDecisionTest(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/api.approval.ApprovalService/SetBinaryStateTest
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.SetBinaryStateResponse>
   */
  setBinaryStateTest(
    requestData: thisProto.SetBinaryStateRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.SetBinaryStateResponse> {
    return this.$raw
      .setBinaryStateTest(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }
}
