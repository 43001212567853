import { Injectable } from '@angular/core';
import { GrpcMetadata } from '@ngx-grpc/common';
import { firstValueFrom } from 'rxjs';

import { LoggerService } from '../services/logger.service';
import { ApiAuthService } from './api-auth.service';
import {
  ArchiveRequest,
  ArchiveResponse,
  CreateRequest,
  CreateResponse,
  ListRequest,
  ListResponse,
  UpdateRequest,
  UpdateResponse,
} from './generated/src/main/proto/api/customer-storage-credential-service.pb';
import { CustomerStorageCredentialServiceClient } from './generated/src/main/proto/api/customer-storage-credential-service.pbsc';
import { CustomerStorageCredential } from './generated/src/main/proto/storage/customer-storage-credential.pb';

/**
 * Service to manage customer storage credentials, including creating, updating, listing, and archiving.
 */
@Injectable({
  providedIn: 'root',
})
export class CustomerStorageCredentialService {
  constructor(
    private customerStorageCredentialServiceClient: CustomerStorageCredentialServiceClient,
    private apiAuthService: ApiAuthService,
    private logger: LoggerService
  ) {}

  /**
   * Creates a new storage credential for a customer.
   *
   * @param customerStorageCredential - The storage credential to create.
   * @returns A promise that resolves to a CreateResponse containing the result of the operation.
   */
  public createStorageCredential(
    customerStorageCredential: CustomerStorageCredential
  ): Promise<CreateResponse> {
    return new Promise((resolve, reject) => {
      this.apiAuthService
        .getAuthenticatedRequestHeader()
        .then((grpcMetaData: GrpcMetadata) => {
          const createRequest = new CreateRequest();
          createRequest.customerStorageCredential = customerStorageCredential;
          const createResponse = firstValueFrom(
            this.customerStorageCredentialServiceClient.create(
              createRequest,
              grpcMetaData
            )
          );
          createResponse
            .then((value) => {
              return resolve(value);
            })
            .catch((error) => {
              this.logger.error(error);
              return reject(error);
            });
        });
    });
  }

  /**
   * Updates an existing storage credential for a customer.
   *
   * @param customerStorageCredential - The storage credential to update.
   * @returns A promise that resolves to an UpdateResponse containing the result of the operation.
   */
  public updateStorageCredential(
    customerStorageCredential: CustomerStorageCredential
  ): Promise<UpdateResponse> {
    return new Promise((resolve, reject) => {
      this.apiAuthService
        .getAuthenticatedRequestHeader()
        .then((grpcMetaData: GrpcMetadata) => {
          const updateRequest = new UpdateRequest();
          updateRequest.customerStorageCredential = customerStorageCredential;
          updateRequest.etag = customerStorageCredential.etag;
          const updateResponse = firstValueFrom(
            this.customerStorageCredentialServiceClient.update(
              updateRequest,
              grpcMetaData
            )
          );
          updateResponse
            .then((value) => {
              return resolve(value);
            })
            .catch((error) => {
              this.logger.error(error);
              return reject(error);
            });
        });
    });
  }

  /**
   * Lists all storage credentials.
   *
   * @param listRequest - The request parameters for listing storage credentials.
   * @returns A promise that resolves to a ListResponse containing the list of storage credentials.
   */
  public async listAllStorageCredentials(
    listRequest: ListRequest
  ): Promise<ListResponse> {
    const grpcMetadata =
      await this.apiAuthService.getAuthenticatedRequestHeader();
    return firstValueFrom(
      this.customerStorageCredentialServiceClient.list(
        listRequest,
        grpcMetadata
      )
    );
  }

  /**
   * Lists storage credentials for a specific customer.
   *
   * @param customerId - The ID of the customer.
   * @returns A promise that resolves to a ListResponse containing the list of storage credentials for the specified customer.
   */
  public async listStorageCredentials(
    customerId: string
  ): Promise<ListResponse> {
    const grpcMetadata =
      await this.apiAuthService.getAuthenticatedRequestHeader();
    return firstValueFrom(
      this.customerStorageCredentialServiceClient.list(
        new ListRequest({
          customerId: customerId,
        }),
        grpcMetadata
      )
    );
  }

  /**
   * Archives a specified storage credential.
   *
   * @param id - The ID of the storage credential to archive.
   * @returns A promise that resolves to an ArchiveResponse containing the result of the operation.
   */
  public archiveStorageCredential(id: string): Promise<ArchiveResponse> {
    return new Promise((resolve, reject) => {
      this.apiAuthService
        .getAuthenticatedRequestHeader()
        .then((grpcMetaData: GrpcMetadata) => {
          const archiveRequest = new ArchiveRequest();
          archiveRequest.id = id;
          const archiveResponse = firstValueFrom(
            this.customerStorageCredentialServiceClient.archive(
              archiveRequest,
              grpcMetaData
            )
          );
          archiveResponse
            .then((value) => {
              return resolve(value);
            })
            .catch((error) => {
              this.logger.error(error);
              return reject(error);
            });
        });
    });
  }
}
