import { Injectable } from '@angular/core';
import { GrpcMetadata } from '@ngx-grpc/common';
import { firstValueFrom } from 'rxjs';

import { ApiAuthService } from './api-auth.service';
import {
  GetBinaryTypesRequest,
  GetBinaryTypesResponse,
} from './generated/src/main/proto/api/binary-type-service.pb';
import { BinaryTypeServiceClient } from './generated/src/main/proto/api/binary-type-service.pbsc';

/**
 * Service to interact with the binary type API, providing methods to fetch binary types.
 */
@Injectable({
  providedIn: 'root',
})
export class BinaryTypeService {
  constructor(
    private binaryTypeServiceClient: BinaryTypeServiceClient,
    private apiAuthService: ApiAuthService
  ) {}

  /**
   * Retrieves the list of binary types from the API.
   *
   * @param includeDeprecated - A boolean flag indicating whether to include deprecated binary types.
   * @returns A promise that resolves to a GetBinaryTypesResponse containing the list of binary types.
   */
  public async getBinaryTypes(
    includeDeprecated = false
  ): Promise<GetBinaryTypesResponse> {
    const grpcMetaData: GrpcMetadata =
      await this.apiAuthService.getAuthenticatedRequestHeader();

    const getBinaryTypesRequest = new GetBinaryTypesRequest({
      includeDeprecated: includeDeprecated,
    });

    return await firstValueFrom(
      this.binaryTypeServiceClient.getBinaryTypes(
        getBinaryTypesRequest,
        grpcMetaData
      )
    );
  }
}
