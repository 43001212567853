import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss'],
  standalone: true,
  imports: [CommonModule],
})
export class PageHeaderComponent {
  @Input() title = '';
  @Input() lastUpdated: string | undefined;
}
