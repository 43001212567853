import { LoggerService } from 'app/services/logger.service';
import { jwtDecode } from 'jwt-decode';

import { UserService } from '../services/user.service';
import { MessageBoxProvider } from '../views/shared/components/message-box/message-box.provider';

export class SamlBase {
  constructor(
    protected logger: LoggerService,
    protected messageBox: MessageBoxProvider,
    protected userService: UserService
  ) {}

  protected signInWithSaml(tenantId: string) {
    this.userService
      .signInWithSaml(tenantId)
      .then(async (userCredential) => {
        const token = await userCredential.user.getIdToken();
        // Workaround as Firebase IdTokenResult does not have sign-in-attributes property.
        try {
          const idTokenResult = JSON.stringify(jwtDecode(token));
          const signInAttributes =
            JSON.parse(idTokenResult)['firebase']['sign_in_attributes'];
          let displayName = undefined;
          if (signInAttributes['displayName']) {
            displayName = signInAttributes['displayName'];
          } else if (
            signInAttributes['firstName'] &&
            signInAttributes['lastName']
          ) {
            displayName =
              `${signInAttributes['firstName']} ${signInAttributes['lastName']}`.trim();
          }
          if (!userCredential.user.displayName && displayName) {
            this.userService.updateDisplayName(displayName);
          }
        } catch (error) {
          this.logger.error('Provider is missing Saml mappings', error);
        }
      })
      .catch((error) => {
        switch (error.code) {
          case 'auth/multi-factor-auth-required':
            this.messageBox.show(
              'This account is expected to use 2FA but the functionality is not supported by the identity provider.',
              'error'
            );
            this.logger.error('SignIn error (provider)', error);
            break;
          case 'auth/operation-not-allowed':
            this.messageBox.show(
              'This account does not support single sign-on.',
              'error'
            );
            this.logger.error('SignIn error (provider)', error);
            break;
          case 'auth/invalid-tenant-id':
            this.messageBox.show('Sign-in failed due to invalid tenant Id.');
            this.logger.error('Invalid tenant Id', error);
            break;
          case 'auth/popup-closed-by-user':
            this.messageBox.show('Sign-in failed, pop-up was closed by user.');
            this.logger.error(
              'Sign-in failed, pop-up was closed by user',
              error
            );
            break;
          default:
            this.messageBox.show(
              'An issue was found while attempting to sign-in.',
              'error'
            );
            this.logger.error('SAML sign-in error (provider)', error);
        }
      });
  }
}
